import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Data } from '@angular/router';
import * as Highcharts from 'highcharts';
import { DatePipe } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

import { BankUserDashboardService } from '../services/BankUserDashboard/bank-user-dashboard.service'

import { UsageDashBoardEntities } from '../usage-dashboard/UsageDashBoardEntities'
import { event } from 'jquery';

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-bank-user-dashboard',
  templateUrl: './bank-user-dashboard.component.html',
  styleUrls: ['./bank-user-dashboard.component.css']
})
export class BankUserDashboardComponent implements OnInit {

  rangeDates: Date[];
  value: any = 30

  _LstWeekTitle: string = ""
  _LstMonthTitle: string = ""
  _LstQuarterTitle: string = ""
  _LstYearTitle: string = ""

  calendar: any = [];
  selectedOption = "Last Week";

  _SelectDuration = "Last Week";
  _activeType = 'Users';

  _FromDate: any = undefined;
  _ToDate: any = undefined;

  Tooltip: string = "Users"

  _XAxisArray: any = []
  _YAxisArray: any = []
  maxValue: number
  _options: any

  _BotInteractionsList: any
  _BotInteraction: any = [];

  QAcolor: string = ""
  WUcolor: string = ""
  MUcolor: string = ""
  FCcolor: string = ""
  ANScolor: string = ""

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string
  closeModal: any
  myDate: any
  
 _RoleName: any
 _ViewClientRoleAccess: boolean = false;

  _isSelectedField: boolean = false;
  _selectedtype: any;

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;

  title = environment.pageTitle + ' - Bank User Dashboard';

  constructor(private modalService: NgbModal, public bankUserDashboardService: BankUserDashboardService, private datePipe: DatePipe, private titleService: Title) {
    this.myDate = new Date();
  }

  ngOnInit(): void {

    this.titleService.setTitle(this.title);

    this.LoadDashboardSummary(this.selectedOption, null, null, this._activeType);

    this.fetchDataByCalendar();

    this._RoleName = window.localStorage.getItem("RoleName");

    if (this._RoleName === 'ClientView' || this._RoleName === 'ContentView') {
      this._ViewClientRoleAccess = true;
    }
  }

  mySelectHandler(getname: any) {
    this.LoadDashboardSummary(getname.name, null, null, this._activeType);
  }

  fetchDataByCalendar() {
    this.calendar = [
      { name: 'Last Week', code: 'week' },
      { name: 'Last Month', code: 'month' },
      { name: 'Last Quarter', code: 'quarter' },
      { name: 'Last Year', code: 'year' }
    ]
  }

  numberWithCommas(x: any) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  _tickIntervalX: any = 1
  _tickIntervalY: any = 1000
  LoadDashboardSummary(vDuration: any, vFromDateIP: any, vToDateIP: any, vType: any) {
    this._SelectDuration = vDuration;

    if (vDuration.toUpperCase() == "LAST MONTH") {
      this._tickIntervalX = 5
    }
    else if (vDuration.toUpperCase() == "LAST QUARTER" || vDuration.toUpperCase() == "LAST YEAR") {
      this._tickIntervalX = 1
    }
    else {
      this._tickIntervalX = 1
    }

    this.getBotInteractions(vDuration, vFromDateIP, vToDateIP, vType);
    this.getAxisValues(vDuration, vFromDateIP, vToDateIP, vType, 'Load');
  };

  getBotInteractions(vDuration: any, vFromDateIP: any, vToDateIP: any, vType: any) {
    debugger
    let EntitiesObj = new UsageDashBoardEntities()
    EntitiesObj.BotDashboardRequest.Duration = vDuration;
    EntitiesObj.BotDashboardRequest.FromDate = vFromDateIP;
    EntitiesObj.BotDashboardRequest.ToDate = vToDateIP;
    EntitiesObj.BotDashboardRequest.Type = vType;

    this.Tooltip = vType;

    this.bankUserDashboardService.getBotInteractions(EntitiesObj.BotDashboardRequest).subscribe((res: any) => {
      debugger
      if (res.Status.toUpperCase() === "SUCCESS") {
        debugger
        this._BotInteractionsList = res.Data;

        if (this._BotInteractionsList.length > 0) {
          this._BotInteraction = this._BotInteractionsList[0];

          this._BotInteraction.UserCount = this.numberWithCommas(this._BotInteraction.UserCount);
          this._BotInteraction.SessionCount = this.numberWithCommas(this._BotInteraction.SessionCount);

          this.setPctColor(this._BotInteraction)

          this._LstWeekTitle = "Last Week: " + this.datePipe.transform(this._BotInteraction.WeekStartDate, "yyyy-MM-dd") + ' - ' + this.datePipe.transform(this._BotInteraction.WeekEndDate, "yyyy-MM-dd");
          this._LstMonthTitle = "Last Month: " + this.datePipe.transform(this._BotInteraction.MonthStartDate, "yyyy-MM-dd") + ' - ' + this.datePipe.transform(this._BotInteraction.MonthEndDate, "yyyy-MM-dd");
          this._LstQuarterTitle = "Last Quarter: " + this.datePipe.transform(this._BotInteraction.QuarterStartDate, "yyyy-MM-dd") + ' - ' + this.datePipe.transform(this._BotInteraction.QuarterEndDate, "yyyy-MM-dd");
          this._LstYearTitle = "Last Year: " + this.datePipe.transform(this._BotInteraction.YearStartDate, "yyyy-MM-dd") + ' - ' + this.datePipe.transform(this._BotInteraction.YearEndDate, "yyyy-MM-dd")

        }
        else {
          this.popupImg = "../../../assets/images/CommonError.png";
          this.popupContent = "Oops!";
          this.popupContentError = "No Data to show.";

          this.triggerModal(this.modalWarningAndInfo)
        }
      }

    })
  }

  getAxisValues(vDuration: any, vFromDateIP: any, vToDateIP: any, vType: any, vReFrom: any) {

    this._XAxisArray = [];
    this._YAxisArray = [];

    this._selectedtype = vType
    if (this._selectedtype) {
      this._isSelectedField = true
    } else {
      this._isSelectedField = false
    }

    if (vType === 'SessionCount')
      this.Tooltip = 'Total Sessions';
    else if (vType === 'Users' || vType === 'UserCount')
      this.Tooltip = "Unique Users";
    else
      this.Tooltip = "Total AvgDuration";

    if (vType === 'Users' || vType === 'UserCount') {
      vType = 'Users';
    }
    else if (vType === 'SessionCount') {
      vType = 'Sessions';
    }

    let EntitiesObj = new UsageDashBoardEntities()

    EntitiesObj.BotDashboardRequest.Duration = vDuration;
    EntitiesObj.BotDashboardRequest.FromDate = vFromDateIP;
    EntitiesObj.BotDashboardRequest.ToDate = vToDateIP;
    EntitiesObj.BotDashboardRequest.Type = vType;

    this.bankUserDashboardService.getAxisValues(EntitiesObj.BotDashboardRequest).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
    
        res.Data.forEach((currentValue: any, index: any) => {
          if (!currentValue.name) {
            this._XAxisArray.push(currentValue.LstXAxisValues);
            this._YAxisArray.push(currentValue.LstYAxisValues);

          }
        });

        var maxValue = this._YAxisArray.reduce((op: any, item: any) => op = op > item ? op : item, 0);
        var maxv = Math.floor(maxValue)

        
        if (maxv > 100000) {
          this._tickIntervalY = 100000
        }
        else if (maxv > 10000) {
          this._tickIntervalY = 10000
        }
        else if (maxv > 1000) {
          this._tickIntervalY = 1000
        }
        else if (maxv > 100) {
          this._tickIntervalY = 100
        }
        else if (maxv > 10) {
          this._tickIntervalY = 10
        }
        else
          this._tickIntervalY = 1

        this.HighChartFunction();

      }
      else if (res.Error) {
        ;
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  HighChartFunction() {
    this._options = {
      title: {
        text: ''
      },
      legend: {
        enabled: false
      },
      chart: {
      },
      labels: {
        enabled: false
      },
      xAxis: {
        crosshair: {
          width: 3,
          color: '#20c2d7'
        },
        tickInterval: this._tickIntervalX,
        labels: {
          enabled: true,
          align: 'center',
          x: 0,
          y: 30,
          fontSize: '8px'
        },
        lineWidth: 0.8,
        minorGridLineWidth: 0,
        lineColor: '#aeaeb2',
        minorTickLength: 1,
        tickLength: 1,
        categories: this._XAxisArray
      },
      yAxis: {
        min: 0,
        title: {
          text: ''
        },
        tickInterval: this._tickIntervalY,
     
        labels: {
          enabled: true,
          align: 'center',
          x: -10,
          y: 9,
        
        },
        lineWidth: 0.8,
        minorGridLineWidth: 0,
        lineColor: '#aeaeb2',
        plotLines: [{
          value: this._YAxisArray,
          color: "#20c2d7",
          width: 2,
          zIndex: 4,
          dashStyle: 'dash',
          label: {
            text: 'Target(T)',
            align: 'right',
            x: -10,
            y: 16,
            style: {
              fontWeight: 'bold'
            }
          }
        }]
      },
      tooltip: {
        useHTML: true,
        backgroundColor: '#fff',
        borderWidth: 1,
        borderRadius: 18,
        borderColor: '#20c2d7',
        shadow: false,
        headerFormat: '<table style="width: 103px; border-radius: 18px;"><tr><td><h6 style="padding-top:8px; color: #4b4b4b;font-size:var(--tooltip-font);font-family:Lato-Regular;text-align: center">{point.key}</h6></td></tr>',
        pointFormat:
          '<tr><td><h6 style="color: #4b4b4b;font-size:var(--tooltip-font);font-family:Lato-Regular;text-align: center"> ' + this.Tooltip + ' : {point.y}</h6></td></tr>',
        footerFormat: '</table>',
        followPointer: true,
        style: {
          padding: 40,
        }
      },
     
      plotOptions: {
        line: {
          marker: {
            enabled: true
          },
          dataLabels: {
            enabled: true
          }
        },
        series: {
          dataLabels: {
            enabled: true
          },
          animation: {
            duration: 4000
          }
        }
      },
      credits: {
        enabled: false
      },

      series: [{
        type: 'spline',
        showInLegend: false,
        color: "#20c2d7",
        name: "", 
        data: this._YAxisArray,
        marker: {
          labels: {
            enabled: true
          },
          enabled: true
        },
        labels: {
          enabled: true
        },
        gridLineColor: 'transparent'
      }],

    }
    Highcharts.chart('highchartContainer', this._options)
  }


  setPctColor(BotData: any) {
    if (BotData.QueAnsPctIncDec > 60) this.QAcolor = 'pctrighDivCommonMainColor';
    else if (BotData.QueAnsPctIncDec >= 20 && BotData.QueAnsPctIncDec <= 60) this.QAcolor = 'pctcolorOrange';
    else if (BotData.QueAnsPctIncDec < 20) this.QAcolor = 'pctcolorRed';
    else this.QAcolor = 'pctcolorDefault'

    if (BotData.WebUsrPctIncDec > 60) this.WUcolor = 'pctrighDivCommonMainColor';
    else if (BotData.WebUsrPctIncDec >= 20 && BotData.WebUsrPctIncDec <= 60) this.WUcolor = 'pctcolorOrange';
    else if (BotData.WebUsrPctIncDec < 20) this.WUcolor = 'pctcolorRed';
    else this.WUcolor = 'pctcolorDefault'

    if (BotData.MobileUsrPctIncDec > 60) this.MUcolor = 'pctrighDivCommonMainColor';
    else if (BotData.MobileUsrPctIncDec >= 20 && BotData.MobileUsrPctIncDec <= 60) this.MUcolor = 'pctcolorOrange';
    else if (BotData.MobileUsrPctIncDec < 20) this.MUcolor = 'pctcolorRed';
    else this.MUcolor = 'pctcolorDefault'

    if (BotData.FallbackCountPctIncDec < 10) this.FCcolor = 'pctrighDivCommonMainColor';
    else if (BotData.FallbackCountPctIncDec <= 25 && BotData.FallbackCountPctIncDec >= 10) this.FCcolor = 'pctcolorOrange';
    else if (BotData.FallbackCountPctIncDec > 25) this.FCcolor = 'pctcolorRed';
    else this.FCcolor = 'pctcolorDefault'

    if (BotData.AvgNPSScorePercIncDec > 60) this.ANScolor = 'pctrighDivCommonMainColor';
    else if (BotData.AvgNPSScorePercIncDec >= 20 && BotData.AvgNPSScorePercIncDec <= 60) this.ANScolor = 'pctcolorOrange';
    else if (BotData.AvgNPSScorePercIncDec < 20) this.ANScolor = 'pctcolorRed';
    else this.ANScolor = 'pctcolorDefault';
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  convetToPDF() {
    const data = document.getElementById('HomePageD');
    if (data) {
      html2canvas(data).then(canvas => {
        var imgWidth = 208;
        var pageHeight = data.clientHeight;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jsPDF('p', 'mm', 'a4'); 
        var position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)

        this.myDate = new Date();
        this.myDate = this.datePipe.transform(this.myDate, 'MMddyyyyHHmm');

        pdf.save('AccountAnalyticsShare' + this.myDate +'.pdf'); 

       
        // a.download = 'AccountAnalytics' + this.myDate + '.csv';
      });
    }
  }

  ExportDashboardData(vDuration: any, vFromDateIP: any, vToDateIP: any, vType: any) {
    let EntitiesObj = new UsageDashBoardEntities()
    EntitiesObj.BotDashboardRequest.Duration = vDuration.name == undefined ? vDuration : vDuration.name;
    EntitiesObj.BotDashboardRequest.FromDate = vFromDateIP;
    EntitiesObj.BotDashboardRequest.ToDate = vToDateIP;
    EntitiesObj.BotDashboardRequest.Type = vType;

    this.bankUserDashboardService.ExportDashboardData(EntitiesObj.BotDashboardRequest).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        var status = res.status;
        const headers = res.headers;
        const data = res.Data;
        var contentType = 'application/CSV';
        var linkElement = document.createElement('a');
        var blob = new Blob([data], { type: contentType });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';

        this.myDate = new Date();
        
        this.myDate = this.datePipe.transform(this.myDate, 'MMddyyyyHHmm');
        a.download = 'AccountAnalytics' + this.myDate + '.csv';

        document.body.appendChild(a);
        a.click();

        this.popupImg = "../../../assets/images/download-popup.png";
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo);

      }
    })
  }

}
