import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FaqService } from 'src/app/services/faq/faq.service';
import { faqEntities } from '../../faq/fqaentities'
import { Router } from "@angular/router";

@Component({
  selector: 'app-intent-list',
  templateUrl: './intent-list.component.html',
  styleUrls: ['./intent-list.component.css'],
  providers: [DatePipe]
})
export class IntentListComponent implements OnInit {

  @Input() inputVariable: any;
  _inputData: any
  _selectedRowData: any = []

  intentListArr: any = []
  intentListHeadersArr: any = [];

  first: number = 0;
  sortColumnName: string;
  sortorder: boolean;
  descOrder: Boolean;
  newrow: boolean = false
  addRecord = new faqEntities()

  _URL_REGEXP = /^(\s+\S+\s*)*(?!\s).*$/;
  _disableSaveBtn = true;
  _hideEmptyRow: boolean = false

  _ifNoRecords: boolean = false
  isLoader: boolean = true;
  _hideContentDuringLoading: boolean = false

  popupImg: string;
  popupContent: string;
  popupContentError: string

  dataTodelete: any
  closeModal: any
  myDate: any
  ErrorMSG: any

  _IsAnythingChange = false
  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;
  @ViewChild("ConfirmationModal", { static: false }) public confirmationModal: NgbModal;
  @Output() inputdata: EventEmitter<any> = new EventEmitter<any>();

  constructor(public faqconfigservice: FaqService, private modalService: NgbModal, private datePipe: DatePipe, private router: Router) {
    this.myDate = new Date();
  }

  ngOnInit(): void {
    
    this._inputData = this.inputVariable
    this._selectedRowData = this._inputData.selectedRowData

    this.intentListHeaders()
    this.fetIntentDetails();
  }

  intentListHeaders() {
    this.intentListHeadersArr = [
      { field: 'DispId', header: 'Sr No.', width: "6%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'Questions', header: 'FAQ', width: "14%", isEditable: "true", isSort: "true", textAlignment: "left", value: 'FAQ' },
      { field: 'DisplayMessage', header: 'Answer', width: "14%", isEditable: "true", isSort: "true", textAlignment: "left", value: 'Answer' },
      { field: 'BondModifiedOn', header: '', width: "9%", isEditable: "false", isSort: "false", textAlignment: "center", value: 'b' }
    ]
  }

  sortcolumn(ColumnName: any) {

    let reverse = this.sortColumnName === ColumnName ? !this.descOrder : undefined;

    if (reverse === undefined)
      reverse = false;

    this.sortColumnName = ColumnName;
    this.descOrder = reverse;

    if (this.descOrder == true)
      this.sortorder = false;
    else
      this.sortorder = true;
  }

  onchangeControlles(event: any, from: any, value: any) {

    
    if (from.toUpperCase() == "EDIT") {
      if (!this._URL_REGEXP.test(value)) {
        this._disableSaveBtn = true
      }
      else {
        if (value == "")
          this._disableSaveBtn = true
        else
          this._disableSaveBtn = false
      }
    }
    else {
      if (
        !this._URL_REGEXP.test(this.addRecord.IntentMaintenance.Questions)
        || !this._URL_REGEXP.test(this.addRecord.IntentMaintenance.DisplayMessage)
      )
        this._disableSaveBtn = true
      else {

        if (this.addRecord.IntentMaintenance.Questions == "" || this.addRecord.IntentMaintenance.DisplayMessage == "")
          this._disableSaveBtn = true
        else
          this._disableSaveBtn = false
      }
    }


  }

  fetIntentDetails() {
    
    this.isLoader = true;
    this.faqconfigservice.GetIntentByUsingSampleRes(this._selectedRowData).subscribe((res: any) => {

      
      if (res.Status.toUpperCase() == "SUCCESS") {

        this.intentListArr = res.Data

        this._hideEmptyRow = false;
        if (this.intentListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 500);
        } else {
          this._ifNoRecords = false
        }

        this.isLoader = false;
      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  addNewRow() {
    
    this.newrow = true;
    this._ifNoRecords = false

    var data = {
      Questions: "",
      DisplayMessage: ""
    }

    if (this.intentListArr.length == 0) {
      this.intentListArr.push(data);
      this._hideEmptyRow = true;
    }

    this.addRecord.IntentMaintenance.SectionName = "";
    this.addRecord.IntentMaintenance.Questions = "";
    this.addRecord.IntentMaintenance.DisplayMessage = "";
    this.first = 0;
  }

  addIntentRecord(event: any) {
    
    this.newrow = false;
    this._IsAnythingChange = true
    //  this.isLoader = true;
    // this.addRecord.IntentMaintenance.SectionName = this._selectedRowData.SectionName;
    // this.addRecord.IntentMaintenance.IntentName = this._selectedRowData.SectionName;
    // this.addRecord.IntentMaintenance.SectionName = this._selectedRowData.SectionName;

    this.myDate = new Date();
    this.myDate = this.datePipe.transform(this.myDate, 'MMddyyyyHHmmss');
    this.addRecord.IntentMaintenance.IntentName = "FAQ" + this.myDate

    var data = {
      // BondCreatedOn: "2021-01-27T01:33:27",
      // BondModifiedOn: "2021-01-27T01:33:27",
      // DispId: 1,
      DisplayMessage: this.addRecord.IntentMaintenance.DisplayMessage,
      Intent: this.addRecord.IntentMaintenance.IntentName,
      IntentId: 0,
      IsEndOfConversation: true,
      Language: "EN",
      Questions: this.addRecord.IntentMaintenance.Questions,
      RawIntentId: this._selectedRowData.IntentId,
      RecStatus: true,
      ResponseType: "Text",
      SectionName: this._selectedRowData.SectionName,
      TenantID: 1,
      UserMessage: this.addRecord.IntentMaintenance.DisplayMessage,
      ParentIntent: this._selectedRowData.IntentName
    }

    this.intentListArr = this.intentListArr.filter((x: { Questions: string; }) => x.Questions !== "");
    this.intentListArr.push(data);

    if (this.intentListArr.length == 0) {
      setTimeout(() => {
        this._hideEmptyRow = true
      }, 1000);
    } else {
      this._hideEmptyRow = false
    }

  }

  cancelNewRow() {
    
    this.newrow = false;

    this.addRecord.IntentMaintenance.SectionName = "";
    this.addRecord.IntentMaintenance.Questions = "";
    this.addRecord.IntentMaintenance.DisplayMessage = "";

    this.intentListArr = this.intentListArr.filter((x: { Questions: string; }) => x.Questions !== "");
    if (this.intentListArr.length == 0) {
      setTimeout(() => {
        this._ifNoRecords = true
      }, 1000);
    } else {
      this._ifNoRecords = false
    }
  }

  SaveIntent() {
    
    // vEditRowData.RawIntentId = this._selectedRowData.IntentId

    this.isLoader = true;
    this._hideContentDuringLoading = true

    this._ifNoRecords = false

    if (this.intentListArr.length > 0) {
      if (this.intentListArr[0].Questions == "") {
        var checkExists = this.intentListArr.find((x: { Questions: string; }) => x.Questions == "")

        if (checkExists) {
          const index = this.intentListArr.indexOf(checkExists);
          if (index !== -1) {
            this.intentListArr.splice(index, 1);
          }
        }
      }

    }
    this.faqconfigservice.UpdateSampleResIntents(this.intentListArr).subscribe((res: any) => {

      
      if (res.Status.toUpperCase() == "SUCCESS") {
        this.isLoader = false;
        this._hideContentDuringLoading = false
        // this._hideEmptyRow = true;

        if (this.intentListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        } else {
          this._ifNoRecords = false
        }

        this.popupImg = "../../../assets/images/Update.png"
        this.popupContent = res.Message;
        this.popupContentError = "";

        this._IsAnythingChange == false
        this.triggerModal(this.modalWarningAndInfo)

        setTimeout(() => {
          this._inputData.dismissFunction()
        }, 500);

        let currentUrl = '/portal/ChatBOT/FAQList';
        this.router.navigateByUrl('/portal', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  OpenDeletePopup(vEditRowData: any) {
    
    this.ErrorMSG = ''
    this._hideContentDuringLoading = true

    var checkIntentExists = this.intentListArr.find((x: { Intent: string; }) => x.Intent == vEditRowData.Intent)

    if (checkIntentExists) {
      
      const index = this.intentListArr.indexOf(checkIntentExists);
      if (index !== -1) {
        // this.intentListArr.splice(index, 1);

        checkIntentExists.IsDeleted = true;

        this.intentListArr[index] = checkIntentExists
      }
    }

    this._IsAnythingChange = true

    this._hideContentDuringLoading = false

    // this.ErrorMSG = 'Please click on the Save button to save the changes.'

    this.popupImg = "../../../assets/images/CommonError.png"
    this.popupContent = "Please click on the Save button to save the changes.";
    this.popupContentError = "";

    this.triggerModal(this.modalWarningAndInfo);
    this._disableSaveBtn = false
  }

  // OpenDeletePopup(vEditRowData: any) {
  //   
  //   this.ErrorMSG = ''
  //   this.isLoader = true
  //   this._hideContentDuringLoading = true

  //   // vEditRowData.RawIntentId = this._selectedRowData.IntentId
  //   this.faqconfigservice.DeleteSampleResIntents(vEditRowData).subscribe((res: any) => {

  //     
  //     if (res.Status.toUpperCase() == "SUCCESS") {

  //       var checkIntentExists = this.intentListArr.find((x: { Intent: string; }) => x.Intent == vEditRowData.Intent)

  //       if (checkIntentExists) {
  //         const index = this.intentListArr.indexOf(checkIntentExists);
  //         if (index !== -1) {
  //           this.intentListArr.splice(index, 1);
  //         }
  //       }

  //       this._IsAnythingChange = true
  //       this.isLoader = false

  //       this._hideContentDuringLoading = false

  //       this.popupImg = "../../../assets/images/delete-popup.png"
  //       this.popupContent = res.Message;
  //       this.popupContentError = "";

  //       this.ErrorMSG = 'Please click on the Save button to save the changes.'
  //       this._disableSaveBtn = false
  //       // if (this.intentListArr.length == 0) {
  //       //   setTimeout(() => {
  //       //     this._ifNoRecords = true
  //       //   }, 1000);
  //       // } else {
  //       //   this._ifNoRecords = false
  //       // }

  //       // this.triggerModal(this.modalWarningAndInfo)
  //       // setTimeout(() => {
  //       //   this._inputData.dismissFunction()
  //       // }, 500);
  //     }
  //     else if (res.Error) {
  //       
  //       this.popupImg = "../../../assets/images/CommonError.png";
  //       this.popupContent = "Oops!";
  //       this.popupContentError = res.Error;
  //       this.isLoader = false
  //       this.triggerModal(this.modalWarningAndInfo)
  //     }
  //     else {
  //       
  //       var checkIntentExists = this.intentListArr.find((x: { Intent: string; }) => x.Intent == vEditRowData.Intent)

  //       if (checkIntentExists) {
  //         const index = this.intentListArr.indexOf(checkIntentExists);
  //         if (index !== -1) {
  //           this.intentListArr.splice(index, 1);
  //         }
  //       }

  //       this.isLoader = false
  //       this._IsAnythingChange = true
  //       this.isLoader = false

  //       this._hideContentDuringLoading = false

  //       if (this.intentListArr.length == 0) {
  //         setTimeout(() => {
  //           this._ifNoRecords = true
  //         }, 1000);
  //       } else {
  //         this._ifNoRecords = false
  //       }
  //     }
  //   })

  // }



  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onCancelPopup() {
    
    if (this._IsAnythingChange == true) {
      this.popupImg = "../../../assets/images/Update.png";
      this.popupContent = "Do want to save this changes?";
      this.popupContentError = "";
      this.triggerModal(this.confirmationModal)
    }
    else {
      this.CancelIntent()
    }
  }

  CancelIntent() {
    
    this.addRecord = new faqEntities()
    this._inputData.dismissFunction()
  }

  public boundedMainYesValue = this.SaveIntent.bind(this);
  public boundedMainNoValue = this.CancelIntent.bind(this);

}
