import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { dateFormat } from 'highcharts';

@Component({
    template: ''
})

export class ContextFlowEntity {
    UseCaseName: any;
    IntentName: any;
    PrimaryQuestion = "";
    Slots: Array<Slot> = [];
}

export class Slot {
    SlotName: any = "";
    SlotType: any = "";
    SlotOrder: number = 1
    NodeID: string = ""
    Question: string = ""
    Slotmessage: Array<Slotmessage> = [];
    SlotValues = [];
    SlotDataType: any = "";
    DataValidation: any = "";
    DataFormat: any = "";
    InputFormat: any = "NA";
    NoOfAttemptsAllowed: any = "1";
    NoOfAttemptsOver: any = 0;
    AwaitMsg: any = "";
    Returnslotbasedoninput: Array<ReturnSlotBasedOnInput> = [];
    SavedUserInput: any = "";
    SaveIntoDB: any = "";
    UserInputBox: boolean = false;
    Enablefield: any = "";
    EndOfConv: boolean = false;
}

export class Slotmessage {
    AppType: any = "";
    PreMsg: any = "";
    PreMsgType: any = "";
    MainMsg: any = "";
    DisplayMessage: any = "";
    ResponseType: any = "";
    PostMsg: any = "";
    PostMsgType: any = "";
}

export class ReturnSlotBasedOnInput {
    id: number
    UserInput: any = "";
    ReturnType: any = "Slots";
    ReturnName: any = "";
    Returnparams = new Returnparams();
    CustomCSMethod: any = "";
    Override: any = "";
    APIEndPointDetails: [];
    Slotmessage: Array<Slotmessage> = [];
}

export class Returnparams {
}

export class Nodes {
    SlotName: string = "";
    Question: string = "";
    Answer: string = "";
    NodeID: string = "";
}