import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, ViewChild, ElementRef, HostListener, EventEmitter } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { EnterpriseUserAccountService } from '../services/EnterpriseUserAccount/enterprise-user-account.service'
import { newAccountEntities } from '../NewAccounts/newAccountEntities'

import { ChangeDetectorRef } from '@angular/core';

import { Table } from 'primeng/table';
import { AnyMxRecord } from 'node:dns';

import { environment } from 'src/environments/environment';

import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-enterprise-user-account',
  templateUrl: './enterprise-user-account.component.html',
  styleUrls: ['./enterprise-user-account.component.css']
})
export class EnterpriseUserAccountComponent implements OnInit {

  @ViewChild('dt') dt: ElementRef
  @ViewChild("dt", { static: false }) public ptable: Table;
  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;
  @ViewChild("ConfirmationModal", { static: false }) public confirmationModal: NgbModal;
  @ViewChild("userHtml", { static: false }) userHtml: any;
  @ViewChild("modalDownloadFiles", { static: false }) public modalDownloadFiles: NgbModal;


  searchDataArr: any = [];

  filterAllFields: any
  filterArr: any = [];
  isHide: boolean = true
  selectedAccountStatus: string = '';

  newFilterDataArr: any = [];
  selectedValues: any = [];
  selectedSearchOpt: any[];
  toggle: boolean = false;
  chageFilterInput: boolean = false;
  totalSelectedValues: any;
  selectedFieldOutline: boolean = false;
  greyFieldOutline: boolean = false;

  isLoader: boolean = true;

  sortColumnName: string;
  sortorder: boolean;
  descOrder: Boolean;

  selectedName: string = "";
  selectedEmail: string = "";
  selectedRefNo: string = "";
  IsSelectedCB: boolean = false;

  searchFAQ: string = "";

  first: number = 0;
  rows = 10;

  parameters = new newAccountEntities()
  myDate: any

  NewAccListArr: any = [];

  newAccHeadersArr: any = [];
  searchOptionArr: any = [];
  selectedOptArr: any = [];

  showModifyIcon: boolean = true
  hoverModifyIcon: boolean = false

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string
  closeModal: any
  popupPrimaryBtn: string

  isStatusClick: boolean = false
  eventDispId: any
  isIncompleteStatus: boolean = false
  isPendingStatus: boolean = false

  showApprovePopup: boolean = false
  _ifNoRecords: boolean = false

  checkPengingStatusLable: string;
  PendingStatus: string 	

  _APIUrl: any
  _SingleRowData: any

  _RoleName: any
  _ViewClientRoleAccess: boolean = false;

  @ViewChild('eb') eb: ElementRef

  title = 'BOND Portal - Enterprise User Accounts';

  constructor(private modalService: NgbModal, private _eref: ElementRef, public newAccountsService: EnterpriseUserAccountService, private _http: HttpClient, private cdref: ChangeDetectorRef, private datePipe: DatePipe,private titleService:Title) {
    this.myDate = new Date();
    this.sortorder = false;
  }

  ngOnInit(): void {

    this.titleService.setTitle(this.title);

    this._APIUrl = environment._baseURL;

    this.getNewAccountsList();
    this.NewAccListHeaders();
    this.filterDropdown();
    this.sortorder = false;
    
    this._RoleName = window.localStorage.getItem("RoleName");

    if (this._RoleName === 'ClientView' || this._RoleName === 'ContentView') {
      this._ViewClientRoleAccess = true;
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  statusLink(event: any) {
    this.eventDispId = event.DispID
    this.userHtml = event.Reason

    this._SingleRowData = event

    if (event.RvwStatus.toUpperCase() === "INCOMPLETE") {
      this.isStatusClick = true
      this.isIncompleteStatus = true
      this.isPendingStatus = false
    }
    else if (event.RvwStatus.toUpperCase() === "PENDING") {
      this.isStatusClick = true
      this.isPendingStatus = true
      this.isIncompleteStatus = false
    }
    else {
      this.isStatusClick = false
      this.isIncompleteStatus = false
      this.isPendingStatus = false
    }
  }

  checkPendingStatus(event: any, singleRow: AnyMxRecord) {

    this.checkPengingStatusLable = event
    this.popupContent = "Are you sure you want to " + this.checkPengingStatusLable + " this account?"

    this.triggerModal(this.confirmationModal);
  }

  filterDropdown() {
    this.filterArr = [
      {
        label: 'Account Status', value: 'accountstatus',
        items: [
          { name: 'Completed', code: 'accountstatus', status: 'completed', checked: false },
          { name: 'Incomplete', code: 'accountstatus', status: 'incomplete', checked: false },
          { name: 'Pending', code: 'accountstatus', status: 'pending', checked: false }
        ]
      }
    ];

    this.searchOptionArr = [
      { name: 'Name', code: 'name' },
      { name: 'Email', code: 'email' },
      { name: 'Reference Number', code: 'refNo' }
    ]
  }

  selectOption(text: any) {
    this.isLoader = true;
    this.filterAllFields = text;
  }

  onClickedOutside(e: any) {
    this.toggle = false;
    this.selectedFieldOutline = false;
  }

  clearInputField() {
    this.selectedValues = []
    this.filterAllFields = []
    this.isHide = true
    
    this.toggle = false
    this.selectedFieldOutline = false;

    this.filterDropdown();
    this.chageFilterInput = false

    const event = { "keyCode": 13 }

    this.searchData(event, "Search");
  }

  selectFilterOption(details: any) {
    ;
    this.isHide = false

    if (details.checked == true) {
      this.chageFilterInput = true
      var d = this.selectedValues.find((x: { code: string; }) => x.code == details.code)

      if (d) {
        this.dropSkill(d)
        this.selectedValues.push(details)
      }

      if (!d) {
        this.selectedValues.push(details)
      }
    }
    else {
      this.selectedValues.forEach((value: any, id: any) => {

        if (value.status == details.status)

          this.selectedValues.splice(id, 1)
        this.chageFilterInput = true
      });
    }

    this.totalSelectedValues = this.selectedValues.length
    if (this.totalSelectedValues === 0) {
      this.chageFilterInput = false
      this.isHide = true
    }

    this.filterAllFields = this.selectedValues;

    const event = { "keyCode": 13 }

    this.searchData(event, "Search");

    var skinName = this.selectedValues.find((x: { name: string; }) => x.name == details);
  }

  dropSkill(index: any) {
    this.selectedValues.forEach((value: any, id: any) => {
      if (value.status == index.status) 
        this.selectedValues.splice(id, 1)
    });

    let indexMain = this.filterArr[0].items.indexOf(index);

    if (indexMain === -1) {
      indexMain = this.filterArr[1].items.indexOf(index);

      if (indexMain === -1) {
        indexMain = this.filterArr[2].items.indexOf(index);

        if (indexMain === -1) {
        }
        else {
          const dataMain = this.filterArr[2].items.filter(function (x: any) { return x.status === index.status })[0];

          dataMain.checked = false;

          this.filterArr[2].items[indexMain] = dataMain
        }
      }
      else {
        const dataMain = this.filterArr[1].items.filter(function (x: any) { return x.status === index.status })[0];

        dataMain.checked = false;

        this.filterArr[1].items[indexMain] = dataMain
      }
    }
    else {
      const dataMain = this.filterArr[0].items.filter(function (x: any) { return x.status === index.status })[0];

      dataMain.checked = false;

      this.filterArr[0].items[indexMain] = dataMain
    }

    this.filterAllFields = this.selectedValues;

    const event = { "keyCode": 13 }

  }

  clickEvent() {
    this.selectedFieldOutline = true;
    this.toggle = !this.toggle
  }

  changeStyle() {
    this.greyFieldOutline = !this.greyFieldOutline
  }

  getNewAccountsList() {

    this.newAccountsService.getNewAccountsData(this.parameters.NewAccountModel).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {

        this.NewAccListArr = res.Data;
        this.isLoader = false;
        this.sortColumnName = '';
        this.sortorder = false;

      } else if (res.Error) {

        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  NewAccListHeaders() {
    this.newAccHeadersArr = [
      { field: 'DispID', header: 'Sr No.', width: "8%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'ReferanceNo', header: 'Ref. No', width: "7%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'Section Name' },
      { field: 'FirstName', header: 'Name', width: "10%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'FAQ' },
      { field: 'EmailId', header: 'Email', width: "12%", isEditable: "true", isSort: "true", textAlignment: "left", value: 'Answer' },
      { field: 'MobileNo', header: 'Phone', width: "7%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'b' },
      // { field: 'DLNO', header: 'DL#', width: "6%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'b' },
      // { field: 'BondScore', header: 'BOND Score', width: "9%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'b' },
      { field: 'BondCreatedOn', header: 'Create Date', width: "9%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'b' },
      { field: 'RvwStatus', header: 'Verification Status', width: "12%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'b' },
      { field: 'download', header: '', width: "8%", isEditable: "false", isSort: "false", textAlignment: "center", value: 'b' },
      // { field: 'ImgPath', header: '', width: "8%", isEditable: "false", isSort: "false", textAlignment: "center", value: 'b' }
    ]
  }

  searchNewAccount: string = "";

  searchData(event: any, actionName: any) {
;
    this.selectedName = "";
    this.selectedEmail = "";
    this.selectedRefNo = "";

    if (event.keyCode === 13) {

      var searchEntities = new newAccountEntities();

      this.searchNewAccount = this.searchNewAccount.trim();

       if (this.selectedOptArr.length != 0) {

        for (let i = 0; i < this.selectedOptArr.length; i++) {
          if (this.selectedOptArr[i].code.toUpperCase() == "NAME") {
            this.selectedName = this.searchNewAccount
          } else if (this.selectedOptArr[i].code.toUpperCase() == "EMAIL") {
            this.selectedEmail = this.searchNewAccount
          } else if (this.selectedOptArr[i].code.toUpperCase() == "REFNO") {
            this.selectedRefNo = this.searchNewAccount
          } 
        }
        this.IsSelectedCB = true;
      }
      else {
        this.selectedName = this.searchNewAccount;
        this.selectedEmail = this.searchNewAccount;
        this.selectedRefNo = this.searchNewAccount;
        this.IsSelectedCB = false;
      }


     searchEntities.NewAccountModel.EmailId = this.selectedEmail;
     searchEntities.NewAccountModel.ReferanceNo = this.selectedRefNo;
     searchEntities.NewAccountModel.FirstName = this.selectedName;
      searchEntities.NewAccountModel.IsSelectedCB = this.IsSelectedCB;

      this.getfilterDatafromArray();

      searchEntities.NewAccountModel.RvwStatus = this.selectedAccountStatus

      if (actionName.toUpperCase() === "SEARCH") {
        this.SearchNewAccount(searchEntities.NewAccountModel);
      }
      else if (actionName.toUpperCase() === "EXPORT") {
        this.exportNewAccountsList(searchEntities.NewAccountModel);
      }
    }
  }

  SearchNewAccount(searchEntities: any) {
    ;
    this.isLoader = true;

    this.newAccountsService.SearchNewAccountData(searchEntities).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        this.NewAccListArr = res.Data;
        this.isLoader = false;

        this.sortColumnName = '';
        this.sortorder = false;

        this.ptable.reset();

        if (this.NewAccListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        }else {
          this._ifNoRecords = false
        }
      }
      else if (res.Error) {

        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  private getfilterDatafromArray() {
    this.selectedAccountStatus = "";

    if (this.filterAllFields != undefined) {

      for (let i = 0; i < this.filterAllFields.length; i++) {
        if (this.filterAllFields[i].code.toUpperCase() == "ACCOUNTSTATUS") {
          if (this.selectedAccountStatus === "")
            this.selectedAccountStatus = this.filterAllFields[i].name;

          else
            this.selectedAccountStatus = this.selectedAccountStatus + "," + this.filterAllFields[i].name;
        }
        else {
          this.selectedAccountStatus = "";
        }
      }
    }
  }

  AfterCLickExport() {

    const event = {
      keyCode: 13
    }

    this.searchData(event, "Export")
  }

  closePopup() {
    this.showPopup = false;
    this.showApprovePopup = false;

  }

  exportNewAccountsList(searchEntities: any) {

    searchEntities.SorColumnName = this.sortColumnName
    searchEntities.Reverseorder = this.sortorder

    this.newAccountsService.exportNewAccountsData(searchEntities).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        var status = res.status;
        const headers = res.headers;
        const data = res.Data;
        var contentType = 'application/CSV';
        var linkElement = document.createElement('a');
        var blob = new Blob([data], { type: contentType });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        
        this.myDate = new Date();
        
        this.myDate = this.datePipe.transform(this.myDate, 'MMddyyyyHHmm');
        a.download = "NewAccounts" + this.myDate + '.csv';

        document.body.appendChild(a);
        a.click();

        this.popupImg = "../../../assets/images/download-popup.png";
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo);

      } else if (res.Error) {
        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;
        this.triggerModal(this.modalWarningAndInfo);
      }
    })
  }

  public boundedPrintValue = this.updateNewAccountsData.bind(this);

  updateNewAccountsData(vEntities: any) {
    this.showApprovePopup = false

    this.isLoader = true
    this.isStatusClick = false
    this.isIncompleteStatus = false
    this.isPendingStatus = false

    this.parameters = new newAccountEntities()
    this.parameters.NewAccountModel.RvwStatus = 'COMPLETED'
    this.parameters.NewAccountModel.AccountID = this._SingleRowData.AccountID

    this.newAccountsService.updateNewAccountsData(this.parameters.NewAccountModel).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {

        if (vEntities.toUpperCase() == "APPROVE") {
          this.popupImg = "../../../assets/images/Approved_icon.png";
          this.popupContent = "Account Approved Successfully";
          this.popupContentError = "";

          this.triggerModal(this.modalWarningAndInfo)
        }
        else {
          this.popupImg = "../../../assets/images/disapprove_icon.png";
          this.popupContent = "Account Disapproved Successfully";
          this.popupContentError = "";
          this.triggerModal(this.modalWarningAndInfo)
        }

        this.getNewAccountsList();
      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/update_error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  sortcolumn(ColumnName: any) {

    let reverse = this.sortColumnName === ColumnName ? !this.descOrder : undefined;

    if (reverse === undefined)
      reverse = false;

    this.sortColumnName = ColumnName;
    this.descOrder = reverse;

    if (this.descOrder == true)
      this.sortorder = false;
    else
      this.sortorder = true;
  }

  hoverActionModifyIcon() {
    this.showModifyIcon = false
    this.hoverModifyIcon = true;
  }

  removeModifyIcon() {
    this.showModifyIcon = true
    this.hoverModifyIcon = false;
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

   selectSearchOption(text: any) {
    ;
    this.isLoader = true;
    this.selectedOptArr = text; 

     this.selectedName = "";
     this.selectedEmail = "";
     this.selectedRefNo = "";
    
     var searchEntities = new newAccountEntities();

     if (this.selectedOptArr.length != 0) {

      for (let i = 0; i < this.selectedOptArr.length; i++) {
        if (this.selectedOptArr[i].code.toUpperCase() == "NAME") {
          this.selectedName = this.searchNewAccount
        } else if (this.selectedOptArr[i].code.toUpperCase() == "EMAIL") {
          this.selectedEmail = this.searchNewAccount
        } else if (this.selectedOptArr[i].code.toUpperCase() == "REFNO") {
          this.selectedRefNo = this.searchNewAccount
        } 
      }
      this.IsSelectedCB = true;
    }

    else {
      this.selectedName = this.searchNewAccount;
      this.selectedEmail = this.searchNewAccount;
      this.selectedRefNo = this.searchNewAccount;
      this.IsSelectedCB = false;
    }

    searchEntities.NewAccountModel.FirstName = this.selectedName;
    searchEntities.NewAccountModel.EmailId = this.selectedEmail;
    searchEntities.NewAccountModel.ReferanceNo = this.selectedRefNo;
    searchEntities.NewAccountModel.IsSelectedCB = this.IsSelectedCB;

    this.SearchNewAccount(searchEntities.NewAccountModel);

  }

  openDownloadFilesPopup() {
    this.triggerModal(this.modalDownloadFiles);
  }

}
