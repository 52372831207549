import { Component, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ClusterPersonaService } from '../services/Cluster_Persona/cluster-persona.service'

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-cluster',
    templateUrl: './cluster.component.html',
    styleUrls: ['./cluster.component.css']
})
export class ClusterComponent implements OnInit {

    selectedData: any;
    filteredData: any = [];
    filteredDataName: any = [];
    cluster: any = "#3cd378";
    category: any
    categoryArr: any = [];

    finalbankDataArr: any = [];
    totalUser: any

    bublefirst: any
    bubleSecond: any
    bublethird: any

    BankDashboardData: any
    BgColor: any = "BgColorG";
    BgColorlable: any = "BgColorG";
    options: any

    options1: any
    options2: any
    options3: any
    points: any

    BDDataForBubbles: any = []
    SegmentList: any = []

    showPopup: boolean = false
    popupImg: string;
    popupContent: string;
    popupContentError: string
    closeModal: any

    isHide: boolean = true
    chageFilterInput: boolean = false;
    selectedFieldOutline: boolean = false;
    greyFieldOutline: boolean = false;
    filterArr: any = [];
    toggle: boolean = false;
    selectedValues: any = [];
    totalSelectedValues: any;

    _BehaviorIndex: string
    _Users: string;
    _ConversionIndex: string;

    _Exportbutton: boolean = false
    _CRMbutton: boolean = false

    _RoleName: any
    _ViewClientRoleAccess: boolean = false;

    _bgColorName: string;

    @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;

    title = environment.pageTitle + ' - Cluster';

    constructor(public clusterPersonaService: ClusterPersonaService, private modalService: NgbModal, private titleService: Title) { }

    ngOnInit(): void {

        this.titleService.setTitle(this.title);

        this.fetDataList();
        this.getBDDataForBubbles();
        this.filterDropdown();
        this.MainPieData();

        this._RoleName = window.localStorage.getItem("RoleName");

        if (this._RoleName === 'ClientView' || this._RoleName === 'ContentView') {
            this._ViewClientRoleAccess = true;
        }
    }

    filterDropdown() {
        this.filterArr = [
            { name: "Credit cards", value: "Creditcards", checked: false },
            { name: "Student loan", value: "Studentloan", checked: false },
            { name: "Auto loan", value: "Autoloan", checked: false },
            { name: "Personal loan", value: "'Personalloan", checked: false },
            { name: "Deposits", value: "Deposits", checked: false },
            { name: "Mortgage", value: "Mortgage", checked: false },
            { name: "Home", value: "Home", checked: false },
            { name: "Improvement", value: "Improvement", checked: false },
            { name: "Investment", value: "Investment", checked: false },
            { name: "Auto Insurance", value: "AutoInsurance", checked: false },
            { name: "Refinance student", value: "Refinancestudent", checked: false },
            { name: "Refinance auto loan", value: "Refinance auto loan", checked: false },
            { name: "Savings Account", value: "SavingsAccount", checked: false },
            { name: "IRA", value: "IRA", checked: false }
        ];
    }

    onClickedOutside(e: any) {
        this.toggle = false;
    }

    selectFilterOption(details: any) {
        this.isHide = false

        if (details.checked == true) {
            this.chageFilterInput = true
            this.selectedValues.push(details)

        }
        else {
            this.selectedValues.forEach((value: any, id: any) => {
                if (value.name == details.name) {
                    this.selectedValues.splice(id, 1)
                }
                this.chageFilterInput = true
            });
        }

        this.totalSelectedValues = this.selectedValues.length

        if (this.totalSelectedValues === 0) {
            this.chageFilterInput = false
            this.isHide = true
        }

        this.GetBankDashBordData(this.cluster, this.selectedValues)
    }

    dropSkill(index: any) {
        this.chageFilterInput = true

        let indexMain = this.filterArr.indexOf(index);

        const dataMain = this.filterArr.filter(function (x: any) { return x.name === index.name })[0];

        dataMain.checked = false;

        this.filterArr[indexMain] = dataMain

        this.selectedValues.forEach((value: any, id: any) => {
            if (value.name == index.name) {
                this.selectedValues.splice(id, 1)
            }
            this.chageFilterInput = true
        });

        this.totalSelectedValues = this.selectedValues.length

        if (this.totalSelectedValues === 0) {
            this.chageFilterInput = false
            this.isHide = true
        }

        this.GetBankDashBordData(this.cluster, this.selectedValues)
    }

    clickEvent() {
        this.selectedFieldOutline = !this.selectedFieldOutline
        this.toggle = !this.toggle
    }

    changeStyle() {
        this.greyFieldOutline = !this.greyFieldOutline
    }

    getBDDataForBubbles() {
        const data = {
            Cumsomerid: 1
        }

        this.clusterPersonaService.getBDDataForBubbles(data).subscribe((res: any) => {

            if (res.Status.toUpperCase() == "SUCCESS") {

                this.BDDataForBubbles = res.Data;

                this.GetBankDashBordData(this.cluster, this.category);
                this.DisplayPie();
                this.bublefirst = [];
                this.bubleSecond = [];
                this.bublethird = [];

                for (var i = 0; i < this.BDDataForBubbles.length; i++) {
                    if (this.BDDataForBubbles[i].Segment.toUpperCase() === '#3CD378') {
                        this.SegmentList.push(this.BDDataForBubbles[i].Products);

                        this.bublefirst.push({ x: this.BDDataForBubbles[i].XVal, y: this.BDDataForBubbles[i].YVal, z: this.BDDataForBubbles[i].NoOfUsers, name: 'CC', Product: this.BDDataForBubbles[i].Products, color: this.BDDataForBubbles[i].Color });
                    }
                    else if (this.BDDataForBubbles[i].Segment.toUpperCase() === '#D33B7F') {
                        this.bubleSecond.push({ x: this.BDDataForBubbles[i].XVal, y: this.BDDataForBubbles[i].YVal, z: this.BDDataForBubbles[i].NoOfUsers, name: 'CC', Product: this.BDDataForBubbles[i].Products, color: this.BDDataForBubbles[i].Color });
                    }
                    else if (this.BDDataForBubbles[i].Segment.toUpperCase() === '#EF7F65') {
                        this.bublethird.push({ x: this.BDDataForBubbles[i].XVal, y: this.BDDataForBubbles[i].YVal, z: this.BDDataForBubbles[i].NoOfUsers, name: 'CC', Product: this.BDDataForBubbles[i].Products, color: this.BDDataForBubbles[i].Color });
                    }
                }

                this.bubblefirst();
                this.bubbleSecond();
                this.bubblethird();
            }
            else if (res.Error) {
                this.popupImg = "../../../assets/images/CommonError.png";
                this.popupContent = "Oops!";
                this.popupContentError = res.Error;

                this.triggerModal(this.modalWarningAndInfo)
            }
        })
    }

    fetDataList() {
        this.filteredData = [
            { name: "Credit cards", value: "Creditcards" },
            { name: "Student loan", value: "Studentloan" },
            { name: "Auto loan", value: "Autoloan" },
            { name: "'Personal loan", value: "'Personalloan" },
            { name: "Deposits", value: "Deposits" },
            { name: "Mortgage", value: "Mortgage" },
            { name: "Home", value: "Home" },
            { name: "Improvement", value: "Improvement" },
            { name: "Investment", value: "Investment" },
            { name: "Auto Insurance", value: "AutoInsurance" },
            { name: "Refinance student", value: "Refinancestudent" },
            { name: "Refinance auto loan", value: "Refinance auto loan" },
            { name: "Savings Account", value: "SavingsAccount" },
            { name: "IRA", value: "IRA" }
        ]
    }

    filterDataList(event: any) {

        let filtered: any[] = [];
        let query = event.query;
        for (let i = 0; i < this.filteredData.length; i++) {
            let data = this.filteredData[i];
            if (data.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                filtered.push(
                    {
                        name: data.name,
                        value: data.name
                    }
                );
            }
        }

        this.filteredDataName = filtered;
    }

    GetBankDashBordData(cluster: string, category: string) {
        this.categoryArr = this.selectedValues

        category = "";

        for (var i = 0; i < this.categoryArr.length; i++) {
            if (category === "") {
                category = this.categoryArr[i].name;
            }
            else {
                category = category + "," + this.categoryArr[i].name;
            }
        }

        const data = {
            Cumsomerid: 1,
            Cluster: cluster,
            category: category
        }

        this.clusterPersonaService.getBankDashBordData(data).subscribe((res: any) => {

            if (res.Status.toUpperCase() == "SUCCESS") {
                this.BankDashboardData = res.Data;
                this.totalUser = 0;

                for (var i = 0; i < this.BankDashboardData.length; i++) {
                    this._BehaviorIndex = this.BankDashboardData[0].BehaviorIndex;
                    this._Users = this.BankDashboardData[0].Users;
                    this._ConversionIndex = this.BankDashboardData[0].ConversionIndex;

                    this.totalUser = this.totalUser + this.BankDashboardData[i].NoOfUsers;
                }

                if (cluster.toUpperCase() === '#3CD378') {
                    this.BgColor = "BgColorG";
                    this.BgColorlable = "BgColorLableG"
                    this._bgColorName = "Green"
                }

                else if (cluster.toUpperCase() === '#D33B7F') {
                    this.BgColor = "BgColorR";
                    this.BgColorlable = "BgColorLableR"
                    this._bgColorName = "Red"
                }
                else {
                    this.BgColor = "BgColorP";
                    this.BgColorlable = "BgColorLableP"
                    this._bgColorName = "Orange"
                }
            }
            else if (res.Error) {

                this.popupImg = "../../../assets/images/CommonError.png";
                this.popupContent = "Oops!";
                this.popupContentError = res.Error;

                this.triggerModal(this.modalWarningAndInfo)
            }
        })
    }

    ExportBDData(cluster: string, category: string) {

        this.categoryArr = this.selectedValues

        category = "";

        for (var i = 0; i < this.categoryArr.length; i++) {
            if (category === "") {
                category = this.categoryArr[i].name;
            }
            else {
                category = category + "," + this.categoryArr[i].name;
            }
        }

        const data = {
            Cumsomerid: 1,
            Cluster: cluster,
            category: category
        }

        this.clusterPersonaService.exportBDData(data).subscribe((res: any) => {

            if (res.Status.toUpperCase() == "SUCCESS") {
                var status = res.status;
                const headers = res.headers;
                const data = res.Data;
                var contentType = 'application/CSV';
                var linkElement = document.createElement('a');
                var blob = new Blob([data], { type: contentType });
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                a.download = 'Cluster.csv';

                document.body.appendChild(a);
                a.click();

                this.popupImg = "../../../assets/images/download-popup.png";
                this.popupContent = res.Message;
                this.popupContentError = "";

                this.triggerModal(this.modalWarningAndInfo);

            } else if (res.Error) {
                this.popupImg = "../../../assets/images/download-popup-error.png";
                this.popupContent = "Oops!";
                this.popupContentError = res.Error;

                this.triggerModal(this.modalWarningAndInfo);
            }
        })
    }

    MainPieData() {
        this.options = {
            chart: {
                renderTo: 'container',
                type: 'pie',
                plotBackgroundColor: '#1e1d2f',
                plotBorderWidth: 0,
                plotShadow: false,
                backgroundColor: '#1e1d2f'
            },

            credits: {
                enabled: false
            },
            exporting: { enabled: false },
            title: {
                text: ''
            },
            yAxis: {
                title: {
                    text: ''
                }
            },

            plotOptions: {

                series: {
                    animation: false,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    shadow: true
                },
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    slicedOffset: 0,
                    shadow: false,


                    borderColor: 'grey',
                    borderwidth: 10,
                    showInLegend: false
                }
            },

            tooltip: {
                enabled: false,
                borderRadius: 30
            },

            series: [
                {
                    name: 'outerPie',

                    states: {
                        hover: {
                            enabled: true
                        }
                    },
                    allowPointSelect: true,

                    point: {
                        events: {

                            click: (event: any) => {
                                this.GetBankDashBordData(this.cluster, this.category);
                            }
                        }
                    },

                    data: [

                        {
                            y: 0.01,
                            SliceName: 'Gap2',
                            Segment: ''
                        },

                        {
                            y: 33.32,
                            SliceName: 'Firefox',
                            Segment: '#3cd378'
                        },
                        {
                            y: 0.01,
                            SliceName: 'Gap1',
                            Segment: ''
                        },

                        {
                            y: 33.32,
                            SliceName: 'MSIE',
                            Segment: '#ef7f65'
                        },

                        {
                            y: 0.01,
                            SliceName: 'Gap3',
                            Segment: ''
                        },

                        {
                            y: 33.32,
                            SliceName: 'Chrome',
                            Segment: '#d33b7f'
                        }
                    ],
                    size: '93%',
                    innerSize: '5%',
                    showInLegend: false,
                    dataLabels: {
                        enabled: false
                    }
                },
                {
                    name: 'innerPie',
                    colorByPoint: false,
                    type: 'pie',

                    states: {
                        hover: {
                            enabled: true
                        }

                    },
                    allowPointSelect: true,
                    point: {
                        events: {
                            click: (event: any) => {


                                this.cluster = event.point.Segment;
                                this.GetBankDashBordData(this.cluster, this.category);


                                let arrydata = this.options.series[1].data.filter(function (x: any) { return x.Segment === event.point.Segment })[0];

                                let indexMain = this.options.series[1].data.indexOf(arrydata);

                                let tempData = {
                                    y: arrydata.y,
                                    SliceName: arrydata.SliceName,
                                    Segment: arrydata.Segment,
                                    Selected: true
                                }

                                this.options.series[1].data[indexMain] = tempData;
                            }
                        }
                    },

                    events: {
                        mouseOut: (event: any) => {
                            var serie = event.target.points;
                            $.each(serie, function (i, e) {
                                this.graphic.attr({
                                    fill: '#33324f'
                                });

                                if (!this.selected)
                                    this.graphic.attr({
                                        fill: '#1e1d2f'
                                    });
                            });
                        },

                        afterAnimate: (event: any) => {
                            var serie = event.target.points;
                            $.each(serie, function (i, e) {
                                this.graphic.attr({
                                    fill: '#33324f'
                                });

                                if (!this.selected)
                                    this.graphic.attr({
                                        fill: '#1e1d2f'
                                    });
                            });
                        }
                    },

                    data: [
                        {
                            y: 0.01,
                            SliceName: 'Gap1',
                            Segment: '',
                            selected: false,
                            sliced: false
                        },

                        {
                            y: 33.32,
                            SliceName: 'Firefox',
                            Segment: '#3cd378',
                            selected: false,
                            sliced: false
                        },
                        {
                            y: 0.01,
                            SliceName: 'Gap2',
                            Segment: '',
                            selected: false,
                            sliced: false
                        },

                        {
                            y: 33.32,
                            SliceName: 'MSIE',
                            Segment: '#ef7f65',
                            selected: false,
                            sliced: false
                        },

                        {
                            y: 0.01,
                            SliceName: 'Gap3',
                            Segment: '',
                            selected: false,
                            sliced: false
                        },

                        {
                            y: 33.32,
                            SliceName: 'Chrome',
                            Segment: '#d33b7f',
                            selected: false,
                            sliced: false
                        }

                    ],

                    size: '90%',
                    innerSize: '0%',
                    showInLegend: false,
                    dataLabels: {
                        enabled: false
                    }
                }
            ],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal'
                        }
                    }
                }]
            }
        }

        this.options1 = {
            chart: {
                renderTo: 'bubble1',
                backgroundColor: 'transparent',
                type: 'bubble',
                allowPointSelect: true,
                plotBorderWidth: 0,
                zoomType: 'xy',
                events: {
                    click: (event: any) => {


                        var MainClusterColor = "#3CD378";
                        this.cluster = "#3CD378";
                        this.GetBankDashBordData(this.cluster, this.category);

                        let arrydata = this.options.series[1].data.filter(function (x: any) { return x.Segment == MainClusterColor })[0];

                        let indexMain = this.options.series[1].data.indexOf(arrydata);

                        let tempData = {
                            y: arrydata.y,
                            SliceName: arrydata.SliceName,
                            Segment: arrydata.Segment,
                            Selected: true
                        }

                        this.options.series[1].data[indexMain] = tempData;
                    }
                }
            },
            credits: {
                enabled: false
            },
            exporting: { enabled: false },
            legend: {
                enabled: false,
                bubbleLegend: {
                    minSize: 10,
                    maxSize: 10,
                }
            },

            title: {
                text: ''
            },

            subtitle: {
                text: ''
            },

            xAxis: {
                gridLineWidth: 0,
                title: {
                    text: ''
                },
                labels: {
                    format: '{value} gr'
                },

                plotLines: [{
                    color: 'white',
                    dashStyle: 'dot',
                    width: 2,
                    value: 65,
                    label: {
                        rotation: 0,
                        y: 15,
                        style: {
                            fontStyle: 'italic'
                        },
                        text: ''
                    },
                    zIndex: 3
                }],
                visible: false
            },

            yAxis: {
                gridLineWidth: 0,
                startOnTick: false,
                endOnTick: false,
                title: {
                    text: ''
                },
                labels: {
                    format: '{value} gr'
                },

                maxPadding: 0.2,
                plotLines: [{
                    color: 'white',
                    dashStyle: 'dot',
                    width: 2,
                    value: 50,
                    label: {
                        align: 'right',
                        style: {
                            fontStyle: 'italic'
                        },
                        text: '',
                        x: -10
                    },
                    zIndex: 3
                }],
                visible: false
            },
            tooltip: {
                useHTML: true,
                backgroundColor: 'var(--tooltipbgColor)',
                borderWidth: 1,
                borderRadius: 45,
                borderColor: 'var(--tooltipbrColor)',
                className: 'MainTooltipContainer',
                shadow: false,
                headerFormat: '<table class="customTooltip">',
                pointFormat: '<tr><th colspan="1" style="padding-top:11px; padding-left:14px; padding-right:14px;"><h6 style="font-size:var(--Cluster-tooltip-font);font-family:Lato-Regular;text-align: center">{point.Product}</h6></th></tr>' +
                    '<tr><th style="padding-bottom: 30px;"><h6 style="font-size:var(--Cluster-tooltip-font);font-family:Lato-Regular;text-align: center">Users: {point.z:.0f}</h6></td></tr>',
                footerFormat: '</table>',
                followPointer: true,
                style: {
                    padding: 0
                }
            },

            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false,
                        format: '{point.name}'
                    }
                }
            },

            series: [{
                data: this.bublefirst,
                marker: {
                    fillOpacity: 2
                }
            }]

        }
    }

    DisplayPie() {
        Highcharts.setOptions({
            colors: ['#1e1d2f', '#3CD378', '#33324f', '#EF7F65', '#33324f', '#D33B7F']
        });


        let chart = new Highcharts.Chart(this.options);
    }

    bubblefirst() {

        this.options1 = {
            chart: {
                renderTo: 'bubble1',
                backgroundColor: 'transparent',
                type: 'bubble',
                allowPointSelect: true,
                plotBorderWidth: 0,
                zoomType: 'xy',
                events: {
                    click: (event: any) => {


                        var MainClusterColor = "#3cd378";
                        this.cluster = "#3cd378";
                        this.GetBankDashBordData(this.cluster, this.category);

                        this.MainPieData();
                        let arrydata = this.options.series[1].data.filter(function (x: any) { return x.Segment == MainClusterColor })[0];

                        let indexMain = this.options.series[1].data.indexOf(arrydata);

                        let tempData = {
                            y: arrydata.y,
                            SliceName: arrydata.SliceName,
                            Segment: arrydata.Segment,
                            selected: true,
                            sliced: true
                        }

                        this.options.series[1].data[indexMain] = tempData;
                    }
                }
            },
            credits: {
                enabled: false
            },
            exporting: { enabled: false },
            legend: {
                enabled: false,
                bubbleLegend: {
                    minSize: 20,
                    maxSize: 40,
                }
            },

            title: {
                text: ''
            },

            subtitle: {
                text: ''
            },

            xAxis: {
                gridLineWidth: 0,
                title: {
                    text: ''
                },
                labels: {
                    format: '{value} gr'
                },

                plotLines: [{
                    color: 'white',
                    dashStyle: 'dot',
                    width: 2,
                    value: 65,
                    label: {
                        rotation: 0,
                        y: 15,
                        style: {
                            fontStyle: 'italic'
                        },
                        text: ''
                    },
                    zIndex: 3
                }],
                visible: false
            },

            yAxis: {
                gridLineWidth: 0,
                startOnTick: false,
                endOnTick: false,
                title: {
                    text: ''
                },
                labels: {
                    format: '{value} gr'
                },

                maxPadding: 0.2,
                plotLines: [{
                    color: 'white',
                    dashStyle: 'dot',
                    width: 2,
                    value: 50,
                    label: {
                        align: 'right',
                        style: {
                            fontStyle: 'italic'
                        },
                        text: '',
                        x: -10
                    },
                    zIndex: 3
                }],
                visible: false
            },
            tooltip: {
                useHTML: true,
                backgroundColor: 'var(--tooltipbgColor)',
                borderWidth: 1,
                borderRadius: 45,
                borderColor: 'var(--tooltipbrColor)',
                className: 'MainTooltipContainer',
                shadow: false,
                headerFormat: '<table class="customTooltip" style="width: 147.8px;">',
                pointFormat: '<tr><th colspan="1" style="padding-top:11px; padding-left:14px; padding-right:14px;"><h6 style="font-size:var(--Cluster-tooltip-font);font-family:Lato-Regular;text-align: center">{point.Product}</h6></th></tr>' +
                    '<tr><th><h6 style="font-size:var(--Cluster-tooltip-font);font-family:Lato-Regular;text-align: center">Users: {point.z:.0f}</h6></td></tr>',
                footerFormat: '</table>',
                followPointer: true,
                style: {
                    color: 'white',
                    padding: 17,
                    fontSize: '40px'
                }
            },

            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false,
                        format: '{point.name}'
                    }
                }
            },

            series: [{
                data: this.bublefirst,
                marker: {
                    fillOpacity: 2
                }
            }]

        }
        Highcharts.chart('bubble1', this.options1);
    };

    bubbleSecond() {

        this.options2 = {

            chart: {
                renderTo: 'bubble2',
                backgroundColor: 'transparent',
                type: 'bubble',
                plotBorderWidth: 0,
                zoomType: 'xy',
                events: {
                    click: (event: any) => {


                        var MainClusterColor = "#d33b7f";
                        this.cluster = "#d33b7f";
                        this.GetBankDashBordData(this.cluster, this.category);

                        this.MainPieData();
                        let arrydata = this.options.series[1].data.filter(function (x: any) { return x.Segment == MainClusterColor })[0];

                        let indexMain = this.options.series[1].data.indexOf(arrydata);

                        let tempData = {
                            y: arrydata.y,
                            SliceName: arrydata.SliceName,
                            Segment: arrydata.Segment,
                            selected: true,
                            sliced: true
                        }

                        this.options.series[1].data[indexMain] = tempData;
                    }
                }
            },
            credits: {
                enabled: false
            },
            exporting: { enabled: false },
            legend: {
                enabled: false
            },

            title: {
                text: ''
            },

            subtitle: {
                text: ''
            },

            xAxis: {
                gridLineWidth: 0,
                title: {
                    text: ''
                },
                labels: {
                    format: '{value} gr'
                },

                plotLines: [{
                    color: 'white',
                    dashStyle: 'dot',
                    width: 2,
                    value: 65,
                    label: {
                        rotation: 0,
                        y: 15,
                        style: {
                            fontStyle: 'italic'
                        },
                        text: ''
                    },
                    zIndex: 3
                }],
                visible: false
            },

            yAxis: {
                gridLineWidth: 0,
                startOnTick: false,
                endOnTick: false,
                title: {
                    text: ''
                },
                labels: {
                    format: '{value} gr'
                },
                maxPadding: 0.2,
                plotLines: [{
                    color: 'white',
                    dashStyle: 'dot',
                    width: 2,
                    value: 50,
                    label: {
                        align: 'right',
                        style: {
                            fontStyle: 'italic'
                        },
                        text: '',
                        x: -10
                    },
                    zIndex: 3
                }],
                visible: false
            },
            tooltip: {
                useHTML: true,
                backgroundColor: 'var(--tooltipbgColor)',
                borderWidth: 1,
                borderRadius: 45,
                borderColor: 'var(--tooltipbrColor)',
                className: 'MainTooltipContainer',
                shadow: false,
                headerFormat: '<table class="customTooltip" style="width: 147.8px; border-radius: 18px;">',
                pointFormat: '<tr><th colspan="2" style="padding-top:11px; padding-left:14px; padding-right:14px;"><h6 style="font-size:var(--Cluster-tooltip-font);font-family:Lato-Regular;text-align: center">{point.Product}</h6></th></tr>' +
                    '<tr><th><h6 style="font-size:var(--Cluster-tooltip-font);font-family:Lato-Regular;text-align: center">Users: {point.z:.0f}</h6></td></tr>',
                footerFormat: '</table>',
                followPointer: true,
                style: {
                    color: 'white',
                    padding: 17,
                    fontSize: 16
                }
            },

            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false,
                        format: '{point.name}'
                    }
                }
            },

            series: [{
                data: this.bubleSecond,
                marker: {
                    fillOpacity: 3.0
                }
            }]

        }

        Highcharts.chart('bubble2', this.options2);
    };

    bubblethird() {
        this.options3 = {

            chart: {
                renderTo: 'bubble3',
                backgroundColor: 'transparent',
                type: 'bubble',
                plotBorderWidth: 0,
                zoomType: 'xy',
                events: {
                    click: (event: any) => {


                        var MainClusterColor = "#ef7f65";
                        this.cluster = "#ef7f65";
                        this.GetBankDashBordData(this.cluster, this.category);

                        this.MainPieData();
                        let arrydata = this.options.series[1].data.filter(function (x: any) { return x.Segment == MainClusterColor })[0];

                        let indexMain = this.options.series[1].data.indexOf(arrydata);

                        let tempData = {
                            y: arrydata.y,
                            SliceName: arrydata.SliceName,
                            Segment: arrydata.Segment,
                            Selected: true,
                            Sliced: true
                        }
                        this.options.series[1].data[indexMain] = tempData;
                    }
                }
            },
            credits: {
                enabled: false
            },
            exporting: { enabled: false },
            legend: {
                enabled: false
            },

            title: {
                text: ''
            },

            subtitle: {
                text: ''
            },

            xAxis: {
                gridLineWidth: 0,
                title: {
                    text: ''
                },
                labels: {
                    format: '{value} gr'
                },
                plotLines: [{
                    color: 'white',
                    dashStyle: 'dot',
                    width: 2,
                    value: 65,
                    label: {
                        rotation: 0,
                        y: 15,
                        style: {
                            fontStyle: 'italic'
                        },
                        text: ''
                    },
                    zIndex: 3
                }],
                visible: false
            },

            yAxis: {
                gridLineWidth: 0,
                startOnTick: false,
                endOnTick: false,
                title: {
                    text: ''
                },
                labels: {
                    format: '{value} gr'
                },

                maxPadding: 0.2,
                plotLines: [{
                    color: 'white',
                    dashStyle: 'dot',
                    width: 2,
                    value: 50,
                    label: {
                        align: 'right',
                        style: {
                            fontStyle: 'italic'
                        },
                        text: '',
                        x: -10
                    },
                    zIndex: 3
                }],
                visible: false
            },
            tooltip: {
                useHTML: true,
                backgroundColor: 'var(--tooltipbgColor)',
                borderWidth: 1,
                borderRadius: 45,
                borderColor: 'var(--tooltipbrColor)',
                className: 'MainTooltipContainer',
                shadow: false,
                headerFormat: '<table class="customTooltip" style="width: 147.8px; border-radius: 18px !important;">',
                pointFormat: '<tr><th colspan="2" style="padding-top:11px; padding-left:14px; padding-right:14px;"><h6 style="font-size:var(--Cluster-tooltip-font);font-family:Lato-Regular;text-align: center">{point.Product}</h6></th></tr>' +
                    '<tr><th><h6 style="font-size:var(--Cluster-tooltip-font);font-family:Lato-Regular; text-align: center">Users: {point.z:.0f}</h6></td></tr>',
                footerFormat: '</table>',
                followPointer: true,
                style: {
                    color: 'white',
                    padding: 17,
                    fontSize: 16
                }
            },

            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false,
                        format: '{point.name}'
                    }
                }
            },

            series: [{
                data: this.bublethird,
                marker: {
                    fillOpacity: 2
                }

            }]

        }

        Highcharts.chart('bubble3', this.options3);
    };

    triggerModal(content: any) {

        this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
            this.closeModal = `Closed with: ${res}`;
        }, (res) => {
            this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
}