import { Component, ElementRef, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import * as $ from 'jquery'
import * as signalR from '@microsoft/signalr';

import { TestBotService } from '../../services/TestBot/test-bot.service'
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { WebcamImage, WebcamInitError } from 'ngx-webcam';


import { EventEmitter } from '@angular/core';

import { interval as observableInterval, Subject } from "rxjs";
import { takeWhile, scan, tap } from "rxjs/operators";
import { Observable } from 'rxjs';
import { animate, animation, keyframes, style, transition, trigger, useAnimation } from '@angular/animations';
import { transform } from 'typescript';
import { ChangeDetectorRef } from '@angular/core';

import {
  PlaidErrorMetadata,
  PlaidErrorObject,
  PlaidEventMetadata,
  PlaidOnEventArgs,
  PlaidOnExitArgs,
  PlaidOnSuccessArgs,
  PlaidSuccessMetadata,
  PlaidConfig,
  NgxPlaidLinkService,
  PlaidLinkHandler
} from "ngx-plaid-link";

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css'],
  animations: [

  ],
  host: {
    '(document:click)': 'onClick($event)',
  }
})

export class ChatbotComponent implements OnInit {
  @Input() inputVariable: any;

  _isShowChat: any

  _RoleName: any

  @Output() inputdata: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('container', { read: ElementRef }) public scrollcontainer: ElementRef<any>;
  @ViewChild("ratingModal", { static: false }) ratingModal: ElementRef;
  @ViewChild("userHtml", { static: false }) userHtml: any;

  @ViewChild('bbbbRelatedIntent', { read: ElementRef }) public bbbbRelatedIntent: ElementRef<any>;

  @ViewChild('loadingSpan') loadingSpan: ElementRef;

  @ViewChild('pp', { read: ElementRef }) public pp: ElementRef<any>;

  _configUrls: any

  _PlaidToken: any

  private plaidLinkHandler: PlaidLinkHandler;

  constructor(public testBotService: TestBotService, public sanitizer: DomSanitizer, private httpClient: HttpClient, private ref: ChangeDetectorRef, private el: ElementRef, private plaidLinkService: NgxPlaidLinkService) {
    const AccessToken = window.localStorage.getItem("AccessToken");

    this._configUrls = {
      Token: AccessToken
    }
  }

  _Conversation: any = []
  _DisableTextMsg: any
  _txtplaceholder: any

  _BondUserName: any
  _SessionIdValue: any
  _MessageType: any

  _BondAccessToken: any
  _LangCode: any = 'EN'
  _UserId: any
  _UserType: any
  _RequestedBot: any = ""

  _UserName: any
  _AccessToken: any
  _RandomAccessToken: any

  connection: any

  _APIUrl: any

  _DisableGreetMsgs: any

  RelativeQue1: any = ""
  RelativeQue2: any = ""
  RelativeQue3: any = ""

  x1 = document.getElementById('relativeQue1');
  x2 = document.getElementById('relativeQue2');
  x3 = document.getElementById('relativeQue3');

  checked: boolean;

  UserMessage: string
  InsightsList: any

  loader: boolean = false;


  IsHistoryRI = false;
  _postLinkArray: any = []
  _preLinkArray: any = []

  _LinkArray: any = [];
  _ListArray: any = [];
  _TextArray: any = []

  IsthumbsON: boolean = true;
  emojiId: any;
  BlurCloseEmojiID: any
  _CloseDivFlag: boolean

  _BotBackgroundWebSite: any

  scrollTopValue: number;
  scrollHeight: number;
  fetchScrollHeight: number
  checkScrollHeight: boolean = false

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  closeModal: any
  popupContentError: string

  _LTI: boolean = true;
  _SampleTextData: any = [];

  featuredImage: boolean = true;
  _gradStartColor: any;
  _gradEndColor: any;

  username: any;

  _EnableUserRating: boolean = false;
  ShowRating: boolean = false;
  HideRating: boolean = false;
  ShowChat: boolean = true;
  UserPrevRating: any;
  _ratingval = localStorage.getItem("SelectedRating")
  _isfeedbackPopup: boolean = false
  _isratingpopup: boolean = true
  _numberOfStars: number

  _RestartConn: boolean
  _setinterval: any

  _pageName: any
  _libraryInputData: any

  _TTS: boolean = false;
  _EnableWebCam = false;
  _IsDisableSendBtn = true;
  _RT = true;

  _SigningURL: any
  _AL: any
  _AOURls: any
  _IsConsentURLDisabled: boolean = false;

  _IsHavingChats: boolean = false;

  _formdata = new FormData();
  _fileData: any = []
  _FileName: any

  _webcamImage: WebcamImage

  _trigger: Subject<void> = new Subject<void>();

  _MXAccLink: any

  mxConnect: any

  TransPropUserMsg: any

  _showPanelBody: boolean = false;
  _showLoaderBlock: boolean = true;
  _showLoaderImage: boolean = false

  _setloaderposition: boolean = true

  _dynamicRelatedIntentDivHeight: boolean = false
  _isRelativeQueEmpty: boolean = false

  ngOnInit(): void {


    this.TransPropUserMsg = "translate3d(0, 10px, 0)";

    this._setinterval = setInterval(() => {
      this.checkInternetConn()
    }, 1000);

    this.fetchInputPersona();

    this.GetBotGradientColor()

    this._RoleName = window.localStorage.getItem("RoleName");

    this._APIUrl = "http://3.211.109.143:2794/"

    this.ShowChat = this.inputVariable.isShowChat;

    if (this._RoleName == null) {
      this._RoleName = 'User'
    }

    if (this._RoleName.toUpperCase() === "AGENT")
      this.BondFunction('Agent');
    else
      this.BondFunction('User');


    this.connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(this._APIUrl + "BondHub")
      .build();

    this.connection.serverTimeoutInMilliseconds = 1800000;

    if (this._RoleName.toUpperCase() === "MARKETING") {

      this.getDataForMarketUser();
    }
    else {

      this.connection.start().then((res: any) => {
        try {

          if (this._APIUrl === "" || this._APIUrl === null || this._APIUrl === undefined)

            if (this._LangCode === "" || this._LangCode === null || this._LangCode === undefined) {
              this._LangCode = 'EN';
            }

          this.CheckIn(this._MessageType);

          this._Conversation = [];
        }
        catch (e) {
          this._DisableTextMsg = true;
          this._txtplaceholder = "Connecting..";
        }
      });

      setTimeout(() => {
        this._showLoaderImage = true
      }, 1000);

      this.connection.on("AddMessage", (ChatMessageObject: any) => {
        debugger

        this._LTI = true;
        this.IsHistoryRI = false;
        this.loader = false

        this._showPanelBody = true;
        this._showLoaderBlock = false

        function Urlify(text: any) {
          try {
            var urlRegex = /(https?:\/\/[^\s]+)/g;
            if (urlRegex.test(text)) { return 1; }
            else { return 0; }
          }
          catch (e) {

          }
          throw new Error('Function not implemented.');
        }

        if (ChatMessageObject.messageType === "Bot") {

          var data = {
            UserName: this._BondUserName,
            SessionId: this._SessionIdValue,
            MessageType: "",
            AccessToken: this._BondAccessToken,
            LangCode: this._LangCode,
            UserId: this._UserId,
            UserType: this._UserType,
            RequestedBot: this._RequestedBot
          };

          this.connection.invoke("MessageDeliveryAck", data);

          if (ChatMessageObject.responseType === "URL") {

            var UrlArray = ChatMessageObject.displayMessage.split('~|~');

            this._MXAccLink = UrlArray[1].trim();

            this._LinkArray = [];

            var linkText = UrlArray[0].split('~');

            var cnt = 1;

            for (var i = 0; i < linkText.length; i++) {

              if (i % 2 !== 0) {

                var key = linkText[i].trim();

                var value = UrlArray[cnt].trim();

                var containsURL = Urlify(value);

                this._LinkArray.push({
                  "key": key,
                  "value": value,
                  "containsURL": containsURL
                });

                cnt++;
              }
              else {
                containsURL = 0;

                key = linkText[i];

                value = key;

                this._LinkArray.push({
                  "key": key,
                  "value": value,
                  "containsURL": containsURL
                });
              }
            }
          }

          if (ChatMessageObject.preMsgType === "URL") {

            var preUrlArray = ChatMessageObject.preDispMessage.split('~|~');

            this._preLinkArray = [];

            var preLinkText = preUrlArray[0].split('~');

            var preCount = 1;

            for (var preIndex = 0; preIndex < preLinkText.length; preIndex++) {

              if (preIndex % 2 !== 0) {

                var preKey = preLinkText[preIndex].trim();

                var preValue = preUrlArray[preCount].trim();

                var preIsURL = Urlify(preValue);

                this._preLinkArray.push({
                  "preKey": preKey,
                  "preValue": preValue,
                  "preIsURL": preIsURL
                });

                preCount++;
              }
              else {
                containsURL = 0;

                preValue = key;

                this._preLinkArray.push({
                  "preKey": preKey,
                  "preValue": preValue,
                });
              }
            }
          }

          if (ChatMessageObject.postMsgType === "URL") {

            var postUrlArray = ChatMessageObject.postDispMessage.split('~|~');

            this._postLinkArray = [];

            var postLinkText = preUrlArray[0].split('~');

            var postCount = 1;

            for (var postIndex = 0; postIndex < postLinkText.length; postIndex++) {

              if (postIndex % 2 !== 0) {

                var postKey = postLinkText[postIndex].trim();

                var postValue = postUrlArray[postCount].trim();

                var postIsURL = Urlify(postValue);

                this._postLinkArray.push({
                  "postKey": postKey,
                  "postValue": postValue,
                  "postIsURL": postIsURL
                });
                postCount++;
              }
              else {
                containsURL = 0;

                postKey = linkText[postIndex];

                postValue = key;

                this._postLinkArray.push({
                  "postKey": postKey,
                  "postValue": postKey,
                });
              }
            }
          }

          if (ChatMessageObject.responseType === "List") {
            debugger
            var UrlArray = ChatMessageObject.displayMessage.split('~|~');

            // this._MXAccLink = UrlArray[1].trim();

            this._ListArray = [];

            var linkText = UrlArray[0].split('~');

            var cnt = 1;

            for (var i = 0; i < linkText.length; i++) {

              if (i % 2 !== 0) {

                var key = linkText[i].trim();

                var value = UrlArray[cnt].trim();

                var containsURL = Urlify(value);

                this._ListArray.push({
                  "key": key,
                  "value": value,
                  "containsURL": containsURL
                });

                cnt++;
              }
              else {
                containsURL = 0;

                key = linkText[i];

                value = key;

                this._ListArray.push({
                  "key": key,
                  "value": value,
                  "containsURL": containsURL
                });
              }
            }
          }

          if (ChatMessageObject.responseType === "Text") {
            debugger
            var UrlArray = ChatMessageObject.displayMessage.split('~|~');

            // this._MXAccLink = UrlArray[1].trim();

            this._TextArray = [];

            var linkText = UrlArray[0].split('~');

            var cnt = 1;

            for (var i = 0; i < linkText.length; i++) {

              if (i % 2 !== 0) {

                var key = linkText[i].trim();

                var value = UrlArray[cnt].trim();

                var containsURL = Urlify(value);

                this._TextArray.push({
                  "key": key,
                  "value": value,
                  "containsURL": containsURL
                });

                cnt++;
              }
              else {
                containsURL = 0;

                key = linkText[i];

                value = key;

                this._TextArray.push({
                  "key": key,
                  "value": value,
                  "containsURL": containsURL
                });
              }
            }
          }

          var index = 0;
          if (this._Conversation != null) {
            index = this._Conversation.length > 0 ? this._Conversation[this._Conversation.length - 1].Index : this._Conversation.length;
          }

          var newMessage = {

            "SampleResponses": ChatMessageObject.sampleResponse,
            "MessageType": ChatMessageObject.messageType,

            "PostDispMessage": ChatMessageObject.postDispMessage,
            "PostMsgType": ChatMessageObject.postMsgType,

            "ResponseType": ChatMessageObject.responseType,
            "Message": ChatMessageObject.displayMessage,
            "LISTRespMsg": this._ListArray,
            "TEXTRespMsg": this._TextArray,

            "PreDispMessage": ChatMessageObject.preDispMessage,
            "PreMsgType": ChatMessageObject.preMsgType,

            "IsDisableTextbox": ChatMessageObject.isDisableTextbox,

            "ShowEmoji": ChatMessageObject.showEmoji,

            "URLRespMsg": this._LinkArray,
            "PreURLRespMsg": this._preLinkArray,
            "PostURLRespMsg": this._postLinkArray,
            "EnableFld": ChatMessageObject.enableFld,
            "InputFormat": ChatMessageObject.inputFormat,

            "IntentName": ChatMessageObject.intentName,
            "AgentTransferMsg": "",

            Index: index,

          };

          if ((newMessage.IsDisableTextbox === true && newMessage.PostMsgType !== "IMG") ||
            (ChatMessageObject.responseType === "List")) {

            if (newMessage.SampleResponses !== null) {
              this._SampleTextData = newMessage.SampleResponses.split(",");
              if (this._SampleTextData[0] === "" || this._SampleTextData[0] === 'undefined') {

                this._LTI = true;
              }
              else {
                this._LTI = false;
              }

            }
          }


          if (newMessage.IsDisableTextbox === true && ChatMessageObject.responseType === "List") {
            debugger
            this._DisableTextMsg = true;
            this._txtplaceholder = ""
          }
          else {
            this._DisableTextMsg = false;
            this._txtplaceholder = "Say Something..."
          }

          if (newMessage.InputFormat !== "") {
            this._txtplaceholder = newMessage.InputFormat;
          }
          else {
            $("#txtMessage").attr('maxlength', '200');
            this._txtplaceholder = "Please say something..";
          }

          if (newMessage.EnableFld === "FUPLOAD") {
            this._TTS = true;
            this._IsDisableSendBtn = true;
          }
          else
            this._TTS = false;

          if (newMessage.EnableFld === "Selfie") {
            this._EnableWebCam = true;
            this._TTS = true;
            this._IsDisableSendBtn = true;
          }
          else
            this._EnableWebCam = false;

          if ((newMessage.ResponseType === "URL") || (ChatMessageObject.responseType === "List") || (ChatMessageObject.responseType === "Text")) {

            this._RT = false;
            if (ChatMessageObject.slotName === "SigningComplete") {
              this._SigningURL = ChatMessageObject.message.split("~|~")[1].trim();
              this._AL = true;
            }
            else {
              this._SigningURL = null;
              this._AL = false;
            }

            if (ChatMessageObject.intentName === "AOACCOUNTOPENING")
              this._AOURls = true;
            else
              this._AOURls = false;
          }
          else {
            this._AL = false;
            this._AOURls = false;
            this._SigningURL = null;
          }

          if (ChatMessageObject.intentName.toUpperCase() === "AOACCOUNTOPENING" && ChatMessageObject.slotName.toUpperCase() !== "ACCOUNTTYPE") {
            var consentMessageIndex = index - 1;
            $("#botMessage_" + consentMessageIndex + " a").css({ "pointer-events": "none" });
            this._IsConsentURLDisabled = true;
          }

          if (newMessage.ResponseType === "GREET" && (localStorage.getItem("ConversationHistory") === null ||
            localStorage.getItem("ConversationHistory") === "null" || localStorage.getItem("ConversationHistory") === "")) {

            this._Conversation.push(newMessage);
            localStorage.setItem("ConversationHistory", JSON.stringify(this._Conversation));
          }
          else if (newMessage.ResponseType === "GREET" && localStorage.getItem("ConversationHistory") !== null
            && localStorage.getItem("ConversationHistory") !== "") {

            this.IsHistoryRI = true;
            var conhistory: any = []
            conhistory = localStorage.getItem("ConversationHistory")

            JSON.parse(conhistory).forEach((value: any, key: any) => {

              this._Conversation.push(value);
            });

            localStorage.setItem("ConversationHistory", JSON.stringify(this._Conversation));
debugger
            if (this._Conversation.length > 1) {

              if (this._Conversation.length == 4) {
                if (this._Conversation[0].ResponseType === 'GREET' &&
                  this._Conversation[1].responseType === 'RelatedIntent' &&
                  this._Conversation[2].responseType === 'RelatedIntent' &&
                  this._Conversation[3].responseType === 'RelatedIntent') {
                  this._EnableUserRating = false;
                }
                else
                  this._EnableUserRating = true;
              } else
                this._EnableUserRating = true;

              this._DisableGreetMsgs = true;
            }

            var lastConversationIndex = 0;

            this._Conversation.forEach((value: any, key: any) => {
              if (value.ResponseType !== "GREET" && value.responseType !== "RelatedIntent") {
                this._IsHavingChats = true;
                lastConversationIndex++;
              }
            });

            if ((this._Conversation.filter(function (x: any) { return x.Index === lastConversationIndex; })[0].ReponseType != "GREET" ||
              this._Conversation.filter(function (x: any) { return x.Index === lastConversationIndex; })[0].responseType != "RelatedIntent") &&
              lastConversationIndex > 0) {

              if (this._Conversation.filter(function (x: any) { return x.Index === lastConversationIndex; })[0].SampleResponses != null) {
                this._SampleTextData = this._Conversation.filter(function (x: any) { return x.Index === lastConversationIndex; })[0].SampleResponses.split(",");
                if (this._SampleTextData == "" || this._SampleTextData === 'undefined')
                  this._LTI = true;
                else
                  this._LTI = false;
              }
            }

            for (var i = 0; i <= this._Conversation.length; i++) {
              $("#botMessage_" + i).removeClass("disp-none").addClass("disp-contents");
              this.ref.detectChanges()
            }

            this.CheckChatBodyLoad()
          }
          else {
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].BotMessage = newMessage.Message;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].BotMessageType = newMessage.MessageType;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].BotResponseType = newMessage.ResponseType;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].SampleResponses = newMessage.SampleResponses;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].PostDispMessage = newMessage.PostDispMessage;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].PostMsgType = newMessage.PostMsgType;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].PreDispMessage = newMessage.PreDispMessage;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].PreMsgType = newMessage.PreMsgType;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].AgentTransferMsg = newMessage.AgentTransferMsg;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].IsDisabletxtbox = newMessage.IsDisableTextbox;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].ShowEmoji = newMessage.ShowEmoji;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].URLRespMsg = newMessage.URLRespMsg;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].PreURLRespMsg = newMessage.PreURLRespMsg;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].PostURLRespMsg = newMessage.PostURLRespMsg;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].Intentname = newMessage.IntentName;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].LISTRespMsg = newMessage.LISTRespMsg;
            this._Conversation.filter(function (x: any) { return x.Index === index; })[0].TEXTRespMsg = newMessage.TEXTRespMsg;

            localStorage.setItem("ConversationHistory", JSON.stringify(this._Conversation));

            if (newMessage.ResponseType !== "GREET" && newMessage.ResponseType !== "RelatedIntent") {
              this._IsHavingChats = true;
            }
          }


          this.ref.detectChanges()

          if (ChatMessageObject.relatedIntents !== null && this.IsHistoryRI == false) {
            if (ChatMessageObject.relatedIntents.length > 0) {

              this.DisplayRelatedIntent(ChatMessageObject.relatedIntents);

              this.AddRelatedIntentsToHistory(ChatMessageObject.relatedIntents);
            }
          }

          if (this._Conversation.length > 0) {
            var existingRelatedIntents = this._Conversation.filter(function (x: any) {
              return x.responseType === "RelatedIntent";
            });

            if (existingRelatedIntents.length > 0) {
              this.DisplayRelatedIntent(existingRelatedIntents);
            }
          }
          else {
            this.setLoaderPosition();
            this.loader = true;
          }
          this.ScrollChatBody()

        }

        this.headerBlurEffect();

        this.setRelatedIntentPosition();
      });

      this.connection.on("ReturnUserDetails", (userDetailsBotObj: any) => {

        this._BondUserName = userDetailsBotObj.userName;
        this._BondAccessToken = userDetailsBotObj.jwtToken;
        this._UserId = userDetailsBotObj.userId;
        this._SessionIdValue = userDetailsBotObj.sessionId;

        this._DisableTextMsg = false;
        this.ref.detectChanges()

        window.sessionStorage.setItem("BondAccessToken", this._BondAccessToken);
      });

      this.connection.onclose(() => {
        try {
          this._BondUserName = "";
          this._BondAccessToken = "";

          this.RestartConnection();

        } catch (e) {
        }
      });
    }
  }

  ngOnDestroy() {
    if (this._setinterval) {
      clearInterval(this._setinterval);
    }
  }

  IntervalId: any
  CheckChatBodyLoad() {


    this.IntervalId = setInterval(() => {
      this.ScrollChatBodyOnLoad()
      this.ScrollChatBody()
    }, 800);

  }

  closeInterval() {
    if (this.IntervalId) {
      clearInterval(this.IntervalId);
    }
  }

  RestartConnection() {
    try {

      this._DisableTextMsg = true;
      this._txtplaceholder = "Connecting...";

      if (this.connection.connection.connectionState !== 1) {
        this.connection.start().then(() => {

          this._UserName = sessionStorage.getItem("UserName");
          this._UserId = sessionStorage.getItem("UserId");
          this._AccessToken = sessionStorage.getItem("AccessToken");

          if (!this._AccessToken) {
            this._BondUserName = "FN" + this._SessionIdValue.substring(0, 10);
            this._BondAccessToken = this.CreateGuid();
            this._UserType = "Guest";
            this._UserId = this._BondUserName + "@bond.ai";
          }
          else {

            this._BondUserName = this._UserName;
            this._BondAccessToken = this._AccessToken;
            this._UserType = "User";
            this._UserId = this._UserId;
          }
          var data = {
            UserName: this._BondUserName,
            SessionId: this._SessionIdValue,
            MessageType: "User",
            AccessToken: this._BondAccessToken,
            LangCode: this._LangCode,
            UserId: this._UserId,
            UserType: this._UserType
          };

          this.connection.invoke("ReChatStart", data).then(() => {
            try {
              this._DisableTextMsg = false;
              this._txtplaceholder = "Say something...";
              this.ref.detectChanges()
            }
            catch (e) {
              this._DisableTextMsg = true;
              this._txtplaceholder = "Connecting...";
            }
          });

        });
      }
      else
        this._txtplaceholder = "Say Something...";
    } catch (e) {
      this._txtplaceholder = "Connecting...";
    }
  };

  AddRelatedIntentsToHistory(vRelatedIntents: any) {
    if (vRelatedIntents !== null) {
      var index = this._Conversation.length > 0 ? this._Conversation[this._Conversation.length - 1].Index + 1 : this._Conversation.length + 1;

      for (var i = 0; i < vRelatedIntents.length; i++) {

        var relatedIntent = {
          confidence: vRelatedIntents[i].confidence,
          displayMsg: vRelatedIntents[i].displayMsg,
          intentName: vRelatedIntents[i].intentName,
          query: vRelatedIntents[i].query,
          queryId: vRelatedIntents[i].queryId,
          responseType: "RelatedIntent",
          sectionName: vRelatedIntents[i].sectionName,
          Index: index
        };

        this._Conversation.push(relatedIntent);
        localStorage.setItem("ConversationHistory", JSON.stringify(this._Conversation));

        this.ref.detectChanges()

        index++;
      }
    }
  };

  _IsGuestUser: boolean = false;

  BondFunction(vMessageType: any) {
    try {

      this._MessageType = vMessageType;
      this._UserName = window.sessionStorage.getItem("UserName");
      this._UserId = window.sessionStorage.getItem("UserId");
      this._AccessToken = window.sessionStorage.getItem("AccessToken");
      this._SessionIdValue = window.sessionStorage.getItem("SessionId");

      this._RandomAccessToken = sessionStorage.getItem("RandomAccessToken");

      if (this._RandomAccessToken === null) {
        var randomAccessToken = this.CreateGuid();
        this._RandomAccessToken = randomAccessToken;
        sessionStorage.setItem("RandomAccessToken", this._RandomAccessToken);
        localStorage.setItem("ConversationHistory", "null");
      }

      if (!this._AccessToken) {
        this._SessionIdValue = this._RandomAccessToken;
        this._BondUserName = "FN" + this._SessionIdValue.substring(0, 10);
        this._BondAccessToken = this._RandomAccessToken;
        this._UserType = "Guest";
        this._UserId = this._BondUserName + "@bond.ai";

        this._IsGuestUser = true;
      }
      else {

        this._BondUserName = this._UserName;
        this._BondAccessToken = this._AccessToken;
        this._UserType = 'User';
        this._UserId = this._UserId;
        window.sessionStorage.setItem("BondAccessToken", this._BondAccessToken);
      }
    } catch (e) {

    }
  };

  CreateGuid() {
    try {
      function _p8(s: any) {
        var p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
      }
      return _p8(false) + _p8(true) + _p8(true) + _p8(false);
    } catch (e) {

      return "";
    }
  };

  CheckIn(vMessageType: any) {
    try {
      var data = {
        UserName: this._BondUserName,
        SessionId: this._SessionIdValue,
        MessageType: vMessageType,
        AccessToken: this._BondAccessToken,
        LangCode: this._LangCode,
        UserId: this._UserId,
        UserType: this._UserType,
        RequestedBot: this._RequestedBot
      };

      this.connection.invoke("CheckIn", data).then((res: any) => {
        try {
          this._DisableTextMsg = false;
          this._txtplaceholder = "Say Something..";
          this.ref.detectChanges()
        }
        catch (e) {
          this._DisableTextMsg = true;
          this._txtplaceholder = "Conneting...";
        }
      });


    }
    catch (e) {

    }
  };

  SendMessage(vUserMessage: any, vMessageType: any, vReqFrom: any) {
    try {

      if (vUserMessage !== undefined) {
        var res = vUserMessage.trim()
        var reslength = res.length
      }

      this.ScrollChatBody();

      this._DisableGreetMsgs = true;

      if (vUserMessage === "" || vUserMessage === null || vUserMessage === undefined || reslength === 0) {
        return;
      }

      else {

        this.loader = true;
        this.RelativeQue1 = "";
        this.RelativeQue2 = "";
        this.RelativeQue3 = "";

        var data = {
          UserName: this._BondUserName,
          Messages: vUserMessage,
          LangCode: this._LangCode,
          SessionId: this._SessionIdValue,
          MessageType: this._MessageType,
          AccessToken: this._BondAccessToken,
          UserId: this._UserId,
          RequestedBot: this._RequestedBot
        };

        this.AddUserMessage(vUserMessage);

        this.UserMessage = ""

        this.testBotService.sendMessageToBondHub(data).subscribe((res: any) => {

          this.ScrollChatBody();

        })
      }
    } catch (e) {
      this._DisableTextMsg = true;
      this._txtplaceholder = "No Internet Connection...";

    }
  };

  UserBrowser() {

    var userAgent = window.navigator.userAgent;
    var browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };
    var key: any

    for (key in browsers) {

    }
    return 'unknown';
  };

  ScrollChatBody() {

    var browser = this.UserBrowser();
    if (browser === "safari") {
      $("#ChatBody").animate({ scrollTop: $('#ChatBody').prop("scrollHeight") }, "smooth");
    }
    else {
      $("#ChatBody").animate({ scrollTop: $('#ChatBody').prop("scrollHeight") }, "smooth");
    }
  };


  ScrollChatBodyOnLoad() {
    this.scrollcontainer.nativeElement.scrollTop = this.scrollcontainer.nativeElement.scrollHeight;

    this.scrollHeight = this.scrollcontainer.nativeElement.scrollHeight;
    this.scrollTopValue = this.scrollcontainer.nativeElement.scrollTop

    if (this.scrollHeight > 309) {
      this.checkScrollHeight = true;
    } else {
      this.checkScrollHeight = false;
    }

    this.closeInterval()

  };

  AddUserMessage(vUserMessage: any) {

    this.ScrollChatBody();

    this._txtplaceholder = "Sending...";

    if (this._Conversation.length > 0) {
      this.RemoveExistingRelatedIntents();
    }

    var index = 0;
    if (this._Conversation !== null) {
      index = this._Conversation.length > 0 ? this._Conversation[this._Conversation.length - 1].Index + 1 : this._Conversation.length + 1;
    }

    var newMessage = {
      "ResponseType": "Text",
      "MessageType": "UserMessages",
      "Message": vUserMessage,

      "LISTRespMsg": "",

      "SampleResponses": "",
      "BotResponseType": "",

      "PostDispMessage": "",
      "PostMsgType": "",

      "BotMessageType": "",
      "BotMessage": "",

      "PreDispMessage": "",
      "PreMsgType": "",
      "AgentTransferMsg": "",
      "IsDisabletxtbox": "",
      "URLRespMsg": "",
      "PreURLRespMsg": "",
      "PostURLRespMsg": "",
      "Index": index,
    };

    this._EnableUserRating = true;
    this._Conversation.push(newMessage);
    localStorage.setItem("ConversationHistory", JSON.stringify(this._Conversation));


    this.TransPropUserMsg = this.getConversationBodyHeight();

    this.setLoaderPosition();
    this.loader = true;

    this._DisableTextMsg = true;

    this.ScrollChatBody();

  }

  RemoveExistingRelatedIntents() {

    for (var i = 0; i < this._Conversation.length; i++) {
      if (this._Conversation[i].responseType === "RelatedIntent") {
        this._Conversation.splice(i, 1);
        i = i - 1;
      }
    }
    localStorage.setItem("ConversationHistory", JSON.stringify(this._Conversation));
  };

  DisplayRelatedIntent(relatedIntents: any) {
    debugger
    try {
      if (relatedIntents !== null) {
        this._isRelativeQueEmpty = false
        if (relatedIntents.length > 3) {
          this._dynamicRelatedIntentDivHeight = true
        }

        for (var i = 0; i < relatedIntents.length; i++) {
          if (relatedIntents[i].query == null) {
            this._isRelativeQueEmpty = true
          } else if (i === 0) {
            this.RelativeQue1 = relatedIntents[0].query;

          }
          else if (i === 1) {
            this.RelativeQue2 = relatedIntents[1].query;

          }
          else if (i === 2) {
            this.RelativeQue3 = relatedIntents[2].query;

          }
          this.ref.detectChanges()
        }

        this.ScrollChatBody();
      }
      else {

      }
    }
    catch (e) {

    }
  };

  getDataForMarketUser() {
    this.InsightsList = [
      {
        "query": "What amounts shall be eligible for forgiveness?", "displayMsg": "What amounts shall be eligible for forgiveness?",
        "queryId": "", "responseType": "Text", "sectionName": ""
      },
      {
        "confidence": 0, "displayMsg": "What documentation will I be required to submit to my lender with my request for loan forgiveness?", "intentName": "",
        "query": "What documentation will I be required to submit to my lender with my request for loan forgiveness?", "queryId": "", "responseType": "Text", "sectionName": ""
      },
      {
        "confidence": 0, "displayMsg": "I need help with something!", "intentName": "",
        "query": "I need help with something!", "queryId": "", "responseType": "Text", "sectionName": ""
      }
    ];

    this.DisplayRelatedIntent(this.InsightsList);
  }

  AddUserInsight(vSelecteditem: any) {

    this.InsightsList.remo

    this.InsightsList.forEach((value: any, id: any) => {
      if (value.displayMsg == vSelecteditem)
        this.InsightsList.splice(id, 1)
    });

    this.RelativeQue1 = ""
    this.RelativeQue2 = ""
    this.RelativeQue3 = ""

    this.DisplayRelatedIntent(this.InsightsList);

  }

  DisconnectUser() {

    this.SendMessage("CHECKOUT", "Agent", 1);

    window.location.reload();

  };

  ShowEmoticons(id: any, vAnswer: any, vIsLiked: any, vConversationIndex: any) {
    if (vIsLiked != undefined || vIsLiked == "1" || vIsLiked == "2") {
      if (vIsLiked == "1")
        vIsLiked = "-U";
      else if (vIsLiked == "2")
        vIsLiked = "-D";

      this.AddConversationMessageRating(id, vAnswer, vIsLiked, vConversationIndex);
    }
    else {
      $('#transform_' + id).addClass('transform-active');
      this.HideRatingIcons(id);
      $("#like_" + id).css({ 'visibility': 'visible', 'display': 'inline-block' });

      setTimeout(function () {
        $("#dislike_" + id).css({ 'visibility': 'visible', 'display': 'inline-block' });
      }, 300);
    }
  };

  HideRatingIcons(id: any) {
    $("#MessageRating_" + id).css({ 'visibility': 'hidden', 'display': 'none' });
    $("#activeLike_" + id).css({ 'visibility': 'hidden', 'display': 'none' });
    $("#activeDislike_" + id).css({ 'visibility': 'hidden', 'display': 'none' });
    $("#like_" + id).css({ 'visibility': 'hidden', 'display': 'none' });
    $("#dislike_" + id).css({ 'visibility': 'hidden', 'display': 'none' });
    $("#minuslike_" + id).css({ 'visibility': 'hidden', 'display': 'none' });
    $("#minusdislike_" + id).css({ 'visibility': 'hidden', 'display': 'none' });
  };

  AddConversationMessageRating(id: any, vAnswer: any, vIsLiked: any, vConversationIndex: any) {
    if (vIsLiked === 3)
      vIsLiked = "D";
    else if (vIsLiked === 4)
      vIsLiked = "U";
    else vIsLiked = vIsLiked;

    var vQuestion = this._Conversation.filter(function (x: any) { return x.Index === vConversationIndex; })[0].Message;
    var vIntentName = this._Conversation.filter(function (x: any) { return x.Index === vConversationIndex; })[0].Intentname;

    var url = this._APIUrl + "api/BondChatBot/InsertConversationMessageRating";
    var ratingData = {
      'vAnswer': vAnswer,
      'vIsLiked': vIsLiked,
      'vQuestion': vQuestion,
      'vIntentName': vIntentName
    };

    this._Conversation.filter(function (x: any) { return x.Index === vConversationIndex; })[0].UserReponse = vIsLiked;
    localStorage.setItem("ConversationHistory", JSON.stringify(this._Conversation));

    if (vIsLiked == 'U') {
      $("#like_" + id).css({ "visibility": "hidden", "display": "none" });
      $("#activeLike_" + id).css({ "visibility": "visible", "display": "inline-block" });
      $('#transform_' + id).removeClass('transform-active');

      setTimeout(function () {
        $("#dislike_" + id).css({ "visibility": "hidden", "display": "none" });
      }, 200);
    }
    if (vIsLiked == 'D') {
      $("#dislike_" + id).css({ "visibility": "hidden", "display": "none" });
      $("#activeDislike_" + id).css({ "visibility": "visible", "display": "inline-block" });
      $('#transform_' + id).removeClass('transform-active');

      setTimeout(function () {
        $("#like_" + id).css({ "visibility": "hidden", "display": "none" });
      }, 100);
    }

    if (vIsLiked == '-U') {
      $("#minuslike_" + id).css({ "visibility": "visible", "display": "inline-block" });
    }

    if (vIsLiked == '-D') {
      $("#minusdislike_" + id).css({ "visibility": "visible", "display": "inline-block" });
    }

    this.testBotService.InsertConversationMessageRating(ratingData).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {

      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;
      }
    })
  };

  onBlurCloseEmoji(id: any, userResponse: any) {
    this._CloseDivFlag = false;


    $('#transform_' + id).removeClass('transform-active');
    this.HideRatingIcons(id);
    if (userResponse == undefined) {
      $("#MessageRating_" + id).css({ "visibility": "visible", "display": "inline-block" });
    }
    if (userResponse == 'U') {
      $("#activeLike_" + id).css({ 'visibility': 'visible', 'display': 'inline-block' });
    }
    if (userResponse == 'D') {
      $("#activeDislike_" + id).css({ 'visibility': 'visible', 'display': 'inline-block' });
    }
    if (userResponse == '-U') {
      $("#minuslike_" + id).css({ 'visibility': 'visible', 'display': 'inline-block' });
    }
    if (userResponse == '-D') {
      $("#minusdislike_" + id).css({ 'visibility': 'visible', 'display': 'inline-block' });
    }
  };


  addClassAfter() {

    let fetchId = this.pp.nativeElement
  }

  rating(ratingvalue: any) {
    var rv = ratingvalue.value
  }

  fetchUserName() {
    this.username = localStorage.getItem("UserName")
  }


  RatingChatModels() {

    this.ScrollChatBody();

    if (this._EnableUserRating == true) {

      this.ratingModal.nativeElement.classList.add("d-block");
      this.ShowRating = true;

    }
    else {
      this.ShowChat = false;
      this.inputdata.emit(this.ShowChat);

    }

    this.ref.detectChanges()


  }

  CloseRatingModal() {

    this.ShowChat = false;
    this.inputdata.emit(this.ShowChat);
    this.ShowRating = false;
    this.ratingModal.nativeElement.classList.remove("d-block");

  }

  AddUserRating(ratingVal: any) {

    try {

      this.UserPrevRating = ratingVal.value
      this.testBotService.userRating(this.UserPrevRating).subscribe((res: any) => {

        if (res.Status === "Success") {




          window.localStorage.setItem("SelectedRating", this.UserPrevRating);

          this._numberOfStars = this.UserPrevRating
          this._isratingpopup = false
          this._isfeedbackPopup = true
          setTimeout(() => {
            this.ShowChat = false;
            this.inputdata.emit(this.ShowChat);
          }, 5000);
          this.inputdata.emit(this.ShowChat);

        } else if (res.Error) {

          this.popupImg = "../../../assets/images/CommonError.png";
          this.popupContent = "Oops!";
          this.popupContentError = res.Error;

        }
      })

    } catch (e) {

    }
  }

  eventHandler(event: any) {
    this._Conversation = event;
    this.userHtml = this._Conversation[0].Message
  }

  _BotGradStartColor: any
  _BotGradEndColor: any
  _BotsiteUrl: any;

  _startColor: any;
  _endColor: any;


  GetBotGradientColor() {
    this.testBotService.GetBotGradientColor().subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        var urlSafe: any
        urlSafe = res.Data.BotBackgroundWebSite;
        this._BotBackgroundWebSite = this.sanitizer.bypassSecurityTrustResourceUrl(urlSafe);

        this._BotGradStartColor = res.Data.BotGradStartColor;
        this._BotGradEndColor = res.Data.BotGradEndColor;
        this._BotsiteUrl = res.Data.BotBackgroundWebSite;

        this._gradStartColor = res.Data.BotGradStartColor;
        this._gradEndColor = res.Data.BotGradEndColor;

      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

      }
    })
  }

  previewThemeChanges() {

    this._BotGradStartColor = this._startColor;
    this._BotGradEndColor = this._endColor;

    window.localStorage.setItem("BotGradStartColor", this._BotGradStartColor);
    window.localStorage.setItem("BotGradEndColor", this._BotGradEndColor);

    var urlSafe: any
    urlSafe = this._BotsiteUrl
    this._BotBackgroundWebSite = this.sanitizer.bypassSecurityTrustResourceUrl(urlSafe);
  }

  UpdateBotGradientColor(vIsClick: any) {

    this.previewThemeChanges();

    var data = {
      BotBackgroundWebSite: this._BotsiteUrl,
      BotGradStartColor: this._BotGradStartColor,
      BotGradEndColor: this._BotGradEndColor,
      IsClick: vIsClick
    }

    this.testBotService.UpdateBotGradientColor(data).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {

        window.location.reload();
      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

      }
    })
  }

  _isOnline: boolean

  closeChatBotIsOffline() {
    if (!navigator.onLine) {
      this.ShowChat = false;
      this.inputdata.emit(this.ShowChat);
    }
  }
  checkInternetConn() {
    if (navigator.onLine) {
      if (this._RestartConn === true) {
        this._isOnline = true
        this.RestartConnection();
        this._RestartConn = false;
        this._DisableTextMsg = false;
        this.ref.detectChanges()
      }
    } else {
      this._isOnline = false;
      // this.closeChatBotIsOffline();
      this._RestartConn = true;
      this._DisableTextMsg = true;
      this._txtplaceholder = "No Intertnet Connection...";
      this.ref.detectChanges()
    }
  }

  onRightClick() {
    return false;
  }

  fetchInputPersona() {
    this._libraryInputData = this.inputVariable
    this._pageName = this._libraryInputData.PageName
  }

  UpdateSigningStatus(vSessionId: any) {
    try {
      var data = {

        "sessionId": vSessionId
      }
      this.testBotService.UpdateAccountDetails(data).subscribe((res: any) => {

      })

    } catch (e) {

    }
  };

  _CardDetails: any = false;
  _fullName: any

  GetCardDetail() {
    try {
      this._CardDetails = true;

      this.testBotService.GetCardDetail().subscribe((res: any) => {

        if (res.Status.toUpperCase() == "SUCCESS") {

          this._CardDetails = res.Data;
          this._fullName = this._CardDetails.FirstName + " " + this._CardDetails.LastName;
        }
        else if (res.Error) {

          this.popupImg = "../../../assets/images/download-popup-error.png";
          this.popupContent = "Oops!";
          this.popupContentError = res.Error;

        }

      })
    } catch (e) {
    }
  };

  getTheFiles(vEvent: any) {

    const file: File = vEvent.target.files[0];

    if (file) {

      this._FileName = file.name;

      this._formdata.append('file', file);

      this._IsDisableSendBtn = false;

    }

  };



  uploadFilesF() {

    this._TTS = false;

    this.ScrollChatBody();


    this.testBotService.UploadFile(this._formdata).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {

        if (res.Data === true) {
          this.SendMessage("Got It", "User", "");
          this._TTS = false;
          this._FileName = "";
        }
      }
      else if (res.Error) {

        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

      }
    })
  };

  _webCamShow: boolean = false;
  startCamera() {

    this._webCamShow = true;

  };

  stopCamera() {

    this._webCamShow = false

    this._IsDisableSendBtn = false;

    this._FileName = "";
  };

  _webcamError: boolean = false;
  _video: any;
  channel: any = [];
  patOpts = { x: 0, y: 0, w: 25, h: 25 };
  snapshotData: any
  sendSnapshotToServer = (imgBase64: any) => {
    this.snapshotData = imgBase64;
  };

  onError(err: any) {

    this._webcamError = err;
  };

  onSuccess() {
    this._video = this.channel.video;

    this.patOpts.w = this._video.width;
    this.patOpts.h = this._video.height;
  };


  uploadFiles() {
    if (this._webcamImage !== undefined) {
      this._webCamShow = false;
      var imageBase64 = this._webcamImage.imageAsDataUrl;
      var blob = this.dataURItoBlob(imageBase64);
      var file = new File([blob], 'Userselfie.png');

      var formdata = new FormData();
      formdata.append('file', file);

      this.testBotService.UploadFile(formdata).subscribe((res: any) => {

        if (res.Status.toUpperCase() == "SUCCESS") {

          if (res.Data === true) {
            this.SendMessage("Got It", "User", "");
            this._TTS = false;
            this.stopCamera();
            this._FileName = "";
          }
        }
        else if (res.Error) {

          this.popupImg = "../../../assets/images/download-popup-error.png";
          this.popupContent = "Oops!";
          this.popupContentError = res.Error;

        }
      })
    }
    else {
      this.stopCamera();
      this._FileName = "";
    }
  };

  dataURItoBlob = function (dataURI: any) {
    var byteString = atob(dataURI.toString().split(',')[1]);

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: 'image/png' });
    return blob;
  };

  _patOpts: any
  _patCanvas: any
  _patData = null;
  _ctx: any

  makeSnapshot() {
    if (this._video) {
      this._patCanvas = document.querySelector('#snapshot');
      if (!this._patCanvas) return;

      this._patCanvas.width = this._video.width;
      this._patCanvas.height = this._video.height;
      var ctxPat = this._patCanvas.getContext('2d');

      var idata = this.getVideoData(this._patOpts.x, this._patOpts.y, this._patOpts.w, this._patOpts.h);
      ctxPat.putImageData(idata, 0, 0);

      this.sendSnapshotToServer(this._patCanvas.toDataURL());
      this._patData = idata;

      var imageBase64 = this.snapshotData;
      var blob = this.dataURItoBlob(imageBase64);
      var file = new File([blob], 'Userselfie.png');
      this._FileName = file.name;
    }
  };


  getVideoData(x: any, y: any, w: any, h: any) {
    var hiddenCanvas = document.createElement('canvas');
    hiddenCanvas.width = this._video.width;
    hiddenCanvas.height = this._video.height;
    var ctx = hiddenCanvas.getContext('2d');
    this._ctx.drawImage(this._video, 0, 0, this._video.width, this._video.height);
    return this._ctx.getImageData(x, y, w, h);
  };

  seconds: any
  public triggerSnapshot(): void {
    this.seconds = 3;
    this._trigger.next();
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info("received webcam image", webcamImage);
    this._webcamImage = webcamImage;


    this.uploadFiles();
  }

  public get triggerObservable(): Observable<void> {
    return this._trigger.asObservable();
  }

  handleInitError(error: WebcamInitError): void {
  }

  _IsAccept: boolean = true;
  _DisableUpdate: boolean = false;
  _ConsentDoc: boolean = false;

  ckChangeModify() {
    if (this._IsAccept === true)
      this._DisableUpdate = false;
    else
      this._DisableUpdate = true;
  };

  _IsConsentSigned = false;
  _OpenACCPopup = false;

  SubmitConsent() {
    try {

      this.UpdateSigningStatus(this._SessionIdValue);
      this._ConsentDoc = false;
      this._IsConsentSigned = true;
    } catch (e) {
    }
  };


  CloseConsentPopup() {
    try {
      this._ConsentDoc = false;
      this._OpenACCPopup = false;

    } catch (e) {
    }
  };

  OpenConsentDoc() {
    this._ConsentDoc = true;
  };


  getConversationBodyHeight() {

    var transPropUserMsg = this.TransPropUserMsg;
    var calculatedHeights = this.calcConversationHeight();

    var d1: any
    d1 = calculatedHeights.greetContainerHeight
    var d2: any
    d2 = calculatedHeights.chatbodyHeight

    if (d2 > (calculatedHeights.conversationDivHeight + d1)) {
      var transalateHeight = d2 - (calculatedHeights.conversationDivHeight + d1);
      transPropUserMsg = "translate3d(0, " + transalateHeight + "px, 0)";
    }
    return transPropUserMsg;
  };

  calcConversationHeight() {
    var greetContainerHeight = $("#greet-banner").height();
    const chatDiv = document.getElementById("ChatBody");
    var chatbodyHeight = chatDiv?.offsetHeight;
    var conversationDivHeight = 0;

    for (var i = 0; i < this._Conversation.length; i++) {
      var conversationDiv = document.getElementById("conversations_" + i);

      if (conversationDiv != null || conversationDiv != undefined) {
        if ($("#conversations_" + i)[0].innerText != "") {
          conversationDivHeight += conversationDivHeight + conversationDiv.offsetHeight;
        }
      }
    }

    var calculatedHeights = {
      "greetContainerHeight": greetContainerHeight,
      "chatbodyHeight": chatbodyHeight,
      "conversationDivHeight": conversationDivHeight
    };

    return calculatedHeights;
  };

  setLoaderPosition() {

    var calculatedHeights = this.calcConversationHeight();


    var d1: any
    d1 = calculatedHeights.greetContainerHeight
    var d2: any
    d2 = calculatedHeights.chatbodyHeight

    var minus = calculatedHeights.conversationDivHeight - d1

    let myTag = this.el.nativeElement.querySelector("#loadingSpan");

    if (minus > d2) {
      this._setloaderposition = false
    }
    else if (calculatedHeights.conversationDivHeight != 0) {
      this._setloaderposition = true
      this.ref.detectChanges();
    }


  };

  onScroll(scrolling: any) {
    this.headerBlurEffect()
  }

  headerBlurEffect() {
    let topHeaderBlur = this.el.nativeElement.querySelector("#ChatBody");

    var greetContainerHeight: any
    greetContainerHeight = $("#greet-banner").height();
    var chatBodyScrollPos: any
    chatBodyScrollPos = $("#ChatBody").scrollTop();

    if (greetContainerHeight + chatBodyScrollPos > 248) {
      $("#chatbotclose").attr("src", '../../assets/images/BotCloseBlack.png');
      $('#topheader').removeClass("topmostHeader").addClass("topbar-blur");

      $(".bbchat-body").addClass("padding-top-74");
      $(".bbchat-body").addClass("h-760");
      $(".bbchat-body").addClass("h-506px");
      $(".bbchat-body").addClass("h-1200");
    }
    else {
      $("#chatbotclose").attr("src", '../../assets/images/cross.png');
      $('#topheader').removeClass("topbar-blur").addClass("topmostHeader");
      $(".bbchat-body").removeClass("padding-top-74");
      $(".bbchat-body").removeClass("h-760");
      $(".bbchat-body").removeClass("h-506px");
      $(".bbchat-body").removeClass("h-1200");
    }

    this.ref.detectChanges();

  }

  setRelatedIntentPosition() {

    var calculatedHeights = this.calcConversationHeight();

    var d1: any
    d1 = calculatedHeights.greetContainerHeight
    var d2: any
    d2 = calculatedHeights.chatbodyHeight

    var minus = calculatedHeights.conversationDivHeight - d1

    if (this._pageName === 'Persona') {
      if (minus > d2) {
        $('#relatedIntent').removeClass("p_bbChildHelptextScroll").addClass("bbChildHelptext");
      }
      else if (calculatedHeights.conversationDivHeight != 0) {
        $('#relatedIntent').removeClass("bbChildHelptext").addClass("p_bbChildHelptextScroll");
      }

      if (this._IsHavingChats == false) {
        $('#relatedIntent').removeClass("bbChildHelptext").addClass("p_bbChildHelptextScroll");
      }
      else {
        $('#relatedIntent').removeClass("p_bbChildHelptextScroll").addClass("bbChildHelptext");
      }
    }

    if (minus > d2) {
      $('#relatedIntent').removeClass("bbChildHelptextScroll").addClass("bbChildHelptext");
    }
    else if (calculatedHeights.conversationDivHeight != 0) {
      $('#relatedIntent').removeClass("bbChildHelptext").addClass("bbChildHelptextScroll");
    }

    if (this._IsHavingChats == false) {
      $('#relatedIntent').removeClass("bbChildHelptext").addClass("bbChildHelptextScroll");
    }
    else {
      $('#relatedIntent').removeClass("bbChildHelptextScroll").addClass("bbChildHelptext");
    }
  };

  AccountLink() {
    try {
      ;
      var data = {
        "packageName": null
      }
      this.testBotService.GetLinkToken(data).subscribe((res: any) => {
        ;
        if (res.Status.toUpperCase() == "SUCCESS") {
          this._PlaidToken = res.Data.link_token;

          this.PlaidCreate();
        }
        else {

        }
      })

    } catch (e) {

    }
  };

  PlaidCreate() {
    ;

    const plaidConfig: PlaidConfig = {
      clientName: 'BondAI',
      env: "sandbox",
      selectAccount: true,
      token: this._PlaidToken,

      product: ['auth', 'transactions'],

      onLoad: Function,
      onSuccess: Function,
      onExit: Function,
      onEvent: Function
    };

    this.plaidLinkService
      .createPlaid(
        Object.assign({}, plaidConfig, {
          onSuccess: (token: any, metadata: any) => this.onSuccessPlaid(token, metadata),
          onExit: (error: any, metadata: any) => this.onExit(error, metadata),
          onEvent: (eventName: any, metadata: any) => this.onEvent(eventName, metadata)
        })
      )
      .then((handler: PlaidLinkHandler) => {
        this.plaidLinkHandler = handler;
        this.open();
      });

  };

  open() {
    ;
    this.plaidLinkHandler.open();
  }

  exit() {
    ;
    this.plaidLinkHandler.exit();
  }

  onSuccessPlaid(token: any, metadata: any) {
    ;

    var data = {
      BankName: metadata.institution.name,
      Public_token: token,
      Account_id: metadata.account_id,
      ProductName: metadata.accounts[0].type,
      AccountType: metadata.accounts[0].subtype,
      AccessToken: localStorage.getItem(this._AccessToken)
    };

    this.testBotService.InsertAccLinkDetails(data).subscribe((res: any) => {
      ;
    })

  }

  onEvent(eventName: any, metadata: any) {

  }

  onExit(error: any, metadata: any) {

  }

  deleteonEsp(event: any) {
    if (event.keyCode == 27) {

      this.ShowRating = false
    }
  }

  onClick(event: any) {

    if (!this.el.nativeElement.contains(event.target)) {
    }
  }

  ClickedOut(event: any) {

    $('#ratingModalPopup').removeClass("d-block")
    $('#ratingModalPopup').removeClass("modal")
    if (event.target.classList[0] === "hover_bkgr_fricc") {
      $('#ratingModalPopup').addClass("modal")
      this.ShowRating = false
    } else {
      $('#ratingModalPopup').addClass("d-block")
      $('#ratingModalPopup').addClass("modal")
      this.ShowRating = true
    }
  }

}