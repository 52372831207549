import { Component, OnInit } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})

export class SettingsComponent implements OnInit {

  title = environment.pageTitle + ' - Settings';

  _isDesktopNotify: boolean = true
  _isEmailNotify: boolean = false

  fetchRole: any = localStorage.getItem("RoleName")
  _addActiveShowClass: boolean = true
  _hideOptionAsPerRole: boolean

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.showTenantBlock();
  }

  handleDesktopNotification(inputData: any) {

  }

  handleEmailNotification(inputData: any) {

  }

  showTenantBlock() {
    let fetchSettingVal = localStorage.getItem("setting")
    if (this.fetchRole.toUpperCase() === 'ADMIN' || this.fetchRole.toUpperCase() === 'CONTENT' || this.fetchRole.toUpperCase() === 'CONTENTVIEW') {
      this._addActiveShowClass = false
      this._hideOptionAsPerRole = false

      if (fetchSettingVal == 'false') {
        this._addActiveShowClass = true
      }
    } else {
      this._addActiveShowClass = true
      this._hideOptionAsPerRole = true
    }
  }
}
