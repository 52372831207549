import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';

import { HttpCommonReq } from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {
      LoginUrl: 'api/AuthorizeAPI/PortalLogIn',
      AgentUrl: 'api/AuthorizeAPI/AgentLogin',
      
    }
  }

  Login(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.postForLogin(this.configUrls.LoginUrl, data)
  }

  AgentLogin(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.postForLogin(this.configUrls.AgentUrl, data)
  }
}
