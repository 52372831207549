import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http'

import { HttpCommonReq } from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})

export class DeletedFAQService {
  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {
      getDeletedFAQList: 'api/DeletedRawIntent/GetAllRawIntents'
    }
  }

  getAllDeletedFAQData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.getDeletedFAQList, data)
  }

  getFilteredDeletedFAQData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.getDeletedFAQList, data)
  }
}
