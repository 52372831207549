import { Component, OnInit, ViewChild } from '@angular/core';

import { DashboardTargetService } from '../services/DashboardTarget/dashboard-target.service'
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Entities } from '../test-bot/Entities'

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-target',
  templateUrl: './dashboard-target.component.html',
  styleUrls: ['./dashboard-target.component.css']
})

export class DashboardTargetComponent implements OnInit {

  DashboardTargetArr: any = [];

  dashboardTargetHeadersArr: any = [];

  first: number = 0;

  isLoader: boolean = false;

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string
  closeModal: any

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;

  title = environment.pageTitle + ' - Dashboard Target';

  constructor(public dashboardTargetService: DashboardTargetService, private modalService: NgbModal, private titleService: Title) { }

  ngOnInit(): void {

    this.titleService.setTitle(this.title);

    this.isLoader = true

    this.getDashboardTargetLList();

    this.dashboardTargetHeaders();
  }

  dashboardTargetHeaders() {
    this.dashboardTargetHeadersArr = [
      { field: 'AppSettingID', header: 'Sr No.', width: "5%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'abc' },
      { field: 'SettingDesc', header: 'Name', width: "12%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'Section Name' },
      { field: 'SettingValue', header: 'Day Target Value', width: "8%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'FAQ' }
    ]
  }

  getDashboardTargetLList() {

    this.dashboardTargetService.getDashboardTargetData().subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        this.DashboardTargetArr = res.Data
        this.isLoader = false;
      }
      else if (res.Error) {

        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  UpdateDashboardTargetData() {

    this.isLoader = true;
    let EntitiesObj = new Entities()
    EntitiesObj.TargetDataModel.TargetType = "Web User";
    EntitiesObj.TargetDataModel.TargetValue = "70";
    EntitiesObj.TargetDataModel.BondTargetId = "2";

    this.dashboardTargetService.UpdateTargetValue(EntitiesObj.TargetDataModel).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        this.isLoader = false;

        this.first = 0;

        this.popupImg = "../../../assets/images/Update.png"
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)

        this.getDashboardTargetLList();

      } else if (res.Error) {

        this.isLoader = false;

        this.popupImg = "../../../assets/images/update_error.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  closePopup() {
    this.showPopup = false;
  }

  triggerModal(content: any) {

    this.modalService.open(content).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
