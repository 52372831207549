import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient} from '@angular/common/http'

import {HttpCommonReq} from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

  configUrls:any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {
      getAllUserDetailsData: 'api/UserDetails/GetAllUserDetailsData',
      updateUserDetailsData: 'api/UserDetails/UpdateUserDetails',
      deleteUserDetailsData: 'api/UserDetails/DeleteUserDetails',
      insertUserDetailsData: 'api/UserDetails/InsertUserDetails',
      UpdateAccountInfo: 'api/UserDetails/UpdateAccountInfo'
    }
  }

    getUserDetailsData(data: any){    
      let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
      return httpClientCommonObj.post(this.configUrls.getAllUserDetailsData,data)
    }

    UpdateAccountInfo(data: any){
      let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
      return httpClientCommonObj.post(this.configUrls.UpdateAccountInfo,data)
    }

    UpdateUserDetailsData(data: any){
      let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
      return httpClientCommonObj.post(this.configUrls.updateUserDetailsData,data)
    }


    DeleteUserDetailsData(data: any){
      let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
      return httpClientCommonObj.post(this.configUrls.deleteUserDetailsData,data)
    }

    InsertUserDetailsData(data: any){
      let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
      return httpClientCommonObj.post(this.configUrls.insertUserDetailsData,data)
    }
}
