import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http'

import { HttpCommonReq } from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})

export class LKPListService {

  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {
      getAllLKPData: 'api/LookupMaint/GetAllLookupData',
      updateLKPData: 'api/LookupMaint/UpdateLookupData',
      deleteLKPData: 'api/LookupMaint/DeleteLookupData',
      insertLKPData: 'api/LookupMaint/InsertLookupData',
      exportLKPData: 'api/LookupMaint/ExportCSVFile',
    }
  }

  getLookupData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.getAllLKPData, data)
  }

  UpdateLookupData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.updateLKPData, data)
  }

  DeleteLookupData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.deleteLKPData, data)
  }

  InsertLookupData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.insertLKPData, data)
  }

  ExportLookupData(data: any) {

    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.exportLKPData, data)
  }
}
