import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, ViewChild, ElementRef, HostListener, EventEmitter } from '@angular/core';
import { FaqService } from '../../services/faq/faq.service';
import { faqEntities } from '../../faq/fqaentities'
import { interval } from 'rxjs';

import { ChangeDetectorRef } from '@angular/core';

import { Table } from 'primeng/table';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfusionMatrixEntity } from 'src/app/confusion-matrix/ConfusionMatrixEntity';
import { ConfusionMatrixService } from 'src/app/services/ConfusionMatrix/confusion-matrix.service';

@Component({
  selector: 'app-build-activity',
  templateUrl: './build-activity.component.html',
  styleUrls: ['./build-activity.component.css']
})
export class BuildActivityComponent implements OnInit {

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;
  constructor(public faqconfigservice: FaqService, private modalService: NgbModal, public confusionMatrixService: ConfusionMatrixService,) { }

  ngOnInit(): void {
    this.getBuildModelStatus();
  }

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string

  progress = [
    { progressed: { per: '10', status: "GENERATEFILES COMPLETED" } },
    { progressed: { per: '20', status: "BUILDMODEL STARTED" } },
    { progressed: { per: '30', status: "BUILDMODEL STARTED" } },
    { progressed: { per: '40', status: "BUILDMODEL COMPLETED" } },
    { progressed: { per: '50', status: "MBMODELUSE COMPLETED" } },
    { progressed: { per: '60', status: "IT-STARTED" } },
    { progressed: { per: '70', status: "IT-COMPLETED" } },
    { progressed: { per: '80', status: "IMT-STARTED" } },
    { progressed: { per: '90', status: "IMT-INPROGRESS" } },
    { progressed: { per: '100', status: "IMT-COMPLETED" } }
  ];

  i = 0;

  closeModal: any
  _allCompleted: any
  _OverAllStatus: any
  _allCompletedMSG: any
  _hideonCancel: any
  _BuildActivityId: any

  _buildActivity: any
  _newVersion: any
  _modelName: any = "MODEL 1"
  _BuildActivity: any
  _BuildModelPerc: any
  _BuildStatus: any
  _BAlink: any
  _Lang = "EN";

  _isDemoBuilding: any

  _BuildmodelIP = {
    "StartTesting": false,
    "IntentTest": false
  };

  methodCallForInterval() {

    this._allCompleted = false;
    this.GetProgressBarForDemo();

    var sub = interval(1000)
      .subscribe((val: any) => { this.GetProgressBarForDemo() });

  };

  GetBuildActivityForProgress() {

    this.faqconfigservice.GetBuildActivityForProgressBar().subscribe((res: any) => {

      if (res.Status.toUpperCase() == 'SUCCESS') {
        if (res.Data.length !== 0) {
          this._buildActivity = res.Data;

          this._buildActivity.forEach((value: any, key: any) => {


            this._newVersion = value.BuildVersion;
            this._modelName = "Model " + value.ModelNumber;
            this._BuildActivity = value.BuildStatusDesc;
            this._BuildModelPerc = value.BuildModelPerc;
            this._BuildActivityId = value.BuildActivityId;
            this._BuildmodelIP = { "StartTesting": value.StartTesting, "IntentTest": value.IntentTest };

            this._OverAllStatus = value.OverAllStatus.toUpperCase() === "FAILED" ? true : false;

            
            if (value.OverAllStatus.toUpperCase() === "REQUESTED" || value.OverAllStatus.toUpperCase() === "INPROGRESS") {
              this._BuildStatus = true;
              this._hideonCancel = true;


              if (value.BuildStatus.toUpperCase() === "BUILDMODEL COMPLETED" && value.OverAllStatus.toUpperCase() === "INPROGRESS") {

                this.searchData("Model" + value.ModelNumber);
              }

              if (value.BuildStatus.toUpperCase() === "IT-STARTED" || value.BuildStatus.toUpperCase() === "IT-COMPLETED" || value.BuildStatus.toUpperCase() === "IMT-COMPLETED" || value.BuildStatus.toUpperCase() === "IT-INPROGRESS" || value.BuildStatus.toUpperCase() === "IMT-STARTED" || value.BuildStatus.toUpperCase() === "IMT-INPROGRESS") {
                this.GetTestSummary();
              }

              this._allCompleted = false;

            }
            else {
              if (value.OverAllStatus.toUpperCase() === "COMPLETED") {
                this._BuildStatus = true;
                this._allCompleted = true;
                this._allCompletedMSG = "Model building for " + this._modelName + " completed";

              }
              else {
                this._BuildStatus = false;
                this._allCompleted = true;
                this._allCompletedMSG = ' Model Building of ' + this._modelName + ' is Failed.';

                alert("Overall Status" + value.OverAllStatus + "\n Build Status : " + value.BuildStatus);

              }
            }
          });
        }
        else {
          this._IsDisplayCFYes = false;
          this._IsDisplayCFNo = false

          this._BuildStatus = true;
          this._hideonCancel = false;
          this._allCompleted = true;

        }
      }
      else {
        this.popupImg = "../../../assets/images/update_error.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)

        setTimeout(() => {
          this.dismisspopup();
        }, 100);
       
      }
    })
  }
  
  dismisspopup() {
    this.modalService?.dismissAll();
  }

  GetProgressBarForDemo() {
    if (this._allCompleted == false) {
      this._newVersion = "1.1.0";
      this._modelName = "MODEL1";

      this._BuildModelPerc = this.progress[this.i].progressed.per;
      this._BuildActivity = this.progress[this.i].progressed.status;

      this._BuildStatus = true;
      this._hideonCancel = true;

      this._BuildmodelIP = { "StartTesting": false, "IntentTest": false };

      this._OverAllStatus = false;
      this._allCompleted = false;

      this.i = this.i + 1;
    }
    if (this.i > 9) {
      this._allCompletedMSG = 'Model Building of ' + this._modelName + ' is completed.';
      this._allCompleted = true;
    }

  }

  CloseBuildActivityForDemo() {

    if (this._allCompleted == false) {
      this._hideonCancel = false;
      this._allCompleted = true;
      this._allCompletedMSG = 'Model Building of ' + this._modelName + ' is stop.';
    }
  }

  _getBuildActivityForProgressIntervalId: any
  _TraningModelNameList: any

  getBuildModelStatus() {
    this.faqconfigservice.getBuildModelStatus().subscribe((res: any) => {

      this._isDemoBuilding = res.Data.IsDemoBuilding;
      this._TraningModelNameList = res.Data.TraningModelNames;

      if (this._isDemoBuilding == "true") {
        this._allCompleted = false;
        this.GetProgressBarForDemo();

        var sub = interval(10000)
          .subscribe((val: any) => { this.GetProgressBarForDemo() });
      }
      else {
        this.GetBuildActivityForProgress();

        // var sub = interval(30000)
        //   .subscribe((val: any) => { this.GetBuildActivityForProgress() });


        this._getBuildActivityForProgressIntervalId = setInterval(() => {
          this.GetBuildActivityForProgress()
        }, 30000);
      }

    })
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  InsertBuildActivity(BuildmodelIPData: any) {
    if (BuildmodelIPData == undefined) {
      BuildmodelIPData = {
        "StartTesting": false,
        "IntentTest": false
      };
    }

    var data = {
      "BuildLang": this._Lang,
      "BondCreatedBy": window.localStorage.getItem("UserId"),
      "BuildModelName": this._modelName == "MODEL 1" ? 1 : 2,
      "BuildVersion": this._newVersion,
      "StartTesting": BuildmodelIPData.StartTesting,
      "IntentTest": BuildmodelIPData.IntentTest,
      "ApplyLookupstoAll": true,
      "ModelNumber": this._modelName,
    };

    this.faqconfigservice.InsertBuildModelDetails(data).subscribe((res: any) => {

      if (res.Status.toUpperCase() == 'SUCCESS') {
        this._BuildStatus = true;
        this._BAlink = true;

        this.GetBuildActivityForProgress()

        var sub = interval(60000)
          .subscribe((val: any) => { this.GetBuildActivityForProgress() });

      }
      else {
        this.popupImg = "../../../assets/images/update_error.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })

  }

  _TestSummaryData: any

  CloseBuildActivityModel() {


    var data = {
      "BuildActivityId": this._BuildActivityId
    };

    this.faqconfigservice.UpdateBuildActivityStatus(data).subscribe((res: any) => {

      if (res.Status.toUpperCase() == 'SUCCESS') {
        
        this._hideonCancel = false;
        this._allCompleted = true;
        this._allCompletedMSG = 'Model Building of ' + this._modelName + ' is stop.';


        this.closeInterval();

      }
      else {
        this.popupImg = "../../../assets/images/update_error.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  closeInterval() {
    if (this._getBuildActivityForProgressIntervalId) {
      clearInterval(this._getBuildActivityForProgressIntervalId);
    }
  }

  GetTestSummary() {
    var ModelDataObj = this._TraningModelNameList.find((x: { DisplayModelName: string; }) => x.DisplayModelName == this._modelName)
    if (ModelDataObj) {
      var data = {
        "LangCode": this._Lang,
        "BuildActivityId": this._BuildActivityId,
        "ModelName": ModelDataObj.ModelName,
      };

      this.faqconfigservice.GetBuildTestingResult(data).subscribe((res: any) => {

        if (res.Status.toUpperCase() == 'SUCCESS') {
          if (res.Data.length !== 0) {
            this._TestSummaryData = res.Data;
          }
        }
        else {
          this.popupImg = "../../../assets/images/update_error.png"
          this.popupContent = "Oops!"
          this.popupContentError = res.Error;

          this.triggerModal(this.modalWarningAndInfo)
        }
      })
    }
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  _ConfusionMatrixData: any = []
  _IsDisplayCFYes = false;
  _IsDisplayCFNo = false

  searchData(vModelName: any) {

    var confusionMatrixEntityObj;

    confusionMatrixEntityObj = new ConfusionMatrixEntity();
    confusionMatrixEntityObj.Question = "";

    confusionMatrixEntityObj.ExpectedIntent = ""
    confusionMatrixEntityObj.ConfidanceFrom = 0;
    confusionMatrixEntityObj.ConfidanceTo = 100;
    confusionMatrixEntityObj.ModelName = vModelName;

    this.confusionMatrixService.SearchConfusionMatrixData(confusionMatrixEntityObj).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        
        if (res.Data.length != 0) {
          this._IsDisplayCFYes = true;
          this._IsDisplayCFNo = false
        }
        else {
          this._IsDisplayCFNo = true
          this._IsDisplayCFYes = false;
        }
      } else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })

  }


}
