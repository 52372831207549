import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';

import { HttpCommonReq } from '../../services/HttpCommonReq'
import { getDefaultCompilerOptions } from 'typescript';

@Injectable({
  providedIn: 'root'
})
export class ContextFlowService {

  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {

      getCFList: 'api/ClickableFlows/GetAllClickableFlows',
      SearchFaqList: 'api/ClickableFlows/GetClickableFlowsByID',
      Addcontextrecord: 'api/ClickableFlows/InsertClickableFlows',
      updatecontextrecord: 'api/ClickableFlows/UpdateClickableFlows',
      deletecontextrecord: 'api/ClickableFlows/DeleteClickableFlows',

      addDefaultOption: 'api/Intent/InsertIntentData',
      UpdateDefaultOption: 'api/Intent/UpdateIntentData',

      deleteDefaultOption: 'api/Intent/DeleteIntentData',
      saveContextFlow: 'api/ClickableFlows/UpdateContextFlow',
      UpdateContextRelatedInfo: 'api/Intent/UpdateContextRelatedInfo',

      GetContextOptions: 'api/ClickableFlows/GetContextOptions',

      GetIntentByID : 'api/Intent/GetIntentByID'
    }
  }

  getAllContextFlowRecords() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.getCFList)
  }

  UpdateContextRelatedInfo(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.UpdateContextRelatedInfo, data)
  }

  GetContextOptions(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.GetContextOptions, data)
  }

  addContextRecord(data: any) {
    
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.Addcontextrecord, data)
  }

  updateContextRecord(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.updatecontextrecord, data)
  }

  deleteContextRecord(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.deletecontextrecord, data)
  }







  addDefaultOptionRecord(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.addDefaultOption, data)
  }

  deletedDefualtOption(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.deleteDefaultOption, data)
  }

  updateDefaultOptionRecord(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.UpdateDefaultOption, data)
  }






  //save conversation flow diagram
  saveContextFlowDiagram(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.saveContextFlow, data)
  }

  GetIntentByID(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.GetIntentByID, data)
  }
}
