<div class="container-fluid screen-container" id="HomePageD">
    <div class="HDScreenContainer">
        <div class="row personaPageWrapper">

            <div class="col-md-8">

                <div class="col-md-12">
                    <div class="{{_topHeaderImage}}">
                        <img src="{{_personImg}}">
                    </div>
                </div>

                <div class="loaderPersonaPageContainer" *ngIf="_isLoader">

                    <div class="col-md-12">
                        <div class="row loader-top-row">
                            <div class="col-md-3 col-xl-4 loader-left-banner">
                                <ngx-skeleton-loader class="left-banner-loader1" count="1"></ngx-skeleton-loader>

                                <ngx-skeleton-loader class="left-banner-loader2" count="1"></ngx-skeleton-loader>
                            </div>
                            <div class="col-md-9 col-xl-8 loader-right-banner">
                                <ngx-skeleton-loader class="right-banner-loader1" count="1"></ngx-skeleton-loader>

                                <ngx-skeleton-loader class="right-banner-loader2" count="1"></ngx-skeleton-loader>

                                <ngx-skeleton-loader class="right-banner-loader3" count="1"></ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="row" *ngIf="_isLoader">
                            <div class="col-md-7 NeedClass-loader">
                                <ul>
                                    <li>
                                        <ngx-skeleton-loader class="loader1" count="1">
                                        </ngx-skeleton-loader>
                                        <ngx-skeleton-loader class="loader2" count="1">
                                        </ngx-skeleton-loader>
                                    </li>
                                    <li>
                                        <ngx-skeleton-loader class="loader1" count="1">
                                        </ngx-skeleton-loader>
                                        <ngx-skeleton-loader class="loader2" count="1">
                                        </ngx-skeleton-loader>
                                    </li>
                                    <li>
                                        <ngx-skeleton-loader class="loader1" count="1">
                                        </ngx-skeleton-loader>
                                        <ngx-skeleton-loader class="loader2" count="1">
                                        </ngx-skeleton-loader>
                                    </li>
                                    <li>
                                        <ngx-skeleton-loader class="loader1" count="1">
                                        </ngx-skeleton-loader>
                                        <ngx-skeleton-loader class="loader2" count="1">
                                        </ngx-skeleton-loader>
                                    </li>
                                    <li>
                                        <ngx-skeleton-loader class="loader1" count="1">
                                        </ngx-skeleton-loader>
                                        <ngx-skeleton-loader class="loader2" count="1">
                                        </ngx-skeleton-loader>
                                    </li>
                                </ul>
                            </div>

                            <div class="col-md-6 BehavioralClass-loader">
                                <ngx-skeleton-loader class="loader1" count="1">
                                </ngx-skeleton-loader>
                                <ngx-skeleton-loader class="loader2" count="1">
                                </ngx-skeleton-loader>
                                <ngx-skeleton-loader class="loader3" count="1">
                                </ngx-skeleton-loader>
                                <ngx-skeleton-loader class="loader4" count="1">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 loader-BondBG">
                            <ngx-skeleton-loader count="1">
                            </ngx-skeleton-loader>
                        </div>

                        <div class="col-md-6 loader-BondBG">
                            <ngx-skeleton-loader count="1">
                            </ngx-skeleton-loader>
                        </div>
                    </div>

                </div>
                <div class="personaPageContainer">

                    <div class="col-md-12 personaBanner" *ngIf="!_isLoader">
                        <div class="row">
                            <div class="col-md-3 col-xl-4 left-banner">
                                <p class="title">{{consumerFirstName}}</p>
                                <p class="subTitle">{{consumerLastName}}</p>
                                <p class="tagLine">{{personaDetails.Address}}</p>
                            </div>
                            <div class="col-md-9 col-xl-8 right-banner">
                                <div class="d-inline-block">
                                    <p class="title">{{personaDetails.MasterPersona}}</p>
                                    <p class="tagLine">{{personaDetails.SubPersonaList}}</p>
                                    <p class="colorTagLine">{{personaDetails.Insight}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row personaListCard">

                        <div class="col-md-7 NeedClass">
                            <div class="listCategory">
                                <div class="listCategoryHeader">
                                    Needs
                                </div>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item" *ngFor="let item of PersonaCategoryList">
                                        <span class="categoryName">{{item.name}}</span>
                                        <span class="valueBlock {{valueBlockWidth}}" [ngClass]="{'red-border': item.value === '4.6%' || item.value === 'NA' , 'green-border': item.value === '12%' || item.value === 'Dup',
                                        'yellow-border': item.value === '14.5%'}">{{item.value}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-md-6 BehavioralClass">
                            <div class="card">

                                <div class="cardDetailsBlock">
                                    <div class="cardHeader">
                                        <div class="d-inline-block xl-mt-10px mt-5px">Behavioral Stability</div>
                                        <div class="d-inline-block float-right">
                                            <p-dropdown [options]="calendar" class="CalenderDropDown"
                                                optionLabel="name">
                                            </p-dropdown>
                                        </div>
                                    </div>

                                    <div class="media stabilityChartBlock">
                                        <div class="align-self-center badges">
                                            <span>{{BondScoreVal}}</span>
                                        </div>
                                        <div class="media-body text-center align-self-center">
                                            <div class="points">
                                                <span class="upArrow">
                                                    <img src="../../assets/images/Polygon.png">
                                                </span>
                                                <span *ngIf="BondStatus=='NegativeVal'" class="downArrow">&#x25BC;
                                                </span>
                                                <span class="text">3 points</span>
                                            </div>
                                            <hr />
                                            <div class="pointerData">
                                                <span>Y Y Y Y N</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="chartBlock">
                                        <span class="chartBlockTitle">Trends</span>
                                        <div id="cardChart"></div>
                                    </div>

                                    <div class="row chartDetails text-center">
                                        <div class="chartDetailsContainer">
                                            <div class="col-md-4 chartFooterblock"> High :
                                                {{_maxBondScore}}
                                            </div>
                                            <div class="col-md-4 chartFooterblock" style="padding:0px;border-left: 1px solid;
                                        border-right: 1px solid;"> Low :
                                                {{_minBondScore}}
                                            </div>
                                            <div class="col-md-4 chartFooterblock" style="padding:0px 0 0 15px;">
                                                Median:
                                                {{_medianBondScore| number:'1.0-0'}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row text-center" *ngIf="!_isLoader">

                        <div class="col-md-6">
                            <button [hidden]="_ViewClientRoleAccess" title="Export to CRM" [disabled]="true"
                                class="btn btn-primary BondBG">Export to
                                CRM</button>
                        </div>

                        <div class="col-md-6">
                            <button [hidden]="_ViewClientRoleAccess" title="Export to PDF" (click)="convetToPDF()"
                                class="btn btn-primary BondBG">Export
                                to PDF</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 loader-chatbot" *ngIf="_isLoader">

                <div class="loader-chatbotContainer">
                    <div class="loader-chatbotPanel">
                        <ngx-skeleton-loader class="loader1" count="1"></ngx-skeleton-loader>

                        <ngx-skeleton-loader class="loader2" count="1"></ngx-skeleton-loader>

                        <ngx-skeleton-loader class="loader3" count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="loader-chatbotFooter">

                    </div>
                </div>
            </div>

            <div class="col-md-4 xl-pt-30px" *ngIf="!_isLoader">

                <div class="clusterChatBot">
                    <div class="panel panel-primary" style="border:none!important; ">

                        <div class="clusterChatBotContainer" *ngIf="ShowChat==true">
                            <!-- <lib-TestBot-lib (inputdata)="fetchShowChatValue($event)"
                                [inputVariable]="{isShowChat: ShowChat, PageName: _pageName, RoleName: _RoleName}">
                            </lib-TestBot-lib> -->

                            <bond-bot (recivechatValue)="fetchShowChatValue($event)" isShowChat=true PageName="Persona"
                                RoleName="Client" ClientTenantId="7B599D13-A945-4F47-9964-5880C6E6AB87">
                            </bond-bot>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>