import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

  title = environment.pageTitle + ' - Session Timed Out';

  constructor(private router: Router, private modalService: NgbModal, private titleService: Title) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.dismisspopup();
    }, 100);

    this.titleService.setTitle(this.title);

    // Get a reference to the last interval + 1
    const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);

    // Clear any timeout/interval up to that id
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
  }

  redirectToLogin() {
    this.router.navigateByUrl('login');
  }

  dismisspopup() {
    this.modalService.dismissAll();
  }
}
