import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UserDetailsService } from 'src/app/services/UserDetails/user-details.service';


@Component({
  selector: 'app-accountinformation',
  templateUrl: './accountinformation.component.html',
  styleUrls: ['./accountinformation.component.css']
})
export class AccountinformationComponent implements OnInit {

  accountInfoForm: FormGroup;
  submitted = false;
  userEmail = localStorage.getItem("UserId")

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string

  _firstName: any
  _lastName: any
  _emailId: any

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;

  constructor(private formBuilder: FormBuilder, public userDetailsService: UserDetailsService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.applicationForm();
    this.accountInfoForm.controls.Email.setValue(this.userEmail);

    this._emailId = localStorage.getItem('UserId')
  }

  applicationForm() {
    this.accountInfoForm = this.formBuilder.group({
      FirstName: ['', [Validators.required, Validators.pattern(/^(?! )(?!.* $)[a-zA-Z ()-]+$/)]],
      LastName: ['', [Validators.required, Validators.pattern(/^(?! )(?!.* $)[a-zA-Z ()-]+$/)]],
      Email: ['', [Validators.required, Validators.email]]
    });
  }

  get f() { return this.accountInfoForm.controls; }

  submitAccountInfoForm() {

    this.submitted = true;

    if (this.accountInfoForm.invalid) {
      return;
    }
    else {

      var data = {
        FirstName: this._firstName,
        LastName: this._lastName,
        EmailId: this._emailId
      }

      this.userDetailsService.UpdateAccountInfo(data).subscribe((res: any) => {

        if (res.Status.toUpperCase() == "SUCCESS") {

          this.popupImg = "../../../assets/images/Update.png";
          this.popupContent = "";
          this.popupContentError = res.Message;

          this.triggerModal(this.modalWarningAndInfo)
        }
        else if (res.Error) {

          this.popupImg = "../../../assets/images/CommonError.png";
          this.popupContent = "Oops!";
          this.popupContentError = res.Error;

          this.triggerModal(this.modalWarningAndInfo)
        }
      })
    }

    const accountInfo = {
      firstName: this.accountInfoForm.controls.FirstName.value,
      lastName: this.accountInfoForm.controls.LastName.value,
      email: this.accountInfoForm.controls.Email.value,
    }
  }

  closeModal: any

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
