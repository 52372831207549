<div class="container-fluid screen-container xl-bg-transperent ">

    <div class="mainContainerWrapper">

        <div class="header row">
            <div class="col-md-12">
                <div class="float-left">
                    <span class="page-title">LKP List</span>
                </div>

                <div class="float-right">
                    <button [disabled]='this._ifNoRecords' [hidden]="_ViewClientRoleAccess" title="Export" type="button"
                        class="btn btn-secondary" (click)="AfterCLickExport()">
                        <img src="../../../assets/images/Export_icon.png" class="left-img export_icon">
                        <img src="../../../assets/images/Export-icon-selected.png" class="left-img seleted_export_icon">
                        <span class="align-middle">export</span>
                    </button>
                    <button [hidden]="_ViewClientRoleAccess" title="Add" type="button" [hidden]="_ViewRoleAccess"
                        class="btn btn-primary" (click)="addNewRow()"><img src="../../../assets/images/plus_icon.png"
                            class="left-img"><span class="align-middle">add LKP</span></button>
                </div>
            </div>
        </div>

        <div class="row subHeader xl-pl-20px xl-pr-20px">
            <div class="col-md-12" style="padding-bottom:7px !important ;">
                <div class="float-left">
                    <div class="search-field">

                        <div class="static-img"><img src="../../../assets/images/search.png"></div>
                        <input type="text" class="form-control custom-input-field" placeholder="Search"
                            [(ngModel)]="searchLKP" (keypress)="searchData($event)">
                        <div class="multiselectSearch">
                            <p-multiSelect [options]="searchOptionArr" [(ngModel)]="selectedSearchOpt"
                                [disabled]='searchLKP==""' (ngModelChange)="selectSearchOption($event)"
                                optionLabel="name" display="chip" [showHeader]="false"></p-multiSelect>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="row">

            <div class="tableContent" [hidden]="_ifNoRecords">

                <p-table #dt [value]="LkpListArr" styleClass="p-custom-datatable" [rows]="10" [paginator]="true"
                    reflow="true" paginatorTemplate="{RowsPerPageDropdown}" paginatorPosition="bottom"
                    paginatorPosition="bottom" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,40,50]"
                    currentPageReportTemplate="Displaying {last} of {totalRecords}" [rowHover]="true"
                    [scrollable]="true" scrollHeight="flex" [resizableColumns]="true" [(first)]="first" editMode="row"
                    [editable]="true" (onRowEditInit)="onRowEditInit($event)">

                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col *ngFor="let col of lkpHeadersArr" [style.width]='col.width'>
                        </colgroup>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn *ngFor="let col of lkpHeadersArr" [pSortableColumn]="col.field"
                                style="text-align: left;" [ngClass]="col.field" data-toggle="tooltip"
                                data-placement="top" title="{{col.header}}">
                                <div class="tableHeader" *ngIf="col.isSort === 'true'" (click)="sortcolumn(col.field)"
                                    [ngClass]="{'border-right-dadadd': col.field != 'BondModifiedOn' && col.field != 'status'}">
                                    <div class="d-inline-block text-overflow-header"
                                        [ngClass]="{'pl-15':col.field === 'DispID', 'xl-pl-20px':col.field === 'DispID'}">
                                        {{col.header}}</div>
                                    <div class="tableSortIcon">
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </div>
                                </div>
                                <div *ngIf="col.isSort === 'false'">
                                    {{col.header}}
                                </div>
                            </th>

                        </tr>
                        <tr *ngIf="isLoader">
                            <td *ngFor="let col of lkpHeadersArr">
                                <div class="item customSkeletonLoader">
                                    <ngx-skeleton-loader count="20" appearance=""></ngx-skeleton-loader>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-car let-ri="rowIndex">

                        <tr *ngIf="ri == 0 && newrow" class="newTableRow">
                            <td *ngFor="let col of lkpHeadersArr" style="padding: 0!important">

                                <div *ngIf="col.field == 'LookupTableName'">
                                    <input pInputText type="text" class="newInputField form-control"
                                        placeholder="lookup table name here ..." (keyup)="onchangeControlles($event)"
                                        [(ngModel)]="this.addRecord.Lookups.LookupTableName" required>
                                </div>

                                <div *ngIf="col.field == 'LookupTableValue'">
                                    <input pInputText type="text" class="newInputField form-control"
                                        placeholder="lookup table value here ..." (keyup)="onchangeControlles($event)"
                                        [(ngModel)]="this.addRecord.Lookups.LookupTableValue" required>
                                </div>

                                <div *ngIf="col.field == 'Language'">

                                    <input pInputText type="text" class="newInputField form-control"
                                        placeholder="Add your Lang here ..." (keyup)="onchangeControlles($event)"
                                        [(ngModel)]="this.addRecord.Lookups.Language" required>

                                </div>

                                <div *ngIf="col.field === 'BondModifiedOn'">
                                    <button title="Save" type="button" class="btn btn-success"
                                        (click)="addLookupList(this.addRecord.Lookups)"
                                        [disabled]="_disableSaveBtn || !this.addRecord.Lookups.LookupTableName || !this.addRecord.Lookups.LookupTableValue || !this.addRecord.Lookups.Language">Save</button>

                                    <button title="Cancel" class="cancelRow btn btn-secondary"
                                        (click)="cancelNewRow()">Cancel</button>
                                </div>
                            </td>
                        </tr>

                        <tr *ngIf="ri == fetchDispID && editrow" class="newTableRow">
                            <td *ngFor="let col of lkpHeadersArr" style="padding: 0!important">

                                <div *ngIf="col.field == 'LookupTableName'">
                                    <input pInputText type="text" class="newInputField form-control"
                                        placeholder="lookup table name here ..."
                                        [(ngModel)]="this.updateRecord.Lookups.LookupTableName"
                                        (keyup)="onchangeControlles($event)" required>
                                </div>

                                <div *ngIf="col.field == 'LookupTableValue'">
                                    <input pInputText type="text" class="newInputField form-control"
                                        placeholder="lookup table value here ..."
                                        [(ngModel)]="this.updateRecord.Lookups.LookupTableValue"
                                        (keyup)="onchangeControlles($event)" required>
                                </div>

                                <div *ngIf="col.field == 'Language'">

                                    <input pInputText type="text" class="newInputField form-control"
                                        placeholder="Add your Lang here ..."
                                        [(ngModel)]="this.updateRecord.Lookups.Language"
                                        (keyup)="onchangeControlles($event)" required disabled>

                                </div>

                                <div *ngIf="col.field === 'BondModifiedOn'">
                                    <button title="Save" type="button" class="btn btn-success"
                                        (click)="updateLookupRecord()"
                                        [disabled]="_disableSaveBtn || !this.updateRecord.Lookups.LookupTableName || !this.updateRecord.Lookups.LookupTableValue || !this.updateRecord.Lookups.Language">Save</button>

                                    <a class="cancelRow" (click)="cancelNewRow()">Cancel</a>
                                </div>
                            </td>
                        </tr>

                        <tr *ngIf="!_hideEmptyRow" [ngClass]="{'d-none':ri == fetchDispID && editrow}">
                            <td pEditableColumn *ngFor="let col of lkpHeadersArr"
                                [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">
                                <div
                                    [ngClass]="{'modDtBlock': col.field === 'BondModifiedOn', 'text-overflow-field': col.field === 'DisplayMessage' || col.field === 'Questions'}">

                                    <div [hidden]="_ViewRoleAccess" *ngIf="col.field != 'BondModifiedOn'"
                                        [ngClass]="{'xl-pl-14px':col.field !== 'DispID', 'pl-10':col.field !== 'DispID','pl-25':col.field === 'DispID', 'xl-pl-35px':col.field === 'DispID'}">

                                        {{car[col.field]}}
                                    </div>

                                    <div [hidden]="_ViewRoleAccess" *ngIf="col.field === 'BondModifiedOn'"
                                        [ngClass]="{'xl-pl-14px':col.field !== 'DispID', 'pl-10':col.field !== 'DispID'}">
                                        {{car[col.field] | date : 'MM/dd/yyyy HH:mm:ss'}}
                                    </div>

                                </div>

                                <div
                                    [ngClass]="{'text-overflow-field': col.field === 'DisplayMessage' || col.field === 'Questions'}">

                                    <div [hidden]="!_ViewRoleAccess" *ngIf="col.field != 'BondModifiedOn'"
                                        [ngClass]="{'xl-pl-14px':col.field !== 'DispID', 'pl-10':col.field !== 'DispID','pl-25':col.field === 'DispID', 'xl-pl-35px':col.field === 'DispID'}">

                                        {{car[col.field]}}
                                    </div>

                                    <div [hidden]="!_ViewRoleAccess" *ngIf="col.field === 'BondModifiedOn'"
                                        [ngClass]="{'xl-pl-14px':col.field !== 'DispID', 'pl-10':col.field !== 'DispID'}">
                                        {{car[col.field] | date : 'dd/MM/yyyy HH:mm:ss'}}
                                    </div>

                                </div>



                                <div class="actions" [hidden]="_ViewRoleAccess"
                                    [ngClass]="{'showactionBlock': col.field === 'BondModifiedOn', 'xl-pl-14px':col.field !== 'DispID', 'pl-10':col.field !== 'DispID'}">
                                    <a class="mr-2 cursor-pointer" (click)="fetchSingleRecord(car, ri)"
                                        class="faqModifyActionBtn mr-2" title="Modify">
                                        <img class="block_img" src="../../../assets/images/Modify.png">
                                        <img class="hover_modify_img" src="../../../assets/images/Modify_on_hover.png">
                                    </a>
                                    <a class="mr-2" (click)="OpenDeletePopup(car)" class="faqDeleteActionBtn"
                                        title="Delete">
                                        <img class="block_img" src="../../../assets/images/delete.png">
                                        <img class="hover_delete_img" src="../../../assets/images/delete_on_hover.png">
                                    </a>
                                </div>


                            </td>
                        </tr>
                    </ng-template>


                    <ng-template pTemplate='footer' class="position-relative">

                    </ng-template>
                </p-table>
            </div>

            <div class="noRecordFound text-center border-top" *ngIf="_ifNoRecords">
                <img src="../../assets/images/no_records.png">
                <p class="title">No Result Found</p>
                <p class="mb-0 subtitle">Sorry, we couldn’t find any results for this search.</p>
            </div>

        </div>
    </div>
</div>

<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>

<ng-template #modalDataDelete let-modal>
    <div class="customThemeModal">
        <div class="" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="closePopup">
                        <a (click)="modal.dismiss('Cross click')">
                            <img src="../../../assets/images/cross.png" class="cross-img">
                        </a>
                    </div>
                    <div class="deletePopupTitle">This will delete the Lookup value</div>
                    <div class="popupContent popupContentQue">"{{dataTodelete.LookupTableValue}}" </div>

                    <!-- <div class="popupWrapper">

                        <div class="popupWrapperHeader">Why do you want to delete?</div>

                        <div class=" CheckBoxDiv ">
                            <p-dropdown class="CalenderDropDown" [options]="_DeleteReasonsLst"
                                [(ngModel)]="_selectedDeleteReasons" optionLabel="name"
                                (onChange)="OnSelectDeletedReason()">
                            </p-dropdown>
                        </div>

                        <div class="PoupContent" *ngIf='_isDisplayOtherSec'>
                            <label class="otherLabel">Other:</label>
                            <textarea class="OtherTextBox form-control " [(ngModel)]="_OtherData" maxlength="500"></textarea>
                        </div>

                    </div> -->

                    <div class="PoupContent deletebtnsdiv">
                        <button title="Delete" type="button" class="btn btn-primary mr-10px deletebtn"
                            (click)="deleteLKPRecord(selectedCities, _OtherData); modal.dismiss(); ">Delete</button>
                        <button title="Cancel" type="button" class="btn btn-secondary deletebtn"
                            (click)="modal.dismiss('Cross click')">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>