<div class="container-fluid">
    <div class="loginPageHeader text-center">
        <div class="loginPageTitle">
            <img src="../../../assets/images/login-title.png" alt="">
        </div>
        <div class="loginPageSubtitle">
            <img src="../../../assets/images/login-subtitle.png" alt="">
        </div>
    </div>
    <div class="login-container">
        <div class="w-100" *ngIf="_isPasswordchange == false">
            <p class="containerTitle">Reset Password </p>

            <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" class="login-Form w-100">
                <div *ngIf="_isPasswordchange == false">
                    <div class="form-group position-relative">
                        <input type="{{checktype}}" class="form-control textBoxSizes" formControlName="password"
                            id="password" autocomplete="off" placeholder="Create Password"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                        <div class="showPassword">
                            <a (click)="showPassword()">
                                <img src="../../../assets/images/showPwd.png" *ngIf="eye" class="eyeIcon" alt="">
                            </a>
                            <a (click)="hidePassword()">
                                <img src="../../../assets/images/hidePwd.png" *ngIf="eyeSlash" class="eyeIcon" alt="">
                            </a>
                        </div>
                        <div *ngIf="submitted && f.password.errors" class="error invalid-feedback">
                            <div *ngIf="f.password.errors.required" style="font-family: 'Lato-Regular';">Password is
                                required</div>
                            <div *ngIf="f.password.errors.minlength" style="font-family: 'Lato-Regular';">Password must
                                be at least 6 characters</div>
                        </div>
                    </div>
                    <div class="form-group position-relative mb-0">
                        <input type="{{checktype}}" class="form-control textBoxSizes" formControlName="confirmPassword"
                            id="confirmPassword" autocomplete="off" placeholder="Confirm Password"
                            [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
                        <div class="showPassword">
                            <a (click)="showPassword()">
                                <img src="../../../assets/images/showPwd.png" *ngIf="eye" class="eyeIcon" alt="">
                            </a>
                            <a (click)="hidePassword()">
                                <img src="../../../assets/images/hidePwd.png" *ngIf="eyeSlash" class="eyeIcon" alt="">
                            </a>
                        </div>

                        <div *ngIf="submitted && f.confirmPassword.errors" class="error invalid-feedback">
                            <div *ngIf="f.confirmPassword.errors.required" style="font-family: 'Lato-Regular';">Confirm
                                Password is required</div>
                            <div *ngIf="f.confirmPassword.errors.mustMatch" style="font-family: 'Lato-Regular';">
                                Passwords must match</div>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button title="Login now" class="btn btn-success login_button">Login
                        now</button>
                </div>
            </form>
        </div>
        <div class="w-100 changePwd" *ngIf="_isPasswordchange == true">
            <p class="containerTitle">Password Reset </p>
            <p class="containerSubTitle">A message has been sent to you by email with instructions on how to
                reset your password.</p>
        </div>
    </div>
</div>