import { Component, OnInit, ViewChild } from '@angular/core';
import { FaqService } from '../services/faq/faq.service';

import { faqEntities } from '../faq/fqaentities'
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Table } from 'primeng/table';

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-default-intent',
  templateUrl: './default-intent.component.html',
  styleUrls: ['./default-intent.component.css']
})
export class DefaultIntentComponent implements OnInit {

  defaultIntentNumber: number = 3

  isReadonly: boolean = true
  isBlockSaveCancelButton: boolean = false
  isBlockEditButton: boolean = true
  _isSaveDiagramLoader: boolean = false
  _isSectionNameErrorFlag: boolean = false
  _isIntentNameErrorFlag: boolean = false
  _ifNoRecords: boolean = false
  isLoader: boolean = true;
  _isDefaultNumberErrorFlag: boolean = false
  _isMoreThenDefaultIntent: boolean = false

  sectionNameListArr: any = []
  FaqListArr: any = []
  DistinctParentIntentDataList: any = []
  intentNameList: any = []
  intentNameListArr: any = []
  nodeObjArr: any = []
  DefaultIntentListHeadersArr: any = []

  _SelectedSectionName: any
  _SelectedIntentName: any

  _faqObj = new faqEntities();

  popupImg: string;
  popupContent: string;
  popupContentError: string
  closeModal: any

  first: number = 0;

  sortColumnName: string;
  sortorder: boolean;
  descOrder: Boolean;

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;
  @ViewChild("modalDataDelete", { static: false }) public modalDataDelete: NgbModal;
  @ViewChild("dt", { static: false }) public ptable: Table;

  title = environment.pageTitle + ' - Default Intents';

  constructor(public faqconfigservice: FaqService, private modalService: NgbModal, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.GetDefaultIntentCountValue()
    this.fetchFaqList();
    this.DefaultIntentListHeaders();
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  DefaultIntentListHeaders() {
    this.DefaultIntentListHeadersArr = [
      { field: 'defaultIntentID', header: 'Sr No.', width: "5%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'Questions', header: 'FAQ', width: "15%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'BondModifiedOn', header: '', width: "4%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'b' }
    ]
  }

  sortcolumn(ColumnName: any) {

    let reverse = this.sortColumnName === ColumnName ? !this.descOrder : undefined;

    if (reverse === undefined)
      reverse = false;

    this.sortColumnName = ColumnName;
    this.descOrder = reverse;

    if (this.descOrder == true)
      this.sortorder = false;
    else
      this.sortorder = true;
  }

  //Fetch secion name 
  fetchFaqList() {
    this._isSaveDiagramLoader = true
    this.isLoader = true
    this.faqconfigservice.GetAllIntents().subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {

        this.isLoader = false

        this._isSaveDiagramLoader = false
        this.FaqListArr = res.Data

        let filteredArr: any[] = [];
        let result: any = [];

        this.FaqListArr = this.FaqListArr.filter((x: { SectionName: string, IsEndOfConversation: boolean }) => x.SectionName.toUpperCase() !== 'ACCOUNT OPENING' && x.IsEndOfConversation !== false);

        for (let i of this.DistinctParentIntentDataList) {
          this.FaqListArr = this.FaqListArr.filter((x: { IntentName: string; }) => x.IntentName !== i.ParentIntent);
        }

        result = Array.from(this.FaqListArr.reduce((m: any, t: any) => m.set(t.SectionName, t), new Map()).values());

        for (let i = 0; i < result.length; i++) {
          filteredArr.push({
            name: result[i].SectionName,
            value: result[i].SectionName
          });
        }
        this.sectionNameListArr = filteredArr;

        //get All campign FAQ to dilay data in table 
        this.nodeObjArr = []

        var CampaignFAQList = this.FaqListArr.filter((x: { IsCampaignFAQ: any; }) => x.IsCampaignFAQ == true);

        CampaignFAQList.forEach((value: any, key: any) => {
          this._faqObj = new faqEntities();
          this._faqObj.IntentMaintenance.SectionName = value.SectionName
          this._faqObj.IntentMaintenance.IntentName = value.Intent
          this._faqObj.IntentMaintenance.IsCampaignFAQ = value.IsCampaignFAQ
          this._faqObj.IntentMaintenance.Questions = value.Questions

          this.nodeObjArr.push(this._faqObj.IntentMaintenance)
        })

        if (this.nodeObjArr.length == 0) {
          this._ifNoRecords = true
        } else {
          this._ifNoRecords = false
        }

        this._faqObj = new faqEntities();
      }
      else {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  SelectSecionName(vselectedSectiName: any) {

    this._isSectionNameErrorFlag = false
    let result: any[] = [];

    this._faqObj.IntentMaintenance.Questions = ""

    // this.vselectedSectionName = vselectedSectiName.value.name
    this.intentNameList = this.FaqListArr.filter((x: { SectionName: string; }) => x.SectionName == vselectedSectiName.value.name)
    //result = Array.from(this.intentNameList.reduce((m: any, t: any) => m.set(t.IntentName, t), new Map()).values());

    this.intentNameListArr = []

    for (let i = 0; i < this.intentNameList.length; i++) {
      this.intentNameListArr.push({
        name: this.intentNameList[i].Intent,
        value: this.intentNameList[i].Intent
      });
    }

    this._faqObj.IntentMaintenance.Questions = ""


    if (this.nodeObjArr.length > 0) {
      for (let i of this.intentNameList) {
        let fetchExstingIntentName = this.nodeObjArr.find((x: { Question: string; }) => x.Question == i.Questions)

        if (fetchExstingIntentName) {
          var std = this.intentNameListArr.filter((x: { name: string; }) => x.name != fetchExstingIntentName.IntentName.name)
          this.intentNameListArr = std
        }
      }
    }
  }

  SelectIntentName(data: any) {

    this._isIntentNameErrorFlag = false
    let intentNameRecord


    intentNameRecord = this.intentNameList.filter((x: { Intent: string; }) => x.Intent == data.value.name)

    if (intentNameRecord.length != 0) {
      this._faqObj.IntentMaintenance.Questions = intentNameRecord[0].Questions
    }
  }

  _disableSelectButton = false
  _defaultNumber: number = 0
  disableSelectButton(Defultnumber: number, event: any) {
    
    this._disableSelectButton = true
    this._defaultNumber = Defultnumber;
    if (this.nodeObjArr.length > event.key) {

      this.popupImg = "../../../assets/images/update_error.png"
      this.popupContent = "Oops!"
      this.popupContentError = "The value cannot be less than already selected default intents count. Please remove default intents from the grid and then try to reset this value.";

      this.triggerModal(this.modalWarningAndInfo)

      this.defaultIntentNumber = Defultnumber;
    }

  }

  editDefaultNumber() {
    this.isReadonly = false
    this.isBlockSaveCancelButton = true
    this.isBlockEditButton = false
  }

  CancelBtnAction() {
    this.isBlockEditButton = true
    this.isBlockSaveCancelButton = false
    this.isReadonly = true
    this._isDefaultNumberErrorFlag = false

    if (this._defaultNumber) {
      this.defaultIntentNumber = this._defaultNumber
      this._disableSelectButton = false
    }
  }

  SaveBtnAction() {
    
    if (this.defaultIntentNumber) {

      var data = {
        SettingDesc: "DefaultIntentCount",
        SettingValue: this.defaultIntentNumber,
        Class: "BotDetails"
      }

      if (this.defaultIntentNumber > 5) {
        this._isDefaultNumberErrorFlag = true
        this.defaultIntentNumber = this._defaultNumber;
      } else {
        this._isDefaultNumberErrorFlag = false

        this._defaultNumber = 0;
        this.CancelBtnAction();

        this.faqconfigservice.UpdateAppsettingValue(data).subscribe((res: any) => {

          
          if (res.Status.toUpperCase() == "SUCCESS") {

            this._disableSelectButton = false

            this.popupContentError = "";
            this.popupImg = "../../../assets/images/Update.png";
            this.popupContent = res.Message
            this.triggerModal(this.modalWarningAndInfo)
          }
          else {
            this.popupImg = "../../../assets/images/CommonError.png";
            this.popupContent = "Oops!";
            this.popupContentError = res.Error;

            this.triggerModal(this.modalWarningAndInfo)
          }
        })
      }

    }
    else {
      this._isDefaultNumberErrorFlag = true
    }

  }

  SaveIntent() {
    

    // if (this._disableSelectButton == true) {
    //   this.popupImg = "../../../assets/images/CommonError.png"
    //   this.popupContent = "Oops!"
    //   this.popupContentError = "Please save the number of default intents value"

    //   this.triggerModal(this.modalWarningAndInfo)
    // }
    // else {
    if (!this._SelectedSectionName) {
      this._isSectionNameErrorFlag = true
    }
    else if (!this._SelectedIntentName) {
      this._isIntentNameErrorFlag = true
    }
    else
      if (this.defaultIntentNumber <= 5) {
        6 < 4
        if (this.nodeObjArr.length < this.defaultIntentNumber) {

          this._ifNoRecords = false

          if (this.nodeObjArr.length > 0) {
            this.nodeObjArr = this.nodeObjArr.filter((x: { Questions: string; }) => x.Questions !== this._faqObj.IntentMaintenance.Questions);
          }

          this._faqObj.IntentMaintenance.SectionName = this._SelectedSectionName.name == undefined ? this._SelectedSectionName : this._SelectedSectionName.name
          this._faqObj.IntentMaintenance.IntentName = this._SelectedIntentName.name == undefined ? this._SelectedIntentName : this._SelectedIntentName.name
          this._faqObj.IntentMaintenance.IsCampaignFAQ = true

          this.SaveSelectedIntentInDB(this._faqObj.IntentMaintenance, "ADD")

          this.nodeObjArr.push(this._faqObj.IntentMaintenance)

          this._SelectedIntentName = ""
          this._faqObj = new faqEntities()
          this._SelectedSectionName = ""

        } else {

          this.popupImg = "../../../assets/images/update_error.png"
          this.popupContent = "Oops!"
          this.popupContentError = "Total number of default intents cannot be greater than " + this.defaultIntentNumber;

          this.triggerModal(this.modalWarningAndInfo)
        }
      }

      else {
        this._isDefaultNumberErrorFlag = true
      }

  }

  CancelIntent() {
    this._faqObj = new faqEntities()
    this._SelectedIntentName = ""
    this._SelectedSectionName = ""
  }

  sendDataToDelete: any
  public boundDeleteRow = this.fetchCurrentRowDetailsToDelete.bind(this);

  OpenDeletePopup(rowDetails: any) {

    this.popupImg = "../../../assets/images/delete_popup_icon.png";
    this.popupContent = "Do you want to remove the default intent?";
    this.popupContentError = "";
    this.sendDataToDelete = rowDetails
    this.triggerModal(this.modalDataDelete)
  }

  fetchCurrentRowDetailsToDelete() {

    this.DeleteCurrentRow(this.sendDataToDelete);
  }

  DeleteCurrentRow(vSelectedRow: any) {

    this._faqObj.IntentMaintenance.SectionName = vSelectedRow.SectionName
    this._faqObj.IntentMaintenance.IntentName = vSelectedRow.IntentName
    this._faqObj.IntentMaintenance.IsCampaignFAQ = false

    this.SaveSelectedIntentInDB(this._faqObj.IntentMaintenance, "DELETE");
  }

  //Fetch secion name 
  SaveSelectedIntentInDB(data: any, vActionName: any) {

    this.isLoader = true
    this.faqconfigservice.UpdateIntentValue(data).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {

        this.isLoader = false;
        if (vActionName.toUpperCase() == "DELETE") {
          this.fetchFaqList();
          // this.isLoader = false;


          this._SelectedIntentName = ""
          this._faqObj = new faqEntities()
          this._SelectedSectionName = ""


          this.popupContentError = "";
          this.popupImg = "../../../assets/images/delete-popup.png";
          this.popupContent = "Default intent removed successfully."
          this.triggerModal(this.modalWarningAndInfo)
        }
      }
      else {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  //Get Default Intent Count Value
  GetDefaultIntentCountValue() {

    var data = {
      SettingDesc: "DefaultIntentCount",
      Class: "BotDetails"
    }

    this.faqconfigservice.GetDefaultIntentCountValue(data).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {

        this.defaultIntentNumber = res.Data.SettingValue;
      }
      else {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }
}
