<div class="customThemeModal">
    <div class="" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="closePopup">
                    <a (click)="inputVariable.dismissFunction()">
                        <img src="../../../assets/images/cross.png" class="cross-img">
                    </a>

                </div>
                <div class="popupImg">
                    <img src="{{inputVariable.image}}" class="left-img">
                </div>
                <div class="popupTitle">{{inputVariable.popupTitle}}</div>
                <div class="popupContent" [innerHtml]="inputVariable.popupContent">{{inputVariable.popupContent}}</div>
            </div>
        </div>
    </div>
</div>