<div class="container-fluid screen-container xl-bg-transperent " *ngIf="_showAddEditScreen==false">

    <div class="mainContainerWrapper intentTestDetailsWrapper">

        <div class="header row">
            <div class="col-md-12">
                <div class="float-left">
                    <span class="page-title">Intent Test Details</span>
                </div>

                <div class="float-right">
                    <button [hidden]="true" title="Add User" type="button" class="btn btn-primary"
                        (click)="addNewRow('AddUser')"><img src="../../../assets/images/plus_icon.png"
                            class="left-img"><span class="align-middle">addUser</span></button>

                </div>
            </div>
        </div>

        <div class="row subHeader xl-pl-20px xl-pr-20px">
            <div class="col-md-12" style="margin-bottom: 10px!important;">
                <div class="float-left">
                    <div class="search-field">

                        <div class="static-img"><img src="../../../assets/images/search.png"></div>
                        <input type="text" class="form-control custom-input-field" placeholder="Search"
                            [(ngModel)]="searchUSER" (keypress)="searchData($event, 'Search')">
                        <div class="multiselectSearch">
                            <p-multiSelect [options]="searchOptionArr" [disabled]='searchUSER==""'
                                [(ngModel)]="selectedSearchOpt" (ngModelChange)="selectSearchOption($event)"
                                optionLabel="name" display="chip" [showHeader]="false"></p-multiSelect>
                        </div>
                    </div>
                </div>

                <div class="float-right ">
                    <div class="label"></div>
                    <!-- <p-dropdown [options]="language" (onChange)='selectSearchOption($event)'  class="CalenderDropDown" [(ngModel)]="selectedLanguage"
                         optionLabel="name" >
                    </p-dropdown> -->

                    <div class="float-right" (clickOutside)="onClickedOutside($event)">
                        <div class="dropdown-field">
                            <div class="dropdown-input-field" (click)="clickEvent()">
                                <div class="static-img" *ngIf="isHide"><img src="../../../assets/images/Filter.png">
                                </div>
                                <div class="static-img" *ngIf="chageFilterInput == true"><img
                                        src="../../../assets/images/filter_icon.png"></div>
                                <input type="text" class="form-control custom-input-field"
                                    [ngClass]="{'selectedfilterInput':chageFilterInput == true, 'selectedfilterInputOutline':selectedFieldOutline, 'greyInputOutline':greyFieldOutline == true}"
                                    placeholder="Filter" disabled>
                                <div class="filterChips">
                                    <span *ngIf="chageFilterInput == true">Filter/{{totalSelectedValues}}</span>
                                </div>
                                <a class="dropdownArrow" (mouseover)="changeStyle()" (mouseout)="changeStyle()"
                                    (click)="clickEvent()">
                                    <img *ngIf="isHide" (click)="clickEvent()"
                                        src="../../../assets/images/arrow-down.png">
                                    <img *ngIf="chageFilterInput == true" (click)="clickEvent()"
                                        src="../../../assets/images/Icon ionic-ios-arrow-down.png">
                                </a>
                            </div>
                        </div>
                        <div [ngClass]="{'showFilterContainer': toggle, 'hideFilterContainer':toggle == false}">
                            <div class="mt-2 filterArrContainer">
                                <div class="filterHeaderBlock">
                                    <span class="filterCotainerTitle">Filter types</span>
                                    <span class="float-right filterContainerLink"><a
                                            (click)="clearInputField()">Clear</a></span>
                                </div>
                                <div class="mainBlock">
                                    <div class="block" *ngFor="let item of filterArr">
                                        <div class="filterArrTitle">{{item.label}}</div>
                                        <div *ngFor="let data of item.items">
                                            <ul class="pl-0">
                                                <li>
                                                    <label class="filterOptions mb-0"
                                                        [ngClass]="{'selectedFilterOptions':data.checked == true}">
                                                        <input type="checkbox" name="groupname" value="{{data.name}}"
                                                            [(ngModel)]="data.checked"
                                                            (ngModelChange)="selectFilterOption(data)" />
                                                        <div class="filter-status-color"
                                                            *ngIf="item.value == 'faqstatus'"
                                                            [ngClass]="{'status-bg-live': data.status === 'live', 'status-bg-live-update':data.status === 'liveContentUpdate',
                                                'status-bg-content-approved': data.status === 'liveContentApproved', 'status-bg-not-live': data.status === 'notLive'}">
                                                        </div>
                                                        {{data.name}}
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="row faqListTable">

            <div class="tableContent" [hidden]="_ifNoRecords">

                <p-table #dt [value]="userListArr" styleClass="p-custom-datatable" [rows]="10" [paginator]="true"
                    reflow="true" paginatorTemplate="{RowsPerPageDropdown}" paginatorPosition="bottom"
                    paginatorPosition="bottom" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,40,50]"
                    currentPageReportTemplate="Displaying {last} of {totalRecords}" [rowHover]="true"
                    [scrollable]="true" scrollHeight="flex" [(first)]="first" editMode="row" [editable]="true"
                    (onRowEditInit)="onRowEditInit($event)" [resizableColumns]="true">

                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col *ngFor="let col of userHeadersArr" [style.width]='col.width'>
                        </colgroup>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn *ngFor="let col of userHeadersArr" [pSortableColumn]="col.field"
                                [pSortableColumnDisabled]="col.field === 'status'" style="text-align: left;"
                                [ngClass]="col.field" data-toggle="tooltip" data-placement="top" title="{{col.header}}">
                                <div class="tableHeader" *ngIf="col.isSort === 'true'" (click)="sortcolumn(col.field)"
                                    [ngClass]="{'border-right-dadadd': col.field != 'RoleName' && col.field != 'status'}">
                                    <div class="d-inline-block"
                                        [ngClass]="{'text-overflow-header': col.width > '500%', 'pl-15':col.field === 'DispID', 'xl-pl-20px':col.field === 'DispID'}">
                                        {{col.header}}</div>
                                    <div class="tableSortIcon">
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </div>
                                </div>
                                <div *ngIf="col.isSort === 'false'">
                                    {{col.header}}
                                </div>
                            </th>

                        </tr>
                        <tr *ngIf="isLoader">
                            <td *ngFor="let col of userHeadersArr">
                                <div class="item customSkeletonLoader">
                                    <ngx-skeleton-loader count="20" appearance=""></ngx-skeleton-loader>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-car let-ri="rowIndex">

                        <tr *ngIf="ri == 0 && newrow" class="newTableRow">
                            <td *ngFor="let col of userHeadersArr" style="padding: 0!important">

                                <div *ngIf="col.field === 'EmailId'" class="autoSelectedFilter">
                                    <p-autoComplete [(ngModel)]="this.addRecord.UserDetails.EmailId"
                                        [suggestions]="filteredEmailId" (completeMethod)="filterEmailId($event)"
                                        field="name" [minLength]="1" [maxlength]="50" placeholder="Select Section">
                                    </p-autoComplete>
                                </div>

                                <div *ngIf="col.field == 'EmailId'">
                                    <input pInputText type="text" class="newInputField form-control" [minLength]="1"
                                        [maxlength]="200" placeholder="Add your question here ..."
                                        [(ngModel)]="this.addRecord.UserDetails.FirstName" required>
                                </div>

                                <div *ngIf="col.field == 'LastName'">
                                    <textarea #comments class="form-control newInputField" rows="0" id="comment"
                                        [minLength]="1" [maxlength]="2000" placeholder="Add your answer here ..."
                                        [(ngModel)]="this.addRecord.UserDetails.LastName" required
                                        (keydown)="onKeypressEvent($event)"></textarea>
                                </div>


                                <div *ngIf="col.field === 'status'">
                                    <button title="Cancel" class="cancelRow btn btn-secondary">Cancel</button>
                                </div>
                            </td>
                        </tr>

                        <tr *ngIf="!_hideEmptyRow">
                            <td pEditableColumn *ngFor="let col of userHeadersArr"
                                [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">
                                <div [hidden]="_ViewClientRoleAccess"
                                    [ngClass]="{'modDtBlock': col.field === 'RoleName', 'text-overflow-field': col.field === 'LastName' || col.field === 'FirstName'}">

                                    <div [hidden]="_ViewClientRoleAccess"
                                        *ngIf="col.field != 'RoleName' && col.field != 'LastName'"
                                        [ngClass]="{'xl-pl-14px':col.field !== 'DispID', 'pl-10':col.field !== 'DispID','pl-25':col.field === 'DispID', 'xl-pl-35px':col.field === 'DispID'}">
                                        {{car[col.field]}}
                                    </div>

                                    <div *ngIf="col.field === 'EmailId'"
                                        [ngClass]="{'xl-pl-14px':col.field !== 'DispID', 'pl-10':col.field !== 'DispID'}"
                                        [innerHtml]="car[col.field]">
                                    </div>


                                    <div [hidden]="_ViewClientRoleAccess" *ngIf="col.field === 'RoleName'"
                                        [ngClass]="{'xl-pl-14px':col.field !== 'DispID', 'pl-10':col.field !== 'DispID'}">
                                        {{car[col.field]}}
                                    </div>

                                    <div [hidden]="_ViewClientRoleAccess" class="filter-status-color"
                                        *ngIf="col.field === 'status'"
                                        [ngClass]="{'status-bg-live': car.IsLiveRawIntent == true, 'status-bg-live-update':car.IsModifiedRawIntent == true, 
                                    'status-bg-content-approved': car.IsContentApprovedRawIntent == true, 'status-bg-not-live': car.IsNewRawIntent == true, 'xl-pl-14px':col.field !== 'DispID', 'pl-10':col.field !== 'DispID'}">
                                    </div>
                                </div>

                                <div [hidden]="!_ViewClientRoleAccess"
                                    [ngClass]="{'text-overflow-field': col.field === 'LastName' || col.field === 'FirstName'}">

                                    <div [hidden]="!_ViewClientRoleAccess" *ngIf="col.field != 'RoleName'"
                                        [ngClass]="{'xl-pl-14px':col.field !== 'DispID', 'pl-10':col.field !== 'DispID','pl-25':col.field === 'DispID', 'xl-pl-35px':col.field === 'DispID'}">

                                        {{car[col.field]}}
                                    </div>

                                </div>

                                <div [hidden]="_ViewClientRoleAccess" class="actions w-100"
                                    [ngClass]="{'showactionBlock': col.field === 'RoleName', 'xl-pl-14px':col.field !== 'DispID', 'pl-10':col.field !== 'DispID'}">
                                    <span>
                                        <a class="mr-2 cursor-pointer"
                                            (click)="OnAddEditBtnClick('EditUser', car.EmailId)"
                                            class="faqModifyActionBtn" title="Modify">
                                            <img class="block_img" src="../../../assets/images/Modify.png">
                                            <img class="hover_modify_img"
                                                src="../../../assets/images/Modify_on_hover.png">
                                        </a>
                                    </span>
                                    <span>

                                        <a class="mr-2" (click)="OpenDeletePopup(car)" class="faqDeleteActionBtn"
                                            title="Delete">
                                            <img class="block_img" src="../../../assets/images/delete.png">
                                            <img class="hover_delete_img"
                                                src="../../../assets/images/delete_on_hover.png">
                                        </a>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </ng-template>


                </p-table>
            </div>

            <div class="noRecordFound text-center border-top" *ngIf="_ifNoRecords">
                <img src="../../assets/images/no_records.png">
                <p class="title">No Result Found</p>
                <p class="mb-0 subtitle">Sorry, we couldn’t find any results for this search.</p>
            </div>
        </div>
    </div>
</div>

<app-adduserdetails (inputdata)="AddEditPageResult($event)" *ngIf="_showAddEditScreen==true"
    [inputVariable]="{PageName: PageName, EditRowID: EditRowID, userListArrData: userListArrData}">
</app-adduserdetails>
<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>