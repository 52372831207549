<div class="container-fluid">
    <div class="loginPageHeader text-center">
        <div class="loginPageTitle">
            <img src="../../../assets/images/login-title.png" alt="">
        </div>
        <div class="loginPageSubtitle">
            <img src="../../../assets/images/login-subtitle.png" alt="">
        </div>
    </div>
    <div class="login-container">
        <div *ngIf="_isPasswordReset == false">
            <p class="containerTitle">Forgot Password ? </p>
            <p class="containerSubTitle">Please enter the email address you used to create your account, and we'll send
                you
                a link to reset your password.</p>

            <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" class="login-Form w-100">
                <div class="form-group mb-0">
                    <input type="text" class="form-control textBoxSizes " formControlName="username" id="username"
                        autocomplete="off" placeholder="Email">
                    <div class="error lablesCommon"
                        *ngIf="forgotPasswordForm.controls['username'].hasError('required') && forgotPasswordForm.controls['username'].touched"
                        style="color: red;">Username is required</div>
                </div>
                <button title="Login now" class="btn btn-success login_button" [disabled]="forgotPasswordForm.invalid">Login
                    now</button>
            </form>
        </div>
        <div *ngIf="_isPasswordReset == true">
            <p class="containerTitle">Password Changed!</p>
            <p class="containerSubTitle">Your password has been changed successfully</p>
            <button title="Login now" class="btn btn-success login_button" (click)="redirectToLogin()">Login
                now</button>
        </div>
    </div>
</div>