<div class="col-md-8 col-sm-8 col-xs-8 " class="container-fluid screen-container bg-fff xl-bg-transperent ">
    <div class="col-md-10 col-sm-10 col-xs-10 inner-container"
        style="margin-left:auto!important;margin-right:auto!important;border-style:solid;">
        <form class="col-md-12 " style="padding:15px; border-style:none;" name="form">
            <div class="form-group ">
                <div class="row col-md-12 ">
                    <span class="col-md-3 col-sm-12 col-xs-12 PopupLables"> Model name:</span>
                    <input type="text" value="Bond" disabled
                        class=" col-md-3 col-sm-12 col-xs-12 form-control PopupInputeTextBox" id="modelname"
                        [(ngModel)]="_modelName" name='_modelname' [ngModelOptions]="{standalone: true}" />

                    <span class="col-md-3 col-sm-12 col-xs-12 PopupLables"> Version :</span>
                    <input class="col-md-3 col-sm-12 col-xs-12 form-control  PopupInputeTextBox" disabled id="version"
                        [(ngModel)]="_newVersion" name='version' [ngModelOptions]="{standalone: true}" />
                </div>
                <br />

                <div class="row col-md-12">
                    <!-- <span class="col-md-3 col-sm-12 col-xs-12 PopupLables"> Build Model For <span
                            style="color:red;">*</span>:</span> -->

                    <!-- <select class="col-md-3 col-sm-12 col-xs-12 form-control  popupDropdown" disabled
                        [(ngModel)]="_Lang" [ngModelOptions]="{standalone: true}">
                        <option ng-repeat="Lang in Language">{{_Lang}}</option>
                    </select> -->
                    <span class="col-md-3 col-sm-12 col-xs-12 PopupLables"> Perform tests for :</span>

                    <div class="col-md-3 col-sm-12 col-xs-12 form-group custom-control custom-checkbox checkbox-xl"
                        style="padding-left: 38px;">

                        <p-checkbox name="groupname" id="IntentTest" class="mr-24px" [disabled]="true"
                            [(ngModel)]="_BuildmodelIP.IntentTest" label="Intent" [binary]="_BuildmodelIP.IntentTest">
                        </p-checkbox>

                    </div>

                    <div class="col-md-3 col-sm-12 col-xs-12 form-group custom-control custom-checkbox checkbox-xl ">

                        <p-checkbox name="groupname" id="StartTesting" [disabled]="true"
                            [(ngModel)]="_BuildmodelIP.StartTesting" label="Utterance"
                            [binary]="_BuildmodelIP.StartTesting">
                        </p-checkbox>

                    </div>
                </div>
                <br />
            </div>

            <hr class="fontTextCommon" />

            <div class="form-group col-md-12 col-sm-12 col-xs-12" style="padding-left:0px;" *ngIf="_hideonCancel">
                <div class="row col-md-12 ">
                    <span class="col-md-3 PopupLables"> Progress:</span>

                    <div class="progress col-md-9 " style="padding-left:0px!important;padding-right:0px">
                        <div class="progress-bar" role="progressbar" aria-valuenow="_BuildModelPerc"
                            [style.width]="_BuildModelPerc + '%'" aria-valuemin="0" aria-valuemax="100">
                            {{_BuildModelPerc}}%</div>
                    </div>

                </div>
                <br />
                <div class="row col-md-12 ">
                    <span class="col-md-3 PopupLables"> Current Activity:</span>

                    <div class=" col-md-9 " style="padding-left:0px!important;">
                        <div role="progressbar" aria-valuenow="_BuildActivity" [style.width]="_BuildActivity">
                            {{_BuildActivity}}</div>
                    </div>

                </div>
                <br />

                <div class="form-group col-md-12 col-sm-12 col-xs-12" style="border-top:none;">
                    <div class="row col-md-12 " style="border-top:none;">
                        <table class="table" style="border-top:none;">
                            <tbody style="border-top:none;">
                                <tr *ngFor="let testData of _TestSummaryData" style="border-top:none;">
                                    <td *ngIf="testData.TestFrom == 'IT'">
                                        <span class="lable-td">Intent Test</span>
                                    </td>
                                    <td *ngIf="testData.TestFrom == 'IMT'">
                                        <span class="lable-td"> Utternace Test </span>
                                    </td>
                                    <td>
                                        <span class="lable-td"> Total Intent: </span>
                                        <span>{{testData.TotalUtternace}} </span>
                                    </td>
                                    <td>
                                        <span class="lable-td"> Pass: </span>
                                        <span style="color:green;">{{testData.PassCnt}}</span>
                                    </td>
                                    <td>
                                        <span class="lable-td"> Fail: </span>
                                        <span style="color:red;"> {{testData.FailCnt}}</span>
                                    </td>
                                    <td>
                                        <span class="lable-td"> Bad Pick: </span>
                                        <span style="color:orange;">{{testData.BadPickCnt}}</span>
                                    </td>
                                    <td>
                                        <span class="lable-td"> Low Confid: </span>
                                        <span>{{testData.LowConfidenceCnt}}</span>
                                    </td>
                                    <!-- <td style="width:20px;">
                                        <span><a href="IntentTestingSummary.html?test={{testData.TestFrom}}&ID={{BuildActivityId}}" target="_blank" onclick="">More Details</a></span>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                </div>

            </div>

            <div class="col-md-12 cls-col">

                <span style="color:rgb(0, 30, 128);font-weight:bold;"
                *ngIf="_IsDisplayCFYes">There are conflicts between some intents. Please click on <a >"Confusion Matrix"</a> option to check the report.</span>

                <span style="color:green;font-weight:bold;margin-left: 15%;"
                *ngIf="_IsDisplayCFNo">There are no conflicts between the intents.</span>

                <div>
                    <span style="color:green;font-weight:bold;margin-left: 15%;"
                        *ngIf="_allCompleted">{{_allCompletedMSG}} </span>

                      
                    <button type="button" *ngIf="!_allCompleted" class="btn btn-success float-left" name="Back"
                        routerLink="/portal/ChatBOT/FAQList" title="Back">Back</button>

                    <button type="button" *ngIf="!_allCompleted" class="btn btn-success float-right" (click)="CloseBuildActivityModel()"
                        name="cancel" title="Cancel">Cancel</button>
                    
                        <button *ngIf="_allCompleted" type="button" class="btn btn-success float-right" 
                        name="Close" title="Close" routerLink="/portal/ChatBOT/FAQList">Close</button>
                </div>
            </div>
        </form>
    </div>
</div>