import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { LoginService } from '../../services/Login/login.service'
import { TenantSelectionService } from '../../services/TenantSelection/tenant-selection.service'

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  invalidLogin: boolean = false;

  checktype: string = "password"
  eye: boolean = false
  eyeSlash: boolean = true;

  spinnerloader: boolean = false

  title = environment.pageTitle + ' - Login Page';

  constructor(private formBuilder: FormBuilder, private router: Router, public loginService: LoginService, private titleService: Title, public tenantSelectionService: TenantSelectionService) { }

  ngOnInit(): void {
    ;
    window.localStorage.clear();

    this.titleService.setTitle(this.title);

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required])],
      password: ['', Validators.required]
    });
  }

  onSubmit() {

    this.spinnerloader = true

    if (this.loginForm.invalid) {
      return;
    }
    const loginPayload = {
      EmailId: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value
    }

    this.loginService.Login(loginPayload).subscribe((res: any) => {


      if (res.Status == "Success") {
        window.localStorage.setItem("AccessToken", res.Data.AccessToken);
        window.localStorage.setItem("RoleName", res.Data.RoleName);
        window.localStorage.setItem("UserId", res.Data.UserId);
        window.localStorage.setItem("UserName", res.Data.UserName);
        window.localStorage.setItem("SessionId", res.Data.SessionId);
        window.localStorage.setItem("TenantId", res.Data.TenantId);

        sessionStorage.setItem("SessionId", res.Data.SessionId);
        sessionStorage.setItem("RoleName", res.Data.RoleName);
        sessionStorage.setItem("UserName", res.Data.UserName);
        sessionStorage.setItem("AccessToken", res.Data.AccessToken);
        sessionStorage.setItem("UserId", res.Data.UserId);
        sessionStorage.setItem("TenantId", res.Data.TenantId);

        if (res.Data.RoleName.toUpperCase() === 'AGENT') {
          this.router.navigateByUrl('portal/PersonaCluster');
        } else if (res.Data.RoleName.toUpperCase() === 'ENTERPRISE') {
          this.router.navigateByUrl('portal/EnterpriseAccountAnalytics');
        } else if (res.Data.RoleName.toUpperCase() === 'BANKUSER') {
          this.router.navigateByUrl('portal/BankUserAccountAnalytics');
        }

        else if (res.Data.RoleName.toUpperCase() === 'ADMIN' || res.Data.RoleName.toUpperCase() === 'CONTENT' || res.Data.RoleName.toUpperCase() === 'CONTENTVIEW') {
          this.tenantSelectionService.getTenants().subscribe((res: any) => {
            if (res.Status.toUpperCase() == "SUCCESS") {
              if (res.Data.length == 1) {
                this.router.navigateByUrl('portal/AccountAnalytics');
              } else {
                window.localStorage.setItem("setting", "true");
                this.router.navigateByUrl('portal/settings');
              }
            }
          })
        } else {
          this.router.navigateByUrl('portal/AccountAnalytics');
        }

        this.spinnerloader = false
      }
      else {
        this.invalidLogin = true;
      }
    })
  }

  showPassword() {
    this.checktype = "password"
    this.eye = false
    this.eyeSlash = true
  }

  hidePassword() {
    this.checktype = "text"
    this.eye = true
    this.eyeSlash = false
  }

  onKeyUp(data: any) {

    if (data.target.value != "") {
      this.spinnerloader = false
      this.invalidLogin = false;
    }
  }
}
