import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MustMatch } from '../../services/MustMatchValidator/must-match.service';

import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PasswordService } from 'src/app/services/password/password.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

  passwordForm: FormGroup;
  passwordSubmitted = false;

  checktype: string = "password"
  eye: boolean = false
  eyeSlash: boolean = true;

  oldPwdchecktype: string = "password"
  oldPeye: boolean = false
  oldPeyeSlash: boolean = true;

  ConfirmPwdchecktype: string = "password"
  ConfirmPeye: boolean = false
  ConfirmPeyeSlash: boolean = true;

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string

  _confirmPassword: any
  _newpassword: any
  _oldpassword: any

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;

  constructor(private formBuilder: FormBuilder, private router: Router,  public passwordService: PasswordService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.passwordFormGroup()
  }

  passwordFormGroup() {

    this.passwordForm = this.formBuilder.group({
      oldpassword: ['', [Validators.required, Validators.minLength(6)]],
      password: ['', [Validators.required,
      Validators.minLength(8),
      Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
      ]],
      confirmPassword: ['', Validators.required],
    },
      {
        validator: MustMatch('password', 'confirmPassword')
      });
  }

  get p() { return this.passwordForm.controls; }

  submitPassword() {
    
    this.passwordSubmitted = true;

    if (this.passwordForm.invalid) {
      return;
    }
    else {
      

      var UserId = window.localStorage.getItem("UserId")
      var AccessToken = window.localStorage.getItem("AccessToken")

      var data = {
        UserId: UserId,
        AccessToken: AccessToken,
        OldPassword: this.passwordForm.controls.oldpassword.value,
        NewPassword:  this.passwordForm.controls.password.value,
        ConfirmPassword:  this.passwordForm.controls.confirmPassword.value,
        LangCode: 'EN'
      }

      this.passwordService.ChangeUserPassword(data).subscribe((res: any) => {

        if (res.Status.toUpperCase() == "SUCCESS") {

          this.router.navigateByUrl('login');

          this.popupImg = "../../../assets/images/Update.png";
          this.popupContent = "";
          this.popupContentError = res.Message+" Please login again.";

          this.triggerModal(this.modalWarningAndInfo)
        }
        else if (res.Error) {

          this.popupImg = "../../../assets/images/CommonError.png";
          this.popupContent = "Oops!";
          this.popupContentError = res.Error;

          this.triggerModal(this.modalWarningAndInfo)
        }
      })
    }

  }

  showOldPassword() {
    this.oldPwdchecktype = "password"
    this.oldPeye = false
    this.oldPeyeSlash = true
  }

  hideOldPassword() {
    this.oldPwdchecktype = "text"
    this.oldPeye = true
    this.oldPeyeSlash = false
  }

  showPassword() {
    this.checktype = "password"
    this.eye = false
    this.eyeSlash = true
  }

  hidePassword() {
    this.checktype = "text"
    this.eye = true
    this.eyeSlash = false
  }

  showConfirmPassword() {
    this.ConfirmPwdchecktype = "password"
    this.ConfirmPeye = false
    this.ConfirmPeyeSlash = true
  }

  hideConfirmPassword() {
    this.ConfirmPwdchecktype = "text"
    this.ConfirmPeye = true
    this.ConfirmPeyeSlash = false
  }

  closeModal: any
  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
