import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FaqlistComponent } from './faq/faqlist/faqlist.component';
import { HeaderComponent } from './header/header.component';
import { DeletedfaqsComponent } from './faq/deletedfaqs/deletedfaqs.component';
import { TableTemplateComponent } from './commonComponent/table-template/table-template.component';
import { SidebarComponent } from './commonComponent/sidebar/sidebar.component';
import { ThemeStructureComponent } from './theme-structure/theme-structure.component';
import { LoginComponent } from './LoginComponent/login/login.component';
import { NewAccountsComponent } from './NewAccounts/new-accounts.component';
import { AMLComponent } from './aml/aml.component';
import { KYCComponent } from './kyc/kyc.component';
import { FailedFAQsComponent } from './faq/failed-faqs/failed-faqs.component';
import { FAQUsageComponent } from './faq/faqusage/faqusage.component';
import { DashboardTargetComponent } from './dashboard-target/dashboard-target.component';
import { LKPListComponent } from './lkp-list/lkp-list.component';
import { SingleWordComponent } from './single-word/single-word.component';
import { ProductionLogComponent } from './production-log/production-log.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { NotficationComponent } from './notfication/notfication.component';
import { SettingsComponent } from './settings/settings.component';
import { LogoutComponent } from './logout/logout.component';
import { TestBotComponent } from './test-bot/test-bot.component';
import { PersonaClusterComponent } from './persona-cluster/persona-cluster.component';
import { ClusterComponent } from './cluster/cluster.component';
import { UsageDashboardComponent } from './usage-dashboard/usage-dashboard.component';
import { UserChatHistoryComponent } from './user-chat-history/user-chat-history.component';
import { AddfaqComponent } from './faq/addfaq/addfaq.component';

import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import {MultiSelectModule} from 'primeng/multiselect';
import {CheckboxModule} from 'primeng/checkbox';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {CalendarModule} from 'primeng/calendar';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputSwitchModule} from 'primeng/inputswitch';
import {RadioButtonModule} from 'primeng/radiobutton';
import {EditorModule} from 'primeng/editor';
import {RatingModule} from 'primeng/rating';
import {SliderModule} from 'primeng/slider';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { JwtHelperService, JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { RoleGuardService } from './services/auth/role-guard.service';
import { AuthService } from './services/auth/auth.service';

import { ClickOutsideModule } from 'ng-click-outside';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { WarningAndInfoModalComponent } from './commonComponent/warning-and-info-modal/warning-and-info-modal.component';
import { ConfirmationModalComponent } from './commonComponent/confirmation-modal/confirmation-modal.component';
import { ChatbotComponent } from './commonComponent/chatbot/chatbot.component';
import { AssignModelComponent } from './faq/assign-model/assign-model.component';
import { AgentLoginComponent } from './agent-login/agent-login.component';

import { BuildActivityComponent } from './faq/build-activity/build-activity.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';

// import {TestBotLibModule} from 'test-bot-lib'

import {DatePipe} from '@angular/common';

import {WebcamModule} from 'ngx-webcam'

import { NgxPlaidLinkModule } from "ngx-plaid-link";

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AccountinformationComponent } from './settings/accountinformation/accountinformation.component';
import { PasswordComponent } from './settings/password/password.component';
import { TenantselectionComponent } from './settings/tenantselection/tenantselection.component';

import { SortPipe } from './services/Sorting/sort.pipe';
import { ConvDesignFlowComponent } from './faq/conv-design-flow/conv-design-flow.component';
import { AdduserdetailsComponent } from './user-details/adduserdetails/adduserdetails.component';
import { IntentTestDetailsComponent } from './intent-test-details/intent-test-details.component';

// import the DiagramMOdule for the Diagram component
import { DiagramModule, SymbolPaletteModule, HierarchicalTreeService, DiagramContextMenuService  } from '@syncfusion/ej2-angular-diagrams';

import { AccordionModule } from '@syncfusion/ej2-angular-navigations';

// import the TabModule for the Tab component
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { ClickableFlowComponent } from './faq/clickable-flow/clickable-flow.component';
import { AddClickableFlowComponent } from './faq/add-clickable-flow/add-clickable-flow.component';
import { ConfusionMatrixComponent } from './confusion-matrix/confusion-matrix.component';
import { DefaultIntentComponent } from './default-intent/default-intent.component';
import { IntentListComponent } from './faq/intent-list/intent-list.component';
import { ContextFlowComponent } from './faq/context-flow/context-flow.component';
import { AddContextFlowComponent } from './faq/add-context-flow/add-context-flow.component';
import { EnterpriseDashboardComponent } from './enterprise-dashboard/enterprise-dashboard.component';
import { EnterpriseUserAccountComponent } from './enterprise-user-account/enterprise-user-account.component';
import { BankUserDashboardComponent } from './bank-user-dashboard/bank-user-dashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    FaqlistComponent,
    HeaderComponent,
    DeletedfaqsComponent,
    TableTemplateComponent,
    SidebarComponent,
    ThemeStructureComponent,
    LoginComponent,
    NewAccountsComponent,
    AMLComponent,
    KYCComponent,
    FailedFAQsComponent,
    FAQUsageComponent,
    DashboardTargetComponent,
    LKPListComponent,
    SingleWordComponent,
    ProductionLogComponent,
    UserDetailsComponent,
    NotficationComponent,
    SettingsComponent,
    LogoutComponent,
    TestBotComponent,
    PersonaClusterComponent,
    UsageDashboardComponent,
    UserChatHistoryComponent,
    ClusterComponent,
    UnauthorizedComponent,
    AccessDeniedComponent,
    WarningAndInfoModalComponent,
    ConfirmationModalComponent,
    AddfaqComponent,
    ChatbotComponent,
    AssignModelComponent,
    AgentLoginComponent,
    BuildActivityComponent,
    ForgotpasswordComponent,
    ResetpasswordComponent,
    AccountinformationComponent,
    PasswordComponent,
    TenantselectionComponent,
    SortPipe,
    AdduserdetailsComponent,
    IntentTestDetailsComponent,
    AdduserdetailsComponent,
    ConvDesignFlowComponent,
    // SortPipe,
    AdduserdetailsComponent,
    ClickableFlowComponent,
    AddClickableFlowComponent,
    ConfusionMatrixComponent,
    DefaultIntentComponent,
    IntentListComponent,
    ContextFlowComponent,
    AddContextFlowComponent,
    EnterpriseDashboardComponent,
    EnterpriseUserAccountComponent,
    BankUserDashboardComponent,
  ],
  imports: [
    BrowserModule,
    ClickOutsideModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TableModule,
    DropdownModule,
    CheckboxModule,
    PaginatorModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    MultiSelectModule,
    CalendarModule,
    ProgressBarModule,
    InputSwitchModule,
    RadioButtonModule,
    EditorModule,
    RatingModule,
    // TestBotLibModule,
    NgxSkeletonLoaderModule,
    WebcamModule,
    NgxPlaidLinkModule,
    NgxMaskModule.forRoot(),
    DiagramModule,
    SymbolPaletteModule,
    AccordionModule,
    SliderModule,
    TabModule,
    JwtModule.forRoot({})// AngularFontAwesomeModule
    // JwtModule.forRoot({})
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService, AuthService, DatePipe, SortPipe, HierarchicalTreeService, DiagramContextMenuService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
