import { Component, Input, OnInit, Output, ViewChild, ElementRef, HostListener, EventEmitter, Pipe } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../services/Notification/notification.service'

import { Entities } from '../../../src/app/faq/deletedfaqs/Entities'

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notfication',
  templateUrl: './notfication.component.html',
  styleUrls: ['./notfication.component.css']
})
export class NotficationComponent implements OnInit {

  @Input() inputVariable: any;

  title = environment.pageTitle + ' - Notification';

  NotificationArr: any = [];
  NotificationHeadersArr: any = [];
  selectedSearchOpt: any[];
  selectedOptArr: any = [];

  isLoader: boolean = true;

  first: number = 0;
  rows = 10;
  islastPageFlag: boolean;

  _ifNoRecords: boolean = false

  _NotificationSub: string;
  _NotificationFrom: string;
  _NotificationTo: string;
  _NotificationDesc: string;
  _NotificationOn: string;
  _EmailId: any;

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: any[];

  closeModal: any

  _isHideNotificationBlock: any
  @Output() fetchHideNotificationValue: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;
  @ViewChild("modalDataDelete", { static: false }) public modalDataDelete: NgbModal;

  toggle: boolean = false;
  _isUpArrow: boolean = false

  constructor(private modalService: NgbModal, public notificationService: NotificationService, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.faqListHeaders();
    this.getNotificationsList();
  }

  faqListHeaders() {
    this.NotificationHeadersArr = [
      { field: 'NotificationId', header: 'Sr No.', width: "10%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'Module', header: 'Module', width: "10%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'Section Name' },
      { field: 'NotificationDesc', header: 'Notification', width: "60%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'FAQ' },
      { field: 'NotificationOn', header: 'Date', width: "10%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'BondModified on' },
    ]

  }

  getNotificationsList() {
    this._EmailId = window.localStorage.getItem("UserId");
    let EntitiesObj = new Entities();
    EntitiesObj.Notifications.NotificationTo = this._EmailId;

    this.notificationService.getNotificationData(EntitiesObj.Notifications).subscribe((res: any) => {
      ;
  
      this.NotificationArr = res.Data
      this.isLoader = false;

      if (this.NotificationArr.length == 0) {
        setTimeout(() => {
          this._ifNoRecords = true
        }, 1000);
      } else {
        this._ifNoRecords = false
      }
    })
  }

  getById(singleRecord: any) {
    this.popupContentError = singleRecord;
    this._NotificationFrom = singleRecord.NotificationFrom
    this._NotificationTo = singleRecord.NotificationTo
    this._NotificationDesc = singleRecord.NotificationDesc
    this._NotificationOn = singleRecord.NotificationOn
    this._NotificationSub = singleRecord.NotificationSubject

    this.triggerModal(this.modalDataDelete);

    let EntitiesObj = new Entities();
    EntitiesObj.Notifications.NotificationId = singleRecord.NotificationId;
    EntitiesObj.Notifications.IsRead = true;

    this.notificationService.UpdateNotificationValue(EntitiesObj.Notifications).subscribe((res: any) => {
      ;

      this.NotificationArr.forEach((value: any, id: any) => {
        ;
        if (value.NotificationId == singleRecord.NotificationId) {
          ;
          value.IsRead = true
        }

      });

    })

  }

  clearAllNotifications() {
    let EntitiesObj = new Entities();
    EntitiesObj.Notifications.NotificationTo = this.NotificationArr[0].NotificationTo;

    this.notificationService.ClearNotificationValue(EntitiesObj.Notifications).subscribe((res: any) => {
      ;
      this.NotificationArr = [];
      this.isLoader = false;

      if (this.NotificationArr.length == 0) {
        setTimeout(() => {
          this._ifNoRecords = true
        }, 1000);
      } else {
        this._ifNoRecords = false
      }

    })
  }

  triggerModal(content: any) {
    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeNotificationBlock() {
    
    this._isHideNotificationBlock = false
    window.localStorage.setItem("ShowNotificationBlock", this._isHideNotificationBlock);
    this.fetchHideNotificationValue.emit(this._isHideNotificationBlock);
  }

  selectedNotificationId: any
  test: boolean = false
  readMoreDetails(id: any) {
    
    this.selectedNotificationId = id
    
    for (let i=0; i< this.NotificationArr.length; i++) {
      if (id === this.NotificationArr[i].NotificationId) {
        this.toggle = !this.toggle;
        if (this.toggle == true) {
          $("#notificationDescription_" + this.NotificationArr[i].NotificationId).removeClass("text-overflow-field");
          this._isUpArrow = true
        } else {
          $("#notificationDescription_" + this.NotificationArr[i].NotificationId).addClass("text-overflow-field");
          this._isUpArrow = false
        }
      }
    }
  }
}
