import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'

export const environment = {
  production: false,

  _configUrls: [],

  pageTitle: "BOND Portal",
  // pageTitle : "TAB Bank Portal",
  // pageTitle : "TA Protal",

  // _baseURL: "http://3.211.109.143:2794/"
  // _baseURL: "http://localhost:59092/"
  // _baseURL : 'http://44.201.151.253:2794/'
  // _baseURL:"http://52.86.205.173:8082/"
  // _baseURL:"http://52.200.152.7:2794/"
  // _baseURL: "https://portalweb.bond.ai/"
  //  _baseURL: "http://portalnlpweb.bond.ai/"
  // _baseURL: "https://shellweb.bond.ai/"
  // _baseURL: "http://tabportalweb.bond.ai/"
  // _baseURL: "http://tanlpweb.bond.ai/"
  // _baseURL: "https://shellweb.bond.ai/"
  // _baseURL : 'http://34.225.253.123:2790/'
  _baseURL: "http://100.25.61.139:2730/"
  // _baseURL: "http://100.25.61.139:2750/"
};