import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { faqEntities } from '../../faq/fqaentities'
import { DatePipe } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/calendar';

import { FAQsUsageService } from '../../services/FAQsUsage/faqs-usage.service'

import { Table } from 'primeng/table';

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-faqusage',
  templateUrl: './faqusage.component.html',
  styleUrls: ['./faqusage.component.css'],
  providers: [DatePipe]
})

export class FAQUsageComponent implements OnInit {
  @ViewChild('dt') dt: ElementRef
  @ViewChild("dt", { static: false }) public ptable: Table;
  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;

  FAQUsageArr: any = [];
  faqsUsageHeadersArr: any = [];
  searchOptionArr: any = [];
  selectedSearchOpt: any[];
  selectedOptArr: any = [];
  calendar: any = [];
  selectedOption: Date;
  rangeDates: Date[];
  toDate: any;
  fromDate: any;

  _SelectDuration = "Last Week";

  first: number = 0;
  rows = 10;
  islastPageFlag: boolean;

  sortColumnName: string;
  sortorder: boolean;
  descOrder: Boolean;
  AfterUpdateColumnName: string;

  selectedSection: string = "";
  selectedFaq: string = "";
  selectedAns: string = "";
  selectedUt: string = "";
  IsSelectedCB: boolean = false;
  searchFAQ: string = "";

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string
  closeModal: any

  _RoleName: any
  _ViewClientRoleAccess: boolean = false;

  isLoader: boolean = true;

  parameters = new faqEntities()
  myDate: any

  _searchData: string

  _ifNoRecords: boolean = false

  _calendarIcon: boolean = true;
  _closeIcon: boolean = false

  title = environment.pageTitle + ' - FAQ Usage';

  constructor(private modalService: NgbModal, public fAQsUsageService: FAQsUsageService, private datePipe: DatePipe, private titleService: Title) {
    this.myDate = new Date();
    this.sortorder = false;
  }

  ngOnInit(): void {

    this.titleService.setTitle(this.title);

    this.faqListHeaders(this.selectedOption);
    this.getAllFAQsUsagelist();

    this.fetchDataByCalendar();

    this._RoleName = window.localStorage.getItem("RoleName");

    if (this._RoleName === 'ClientView' || this._RoleName === 'ContentView') {
      this._ViewClientRoleAccess = true;
    }
  }

  faqListHeaders(vDuration: any) {
    this._SelectDuration = vDuration;

    this.faqsUsageHeadersArr = [
      { field: 'DispId', header: 'Sr No.', width: "2%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'Message', header: 'FAQ', width: "15%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'FAQ' },
      { field: 'Confidence', header: 'Usage Count', width: "6%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'Usage Count' },
    ]

    this.searchOptionArr = [
      { name: 'Section Name', code: 'sectionName' },
      { name: 'FAQ', code: 'faq' },
      { name: 'Answer', code: 'ans' }
    ]
  }

  fetchDataByCalendar() {
    this.calendar = [
      { name: 'Last Week', code: 'week' },
      { name: 'Last Month', code: 'month' },
      { name: 'Last Quarter', code: 'quarter' },
      { name: 'Last Year', code: 'year' }
    ]
  }

  getAllFAQsUsagelist() {
    const params = {
    }
    this.fAQsUsageService.getFAQsUsageData(params).subscribe((res: any) => {
      ;
      if (res.Status = 'SUCCESS') {
        this.FAQUsageArr = res.Data
        this.isLoader = false

        this.sortColumnName = '';
        this.sortorder = false;

      }
      else if (res.Error) {

        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
      }
    })
  }

  AfterCLickExport() {
    ;
    const event = {
      keyCode: 13
    }

    this.searchData(this.toDate, this.fromDate, "Export");
  }

  selectSearchOption(text: any) {
    this.selectedOptArr = text; 
  }

  searchData(todate: any, fromdate: any, actionName: any) {
    ;

    var searchEntities = new faqEntities();
    searchEntities.IntentMaintenance.FromDate = this.fromDate;
    searchEntities.IntentMaintenance.ToDate = this.toDate;

    if (actionName.toUpperCase() === "SEARCH") {
      this.getFilteredFAQUsagelist(searchEntities.IntentMaintenance);
    }
    else if (actionName.toUpperCase() === "EXPORT") {
      ;
      this.exportCSV(searchEntities.IntentMaintenance);
    }

  }

  fetchDateRange(selectedDates: any) {
    this._calendarIcon = false;
    this._closeIcon = true

    this.fromDate = this.datePipe.transform(selectedDates[0], "yyyy-MM-dd");
    this.toDate = this.datePipe.transform(selectedDates[1], "yyyy-MM-dd");
    if (this.fromDate !== null && this.toDate !== null) {
      this.searchData(this.fromDate, this.toDate, "Search");
    }
  }

  exportCSV(searchObj: any) {
    ;
    this.parameters.IntentMaintenance.FromDate = searchObj.FromDate
    this.parameters.IntentMaintenance.ToDate = searchObj.ToDate

    this.parameters.IntentMaintenance.SorColumnName = this.sortColumnName
    this.parameters.IntentMaintenance.Reverseorder = this.sortorder
    this.parameters.IntentMaintenance.AfterUpdateColumnName = this.AfterUpdateColumnName

    this.fAQsUsageService.exportFAQsUsageData(this.parameters.IntentMaintenance).subscribe((res: any) => {
      ;
      if (res.Status.toUpperCase() == "SUCCESS") {
        var status = res.status;
        const headers = res.headers;
        const data = res.Data;
        var contentType = 'application/CSV';
        var linkElement = document.createElement('a');
        var blob = new Blob([data], { type: contentType });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';

        this.myDate = new Date();
        this.myDate = this.datePipe.transform(this.myDate, 'MMddyyyyHHmm');
        a.download = "FAQsUsage_" + this.myDate + '.csv';

        document.body.appendChild(a);
        a.click();

        this.popupImg = "../../../assets/images/download-popup.png";
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo);


      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo);
      }
    })
  }

  getFilteredFAQUsagelist(searchEntities: any) {
    ;

    this.fAQsUsageService.getFAQsUsageData(searchEntities).subscribe((res: any) => {
      if (res.Status = 'SUCCESS') {
        ;
        this.FAQUsageArr = res.Data
        this.isLoader = false

        this.sortColumnName = '';
        this.sortorder = false;

        if (this.FAQUsageArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        } else {
          this._ifNoRecords = false
        }

      }
      else if (res.Error) {
        ;
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
      }
    })
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  GOToEnteredPageNo(pageNo: any): void {

    if (pageNo != "0" && pageNo != "") {

      var enteredPageNo: number = +pageNo;

      this.islastPageFlag = this.isLastPage(enteredPageNo);

      if (this.islastPageFlag === false) {
        this.first = enteredPageNo * 10 - 10;
      }
      else {
        const pageCount = Math.ceil(this.FAQUsageArr.length / this.rows);

        let page = pageCount - 1;
        page = page * this.rows

        this.first = page
      }
    }
    else {
      this.first = 0;
    }
  }

  
  isLastPage(pageNo: any): boolean {

    const pageCount = Math.ceil(this.FAQUsageArr.length / this.rows);

    const page = pageCount - 1;

    if (pageNo <= page) {
      return false;
    }
    else {
      return true;
    }
  }

  sortcolumn(ColumnName: any) {
    let reverse = this.sortColumnName === ColumnName ? !this.descOrder : undefined;

    if (reverse === undefined)
      reverse = false;

    this.sortColumnName = ColumnName;
    this.descOrder = reverse;

    if (this.descOrder == true)
      this.sortorder = false;
    else
      this.sortorder = true;
  }

  clearInputField(clearData: any) {
    this._calendarIcon = true;
    this._closeIcon = false

    this.rangeDates = clearData

    this.rangeDates = []
    window.location.reload();
  }
}
