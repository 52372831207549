<div class="container-fluid mainLoginContainer">
  <div class="row loginPageContainer">
    <div class="loginPageWrapper">
      <div class="loginPageHeader">
        <div class="loginPageTitle">
          <img src="../../../assets/images/login-title.png" alt="">
        </div>
        <div class="loginPageSubtitle">
          <img src="../../../assets/images/login-subtitle.png" alt="">
        </div>
      </div>
      <div class="login-container">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-Form">
          <h2>Hello,</h2>
          <p class="sub-title">Welcome back!</p>
          <div class="form-group mb-0">
            <input type="text" class="form-control textBoxSizes " formControlName="username" id="username"
              autocomplete="off" placeholder="Email" (keypress)="onKeyUp($event)">
            <div class="error lablesCommon"
              *ngIf="loginForm.controls['username'].hasError('required') && loginForm.controls['username'].touched"
              style="color: red;">Username is required</div>
          </div>
          <div class="form-group position-relative">
            <input type="{{checktype}}" class="form-control textBoxSizes" formControlName="password" id="pwd"
              autocomplete="off" placeholder="Password" (keypress)="onKeyUp($event)">
            <div class="showPassword">
              <a (click)="showPassword()">
                <img src="../../../assets/images/showPwd.png" *ngIf="eye" class="eyeIcon" alt="">
              </a>
              <a (click)="hidePassword()">
                <img src="../../../assets/images/hidePwd.png" *ngIf="eyeSlash" class="eyeIcon" alt="">
              </a>
            </div>
            <div class="error lablesCommon"
              *ngIf="loginForm.controls['password'].hasError('required') && loginForm.controls['password'].touched"
              style="color: red;">Password is required</div>
          </div>
          <button title="Login now" class="btn btn-success login_button" [disabled]="loginForm.invalid">
            <span class="mr-2" *ngIf="spinnerloader"><i class="fa fa-spinner fa-spin"></i></span>Login now</button>
          <div *ngIf="invalidLogin" class="error">
            <div class="lablesCommon" style="color: red;">Invalid Credentials.</div>
          </div>
          <a routerLink="forgotpassword" class="lablesCommon">Forgot Password?</a>
        </form>
      </div>
    </div>
  </div>
</div>