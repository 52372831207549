<div class="container-fluid screen-container xl-bg-transperent defaultIntentContainer">
    <div class="mainContainerWrapper ">

        <div class="header row">
            <div class="col-md-12">
                <div class="float-left">
                    <span class="page-title">Default Intents</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="BlockTitle pl-20">
                    <p> Number of default intents</p>
                </div>
                <div class="BlockInputField">
                    <input type="text" class="form-control text-center" [(ngModel)]="defaultIntentNumber"
                        [readonly]="isReadonly" onkeypress="return /^-?[0-9]*$/.test(this.value+event.key)" (keypress)="disableSelectButton(defaultIntentNumber, $event)">

                </div>
                <div class="BlockActionButton" *ngIf="isBlockEditButton">
                    <button class="btn btn-light" (click)="editDefaultNumber()">Edit</button>
                </div>
                <div class="BlockSaveCancelButton" *ngIf="isBlockSaveCancelButton">
                    <button class="btn btn-primary mr-3" [disabled]="!defaultIntentNumber"
                        (click)="SaveBtnAction()">Save</button>
                    <button class="btn btn-secondary" [disabled]="!defaultIntentNumber"
                        (click)="CancelBtnAction()">Cancel</button>
                </div>
            </div>
        </div>

        <div class="row errorFlag" *ngIf="_isDefaultNumberErrorFlag">
            <div class="col-md-12">
                <label>Number of default intents should not be greator than 5.</label>
            </div>
        </div>

        <hr />

        <div class="row addFaqWrapper singleWordListWrapper mb-3">
            <div class="col-md-6">
                <div class="BlockTitle">
                    <p class="mb-0"><b>Add intents</b></p>
                </div>
                <div class="row defaultIntentContainer">
                    <div class="col-md-12 mb-3">
                        <div class="label">Section Name <span class="asterisk">*</span></div>

                        <p-dropdown [options]="sectionNameListArr" [(ngModel)]="_SelectedSectionName" optionLabel="name"
                            [filter]="true" filterBy="name" placeholder="Select a Section name"
                            (onChange)="SelectSecionName($event)" class="w-100">
                        </p-dropdown>
                        <label *ngIf="_isSectionNameErrorFlag" class="errorFlag">Please select section name from
                            dropdownlist.</label>
                    </div>

                    <div class="col-md-12 mb-3">
                        <div class="label">Intent Name <span class="asterisk">*</span></div>

                        <p-dropdown [options]="intentNameListArr" [(ngModel)]="_SelectedIntentName" optionLabel="name"
                            [filter]="true" filterBy="name" placeholder="Select a Intent name"
                            (onChange)="SelectIntentName($event)">
                        </p-dropdown>
                        <label *ngIf="_isIntentNameErrorFlag" class="errorFlag">Please select intent name from
                            dropdownlist.</label>
                    </div>

                    <div class="col-md-12 mb-3">
                        <div class="label">Question <span class="asterisk">*</span></div>
                        <textarea pInputText type="text" [(ngModel)]="_faqObj.IntentMaintenance.Questions"
                            maxlength="50" class="intentInputField form-control"
                            style="border-radius: 15px;height: auto;width: 100%;" readonly></textarea>
                    </div>

                    <!-- <div class="col-md-12 errorFlag" *ngIf="_isMoreThenDefaultIntent">
                        <label>Number of default intents should not be greator than 5.</label>
                    </div> -->

                    <div class="col-md-12 defaultIntentFooter">
                        <button class="btn btn-primary mr-3" (click)="SaveIntent()" [disabled]="!defaultIntentNumber || _disableSelectButton">Select</button>
                        <button class="btn btn-secondary" (click)="CancelIntent()">Cancel</button>
                    </div>

                </div>
            </div>

            <div class="col-md-6">

                <div class="tableContent border" [hidden]="_ifNoRecords">
                    <p-table #dt [value]="nodeObjArr" styleClass="p-custom-datatable" [rows]="10" reflow="true"
                        [showCurrentPageReport]="true" [rowHover]="true" [scrollable]="true" scrollHeight="flex"
                        [(first)]="first" editMode="row" [editable]="true" [resizableColumns]="true">

                        <ng-template pTemplate="colgroup">
                            <colgroup>
                                <col *ngFor="let col of DefaultIntentListHeadersArr" [style.width]='col.width'>
                            </colgroup>
                        </ng-template>

                        <ng-template pTemplate="header">
                            <tr>
                                <th pResizableColumn *ngFor="let col of DefaultIntentListHeadersArr"
                                    [pSortableColumn]="col.field"
                                    [pSortableColumnDisabled]="col.field === 'BondModifiedOn'" style="text-align: left;"
                                    [ngClass]="col.field" data-toggle="tooltip" data-placement="top"
                                    title="{{col.header}}">
                                    <div class="tableHeader" *ngIf="col.isSort === 'true'"
                                        (click)="sortcolumn(col.field)"
                                        [ngClass]="{'border-right-dadadd': col.field != 'Question'}">
                                        <div class="d-inline-block text-overflow-header"
                                            [ngClass]="{'pl-15':col.field === 'defaultIntentID', 'xl-pl-20px':col.field === 'defaultIntentID'}">
                                            {{col.header}}</div>
                                        <div class="tableSortIcon">
                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                        </div>
                                    </div>
                                    <div *ngIf="col.isSort === 'false'">
                                        {{col.header}}
                                    </div>
                                </th>

                            </tr>
                            <tr *ngIf="isLoader">
                                <td *ngFor="let col of DefaultIntentListHeadersArr">
                                    <div class="item customSkeletonLoader">
                                        <ngx-skeleton-loader count="5" appearance=""></ngx-skeleton-loader>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-car let-ri="rowIndex">
                            <tr class="position-relative" [hidden]="isLoader">
                                <td pEditableColumn *ngFor="let col of DefaultIntentListHeadersArr"
                                    [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">
                                    <div
                                        [ngClass]="{'xl-pl-14px':col.field !== 'defaultIntentID', 'pl-10':col.field !== 'defaultIntentID'}">
                                        <div *ngIf="col.field != 'Questions' && col.field != 'BondModifiedOn'"
                                            [ngClass]="{'pl-25':col.field === 'defaultIntentID', 'xl-pl-35px':col.field === 'defaultIntentID'}">
                                            {{ri + 1}}
                                        </div>
                                    </div>

                                    <div *ngIf="col.field === 'Questions'"
                                        [ngClass]="{'xl-pl-14px':col.field !== 'defaultIntentID', 'pl-10':col.field !== 'defaultIntentID', 'text-overflow-field': col.field === 'Questions'}">
                                        {{car[col.field]}}
                                    </div>

                                    <div class="actions w-100"
                                        [ngClass]="{'showactionBlock': col.field === 'BondModifiedOn', 'xl-pl-14px':col.field !== 'defaultIntentID', 'pl-10':col.field !== 'defaultIntentID'}">
                                        <span>
                                            <a class="faqModifyActionBtn faqDeleteActionBtn" title="Delete"
                                                (click)="OpenDeletePopup(car)">
                                                <img class="block_img" src="../../../assets/images/delete.png">
                                                <img class="hover_delete_img"
                                                    src="../../../assets/images/delete_on_hover.png">
                                            </a>
                                        </span>
                                    </div>

                                </td>
                            </tr>
                        </ng-template>

                    </p-table>
                </div>

                <div class="noRecordFound text-center border-top" *ngIf="_ifNoRecords">
                    <img src="../../assets/images/no_records.png">
                    <p class="title">No Records Found</p>
                </div>
            </div>
        </div>

    </div>
</div>

<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>

<ng-template #modalDataDelete let-modal="dismiss">
    <app-confirmation-modal
        [confirmModalInputVariable]="{btnname: 'Yes', isDeleteDefaultIntent:true, popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}"
        [callbackCancelFunction]="boundDeleteRow">
    </app-confirmation-modal>
</ng-template>

<div class="animatedLoader" *ngIf="_isSaveDiagramLoader" style="left: 0;">
    <img src="../../../assets/images/animation_loader.gif" class="loaderImg">
</div>