//import { Component, OnInit } from '@angular/core';


import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, ViewChild, ElementRef, HostListener, EventEmitter, Pipe } from '@angular/core';
//import { UserDetailsService } from '../services/UserDetails/user-details.service';
import { FaqService } from '../services/faq/faq.service';
import { Entities } from '../test-bot/Entities'
import { Router } from "@angular/router";

import { ChangeDetectorRef } from '@angular/core';

import { Table } from 'primeng/table';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DomSanitizer } from '@angular/platform-browser';

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-intent-test-details',
  templateUrl: './intent-test-details.component.html',
  styleUrls: ['./intent-test-details.component.css']
})
/* export class IntentTestDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    ;
  }

} */

export class IntentTestDetailsComponent implements OnInit {
  @ViewChild('dt') dt: ElementRef
  @ViewChild("dt", { static: false }) public ptable: Table;


  userListArr: any = [];
  userHeadersArr: any = [];

  searchDataArr: any = [];

  singleDataArr: any = [];
  dataId: number;
  vStrMainData: string;

  generatedDataOfMU: string;

  searchUSER: string = "";
  DataForExport: any;

  selectedSearchOpt: any[];
  searchOptionArr: any = [];
  selectedOptArr: any = [];
  searchUSERArr: any = [];
  language: any = [];
  selectedLanguage: any = [];


  selectedSection: string = "";
  selectedFaq: string = "";
  selectedAns: string = "";
  selectedUt: string = "";
  IsSelectedCB: boolean = false;

  filterAllFields: any
  filterArr: any = [];
  isHide: boolean = true
  selectedstatus: string = '';
  selectedFaqName: string = '';
  selectedResponceType: string = '';
  selectedUtterance: string = '';

  newFilterDataArr: any = [];
  selectedValues: any = [];
  toggle: boolean = false;
  chageFilterInput: boolean = false;
  totalSelectedValues: any;
  selectedFieldOutline: boolean = false;
  greyFieldOutline: boolean = false;

  hoverFilterArr: any = []
  selectedFilterOption: string

  isLoader: boolean = true;

  sortColumnName: string;
  sortorder: boolean;
  descOrder: Boolean;
  AfterUpdateColumnName: string;

  first: number = 0;
  rows = 10;
  islastPageFlag: boolean;

  parameters = new Entities()
  myDate: any

  totalRows: string;
  filteredEmailId: any = [];
  selectedsectionname: string;
  newSrNo: string;
  newQuestion: string;
  newAnswer: string;
  newrow: boolean = false
  addRecord = new Entities()

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;

  showDeletePopup: boolean = false
  _DeleteReasonsLst: any = []
  _selectedDeleteReasons: any
  _isDisplayOtherSec: boolean = false

  dataTodelete: any
  closeModal: any
  _OtherData: any

  _RoleName: any
  _IsClient: any

  _languageArr: any = [];
  _filteredEmailIdArr: any = [];
  _buildModelArr: any = [];

  _ViewClientRoleAccess: boolean = false;

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;
  @ViewChild("modalDataDelete", { static: false }) public modalDataDelete: NgbModal;
  @ViewChild("ConfirmationModal", { static: false }) public confirmationModal: NgbModal;

  @Output() inputdata: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("comments") comment: ElementRef

  _isSelectedField: boolean = false

  _ifNoRecords: boolean = false

  title = environment.pageTitle + ' - Intent Test Details';

  constructor(private modalService: NgbModal, private _eref: ElementRef, public userconfigservice: FaqService,
    private _http: HttpClient, private cdref: ChangeDetectorRef, private datePipe: DatePipe, private router: Router, private sanitizer: DomSanitizer,
    private titleService: Title) {
    this.myDate = new Date();
    this.sortorder = false;
  }

  selectedCities: string[] = [];

  ngOnInit(): void {

    this.titleService.setTitle(this.title);


    this._RoleName = window.localStorage.getItem("RoleName");

    if (this._RoleName === 'ClientView' || this._RoleName === 'ContentView') {
      this._ViewClientRoleAccess = true;
    }

    this.fetchUserList();
    this.userListHeaders();
    this.filterDropdown();
    //this.fetchLanguage();
    this.sortorder = false;

    if (this._RoleName.toUpperCase() == 'CLIENT') {
      this._IsClient = true
    }

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  filterDropdown() {
    this.filterArr = [
      {
        label: 'Status', value: 'status',
        items: [
          { name: 'Bad Pick', code: 'status', status: 'BadPick', checked: false },
          { name: 'Pass', code: 'status', status: 'pass', checked: false },
          { name: 'Confidence 40 to 75', code: 'status', status: 'confidence40to75', checked: false },
          { name: 'Confidence bellow 40', code: 'status', status: 'confidencebellow40', checked: false }
        ]
      },
      {
        label: 'Model', value: 'model',
        items: [
          { name: 'Model 1', code: 'model', status: 'Model 1', checked: false },
          { name: 'Model 2', code: 'model', status: 'Model 2', checked: false },
          { name: 'Model 3', code: 'model', status: 'Model 3', checked: false },
        ]
      }
    ];

    this.searchOptionArr = [
      { name: 'SectionName', code: 'sectionName' },
      { name: 'IntentName', code: 'faq' },
      { name: 'Utterance', code: 'ans' }
    ]

    // this.language = [
      //     { name: 'Bad Pick', code: 'Bad Pick' },
      //     { name: 'Pass', code: 'Pass' },
      //     { name: 'Confidence 40 to 75', code: 'FB40T75' },
      //     { name: 'Confidence bellow 40', code: 'FBB40' }
    
      //   ]
  }

  selectOption(text: any) {
    this.isLoader = true;

    this.filterAllFields = text;
  }

  onClickedOutside(e: any) {

    this.toggle = false;
  }

  clearInputField() {

    this.selectedValues = []
    this.filterAllFields = []
    this.isHide = true

    this.filterDropdown();
    this.chageFilterInput = false

    const event = { "keyCode": 13 }

    this.searchData(event, "Search");
  }

  private getfilterDatafromArray() {
    this.IntentStatus = "";


    if (this.filterAllFields != undefined) {

      for (let i = 0; i < this.filterAllFields.length; i++) {
        if (this.filterAllFields[i].code.toUpperCase() == "STATUS") {
          if (this.IntentStatus === "")
            this.IntentStatus = this.filterAllFields[i].name;

          else
            this.IntentStatus = this.IntentStatus + "," + this.filterAllFields[i].name;
        }
        else {
          this.IntentStatus = "";
        }
      }
    }
  }

  selectFilterOption(details: any) {
    this.isHide = false
debugger
    if (details.checked == true) {
      this.chageFilterInput = true

      var d = this.selectedValues.find((x: { code: string; }) => x.code == details.code)

      if (d) {
        this.dropSkill(d)
        this.selectedValues.push(details)
      }

      if (!d) {
        this.selectedValues.push(details)
      }
    }
    else {
      this.selectedValues.forEach((value: any, id: any) => {
        if (value.status == details.status)
          this.selectedValues.splice(id, 1)
        this.chageFilterInput = true
      });
    }

    this.totalSelectedValues = this.selectedValues.length
    if (this.totalSelectedValues === 0) {
      this.chageFilterInput = false
      this.isHide = true
    }

    this.filterAllFields = this.selectedValues;

    const event = { "keyCode": 13 }

    this.searchData(event, "Search");

  }


  dropSkill(index: any) {
    this.selectedValues.forEach((value: any, id: any) => {
      if (value.status == index.status)
        this.selectedValues.splice(id, 1)
    });

    let indexMain = this.filterArr[0].items.indexOf(index);

    if (indexMain === -1) {
      indexMain = this.filterArr[1].items.indexOf(index);

      if (indexMain === -1) {
        indexMain = this.filterArr[2].items.indexOf(index);

        if (indexMain === -1) {
        }
        else {
          const dataMain = this.filterArr[2].items.filter(function (x: any) { return x.status === index.status })[0];

          dataMain.checked = false;

          this.filterArr[2].items[indexMain] = dataMain

        }
      }
      else {
        const dataMain = this.filterArr[1].items.filter(function (x: any) { return x.status === index.status })[0];

        dataMain.checked = false;

        this.filterArr[1].items[indexMain] = dataMain

      }
    }
    else {
      const dataMain = this.filterArr[0].items.filter(function (x: any) { return x.status === index.status })[0];

      dataMain.checked = false;

      this.filterArr[0].items[indexMain] = dataMain

    }

    this.filterAllFields = this.selectedValues;

    const event = { "keyCode": 13 }

  }

  clickEvent() {
    this.selectedFieldOutline = !this.selectedFieldOutline
    this.toggle = !this.toggle
  }

  changeStyle() {
    this.greyFieldOutline = !this.greyFieldOutline
  }

  handleLink: any
  _LinkArray: any = []
  _selectedRespType: any = [];
  selectedintentname: string

  fetchUserList() {
    ;
    this.isLoader = true;
    this.parameters = new Entities()
    // this.parameters.IntentTestingSummary.Status = "Bad Pick";
    // this.selectedLanguage.name = "Bad Pick";
    // this.selectedstatus = "Bad Pick";

    /* if (this.parameters.IntentTestingSummary.Status=='Confidence 40 to 75')
    {
      this.parameters.IntentTestingSummary.Status='FB40T75'
    }
    if (this.parameters.IntentTestingSummary.Status=='Confidence bellow 40')
    {
      this.parameters.IntentTestingSummary.Status='FBB40'
    } */

    this.userconfigservice.getTestResult(this.parameters.IntentTestingSummary).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        ;
        this._hideEmptyRow = false;
        this.userListArr = []
        this.userListArr = res.Data;

        if (this.userListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        } else {
          this._ifNoRecords = false
        }

        this.isLoader = false;
        this.sortColumnName = '';
        this.first = 0;
        this.sortorder = false;

        this.ptable.reset();

      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  IntervalId: any

  closeInterval() {
    if (this.IntervalId) {
      clearInterval(this.IntervalId);
    }
  }

  userListHeaders() {
    this.userHeadersArr = [
      { field: 'DispID', header: 'Sr No.', width: "4%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'SectionName', header: 'Section Name', width: "7%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'Section Name' },
      { field: 'IntentName', header: 'Intent Name', width: "14%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'FAQ' },
      { field: 'Utterance', header: 'Utterance', width: "14%", isEditable: "true", isSort: "true", textAlignment: "left", value: 'Answer' },
      { field: 'ExpectedResponse', header: 'Expected Response', width: "14%", isEditable: "true", isSort: "true", textAlignment: "left", value: 'Answer' },
      { field: 'ActualResponse', header: 'Actual Response', width: "14%", isEditable: "true", isSort: "true", textAlignment: "left", value: 'Answer' },
      { field: 'Status', header: 'Status', width: "7%", isEditable: "true", isSort: "true", textAlignment: "left", value: 'Answer' },
    ]
  }


  popupContentError: string

  closePopup() {

    this.showPopup = false
    this.showDeletePopup = false
  }

  filterEmailId(event: any) {
    let filtered: any[] = [];
    let result: any = [];
    let query = event.query;

    result = Array.from(this.userListArr.reduce((m: any, t: any) => m.set(t.SectionName, t), new Map()).values());

    for (let i = 0; i < result.length; i++) {
      if (result[i].SectionName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push({
          name: result[i].SectionName,
          value: result[i].SectionName
        });
      }
    }

    this.filteredEmailId = filtered
  }

  onRowEditInit(book: any) {
  }

  _hideEmptyRow: boolean = false

  addNewRow(vClickBtnName: string) {
    this.PageName = vClickBtnName
    this.userListArrData = this.userListArr

    this._showAddEditScreen = true
    window.localStorage.setItem("showFaq", this._showAddEditScreen)
  }

  OnSelectDeletedReason() {
    if (this._selectedDeleteReasons.name == 'Other') {
      this._isDisplayOtherSec = true
    }
    else
      this._isDisplayOtherSec = false
  }

  _QuestionTodelete: any

  OpenDeletePopup(data: any) {

    this._OtherData = "";
    this.dataTodelete = data

    this._QuestionTodelete = data.Questions

    this.triggerModal(this.modalDataDelete);
  }

  selectSearchOption(text: any) {

    // this.isLoader = true;
    this.selectedOptArr = text;

    // this.selectedSection = "";
    // this.selectedFaq = "";
    // this.selectedAns = "";
    // this.selectedUt = "";

    // var searchEntities = new Entities();

    // this.searchUSER = this.searchUSER.trim()

    // if (this.selectedOptArr.length != 0) {
    //   for (let i = 0; i < this.selectedOptArr.length; i++) {
    //     if (this.selectedOptArr[i].code.toUpperCase() == "SECTIONNAME") {
    //       this.selectedSection = this.searchUSER
    //     } else if (this.selectedOptArr[i].code.toUpperCase() == "FAQ") {
    //       this.selectedFaq = this.searchUSER
    //     } else if (this.selectedOptArr[i].code.toUpperCase() == "ANS") {
    //       this.selectedAns = this.searchUSER
    //     } else if (this.selectedOptArr[i].code.toUpperCase() == "UTTERANCES") {
    //       this.selectedUt = this.searchUSER
    //     }
    //   }
    //   this.IsSelectedCB = true;

    // }

    // else if (this.selectedOptArr.length == 0) {
    //   this.IsSelectedCB = false;
    // }
    // else if (this.selectedOptArr.length == undefined) {
    //   this.IsSelectedCB = false;
    // }

    // else {
    //   this.selectedSection = this.searchUSER;
    //   this.selectedFaq = this.searchUSER;
    //   this.selectedAns = this.searchUSER;
    //   this.selectedUt = this.searchUSER
    //   this.IsSelectedCB = false;
    // }

    // searchEntities.IntentTestingSummary.SectionName = this.selectedSection;
    // searchEntities.IntentTestingSummary.IntentName = this.selectedFaq;
    // searchEntities.IntentTestingSummary.Utterance = this.selectedAns;
    // searchEntities.IntentTestingSummary.IsSelectedCB = this.IsSelectedCB;
    // searchEntities.IntentTestingSummary.Status = this.selectedLanguage.code;

    // this.searchUSERRecord(searchEntities.IntentTestingSummary);


    const event = { "keyCode": 13 }

    this.searchData(event, "Search");

  }

  IntentStatus: any;
  searchData(event: any, actionName: any) {


    this.selectedSection = "";
    this.selectedFaq = "";
    this.selectedAns = "";
    this.selectedUt = "";

    if (event.keyCode === 13) {
      var searchEntities = new Entities();

      this.searchUSER = this.searchUSER.trim()

      if (this.selectedOptArr.length != 0) {

        for (let i = 0; i < this.selectedOptArr.length; i++) {
          if (this.selectedOptArr[i].code.toUpperCase() == "SECTIONNAME") {
            this.selectedSection = this.searchUSER
          } else if (this.selectedOptArr[i].code.toUpperCase() == "FAQ") {
            this.selectedFaq = this.searchUSER
          } else if (this.selectedOptArr[i].code.toUpperCase() == "ANS") {
            this.selectedAns = this.searchUSER
          } else if (this.selectedOptArr[i].code.toUpperCase() == "UTTERANCES") {
            this.selectedUt = this.searchUSER
          }
        }
        this.IsSelectedCB = true;
      }
      else {
        this.selectedSection = this.searchUSER;
        this.selectedFaq = this.searchUSER;
        this.selectedAns = this.searchUSER;
        this.selectedUt = this.searchUSER
        this.IsSelectedCB = false;
      }

      searchEntities.IntentTestingSummary.SectionName = this.selectedSection;
      searchEntities.IntentTestingSummary.IntentName = this.selectedFaq;
      searchEntities.IntentTestingSummary.Utterance = this.selectedAns;
      searchEntities.IntentTestingSummary.IsSelectedCB = this.IsSelectedCB;


      this.getfilterDatafromArray()

      searchEntities.IntentTestingSummary.Status = this.IntentStatus;

      if (actionName.toUpperCase() === "SEARCH") {
        this.searchUSERRecord(searchEntities.IntentTestingSummary);
      }

    }
  }

  // fetchLanguage() {
  //   this.language = [
  //     { name: 'Bad Pick', code: 'Bad Pick' },
  //     { name: 'Pass', code: 'Pass' },
  //     { name: 'Confidence 40 to 75', code: 'FB40T75' },
  //     { name: 'Confidence bellow 40', code: 'FBB40' }

  //   ]
  // }


  searchUSERRecord(searchObj: any) {
    ;
    this._ifNoRecords = false
    this.isLoader = true;

    this.DataForExport = this.parameters.IntentTestingSummary;

    /* if (this.parameters.IntentTestingSummary.Status=='Confidence 40 to 75')
    {
      this.parameters.IntentTestingSummary.Status='FB40T75'
    }
    if (this.parameters.IntentTestingSummary.Status=='Confidence bellow 40')
    {
      this.parameters.IntentTestingSummary.Status='FBB40'
    } */


    this.userconfigservice.getTestResult(searchObj).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        this.userListArr = res.Data;
        this.isLoader = false;
        this.toggle = false;

        this.sortColumnName = '';
        this.AfterUpdateColumnName = "";
        this.sortorder = false;

        this.ptable.reset();

        if (this.userListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        } else {
          this._ifNoRecords = false
        }

      } else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }


  sortcolumn(ColumnName: any) {

    let reverse = this.sortColumnName === ColumnName ? !this.descOrder : undefined;

    if (reverse === undefined)
      reverse = false;

    this.sortColumnName = ColumnName;
    this.descOrder = reverse;

    if (this.descOrder == true)
      this.sortorder = false;
    else
      this.sortorder = true;
  }

  GOToEnteredPageNo(pageNo: any): void {

    if (pageNo != "0" && pageNo != "") {

      var enteredPageNo: number = +pageNo;

      this.islastPageFlag = this.isLastPage(enteredPageNo);

      if (this.islastPageFlag === false) {
        this.first = enteredPageNo * 10 - 10;
      }
      else {
        const pageCount = Math.ceil(this.userListArr.length / this.rows);

        let page = pageCount - 1;
        page = page * this.rows

        this.first = page
      }
    }
    else {
      this.first = 0;
    }
  }

  isLastPage(pageNo: any): boolean {

    const pageCount = Math.ceil(this.userListArr.length / this.rows);

    const page = pageCount - 1;

    if (pageNo <= page) {
      return false;
    }
    else {
      return true;
    }
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onKeypressEvent(event: any) {
    var eventLength = event.target.value
    var textLength = eventLength.length;

    var htmlElement = this.comment.nativeElement as HTMLElement;

    if (textLength % 50 == 0) {
      var height = textLength / 50;

      if (height >= 2) {
        htmlElement.setAttribute("style", "height:93px !important")
      } else {
        htmlElement.setAttribute("style", "height:auto !important")
      }
    }

  }

  _newVersion: any
  _modelName1: any
  _Lang = "EN";

  _BuildmodelIP = {
    "StartTesting": false,
    "IntentTest": false
  };

  _showAddEditScreen: any = false

  ShowFaqList: any = window.localStorage.setItem("showFaq", this._showAddEditScreen)

  PageName: any
  EditRowID: any
  userListArrData: any

  OnAddEditBtnClick(vClickBtnName: string, vEditRowId: string) {

    this.PageName = vClickBtnName
    this.EditRowID = vEditRowId
    this.userListArrData = this.userListArr

    this._showAddEditScreen = true
    window.localStorage.setItem("showFaq", this._showAddEditScreen)
  }

  cancelbuttonOnAddEdit() {
    this._showAddEditScreen = false

  }

  AddEditPageResult(SuccVal: any) {
    if (SuccVal.toUpperCase() == "SUCCESS") {
      this.fetchUserList()
      this._showAddEditScreen = false
    }
    else {
      this._showAddEditScreen = false
    }
  }

  CloseModel() {

  }

  _TraningModelNameList: any
  getBuildModelStatus() {
    this.userconfigservice.getBuildModelStatus().subscribe((res: any) => {

      debugger
      if (res.Status.toUpperCase() == 'SUCCESS') {

        this._TraningModelNameList = res.Data.TraningModelNames;

        if (this._TraningModelNameList.length > 0) {
          debugger
          this._TraningModelNameList.forEach((value: any, key: any) => {
            this._buildModelArr.push({ name: value.DisplayModelName, code: 'Model', status: value.ModelName, checked: false },)
          });
        }
        else {
          this._buildModelArr = [
            this._buildModelArr.push({ name: 'Model 1', code: 'Model', status: 'MODEL1', checked: false },)

          ]
        }
      }
      else {
        this.popupImg = "../../../assets/images/update_error.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }
}
