import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SingleWordService } from '../services/SingleWord/single-word.service'

import { Entities } from '../single-word/Entities'

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-single-word',
  templateUrl: './single-word.component.html',
  styleUrls: ['./single-word.component.css']
})
export class SingleWordComponent implements OnInit {

  singleListArr: any = [];
  singleListArray: any = [];

  singleListHeadersArr: any = [];

  filteredSingleWordArr: any = [];

  language: any = [];
  selectedLanguage: any = "English";
  selectedSectionName: any = [];
  filteredSectionName: any = [];
  sectionName: any = [];
  SectionNames: any = [];
  IntentNames: any = [];

  selectedSectionNamearr: any = [];
  selectedIntentNamearr: any = [];
  selectedIntentName: any = [];
  filteredIntentName: any = [];
  filteredSelectedIntentName: any = [];

  selectedSingleWord: string

  isLoader: boolean = false;
  parameters = new Entities();

  _ifNoRecords: boolean = false

  first: number = 0;

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string
  closeModal: any

  _searchData: string

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;
  @ViewChild("dt", { static: false }) public ptable: Table;
  title = environment.pageTitle + ' - Single Word List';

  constructor(public singleWordService: SingleWordService, private modalService: NgbModal, private titleService: Title) { }

  ngOnInit(): void {

    this.titleService.setTitle(this.title);

    this.isLoader = true
    this.getSingleWordList();
    this.singleListHeaders();
  }

  singleListHeaders() {
    this.singleListHeadersArr = [
      { field: 'DispID', header: 'Sr No.', width: "3%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'SectionName', header: 'Section Name', width: "8%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'Section Name' },
      { field: 'IntentName', header: 'Intent Name', width: "12%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'FAQ' },
      { field: 'Word', header: 'Word', width: "8%", isEditable: "true", isSort: "true", textAlignment: "left", value: 'Answer' },
      { field: 'BondCreatedOn', header: 'Created Date', width: "5%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'b' }
    ]
  }

  getSingleWordList() {

    let EntitiesObj = new Entities();
    EntitiesObj.SingleWordModel.IntentName = "";
    EntitiesObj.SingleWordModel.SectionName = "";
    EntitiesObj.SingleWordModel.Word = "";

    this.getFilteredSingleWordLList(EntitiesObj)

    this.singleWordService.getSingleWordData(EntitiesObj.SingleWordModel).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        this.singleListArr = res.Data;
        this.singleListArray = res.Data;
        var x = res.Data.length;
        var j = 0;
        for (var i = 0; i < res.Data.length; i++) {

          this.selectedSectionName[j] = res.Data[i].SectionName
          j++;

        }

        if (this.singleListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        } else {
          this._ifNoRecords = false
        }


        this.isLoader = false;
      }
      else if (res.Error) {

        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  searchData(event: any, actionName: any) {

    if (event.keyCode === 13) {

      let EntitiesObj = new Entities();

      this.selectedSingleWord = this.selectedSingleWord.trim();

      EntitiesObj.SingleWordModel.IntentName = this.selectedIntentName.name;
      EntitiesObj.SingleWordModel.SectionName = this.selectedSectionName.name;
      EntitiesObj.SingleWordModel.Word = this.selectedSingleWord;

      this.getFilteredSingleWordLList(EntitiesObj.SingleWordModel);
    }
  }

  filterSectionName(event: any) {
    ;
    let filtered: any[] = [];
    let result: any = [];
    let query = event.query;

    result = Array.from(this.singleListArray.reduce((m: any, t: any) => m.set(t.SectionName, t), new Map()).values());

    for (let i = 0; i < result.length; i++) {
      if (result[i].SectionName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push({
          name: result[i].SectionName,
          value: result[i].SectionName
        });
      }
    }



    this.filteredSectionName = filtered
  }

  filterSelection(selectedSectionName: any) {
    this.selectedSectionNamearr = [];
    for (let item of this.singleListArray) {
      if (selectedSectionName.name === item.SectionName) {
        this.selectedSectionNamearr.push(item)
      }
    }

    let EntitiesObj = new Entities();

    EntitiesObj.SingleWordModel.IntentName = this.selectedIntentName.name;
    EntitiesObj.SingleWordModel.SectionName = this.selectedSectionName.name;
    EntitiesObj.SingleWordModel.Word = this.selectedSingleWord;

    this.getFilteredSingleWordLList(EntitiesObj.SingleWordModel);
  }

  filterIntentSelection(selectedIntentName: any) {
    this.isLoader = true;
    let EntitiesObj = new Entities();

    EntitiesObj.SingleWordModel.SectionName = this.selectedSectionName.name;
    EntitiesObj.SingleWordModel.Word = this.selectedSingleWord;

    EntitiesObj.SingleWordModel.IntentName = this.selectedIntentName.name;

    this.getFilteredSingleWordLList(EntitiesObj.SingleWordModel);
  }

  filterIntentName(event: any) {
    let filtered: any[] = [];
    let result: any = [];
    let query = event.query;

    result = Array.from(this.selectedSectionNamearr.reduce((m: any, t: any) => m.set(t.IntentName, t), new Map()).values());

    for (let i = 0; i < result.length; i++) {
      if (result[i].IntentName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push({
          name: result[i].IntentName,
          value: result[i].IntentName
        });
      }
    }

    this.filteredIntentName = filtered
  }

  getFilteredSingleWordLList(EntitiesObj: any) {
    this.isLoader = true;
    this.singleWordService.getSingleWordData(EntitiesObj).subscribe((res: any) => {

      if (res.Status = 'SUCCESS') {
        this.singleListArr = res.Data

        if (this.singleListArr.length == 0) {
          setTimeout(() => {
            ;
            this._ifNoRecords = true
          }, 1000);
        } else {
          ;
          this._ifNoRecords = false
        }

        this.isLoader = false


        this.ptable.reset();
      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  closePopup() {
    this.showPopup = false;
  }

  triggerModal(content: any) {

    this.modalService.open(content).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
