<div class="container-fluid screen-container xl-bg-transperent defaultIntentContainer"
    *ngIf="_showFlowChartScreen==false">
    <div class="mainContainerWrapper ">

        <div class="row">
            <div class="col-md-6">
                <div class="header row">
                    <div class="col-md-12">
                        <div class="float-left">
                            <span class="page-title">Context</span>
                        </div>

                        <div class="float-right">
                            <button title="Add Context" type="button" class="btn btn-primary"
                                (click)="addNewContextRow()"><img src="../../../assets/images/plus_icon.png"
                                    class="left-img"><span class="align-middle">add Context</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row faqListTable pr-15">

                    <div class="tableContent" [hidden]="_ifNoRecords">

                        <p-table #dt [value]="contextListArr" styleClass="p-custom-datatable" [rows]="10" reflow="true"
                            [rowHover]="true" [scrollable]="true" scrollHeight="flex" [(first)]="first" editMode="row"
                            [editable]="true" [resizableColumns]="true">

                            <ng-template pTemplate="colgroup">
                                <colgroup>
                                    <col *ngFor="let col of contextListHeadersArr" [style.width]='col.width'>
                                </colgroup>
                            </ng-template>

                            <ng-template pTemplate="header">
                                <tr>
                                    <th pResizableColumn *ngFor="let col of contextListHeadersArr"
                                        style="text-align: left;" [ngClass]="col.field" data-toggle="tooltip"
                                        data-placement="top" title="{{col.header}}">
                                        <div *ngIf="col.isSort === 'false' && col.field != 'DisplayMessage'"
                                            [ngClass]="{'text-overflow-header': col.width > '500%', 'pl-15':col.field === 'Context', 'xl-pl-20px':col.field === 'Context'}">
                                            {{col.header}}
                                        </div>
                                    </th>

                                </tr>
                                <tr *ngIf="isLoader">
                                    <td *ngFor="let col of contextListHeadersArr">
                                        <div class="item customSkeletonLoader">
                                            <ngx-skeleton-loader count="10" appearance=""></ngx-skeleton-loader>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-data let-ri="rowIndex" >

                                <tr *ngIf="ri == 0 && newrow" class="newTableRow">
                                    <td *ngFor="let col of contextListHeadersArr" style="padding: 0!important">

                                        <div *ngIf="col.field == 'Context'">
                                            <input pInputText type="text" class="newInputField form-control"
                                                [minLength]="1" maxlength="200" placeholder="Add your question here ..." (keypress)='CheckforWhiteSpace($event)'
                                                (keyup)='onchangeControlles($event, "Add", this.addRecord.ClicakbleFlowMaster.Context)'
                                                [(ngModel)]="this.addRecord.ClicakbleFlowMaster.Context" required>
                                        </div>

                                        <div *ngIf="col.field === 'BondModifiedOn'">
                                            <button title="Add" type="button" class="btn btn-success"
                                                (click)="addContextRecord(this.addRecord.ClicakbleFlowMaster)"
                                                [disabled]="!this.addRecord.ClicakbleFlowMaster.Context || _disableSaveBtn">Add</button>
                                            <button title="Cancel" class="cancelRow btn btn-secondary float-right"
                                                (click)="cancelNewRow()">Cancel</button>
                                        </div>
                                    </td>
                                </tr>

                                <tr *ngIf="ri == selectedRowID && editRow" class="newTableRow">
                                    <td *ngFor="let col of contextListHeadersArr" style="padding: 0!important">

                                        <div *ngIf="col.field == 'Context'">
                                            <input pInputText type="text" class="newInputField form-control"
                                                [minLength]="1" maxlength="200" placeholder="Add your question here ..." (keypress)='CheckforWhiteSpace($event)'
                                                (keyup)='onchangeControlles($event, "Edit", this.addRecord.ClicakbleFlowMaster.Context)'
                                                [(ngModel)]="this.addRecord.ClicakbleFlowMaster.Context" required>
                                        </div>

                                        <div *ngIf="col.field === 'BondModifiedOn'">
                                            <button title="Add" type="button" class="btn btn-success"
                                                (click)="updateContextRecord(this.addRecord.ClicakbleFlowMaster)"
                                                [disabled]="!this.addRecord.ClicakbleFlowMaster.Context || _disableSaveBtn">Save</button>
                                            <button title="Cancel" class="cancelRow btn btn-secondary float-right"
                                                (click)="cancelEditedRow()">Cancel</button>
                                        </div>
                                    </td>
                                </tr>

                                <tr *ngIf="!_hideEmptyRow" [ngClass]="{'d-none':ri == selectedRowID && editRow}"
                                    [hidden]="_hideContentDuringLoading || data.IsDeleted == true">
                                    <td pEditableColumn *ngFor="let col of contextListHeadersArr"
                                        [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">

                                        <div *ngIf="col.field != 'BondModifiedOn'"
                                            [ngClass]="{'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId','pl-25':col.field === 'DispId', 'xl-pl-35px':col.field === 'DispId', 'text-overflow-field': col.field === 'DisplayMessage' || col.field === 'Context'}">
                                            <a (click)="recordInfo(data)">{{data[col.field]}}</a>
                                        </div>

                                        <div class="actions w-100"
                                            [ngClass]="{'showactionBlock': col.field === 'BondModifiedOn', 'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId'}">
                                            <div class="tableActionButton w-50">
                                                <a class="cursor-pointer faqModifyActionBtn"
                                                    (click)="editContextRecord(ri,data)" title="Modify">
                                                    <img class="block_img" src="../../../assets/images/Modify.png">
                                                    <img class="hover_modify_img"
                                                        src="../../../assets/images/Modify_on_hover.png">
                                                </a>
                                            </div>
                                            <div class="tableActionButton w-50">

                                                <a (click)="OpenDeletePopup(data)" class="faqDeleteActionBtn"
                                                    title="Delete">
                                                    <img class="block_img" src="../../../assets/images/delete.png">
                                                    <img class="hover_delete_img"
                                                        src="../../../assets/images/delete_on_hover.png">
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>

                    <div class="noRecordFound text-center border-top" *ngIf="_ifNoRecords">
                        <img src="../../assets/images/no_records.png">
                        <p class="title">No Records Found</p>
                    </div>

                </div>
            </div>

            <div class="col-md-6">
                <div class="defaultOptionInfoBlock" *ngIf="_isDefaultOptionBlockEmpty">
                    <p class="mb-0">Please click on the context from left hand side table to add default options</p>
                </div>
                <div class="defaultOptionBlock" *ngIf="!_isDefaultOptionBlockEmpty">
                    <div class="header row">
                        <div class="col-md-12">
                            <div class="float-left">
                                <span class="page-title">{{selectedContextName}} - Default Option</span>
                            </div>

                            <div class="float-right">
                                <button title="Add Option" type="button" class="btn btn-primary"
                                    (click)="addNewOption()"><img src="../../../assets/images/plus_icon.png"
                                        class="left-img"><span class="align-middle">add Option</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row faqListTable">

                        <div class="tableContent" [hidden]="_ifNoRecords">

                            <p-table #dt [value]="defaultOptionListArr" styleClass="p-custom-datatable" [rows]="5"
                                reflow="true" [rowHover]="true" [scrollable]="true" scrollHeight="flex"
                                [(first)]="first" editMode="row" [editable]="true" [resizableColumns]="true">

                                <ng-template pTemplate="colgroup">
                                    <colgroup>
                                        <col *ngFor="let col of defaultOptionListHeadersArr" [style.width]='col.width'>
                                    </colgroup>
                                </ng-template>

                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pResizableColumn *ngFor="let col of defaultOptionListHeadersArr"
                                            style="text-align: left;" [ngClass]="col.field" data-toggle="tooltip"
                                            data-placement="top" title="{{col.header}}">
                                            
                                            <div *ngIf="col.isSort === 'false' && col.field != 'DisplayMessage'"
                                                [ngClass]="{'text-overflow-header': col.width > '500%', 'pl-15':col.field === 'Questions', 'xl-pl-20px':col.field === 'Questions'}">
                                                {{col.header}}
                                            </div>
                                        </th>

                                    </tr>
                                    <tr *ngIf="isDefaultOptionBlockLoader">
                                        <td *ngFor="let col of defaultOptionListHeadersArr">
                                            <div class="item customSkeletonLoader">
                                                <ngx-skeleton-loader count="10" appearance=""></ngx-skeleton-loader>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>

                                <ng-template pTemplate="body" let-data let-ri="rowIndex">

                                    <tr *ngIf="!_hideEmptyRow"
                                        [hidden]="_hideOptionContentDuringLoading || data.IsDeleted == true">
                                        <td pEditableColumn *ngFor="let col of defaultOptionListHeadersArr"
                                            [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">

                                            <div *ngIf="col.field != 'BondModifiedOn' && col.field != 'DisplayMessage'"
                                                [ngClass]="{'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId','pl-25':col.field === 'DispId', 'xl-pl-35px':col.field === 'DispId', 'text-overflow-field': col.field === 'DisplayMessage' || col.field === 'Questions'}">
                                                {{data[col.field]}}
                                            </div>

                                            <div class="actions w-100"
                                                [ngClass]="{'showactionBlock': col.field === 'BondModifiedOn', 'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId'}">
                                                <div class="tableActionButton">
                                                    <a class="mr-2 cursor-pointer" class="faqModifyActionBtn"
                                                        title="Modify" (click)="EditOption(data)">
                                                        <img class="block_img" src="../../../assets/images/Modify.png">
                                                        <img class="hover_modify_img"
                                                            src="../../../assets/images/Modify_on_hover.png">
                                                    </a>
                                                </div>
                                                <div class="tableActionButton">

                                                    <a class="mr-2" (click)="OpenDefaultOptionDeletePopup(data)"
                                                        class="faqDeleteActionBtn" title="Delete">
                                                        <img class="block_img" src="../../../assets/images/delete.png">
                                                        <img class="hover_delete_img"
                                                            src="../../../assets/images/delete_on_hover.png">
                                                    </a>
                                                </div>
                                                <div class="tableActionButton">
                                                    <a class="mr-2" class="faqConversationFlowBtn"
                                                        title="Conversational flow"
                                                        (click)="OpenConversationDesignFlow(data)">
                                                        <img class="block_img"
                                                            src="../../../assets/images/conversationFlow.png">
                                                        <img class="hover_flow_img"
                                                            src="../../../assets/images/conversationFlow_hover.png">
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>

                        <div class="noRecordFound text-center border-top" *ngIf="_ifNoRecordsForDF">
                            <img src="../../assets/images/no_records.png">
                            <p class="title">No Records Found</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<app-add-context-flow *ngIf="_showFlowChartScreen==true" (inputdata)="contextFlowListPage($event)"
    [inputVariable]="{selectedRowData:selectedRow}"></app-add-context-flow>

<ng-template #modalDataDelete let-modal="dismiss">
    <app-confirmation-modal
        [confirmModalInputVariable]="{btnname: 'Yes', isDeleteDefaultIntent:true, popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}"
        [callbackCancelFunction]="boundDeleteRow">
    </app-confirmation-modal>
</ng-template>

<ng-template #modalDefaultOptionDataDelete let-modal="dismiss">
    <app-confirmation-modal
        [confirmModalInputVariable]="{btnname: 'Yes', isDeleteDefaultIntent:true, popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}"
        [callbackCancelFunction]="boundDefaultOptionDeleteRow">
    </app-confirmation-modal>
</ng-template>

<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>

<ng-template #addDefaultOption let-modal>
    <div class="customThemeModal">
        <div class="modal-content text-left">
            <div class="modal-body">
                <div class="closePopup mb-3">
                    <a (click)="modal.dismiss('Cross click')">
                        <img src="../../../assets/images/cross.png" class="cross-img">
                    </a>
                </div>
                <div class="popupTitle mb-3 pl-0">Add default intent</div>

                <div class="popupWrapper">

                    <div class="mb-2">
                        <label>FAQ</label>

                         <textarea pInputText  class="newInputField form-control" [minLength]="1" maxlength="200" id="exampleFormControlTextarea1"
                            [(ngModel)]=" this.addIntentRecord.IntentMaintenance.Questions"  placeholder="Add your question here ..."
                            (keyup)='onchangeControllesDI($event, "Add", this.addIntentRecord.IntentMaintenance.Questions)' required></textarea> 

                        <!-- <input pInputText type="text" class="newInputField form-control" [minLength]="1" maxlength="200"
                            placeholder="Add your question here ..."
                            (keyup)='onchangeControllesDI($event, "Add", this.addIntentRecord.IntentMaintenance.Questions)'
                            [(ngModel)]="this.addIntentRecord.IntentMaintenance.Questions" required> -->
                    </div>

                    <div>
                        <label>Answer</label>
                        <textarea pInputText  class="newInputField form-control" [minLength]="1" maxlength="2000" id="exampleFormControlTextarea1"
                        [(ngModel)]=" this.addIntentRecord.IntentMaintenance.DisplayMessage"  placeholder="Add your question here ..."
                        (keyup)='onchangeControllesDI($event, "Add", this.addIntentRecord.IntentMaintenance.DisplayMessage)' required></textarea> 

<!-- 
                        <input pInputText type="text" class="newInputField form-control" [minLength]="1"
                            maxlength="2000" placeholder="Add your answer here ..."
                            (keyup)='onchangeControllesDI($event, "Add", this.addIntentRecord.IntentMaintenance.DisplayMessage)'
                            [(ngModel)]="this.addIntentRecord.IntentMaintenance.DisplayMessage" required> -->
                    </div>

                </div>

                <div class="PoupContent float-right mt-3">
                    <button title="Delete" type="button" class="btn btn-primary mr-10px deletebtn"
                        (click)="DecideAddEditForDefultOptions(_FromCLick, this.addIntentRecord.IntentMaintenance); modal.dismiss(); "
                        [disabled]='!this.addIntentRecord.IntentMaintenance.Questions  || !this.addIntentRecord.IntentMaintenance.DisplayMessage ||_disableSaveBtn'>Save</button>
                    <button title="Cancel" type="button" class="btn btn-secondary deletebtn"
                        (click)="cancelDefaultOptionPopup(); modal.dismiss('Cross click')">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>