import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, ViewChild, ElementRef, HostListener, EventEmitter, Pipe } from '@angular/core';
import { FaqService } from '../../services/faq/faq.service';
import { faqEntities } from '../../faq/fqaentities'
import { Router } from "@angular/router";

import { ChangeDetectorRef } from '@angular/core';

import { Table } from 'primeng/table';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DomSanitizer } from '@angular/platform-browser';


import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-faqlist',
  templateUrl: './faqlist.component.html',
  styleUrls: ['./faqlist.component.css'],
  providers: [DatePipe]
})

export class FaqlistComponent implements OnInit {
  @ViewChild('dt') dt: ElementRef
  @ViewChild("dt", { static: false }) public ptable: Table;

  faqListArr: any = [];
  faqHeadersArr: any = [];

  faqListArrForAddFaq: any = []

  searchDataArr: any = [];

  singleDataArr: any = [];
  dataId: number;
  vStrMainData: string;

  generatedDataOfMU: string;

  searchFAQ: string = "";
  DataForExport: any;

  selectedSearchOpt: any[];
  searchOptionArr: any = [];
  selectedOptArr: any = [];
  searchFAQArr: any = [];

  selectedSection: string = "";
  selectedFaq: string = "";
  selectedAns: string = "";
  selectedUt: string = "";
  IsSelectedCB: boolean = false;

  filterAllFields: any
  filterArr: any = [];
  isHide: boolean = true
  selectedFaqName: string = '';
  selectedResponceType: string = '';
  selectedUtterance: string = '';

  newFilterDataArr: any = [];
  selectedValues: any = [];
  toggle: boolean = false;
  chageFilterInput: boolean = false;
  totalSelectedValues: any;
  selectedFieldOutline: boolean = false;
  greyFieldOutline: boolean = false;

  hoverFilterArr: any = []
  selectedFilterOption: string

  isLoader: boolean = true;

  sortColumnName: string;
  sortorder: boolean;
  descOrder: Boolean;
  AfterUpdateColumnName: string;

  first: number = 0;
  rows = 10;
  islastPageFlag: boolean;

  parameters = new faqEntities()
  myDate: any

  totalRows: string;
  filteredSectionName: any = [];
  selectedsectionname: string;
  newSrNo: string;
  newQuestion: string;
  newAnswer: string;
  newrow: boolean = false
  addRecord = new faqEntities()

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;

  showDeletePopup: boolean = false
  _DeleteReasonsLst: any = []
  _selectedDeleteReasons: any
  _isDisplayOtherSec: boolean = false

  vselectedListTypeRowDetails: any

  dataTodelete: any
  closeModal: any
  _OtherData: any

  _RoleName: any
  _IsClient: any

  _languageArr: any = [];
  _filteredSectionNameArr: any = [];
  _buildModelArr: any = [];

  selectedFAQStatus: string = '';
  selectedTotalUtter: string = '';
  selectedResponseType: string = '';

  _ViewClientRoleAccess: boolean = false;

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;
  @ViewChild("modalListTypeData", { static: false }) public modalListTypeData: NgbModal;
  @ViewChild("modalDataDelete", { static: false }) public modalDataDelete: NgbModal;
  @ViewChild("ConfirmationModal", { static: false }) public confirmationModal: NgbModal;

  @Output() inputdata: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("comments") comment: ElementRef

  _isSelectedField: boolean = false

  _ifNoRecords: boolean = false

  title = environment.pageTitle + ' - FAQ List';

  constructor(private modalService: NgbModal, private _eref: ElementRef, public faqconfigservice: FaqService,
    private _http: HttpClient, private cdref: ChangeDetectorRef, private datePipe: DatePipe, private router: Router, private sanitizer: DomSanitizer,
    private titleService: Title) {
    this.myDate = new Date();
    this.sortorder = false;

    var faqEntitiesObj = new faqEntities()
    this.DataForExport = faqEntitiesObj.IntentMaintenance;
  }

  selectedCities: string[] = [];

  ngOnInit(): void {

    this.titleService.setTitle(this.title);

    this.getBuildModelStatus()
    this.fetchDeleteReasonListType()

    this._RoleName = window.localStorage.getItem("RoleName");

    if (this._RoleName === 'ClientView' || this._RoleName === 'ContentView') {
      this._ViewClientRoleAccess = true;
    }

    this.fetFaqList();
    this.faqListHeaders();
    this.filterDropdown();
    this.sortorder = false;

    if (this._RoleName.toUpperCase() == 'CLIENT') {
      this._IsClient = true
    }

    this.fetchLanguage();
    this.fetchBuildModel();

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  filterDropdown() {
    this.filterArr = [
      {
        label: 'FAQ Status', value: 'faqstatus',
        items: [
          { name: 'Live', code: 'faqstatus', status: 'live', checked: false },
          { name: 'Live but content need updates', code: 'faqstatus', status: 'liveContentUpdate', checked: false },
          { name: 'New and content is approved', code: 'faqstatus', status: 'liveContentApproved', checked: false },
          { name: 'Not live', code: 'faqstatus', status: 'notLive', checked: false }
        ]
      },
      {
        label: 'Response Type', value: 'rt',
        items: [
          { name: 'Text', code: 'responseType', status: 'text', checked: false },
          { name: 'List', code: 'responseType', status: 'list', checked: false },
          { name: 'URL', code: 'responseType', status: 'url', checked: false },
        ]
      },
      {
        label: 'Utterance', value: 'us',
        items: [
          { name: 'Equal to 30', code: 'utterance', status: 'equalto30', checked: false },
          { name: 'Less than 30', code: 'utterance', status: 'lessthan30', checked: false },
          { name: 'More than 30', code: 'utterance', status: 'morethan30', checked: false },
        ]
      }
    ];

    this.hoverFilterArr = [
      { name: 'Live', code: 'NY', status: 'live' },
      { name: 'Live but content need updates', code: 'RM', status: 'liveContentUpdate' },
      { name: 'New and content is approved', code: 'LDN', status: 'liveContentApproved' },
      { name: 'Not live', code: 'IST', status: 'notLive' }
    ];

    this.searchOptionArr = [
      { name: 'Section Name', code: 'sectionName' },
      { name: 'FAQ', code: 'faq' },
      { name: 'Answer', code: 'ans' }
    ]

  }

  selectOption(text: any) {
    this.isLoader = true;

    this.filterAllFields = text;
  }

  onClickedOutside(e: any) {

    this.toggle = false;
  }

  clearInputField() {

    this.selectedValues = []
    this.filterAllFields = []
    this.isHide = true

    this.filterDropdown();
    this.chageFilterInput = false

    const event = { "keyCode": 13 }

    this.searchData(event, "Search");
  }



  selectFilterOption(details: any) {
    this.isHide = false

    if (details.checked == true) {
      this.chageFilterInput = true

      var d = this.selectedValues.find((x: { code: string; }) => x.code == details.code)

      if (d) {
        this.dropSkill(d)
        this.selectedValues.push(details)
      }

      if (!d) {
        this.selectedValues.push(details)
      }
    }
    else {
      this.selectedValues.forEach((value: any, id: any) => {
        if (value.status == details.status)
          this.selectedValues.splice(id, 1)
        this.chageFilterInput = true
      });
    }

    this.totalSelectedValues = this.selectedValues.length
    if (this.totalSelectedValues === 0) {
      this.chageFilterInput = false
      this.isHide = true
    }

    this.filterAllFields = this.selectedValues;

    const event = { "keyCode": 13 }

    this.searchData(event, "Search");

  }


  dropSkill(index: any) {
    this.selectedValues.forEach((value: any, id: any) => {
      if (value.status == index.status)
        this.selectedValues.splice(id, 1)
    });

    let indexMain = this.filterArr[0].items.indexOf(index);

    if (indexMain === -1) {
      indexMain = this.filterArr[1].items.indexOf(index);

      if (indexMain === -1) {
        indexMain = this.filterArr[2].items.indexOf(index);

        if (indexMain === -1) {
        }
        else {
          const dataMain = this.filterArr[2].items.filter(function (x: any) { return x.status === index.status })[0];

          dataMain.checked = false;

          this.filterArr[2].items[indexMain] = dataMain
        }
      }
      else {
        const dataMain = this.filterArr[1].items.filter(function (x: any) { return x.status === index.status })[0];

        dataMain.checked = false;

        this.filterArr[1].items[indexMain] = dataMain
      }
    }
    else {
      const dataMain = this.filterArr[0].items.filter(function (x: any) { return x.status === index.status })[0];

      dataMain.checked = false;

      this.filterArr[0].items[indexMain] = dataMain
    }

    this.filterAllFields = this.selectedValues;

    const event = { "keyCode": 13 }

  }

  clickEvent() {
    this.selectedFieldOutline = !this.selectedFieldOutline
    this.toggle = !this.toggle
  }

  changeStyle() {
    this.greyFieldOutline = !this.greyFieldOutline
  }

  handleLink: any
  _LinkArray: any = []
  _selectedRespType: any = [];
  selectedintentname: string

  fetFaqList() {

    this.isLoader = true;
    this.parameters = new faqEntities()

    this.faqconfigservice.getAllFaqRecords(this.parameters.IntentMaintenance).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {


        this._hideEmptyRow = false;
        this.faqListArr = []
        this.faqListArr = res.Data;
        this.faqListArrForAddFaq = res.Data

        const dataMain = this.faqListArr.filter(function (x: any) { return x.IntentName === "AOHelpToChoose" });
        if (this.faqListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        } else {
          this._ifNoRecords = false
        }

        for (let j of this.faqListArr) {
          this.selectedintentname = j.IntentName
          if (this.selectedintentname === 'FAQCCApply') {
            var UrlArray = j.DisplayMessage.split('~|~');
            this._LinkArray = [];

            var linkText = UrlArray[0].split('~');

            var cnt = 1;

            function Urlify(text: any) {
              try {
                var urlRegex = /(https?:\/\/[^\s]+)/g;
                if (urlRegex.test(text)) { return 1; }
                else { return 0; }
              }
              catch (e) {

              }
              throw new Error('Function not implemented.');
            }

            for (var i = 0; i < linkText.length; i++) {

              if (i % 2 !== 0) {

                var key = linkText[i].trim();

                var value = UrlArray[cnt].trim();

                var containsURL = Urlify(value);

                this._LinkArray.push({
                  "key": key,
                  "value": value,
                  "containsURL": containsURL
                });
                cnt++;
              }
              else {
                containsURL = 0;

                key = linkText[i];

                value = key;

                this._LinkArray.push({
                  "key": key,
                  "value": value,
                  "containsURL": containsURL
                });
              }
            }
          }
        }

        this.isLoader = false;
        this.sortColumnName = '';
        this.first = 0;
        this.sortorder = false;

        this.ptable.reset();


        if (this._isDisplayBuild == undefined) {
          this._isDisplayBuild = "false"
        }

        // if (this._isDisplayBuild.toUpperCase() == 'TRUE') {
        //   this.GetAllBuildActivity();

        //   this.IntervalId = setInterval(() => {
        //     this.GetAllBuildActivity()
        //   }, 30000);
        // }
      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  IntervalId: any

  closeInterval() {
    if (this.IntervalId) {
      clearInterval(this.IntervalId);
    }
  }

  faqListHeaders() {
    this.faqHeadersArr = [
      { field: 'DispId', header: 'Sr No.', width: "4%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'SectionName', header: 'Section Name', width: "7%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'Section Name' },
      { field: 'Questions', header: 'FAQ', width: "14%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'FAQ' },
      { field: 'DisplayMessage', header: 'Answer', width: "14%", isEditable: "true", isSort: "true", textAlignment: "left", value: 'Answer' },
      { field: 'BondModifiedOn', header: 'Mod. Dt.', width: "7%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'b' },
      { field: 'status', header: '', width: "3%", isEditable: "false", isSort: "false", textAlignment: "center", value: 'abc' }
    ]
  }

  popupContentError: string

  closePopup() {

    this.showPopup = false
    this.showDeletePopup = false
  }


  addFaqRecord(event: any) {
    this.isLoader = true;
    this.newrow = false;

    this.selectedValues = []
    this.filterAllFields = []
    this.isHide = true

    this.filterDropdown();
    this.chageFilterInput = false

    this.searchFAQ = ""
    this.selectedOptArr = []
    this.selectedSearchOpt = []

    this.parameters.IntentMaintenance.Questions = event.Questions

    if (event.DisplayMessage !== undefined) {
      var message = event.DisplayMessage.split('~|~')[0];
      var updMessage = message.replace(/~/g, '');
      event.UserMessage = updMessage.replace(/<br>/g, '');
    }

    this.parameters.IntentMaintenance.DisplayMessage = event.DisplayMessage.trim()
    this.parameters.IntentMaintenance.UserMessage = event.UserMessage.trim()

    this.parameters.IntentMaintenance.Language = "EN"
    this.parameters.IntentMaintenance.ResponseType = "Text"
    this.parameters.IntentMaintenance.SectionName = event.SectionName.name === undefined ? event.SectionName : event.SectionName.name;
    this.parameters.IntentMaintenance.IsEndOfConversation = true;

    this.myDate = new Date();

    this.myDate = this.datePipe.transform(this.myDate, 'MMddyyyyHHmm');
    this.parameters.IntentMaintenance.IntentName = "FAQ" + this.myDate

    this.faqconfigservice.addFaqRecord(this.parameters.IntentMaintenance).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        this.isLoader = false;

        this.sortColumnName = '';
        this.first = 0;
        this.sortorder = false;

        this.showPopup = false;
        this.popupImg = "../../../assets/images/file-plus.png"
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)

        this.fetFaqList();

      } else if (res.Error) {
        this.isLoader = false;

        this.showPopup = false;
        this.popupImg = "../../../assets/images/file-plus-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;
      }
    })
  }

  filterSectionName(event: any) {

    let filtered: any[] = [];
    let result: any = [];
    let query = event.query;

    result = Array.from(this.faqListArr.reduce((m: any, t: any) => m.set(t.SectionName, t), new Map()).values());

    for (let i = 0; i < result.length; i++) {
      if (result[i].SectionName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push({
          name: result[i].SectionName,
          value: result[i].SectionName
        });
      }
    }


    this.filteredSectionName = filtered
  }

  onRowEditInit(book: any) {

  }

  _hideEmptyRow: boolean = false
  addNewRow() {
    this.newrow = true;
    this._ifNoRecords = false

    var data = {
      SectionName: "",
      Questions: "",
      DisplayMessage: ""
    }

    if (this.faqListArr.length == 0) {
      this.faqListArr.push(data);
      this._hideEmptyRow = true;
    }

    this.addRecord.IntentMaintenance.SectionName = "";
    this.addRecord.IntentMaintenance.Questions = "";
    this.addRecord.IntentMaintenance.DisplayMessage = "";
    this.first = 0;

    this.ptable.scrollTo({ top: 0, behavior: 'smooth' });
  }

  cancelNewRow() {
    ;
    this.newrow = false;

    this.addRecord.IntentMaintenance.SectionName = "";
    this.addRecord.IntentMaintenance.Questions = "";
    this.addRecord.IntentMaintenance.DisplayMessage = "";

    if (this.faqListArr.length == 1) {
      setTimeout(() => {
        this._ifNoRecords = true
      }, 1000);
    } else {
      this._ifNoRecords = false
    }

  }

  fetchDeleteReasonListType() {
    this._DeleteReasonsLst = [
      { name: 'Testing', code: '1' },
      { name: 'Duplicate', code: '2' },
      { name: 'Merged with another current intent', code: '3' },
      { name: 'Removal requested by client', code: '4' },
      { name: 'Other', code: '5' }
    ]
  }

  OnSelectDeletedReason() {
    if (this._selectedDeleteReasons.name.toUpperCase() == 'OTHER') {


      this._isDisplayOtherSec = true

      if (this._OtherData) {
        this._disableDeleteBtn = false
      }
      else {
        this._disableDeleteBtn = true
      }
    }
    else {
      this._isDisplayOtherSec = false

      this._disableDeleteBtn = false
    }
  }

  _QuestionTodelete: any
  OpenDeletePopup(data: any) {

    this._OtherData = "";
    this.dataTodelete = data

    this._QuestionTodelete = data.Questions
    this.fetchDeleteReasonListType();

    this._selectedDeleteReasons = {
      code: "1",
      name: "Testing"
    }

    this._disableDeleteBtn = false;
    this._isDisplayOtherSec = false;

    this.triggerModal(this.modalDataDelete);
  }

  deleteFaqRecord(vCheckBoxSelectedList: any, vOtherData: any) {


    this.selectedValues = []
    this.filterAllFields = []
    this.isHide = true

    this.filterDropdown();
    this.chageFilterInput = false

    this.searchFAQ = ""
    this.selectedOptArr = []
    this.selectedSearchOpt = []

    this.showDeletePopup = false
    this.isLoader = true

    if (this._selectedDeleteReasons == undefined) {
      this._selectedDeleteReasons = {
        code: "1",
        name: "Testing"
      }
    }

    if (this._selectedDeleteReasons.name.toUpperCase() == 'OTHER') {
      this.parameters.IntentMaintenance.DeleteReason = this._OtherData
      this.parameters.IntentMaintenance.DeleteReasonNo = this._selectedDeleteReasons.code
    }
    else {
      this.parameters.IntentMaintenance.DeleteReason = this._selectedDeleteReasons.name
      this.parameters.IntentMaintenance.DeleteReasonNo = this._selectedDeleteReasons.code
    }

    this.parameters.IntentMaintenance.IntentId = this.dataTodelete.IntentId
    this.parameters.IntentMaintenance.IntentName = this.dataTodelete.IntentName

    this.faqconfigservice.deleteFaqRecord(this.parameters.IntentMaintenance).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        this.isLoader = true

        this.sortColumnName = '';
        this.sortorder = false;

        this.popupImg = "../../../assets/images/delete-popup.png";
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo);

        this.fetFaqList();

      }
      else if (res.Error) {
        this.isLoader = true

        this.showPopup = false
        this.popupImg = "../../../assets/images/Delete-popup-error.png";
        this.popupContent = "Oops!";

        this.popupContentError = res.Error;
      }
    })
  }

  selectSearchOption(text: any) {
    this.isLoader = true;
    this.selectedOptArr = text;

    this.selectedSection = "";
    this.selectedFaq = "";
    this.selectedAns = "";
    this.selectedUt = "";

    var searchEntities = new faqEntities();

    if (this.selectedOptArr.length != 0) {

      for (let i = 0; i < this.selectedOptArr.length; i++) {
        if (this.selectedOptArr[i].code.toUpperCase() == "SECTIONNAME") {
          this.selectedSection = this.searchFAQ
        } else if (this.selectedOptArr[i].code.toUpperCase() == "FAQ") {
          this.selectedFaq = this.searchFAQ
        } else if (this.selectedOptArr[i].code.toUpperCase() == "ANS") {
          this.selectedAns = this.searchFAQ
        } else if (this.selectedOptArr[i].code.toUpperCase() == "UTTERANCES") {
          this.selectedUt = this.searchFAQ
        }
      }
      this.IsSelectedCB = true;
    }

    else {
      this.selectedSection = this.searchFAQ;
      this.selectedFaq = this.searchFAQ;
      this.selectedAns = this.searchFAQ;
      this.selectedUt = this.searchFAQ
      this.IsSelectedCB = false;
    }

    searchEntities.IntentMaintenance.SectionName = this.selectedSection;
    searchEntities.IntentMaintenance.Questions = this.selectedFaq;
    searchEntities.IntentMaintenance.UserMessage = this.selectedAns;
    searchEntities.IntentMaintenance.IsSelectedCB = this.IsSelectedCB;

    this.getfilterDatafromArray();

    searchEntities.IntentMaintenance.FaqStatus = this.selectedFAQStatus;
    searchEntities.IntentMaintenance.ResponseType = this.selectedResponseType;
    searchEntities.IntentMaintenance.StrTotalUtter = this.selectedTotalUtter;

    this.searchFaqRecord(searchEntities.IntentMaintenance);

  }

  searchData(event: any, actionName: any) {

    this.selectedSection = "";
    this.selectedFaq = "";
    this.selectedAns = "";
    this.selectedUt = "";

    if (event.keyCode === 13) {
      var searchEntities = new faqEntities();

      this.searchFAQ = this.searchFAQ.trim();

      if (this.selectedOptArr.length != 0) {

        for (let i = 0; i < this.selectedOptArr.length; i++) {
          if (this.selectedOptArr[i].code.toUpperCase() == "SECTIONNAME") {
            this.selectedSection = this.searchFAQ.substring(0, 50);
          } else if (this.selectedOptArr[i].code.toUpperCase() == "FAQ") {
            this.selectedFaq = this.searchFAQ.substring(0, 200);
          } else if (this.selectedOptArr[i].code.toUpperCase() == "ANS") {
            this.selectedAns = this.searchFAQ
          } else if (this.selectedOptArr[i].code.toUpperCase() == "UTTERANCES") {
            this.selectedUt = this.searchFAQ
          }
        }
        this.IsSelectedCB = true;
      }
      else {
        this.selectedSection = this.searchFAQ.substring(0, 50);
        this.selectedFaq = this.searchFAQ.substring(0, 200);
        this.selectedAns = this.searchFAQ;
        this.selectedUt = this.searchFAQ
        this.IsSelectedCB = false;
      }

      searchEntities.IntentMaintenance.SectionName = this.selectedSection;
      searchEntities.IntentMaintenance.Questions = this.selectedFaq;
      searchEntities.IntentMaintenance.UserMessage = this.selectedAns;
      searchEntities.IntentMaintenance.IsSelectedCB = this.IsSelectedCB;

      this.getfilterDatafromArray();

      searchEntities.IntentMaintenance.FaqStatus = this.selectedFAQStatus;
      searchEntities.IntentMaintenance.ResponseType = this.selectedResponseType;
      searchEntities.IntentMaintenance.StrTotalUtter = this.selectedTotalUtter;

      if (actionName.toUpperCase() === "SEARCH") {
        this.searchFaqRecord(searchEntities.IntentMaintenance);

      }
      else if (actionName.toUpperCase() === "EXPORT") {
        ;
        this.exportCSV(searchEntities.IntentMaintenance);
      }
    }
  }

  searchFaqRecord(searchObj: any) {
    ;
    this._ifNoRecords = false
    this.isLoader = true;
    this.DataForExport = searchObj;

    this._hideEmptyRow = false;
    this.newrow = false;

    this.faqconfigservice.searchFaqRecord(searchObj).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {

        this.faqListArr = res.Data;
        this.isLoader = false;
        this.toggle = false;

        this.sortColumnName = '';
        this.AfterUpdateColumnName = "";
        this.sortorder = false;

        this.ptable.reset();

        if (this.faqListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        } else {
          this._ifNoRecords = false
        }


      } else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  private getfilterDatafromArray() {

    this.selectedFAQStatus = "";
    this.selectedResponseType = "";
    this.selectedTotalUtter = "";

    if (this.filterAllFields != undefined) {

      for (let i = 0; i < this.filterAllFields.length; i++) {
        if (this.filterAllFields[i].code.toUpperCase() == "FAQSTATUS") {
          if (this.selectedFAQStatus == "")
            this.selectedFAQStatus = this.filterAllFields[i].name;

        }
        else if (this.filterAllFields[i].code.toUpperCase() == "RESPONSETYPE") {
          if (this.selectedResponseType == "")
            this.selectedResponseType = this.filterAllFields[i].name;

        }
        else if (this.filterAllFields[i].code.toUpperCase() == "UTTERANCE") {
          if (this.selectedTotalUtter == "")
            this.selectedTotalUtter = this.filterAllFields[i].name;

        }
        else {
          this.selectedFaqName = "";
          this.selectedResponceType = "";
          this.selectedUtterance = "";
        }
      }
    }
  }

  AfterCLickExport() {

    const event = {
      keyCode: 13
    }

    this.searchData(event, "Export")
  }

  exportCSV(searchObj: any) {
    ;

    this.DataForExport.SorColumnName = this.sortColumnName
    this.DataForExport.Reverseorder = this.sortorder
    this.DataForExport.AfterUpdateColumnName = this.AfterUpdateColumnName

    this.faqconfigservice.ExportFAQData(this.DataForExport).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        ;
        var status = res.status;
        const headers = res.headers;
        const data = res.Data;
        var contentType = 'application/CSV';
        var linkElement = document.createElement('a');
        var blob = new Blob([data], { type: contentType });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';

        this.myDate = new Date();

        this.myDate = this.datePipe.transform(this.myDate, 'MMddyyyyHHmm');
        a.download = "FAQList_" + this.myDate + '.csv';

        document.body.appendChild(a);
        a.click();

        this.popupImg = "../../../assets/images/download-popup.png";
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo);


      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo);
      }
    })
  }

  sortcolumn(ColumnName: any) {

    let reverse = this.sortColumnName === ColumnName ? !this.descOrder : undefined;

    if (reverse === undefined)
      reverse = false;

    this.sortColumnName = ColumnName;
    this.descOrder = reverse;

    if (this.descOrder == true)
      this.sortorder = false;
    else
      this.sortorder = true;
  }

  GOToEnteredPageNo(pageNo: any): void {

    if (pageNo != "0" && pageNo != "") {

      var enteredPageNo: number = +pageNo;

      this.islastPageFlag = this.isLastPage(enteredPageNo);

      if (this.islastPageFlag === false) {
        this.first = enteredPageNo * 10 - 10;
      }
      else {
        const pageCount = Math.ceil(this.faqListArr.length / this.rows);

        let page = pageCount - 1;
        page = page * this.rows

        this.first = page
      }
    }
    else {
      this.first = 0;
    }
  }

  isLastPage(pageNo: any): boolean {


    const pageCount = Math.ceil(this.faqListArr.length / this.rows);

    const page = pageCount - 1;

    if (pageNo <= page) {
      return false;
    }
    else {
      return true;
    }
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onKeypressEvent(event: any) {
    var eventLength = event.target.value
    var textLength = eventLength.length;
    var htmlElement = this.comment.nativeElement as HTMLElement;

    if (textLength % 50 == 0) {
      var height = textLength / 50;

      if (height >= 2) {
        htmlElement.setAttribute("style", "height:93px !important")
      } else {
        htmlElement.setAttribute("style", "height:auto !important")
      }
    }

  }

  _buildActivity: any
  _newVersion: any
  _modelName: any =
    {
      name: "Model 1",
      value: "Model 1"
    }

  _modelName1: any
  _BuildActivity: any
  _BuildModelPerc: any
  _BuildStatus: boolean = true
  _BAlink: any
  _Lang = "EN";

  _BuildmodelIP = {
    "StartTesting": false,
    "IntentTest": false
  };

  _Language = ["EN", "ES"];
  _IsDisableModelDropDown = false

  // _Models = ["MODEL1", "MODEL2"];
  _CurrentModel: string
  _IsStartTesting: boolean = true

  GetAllBuildActivity() {

    this.faqconfigservice.GetAllBuildActivity().subscribe((res: any) => {

      if (res.Status.toUpperCase() == 'SUCCESS') {
        if (res.Data.length !== 0) {

          this._buildActivity = res.Data;

          this._buildActivity.forEach((value: any, key: any) => {


            this._newVersion = value.BuildNewVersion;
            this._modelName = { name: value.BuildModelName, value: value.BuildModelName };
            this._modelName1 = value.BuildModelName
            this._BuildActivity = value.BuildStatus;
            this._BuildModelPerc = value.BuildModelPerc;

            if (value.OverAllStatus.toUpperCase() === "REQUESTED" || value.OverAllStatus.toUpperCase() === "INPROGRESS") {
              this._BuildStatus = true;
              this._BAlink = true;

              // if (this._modelName.name.toUpperCase() === "MODEL1") {
              this._CurrentModel = 'Model ' + value.ModelNumber
              // } else if (this._modelName.name.toUpperCase() === "MODEL2") {
              //   this._CurrentModel = 'Model 2'
              // }
              // else if (this._modelName.name.toUpperCase() === "MODEL3") {
              //   this._CurrentModel = 'Model 3'
              // }
              // else {
              //   this._CurrentModel = 'Model 1'
              // }
            }
            else {
              this._BAlink = false;
              this._isDisplayBuild === 'TRUE'
              this._BuildStatus = false;
              this._modelName = {
                name: "MODEL1",
                value: "Model 1"
              }
              this._modelName1 = undefined
            }

          });
        }
        else {
          this._newVersion = "1.1.0";
          this._modelName = {
            name: "MODEL1",
            value: "Model 1"
          }
          this._BuildStatus = false;
          this._BAlink = false;

          this._modelName1 = undefined
        }
      }
      else {
        this.popupImg = "../../../assets/images/update_error.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  Check_InProgressActivity() {


    this.faqconfigservice.Check_InProgressActivity().subscribe((res: any) => {


      if (res.Status.toUpperCase() == 'SUCCESS') {

        if (res.Data.length > 0) {
          this._disableInnerBuildBtn = true;
          this._MsgInnerBuildpopup = true
          this._IsDisableModelDropDown = true
          this._IfNoModelDataMsg = 'Server is busy in training another model. Please try after some time.'
        }
        else {
          this._disableInnerBuildBtn = false;
          this._MsgInnerBuildpopup = false
          this._IsDisableModelDropDown = false
        }
      }
      else {
        this.popupImg = "../../../assets/images/CommonError.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;



        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  ckChangeTest() {
    if (this._BuildmodelIP.IntentTest === false) {
      this._IsStartTesting = true;
      this._BuildmodelIP.StartTesting = false;
    }
    else {
      this._IsStartTesting = false;
      this._BuildmodelIP.StartTesting = false;
    }
  };

  InsertBuildActivity(BuildmodelIPData: any) {

    if (BuildmodelIPData == undefined) {
      BuildmodelIPData = {
        "StartTesting": false,
        "IntentTest": false
      };
    }


    var data = {
      BuildLang: this._Lang,
      BondCreatedBy: window.localStorage.getItem("UserId"),
      BuildModelName: this._modelName.name == undefined ? this._modelName : this._modelName.name,
      BuildVersion: this._newVersion,
      StartTesting: BuildmodelIPData.StartTesting,
      IntentTest: BuildmodelIPData.IntentTest,
      ApplyLookupstoAll: true,
      ModelNumber: this._modelName.value,
      //ModelNumber: (this._modelName.name == undefined ? this._modelName : this._modelName.name).toUpperCase() == "MODEL1" ? 1 : 2,

    };

    this.faqconfigservice.InsertBuildModelDetails(data).subscribe((res: any) => {

      if (res.Status.toUpperCase() == 'SUCCESS') {
        this._BuildStatus = true;
        this._BAlink = true;
      }
      else {
        this.popupImg = "../../../assets/images/update_error.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)

        setTimeout(() => {
          this.dismisspopup();
        }, 100);
      }
    })
  }

  dismisspopup() {
    this.modalService?.dismissAll();
  }

  _isDemoBuilding: any
  _isDisplayBuild: any
  _TraningModelNameList: any

  getBuildModelStatus() {
    this.faqconfigservice.getBuildModelStatus().subscribe((res: any) => {

      if (res.Status.toUpperCase() == 'SUCCESS') {

        this._isDemoBuilding = res.Data.IsDemoBuilding;
        this._isDisplayBuild = res.Data.IsDisplayBuild.toUpperCase();
        this._TraningModelNameList = res.Data.TraningModelNames;

        if (this._isDisplayBuild.toUpperCase() == 'TRUE') {
          this.GetAllBuildActivity();

          this.IntervalId = setInterval(() => {
            this.GetAllBuildActivity()
          }, 30000);
        }

        if (this._TraningModelNameList.length > 0) {
          this._TraningModelNameList.forEach((value: any, key: any) => {
            this._buildModelArr.push({ name: value.ModelName, value: value.DisplayModelName })
          });
        }

        else {
          this._buildModelArr = [
            { name: 'MODEL1', value: 'Model 1' }
          ]
        }
      }
      else {
        this.popupImg = "../../../assets/images/update_error.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  DecideBuildingInsert(BuildmodelIPData: any) {
    if (this._isDemoBuilding == "true") {

      this._BuildStatus = true;
      this._BAlink = true;

      this.closeInterval();

    }
    else {
      this.InsertBuildActivity(BuildmodelIPData)
    }
  }

  _allCompleted: any
  _OverAllStatus: any
  _allCompletedMSG: any
  _hideonCancel: any
  _BuildActivityId: any

  _showAddEditScreen: any = false

  _selectedRow: any
  _showFlowChartScreen: any = false

  ShowFaqList: any = window.localStorage.setItem("showFaq", this._showAddEditScreen)

  ShowFaqListdata: any = window.localStorage.setItem("showFaqlist", this._showFlowChartScreen)


  PageName: any
  EditRowID: any
  faqListArrData: any

  OnAddEditBtnClick(vClickBtnName: string, vEditRowId: string) {

    this.PageName = vClickBtnName
    this.EditRowID = vEditRowId
    this.faqListArrData = this.faqListArrForAddFaq

    this._showAddEditScreen = true
    window.localStorage.setItem("showFaq", this._showAddEditScreen)
  }

  cancelbuttonOnAddEdit() {
    this._showAddEditScreen = false

  }

  AddEditPageResult(SuccVal: any) {
    if (SuccVal.toUpperCase() == "SUCCESS") {

      this.fetFaqList()
      this._showAddEditScreen = false

      this.selectedValues = []
      this.filterAllFields = []
      this.isHide = true

      this.filterDropdown();
      this.chageFilterInput = false

      this.searchFAQ = ""
      this.selectedOptArr = []
      this.selectedSearchOpt = []
    }
    else {
      this._showAddEditScreen = false
    }
  }

  CloseConFlowWindow(SuccVal: any) {

    this._showFlowChartScreen = false
  }

  CloseModel() {

  }

  fetchLanguage() {
    this._languageArr = [
      { name: 'English', code: 'en' }
      // ,      { name: 'Spanish', code: 'sp' }
    ]
  }

  fetchBuildModel() {



    // this._buildModelArr = [
    //   { name: 'Model 1' },
    //   { name: 'Model 2' }
    // ]
  }

  _MsgInnerBuildpopup = false
  _IfNoModelDataMsg: string = ""
  filterSectionNameAE(event: any, vselectedModel: any) {

    if (this.faqListArr.length != 0) {
      var dataMain = this.faqListArr.filter(function (x: any) { return x.ModelName === vselectedModel.name.toUpperCase() });

      if (dataMain.length > 0) {
        this._disableInnerBuildBtn = false;
        this._MsgInnerBuildpopup = false;

      }
      else {
        this._disableInnerBuildBtn = true;
        this._MsgInnerBuildpopup = true

        this._IfNoModelDataMsg = 'FAQs are not yet added in ' + this._modelName.value + '. Please add the FAQs and then train this model'
      }
    }
  }


  _URL_REGEXP = /^(\s+\S+\s*)*(?!\s).*$/;
  _disableDeleteBtn = false;
  _disableSaveBtn = true;
  onchangeControlles(event: any) {


    if (!this._URL_REGEXP.test(this._OtherData) || !this._OtherData) {
      this._disableDeleteBtn = true
    }
    else {
      this._disableDeleteBtn = false
    }

    if (!this._URL_REGEXP.test(this.addRecord.IntentMaintenance.SectionName) ||
      !this._URL_REGEXP.test(this.addRecord.IntentMaintenance.Questions) ||
      !this._URL_REGEXP.test(this.addRecord.IntentMaintenance.DisplayMessage)) {

      this._disableSaveBtn = true
    }
    else {
      this._disableSaveBtn = false
    }

  }

  OpenConversationDesignFlow(data: any) {
    this._selectedRow = data
    this._showFlowChartScreen = true
    window.localStorage.setItem("showFaqlist", this._showFlowChartScreen)
  }

  /* new modal popup for intent list */

  triggerIntentListModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'NormalThemeModal', backdrop: 'static', keyboard: false }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReasonForIntentList(res)}`;
    });
  }

  private getDismissReasonForIntentList(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  OpenListTypeRowDetailsPopup(rowData: any) {

    this.vselectedListTypeRowDetails = rowData
    this.triggerIntentListModal(this.modalListTypeData)
  }

  _disableInnerBuildBtn = true;

  ResetBuildPouup() {
    this._MsgInnerBuildpopup = false
    this._disableInnerBuildBtn = false

    this._modelName = { name: 'MODEL1', value: 'Model 1' }
    this._BuildmodelIP = { "StartTesting": false, "IntentTest": false };

    this.Check_InProgressActivity();
  }
}

