

<div class="notificationContainer">
    <div class="blockHeader">
        <div class="title">
            Notification
        </div>
        <div class="float-right closeBlock">
            <a (click)="closeNotificationBlock()">
                <img src="../../assets/images/Notification_cross.png">
            </a>
        </div>
    </div>

    <div class="blockContainer">
        <a class="clearAllLink" (click)="clearAllNotifications()">Clear All</a>
        <div *ngFor=" let item of NotificationArr">
            <div class="header">
                <div class="title">
                    {{item.NotificationOn | date:'MM/dd/yyyy'}}
                </div>
            </div>
            <div class="contentBlock">
                <div class="contentBlockheader">
                    <div class="float-left">
                        <img src="../../assets/images/notification_chatbot_small_icon.png"
                            class="smallChatbotIcon chatbotIcon">
                        <img src="../../assets/images/notification_chatbot_medium_icon.png"
                            class="mediumChatbotIcon chatbotIcon">
                        <span class="title">{{item.Module}}</span>
                    </div>
                    <div class="float-right">
                        <span class="subTitle">
                            {{item.NotificationOn | date:'MM/dd/yyyy'}}
                        </span>
                        <span class="subTitle">
                            {{item.NotificationOn | date:'h:mm a'}}
                        </span>
                    </div>
                </div>
                <div class="contentBlockData">

                    <div class="media">
                        <div class="media-body">
                            <p>{{item.NotificationSubject}}</p>
                        </div>

                        <a>
                            <img src="../../assets/images/closeNotitfication.png"
                                class="closeNotification align-self-center">
                        </a>
                    </div>

                    <p [innerHTML]="item.NotificationDesc" id="notificationDescription_{{item.NotificationId}}" class="text-overflow-field">
                        Question:{{item.NotificationDesc}}</p>

                    <div class="contentBlockDataFooter">
                        <div class="float-left notificationId">
                            #{{item.NotificationId}}
                        </div>
                        <div class="float-right">
                            <a (click)="readMoreDetails(item.NotificationId)" id="{{item.NotificationId}}">
                                <img src="../../assets/images/notification_samll_arrow-down.png" class="smallDownArrow">
                                <img src="../../assets/images/notification_samll_arrow-down.png" class="bigDownArrow">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <img src="../../assets/images/notification_small_triangle.png" class="messageEndTriangle">
        </div>
    </div>

</div>