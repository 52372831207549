import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';

import { HttpCommonReq } from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})
export class NavigationMenuService {

  configUrls:any = [];

  constructor(private httpClient: HttpClient, private router: Router) {
    this.configUrls = {
      getNavigationMenuList: 'api/Navigation/GetNavigationMenu',
    }
  }

  getNavigationMenuData(){
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
    return httpClientCommonObj.get(this.configUrls.getNavigationMenuList)
  }
}
