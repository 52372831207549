import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-theme-structure',
  templateUrl: './theme-structure.component.html',
  styleUrls: ['./theme-structure.component.css']
})
export class ThemeStructureComponent implements OnInit {

  _Pagename: boolean = false
  _currentURL: any

  _isTestBotContent: boolean = false
  _isOtherContent: boolean = true

  _fetchUrl: boolean = false

  ShowNotificationBlock: boolean
  ShowChat: boolean

  ShowFAQListBlock: boolean

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  fetchCurrentUrl() {
    
    if (this.router.url === '/testBot') {

    }
  }

  fetchURL(fetchurl: any) {
    if (fetchurl == 'TestBot') {
      this._fetchUrl = true
    } else {
      this._fetchUrl = false
    }
  }

  fetchNotificationValue(flagValue: any) {
    this.ShowNotificationBlock = flagValue

    if (this.ShowNotificationBlock === false) {
      $("#notificationLink").removeClass("active-link");
    } else if (this.ShowNotificationBlock === true) {
      $("#notificationLink").addClass("active-link")
    }
  }

  closeNotificationBlock(hideFlagValue: any) {
    this.ShowNotificationBlock = hideFlagValue

    if (this.ShowNotificationBlock === false) {
      $("#notificationLink").removeClass("active-link");
    } else if (this.ShowNotificationBlock === true) {
      $("#notificationLink").addClass("active-link")
    }
  }
}
