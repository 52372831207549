import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';

import { LoginComponent } from './LoginComponent/login/login.component';
import { FaqlistComponent } from './faq/faqlist/faqlist.component';
import { DeletedfaqsComponent } from './faq/deletedfaqs/deletedfaqs.component';
import { NewAccountsComponent } from './NewAccounts/new-accounts.component';
import { AMLComponent } from './aml/aml.component';
import { KYCComponent } from './kyc/kyc.component';
import { FailedFAQsComponent } from './faq/failed-faqs/failed-faqs.component';
import { FAQUsageComponent } from './faq/faqusage/faqusage.component';
import { DashboardTargetComponent } from './dashboard-target/dashboard-target.component';
import { LKPListComponent } from './lkp-list/lkp-list.component';
import { SingleWordComponent } from './single-word/single-word.component';
import { ProductionLogComponent } from './production-log/production-log.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { IntentTestDetailsComponent } from './intent-test-details/intent-test-details.component';
import { NotficationComponent } from './notfication/notfication.component';
import { SettingsComponent } from './settings/settings.component';
import { TestBotComponent } from './test-bot/test-bot.component';
import { PersonaClusterComponent } from './persona-cluster/persona-cluster.component';
import { ClusterComponent } from './cluster/cluster.component';
import { UsageDashboardComponent } from './usage-dashboard/usage-dashboard.component';

import { ThemeStructureComponent } from './theme-structure/theme-structure.component';
import { UserChatHistoryComponent } from './user-chat-history/user-chat-history.component';
import { AddfaqComponent } from './faq/addfaq/addfaq.component';

import { AuthGuardService as AuthGuard } from './services/auth/auth-guard.service';
import { RoleGuardService as RoleGuard } from './services/auth/role-guard.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AssignModelComponent } from './faq/assign-model/assign-model.component';

import { AgentLoginComponent } from './agent-login/agent-login.component';
import { BuildActivityComponent } from './faq/build-activity/build-activity.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ClickableFlowComponent } from './faq/clickable-flow/clickable-flow.component';
import { ConfusionMatrixComponent } from './confusion-matrix/confusion-matrix.component';
import { DefaultIntentComponent } from './default-intent/default-intent.component';
import { ContextFlowComponent } from './faq/context-flow/context-flow.component';

import { EnterpriseDashboardComponent } from './enterprise-dashboard/enterprise-dashboard.component';
import { EnterpriseUserAccountComponent } from './enterprise-user-account/enterprise-user-account.component';
import { BankUserDashboardComponent } from './bank-user-dashboard/bank-user-dashboard.component';

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "login", component: LoginComponent },
  { path: "forgotpassword", component: ForgotpasswordComponent },
  { path: "resetpassword", component: ResetpasswordComponent },
  { path: "AgentLogin", component: AgentLoginComponent },
  { path: "unauthorized", component: UnauthorizedComponent },
  { path: "accessdenied", component: AccessDeniedComponent },
  {
    path: "portal", component: ThemeStructureComponent,
    children: [
      { path: "", redirectTo: 'AccountAnalytics', pathMatch: 'full' },
      {
        path: "AccountAnalytics",
        component: UsageDashboardComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Client', 'Agent', 'Marketing', 'ClientView', 'ContentView']
        }
      },
      {
        path: "EnterpriseAccountAnalytics",
        component: EnterpriseDashboardComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Enterprise']
        }
      },
      {
        path: "BankUserAccountAnalytics",
        component: BankUserDashboardComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['bankUser']
        }
      },
      {
        path: "addfaq",
        component: AddfaqComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Client', 'Agent', 'ClientView', 'ContentView']
        }
      },
      {
        path: "ChatBOT/FAQList",
        component: FaqlistComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Client', 'Agent', 'ClientView', 'ContentView']
        }
      },
      {
        path: "ChatBOT/DeletedFAQs",
        component: DeletedfaqsComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Client', 'Agent', 'ClientView', 'ContentView']
        }
      },
      {
        path: "Accounts/UserAccounts",
        component: NewAccountsComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Client', 'Agent', 'ClientView', 'ContentView']
        }
      },
      {
        path: "Accounts/EnterpriseUserAccounts",
        component: EnterpriseUserAccountComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Enterprise', 'bankUser']
        }
      },
      {
        path: "Accounts/AMLRules",
        component: AMLComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Client', 'Agent', 'ClientView', 'ContentView', 'Enterprise', 'bankUser']
        }
      },
      {
        path: "Accounts/KYCRules",
        component: KYCComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Client', 'Agent', 'ClientView', 'ContentView', 'Enterprise', 'bankUser']
        }
      },
      {
        path: "BOTUsage/ChatHistory",
        component: FailedFAQsComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Client', 'Agent', 'ClientView', 'ContentView']
        }
      },
      { path: "BOTUsage/FAQUsage", component: FAQUsageComponent },
      {
        path: "ChatBOT/DashboardTarget",
        component: DashboardTargetComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Agent', 'ContentView']
        }
      },
      {
        path: "ChatBOT/LKPList",
        component: LKPListComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Agent', 'ContentView']
        }
      },
      {
        path: "ChatBOT/SingleWordList",
        component: SingleWordComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Agent', 'ContentView']
        }
      },
      // {
      //   path: "ChatBOT/ClickableFlow",
      //   component: ClickableFlowComponent,
      //   canActivate: [RoleGuard],
      //   data: {
      //     allowedRoles: ['Admin', 'Content', 'Agent', 'ContentView']
      //   }
      // },
      {
        path: "ChatBOT/ClickableFlow",
        component: ContextFlowComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Agent', 'ContentView']
        }
      },
      { path: "productionLog", component: ProductionLogComponent },
      {
        path: "ChatBOT/User Details",
        component: UserDetailsComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Agent', 'ContentView']
        }
      },

      {
        path: "ModelReports/IntentTestDetails",
        component: IntentTestDetailsComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Agent', 'ContentView']
        }
      },
      {
        path: "ModelReports/ConfusionMatrix",
        component: ConfusionMatrixComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Agent', 'ContentView']
        }
      },
      {
        path: "ChatBOT/DefaultIntent",
        component: DefaultIntentComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Agent', 'ContentView']
        }
      },
      {
        path: "notification",
        component: NotficationComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'ContentView', 'Enterprise', 'bankUser']
        }
      },
      {
        path: "settings",
        component: SettingsComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Client', 'Agent', 'ClientView', 'ContentView', 'Enterprise', 'bankUser']
        }
      },
      {
        path: "ChatBOT/TestBOT",
        component: TestBotComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Client', 'Agent', 'ClientView', 'ContentView']
        }
      },
      {
        path: "Personas/Individual",
        component: PersonaClusterComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Client', 'Agent', 'Marketing', 'ClientView', 'ContentView', 'Enterprise', 'bankUser']
        }
      },
      {
        path: "Personas/Cluster",
        component: ClusterComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Client', 'Agent', 'ClientView', 'ContentView', 'Enterprise', 'bankUser']
        }
      },
      { path: "userChatHistory", component: UserChatHistoryComponent },
      { path: "login", component: LoginComponent },
      {
        path: "assignModel",
        component: AssignModelComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Client', 'Agent']
        }
      },
      {
        path: "ChatBOT/buildActivity",
        component: BuildActivityComponent,
        canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Admin', 'Content', 'Agent']
        }
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
