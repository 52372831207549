
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";

import { NotificationService } from '../../services/Notification/notification.service'
import { NavigationMenuService } from '../../services/NavigationMenu/navigation-menu.service'

import { Entities } from '../../../app/faq/deletedfaqs/Entities'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {

  toggle: boolean = false;
  toggle_menu: boolean = true;

  _RoleName = window.localStorage.getItem("RoleName");

  _ShowModelPopup: boolean = true;
  _PopupTitle: string = '';
  _PopupDescription: string = '';
  _Warning: string = '';
  _Access: boolean = false;
  _ViewRoleAccess: boolean = false;
  _ViewClientRoleAccess: boolean = false;

  showApprovePopup: boolean = false
  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string;
  closeModal: any
  popupPrimaryBtn: string;

  NotifBubble: any = false;
  totalNotif: number = 0

  personaPopupExpand: any = "false"
  onboardingPopupExpand: any = "false"
  chatbotPopupExpand: any = "false"
  botUsagePopupExpand: any = "false"
  modelReportsPopupExpand: any = "false"



  @ViewChild('pc') pc: ElementRef
  @ViewChild('pcss') pcss: ElementRef

  @ViewChild('ob') ob: ElementRef
  @ViewChild('obss') obss: ElementRef

  @ViewChild('cb') cb: ElementRef
  @ViewChild('cbss') cbss: ElementRef

  @ViewChild('ud') ud: ElementRef

  @ViewChild("ConfirmationModal", { static: false }) public ConfirmationModal: NgbModal;
  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;

  selectedMenuItem: boolean = false

  ShowNotificationBlock: any;
  HideBlock: any = window.localStorage.getItem("HideNotitficationBlock");
  @Input() inputVariable: any;
  @Output() fetchShowNotificationValue: EventEmitter<any> = new EventEmitter<any>();

  _navigationMenuArr: any = [];
  _navigationMenuHeaderArr: any = []
  result: any = [];

  _isLoader: boolean = false

  hideAccountMenu: Boolean = false
  hidePersonaMenu: Boolean = false
  hideChatbotMenu: Boolean = false

  vCheckHeightForNormalScreen: any
  vCheckHeightForLargeScreen: any
  vCheckHeightForExtraLargeScreen: any

  constructor(public notificationService: NotificationService, public navigationMenuservice: NavigationMenuService, private router: Router, private modalService: NgbModal) {
    this._ShowModelPopup = true;
  }

  declare $: any;
  ngOnInit(): void {
    this.Authorize();
    this.getNotificationsList();

    this.fetchNavigationMenu();
  }

  clickEvent() {
    this.toggle = !this.toggle;

    if (this.toggle == true) {
      this.personaPopupExpand = false
      this.pcss.nativeElement.classList.remove("show");

      this.onboardingPopupExpand = false
      this.obss.nativeElement.classList.remove("show");

      this.chatbotPopupExpand = false
      this.cbss.nativeElement.classList.remove("show");

      this.botUsagePopupExpand = false
      this.cbss.nativeElement.classList.remove("show");

      this.modelReportsPopupExpand = false
      this.cbss.nativeElement.classList.remove("show");
    }
  }

  checkSidebarCollapse() {
    if (this.toggle == true) {
      this.toggle = false
    }
  }

  Authorize() {

    if (this._RoleName === 'ClientView' || this._RoleName === 'ContentView') {
      this._ViewRoleAccess = true;
    }

    if (this._RoleName === 'ClientView' || this._RoleName === 'Client') {
      this._ViewClientRoleAccess = true;
    }
  }

  togg() {
    return this.toggle_menu = !this.toggle_menu;
  }

  OnCLickLogout() {

    this.popupImg = "../../../assets/images/update_error.png";
    this.popupContent = "Are you sure you want to log out?";
    this.popupContentError = "";
    this.popupPrimaryBtn = "Log out"
    this.triggerModal(this.ConfirmationModal)
  }

  closePopup() {
    this.showPopup = false
    this.showApprovePopup = false;
  }

  getNotificationsList() {
    let EntitiesObj = new Entities();
    const UserId = window.localStorage.getItem("UserId");
    EntitiesObj.Notifications.NotificationTo = UserId == null ? "" : UserId;

    this.notificationService.getNotificationData(EntitiesObj.Notifications).subscribe((res: any) => {

      if (res.Status == 'Success') {
        if (res.Data.length > 0)
          this.NotifBubble = true;
        this.totalNotif = res.Data.length

      }
      else if (res.Error) {
        this.NotifBubble = false;

        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  commonWarningPopup() {

    this.showPopup = false
    this.popupImg = "../../../assets/images/update_error.png";
    this.popupContent = "Logout";
    this.popupContentError = "Are you sure you want to logout?";

  }

  LogOut() {
    window.localStorage.clear();

    window.location.href = '/'
  }

  collapseMenu() {
    this.checkSidebarCollapse();

    this.personaPopupExpand = this.pc.nativeElement.getAttribute('aria-expanded')
    this.personaPopupExpand = false
    this.pcss.nativeElement.classList.remove("show");

    this.onboardingPopupExpand = this.ob.nativeElement.getAttribute('aria-expanded')
    this.onboardingPopupExpand = false
    this.obss.nativeElement.classList.remove("show");

    this.chatbotPopupExpand = this.cb.nativeElement.getAttribute('aria-expanded')
    this.chatbotPopupExpand = false
    this.cbss.nativeElement.classList.remove("show");

    this.botUsagePopupExpand = this.cb.nativeElement.getAttribute('aria-expanded')
    this.botUsagePopupExpand = false
    this.cbss.nativeElement.classList.remove("show");

    this.modelReportsPopupExpand = this.cb.nativeElement.getAttribute('aria-expanded')
    this.modelReportsPopupExpand = false
    this.cbss.nativeElement.classList.remove("show");

  }

  removeActiveClass(menu_variable: any) {
    if (menu_variable == 'personaCluster') {
      this.checkSidebarCollapse();
      this.personaPopupExpand = true
    } else if (menu_variable == 'onboarding') {
      this.checkSidebarCollapse();
      this.onboardingPopupExpand = true
    } else if (menu_variable == 'chatbot') {
      this.checkSidebarCollapse();
      this.chatbotPopupExpand = true
    }else if (menu_variable == 'BOTUsage') {
      this.checkSidebarCollapse();
      this.botUsagePopupExpand = true
    }else if (menu_variable == 'ModelReports') {
      this.checkSidebarCollapse();
      this.modelReportsPopupExpand = true
    }
    this.ud.nativeElement.classList.remove('active-link')
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  showNotification() {
    this.ShowNotificationBlock = true;
    this.fetchShowNotificationValue.emit(this.ShowNotificationBlock);
  }

  fetchFaqTable() {
    let showFaqVal = window.localStorage.getItem("showFaq")
    let showFaqDataList = window.localStorage.getItem("showFaqlist")

    if (showFaqVal == 'true' || showFaqDataList == 'true') {
      let currentUrl = '/portal/ChatBOT/FAQList';
      this.router.navigateByUrl('/portal', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    }
  }

  fetchClickableFlowTable() {
    let showClickableFlowlist = window.localStorage.getItem("showClickableFlowlist")

    if (showClickableFlowlist == 'true') {
      let currentUrl = '/portal/ChatBOT/ClickableFlow';
      this.router.navigateByUrl('/portal', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    }
  }

  fetchNavigationMenu() {
    
    this._isLoader = true

    this.navigationMenuservice.getNavigationMenuData().subscribe((res: any) => {

      
      if (res.Status.toUpperCase() == "SUCCESS") {
        this._navigationMenuArr = res.Data
        let subMenuAccount: any = [];
        let subMenuPersona: any = [];
        let subMenuChatBot: any = [];
        for (let i of this._navigationMenuArr) {
          this._navigationMenuHeaderArr.push({ "ModuleName": i.ModuleDisplayName })

          if (i.ModuleDisplayName === "Accounts") {
            subMenuAccount.push(i)
          } else if (i.ModuleDisplayName === "Personas") {
            subMenuPersona.push(i)
          } else if (i.ModuleDisplayName === "Chat BOT") {
            subMenuChatBot.push(i)
          }
        }

        this._navigationMenuHeaderArr = Array.from(this._navigationMenuHeaderArr.reduce((m: any, t: any) => m.set(t.ModuleName, t), new Map()).values());

        if (subMenuAccount.length == 0) {
          this.hideAccountMenu = true
        }
        if (subMenuPersona.length == 0) {
          this.hidePersonaMenu = true
        }
        if (subMenuChatBot.length == 0) {
          this.hideChatbotMenu = true
        }

        this.calculateDivHightAsPerMenu();


        this._isLoader = false
      }
      else if (res.Error) {

        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  calculateDivHightAsPerMenu() {
    
    let vMenuArrExceptChatbotOption = []
    if(this._navigationMenuHeaderArr.length == 0) {
      this.vCheckHeightForNormalScreen = "100%"
      this.vCheckHeightForLargeScreen = "100%"
      this.vCheckHeightForExtraLargeScreen = "100%"
    }else {
      vMenuArrExceptChatbotOption = this._navigationMenuHeaderArr.filter((x: { ModuleName: string; }) => x.ModuleName.toUpperCase() !== 'CHAT BOT');
      for(let i=0; i < vMenuArrExceptChatbotOption.length; i++) {
        this.vCheckHeightForNormalScreen = (262 + (40*i)) 
        this.vCheckHeightForLargeScreen = (460 + (40*i))
        this.vCheckHeightForExtraLargeScreen = (736 + (40*i))
      }
    }
  }

  setSettingValue() {
    window.localStorage.setItem("setting", "false");
  }
}
