<div class="container-fluid screen-container xl-bg-transperent ">

    <div class="mainContainerWrapper">

        <div class="header row">
            <div class="col-12">
                <div class="float-left">
                    <span class="page-title">Single Word List</span>
                </div>
            </div>
        </div>

        <div class="row addFaqWrapper singleWordListWrapper">
            <div class="col-md-12">
                <div class="d-inline-block mr-20px mb-2 p-button-icon multiselectSearch" >
                    <p-autoComplete   panelStyleClass="customAutocomplete" [(ngModel)]="selectedSectionName" style="color: black !important;" (onSelect)="filterSelection($event)"
                        [suggestions]="filteredSectionName" (completeMethod)="filterSectionName($event)"  (onClear)="filterSelection($event)"
                        [dropdown]="true" field="name" [minLength]="1" placeholder="Select Section">
                    </p-autoComplete>
                </div>

                <div class="d-inline-block mr-20px mb-2">
                    <p-autoComplete class="customAutocomplete" [(ngModel)]="selectedIntentName" (onSelect)="filterIntentSelection($event)"
                        [suggestions]="filteredIntentName" (completeMethod)="filterIntentName($event)" [dropdown]="true" (onClear)= "filterIntentSelection($event)"
                        field="name" [minLength]="1" placeholder="Select intent name" >
                    </p-autoComplete>
                </div>

                <div class="d-inline-block mb-2">
                    <input pInputText type="text" class="custom-input-field form-control" [(ngModel)]="selectedSingleWord"
                    placeholder="Enter single word" (keypress)="searchData($event, 'SEARCH')">
                </div>
            </div>
        </div>

        <div class="row">

            <div class="tableContent" [hidden]="_ifNoRecords">
                <p-table #dt [value]="singleListArr" styleClass="p-custom-datatable" [rows]="10" [paginator]="true"
                    reflow="true" paginatorTemplate="{RowsPerPageDropdown}" paginatorPosition="bottom"
                    paginatorPosition="bottom" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,40,50]"
                    currentPageReportTemplate="Displaying {last} of {totalRecords}" [rowHover]="true"
                    [scrollable]="true" scrollHeight="flex" [resizableColumns]="true" [(first)]="first" editMode="row" [editable]="true">

                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col *ngFor="let col of singleListHeadersArr" [style.width]='col.width'>
                        </colgroup>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn *ngFor="let col of singleListHeadersArr" [pSortableColumn]="col.field"
                                style="text-align: left;" [ngClass]="col.field" data-toggle="tooltip"
                                data-placement="top" title="{{col.header}}">
                                <div class="tableHeader" *ngIf="col.isSort === 'true'"
                                    [ngClass]="{'border-right-dadadd': col.field != 'BondCreatedOn'}">
                                    <div class="d-inline-block text-overflow-header"
                                        [ngClass]="{'pl-15':col.field === 'DispID', 'xl-pl-20px':col.field === 'DispID'}">
                                        {{col.header}}
                                    </div>
                                    <div class="tableSortIcon">
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </div>
                                </div>
                                <div class="tableHeader" *ngIf="col.isSort === 'false'"
                                    [ngClass]="{'border-right-dadadd': col.field != 'BondCreatedOn'}">
                                    <div class="d-inline-block text-overflow-header">
                                        {{col.header}}
                                    </div>
                                </div>
                            </th>

                        </tr>
                        <tr *ngIf="isLoader">
                            <td *ngFor="let col of singleListHeadersArr">
                                <div class="item customSkeletonLoader">
                                    <ngx-skeleton-loader count="20" appearance=""></ngx-skeleton-loader>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-car let-ri="rowIndex">
                        <tr>
                            <td pEditableColumn *ngFor="let col of singleListHeadersArr"
                                [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">
                                <div [ngClass]="{'xl-pl-14px':col.field !== 'DispID', 'pl-10':col.field !== 'DispID'}">
                                    <div *ngIf="col.field != 'BondCreatedOn'"
                                        [ngClass]="{'pl-25':col.field === 'DispID', 'xl-pl-35px':col.field === 'DispID'}">
                                        {{car[col.field]}}
                                    </div>

                                    <div *ngIf="col.field === 'BondCreatedOn'">
                                        {{car[col.field] | date : 'MM/dd/yyyy HH:mm:ss'}}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                </p-table>
            </div>

            <div class="noRecordFound text-center border-top" *ngIf="_ifNoRecords">
                <img src="../../assets/images/no_records.png">
                <p class="title">No Result Found</p>
                <p class="mb-0 subtitle">Sorry, we couldn’t find any results for this search.</p>
            </div>

        </div>

    </div>

    <ng-template #modalWarningAndInfo let-modal>
        <div class="customThemeModal">
            <div class="" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="closePopup">
                            <a (click)="modal.dismiss('Cross click')">
                                <img src="../../../assets/images/cross.png" class="cross-img">
                            </a>
                        </div>
                        <div class="popupImg">
                            <img src="{{popupImg}}" class="left-img">
                        </div>
                        <div class="popupTitle">{{popupContent}}</div>
                        <div class="popupContent">{{popupContentError}}</div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>