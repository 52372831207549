import { Component, OnInit } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css']
})
export class AccessDeniedComponent implements OnInit {

  title = environment.pageTitle + ' - Access denied';

  constructor(private titleService: Title, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    setTimeout(() => {
      this.dismisspopup();
    }, 100);

    // Get a reference to the last interval + 1
    const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);

    // Clear any timeout/interval up to that id
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
  }

  ngOnDestroy() {
  }

  dismisspopup() {
    this.modalService.dismissAll();
  }
}
