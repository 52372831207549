import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  CanActivateChild,
  RouterStateSnapshot
} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router, public jwtHelper: JwtHelperService) { }

  canActivate(

    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    const allowedRoles = next.data.allowedRoles;
    const isAuthorized = this.auth.isAuthorized(allowedRoles);

    if (!isAuthorized) {

      window.localStorage.clear();

      this.router.navigate(['accessdenied']); 
    }

    return isAuthorized;
  }

  // allowedRoles: string[]
  // constructor(public auth: AuthService, public router: Router, public jwtHelper: JwtHelperService) { }
  // canActivate(route: ActivatedRouteSnapshot): boolean {
  //   // this will be passed from the route config
  //   // on the data property
  //   const expectedRole = localStorage.getItem('RoleName');
  //   const token = localStorage.getItem('token');
  //   // decode the token to get its payload
  //   // const tokenPayload = decode(token);
  //   if (
  //     !this.auth.isAuthenticated()
  //   ) {
  //     this.router.navigate(['login']);
  //     return false;
  //   }
  //   // return true;
  //   return this.allowedRoles.includes(expectedRole !== null ? JSON.parse(expectedRole) : "");
  // }
}
