<div class="tenantWrapper text-center">
    <p-dropdown class="CalenderDropDown" [options]="_tenantList" [(ngModel)]="selectedTenant" optionLabel="TenantName">
    </p-dropdown>

    <div class="wrapperBtn">
        <button title="Login now" class="btn btn-success login_button mr-4" (click)="selectTenant()">Select
            Tenant</button>
        <button title="Login now" class="btn btn-success login_button" (click)="Cancel()">Cancel</button>
    </div>
</div>

<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>