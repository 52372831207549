<form autocomplete="off" [formGroup]="accountInfoForm" (ngSubmit)="submitAccountInfoForm()" class="accountInfo-Form">
    <div class="form-group mb-0">
        <input type="text" class="form-control textBoxSizes " formControlName="FirstName" id="FirstName"
            autocomplete="off" placeholder="First Name" [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }" [(ngModel)]='_firstName'>

        <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
            <div *ngIf="f.FirstName.errors?.required">First Name is required</div>
        </div>
        <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
            <div *ngIf="f.FirstName.errors?.pattern">First Name should contain alphabets</div>
        </div>
    </div>

    <div class="form-group">
        <input type="text" class="form-control textBoxSizes " formControlName="LastName" id="LastName"
            autocomplete="off" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }" [(ngModel)]='_lastName'>

        <div *ngIf="submitted && f.LastName.errors" class="invalid-feedback">
            <div *ngIf="f.LastName.errors?.required">Last Name is required</div>
        </div>
        <div *ngIf="submitted && f.LastName.errors" class="invalid-feedback">
            <div *ngIf="f.LastName.errors?.pattern">Last Name should contain alphabets</div>
        </div>
    </div>

    <div class="form-group mb-0">
        <input type="text" class="form-control textBoxSizes " formControlName="Email" id="Email" autocomplete="off"
            placeholder="Enter email" [ngClass]="{ 'is-invalid': submitted && f.Email.errors }" [(ngModel)]='_emailId' readonly>

        <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
            <div *ngIf="f.Email.errors?.required">Email is required</div>
            <div *ngIf="f.Email.errors?.email">Email must be a valid email address</div>
        </div>
    </div>
    <div class="text-center">
        <button title="Login now" class="btn btn-success login_button">Update</button>
    </div>
</form>

<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>