import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http'

import {HttpCommonReq} from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})
export class KYCService {

  configUrls:any = [];

  constructor(private httpClient: HttpClient, private router: Router) {    
    this.configUrls = {
      getKYCList:  'api/KYCCheckList/GetAllData',
      exportKYCList: 'api/KYCCheckList/ExportCSVFile',
      UpdateKYCStatus: 'api/KYCCheckList/UpdateKYCData'
    }
   }

  getAllKYCData(){
   let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
   return httpClientCommonObj.get(this.configUrls.getKYCList)
  }

  exportKYCData(data: any){
   let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
   return httpClientCommonObj.post(this.configUrls.exportKYCList,data)
  }
  
  UpdateKYCData(data: any){
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
   return httpClientCommonObj.post(this.configUrls.UpdateKYCStatus,data)
  }
}
