<div class="container-fluid screen-container xl-bg-transperent">
    <div class="row addFaqWrapper">
        <div class="col-md-9">
            <div class="mainContainerWrapper">
                <div class="header row">
                    <div class="col-md-12">
                        <div class="float-left">
                            <span *ngIf="_PageName != 'Edit'" class="page-title">Add FAQ</span>
                            <span *ngIf="_PageName == 'Edit'" class="page-title">Modify FAQ</span>
                        </div>

                        <!-- <div class="float-right">
                            <div class="switchInput panel-heading" *ngIf='!_IsClient'>
                                <div class="label">Campaign FAQ</div>
                                <div class="switchInputContainer">
                                    <p-inputSwitch (onChange)="handleChangeRI($event);onchangeControlles($event)"
                                        [(ngModel)]="AEparameters.IntentMaintenance.IsCampaignFAQ"
                                        [disabled]=" _IsCampiagnFAQ">
                                    </p-inputSwitch>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="row hdBasicrow mb-20px xl-mb-38px pl-15 pr-15">
                    <div class="col-md-12">
                        <div class="d-inline-block mr-20px">
                            <div class="label">Section Name <span class="asterisk">*</span></div>
                            <p-autoComplete class="customAutocomplete" [minLength]="1" [maxlength]="50"
                                [(ngModel)]="selectedSectionNameAE" [suggestions]="filteredSectionNameAE"
                                (completeMethod)="filterSectionNameAE($event)" field="name" [dropdown]="true"
                                (mouseover)="inputHoverEffect()" (mouseout)="removeInputHoverEffect()"
                                (onDropdownClick)="effectOnClick()" (onHide)="removeDropdownEffect()"
                                (onSelect)="effectOnSelectOption($event); onchangeControlles($event)"
                                (onKeyUp)='onchangeControlles($event)'>
                            </p-autoComplete>
                        </div>

                        <div class="d-inline-block mr-20px">
                            <div class="label">Language</div>
                            <p-dropdown [options]="language" class="CalenderDropDown" [(ngModel)]="selectedLanguage"
                                (onChange)='onchangeControlles($event)' optionLabel="name" [disabled]=true>
                            </p-dropdown>
                        </div>

                        <div class="d-inline-block mr-20px">
                            <div class="label">Model Name</div>
                            <p-dropdown [options]="_Models" class="CalenderDropDown" [(ngModel)]="_selectedModel"
                                (onChange)='onchangeControlles($event)' optionLabel="code" >
                            </p-dropdown>
                        </div>



                        <div class="d-inline-block mr-20px" *ngIf="_PageName == 'Edit' && !_ViewClientRoleAccess">
                            <div class="label">Intent Name <span class="asterisk">*</span></div>
                            <input pInputText type="text" maxlength="50" class="intentInputField form-control"
                                [(ngModel)]=" AEparameters.IntentMaintenance.IntentName"
                                (keyup)='onchangeControlles($event);'
                                [disabled]="_IsClient || _IsContent || singleDataArr.FaqStatus=='Live' || singleDataArr.FaqStatus=='Live but content need updates'"
                                required>
                        </div>

                        <!-- <div class="d-inline-block" *ngIf="_PageName == 'Edit' && !_ViewClientRoleAccess">
                            <div class="label">Conversational Flow</div>
                            <div class="switchInputContainer">
                                <p-inputSwitch (onChange)="checkIntentName();onchangeControlles($event)"
                                    [(ngModel)]="AEparameters.IntentMaintenance.IsEndOfConversation">
                                </p-inputSwitch>
                            </div>
                        </div>                       -->
                    </div>
                </div>

                <div class="row hdBasicrow mb-20px xl-mb-50px pl-15 pr-15">
                    <div class="col-md-12" *ngIf="_PageName != 'Edit'">
                        <div class="blockLabel">User's Question <span class="asterisk">*</span></div>
                        <textarea class="questionTextArea form-control" maxlength="200" id="exampleFormControlTextarea1"
                            [(ngModel)]=" AEparameters.IntentMaintenance.Questions"
                            (keyup)='onchangeControlles($event);'></textarea>
                    </div>
                    <div class="col-md-12" *ngIf="_PageName == 'Edit'">
                        <div class="blockLabel">User's Question <span class="asterisk">*</span></div>
                        <textarea class="questionTextArea form-control" maxlength="200" id="exampleFormControlTextarea1"
                            [(ngModel)]=" AEparameters.IntentMaintenance.Questions"
                            (keyup)='onchangeControlles($event);' readonly></textarea>
                    </div>
                </div>

                <div class="row hdBasicrow pl-15 pr-15">
                    <div class="col-md-12">
                        <div class="blockLabel">Bot's Answer <span class="asterisk">*</span></div>

                        <div class="position-relative">
                            <div class="d-inline-block w-100">
                                <div class="textAreaBlock botAnsTextArea">
                                    <textarea class="botTextArea form-control" maxlength="2000"
                                        id="exampleFormControlTextarea1" (keyup)="PrivewAnswer($event)"
                                        (keyup)='onchangeControlles($event)'
                                        [(ngModel)]=" AEparameters.IntentMaintenance.DisplayMessage"></textarea>
                                    <div class="botTextarea-footer">
                                        <p-dropdown class="CalenderDropDown" [options]="responceType"
                                            [(ngModel)]="_selectedRespType" optionLabel="name"
                                            (onChange)="PrivewAnswer($event);onchangeControlles($event)">
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div class="row mt-20px xl-mt-50px" *ngIf='_IsClient!=true'>
                    <div id="accordion" class="btnAccordion w-100">
                        <div class="text-center" (click)="onAdvanceClick()" *ngIf='_PageName=="Add"'>
                            <h2>
                                <span class="toggleBtn" data-toggle="collapse" data-target="#collapseOne"
                                    aria-expanded="false" aria-controls="collapseOne">
                                    <span class="spanTtitle">Advanced</span>
                                    <span _ngcontent-hox-c182="" class="submenu-icon ml-auto"
                                        style="border: none;padding: 0;">
                                    </span>
                                </span>
                            </h2>

                        </div>
                        <div>
                            <div class="row hdBasicrow xl-mb-50px pl-30 pr-30 pl-54px pr-54px">
                                <div class="col-md-6">
                                    <div class="blockLabel">Main Data</div>
                                    <div class="position-relative">
                                        <div class="d-inline-block w-100">
                                            <div class="textAreaBlock">
                                                <textarea class="advanceBlockTextArea form-control" maxlength="2000"
                                                    id="exampleFormControlTextarea1"
                                                    [(ngModel)]=" AEparameters.IntentMaintenance.MainData"
                                                    (keyup)='onchangeControlles($event);'></textarea>
                                                <div class="advanceTextarea-footer">
                                                    <button title="Create" type="button"
                                                        class="singleWordBtn modify-link-light link-light"
                                                        [disabled]='!AEparameters.IntentMaintenance.MainData || _disableCreateBtn==true'
                                                        (click)="OpenMuiltipleutteranncesPopup(AEparameters.IntentMaintenance)">Create</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="blockLabel">Single Word</div>
                                    <div class="position-relative">
                                        <div class="d-inline-block w-100">
                                            <div class="textAreaBlock singleWordTextArea">
                                                <textarea class="advanceBlockTextArea form-control" maxlength="500"
                                                    id="exampleFormControlTextarea1"
                                                    [(ngModel)]=" AEparameters.IntentMaintenance.SingleWords"
                                                    (keyup)="showAllCounts( AEparameters.IntentMaintenance.SingleWords, AEparameters.IntentMaintenance.MultipleUtterance, $event)"
                                                    (keyup)='onchangeControlles($event)'></textarea>
                                                <div class="advanceTextarea-footer">
                                                    <span>
                                                        <button title="Update" type="button"
                                                            class="singleWordBtn modify-link-light link-light"
                                                            *ngIf="singleDataArr.FaqStatus!='Not live'&& singleDataArr.FaqStatus != 'New and content is approved'"
                                                            [disabled]="_PageName=='Add'|| _SingleWordFlag==false"
                                                            (click)="UpdateSingleWord( AEparameters.IntentMaintenance)">Update</button>
                                                    </span>
                                                    <span class="rightContent">
                                                        Total Single Words {{_TotalSinglewords}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row hdBasicrow muTextareaBlock pl-30 pr-30 pl-54px pr-54px">
                                <div class="col-md-12">
                                    <br>
                                    <div class="blockLabel">Multiple Utterance</div>
                                    <div class="textAreaBlock pl-0 pr-0" [hidden]="isLoader">
                                        <!-- <textarea class="muTextarea p-20px form-control advanceBlockTextArea"
                                            id="LemmaMUTextarea"
                                            [(ngModel)]=" AEparameters.IntentMaintenance.MultipleUtterance" (keyup)="showAllCounts( AEparameters.IntentMaintenance.SingleWords,  AEparameters.IntentMaintenance.MultipleUtterance, $event);
                                            validateUtterCount()" (keyup)='onchangeControlles($event)'
                                            (change)='onchangeControlles($event)'></textarea> -->
                                        <div contenteditable class="muTextarea form-control advanceBlockTextArea"
                                            id="LemmaMUTextarea" (input)="onNgModelChange($event)" (input)="showAllCounts( AEparameters.IntentMaintenance.SingleWords,  AEparameters.IntentMaintenance.MultipleUtterance, $event);
                                            validateUtterCount()" (input)='onchangeControlles($event)'
                                            (change)='onchangeControlles($event)' [innerHtml]="AEparameters.IntentMaintenance.MultipleUtterance"></div>
                                        <div class="textAreaBlockFooter">
                                            <div class="d-inline-block">
                                                <button title="Apply Lemma" type="button"
                                                    class="btn btn-light aaplyLemmaBtn"
                                                    [disabled]='!content || _DisabledUtterBts'
                                                    (click)="ApplyLemmatization()">Apply
                                                    Lemma</button>
                                            </div>
                                            <div class="d-inline-block switchInput panel-heading">
                                                <div class="label">Apply</div>
                                                <div class="switchInputContainer">
                                                    <p-inputSwitch
                                                        (onChange)="CallLookupMethods()"
                                                        [(ngModel)]="_isCheckedRI"
                                                        [disabled]="!content || _DisabledUtterBts">
                                                    </p-inputSwitch>
                                                </div>
                                            </div>
                                            <div class="d-inline-block rightContent">
                                                Total Utterance {{_TotalmultipleUtternces}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="textAreaBlock pl-0 pr-0" *ngIf="isLoader">

                                        <div class="item customSkeletonLoader">
                                            <ngx-skeleton-loader count="3" appearance=""></ngx-skeleton-loader>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row hdBasicrow pl-15 pr-15">
                    <div class="col-md-12">
                        <div class="addFaqFormFooter">

                            <div class="addFaqFormBtns d-inline-block float-left">
                                <button title="Update" type="button" class="btn btn-primary"
                                    (click)="AddUpdateFAQData( AEparameters.IntentMaintenance);" [disabled]='_disableMainUpdateButton || !selectedSectionNameAE || !AEparameters.IntentMaintenance.IntentName || 
                                    !AEparameters.IntentMaintenance.Questions || !this.AEparameters.IntentMaintenance.DisplayMessage 
                                    ||  _IsDisbale==false || _createData ==false'>Update</button>
                                <button title="Cancel" type="button" class="btn btn-secondary"
                                    [disabled]='_disableCreateBtn' (click)='cancelbuttonOnAddEdit()'>Cancel</button>
                            </div>

                            <div class="statusDropdown d-inline-block float-right" *ngIf='_IsClient!=true'>

                                <p-dropdown class="StatusDropDown" [options]="hoverFilterArr"
                                    [(ngModel)]="selectedFilterOption" optionLabel="name"
                                    (onChange)='onchangeControlles($event);'>

                                    <ng-template let-item pTemplate="selectedItem">
                                        <i class="filter-status-color"
                                            [ngClass]="{'status-bg-live': item.status === 'live', 'status-bg-live-update':item.status === 'liveContentUpdate', 
                                        'status-bg-content-approved': item.status === 'liveContentApproved', 'status-bg-not-live': item.status === 'notLive'}"></i>
                                        {{item.name}}
                                    </ng-template>

                                    <ng-template let-filterOption pTemplate="item">
                                        <div class="filterOption-item">
                                            <div>
                                                <div class="filter-status-color"
                                                    [ngClass]="{'status-bg-live': filterOption.status === 'live', 'status-bg-live-update':filterOption.status === 'liveContentUpdate', 
                                                            'status-bg-content-approved': filterOption.status === 'liveContentApproved', 'status-bg-not-live': filterOption.status === 'notLive'}">
                                                </div>
                                                {{filterOption.name}}
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-md-3">

            <div class="row">
                <div class="col-md-12">
                    <div class="ChatBotContainerWrapper">
                        <div class="scrollableChatbotPreviewContainer">
                            <div #container class="panel-body" id="previewIntentBodyContainer" id='ChatBody'
                                *ngIf="_ispreviewClicked == true"
                                [style]="'--featured-bgColor:' + 'linear-gradient('+ _gradStartColor +', '+ _gradEndColor +')' ">

                                <div class="previewLable">
                                    <span>Chat Bot Preview</span>
                                </div>

                                <dl id='conversations'>
                                    <div>
                                        <div *ngIf="_provirewQuestion!=''">
                                            <dt class='row' id='userMessages'>

                                                <div class="col-md-12">
                                                    <div class='bbuser-msg bbcon-common Wrap'>
                                                        <span id='userMessage'>{{ _provirewQuestion}}</span>
                                                    </div>
                                                </div>
                                            </dt>
                                        </div>

                                        <div class='bbchatIconMain remove-gradient-effect' id='botMessage'
                                            [ngClass]="{'d-none':_isBotResponceNull == true}">

                                            <img src='../../../assets/images/chat_bot/transparent_chatbot.png'
                                                [style]="'background-color:' + _gradStartColor "
                                                class='pull-left bbmessage-icon bbchatIconChild' />

                                            <div class="remove-chatbot-gradient ">
                                                <dd *ngIf="_selectedRespType.name =='Text'"
                                                    class='bbbot-response white-space-pre margin-left-33 bbcon-common Wrap'>
                                                    <!-- <p class='margin-0 bbcon-common Wrap' [innerHtml]="_provirewAnswer">
                                                    </p> -->
                                                    <div>
                                                        <span *ngFor='let URLText of _TextArray; let i = index'>
                                                            <span *ngIf="URLText.containsURL===0"
                                                                [innerHtml]="URLText.key"></span>
                                                            <a target='_blank' *ngIf="URLText.containsURL===1"
                                                                [style]="'--textColor:' + _gradEndColor "
                                                                class='link-color' href='{{URLText.value}}'>
                                                                <span [innerHtml]="URLText.key"></span>
                                                            </a>
                                                        </span>
                                                    </div>
                                                </dd>

                                                <dd *ngIf="_selectedRespType.name=='List'"
                                                    class='bbbot-response word-wrap-br margin-left-33 bbcon-common Wrap'>
                                                    <!-- <p class='margin-0 bbcon-common Wrap' [innerHtml]="_provirewAnswer">
                                                    </p> -->
                                                    <div>
                                                        <span *ngFor='let URLText of _ListArray; let i = index'>
                                                            <span *ngIf="URLText.containsURL===0"
                                                                [innerHtml]="URLText.key"></span>
                                                            <a target='_blank' *ngIf="URLText.containsURL===1"
                                                                [style]="'--textColor:' + _gradEndColor "
                                                                class='link-color' href='{{URLText.value}}'>
                                                                <span [innerHtml]="URLText.key"></span>
                                                            </a>
                                                        </span>
                                                    </div>
                                                </dd>

                                                <dd *ngIf="_selectedRespType.name=='URL'"
                                                    class='bbbot-response word-wrap-br margin-left-33 bbcon-common Wrap'>
                                                    <div>
                                                        <span *ngFor='let URLText of _LinkArray; let i = index'>
                                                            <span *ngIf="URLText.containsURL===0"
                                                                [innerHtml]="URLText.key"></span>
                                                            <a target='_blank' *ngIf="URLText.containsURL===1"
                                                                [style]="'--textColor:' + _gradEndColor "
                                                                class='link-color' href='{{URLText.value}}'>
                                                                <span [innerHtml]="URLText.key"></span>
                                                            </a>
                                                        </span>
                                                    </div>
                                                </dd>
                                            </div>
                                        </div>
                                    </div>
                                </dl>

                                <div class='row'>
                                    <div class='col-md-12 col-xs-9 col-sm-9 list-padding'>
                                        <div *ngIf='!_LTI'>
                                            <div *ngFor='let item of _SampleTextData'>
                                                <input type='submit'
                                                    class='btn btn-default bblstbtn list-options-margin-4'
                                                    value={{item}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class='bbParentHelptext'>
                                    <dl>
                                        <div class='bbcon-common bbChildHelptextScroll1' id='relatedIntent'>
                                            <dt class='bbhelptext disp-none' id='relativeQue1'
                                                *ngIf="RelativeQue1 !=''">{{
                                                RelativeQue1 }} </dt>
                                            <dt class='bbhelptext disp-none' id='relativeQue2'
                                                *ngIf="RelativeQue2 !=''">{{
                                                RelativeQue2 }} </dt>
                                            <dt class='bbhelptext disp-none' id='relativeQue3'
                                                *ngIf="RelativeQue3 !=''">{{
                                                RelativeQue3 }} </dt>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="CommentTextBox">
                        <label class="comment_Lable"> Comments</label>

                        <textarea class=" CommentTextBoxTextArea form-control" maxlength="2000"
                            (keyup)='onchangeControlles($event);'
                            [(ngModel)]=" AEparameters.IntentMaintenance.Miscellaneous"></textarea>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf=' _IsClient !=true'>
                <div class="col-md-12">
                    <div class="RelatedIntentDropDown">
                        <label class="Related_Intent_Lable"> Related Intent</label>

                        <div class="bankDashboardFilter" (clickOutside)="onClickedOutsideRI($event)">

                            <div class="mb-2 dropdown-field">
                                <p-autoComplete class="customAutocomplete" [minLength]="1" [maxlength]="50"
                                    [(ngModel)]="_selectedRelatedIntentAE" [suggestions]="_filteredRelatedIntentAE"
                                    (completeMethod)="filterRelatedIntentAE($event)" field="name" [dropdown]="true"
                                    (mouseover)="inputHoverEffect()" (mouseout)="removeInputHoverEffect()"
                                    (onDropdownClick)="effectOnClick()" (onHide)="removeDropdownEffect()"
                                    (onSelect)="effectOnSelectOption($event); selectFilterOptionRI($event) ; onchangeControlles($event)"
                                    (onKeyUp)='onchangeControlles($event)'>
                                </p-autoComplete>
                            </div>

                        </div>

                        <div class="customChipsContainer">

                            <div class="customChips" *ngFor="let item of selectedValuesRI; let i = index">
                                <span *ngIf="item.status==true" class="chipsName"
                                    [ngClass]="{'isStatusTrueClass':item.status==true}">
                                    <span class="filter-status-color"></span>{{item.name}}</span>
                                <span *ngIf="item.status==false" class="chipsName">{{item.name}}</span>

                                <span><a class="crossbtn" (click)="dropSkillRI(item);"><img
                                            src="../../../assets/images/CrossBtn.png"></a></span>
                            </div>

                        </div>


                        <button title="Update" type="button" class="singleWordBtn modify-link-light link-light"
                            [disabled]="_PageName=='Add'"
                            *ngIf="singleDataArr.FaqStatus!='Not live'&& singleDataArr.FaqStatus != 'New and content is approved'"
                            [disabled]="_DisabledUpdateRI"
                            (click)="UpdateRelatedIntents( AEparameters.IntentMaintenance)">
                            <span class="align-middle RIUpdateBtnLable">Update</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #ConfirmationModal let-modal="dismiss">
    <app-confirmation-modal
        [confirmModalInputVariable]="{btnname: 'Yes' , popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal, pendingstatus:  AEparameters.IntentMaintenance, iscancel:true}"
        [callbackFunction]="boundedMainYesValue" [callbackCancelFunction]="boundedMainNoValue">
    </app-confirmation-modal>
</ng-template>

<ng-template #ConfirmationModalCampaign let-modal="dismiss">
    <app-confirmation-modal
        [confirmModalInputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, IsCampaignFAQ:_CampiagnFAQ, dismissFunction: modal}"
        [callbackFunction]="boundCancle">
    </app-confirmation-modal>
</ng-template>

<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>

<ng-template #modalDataDelete let-modal>
    <div class="deletePopupModal">
        <div class="modal-content">
            <div class="modal-body">
                <div class="deleteClosePopup">
                    <a (click)="modal.dismiss('Cross click')">
                        <img src="../../../assets/images/cross.png" class="cross-img">
                    </a>
                </div>
                <div class="deletePopupTitle">This will delete the utterance</div>
                <br>

                <div class="popupWrapper">

                    <div class="popupWrapperHeader">do you want to delete?</div>
                    <br>
                </div>

                <div class="PoupContent deletebtnsdiv">
                    <button title="Delete" type="button" class="btn btn-primary mr-10px deletebtn"
                        (click)="deleteFaqRecord(); modal.dismiss(); ">Yes</button>
                    <button title="Cancel" type="button" class="btn btn-secondary deletebtn"
                        (click)="modal.dismiss('Cross click')">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalCreateData let-modal>
    <div class="deletePopupModal">
        <div class="modal-content">
            <div class="modal-body">
                <div class="deleteClosePopup">
                    <a (click)="modal.dismiss('Cross click')">
                        <img src="../../../assets/images/cross.png" class="cross-img">
                    </a>
                </div>
                <div class="deletePopupTitle">This will be update Multiple Utterances</div>
                <br>

                <div class="popupWrapper">

                    <div class="popupWrapperHeader">Do you want to update Multiple Utterances?</div>
                    <br>
                </div>

                <div class="PoupContent deletebtnsdiv">
                    <button title="Delete" type="button" class="btn btn-primary mr-10px deletebtn"
                        (click)="createBtnforMainData(AEparameters.IntentMaintenance.MainData, AEparameters.IntentMaintenance); modal.dismiss(); ">Yes</button>
                    <button title="Cancel" type="button" class="btn btn-secondary deletebtn"
                        (click)="modal.dismiss('Cross click')">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>