<div class="container-fluid screen-container xl-bg-transperent ">
    <div class="mainContainerWrapper confusionMatrixWrapper">

        <div class="header row">
            <div class="col-md-12">
                <div class="float-left">
                    <span class="page-title">Confusion Matrix</span>
                </div>
            </div>
        </div>

        <div class="row addFaqWrapper singleWordListWrapper subHeader xl-pl-20px xl-pr-20px">
            <div class="col-md-12" style="margin-bottom: 10px!important;">
                <div class="search-field d-inline-block mr-20px mb-2">

                    <div class="static-img"><img src="../../../assets/images/search.png"></div>
                    <input type="text" class="form-control custom-input-field" placeholder="Search by question"
                        [(ngModel)]="searchUSER">
                </div>

                <div class="d-inline-block mr-20px mb-2">
                    <p-autoComplete class="customAutocomplete" [(ngModel)]="selectedExpectedIntent"
                        (onSelect)="filterIntentSelection($event)" [suggestions]="filteredExpectedIntent"
                        (completeMethod)="filterExpectedIntent($event)" [dropdown]="true" field="name" [minLength]="1"
                        placeholder="Select expected intent">
                    </p-autoComplete>
                </div>

                <div class="d-inline-block mr-20px  mb-2">
                    <input type="text" pInputText class="range-input-field custom-input-field form-control"
                        [(ngModel)]="formsttedrangeValues" placeholder="Select numarical range" readonly />
                    <p-slider [(ngModel)]="rangeValues" [range]="true" (onChange)="handleChange($event)"></p-slider>
                </div>

                
                <div class="d-inline-block mr-20px  mb-2">
                    <p-dropdown [options]="_buildModelArr" class="CalenderDropDown" [(ngModel)]="_selectedModel"
                         optionLabel="code" placeholder="Select Model" >
                    </p-dropdown>
                </div>


                <button title="Search" type="button" class="btn btn-primary "
                    (click)="searchData(searchUSER,selectedExpectedIntent, rangeValues)"
                    [disabled]="_isSearchDisable"><img class="left-img"><span
                        class="align-middle">Search</span></button>

            </div>
        </div>

        <div class="row">

            <div class="tableContent" [hidden]="_ifNoRecords">
                <p-table #dt [value]="ConfusionMatrixListArr" styleClass="p-custom-datatable" [rows]="10"
                    [paginator]="true" reflow="true" paginatorTemplate="{RowsPerPageDropdown}"
                    paginatorPosition="bottom" paginatorPosition="bottom" [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[10,20,30,40,50]"
                    currentPageReportTemplate="Displaying {last} of {totalRecords}" [rowHover]="true"
                    [scrollable]="true" scrollHeight="flex" [(first)]="first" editMode="row" [editable]="true"
                    [resizableColumns]="true">

                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col *ngFor="let col of ConfusionMatrixHeadersArr" [style.width]='col.width'>
                        </colgroup>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn *ngFor="let col of ConfusionMatrixHeadersArr"
                                [pSortableColumn]="col.field" [pSortableColumnDisabled]="col.field === 'BondModifiedOn'"
                                style="text-align: left;" [ngClass]="col.field" data-toggle="tooltip"
                                data-placement="top" title="{{col.header}}">
                                <div class="tableHeader" *ngIf="col.isSort === 'true'" (click)="sortcolumn(col.field)"
                                    [ngClass]="{'border-right-dadadd': col.field != 'PredictedIntentConfidence'}">
                                    <div class="d-inline-block text-overflow-header"
                                        [ngClass]="{'pl-15':col.field === 'ClickableFlowsID', 'xl-pl-20px':col.field === 'ClickableFlowsID'}">
                                        {{col.header}}</div>
                                    <div class="tableSortIcon">
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </div>
                                </div>
                                <div *ngIf="col.isSort === 'false'">
                                    {{col.header}}
                                </div>
                            </th>

                        </tr>

                        <tr *ngIf="isLoader">
                            <td *ngFor="let col of ConfusionMatrixHeadersArr">
                                <div class="item customSkeletonLoader">
                                    <ngx-skeleton-loader count="20" appearance=""></ngx-skeleton-loader>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-car let-ri="rowIndex">
                        <tr class="position-relative">
                            <td pEditableColumn *ngFor="let col of ConfusionMatrixHeadersArr"
                                [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">
                                <div
                                    [ngClass]="{'xl-pl-14px':col.field !== 'ClickableFlowsID', 'pl-10':col.field !== 'ClickableFlowsID'}">
                                    <div *ngIf="col.field != 'PredictedIntentConfidence'"
                                        [ngClass]="{'pl-25':col.field === 'ClickableFlowsID', 'xl-pl-35px':col.field === 'ClickableFlowsID'}">
                                        {{car[col.field]}}
                                    </div>
                                </div>

                                <div *ngIf="col.field === 'PredictedIntentConfidence'"
                                    [ngClass]="{'xl-pl-14px':col.field !== 'ClickableFlowsID', 'pl-10':col.field !== 'ClickableFlowsID'}">
                                    {{car[col.field] | percent:'2.0'}}
                                </div>

                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <div class="noRecordFound text-center border-top" *ngIf="_ifNoRecords">
                <img src="../../assets/images/no_records.png">
                <p class="title">No Result Found</p>
                <p class="mb-0 subtitle">Sorry, we couldn’t find any results for this search.</p>
            </div>

        </div>
    </div>
</div>