<div class="container-fluid screen-container rounded-0 pl-0 pr-0">

    <div class="settingDiv" [style]="'--featured-bgColor:' + _iconColor ">
        <a class="themeSettingBtn" (click)="openThemeSettingBlock()">
            <img src="../../assets/images/test_bot/setting_icon_front.png" class="frontSettingImg">
            <img src="../../assets/images/test_bot/setting_icon_back.png" class="backSettingImg">
        </a>
    </div>

    <div class="coreWebsite">
        <iframe [attr.src]="_BotBackgroundWebSite" class="border-0 frame responsive-iframe"
            title="Your financal Partener"></iframe>
    </div>

    <div *ngIf="ShowChat==true">
        <!-- <lib-TestBot-lib (inputdata)="fetchShowChatValue($event)"
            [inputVariable]="{isShowChat: ShowChat, PageName: _pageName, RoleName: _RoleName, ClientTenantId : _ClientTenantId}"></lib-TestBot-lib> -->

        <bond-bot (recivechatValue)="fetchShowChatValue($event)" isShowChat=true PageName="TestBot" RoleName="Client"
            ClientTenantId="7B599D13-A945-4F47-9964-5880C6E6AB87">
        </bond-bot>
    </div>

    <div class="flip-container" [style.display]="ShowChat ? 'none' : 'block'">
        <a (click)="ChatModels()">
            <div class="card border-0 bg-transparent float-right">
                <div class="front" [style]="'--featured-bgColor:' + _iconColor ">
                    <img src="../../assets/images/test_bot/Bond_Bot_Icon_Front.png" class="frontImg">
                    <img src="../../assets/images/test_bot/Bond_Bot_Icon_Back.png" class="backImg">
                </div>
                <div class="back">
                </div>
            </div>
        </a>

    </div>
</div>

<div class="themeSettingBlock" *ngIf="_isthemeSettingBlock">
    <div class="blockBanner">
        <div class="row">
            <div class="col-md-12">
                <div class="float-right closebtn">
                    <a (click)="closeChat()">
                        <img src="../../../assets/images/themeSetting_Close_icon.png" class="cross-img">
                    </a>
                </div>
            </div>
            <div class="col-md-12">
                <div class="title">
                    Theme Settings
                </div>
            </div>
        </div>
    </div>

    <div class="blockDetails">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <label for="exampleInputEmail1">Start Color <span class="testbotAsterisk">*</span></label>
                    <input type="text" class="form-control mb-2" id="exampleInputEmail1" aria-describedby="emailHelp"
                        placeholder="Enter start color" [(ngModel)]="_BotGradStartColor"
                        (keyup)="checkWhiteSpace(_BotGradStartColor); ifFieldIsUpdated($event)">
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <label for="exampleInputEmail1">End color <span class="testbotAsterisk">*</span></label>
                    <input type="text" class="form-control mb-2" id="exampleInputEmail1" aria-describedby="emailHelp"
                        placeholder="Enter end color" [(ngModel)]="_BotGradEndColor"
                        (keyup)="checkWhiteSpace(_BotGradEndColor); ifFieldIsUpdated($event)">
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <label for="exampleInputEmail1">Site Url <span class="testbotAsterisk">*</span></label>
                    <input type="url" class="form-control mb-2" id="exampleInputEmail1" aria-describedby="emailHelp"
                        placeholder="Enter site url" [(ngModel)]="_BotsiteUrl"
                        (keyup)="checkWhiteSpace(_BotsiteUrl); ifFieldIsUpdated($event)">
                </div>
            </div>
        </div>
        <div class="themeBlockFooter">
            <div class="col-md-12">
                <button type="button" class="btn btn-primary custom-light-btn" (click)="UpdateBotGradientColor('')"
                    [disabled]="_disableMainUpdateButton || !_BotGradStartColor || !_BotGradEndColor || !_BotsiteUrl || _isWhiteSpace">Update</button>
                <button type="button" class="btn btn-secondary"
                    (click)="UpdateBotGradientColor('default')">DefaultTheme</button>
            </div>
        </div>
    </div>
</div>