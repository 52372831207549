import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-production-log',
  templateUrl: './production-log.component.html',
  styleUrls: ['./production-log.component.css']
})
export class ProductionLogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
