import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';

import { FaqService } from '../../services/faq/faq.service'

import { faqEntities } from '../../faq/fqaentities'

import { Table } from 'primeng/table';

@Component({
  selector: 'app-assign-model',
  templateUrl: './assign-model.component.html',
  styleUrls: ['./assign-model.component.css']
})
export class AssignModelComponent implements OnInit {

  @ViewChild('dt') dt: ElementRef
  @ViewChild("dt", { static: false }) public ptable: Table;

  deletedFAQArr: any = [];
  tableHeadersArr: any = [];

  first: number = 0;
  rows = 10;
  islastPageFlag: boolean;

  sortColumnName: string;
  sortorder: boolean;
  descOrder: Boolean;
  AfterUpdateColumnName: string;

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string

  isLoader: boolean = true;

  _searchData: string

  _parameters: any
  _TableListArr: any
  _filteredSectionName: any
  _ModelList: any

  constructor(public faqconfigservice: FaqService) {
  }

  ngOnInit(): void {
    this.TableListHeaders();
    this.fetFaqList()
  }

  _selectedOption : any
  _model1 : any
  _model2 : any
  
  TableListHeaders() {
    this.tableHeadersArr = [
      { field: 'DispId', header: 'Sr No.', width: "5%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'SectionName', header: 'Section Name', width: "8%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'Section Name' },
      { field: 'ModelName', header: 'Current Model', width: "15%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'FAQ' },
      { field: 'Model', header: 'Select Model', width: "15%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'FAQ' },
     
    ]

    this._ModelList = [
      { name: 'MODEL1', code: 'week' },
      { name: 'MODEL2', code: 'month' }
    ]
  }

  fetFaqList() {
    this.isLoader = true;
    this._parameters = new faqEntities()

    this.faqconfigservice.getDistinctSections().subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {

        this._TableListArr = res.Data.DistinctSectionList;
        this.isLoader = false;
        this.sortColumnName = '';
        this.first = 0;
        this.sortorder = false;

        this._model1 = res.Data.Model1Count; 
        this._model2 = res.Data.Model2Count;

      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
      }
    })
  }

  
  sortcolumn(ColumnName: any) {
    let reverse = this.sortColumnName === ColumnName ? !this.descOrder : undefined;

    if (reverse === undefined)
      reverse = false;

    this.sortColumnName = ColumnName;
    this.descOrder = reverse;

    if (this.descOrder == true)
      this.sortorder = false;
    else
      this.sortorder = true;
  }

  UpdateModelForSections(vSelectedOption: any, vEvent: any, vSelecedRow: any){

    this._parameters = new faqEntities();
    this._parameters.IntentMaintenance.SectionName = vSelecedRow.SectionName;
    this._parameters.IntentMaintenance.ModelName = vEvent.name;

    this.isLoader = true;

    this.faqconfigservice.UpdateModelForSections(this._parameters.IntentMaintenance).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        this.isLoader = false;
        this.sortColumnName = '';
        this.first = 0;
        this.sortorder = false;

        this.fetFaqList();

      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
      }
    })
  }
}
