<div id="sidebar-container" class="sidebar-expanded" [ngClass]="{'sidebar-collapsed': toggle}">

    <ul class="list-group">

        <a data-toggle="sidebar-colapse" (click)="clickEvent(); setSettingValue()" class="p-0 logoLink">
            <div id="collapse-icon" class="menu-collapsed">
                <span class="logoFirstText">bond.</span>
                <span class="logoLastText">ai</span>
            </div>
            <div id="small-menu-icon" class="small-menu-icon">
                <span class="logoFirstText">b</span>
            </div>
        </a>

        <a #ud routerLink="AccountAnalytics" (click)="collapseMenu(); setSettingValue()" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}" data-toggle="collapse" aria-expanded="false"
            class="list-group-item list-group-item-action flex-column align-items-start sidebar-menu"
            [hidden]="_RoleName === 'Enterprise' || _RoleName === 'bankUser'">
            <div class="d-flex w-100 justify-content-start align-items-center">
                <span class="menuImgContainer">
                    <img src="../../../assets/images/hd_menu_icons/Usage_dashboard.png"
                        class="sidebar-menu-icon main-img">
                    <img src="../../../assets/images/hd_menu_icons/usage_dash_selected.png"
                        class="sidebar-menu-icon hover_persona_img">
                </span>
                <span class="menu-collapsed">Home</span>
            </div>
        </a>

        <a #ud routerLink="EnterpriseAccountAnalytics" (click)="collapseMenu(); setSettingValue()"
            routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" data-toggle="collapse"
            aria-expanded="false"
            class="list-group-item list-group-item-action flex-column align-items-start sidebar-menu"
            [hidden]="_RoleName != 'Enterprise'">
            <div class="d-flex w-100 justify-content-start align-items-center">
                <span class="menuImgContainer">
                    <img src="../../../assets/images/hd_menu_icons/Usage_dashboard.png"
                        class="sidebar-menu-icon main-img">
                    <img src="../../../assets/images/hd_menu_icons/usage_dash_selected.png"
                        class="sidebar-menu-icon hover_persona_img">
                </span>
                <span class="menu-collapsed">Home</span>
            </div>
        </a>

        <a #ud routerLink="BankUserAccountAnalytics" (click)="collapseMenu(); setSettingValue()"
            routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" data-toggle="collapse"
            aria-expanded="false"
            class="list-group-item list-group-item-action flex-column align-items-start sidebar-menu"
            [hidden]="_RoleName !== 'bankUser'">
            <div class="d-flex w-100 justify-content-start align-items-center">
                <span class="menuImgContainer">
                    <img src="../../../assets/images/hd_menu_icons/Usage_dashboard.png"
                        class="sidebar-menu-icon main-img">
                    <img src="../../../assets/images/hd_menu_icons/usage_dash_selected.png"
                        class="sidebar-menu-icon hover_persona_img">
                </span>
                <span class="menu-collapsed">Home</span>
            </div>
        </a>

        <a #ob id="onboarding" data-target="#submenu2" data-toggle="collapse"
            (click)="removeActiveClass('onboarding'); setSettingValue()" [attr.aria-expanded]="onboardingPopupExpand"
            aria-controls="submenu2"
            class="list-group-item list-group-item-action flex-column align-items-start sidebar-menu"
            [ngClass]="{'d-none':hideAccountMenu}">
            <div class="d-flex w-100 justify-content-start align-items-center">
                <span class="menuImgContainer">
                    <img src="../../../assets/images/hd_menu_icons/Onboarding.png" class="sidebar-menu-icon main-img">
                    <img src="../../../assets/images/hd_menu_icons/onboard_selected.png"
                        class="sidebar-menu-icon hover_persona_img">
                </span>
                <span class="menu-collapsed">Accounts</span>
                <span class="submenu-icon ml-auto"></span>
            </div>
        </a>

        <div #obss id="submenu2" class="collapse sidebarSubmenu" aria-labelledby="headingThree"
            data-parent="#sidebar-container">
            <div *ngFor="let i of _navigationMenuArr | sort:'asc':'MenuOrder'">
                <a *ngIf="i.ModuleDisplayName === 'Accounts'" routerLink="{{i.Route}}"
                    routerLinkActive="active-subMenulink" [routerLinkActiveOptions]="{exact: true}"
                    class="list-group-item list-group-item-action">
                    <span class="menu-collapsed">{{i.FeatureName}}</span>
                </a>
            </div>
        </div>

        <a #pc id="personaCluster" data-target="#persona" data-toggle="collapse"
            (click)="removeActiveClass('personaCluster'); setSettingValue()" [attr.aria-expanded]="personaPopupExpand"
            aria-controls="persona"
            class="list-group-item list-group-item-action flex-column align-items-start sidebar-menu"
            [ngClass]="{'d-none':hidePersonaMenu}">
            <div class="d-flex w-100 justify-content-start align-items-center">
                <span class="menuImgContainer">
                    <img src="../../../assets/images/hd_menu_icons/Persona_cluster.png"
                        class="sidebar-menu-icon main-img">
                    <img src="../../../assets/images/hd_menu_icons/Persona_selected.png"
                        class="sidebar-menu-icon hover_persona_img">
                </span>
                <span class="menu-collapsed">Personas</span>
                <span class="submenu-icon ml-auto"></span>
            </div>
        </a>

        <div #pcss id="persona" class="collapse sidebarSubmenu" aria-labelledby="headingThree"
            data-parent="#sidebar-container">
            <div *ngFor="let i of _navigationMenuArr | sort:'asc':'MenuOrder'">
                <a *ngIf="i.ModuleDisplayName === 'Personas'" routerLink="{{i.Route}}"
                    routerLinkActive="active-subMenulink" [routerLinkActiveOptions]="{exact: true}"
                    class="list-group-item list-group-item-action">
                    <span class="menu-collapsed">{{i.FeatureName}}</span>
                </a>
            </div>
        </div>

        <a #cb id="chatbot" data-target="#submenu3" data-toggle="collapse"
            (click)="removeActiveClass('chatbot'); setSettingValue()" [attr.aria-expanded]="chatbotPopupExpand"
            class="list-group-item list-group-item-action flex-column align-items-start sidebar-menu"
            [ngClass]="{'d-none':hideChatbotMenu}" [hidden]="_RoleName === 'Enterprise'|| _RoleName === 'bankUser'">
            <div class="d-flex w-100 justify-content-start align-items-center">
                <span class="menuImgContainer">
                    <img src="../../../assets/images/hd_menu_icons/chat_bot.png" class="sidebar-menu-icon main-img">
                    <img src="../../../assets/images/hd_menu_icons/chatbot_slected.png"
                        class="sidebar-menu-icon hover_persona_img">
                </span>
                <span class="menu-collapsed">Chat Bot</span>
                <span class="submenu-icon ml-auto"></span>
            </div>
        </a>

        <div #cbss id='submenu3' class="collapse sidebarSubmenu" aria-labelledby="headingThree"
            data-parent="#sidebar-container"
            [style]="'--DivHeightForNormalScreen:' + vCheckHeightForNormalScreen + 'px' + ';' + '--DivHeightForBigScreen:' + vCheckHeightForLargeScreen + 'px' + ';' + '--DivHeightForExtraLargeScreen:' + vCheckHeightForExtraLargeScreen + 'px' "
            [ngClass]="{'max-height-0': chatbotPopupExpand == false, 'sidebar-submenu-height': chatbotPopupExpand == true}">
            <div *ngFor="let i of _navigationMenuArr | sort:'asc':'MenuOrder'">
                <a *ngIf="i.ModuleDisplayName === 'Chat BOT'" routerLink="{{i.Route}}"
                    routerLinkActive="active-subMenulink" [routerLinkActiveOptions]="{exact: true}"
                    class="list-group-item list-group-item-action"
                    [ngClass]="{'d-none':i.FeatureName === 'Failed FAQs' || i.FeatureName === 'FAQ List' || i.FeatureName === 'Clickable Flow'}">
                    <span class="menu-collapsed">{{i.FeatureName}}</span>
                </a>
                <a *ngIf="i.ModuleDisplayName === 'Chat BOT' && i.FeatureName === 'FAQ List'" routerLink="{{i.Route}}"
                    routerLinkActive="active-subMenulink" [routerLinkActiveOptions]="{exact: true}"
                    class="list-group-item list-group-item-action" (click)="fetchFaqTable()">
                    <span class="menu-collapsed">{{i.FeatureName}}</span>
                </a>
                <a *ngIf="i.ModuleDisplayName === 'Chat BOT' && i.FeatureName === 'Clickable Flow'"
                    routerLink="{{i.Route}}" routerLinkActive="active-subMenulink"
                    [routerLinkActiveOptions]="{exact: true}" class="list-group-item list-group-item-action"
                    (click)="fetchClickableFlowTable()">
                    <span class="menu-collapsed">{{i.FeatureName}}</span>
                </a>
            </div>

        </div>

        <a #cb id="chatbot" data-target="#submenu4" data-toggle="collapse"
            (click)="removeActiveClass('BOTUsage'); setSettingValue()" [attr.aria-expanded]="botUsagePopupExpand"
            class="list-group-item list-group-item-action flex-column align-items-start sidebar-menu"
            [ngClass]="{'d-none':hideChatbotMenu}" [hidden]="_RoleName === 'Enterprise'|| _RoleName === 'bankUser'">
            <div class="d-flex w-100 justify-content-start align-items-center">
                <span class="menuImgContainer">
                    <img src="../../../assets/images/hd_menu_icons/Bot_Usage.png" class="sidebar-menu-icon main-img">
                    <img src="../../../assets/images/hd_menu_icons/Bot_Usage_selected.png"
                        class="sidebar-menu-icon hover_persona_img">
                </span>
                <span class="menu-collapsed">BOT Usage</span>
                <span class="submenu-icon ml-auto"></span>
            </div>
        </a>

        <div #cbss id='submenu4' class="collapse sidebarSubmenu" aria-labelledby="headingThree"
            data-parent="#sidebar-container"
            [style]="'--DivHeightForNormalScreen:' + vCheckHeightForNormalScreen + 'px' + ';' + '--DivHeightForBigScreen:' + vCheckHeightForLargeScreen + 'px' + ';' + '--DivHeightForExtraLargeScreen:' + vCheckHeightForExtraLargeScreen + 'px' "
            [ngClass]="{'max-height-0': botUsagePopupExpand == false, 'sidebar-submenu-height': botUsagePopupExpand == true}">
            <div *ngFor="let i of _navigationMenuArr | sort:'asc':'MenuOrder'">
                <a *ngIf="i.ModuleDisplayName === 'BOT Usage'" routerLink="{{i.Route}}"
                    routerLinkActive="active-subMenulink" [routerLinkActiveOptions]="{exact: true}"
                    class="list-group-item list-group-item-action"
                    [ngClass]="{'d-none':i.FeatureName === 'Failed FAQs' || i.FeatureName === 'FAQ List' || i.FeatureName === 'Clickable Flow'}">
                    <span class="menu-collapsed">{{i.FeatureName}}</span>
                </a>
                <a *ngIf="i.ModuleDisplayName === 'BOT Usage' && i.FeatureName === 'FAQ List'" routerLink="{{i.Route}}"
                    routerLinkActive="active-subMenulink" [routerLinkActiveOptions]="{exact: true}"
                    class="list-group-item list-group-item-action" (click)="fetchFaqTable()">
                    <span class="menu-collapsed">{{i.FeatureName}}</span>
                </a>
                <a *ngIf="i.ModuleDisplayName === 'BOT Usage' && i.FeatureName === 'Clickable Flow'"
                    routerLink="{{i.Route}}" routerLinkActive="active-subMenulink"
                    [routerLinkActiveOptions]="{exact: true}" class="list-group-item list-group-item-action"
                    (click)="fetchClickableFlowTable()">
                    <span class="menu-collapsed">{{i.FeatureName}}</span>
                </a>
            </div>

        </div>

        <a #cb id="chatbot" data-target="#submenu5" data-toggle="collapse"
            (click)="removeActiveClass('ModelReports'); setSettingValue()"
            [attr.aria-expanded]="modelReportsPopupExpand"
            class="list-group-item list-group-item-action flex-column align-items-start sidebar-menu"
            [ngClass]="{'d-none':hideChatbotMenu}" [hidden]="_RoleName === 'Enterprise'|| _RoleName === 'bankUser'">
            <div class="d-flex w-100 justify-content-start align-items-center">
                <span class="menuImgContainer">
                    <img src="../../../assets/images/hd_menu_icons/Model_Report.png" class="sidebar-menu-icon main-img">
                    <img src="../../../assets/images/hd_menu_icons/Model_Report_selected.png"
                        class="sidebar-menu-icon hover_persona_img">
                </span>
                <span class="menu-collapsed">Model Reports</span>
                <span class="submenu-icon ml-auto"></span>
            </div>
        </a>

        <div #cbss id='submenu5' class="collapse sidebarSubmenu" aria-labelledby="headingThree"
            data-parent="#sidebar-container"
            [style]="'--DivHeightForNormalScreen:' + vCheckHeightForNormalScreen + 'px' + ';' + '--DivHeightForBigScreen:' + vCheckHeightForLargeScreen + 'px' + ';' + '--DivHeightForExtraLargeScreen:' + vCheckHeightForExtraLargeScreen + 'px' "
            [ngClass]="{'max-height-0': modelReportsPopupExpand == false, 'sidebar-submenu-height': modelReportsPopupExpand == true}">
            <div *ngFor="let i of _navigationMenuArr | sort:'asc':'MenuOrder'">
                <a *ngIf="i.ModuleDisplayName === 'Model Reports'" routerLink="{{i.Route}}"
                    routerLinkActive="active-subMenulink" [routerLinkActiveOptions]="{exact: true}"
                    class="list-group-item list-group-item-action"
                    [ngClass]="{'d-none':i.FeatureName === 'Failed FAQs' || i.FeatureName === 'FAQ List' || i.FeatureName === 'Clickable Flow'}">
                    <span class="menu-collapsed">{{i.FeatureName}}</span>
                </a>
                <a *ngIf="i.ModuleDisplayName === 'Model Reports' && i.FeatureName === 'FAQ List'"
                    routerLink="{{i.Route}}" routerLinkActive="active-subMenulink"
                    [routerLinkActiveOptions]="{exact: true}" class="list-group-item list-group-item-action"
                    (click)="fetchFaqTable()">
                    <span class="menu-collapsed">{{i.FeatureName}}</span>
                </a>
                <a *ngIf="i.ModuleDisplayName === 'Model Reports' && i.FeatureName === 'Clickable Flow'"
                    routerLink="{{i.Route}}" routerLinkActive="active-subMenulink"
                    [routerLinkActiveOptions]="{exact: true}" class="list-group-item list-group-item-action"
                    (click)="fetchClickableFlowTable()">
                    <span class="menu-collapsed">{{i.FeatureName}}</span>
                </a>
            </div>

        </div>
        <div class="fixed-bottom bottom-30">

            <a id="notificationLink" (click)="collapseMenu(); showNotification(); setSettingValue()"
                [hidden]="_ViewClientRoleAccess"
                class="notification list-group-item list-group-item-action sidebar-menu">
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <span class="menuImgContainer">
                        <img src="../../../assets/images/hd_menu_icons/Notifications.png"
                            class="sidebar-menu-icon main-img">
                        <img src="../../../assets/images/hd_menu_icons/notification-selected.png"
                            class="sidebar-menu-icon hover_persona_img">
                    </span>
                    <span class="badge" *ngIf="NotifBubble">
                        <span class="badgeNumber">{{totalNotif}}</span>
                    </span>
                    <span class="menu-collapsed">Notifications</span>
                </div>
            </a>

            <a routerLink="settings" (click)="collapseMenu(); setSettingValue()" routerLinkActive="active-link"
                [routerLinkActiveOptions]="{exact: true}" class="list-group-item list-group-item-action sidebar-menu">
                <div class="d-flex w-100 justify-content-start align-items-center">
                    <span class="menuImgContainer">
                        <img src="../../../assets/images/hd_menu_icons/settings.png" class="sidebar-menu-icon main-img">
                        <img src="../../../assets/images/hd_menu_icons/setting_selected.png"
                            class="sidebar-menu-icon hover_persona_img">
                    </span>
                    <span class="menu-collapsed">Settings</span>
                </div>
            </a>

            <a id="dd" class="list-group-item list-group-item-action sidebar-menu" routerLinkActive="active-link"
                [routerLinkActiveOptions]="{exact: true}" (click)="OnCLickLogout()" data-toggle="modal"
                data-target="#myModal">

                <div class="d-flex w-100 justify-content-start align-items-center">
                    <span class="menuImgContainer">
                        <img src="../../../assets/images/hd_menu_icons/log-out.png" class="sidebar-menu-icon main-img">
                        <img src="../../../assets/images/hd_menu_icons/logout_sel.png"
                            class="sidebar-menu-icon hover_persona_img">
                    </span>
                    <span class="menu-collapsed">Log out</span>
                </div>
            </a>
        </div>

    </ul>
    <div class="backtrop"></div>

    <ng-template #modalWarningAndInfo let-modal="dismiss">
        <app-warning-and-info-modal
            [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
        </app-warning-and-info-modal>
    </ng-template>

    <ng-template #ConfirmationModal let-modal="dismiss">
        <app-confirmation-modal
            [confirmModalInputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, primaryBtn: popupPrimaryBtn,  dismissFunction: modal, primaryBtnFunction:LogOut }">
        </app-confirmation-modal>
    </ng-template>
</div>

<div class="animatedLoader" *ngIf="_isLoader">
    <img src="../../../assets/images/animation_loader.gif" class="loaderImg">
</div>