import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { dateFormat } from 'highcharts';

@Component({
    template: ''
})

export class ConvFlowEntity {
    UseCaseName: number = 0;
    IntentName: any;
    PrimaryQuestion = "";
    Slots: Array<Slot>= [];
}

export class Slot {
    SlotName: any = "";
    SlotType: any = "";
    SlotOrder: number = 1
    Slotmessage: Array<Slotmessage> = [];
    SlotValues = [];
    SlotDataType: any = "";
    DataValidation: any = "";
    DataFormat: any = "";
    InputFormat: any = "NA";
    NoOfAttemptsAllowed: any = "1";
    NoOfAttemptsOver: any = 0;
    AwaitMsg: any = "";
    Returnslotbasedoninput: Array<ReturnSlotBasedOnInput>= [];
    SavedUserInput: any = "";
    SaveIntoDB: any = "";
    UserInputBox: boolean = false;
    Enablefield: any = "";
    EndOfConv: boolean = false;
}

export class Slotmessage {
    AppType: any = "";
    PreMsg: any = "";
    PreMsgType: any = "";
    MainMsg: any = "";
    DisplayMessage: any = "";
    ResponseType: any = "";
    PostMsg: any = "";
    PostMsgType: any = "";
}

export class ReturnSlotBasedOnInput {
    UserInput: any = "";
    ReturnType: any = "Slots";
    ReturnName: any = "";
    Returnparams = new Returnparams();
    CustomCSMethod: any = "";
    Override: any = "";
    APIEndPointDetails: [];
    Slotmessage: Array<Slotmessage>= [];
}

export class ngModelReturnSlotBasedOnInput {
    id : number = 0
    SlotID :  number = 0
    UserInput: any = "";
    ReturnType: any = "Slots";
    ReturnName: any = "";
    Returnparams = new Returnparams();
    CustomCSMethod: any = "";
    Override: any = "";
    APIEndPointDetails: [];
    Slotmessage = {
        webAppType : "Web",
        webPreMsg : "",
        webPreMsgType  : "",
        webMainMsg : "",
        webDisplayMessage : "",
        webResponseType : "",
        webPostMsg : "",
        webPostMsgType : "",


        appAppType : "App",
        appPreMsg : "",
        appPreMsgType : "",
        appMainMsg : "",
        appDisplayMessage : "",
        appResponseType: "",
        appPostMsg : "",
        appPostMsgType : ""
    };
}

export class ngModelSlotmessage {
    webAppType : "Web";
    webPreMsg : "";
    webPreMsgType  : "";
    webMainMsg : "";
    webDisplayMessage : "";
    webResponseType : "";
    webPostMsg : "";
    webPostMsgType : "";


    appAppType : "App";
    appPreMsg : "";
    appPreMsgType : "";
    appMainMsg : "";
    appDisplayMessage : "";
    appResponseType: "";
    appPostMsg : "";
    appPostMsgType : ""
};

export class Returnparams {
}