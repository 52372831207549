import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';

import { HttpCommonReq } from '../../services/HttpCommonReq'



@Injectable({
  providedIn: 'root'
})
export class ClickableFlowService {

  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {

      getCFList: 'api/ClickableFlows/GetAllClickableFlows',
      SearchFaqList: 'api/ClickableFlows/GetClickableFlowsByID',
      AddCFFlow: 'api/ClickableFlows/InsertClickableFlows',
      updateCFFlow: 'api/ClickableFlows/UpdateClickableFlows',
      DeleteCFlows: 'api/ClickableFlows/DeleteClickableFlows',
      getCFlowsByID: 'api/ClickableFlows/GetClickableFlowsByID',
      getCFlowsParentIntents: 'api/ClickableFlows/GetCFlowsParentIntents'
    }
  }

  getClickableFlowParentIntentsData() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.getCFlowsParentIntents)
  }

  getAllClickableFlowData() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.getCFList)
  }

  InsertClickableFlows(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.AddCFFlow, data)
  }

  UpdateClickableFlows(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.updateCFFlow, data)
  }

  DeleteClickableFlows(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.DeleteCFlows, data)
  }

  GetClickableFlowsByID(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.getCFlowsByID, data)
  }
}