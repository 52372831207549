import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    template: ''
})
export class newAccountEntities implements OnInit {

    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }
    
    NewAccountModel = {
        "DispID":"",
        "Id":"",
        "ReferanceNo":"",
        "FirstName":"",
        "EmailId":"",
        "MobileNo":"",
        "DLNo":"",
        "LinkAccStatus":"",
        "SigningStatus":"",
        "RvwStatus":"",
        "BondCreatedOn":"",
        "SorColumnName": "",
        "Reverseorder":true,
        "IsSelectedCB" : false,
        "AccountID": ""
    }

    NewAccountModelForExport = {
        "DispID":"",
        "ReferanceNo":"",
        "Name":"",
        "EmailId":"",
        "MobileNo":"",
        "DLNo":"",
        "LinkAccStatus":"",
        "SigningStatus":"",
        "RvwStatus":"",
        "BondCreatedOn":""
    }
}