<div class="container-fluid screen-container xl-bg-transperent ">

    <div class="mainContainerWrapper pageScrollable">

        <div class="header row">
            <div class="col-md-12">
                <div class="float-left">
                    <span class="page-title">AML Rules</span>
                </div>

                <div class="float-right">

                    <button [hidden]="_ViewClientRoleAccess" title="Export" type="button" class="btn btn-secondary"
                        (click)="exportAMLList()">
                        <img src="../../../assets/images/Export_icon.png" class="left-img export_icon">
                        <img src="../../../assets/images/Export-icon-selected.png" class="left-img seleted_export_icon">
                        <span class="align-middle">export</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="tableContent">
                <p-table #dt [value]="amlListArr" styleClass="p-custom-datatable" [rows]="10" [paginator]="true"
                    reflow="true" paginatorTemplate="{RowsPerPageDropdown}" paginatorPosition="bottom"
                    paginatorPosition="bottom" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,40,50]"
                    currentPageReportTemplate="Displaying {last} of {totalRecords}" [rowHover]="true"
                    [scrollable]="true" scrollHeight="flex" [(first)]="first" editMode="row" [editable]="true"
                    [resizableColumns]="true">

                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col *ngFor="let col of amlHeadersArr" [style.width]='col.width'>
                        </colgroup>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn *ngFor="let col of amlHeadersArr" style="text-align: left;"
                                [ngClass]="col.field" data-toggle="tooltip" data-placement="top" title="{{col.header}}">
                                <div class="tableHeader border-right-dadadd" *ngIf="col.isSort === 'true'"
                                    (click)="sortcolumn(col.field)">
                                    <div class="d-inline-block text-overflow-header"
                                        [ngClass]="{'pl-25':col.field === 'AMLCheckListId', 'xl-pl-35px':col.field === 'AMLCheckListId', 'xl-pl-14px':col.field !== 'AMLCheckListId', 'pl-10':col.field !== 'AMLCheckListId'}">
                                        {{col.header}}
                                    </div>
                                    <!-- <div class="tableSortIcon">
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </div> -->

                                </div>
                                <div class="tableHeader" *ngIf="col.isSort === 'false'"
                                    [ngClass]="{'border-right-dadadd': col.field != 'ItsOk', 'pl-25':col.field === 'AMLCheckListId', 'xl-pl-35px':col.field === 'AMLCheckListId', 'xl-pl-14px':col.field !== 'AMLCheckListId', 'pl-10':col.field !== 'AMLCheckListId'}">
                                    <div class="d-inline-block text-overflow-header">
                                        {{col.header}}
                                    </div>
                                </div>

                            </th>

                        </tr>
                        <tr *ngIf="isLoader">
                            <td *ngFor="let col of amlHeadersArr">
                                <div class="item customSkeletonLoader">
                                    <ngx-skeleton-loader count="20" appearance=""></ngx-skeleton-loader>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-car let-ri="rowIndex">
                        <tr>
                            <td pEditableColumn *ngFor="let col of amlHeadersArr"
                                [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">
                                <div class="customInputRadioField"
                                    [ngClass]="{'xl-pl-14px':col.field !== 'AMLCheckListId', 'pl-10':col.field !== 'AMLCheckListId'}">
                                    <div *ngIf="col.field != 'NoGo' && col.field != 'Maybe' && col.field != 'ItsOk'"
                                        [ngClass]="{'pl-25':col.field === 'AMLCheckListId', 'xl-pl-35px':col.field === 'AMLCheckListId'}">
                                        {{car[col.field]}}
                                    </div>

                                    <div *ngIf="col.field === 'NoGo' && car.NoGo == true">
                                        <input [ngClass]="{'pc-10':isDisabled === true}" type="radio"
                                            name="NOGO_{{car.AMLCheckListId}}" ngValue="true"
                                            (click)="UpdateAMLData(car, 'NoGo')" [checked]="car.NoGo" />
                                    </div>

                                    <div *ngIf="col.field === 'NoGo' && car.NoGo == false" title="{{tooltip}}">
                                        <input [ngClass]="{'pc-10':isDisabled === true}" type="radio"
                                            name="NOGO_{{car.AMLCheckListId}}" ngValue="true"
                                            (click)="UpdateAMLData(car, 'NoGo')" [checked]="car.NoGo" />
                                    </div>

                                    <div *ngIf="col.field === 'Maybe' && car.Maybe==true">
                                        <input [ngClass]="{'pc-10':isDisabled === true}" type="radio"
                                            name="NOGO_{{car.AMLCheckListId}}" ng-value="car.Maybe==true"
                                            (click)="UpdateAMLData(car, 'Maybe')" [checked]="car.Maybe" />
                                    </div>

                                    <div *ngIf="col.field === 'Maybe' && car.Maybe==false" title="{{tooltip}}">
                                        <input [ngClass]="{'pc-10':isDisabled === true}" type="radio"
                                            name="NOGO_{{car.AMLCheckListId}}" ng-value="car.Maybe==true"
                                            (click)="UpdateAMLData(car, 'Maybe')" [checked]="car.Maybe" />
                                    </div>

                                    <div *ngIf="col.field === 'ItsOk' && car.ItsOk==true">
                                        <input [ngClass]="{'pc-10':isDisabled === true}" type="radio"
                                            name="NOGO_{{car.AMLCheckListId}}" ng-value="car.ItsOk==true"
                                            (click)="UpdateAMLData(car, 'ItsOk')" [checked]="car.ItsOk" />
                                    </div>

                                    <div *ngIf="col.field === 'ItsOk' && car.ItsOk==false" title="{{tooltip}}">
                                        <input [ngClass]="{'pc-10':isDisabled === true}" type="radio"
                                            name="NOGO_{{car.AMLCheckListId}}" ng-value="car.ItsOk==true"
                                            (click)="UpdateAMLData(car, 'ItsOk')" [checked]="car.ItsOk" />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>