import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { HttpCommonReq } from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {
      getNotificationList: 'api/BondNotification/GetNotifications',
      UpdateNotificationsData: 'api/BondNotification/UpdateNotificationsStatus',
      ClearNotificationsData: 'api/BondNotification/CleareAllNotifications'
    }
  }

  getNotificationData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.getNotificationList, data)
  }

  UpdateNotificationValue(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.UpdateNotificationsData, data)
  }

  ClearNotificationValue(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.ClearNotificationsData, data)
  }
}
