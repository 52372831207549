<div class="container-fluid screen-container xl-bg-transperent "
    *ngIf="_showAddEditScreen==false && _showFlowChartScreen==false">

    <div class="mainContainerWrapper">

        <div class="header row">
            <div class="col-md-12">
                <div class="float-left">
                    <span class="page-title">FAQ List</span>
                </div>

                <div class="float-right">
                    <button [disabled]='this._ifNoRecords' [hidden]="_ViewClientRoleAccess" title="Export" type="button"
                        class="btn btn-secondary" (click)="AfterCLickExport()">
                        <img src="../../../assets/images/Export_icon.png" class="left-img export_icon">
                        <img src="../../../assets/images/Export-icon-selected.png" class="left-img seleted_export_icon">
                        <span class="align-middle">export</span>
                    </button>
                    <button [hidden]="_ViewClientRoleAccess" title="Add FAQ" type="button" class="btn btn-primary"
                        (click)="addNewRow()"><img src="../../../assets/images/plus_icon.png" class="left-img"><span
                            class="align-middle">add
                            FAQ</span></button>

                </div>
            </div>
        </div>

        <div class="row subHeader xl-pl-20px xl-pr-20px">
            <div class="col-md-12">
                <div class="float-left">
                    <div class="search-field">

                        <div class="static-img"><img src="../../../assets/images/search.png"></div>
                        <input type="text" class="form-control custom-input-field" placeholder="Search"
                            [(ngModel)]="searchFAQ" (keypress)="searchData($event, 'Search')">
                        <div class="multiselectSearch">
                            <p-multiSelect [options]="searchOptionArr" [(ngModel)]="selectedSearchOpt"
                                [disabled]='searchFAQ==""' (ngModelChange)="selectSearchOption($event)"
                                optionLabel="name" display='chip' [showHeader]="false"></p-multiSelect>
                        </div>
                    </div>
                </div>

                <div class="float-right" (clickOutside)="onClickedOutside($event)">
                    <div class="mb-2 dropdown-field">

                        <div class="dropdown-input-field" (click)="clickEvent()">
                            <div class="static-img" *ngIf="isHide"><img src="../../../assets/images/Filter.png"></div>
                            <div class="static-img" *ngIf="chageFilterInput == true"><img
                                    src="../../../assets/images/filter_icon.png"></div>
                            <input type="text" class="form-control custom-input-field cursor-pointer"
                                [ngClass]="{'selectedfilterInput':chageFilterInput == true, 'selectedfilterInputOutline':selectedFieldOutline, 'greyInputOutline':greyFieldOutline == true}"
                                placeholder="Filter" readonly>
                            <div class="filterChips">

                                <span *ngIf="chageFilterInput == true">Filter/{{totalSelectedValues}}</span>
                            </div>
                            <a class="dropdownArrow" (mouseover)="changeStyle()" (mouseout)="changeStyle()">
                                <img *ngIf="isHide" src="../../../assets/images/arrow-down.png">
                                <img *ngIf="chageFilterInput == true"
                                    src="../../../assets/images/Icon ionic-ios-arrow-down.png">
                            </a>
                        </div>
                    </div>
                    <div [ngClass]="{'showFilterContainer': toggle, 'hideFilterContainer':toggle == false}">
                        <div class="filterArrContainer">
                            <div class="filterHeaderBlock" style="margin-bottom: 10px;">
                                <span class="filterCotainerTitle">Filter types</span>
                                <span class="float-right filterContainerLink">
                                    <button (click)="clearInputField()" [disabled]='!chageFilterInput'>Clear</button>
                                </span>
                            </div>
                            <div class="mainBlock">
                                <div class="block" *ngFor="let item of filterArr">
                                    <div class="filterArrTitle">{{item.label}}</div>
                                    <div *ngFor="let data of item.items">
                                        <ul>
                                            <li>
                                                <label class="filterOptions mb-0"
                                                    [ngClass]="{'selectedFilterOptions':data.checked==true}">
                                                    <input type="checkbox" name="groupname" value="{{data.name}}"
                                                        [(ngModel)]="data.checked"
                                                        (ngModelChange)="selectFilterOption(data)" />
                                                    <div class="filter-status-color" *ngIf="item.value == 'faqstatus'"
                                                        [ngClass]="{'status-bg-live': data.status === 'live', 'status-bg-live-update':data.status === 'liveContentUpdate', 
                                            'status-bg-content-approved': data.status === 'liveContentApproved', 'status-bg-not-live': data.status === 'notLive'}">
                                                    </div>
                                                    {{data.name}}
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="row faqListTable">

            <div class="tableContent" [hidden]="_ifNoRecords">

                <p-table #dt [value]="faqListArr" styleClass="p-custom-datatable" [rows]="10" [paginator]="true"
                    reflow="true" paginatorTemplate="{RowsPerPageDropdown}" paginatorPosition="bottom"
                    paginatorPosition="bottom" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,40,50]"
                    currentPageReportTemplate="Displaying {last} of {totalRecords}" [rowHover]="true"
                    [scrollable]="true" scrollHeight="flex" [(first)]="first" editMode="row" [editable]="true"
                    (onRowEditInit)="onRowEditInit($event)" [resizableColumns]="true">

                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col *ngFor="let col of faqHeadersArr" [style.width]='col.width'>
                        </colgroup>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn *ngFor="let col of faqHeadersArr" [pSortableColumn]="col.field"
                                [pSortableColumnDisabled]="col.field === 'status'" style="text-align: left;"
                                [ngClass]="col.field" data-toggle="tooltip" data-placement="top" title="{{col.header}}">
                                <div class="tableHeader" *ngIf="col.isSort === 'true'" (click)="sortcolumn(col.field)"
                                    [ngClass]="{'border-right-dadadd': col.field != 'BondModifiedOn' && col.field != 'status'}">
                                    <div class="d-inline-block"
                                        [ngClass]="{'text-overflow-header': col.width > '500%', 'pl-15':col.field === 'DispId', 'xl-pl-20px':col.field === 'DispId'}">
                                        {{col.header}}</div>
                                    <div class="tableSortIcon">
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </div>
                                </div>
                                <div *ngIf="col.isSort === 'false'">
                                    {{col.header}}
                                </div>
                            </th>

                        </tr>
                        <tr *ngIf="isLoader">
                            <td *ngFor="let col of faqHeadersArr">
                                <div class="item customSkeletonLoader">
                                    <ngx-skeleton-loader count="20" appearance=""></ngx-skeleton-loader>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-car let-ri="rowIndex">

                        <tr *ngIf="ri == 0 && newrow" class="newTableRow">
                            <td *ngFor="let col of faqHeadersArr" style="padding: 0!important">

                                <div *ngIf="col.field === 'SectionName'" class="autoSelectedFilter">
                                    <p-autoComplete [(ngModel)]="this.addRecord.IntentMaintenance.SectionName"
                                        [suggestions]="filteredSectionName" (completeMethod)="filterSectionName($event)"
                                        field="name" [minLength]="1" [maxlength]="50"
                                        (keyup)='onchangeControlles($event)' placeholder="Select Section">
                                    </p-autoComplete>
                                </div>

                                <div *ngIf="col.field == 'Questions'">
                                    <input pInputText type="text" class="newInputField form-control" [minLength]="1"
                                        [maxlength]="200" placeholder="Add your question here ..."
                                        (keyup)='onchangeControlles($event)'
                                        [(ngModel)]="this.addRecord.IntentMaintenance.Questions" required>
                                </div>

                                <div *ngIf="col.field == 'DisplayMessage'">
                                    <textarea #comments class="form-control newInputField" rows="0" id="comment"
                                        [minLength]="1" [maxlength]="2000" placeholder="Add your answer here ..."
                                        (keyup)='onchangeControlles($event)'
                                        [(ngModel)]="this.addRecord.IntentMaintenance.DisplayMessage" required
                                        (keydown)="onKeypressEvent($event)"></textarea>
                                </div>

                                <div *ngIf="col.field === 'BondModifiedOn'">
                                    <button title="Save" type="button" class="btn btn-success"
                                        (click)="addFaqRecord(this.addRecord.IntentMaintenance)"
                                        [disabled]="!this.addRecord.IntentMaintenance.SectionName || !this.addRecord.IntentMaintenance.Questions || !this.addRecord.IntentMaintenance.DisplayMessage || _disableSaveBtn">Save</button>
                                    <button title="Cancel" class="cancelRow btn btn-secondary float-right"
                                        (click)="cancelNewRow()">Cancel</button>
                                </div>
                                <!-- <div *ngIf="col.field === 'status'">
                                    <button title="Cancel" class="cancelRow btn btn-secondary"
                                        (click)="cancelNewRow()">Cancel</button>
                                </div> -->
                            </td>
                        </tr>

                        <tr *ngIf="!_hideEmptyRow">
                            <td pEditableColumn *ngFor="let col of faqHeadersArr"
                                [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">
                                <div [hidden]="_ViewClientRoleAccess"
                                    [ngClass]="{'modDtBlock': col.field === 'BondModifiedOn', 'text-overflow-field': col.field === 'DisplayMessage' || col.field === 'Questions'}">

                                    <div [hidden]="_ViewClientRoleAccess"
                                        *ngIf="col.field != 'BondModifiedOn' && col.field != 'DisplayMessage'"
                                        [ngClass]="{'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId','pl-25':col.field === 'DispId', 'xl-pl-35px':col.field === 'DispId'}">

                                        {{car[col.field]}}
                                    </div>



                                    <div *ngIf="col.field === 'DisplayMessage'"
                                        [ngClass]="{'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId'}"
                                        [innerHtml]="car[col.field]">
                                    </div>

                                    <div [hidden]="_ViewClientRoleAccess" *ngIf="col.field === 'BondModifiedOn'"
                                        [ngClass]="{'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId'}">
                                        {{car[col.field] | date : 'MM/dd/yyyy HH:mm:ss'}}
                                    </div>

                                    <div [hidden]="_ViewClientRoleAccess" class="filter-status-color"
                                        *ngIf="col.field === 'status'"
                                        [ngClass]="{'status-bg-live': car.IsLiveRawIntent == true, 'status-bg-live-update':car.IsModifiedRawIntent == true, 
                                    'status-bg-content-approved': car.IsContentApprovedRawIntent == true, 'status-bg-not-live': car.IsNewRawIntent == true, 'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId'}">
                                    </div>
                                </div>

                                <div [hidden]="!_ViewClientRoleAccess"
                                    [ngClass]="{'text-overflow-field': col.field === 'DisplayMessage' || col.field === 'Questions'}">

                                    <div [hidden]="!_ViewClientRoleAccess" *ngIf="col.field != 'BondModifiedOn'"
                                        [ngClass]="{'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId','pl-25':col.field === 'DispId', 'xl-pl-35px':col.field === 'DispId'}">

                                        {{car[col.field]}}
                                    </div>

                                    <div [hidden]="!_ViewClientRoleAccess" *ngIf="col.field === 'BondModifiedOn'"
                                        [ngClass]="{'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId'}">
                                        {{car[col.field] | date : 'dd/MM/yyyy HH:mm:ss'}}
                                    </div>

                                    <div [hidden]="!_ViewClientRoleAccess" class="filter-status-color"
                                        *ngIf="col.field === 'status'"
                                        [ngClass]="{'status-bg-live': car.IsLiveRawIntent == true, 'status-bg-live-update':car.IsModifiedRawIntent == true, 
                                'status-bg-content-approved': car.IsContentApprovedRawIntent == true, 'status-bg-not-live': car.IsNewRawIntent == true, 'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId'}">
                                    </div>
                                </div>

                                <div [hidden]="_ViewClientRoleAccess" class="actions w-100"
                                    [ngClass]="{'showactionBlock': col.field === 'BondModifiedOn', 'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId'}">
                                    <div class="actionButtons">
                                        <a class="mr-2 cursor-pointer" (click)="OnAddEditBtnClick('Edit', car.IntentId)"
                                            class="faqModifyActionBtn"
                                            [ngClass]="{'disabled-link': car.ModelName === _modelName1}" title="Modify">
                                            <img class="block_img" src="../../../assets/images/Modify.png">
                                            <img class="hover_modify_img"
                                                src="../../../assets/images/Modify_on_hover.png">
                                        </a>
                                    </div>
                                    <div class="actionButtons">

                                        <a class="mr-2" (click)="OpenDeletePopup(car)" class="faqDeleteActionBtn"
                                            [ngClass]="{'disabled-link': car.ModelName === _modelName1}" title="Delete">
                                            <img class="block_img" src="../../../assets/images/delete.png">
                                            <img class="hover_delete_img"
                                                src="../../../assets/images/delete_on_hover.png">
                                        </a>
                                    </div>
                                    <!-- 
                                    <div class="actionButtons"
                                        [hidden]="car.IsEndOfConversation == true || car.IsContentApprovedRawIntent == true || car.IsNewRawIntent == true">
                                        <a class="mr-2" (click)="OpenConversationDesignFlow(car)"
                                            class="faqConversationFlowBtn" title="Conversational flow">
                                            <img class="block_img" src="../../../assets/images/conversationFlow.png">
                                            <img class="hover_flow_img"
                                                src="../../../assets/images/conversationFlow_hover.png">
                                        </a>
                                    </div> -->

                                    <div class="actionButtons"
                                        [hidden]="car.ResponseType.toUpperCase() != 'LIST' || car.IsNewRawIntent == true || car.IsEndOfConversation == false">

                                        <a class="mr-2" (click)="OpenListTypeRowDetailsPopup(car)"
                                            [ngClass]="{'disabled-link': car.ModelName === _modelName1}"
                                            class="faqConversationFlowBtn" title="List">
                                            <img class="block_img" src="../../../assets/images/list.png">
                                            <img class="hover_flow_img" src="../../../assets/images/list_on_hover.png">
                                        </a>


                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate='footer' class="position-relative">

                        <div class="media buildStatusLinkBlock" *ngIf="_BAlink && _isDisplayBuild==='TRUE'">
                            <div class="media-body">
                                <p class="title"> {{_CurrentModel}} building in progress...</p>
                                <p class="subtitle">Click on the link to know more</p>
                            </div>
                            <a class="btn btn-secondary BuildActivityLink"
                                routerLink="/portal/ChatBOT/buildActivity">Know
                                more</a>
                        </div>

                        <div class="btn-at-bottom">

                            <button title="Build" type="button" class="btn btn-primary" data-toggle="modal"
                                *ngIf="!_IsClient && _isDisplayBuild==='TRUE'" [disabled]="_BuildStatus"
                                data-target="#BuildModal" (click)="ResetBuildPouup()">build</button>
                        </div>

                    </ng-template>
                </p-table>
            </div>

            <div class="noRecordFound text-center border-top" *ngIf="_ifNoRecords">
                <img src="../../assets/images/no_records.png">
                <p class="title">No Result Found</p>
                <p class="mb-0 subtitle">Sorry, we couldn’t find any results for this search.</p>
            </div>
        </div>
    </div>
</div>

<app-addfaq (inputdata)="AddEditPageResult($event)" *ngIf="_showAddEditScreen==true"
    [inputVariable]="{PageName: PageName, EditRowID: EditRowID, faqListArrData: faqListArrData, TraningModelNameList: _TraningModelNameList}">
</app-addfaq>


<app-conv-design-flow (inputdata)="CloseConFlowWindow($event)" *ngIf="_showFlowChartScreen==true"
    [inputVariable]="{selectedRowData:_selectedRow}">
</app-conv-design-flow>

<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>


<ng-template #modalDataDelete let-modal>
    <div class="deletePopupModal">
        <div class="modal-content">
            <div class="modal-body">
                <div class="deleteClosePopup">
                    <a (click)="modal.dismiss('Cross click')">
                        <img src="../../../assets/images/cross.png" class="cross-img">
                    </a>
                </div>
                <div class="deletePopupTitle">This will delete the FAQ</div>
                <div class="popupContent popupContentQue ">{{_QuestionTodelete}}
                </div>

                <div class="popupWrapper">

                    <div class="popupWrapperHeader">Why do you want to delete?</div>

                    <div class=" CheckBoxDiv ">

                        <p-dropdown class="CalenderDropDown" [options]="_DeleteReasonsLst"
                            [(ngModel)]="_selectedDeleteReasons" optionLabel="name"
                            (onChange)="OnSelectDeletedReason()">
                        </p-dropdown>
                    </div>

                    <div class="PoupContent" *ngIf='_isDisplayOtherSec'>
                        <label class="otherLabel">Other:</label>
                        <textarea class="OtherTextBox form-control " [(ngModel)]="_OtherData"
                            (keyup)='onchangeControlles($event)' maxlength="500"></textarea>
                    </div>

                </div>

                <div class="PoupContent deletebtnsdiv">
                    <button title="Delete" type="button" class="btn btn-primary mr-10px deletebtn"
                        (click)="deleteFaqRecord(selectedCities, _OtherData); modal.dismiss(); "
                        [disabled]='_disableDeleteBtn'>Delete</button>
                    <button title="Cancel" type="button" class="btn btn-secondary deletebtn"
                        (click)="modal.dismiss('Cross click')">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="modal customThemeModal buildModalPopup" [ngClass]="{'d-block':showPopup == true}" id="BuildModal"
    tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="modal-body">
                <div class="closePopup">
                    <a data-dismiss="modal">
                        <img src="../../../assets/images/cross.png" class="cross-img">
                    </a>
                </div>

                <div class="popupTitle">Model Building</div>
                <div class="popupSubTitle" style="font-family: Lato-Regular">Version{{_newVersion}}</div>
                <div class="optionsSelection addFaqWrapper">
                    <div class="d-inline-block mr-24px">
                        <div class="label">Select model</div>
                        <!-- <p-autoComplete class="customAutocomplete" [(ngModel)]="_modelName"
                            [suggestions]="_filteredSectionNameArr" (completeMethod)="filterSectionNameAE($event, _modelName)"
                            field="value" [dropdown]="true">
                        </p-autoComplete> -->

                        <p-dropdown [options]="_buildModelArr" class="CalenderDropDown" [(ngModel)]="_modelName"
                            (onChange)='filterSectionNameAE($event, _modelName)' optionLabel="value"
                            [disabled]='_IsDisableModelDropDown'>
                        </p-dropdown>
                    </div>

                    <!-- <div class="d-inline-block">
                        <div class="label">Language</div>
                        <p-dropdown [options]="_languageArr" class="CalenderDropDown" [(ngModel)]="_Lang"
                            optionLabel="name">
                        </p-dropdown>
                    </div> -->
                </div>

                <div class="mainTestingCheckbox">
                    <div class="label">Perform tests for </div>
                    <p-checkbox name="groupname" (click)="ckChangeTest()" id="IntentTest" class="mr-24px"
                        [disabled]='_IsDisableModelDropDown' [(ngModel)]="_BuildmodelIP.IntentTest" label="Intent"
                        [binary]="true"></p-checkbox>
                    <p-checkbox name="groupname" [disabled]="_IsStartTesting || _IsDisableModelDropDown"
                        id="StartTesting" [(ngModel)]="_BuildmodelIP.StartTesting" label="Utterance" [binary]="true">
                    </p-checkbox>
                </div>
                <span style="color: red;" *ngIf="_MsgInnerBuildpopup">{{_IfNoModelDataMsg}}</span>
                <!-- <span style="color: red;" *ngIf ="_MsgInnerBuildpopup">FAQs are not yet added in {{_modelName.value}}. Please add the FAQs and then train this model</span> -->

            </div>

            <div class="popupFooter">
                <button title="Build" type="button" class="btn btn-primary mr-20px"
                    (click)="DecideBuildingInsert(_BuildmodelIP)" data-toggle="modal" data-target="#BuildModalprogress"
                    data-dismiss="modal" [disabled]='_disableInnerBuildBtn'>Build</button>
                <button title="Cancel" type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>

            </div>

        </div>
    </div>
</div>

<ng-template #modalListTypeData let-modal="dismiss">
    <app-intent-list [inputVariable]="{selectedRowData:vselectedListTypeRowDetails, dismissFunction: modal}">
    </app-intent-list>
</ng-template>