import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe, } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { KYCService } from '../services/KYC/kyc.service'

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.css'],
  providers: [DatePipe]
})

export class KYCComponent implements OnInit {

  kycListArr: any = [];

  kycHeadersArr: any = [];

  first: number = 0;

  isLoader: boolean = false;

  isDisabled: boolean = true;

  selectedKycId: any;
  isNogo: boolean = false;
  categories: any = []

  myDate: any

  sortColumnName: string;
  sortorder: boolean;
  descOrder: Boolean;

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string

  _RoleName: any
  _ViewClientRoleAccess: boolean = false;

  tooltip: string = "Contact admin to change the KYC data"

  @ViewChild("userHtml", { static: false }) userHtml: any;
  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;

  title = environment.pageTitle + ' - KYC Rules';

  constructor(public kycconfigservice: KYCService, private datePipe: DatePipe, private modalService: NgbModal, private titleService: Title) {

    this.myDate = new Date();
  }

  ngOnInit(): void {

    this.titleService.setTitle(this.title);

    this.isLoader = true
    this.getKYCList();
    this.kycListHeaders();

    this._RoleName = window.localStorage.getItem("RoleName");

    if (this._RoleName === 'ClientView' || this._RoleName === 'ContentView') {
      this._ViewClientRoleAccess = true;
    }
  }

  kycListHeaders() {
    this.kycHeadersArr = [
      { field: 'KYCCheckListId', header: 'Sr No.', width: "5%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'abc' },
      { field: 'Name', header: 'Name', width: "8%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'Section Name' },
      { field: 'Information', header: 'Information', width: "12%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'FAQ' },
      { field: 'CauseOfFailure', header: 'Cause Of Failure', width: "15%", isEditable: "true", isSort: "false", textAlignment: "left", value: 'Answer' },
      { field: 'NoGo', header: 'Pass', width: "6%", isEditable: "false", isSort: "false", textAlignment: "center", value: 'b' },
      { field: 'Maybe', header: 'Needs Review', width: "6%", isEditable: "false", isSort: "false", textAlignment: "center", value: 'b' },
      { field: 'ItsOk', header: 'Fail', width: "6%", isEditable: "false", isSort: "true", textAlignment: "center", value: 'b' }
    ]
  }

  closePopup() {
    this.showPopup = false;
  }

  getKYCList() {
    this.kycconfigservice.getAllKYCData().subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        ;
        this.kycListArr = res.Data
        if (this.kycListArr[0].IsLock == "1") {
          this.isDisabled = true;

        }
        else
          this.isDisabled = false;
        this.isLoader = false;

        for (let i of this.kycListArr) {
          if (i.NoGo === false && i.ItsOk === false && i.Maybe === false) {
            this.tooltip = "tooltip"
          }
        }
      }
      else if (res.Error) {

        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  exportKYCList() {
    const params = {
      "reverseorder": "",
      "SorColumnName": ""
    }

    this.kycconfigservice.exportKYCData(params).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        var status = res.status;
        const headers = res.headers;
        const data = res.Data;
        var contentType = 'application/CSV';
        var linkElement = document.createElement('a');
        var blob = new Blob([data], { type: contentType });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';

        this.myDate = new Date();
        
        var myDate1 = this.datePipe.transform(this.myDate, 'MMddyyyyHHmm');
        a.download = "KYCRules" + myDate1 + '.csv';

        document.body.appendChild(a);
        a.click();

        this.popupImg = "../../../assets/images/download-popup.png";
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)
      }
      else if (res.Error) {

        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  UpdateKYCData(SingleRoWData: any, event: any) {

    this.isLoader = true;
    if (event.toUpperCase() === 'NOGO') {
      SingleRoWData.NoGo = true;
      SingleRoWData.Maybe = false;
      SingleRoWData.ItsOk = false;
    }
    else if (event.toUpperCase() === 'MAYBE') {
      SingleRoWData.NoGo = false;
      SingleRoWData.Maybe = true;
      SingleRoWData.ItsOk = false;
    }
    else if (event.toUpperCase() === 'ITSOK') {
      SingleRoWData.NoGo = false;
      SingleRoWData.Maybe = false;
      SingleRoWData.ItsOk = true;
    }
    else {
      SingleRoWData.NoGo = false;
      SingleRoWData.Maybe = false;
      SingleRoWData.ItsOk = false;
    }

    this.kycconfigservice.UpdateKYCData(SingleRoWData).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        this.getKYCList();

        this.popupImg = "../../../assets/images/Update.png"
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)

      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/update_error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo);
      }
    })
  }

  closeModal: any
  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  sortcolumn(ColumnName: any) {

    let reverse = this.sortColumnName === ColumnName ? !this.descOrder : undefined;

    if (reverse === undefined)
      reverse = false;

    this.sortColumnName = ColumnName;
    this.descOrder = reverse;

    if (this.descOrder == true)
      this.sortorder = false;
    else
      this.sortorder = true;
  }
}
