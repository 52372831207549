<div class="container-fluid screen-container bg-fff xl-bg-transperent ">

    <div class="mainContainerWrapper">

        <div class="header row">
            <div class="col-12">
                <div class="float-left">
                    <span class="page-title">Dashboard Target
                    </span>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="tableContent">
                <p-table #dt [value]="DashboardTargetArr" styleClass="p-custom-datatable" [rows]="10" [paginator]="true"
                    reflow="true" paginatorTemplate="{RowsPerPageDropdown}" paginatorPosition="bottom"
                    paginatorPosition="bottom" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,40,50]"
                    currentPageReportTemplate="Displaying {last} of {totalRecords}" [rowHover]="true"
                    [scrollable]="true" scrollHeight="flex" [(first)]="first" editMode="row" [editable]="true">

                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col *ngFor="let col of dashboardTargetHeadersArr" [style.width]='col.width'>
                        </colgroup>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th *ngFor="let col of dashboardTargetHeadersArr" [pSortableColumn]="col.field"
                                style="text-align: left;" [ngClass]="col.field" data-toggle="tooltip"
                                data-placement="top" title="{{col.header}}">
                                <div class="tableHeader border-right-dadadd" *ngIf="col.isSort === 'true'">
                                    <div class="d-inline-block text-overflow-header"
                                        [ngClass]="{'pl-25':col.field === 'AppSettingID', 'xl-pl-35px':col.field === 'AppSettingID', 'xl-pl-14px':col.field !== 'AppSettingID', 'pl-10':col.field !== 'AppSettingID'}">
                                        {{col.header}}
                                    </div>
                                    <div class="tableSortIcon">
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </div>
                                </div>
                                <div class="tableHeader" *ngIf="col.isSort === 'false'"
                                    [ngClass]="{'border-right-dadadd': col.field != 'SettingValue', 'pl-25':col.field === 'AppSettingID', 'xl-pl-35px':col.field === 'AppSettingID', 'xl-pl-14px':col.field !== 'AppSettingID', 'pl-10':col.field !== 'AppSettingID'}">
                                    <div class="d-inline-block text-overflow-header">
                                        {{col.header}}
                                    </div>
                                </div>
                            </th>

                        </tr>
                        <tr *ngIf="isLoader">
                            <td *ngFor="let col of dashboardTargetHeadersArr">
                                <div class="item customSkeletonLoader">
                                    <ngx-skeleton-loader count="20" appearance=""></ngx-skeleton-loader>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-car let-ri="rowIndex">
                        <tr>
                            <td pEditableColumn *ngFor="let col of dashboardTargetHeadersArr"
                                [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">
                                <div
                                    [ngClass]="{'xl-pl-14px':col.field !== 'AppSettingID', 'pl-10':col.field !== 'AppSettingID'}">
                                    <div
                                        [ngClass]="{'pl-25':col.field === 'AppSettingID', 'xl-pl-35px':col.field === 'AppSettingID'}">
                                        {{car[col.field]}}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<ng-template #modalWarningAndInfo let-modal>
    <div class="customThemeModal">
        <div class="" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="closePopup">
                        <a (click)="modal.dismiss('Cross click')">
                            <img src="../../../assets/images/cross.png" class="cross-img">
                        </a>
                    </div>
                    <div class="popupImg">
                        <img src="{{popupImg}}" class="left-img">
                    </div>
                    <div class="popupTitle">{{popupContent}}</div>
                    <div class="popupContent">{{popupContentError}}</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>