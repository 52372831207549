<div class="header row">
    <div class="col-12 pt-3 border-bottom">
        <div class="float-left">
            <h3 class="mt-1">
                <a href="#" class="mr-2">
                    <i class="fa fa-home" aria-hidden="true"></i>
                </a>
                <span>
                    FAQ List
                </span>
            </h3>
        </div>
    
        <div class="float-right mb-2">
            <h3>
                <a href="#" class="mr-2">
                    <i class="fa fa-bell" aria-hidden="true"></i>
                </a>
                <button title="Logout" type="button" class="btn btn-primary">Logout</button>
            </h3>
        </div>
    </div>
</div>