<div class="container-fluid screen-container xl-bg-transperent">

    <div class="mainContainerWrapper">

        <div class="header row">
            <div class="col-12">
                <div class="float-left">
                    <span class="page-title">User Accounts</span>
                </div>

                <div class="float-right">

                    <button [hidden]="_ViewClientRoleAccess" title="Export" type="button" class="btn btn-secondary"
                        (click)="AfterCLickExport()" [disabled]='_ifNoRecords'>
                        <img src="../../../assets/images/Export_icon.png" class="left-img export_icon">
                        <img src="../../../assets/images/Export-icon-selected.png" class="left-img seleted_export_icon">
                        <span class="align-middle">export</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="row subHeader xl-pl-20px xl-pr-20px">
            <div class="col-md-12">
                <div class="float-left">
                    <div class="search-field">

                        <div class="static-img"><img src="../../../assets/images/search.png"></div>
                        <input type="text" class="form-control custom-input-field" placeholder="Type Email or Ref. No"
                            [(ngModel)]="searchNewAccount" (keypress)="searchData($event, 'Search')">

                        <div class="multiselectSearch" style="height: 70px !important;">
                            <p-multiSelect [options]="searchOptionArr" [(ngModel)]="selectedSearchOpt"
                                (ngModelChange)="selectSearchOption($event)" optionLabel="name" display="chip"
                                [disabled]='searchNewAccount==""' [showHeader]="false"></p-multiSelect>
                        </div>
                    </div>
                </div>

                <div class="float-right" (clickOutside)="onClickedOutside($event)">
                    <div class="dropdown-field">
                        <div class="dropdown-input-field" (click)="clickEvent()">
                            <div class="static-img" *ngIf="isHide"><img src="../../../assets/images/Filter.png"></div>
                            <div class="static-img" *ngIf="chageFilterInput == true"><img
                                    src="../../../assets/images/filter_icon.png"></div>
                            <input type="text" class="form-control custom-input-field cursor-pointer"
                                [ngClass]="{'selectedfilterInput':chageFilterInput == true, 'selectedfilterInputOutline':selectedFieldOutline, 'greyInputOutline':greyFieldOutline == true}"
                                placeholder="Filter" readonly>
                            <div class="filterChips">
                                <span *ngIf="chageFilterInput == true">Filter/{{totalSelectedValues}}</span>
                            </div>
                            <a class="dropdownArrow" (mouseover)="changeStyle()" (mouseout)="changeStyle()"
                                (click)="clickEvent()">
                                <img *ngIf="isHide" (click)="clickEvent()" src="../../../assets/images/arrow-down.png">
                                <img *ngIf="chageFilterInput == true" (click)="clickEvent()"
                                    src="../../../assets/images/Icon ionic-ios-arrow-down.png">
                            </a>
                        </div>
                    </div>
                    <div [ngClass]="{'showFilterContainer': toggle, 'hideFilterContainer':toggle == false}">
                        <div class="filterArrContainer">
                            <div class="filterHeaderBlock">
                                <span class="filterCotainerTitle">Filter types</span>
                                <span class="float-right filterContainerLink"><a
                                        (click)="clearInputField()">Clear</a></span>
                            </div>
                            <div class="block w-100" *ngFor="let item of filterArr">
                                <div *ngFor="let data of item.items">
                                    <ul class="pl-0">
                                        <li>
                                            <label class="filterOptions mb-0"
                                                [ngClass]="{'selectedFilterOptions':data.checked == true}">
                                                <input type="checkbox" name="groupname" value="{{data.name}}"
                                                    [(ngModel)]="data.checked"
                                                    (ngModelChange)="selectFilterOption(data)" />
                                                <div class="filter-status-color" *ngIf="item.value == 'faqstatus'"
                                                    [ngClass]="{'status-bg-live': data.status === 'live', 'status-bg-live-update':data.status === 'liveContentUpdate',
                                            'status-bg-content-approved': data.status === 'liveContentApproved', 'status-bg-not-live': data.status === 'notLive'}">
                                                </div>
                                                {{data.name}}
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">

            <div class="tableContent" [hidden]="_ifNoRecords">
                <p-table #dt [value]="NewAccListArr" styleClass="p-custom-datatable" [rows]="10" [paginator]="true"
                    reflow="true" paginatorTemplate="{RowsPerPageDropdown}" paginatorPosition="bottom"
                    paginatorPosition="bottom" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,40,50]"
                    currentPageReportTemplate="Displaying {last} of {totalRecords}" [rowHover]="true"
                    [scrollable]="true" scrollHeight="flex" [(first)]="first" editMode="row" [editable]="true"
                    [resizableColumns]="true">

                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col *ngFor="let col of newAccHeadersArr" [style.width]='col.width'>
                        </colgroup>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn *ngFor="let col of newAccHeadersArr" [pSortableColumn]="col.field"
                                [pSortableColumnDisabled]="col.field === 'download' || col.field === 'ImgPath'"
                                style="text-align: left;" [ngClass]="col.field" data-toggle="tooltip"
                                data-placement="top" title="{{col.header}}">
                                <div class="tableHeader" *ngIf="col.isSort === 'true'" (click)="sortcolumn(col.field)"
                                    [ngClass]="{'border-right-dadadd': col.field != 'RvwStatus' && col.field != 'download' && col.field != 'ImgPath'}">
                                    <div class="d-inline-block text-overflow-header"
                                        [ngClass]="{'pl-15':col.field === 'DispID', 'xl-pl-20px':col.field === 'DispID'}">
                                        {{col.header}}</div>
                                    <div class="tableSortIcon">
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </div>
                                </div>
                                <div *ngIf="col.isSort === 'false'">
                                    {{col.header}}
                                </div>
                            </th>

                        </tr>
                        <tr *ngIf="isLoader">
                            <td *ngFor="let col of newAccHeadersArr">
                                <div class="item customSkeletonLoader">
                                    <ngx-skeleton-loader count="20" appearance=""></ngx-skeleton-loader>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-car let-ri="rowIndex">
                        <tr [ngClass]="{'bg-e3fcff':isStatusClick == true && car.DispID === eventDispId}"
                            class="position-relative">
                            <td pEditableColumn *ngFor="let col of newAccHeadersArr"
                                [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">
                                <div [ngClass]="{'xl-pl-14px':col.field !== 'DispID', 'pl-10':col.field !== 'DispID'}">
                                    <div *ngIf="col.field != 'RvwStatus' && col.field != 'BondCreatedOn' && col.field != 'ImgPath'"
                                        [ngClass]="{'pl-25':col.field === 'DispID', 'xl-pl-35px':col.field === 'DispID'}">
                                        {{car[col.field]}}
                                    </div>

                                    <div *ngIf="col.field === 'BondCreatedOn'">
                                        {{car[col.field] | date : 'MM/dd/yyyy HH:mm:ss'}}
                                    </div>

                                    <div *ngIf="col.field === 'RvwStatus'" class="statusBlock"
                                        [ngClass]="{'completeStatus':car[col.field] === 'Completed', 'incompleteStatus':car[col.field] === 'Incomplete', 'pendingStatus':car[col.field] === 'Pending'}">
                                        <a (click)="statusLink(car)">{{car[col.field]}}</a>
                                    </div>
                                </div>

                                <div *ngIf="col.field === 'download'">

                                    <a (click)="openDownloadFilesPopup()" class="accountActionBtn"
                                        [ngClass]="{'d-none': isStatusClick == true && car.DispID === eventDispId }"
                                        *ngIf="car.SigningStatus==true">
                                        <img src="../../../assets/images/hd_menu_icons/Sign_preview.png">
                                        <img class="hoverImg"
                                            src="../../../assets/images/hd_menu_icons/Sign_preview_hover.png">
                                    </a>

                                    <a (click)="openDownloadFilesPopup()" class="accountActionBtn"
                                        [ngClass]="{'d-none': isStatusClick == true && car.DispID === eventDispId }"
                                        *ngIf="car.SigningStatus==false">
                                        <img src="../../../assets/images/hd_menu_icons/Sign_preview.png">
                                        <img class="hoverImg"
                                            src="../../../assets/images/hd_menu_icons/Sign_preview_hover.png">
                                    </a>
                                </div>

                                <!-- <div *ngIf="col.field === 'ImgPath'"
                                    [ngClass]="{'d-none': isStatusClick == true && car.DispID === eventDispId }">
                                    <a routerLink="/portal/personas/Individual">
                                        <img src="../../../assets/images/avataar/{{car[col.field]}}" class="avataarImg">
                                    </a>
                                </div> -->

                                <!-- <div *ngIf="col.field === 'download' " class="pendingStatusMsg"
                                    [ngClass]="{'d-block': isPendingStatus == true && car.DispID === eventDispId }">

                                    <p-radioButton name="groupname" value="true" label="Approve"
                                        (click)="checkPendingStatus('approve', car)" [(ngModel)]="PendingStatus">
                                    </p-radioButton>
                                </div> -->

                                <!-- <div *ngIf="col.field === 'download' " class="pendingStatusMsg"
                                    [ngClass]="{'d-block': isPendingStatus == true && car.DispID === eventDispId }">

                                    <p-radioButton name="groupname" value="true" label="Approve"
                                        (click)="checkPendingStatus('approve', car)" [(ngModel)]="PendingStatus">
                                    </p-radioButton>
                                </div> -->

                                <div *ngIf="col.field === 'ImgPath' " class="pendingStatusMsg"
                                    [ngClass]="{'d-block': isPendingStatus == true && car.DispID === eventDispId }">

                                    <p-radioButton name="groupname" value="disapprove" label="Disapprove"
                                        (click)="checkPendingStatus('disapprove', car)" [(ngModel)]="PendingStatus">
                                    </p-radioButton>
                                </div>

                                <div class="position-absolute incompleteStatusPosition"
                                    *ngIf="isIncompleteStatus == true && col.field === 'download'">
                                    <div [innerHtml]="userHtml" class="incompleteStatusMsg incomplete"
                                        [ngClass]="{'d-block': isIncompleteStatus == true && car.DispID === eventDispId }">
                                        {{car.Reason}}
                                    </div>
                                </div>

                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <div class="noRecordFound text-center border-top" *ngIf="_ifNoRecords">
                <img src="../../assets/images/no_records.png">
                <p class="title">No Result Found</p>
                <p class="mb-0 subtitle">Sorry, we couldn’t find any results for this search.</p>
            </div>
        </div>
    </div>
</div>

<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>

<ng-template #ConfirmationModal let-modal="dismiss">
    <app-confirmation-modal
        [confirmModalInputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal, pendingstatus: checkPengingStatusLable}"
        [callbackFunction]="boundedPrintValue">
    </app-confirmation-modal>
</ng-template>

<ng-template #modalDownloadFiles let-modal>
    <div class="deletePopupModal">
        <div class="modal-content">
            <div class="modal-body">
                <div class="deleteClosePopup">
                    <a (click)="modal.dismiss('Cross click')">
                        <img src="../../../assets/images/cross.png" class="cross-img">
                    </a>
                </div>
                <div class="deletePopupTitle">Download File</div>
                <div class="popupWrapper">
                    <div class="downloadLink">
                        <span><img src="../../../assets/images/downloadPopupIcon.png" class="download-img"></span>
                        <span><a href="#">Driver's License</a></span>
                    </div>
                    <div class="downloadLink">
                        <span><img src="../../../assets/images/downloadPopupIcon.png" class="download-img"></span>
                        <span><a href="#">Social Security Number(SSN)</a></span>
                    </div>
                    <div class="downloadLink">
                        <span><img src="../../../assets/images/downloadPopupIcon.png" class="download-img"></span>
                        <span><a href="#">W4 Forms</a></span>
                    </div>
                    <div class="downloadLink">
                        <span><img src="../../../assets/images/downloadPopupIcon.png" class="download-img"></span>
                        <span><a href="#">Company Policy Documents</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>