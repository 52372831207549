<h4 class="pageTitle">{{this._selectedRowData.IntentName}}</h4>

<div class="container-fluid flowDiagramBlock screen-container xl-bg-transperent ">
    <div class="row flowchartContainer" *ngIf="isCreateFlowchart">
        <div class="col-md-2 pl-0">
            <div id="palette-space" class="sb-mobile-palette">
                <ejs-symbolpalette id="symbolpalette" [enableAnimation]='enableAnimation' [expandMode]='expandMode'
                    [palettes]='palettes' (created)='create($event)' width="100%" height="700px" [symbolHeight]=60
                    [symbolWidth]=60 [symbolMargin]='symbolMargin' [getSymbolInfo]='getSymbolInfo'>
                </ejs-symbolpalette>
            </div>
        </div>
        <div class="col-md-10 pl-0">
            <ejs-diagram #diagram id="diagram" height="100%" [snapSettings]='snapSettings'>
            </ejs-diagram>
            <router-outlet></router-outlet>
        </div>
    </div>

    <div class="row flowchartContainer viewDiagramContainer" *ngIf="!isCreateFlowchart">
        <!-- <div class="col-md-12">
            <ejs-toolbar #toolbar id='toolbar' (clicked)='toolbarClick($event)' (created)='toolbarCreate($event)'>
                <e-items>
                    <e-item id='Add' tooltipText='Add' prefixIcon='e-ddb-icons e-add' text='Add'></e-item>
                    <e-item type='Separator'></e-item>
                    <e-item id='Edit' tooltipText='Edit' prefixIcon='e-ddb-icons e-update' text='Edit'></e-item>
                    <e-item type='Separator'></e-item>
                    <e-item id='Delete' tooltipText='Delete' prefixIcon='e-ddb-icons e-delete' text='Delete'></e-item>
                    <e-item type='Separator'></e-item>
                </e-items>
            </ejs-toolbar>
        </div> -->
        <div class="col-md-2 pl-0">
            <div id="palette-space" class="sb-mobile-palette">
                <ejs-symbolpalette id="symbolpalette" [enableAnimation]='enableAnimation' [expandMode]='expandMode'
                    [palettes]='palettes' (created)='create($event)' width="100%" height="700px" [symbolHeight]=60
                    [symbolWidth]=60 [symbolMargin]='symbolMargin' [getSymbolInfo]='getSymbolInfo'>
                </ejs-symbolpalette>
            </div>
        </div>
        <div class="col-md-10 pl-0">
            <ejs-diagram #viewdiagram id="viewdiagram" height="100%" [nodes]="nodes" [connectors]="connectors"
                [layout]='layout' [contextMenuSettings]="contextMenuSettings"
                (contextMenuClick)="contextMenuClick($event)" (contextMenuOpen)="contextMenuOpen($event)">
            </ejs-diagram>
        </div>
        <!-- <ejs-dialog #dialog id='editDialog' [buttons]='dlgButtons' width='300px' [visible]='false' isModal='true'
            showCloseIcon='true'>
            <ng-template #content>
                <div class='showLabel'>
                    <ejs-textbox #Description1 id='Description1' placeholder='Enter Description'
                        floatLabelType='Always'></ejs-textbox>
                </div>
            </ng-template>
        </ejs-dialog> -->

        <div *ngIf="fetchRoleName === 'Admin'">
            <div class="properties" *ngIf="isPropertyBlock">
                <div class="blockheader">
                    <span class="title">Properties - {{selectedNodeName}}</span>
                    <span class="float-right">
                        <a (click)="closePropertyBlock()">
                            <img src="../../../assets/BotCloseBlack.png" class="crossImg">
                        </a>
                    </span>
                </div>
                <div class="blockContainer">
                    <ejs-tab id="element">
                        <div class="e-tab-header">
                            <div>General </div>
                            <div>Slot message </div>
                            <div>Return slot based on input </div>
                        </div>
                        <div class="e-content mt-3 scrollablediv">
                            <div>
                                <div class="col-md-12">
                                    <label class="formLabel">Slot name <span class="asterisk">*</span></label>
                                    <input type="text" class="form-control textBoxSizes " id="SlotName"
                                        autocomplete="off" [(ngModel)]="_SlotObj.SlotName" readonly>
                                </div>
                                <div class="col-md-12">
                                    <label class="formLabel">Slot order <span class="asterisk">*</span></label>
                                    <input type="text" class="form-control textBoxSizes "
                                        [(ngModel)]="_SlotObj.SlotOrder" id="SlotOrder" autocomplete="off">
                                </div>
                                <div class="col-md-12">
                                    <label class="formLabel">Slot values <span class="asterisk">*</span></label>
                                    <input type="text" class="form-control textBoxSizes" [(ngModel)]="SlotInputValues"
                                        (keyup)="addDataAsSlotValueArr(SlotInputValues)" id="SlotValues"
                                        autocomplete="off">
                                </div>
                                <div class="col-md-12">
                                    <label class="formLabel">Slot type <span class="asterisk">*</span></label>
                                    <!-- <input type="text" class="form-control textBoxSizes " [(ngModel)]="Dataformat"
                                        id="Dataformat" autocomplete="off"> -->
                                    <div>
                                        <p-dropdown [options]="SlotTypeArr" class="CalenderDropDown"
                                            [(ngModel)]="_SlotObj.SlotType" optionLabel="name" optionValue="code"
                                            (onChange)="onChangeSlotType($event)">
                                        </p-dropdown>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="formLabel">Slot data type <span
                                                    class="asterisk">*</span></label>
                                            <!-- <input type="text" class="form-control textBoxSizes " [(ngModel)]="SlotDataType"
                                                id="SlotDataType" autocomplete="off"> -->
                                            <div>
                                                <p-dropdown [options]="dataTypes" class="CalenderDropDown"
                                                    [(ngModel)]="_SlotObj.SlotDataType" optionLabel="name"
                                                    optionValue="code" (onChange)="onChangeDataType($event)">
                                                </p-dropdown>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <label class="formLabel">Slot data validation <span
                                                    class="asterisk">*</span></label>
                                            <!-- <input type="text" class="form-control textBoxSizes " [(ngModel)]="DataValidation"
                                            id="DataValidation" autocomplete="off"> -->
                                            <div>
                                                <p-dropdown [options]="dataValidation" class="CalenderDropDown"
                                                    [(ngModel)]="_SlotObj.DataValidation" optionLabel="name"
                                                    optionValue="code" (onChange)="onChangeDataValidation($event)">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-md-12">
                                    <label class="formLabel">Slot data format <span class="asterisk">*</span></label>
                                    <!-- <input type="text" class="form-control textBoxSizes " [(ngModel)]="Dataformat"
                                        id="Dataformat" autocomplete="off"> -->
                                    <div>
                                        <p-dropdown [options]="dataFormat" class="CalenderDropDown"
                                            [(ngModel)]="_selectedDataFormat" optionLabel="name"
                                            (onChange)="onChangeDataformat($event)">
                                        </p-dropdown>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label class="formLabel">Input format</label>
                                    <input type="text" class="form-control textBoxSizes "
                                        [(ngModel)]="_SlotObj.InputFormat" id="Inputformat" autocomplete="off">
                                </div>
                                <div class="col-md-12 ">
                                    <label class="formLabel">Number of attempts allowed <span
                                            class="asterisk">*</span></label>
                                    <input type="text" class="form-control textBoxSizes "
                                        [(ngModel)]="_SlotObj.NoOfAttemptsAllowed" id="NumberOfAttemptsAllowed"
                                        autocomplete="off">
                                </div>
                                <!-- <div class="col-md-12 mt-3">
                                    <button title="Save" class="btn btn-primary" style="margin-right: 15px;"
                                        (click)="mainFormSubmitforAdmin('mainGeneral', ConParameters.slotDataEntity)">Save</button>
                                </div> -->
                            </div>
                            <div>
                                <ejs-accordion expandMode='Single'>
                                    <div>
                                        <div>
                                            <div> Slot Message Web</div>
                                        </div>
                                        <div>
                                            <div class="col-md-12">
                                                <label class="formLabel">App type <span
                                                        class="asterisk">*</span></label>
                                                <input class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.webAppType" id="AppType"
                                                    autocomplete="off" readonly />
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Pre message</label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.webPreMsg" id="PreMessage"
                                                    autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Pre message type</label>
                                                <input class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.webPreMsgType"
                                                    id="PreMessageType" autocomplete="off" />
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Main message <span
                                                        class="asterisk">*</span></label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.webMainMsg" id="MainMessage"
                                                    autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Display message <span
                                                        class="asterisk">*</span></label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.webDisplayMessage"
                                                    id="WebDisplayMsg" autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Response type <span
                                                        class="asterisk">*</span></label>
                                                <input class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.webResponseType"
                                                    id="ResponseType" autocomplete="off" />
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Post message</label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.webPostMsg" id="PostMessage"
                                                    autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Post message type</label>
                                                <input class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.webPostMsgType"
                                                    id="PoseMessageType" autocomplete="off" />
                                            </div>
                                            <!-- <div class="col-md-12 mt-3">
                                                <button title="Save" class="btn btn-primary" style="margin-right: 15px;"
                                                    (click)="mainFormSubmitforAdmin('MainWeb')">Save</button>
                                            </div> -->
                                        </div>
                                    </div>

                                    <div>
                                        <div>
                                            <div> Slot Message App</div>
                                        </div>
                                        <div>
                                            <div class="col-md-12">
                                                <label class="formLabel">App type <span
                                                        class="asterisk">*</span></label>
                                                <input class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.appAppType" id="AppType"
                                                    autocomplete="off" readonly />
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Pre message</label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.appPreMsg" id="PreMessage"
                                                    autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Pre message type</label>
                                                <input class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.appPreMsgType"
                                                    id="PreMessageType" autocomplete="off" />
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Main message <span
                                                        class="asterisk">*</span></label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.appMainMsg" id="MainMessage"
                                                    autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Display message <span
                                                        class="asterisk">*</span></label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.appDisplayMessage"
                                                    id="WebDisplayMsg" autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Response type <span
                                                        class="asterisk">*</span></label>
                                                <input class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.appResponseType"
                                                    id="ResponseType" autocomplete="off" />
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Post message</label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.appPostMsg" id="PostMessage"
                                                    autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Post message type</label>
                                                <input class="form-control textBoxSizes "
                                                    [(ngModel)]="_ngModelSlotmessageObj.appPostMsgType"
                                                    id="PoseMessageType" autocomplete="off" />
                                            </div>
                                            <!-- <div class="col-md-12 mt-3">
                                                <button title="Save" class="btn btn-primary" style="margin-right: 15px;"
                                                    (click)="mainFormSubmitforAdmin('MainApp')">Save</button>
                                            </div> -->
                                        </div>
                                    </div>
                                </ejs-accordion>
                            </div>
                            <!-- <div>
                                <ejs-accordion expandMode='Single'>
                                    <div>
                                        <div>
                                            <div> Await Message Web</div>
                                        </div>
                                        <div>
                                            <div class="col-md-12">
                                                <label class="formLabel">App type <span
                                                        class="asterisk">*</span></label>
                                                <input class="form-control textBoxSizes " [(ngModel)]="WebAppType"
                                                    id="AppType" autocomplete="off" readonly />
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Pre message</label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="AwaitWebPreMessage" id="AwaitWebPreMessage"
                                                    autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Pre message type</label>
                                                <input class="form-control textBoxSizes "
                                                    [(ngModel)]="AwaitWebPreMessageType" id="AwaitWebPreMessageType"
                                                    autocomplete="off" />
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Main message <span
                                                        class="asterisk">*</span></label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="AwaitWebMainMessage" id="AwaitWebMainMessage"
                                                    autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Display message <span
                                                        class="asterisk">*</span></label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="AwaitWebDisplayMsg" id="AwaitWebDisplayMsg"
                                                    autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Response type <span
                                                        class="asterisk">*</span></label>
                                                <input class="form-control textBoxSizes "
                                                    [(ngModel)]="AwaitWebResponseType" id="AwaitWebResponseType"
                                                    autocomplete="off" />
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Post message</label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="AwaitWebPostMessage" id="AwaitWebPostMessage"
                                                    autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Post message type</label>
                                                <input class="form-control textBoxSizes "
                                                    [(ngModel)]="AwaitWebPostMessageType" id="AwaitWebPostMessageType"
                                                    autocomplete="off" />
                                            </div>
                                            <div class="col-md-12 mt-3">
                                                <button title="Save" class="btn btn-primary" style="margin-right: 15px;"
                                                    (click)="mainFormSubmitforAdmin('AwaitWeb')">Save</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div>
                                            <div> Await Message App</div>
                                        </div>
                                        <div>
                                            <div class="col-md-12">
                                                <label class="formLabel">App type <span
                                                        class="asterisk">*</span></label>
                                                <input class="form-control textBoxSizes " [(ngModel)]="AppType"
                                                    id="AppType" autocomplete="off" readonly />
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Pre message</label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="AwaitAppPreMessage" id="AwaitAppPreMessage"
                                                    autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Pre message type</label>
                                                <input class="form-control textBoxSizes "
                                                    [(ngModel)]="AwaitAppPreMessageType" id="AwaitAppPreMessageType"
                                                    autocomplete="off" />
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Main message <span
                                                        class="asterisk">*</span></label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="AwaitAppMainMessage" id="AwaitAppMainMessage"
                                                    autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Display message <span
                                                        class="asterisk">*</span></label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="AwaitAppDisplayMsg" id="AwaitAppDisplayMsg"
                                                    autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Response type <span
                                                        class="asterisk">*</span></label>
                                                <input class="form-control textBoxSizes "
                                                    [(ngModel)]="AwaitAppResponseType" id="AwaitAppResponseType"
                                                    autocomplete="off" />
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Post message</label>
                                                <textarea class="form-control textBoxSizes "
                                                    [(ngModel)]="AwaitAppPostMessage" id="AwaitAppPostMessage"
                                                    autocomplete="off"></textarea>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Post message type</label>
                                                <input class="form-control textBoxSizes "
                                                    [(ngModel)]="AwaitAppPostMessageType" id="AwaitAppPostMessageType"
                                                    autocomplete="off" />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </ejs-accordion>
                            </div> -->
                            <div>
                                <!-- <ejs-accordion expandMode='Single'>
                                    <div>
                                        <div>
                                            <div> Error slot </div>
                                        </div>
                                        <div>
                                            <div class="col-md-12">
                                                <label class="formLabel">User input <span
                                                        class="asterisk">*</span></label>
                                                <input type="text" class="form-control disabled "
                                                    [(ngModel)]="_ReturnSlotBasedOnInputObj.UserInput" id="SlotOrder"
                                                    autocomplete="off" readonly>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Return name <span
                                                        class="asterisk">*</span></label>
                                                <div class="form-control textBoxSizes disabled">
                                                    {{this.selectedNodeName}}
                                                </div>
                                            </div>
                                            
                                            <ejs-tab id="element">
                                                <div class="e-tab-header">
                                                    <div>Slot message web </div>
                                                    <div>Slot message app</div>
                                                </div>
                                                <div class="e-content mt-3">
                                                    <div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">App type <span
                                                                    class="asterisk">*</span></label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="_ReturnSlotBasedOnInputObj.Slotmessage.AppType"
                                                                id="AppType" autocomplete="off" readonly />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Pre message</label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="errorWebPreMessage" id="PreMessage"
                                                                autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Pre message type</label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="errorWebPreMessageType" id="PreMessageType"
                                                                autocomplete="off" />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Main message <span
                                                                    class="asterisk">*</span></label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="errorWebMainMessage" id="MainMessage"
                                                                autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Display message <span
                                                                    class="asterisk">*</span></label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="webErrorMsg" id="WebDisplayMsg"
                                                                autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Response type <span
                                                                    class="asterisk">*</span></label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="errorWebResponseType" id="ResponseType"
                                                                autocomplete="off" />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Post message</label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="errorWebPostMessage" id="PostMessage"
                                                                autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Pose message type</label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="errorWebPoseMessageType"
                                                                id="PoseMessageType" autocomplete="off" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">App type <span
                                                                    class="asterisk">*</span></label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="AppType" id="AppType" autocomplete="off"
                                                                readonly />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Pre message</label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="errorAppPreMessage" id="PreMessage"
                                                                autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Pre message type</label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="errorAppPreMessageType" id="PreMessageType"
                                                                autocomplete="off" />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Main message <span
                                                                    class="asterisk">*</span></label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="errorAppMainMessage" id="MainMessage"
                                                                autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Display message <span
                                                                    class="asterisk">*</span></label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="appErrorMsg" id="WebDisplayMsg"
                                                                autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Response type <span
                                                                    class="asterisk">*</span></label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="errorAppResponseType" id="ResponseType"
                                                                autocomplete="off" />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Post message</label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="errorAppPostMessage" id="PostMessage"
                                                                autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Pose message type</label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="errorAppPoseMessageType"
                                                                id="PoseMessageType" autocomplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </ejs-tab>
                                            <div class="col-md-12 mt-3">
                                                <button title="Save" class="btn btn-primary" style="margin-right: 15px;"
                                                    (click)="returnSlotSubmit('errorSlot')">Save</button>
                                            </div>

                                        </div>
                                    </div>
                                    <div
                                        *ngIf="this.NumberOfAttemptsAllowed !=0 || this.NumberOfAttemptsAllowed !=1 || !this.NumberOfAttemptsAllowed">
                                        <div>
                                            <div> Too many attempts Slot </div>
                                        </div>
                                        <div>
                                            <div class="col-md-12">
                                                <label class="formLabel">User input <span
                                                        class="asterisk">*</span></label>
                                                <input type="text" class="form-control disabled "
                                                    [(ngModel)]="tooManyAttemptsUserInput" id="SlotOrder"
                                                    autocomplete="off" readonly>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Return name <span
                                                        class="asterisk">*</span></label>
                                                <input type="text" class="form-control disabled "
                                                    [(ngModel)]="tooManyAttemptsUserInput" id="SlotOrder"
                                                    autocomplete="off" readonly>
                                            </div>
                                            
                                            <ejs-tab id="element">
                                                <div class="e-tab-header">
                                                    <div>Slot message web </div>
                                                    <div>Slot message app</div>
                                                </div>
                                                <div class="e-content mt-3">
                                                    <div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">App type <span
                                                                    class="asterisk">*</span></label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="WebAppType" id="AppType" autocomplete="off"
                                                                readonly />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Pre message</label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="noOfAttemptsWebPreMessage" id="PreMessage"
                                                                autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Pre message type</label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="noOfAttemptsWebPreMessageType"
                                                                id="PreMessageType" autocomplete="off" />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Main message <span
                                                                    class="asterisk">*</span></label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="noOfAttemptsWebMainMessage"
                                                                id="MainMessage" autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Display message <span
                                                                    class="asterisk">*</span></label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="noOfAttemptsErrorMsgWeb" id="WebDisplayMsg"
                                                                autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Response type <span
                                                                    class="asterisk">*</span></label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="noOfAttemptsWebResponseType"
                                                                id="ResponseType" autocomplete="off" />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Post message</label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="noOfAttemptsWebPostMessage"
                                                                id="PostMessage" autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Pose message type</label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="noOfAttemptsWebPoseMessageType"
                                                                id="PoseMessageType" autocomplete="off" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">App type <span
                                                                    class="asterisk">*</span></label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="AppType" id="AppType" autocomplete="off"
                                                                readonly />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Pre message</label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="noOfAttemptsAppPreMessage" id="PreMessage"
                                                                autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Pre message type</label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="noOfAttemptsAppPreMessageType"
                                                                id="PreMessageType" autocomplete="off" />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Main message <span
                                                                    class="asterisk">*</span></label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="noOfAttemptsAppMainMessage"
                                                                id="MainMessage" autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Display message <span
                                                                    class="asterisk">*</span></label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="noOfAttemptsErrorMsgApp" id="WebDisplayMsg"
                                                                autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Response type <span
                                                                    class="asterisk">*</span></label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="noOfAttemptsAppResponseType"
                                                                id="ResponseType" autocomplete="off" />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Post message</label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="noOfAttemptsAppPostMessage"
                                                                id="PostMessage" autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Post message type</label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="noOfAttemptsAppPoseMessageType"
                                                                id="PoseMessageType" autocomplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </ejs-tab>
                                            <div class="col-md-12 mt-3">
                                                <button title="Save" class="btn btn-primary" style="margin-right: 15px;"
                                                    (click)="returnSlotSubmit('tooManyAttempts')">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </ejs-accordion> -->
                                <div id="accordion">
                                    <div class="card"
                                        *ngFor="let address of _ngModelReturnSlotBasedOnInputArr; let i = index;">
                                        <div class="card-header" id="headingOne">
                                            <h5 class="mb-0 accordionLabel" data-toggle="collapse"
                                                [attr.data-target]="'#collapse'+ address.id" aria-expanded="false"
                                                aria-controls="collapseOne" (click)="accordionStyle()">
                                                <!-- <button class="btn btn-link" data-toggle="collapse" [attr.data-target]="'#collapse'+ address.id" aria-expanded="false" aria-controls="collapseOne"> -->
                                                <span>User input for {{address.UserInput}}</span>
                                                <span class="accordionArrow"><i class="fa fa-angle-down"></i></span>
                                                <!-- </button> -->
                                            </h5>
                                        </div>

                                        <div id="collapse{{address.id}}" class="collapse accordionAnimation"
                                            aria-labelledby="headingOne" data-parent="#accordion">
                                            <div class="card-body">
                                                <div class="col-md-12">
                                                    <label class="formLabel">User input <span
                                                            class="asterisk">*</span></label>
                                                    <div class="form-control textBoxSizes disabled">
                                                        {{address.UserInput}}
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <label class="formLabel">Return Name <span
                                                            class="asterisk">*</span></label>
                                                    <div class="" title="{{tooltip}}"
                                                        *ngIf="address.UserInput !== 'Error' && address.UserInput !== 'tooManyAttempts'">

                                                        <p-dropdown [options]="returnNameArr" class="CalenderDropDown"
                                                            [(ngModel)]="address.ReturnName" optionLabel="name"
                                                            placeholder="Select return name" optionValue="code"
                                                            [disabled]="_isReturnNameFieldDisabled"
                                                            (onChange)="onChangeNodesArr($event)">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="form-control" title="{{tooltip}}"
                                                        *ngIf="address.UserInput === 'Error'">
                                                        {{selectedNodeName}}
                                                    </div>
                                                    <div class="form-control" title="{{tooltip}}"
                                                        *ngIf="address.UserInput === 'tooManyAttempts'">
                                                        {{address.UserInput}}
                                                    </div>
                                                </div>

                                                <ejs-tab id="element">
                                                    <div class="e-tab-header">
                                                        <div>Slot message web </div>
                                                        <div>Slot message app</div>
                                                    </div>
                                                    <div class="e-content mt-3">
                                                        <div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">App type <span
                                                                        class="asterisk">*</span></label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.webAppType"
                                                                    id="AppType" autocomplete="off" readonly />
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Pre message</label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.webPreMsg"
                                                                    id="PreMessage" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Pre message type</label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.webPreMsgType"
                                                                    id="PreMessageType" autocomplete="off" />
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Main message <span
                                                                        class="asterisk">*</span></label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.webMainMsg"
                                                                    id="MainMessage" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Display message <span
                                                                        class="asterisk">*</span></label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.webDisplayMessage"
                                                                    id="WebDisplayMsg" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Response type <span
                                                                        class="asterisk">*</span></label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.webResponseType"
                                                                    id="ResponseType" autocomplete="off" />
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Post message</label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.webPostMsg"
                                                                    id="PostMessage" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Post message type</label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.webPostMsgType"
                                                                    id="PoseMessageType" autocomplete="off" />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">App type <span
                                                                        class="asterisk">*</span></label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.appAppType"
                                                                    id="AppType" autocomplete="off" readonly />
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Pre message</label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.appPreMsg"
                                                                    id="PreMessage" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Pre message type</label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.appPreMsgType"
                                                                    id="PreMessageType" autocomplete="off" />
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Main message <span
                                                                        class="asterisk">*</span></label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.appMainMsg"
                                                                    id="MainMessage" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Display message <span
                                                                        class="asterisk">*</span></label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.appDisplayMessage"
                                                                    id="WebDisplayMsg" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Response type <span
                                                                        class="asterisk">*</span></label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.appResponseType"
                                                                    id="ResponseType" autocomplete="off" />
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Post message</label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.appPostMsg"
                                                                    id="PostMessage" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Post message type</label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.Slotmessage.appPostMsgType"
                                                                    id="PoseMessageType" autocomplete="off" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ejs-tab>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-12 returnSlotradioButton">
                                    <div class="row">
                                        <div class="col-md-12 mb-2" *ngIf="_isSlotValueEmpty">
                                            <p-radioButton name="groupname" value="returnToSameSlot"
                                                label="Return to the same slot" [(ngModel)]="selectedReturnSlot"
                                                (onClick)="fetchReturnSlotValue(selectedReturnSlot)"></p-radioButton>
                                        </div>
                                        <div class="col-md-12">
                                            <p-radioButton name="groupname" value="returnSlotBasedOnInput"
                                                label="Return slot based on input" [(ngModel)]="selectedReturnSlot"
                                                (onClick)="fetchReturnSlotValue(selectedReturnSlot)"></p-radioButton>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <ejs-accordion expandMode='Single' *ngIf="_isValidUserInputToSameSlot">
                                    <div>
                                        <div>
                                            <div> User input @All</div>
                                        </div>
                                        <div *ngFor="let address of ValidUserInputToSameSlot; let i = index;">
                                            <div>
                                                <div class="col-md-12">
                                                    <label class="formLabel">Return Name <span
                                                            class="asterisk">*</span></label>
                                                    <div>
                                                        <p-dropdown [options]="returnNameArr" class="CalenderDropDown"
                                                            [(ngModel)]="singleSlotValue" optionLabel="name" optionValue="code"
                                                            (onChange)="onChangeNodesArr($event)">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                                
                                                <ejs-tab id="element">
                                                    <div class="e-tab-header">
                                                        <div>Slot message web </div>
                                                        <div>Slot message app</div>
                                                    </div>
                                                    <div class="e-content mt-3">
                                                        <div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">App type <span
                                                                        class="asterisk">*</span></label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="WebAppType" id="AppType"
                                                                    autocomplete="off" readonly />
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Pre message</label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.SlotMessage[0].PreMsg"
                                                                    id="PreMessage" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Pre message type</label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.SlotMessage[0].PreMsgType"
                                                                    id="PreMessageType" autocomplete="off" />
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Main message <span
                                                                        class="asterisk">*</span></label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.SlotMessage[0].MainMsg"
                                                                    id="MainMessage" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Display message <span
                                                                        class="asterisk">*</span></label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.SlotMessage[0].DisplayMessage"
                                                                    id="WebDisplayMsg" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Response type <span
                                                                        class="asterisk">*</span></label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.SlotMessage[0].ResponseType"
                                                                    id="ResponseType" autocomplete="off" />
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Post message</label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.SlotMessage[0].PostMsg"
                                                                    id="PostMessage" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Post message type</label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.SlotMessage[0].PostMsgType"
                                                                    id="PoseMessageType" autocomplete="off" />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">App type <span
                                                                        class="asterisk">*</span></label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="AppType" id="AppType"
                                                                    autocomplete="off" readonly />
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Pre message</label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.SlotMessage[1].PreMsg"
                                                                    id="PreMessage" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Pre message type</label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.SlotMessage[1].PreMsgType"
                                                                    id="PreMessageType" autocomplete="off" />
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Main message <span
                                                                        class="asterisk">*</span></label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.SlotMessage[1].MainMsg"
                                                                    id="MainMessage" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Display message <span
                                                                        class="asterisk">*</span></label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.SlotMessage[1].DisplayMessage"
                                                                    id="WebDisplayMsg" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Response type <span
                                                                        class="asterisk">*</span></label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.SlotMessage[1].ResponseType"
                                                                    id="ResponseType" autocomplete="off" />
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Post message</label>
                                                                <textarea class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.SlotMessage[1].PostMsg"
                                                                    id="PostMessage" autocomplete="off"></textarea>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <label class="formLabel">Post message type</label>
                                                                <input class="form-control textBoxSizes "
                                                                    [(ngModel)]="address.SlotMessage[1].PostMsgType"
                                                                    id="PoseMessageType" autocomplete="off" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ejs-tab>
                                                <div class="col-md-12 mt-3">
                                                    <button title="Save" class="btn btn-primary"
                                                        style="margin-right: 15px;"
                                                        (click)="returnSlotSubmitUI('returnToSameslot',i)">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ejs-accordion> -->
                                <!-- <ejs-accordion expandMode='Single' *ngIf="_isreturnSlotBasedOnInput">
                                    <div *ngFor="let address of validUserInputArr; let i = index;">
                                        <div>
                                            <div> User input {{address.ReturnName}}</div>
                                        </div>
                                        <div>
                                            <div class="col-md-12">
                                                <label class="formLabel">User input <span
                                                        class="asterisk">*</span></label>
                                                <div class="form-control textBoxSizes disabled">
                                                    {{address.UserInput}}
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <label class="formLabel">Return Name <span
                                                        class="asterisk">*</span></label>
                                                <div class="form-control textBoxSizes disabled">
                                                    {{address.ReturnName}}
                                                </div>
                                            </div>

                                            <ejs-tab id="element">
                                                <div class="e-tab-header">
                                                    <div>Slot message web </div>
                                                    <div>Slot message app</div>
                                                </div>
                                                <div class="e-content mt-3">
                                                    <div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">App type <span
                                                                    class="asterisk">*</span></label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="WebAppType" id="AppType" autocomplete="off"
                                                                readonly />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Pre message</label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="address.SlotMessage[0].PreMsg"
                                                                id="PreMessage" autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Pre message type</label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="address.SlotMessage[0].PreMsgType"
                                                                id="PreMessageType" autocomplete="off" />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Main message <span
                                                                    class="asterisk">*</span></label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="address.SlotMessage[0].MainMsg"
                                                                id="MainMessage" autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Display message <span
                                                                    class="asterisk">*</span></label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="address.SlotMessage[0].DisplayMessage"
                                                                id="WebDisplayMsg" autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Response type <span
                                                                    class="asterisk">*</span></label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="address.SlotMessage[0].ResponseType"
                                                                id="ResponseType" autocomplete="off" />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Post message</label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="address.SlotMessage[0].PostMsg"
                                                                id="PostMessage" autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Post message type</label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="address.SlotMessage[0].PostMsgType"
                                                                id="PoseMessageType" autocomplete="off" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">App type <span
                                                                    class="asterisk">*</span></label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="AppType" id="AppType" autocomplete="off"
                                                                readonly />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Pre message</label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="address.SlotMessage[1].PreMsg"
                                                                id="PreMessage" autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Pre message type</label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="address.SlotMessage[1].PreMsgType"
                                                                id="PreMessageType" autocomplete="off" />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Main message <span
                                                                    class="asterisk">*</span></label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="address.SlotMessage[1].MainMsg"
                                                                id="MainMessage" autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Display message <span
                                                                    class="asterisk">*</span></label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="address.SlotMessage[1].DisplayMessage"
                                                                id="WebDisplayMsg" autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Response type <span
                                                                    class="asterisk">*</span></label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="address.SlotMessage[1].ResponseType"
                                                                id="ResponseType" autocomplete="off" />
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Post message</label>
                                                            <textarea class="form-control textBoxSizes "
                                                                [(ngModel)]="address.SlotMessage[1].PostMsg"
                                                                id="PostMessage" autocomplete="off"></textarea>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <label class="formLabel">Post message type</label>
                                                            <input class="form-control textBoxSizes "
                                                                [(ngModel)]="address.SlotMessage[1].PostMsgType"
                                                                id="PoseMessageType" autocomplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </ejs-tab>
                                            <div class="col-md-12 mt-3">
                                                <button title="Save" class="btn btn-primary" style="margin-right: 15px;"
                                                    (click)="returnSlotSubmitUI('returnSlotbasedOnInput',i)">Save</button>
                                            </div>
                                        </div>
                                    </div>

                                </ejs-accordion> -->
                            </div>
                        </div>
                    </ejs-tab>
                </div>
                <div class="blockFooter">
                    <div class="col-md-12 mt-3">
                        <button title="Save" class="btn btn-primary" style="margin-right: 15px;"
                            (click)="GenarteReturnSLotArrasync(_ngModelReturnSlotBasedOnInputArr)">create return
                            Slot</button>


                        <button title="Save" class="btn btn-primary" style="margin-right: 15px;"
                            (click)="mainFormSubmitforAdmin('mainGeneral', _SlotObj, _ngModelSlotmessageObj, _ngModelReturnSlotBasedOnInputArr)">Save</button>
                    </div>
                </div>
            </div>

            <div class="animatedLoaderForProperties" *ngIf="_isPropertyLoader">
                <img src="../../../assets/images/animation_loader.gif" class="loaderImg">
            </div>
        </div>

    </div>
    <div class="row pageFooterBtn">
        <div class="col-md-12">
            <button type="button" title="Save" class="btn btn-primary mr-3" (click)="saveFlowchart()">Save
                Diagram</button>
            <!-- <button type="button" title="Save" class="btn btn-primary mr-3" (click)="saveFlowchart1()">Save1</button> -->
            <button type="button" title="Download" class="btn btn-primary mr-3"
                (click)="DownloadFile()">Download</button>

            <!-- <button *ngIf="fetchRoleName === 'Admin'" type="button" title="SaveToDB" class="btn btn-primary mr-3"
                (click)="saveToDB()">Save to DB</button> -->
            <button
                *ngIf="fetchRoleName === 'Admin' && ( this._selectedRowData.IsLiveRawIntent == true || this._selectedRowData.IsModifiedRawIntent == true )"
                type="button" title="SaveToDB" class="btn btn-primary" (click)="publish()"
                [disabled]='_disablePublishBtn'>Publish</button>

            <button title="Cancel" type="button" class="btn btn-secondary"
                (click)='cancelbuttonOnConFlow()'>Cancel</button>

            <button title="Cancel" type="button" class="btn btn-secondary" (click)='ClearCompleteflow()'>Clear
                All</button>
        </div>
    </div>
</div>

<div class="animatedLoader" *ngIf="_isSaveDiagramLoader" style="left: 0;">
    <img src="../../../assets/images/animation_loader.gif" class="loaderImg">
</div>