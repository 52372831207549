import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';

import { HttpCommonReq } from '../../services/HttpCommonReq'

@Injectable({
  providedIn: 'root'
})
export class ConversationFlowService {

  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {
    this.configUrls = {
      saveConversationFlow: 'api/IntentMaint/UpdateConversationalFlow',
      publishConversationFlow: 'api/IntentMaint/PublishConversationalFlow'
    }
  }

  //save conversation flow diagram
  saveConversationFlowDiagram(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.saveConversationFlow, data)
  }

  //publish conversation flow diagram
  publishConversationFlowDiagram(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.publishConversationFlow, data)
  }
}
