import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  _isPasswordReset: boolean = false

  title = environment.pageTitle + ' - Forgot Password';
  
  constructor(private formBuilder: FormBuilder, private router: Router, private titleService:Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required, Validators.email])]
    });
  }

  onSubmit() {
    
    this._isPasswordReset = true
  }

  redirectToLogin() {
    this.router.navigateByUrl('login');
  }

}
