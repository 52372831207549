import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FailedFAQsService } from '../../services/FailedFAQs/failed-faqs.service';
import { DatePipe } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TestBotService } from '../../services/TestBot/test-bot.service'

import { Entities } from '../../test-bot/Entities'

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-failed-faqs',
  templateUrl: './failed-faqs.component.html',
  styleUrls: ['./failed-faqs.component.css'],
  providers: [DatePipe]
})


export class FailedFAQsComponent implements OnInit {

  firstDate: string;
  SecondDate: string;
  searchSessionId: string;
  searchUserId: string;
  selectedChatType: any = [];
  chatTypeArr: any = [];

  FailedFAQListArr: any = [];

  selectedIntentNamearr: any = [];

  failedHeadersArr: any = [];

  ConversationHistArr: any = [];
  _Messages: any = [];

  _latest_date: any
  _Prev_date: any
  _FormatedPrev_date: any

  first: number = 0;

  isLoader: boolean = false;

  sortColumnName: string;
  sortorder: boolean;
  descOrder: Boolean;

  myDate: any
  date: any
  closeModal: any

  toDate: any;
  fromDate: any;
  rangeDates: Date[];


  _chatTypeArr: any = []

  newFilterDataArr: any = [];
  selectedValues: any = [];
  toggle: boolean = false;
  chageFilterInput: boolean = true;
  totalSelectedValues: any;
  selectedFieldOutline: boolean = false;
  greyFieldOutline: boolean = false;

  parameters = new Entities()

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string

  _isChatHistoryBlock: boolean = false
  _fetchSessionID: any;
  _isRowSelected: boolean = false;
  _selectedRowID: any;
  _gradStartColor: any;
  _gradEndColor: any;

  filterAllFields: any
  filterArr: any = [];
  isHide: boolean = false;
  selectedAccountStatus: string = 'Failed Chat';
  selectedfield: string = 'Failed Chat';

  _calendarIcon: boolean = true;
  _closeIcon: boolean = false

  @ViewChild("userHtml", { static: false }) userHtml: any;
  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;

  _ifNoRecords: boolean = false;
  _isDateSelected: boolean = true

  _RoleName: any
  _ViewClientRoleAccess: boolean = false;

  title = environment.pageTitle + ' - Chat History';

  @ViewChild('container', { read: ElementRef }) public scrollcontainer: ElementRef<any>;
  scrollHeight: number;

  constructor(public testBotService: TestBotService, public failedFAQsService: FailedFAQsService, private datePipe: DatePipe, private modalService: NgbModal, private titleService: Title) {
    this.myDate = new Date();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.chatTypeArrList();
    ;
    this.isLoader = true;
    this.totalSelectedValues = 1;
    this.fetchCurrentDate();
    this.getFailedFAQData();
    this.FAQListHeaders();
    this.GetBotGradientColor();
    this.filterDropdown();

    this._RoleName = window.localStorage.getItem("RoleName");

    if (this._RoleName === 'ClientView' || this._RoleName === 'ContentView') {
      this._ViewClientRoleAccess = true;
    }
  }

  GetBotGradientColor() {
    this.testBotService.GetBotGradientColor().subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        var urlSafe: any
        urlSafe = res.Data.BotBackgroundWebSite;

        this._gradStartColor = res.Data.BotGradStartColor;
        this._gradEndColor = res.Data.BotGradEndColor;

      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

      }
    })
  }

  chatTypeArrList() {
    this._chatTypeArr = [
      { name: 'Successful Chat', code: 'NY' },
      { name: 'Failed & Thumbs down Chat', code: 'RMD' },
      { name: 'Failed Chat', code: 'RM' },
      { name: 'Thumbs down chat', code: 'LDN' }
    ];
  }

  FAQListHeaders() {
    this.failedHeadersArr = [
      { field: 'DispId', header: 'Sr No.', width: "4%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'abc' },
      { field: 'UserSessionId', header: 'User Session ID', width: "12%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'Section Name' },
      { field: 'MessageFrom', header: 'From User ID', width: "7%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'FAQ' },
      { field: 'Message', header: 'FAQ', width: "15%", isEditable: "true", isSort: "false", textAlignment: "left", value: 'Answer' },
      { field: 'BondCreatedOn', header: 'Mod. Dt.', width: "6%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'b' }
    ]
  }

  closePopup() {
    this.showPopup = false;
  }

  getFailedFAQData() {

    ;
    let EntitiesObj = new Entities()
    EntitiesObj.FailedFAQModel.FromDate = this._FormatedPrev_date;
    EntitiesObj.FailedFAQModel.ToDate = this._latest_date;
    EntitiesObj.FailedFAQModel.MessageFrom = "";
    EntitiesObj.FailedFAQModel.ChatType = "Failed Chat";
    this.selectedChatType.name = "Failed Chat";
    this.fromDate = this._FormatedPrev_date;
    this.toDate = this._latest_date;

    this.failedFAQsService.getFailedFAQData(EntitiesObj.FailedFAQModel).subscribe((res: any) => {
      ;
      if (res.Status.toUpperCase() == "SUCCESS") {
        this.FailedFAQListArr = res.Data;

        this.isLoader = false;
        this.sortColumnName = '';
        this.sortorder = false;

        if (this.FailedFAQListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
            this._isDateSelected = false

          }, 1000);
        } else {
          this._isDateSelected = false
          this._ifNoRecords = false
        }

      }
      else if (res.Error) {


        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = "An error occured.";

        this.triggerModal(this.modalWarningAndInfo);
      }
    })
  }

  exportFAQList() {
    const params = {
      "reverseorder": "",
      "SorColumnName": ""
    }

    this.failedFAQsService.exportFailedFAQData(params).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        var status = res.status;
        const headers = res.headers;
        const data = res.Data;
        var contentType = 'application/CSV';
        var linkElement = document.createElement('a');
        var blob = new Blob([data], { type: contentType });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';

        this.myDate = new Date();
        var myDate1 = this.datePipe.transform(this.myDate = new Date(), 'MMddyyyyHHmm');
        a.download = "FailedFaq" + myDate1 + '.csv';

        document.body.appendChild(a);
        a.click();

        this.popupImg = "../../../assets/images/download-popup.png";
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo);

      }
      else if (res.Error) {

        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = "An error occured.";

        this.triggerModal(this.modalWarningAndInfo);
      }
    })
  }
  testss: boolean = false
  msg: any = [];
  selectedDataMsg: any;

  _isthumbsDown: boolean
  getFilteredConversationHistData(data: any) {

    this._isChatHistoryBlock = true
    this._fetchSessionID = data.UserSessionId
    this._isRowSelected = true
    this._selectedRowID = data.DispId

    this._isthumbsDown = data.IsThumbsDown

    this.selectedDataMsg = data.Message

    let EntitiesObj = new Entities();
    EntitiesObj.ConversationHistoryModel.UserSessionId = data.UserSessionId;
    EntitiesObj.ConversationHistoryModel.MessageFrom = this.searchUserId;

    this.failedFAQsService.getFilteredConversations(EntitiesObj.ConversationHistoryModel).subscribe((res: any) => {
      if (res.Status = 'SUCCESS') {
        ;

        this.ConversationHistArr = res.Data
        this._Messages = this.ConversationHistArr;
        for (let i = 0; i < this._Messages.length; i++) {
          if (this._Messages[i].Relatedintent !== "" && this._Messages[i].Relatedintent !== undefined) {
            if (this._Messages[i].Relatedintent.length > 0) {

              this._Messages[i].Relatedintent = this._Messages[i].Relatedintent.split("|");

            }
          }

          this.msg.push({ "message": this._Messages[i].Message })
        }
        this.addScrollOnselectedMessage();

        this.isLoader = false

        this.sortColumnName = '';
        this.sortorder = false;

      }
      else if (res.Error) {
        ;
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
      }
    })
  }

  addScrollOnselectedMessage() {

    for (let i = 0; i < this.msg.length; i++) {
      if (i > 1 && this.selectedDataMsg === this.msg[i].message) {
        this.scrollHeight = this.scrollcontainer.nativeElement.scrollHeight;
        $("#ChatBody").animate({ scrollTop: $('#ChatBody').prop("scrollHeight") }, this.scrollHeight);
      }
    }
  }

  sortcolumn(ColumnName: any) {

    let reverse = this.sortColumnName === ColumnName ? !this.descOrder : undefined;

    if (reverse === undefined)
      reverse = false;

    this.sortColumnName = ColumnName;
    this.descOrder = reverse;

    if (this.descOrder == true)
      this.sortorder = false;
    else
      this.sortorder = true;
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  fetchDateRange(selectedDates: any) {

    this._calendarIcon = false;
    this._closeIcon = true


    this.fromDate = this.datePipe.transform(selectedDates[0], "yyyy-MM-dd");
    this.toDate = this.datePipe.transform(selectedDates[1], "yyyy-MM-dd");
    if (this.fromDate !== null && this.toDate !== null) {

      const event = {
        keyCode: 13
      }
      this.searchData(event, "Search");
    }
  }

  date1: any

  clearInputField(clearData: any) {

    this._calendarIcon = true;
    this._closeIcon = false


    this.rangeDates = clearData

    this.rangeDates = []
    setTimeout(() => {
      this.isLoader = true
    }, 500);
    this.getFailedFAQData()
    this.fetchDateRange(this.rangeDates)
    window.location.reload();
  }

  fetchCurrentDate() {
    this.date = new Date();
    this._latest_date = this.datePipe.transform(this.date, 'yyyy-MM-dd');

    this._Prev_date = new Date();
    this._Prev_date.setDate(this._Prev_date.getDate() - 1);
    this._FormatedPrev_date = this.datePipe.transform(this._Prev_date, 'yyyy-MM-dd');

  }

  searchData(event: any, actionName: any) {

    this.selectedIntentNamearr = [];

    if (event.keyCode === 13) {
      this.isLoader = true;

      var searchEntities = new Entities();

      this.searchUserId = this.searchUserId === undefined ? "" : this.searchUserId.trim();

      searchEntities.FailedFAQModel.FromDate = this.fromDate;
      searchEntities.FailedFAQModel.ToDate = this.toDate;
      searchEntities.FailedFAQModel.ChatType = this.selectedChatType.name;
      searchEntities.FailedFAQModel.MessageFrom = this.searchUserId;

      this.getfilterDatafromArray();

      searchEntities.FailedFAQModel.ChatType = this.selectedAccountStatus;

      if (actionName.toUpperCase() === "SEARCH") {
        this.getFilteredFailedFAQlist(searchEntities.FailedFAQModel);
      }
      else if (actionName.toUpperCase() === "EXPORT") {
        this.exportCSV(searchEntities.FailedFAQModel);
      }
    }
  }

  getFilteredFailedFAQlist(searchEntities: any) {
    ;

    this._ifNoRecords = false

    this.failedFAQsService.getFailedFAQData(searchEntities).subscribe((res: any) => {
      if (res.Status = 'SUCCESS') {
      
        this.FailedFAQListArr = res.Data
        this.isLoader = false;
        this.toggle = false;

        this.sortColumnName = '';
        this.sortorder = false;

        this._isDateSelected = false

        if (this.FailedFAQListArr.length == 0) {
          ;
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        } else {
          this._ifNoRecords = false
        }

      }
      else if (res.Error) {
        ;
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
      }
    })
  }

  exportCSV(searchObj: any) {

    ;
    this.parameters.FailedFAQModel.FromDate = searchObj.FromDate
    this.parameters.FailedFAQModel.ToDate = searchObj.ToDate
    this.parameters.FailedFAQModel.ChatType = searchObj.ChatType
    this.parameters.FailedFAQModel.MessageFrom = searchObj.MessageFrom

    this.failedFAQsService.exportFailedFAQData(this.parameters.FailedFAQModel).subscribe((res: any) => {
      ;
      if (res.Status.toUpperCase() == "SUCCESS") {
        var status = res.status;
        this.isLoader = false;
        const headers = res.headers;
        const data = res.Data;
        var contentType = 'application/CSV';
        var linkElement = document.createElement('a');
        var blob = new Blob([data], { type: contentType });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';

        this.myDate = this.datePipe.transform(this.myDate = new Date(), 'MMddyyyyHHmm');
        a.download = "ChatHistory_" + this.myDate + '.csv';

        document.body.appendChild(a);
        a.click();

        this.popupImg = "../../../assets/images/download-popup.png";
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo);


      }
      else if (res.Error) {
        this.isLoader = false;
        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo);
      }
    })
  }

  AfterCLickExport() {
    ;
    const event = {
      keyCode: 13
    }
      ;
    this.searchData(event, "Export")
  }

  closeChat() {
    this._isChatHistoryBlock = false
  }

  onClickedOutside(e: any) {
    this.toggle = false;
  }

  clickEvent() {
    this.selectedFieldOutline = !this.selectedFieldOutline
    this.toggle = !this.toggle
  }

  changeStyle() {
    this.greyFieldOutline = !this.greyFieldOutline
  }

  clearInputFieldC() {
    ;
    this.selectedValues = []
    this.filterAllFields = []
    this.isHide = true

    this.filterDropdown();
    this.chageFilterInput = false

    const event = { "keyCode": 13 }
      ;
    this.searchData(event, "Search");
  }
  filterDropdown() {
    this.filterArr = [
      {
        label: 'Account Status', value: 'accountstatus',
        items: [
          { name: 'Successful Chat', code: 'accountstatus', status: 'NY', checked: false },
          { name: 'Failed & Thumbs down Chat', code: 'accountstatus', status: 'RMD', checked: false },
          { name: 'Failed Chat', code: 'accountstatus', status: 'RM', checked: false },
          { name: 'Thumbs down chat', code: 'accountstatus', status: 'LDN', checked: false }
        ]

      }
    ];
  }


  selectFilterOption(details: any) {
    this.isLoader = true
    this._ifNoRecords = false

    if (this.selectedAccountStatus == '') {
      this.selectedAccountStatus = "Failed Chat";
    }
    this.isHide = false


    if (details.checked == false) {
      details.checked = true;
      this.selectedAccountStatus = "Failed Chat"
    }

    if (details.checked == true) {
      this.chageFilterInput = true

      var d = this.selectedValues.find((x: { code: string; }) => x.code == details.code)

      if (d) {
        this.dropSkill(d)
        this.selectedValues.push(details)
      }

      if (!d) {
        this.selectedValues.push(details)
      }
    }
    else {
      this.selectedValues.forEach((value: any, id: any) => {
        if (value.status == details.status)
          this.selectedValues.splice(id, 1)
        this.chageFilterInput = true
      });
    }

    this.totalSelectedValues = this.selectedValues.length
    if (this.totalSelectedValues === 0) {
      this.chageFilterInput = false
      this.isHide = true
    }

    this.filterAllFields = this.selectedValues;

    const event = { "keyCode": 13 }
      ;
    this.searchData(event, "Search");

  }


  dropSkill(index: any) {
    ;
    this.selectedValues.forEach((value: any, id: any) => {
      if (value.status == index.status)
        this.selectedValues.splice(id, 1)
    });

    let indexMain = this.filterArr[0].items.indexOf(index);

    if (indexMain === -1) {
      indexMain = this.filterArr[1].items.indexOf(index);

      if (indexMain === -1) {
        indexMain = this.filterArr[2].items.indexOf(index);

        if (indexMain === -1) {
        }
        else {
          const dataMain = this.filterArr[2].items.filter(function (x: any) { return x.status === index.status })[0];

          dataMain.checked = false;

          this.filterArr[2].items[indexMain] = dataMain

        }
      }
      else {
        const dataMain = this.filterArr[1].items.filter(function (x: any) { return x.status === index.status })[0];

        dataMain.checked = false;

        this.filterArr[1].items[indexMain] = dataMain

      }
    }
    else {
      const dataMain = this.filterArr[0].items.filter(function (x: any) { return x.status === index.status })[0];

      dataMain.checked = false;

      this.filterArr[0].items[indexMain] = dataMain

    }

    this.filterAllFields = this.selectedValues;

    const event = { "keyCode": 13 }
      ;
  }


  private getfilterDatafromArray() {
    ;
    this.selectedAccountStatus = "Failed Chat";

    if (this.filterAllFields != undefined) {

      for (let i = 0; i < this.filterAllFields.length; i++) {
        if (this.filterAllFields[i].code.toUpperCase() == "ACCOUNTSTATUS") {
          this.selectedAccountStatus = this.filterAllFields[i].name;
        }
        if (this.filterAllFields[i].checked == false) {

          this.filterAllFields[i].checked = true;
        }

      }

    }
  }

}
