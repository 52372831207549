import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';

import { HttpCommonReq } from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})
export class UsageDashboardService {

  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {
      getBotInteractions: 'api/BotDashboard/GetBotInteractions',
      getAxisValues: 'api/BotDashboard/GetAxisValues',
      ExportDashboardData: 'api/BotDashboard/ExportDashboardData'
    }
  }

  getBotInteractions(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.getBotInteractions, data)
  }

  getAxisValues(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.getAxisValues, data)
  }

  ExportDashboardData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.ExportDashboardData, data)
  }
}
