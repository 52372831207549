import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table-template',
  templateUrl: './table-template.component.html',
  template: '<app-faqlist></app-faqlist>',
  styleUrls: ['./table-template.component.css']
})

export class TableTemplateComponent implements OnInit {

  @Input() inputVariable: any;

  constructor(@Inject(Router) private router: Router) { }
  ngOnInit(): void {
  }
}
