import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    template: ''
})

export class ConfusionMatrixEntity {
    Question: any = "";
    ExpectedIntent: any = "";
    PredictedIntent: any = "";
    PredictedIntentConfidence: any = "";
    ConfidanceFrom: any = "";
    ConfidanceTo: any = "";
    ModelName: string = "";
}