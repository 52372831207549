import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {  ClickableFlowService} from '../../services/ClickableFlow/clickable-flow.service'
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-clickable-flow',
  templateUrl: './clickable-flow.component.html',
  styleUrls: ['./clickable-flow.component.css']
})
export class ClickableFlowComponent implements OnInit {

  _showAddEditClickableFlowScreen: any = false
  _ifNoRecords: boolean = false

  ClickableFlowListArr: any = [];
  clickableFlowHeadersArr: any = [];

  _selectedClickableFlowById: any = [];
  isLoader: boolean = true;
  sortColumnName: string;
  sortorder: boolean;
  descOrder: Boolean;

  popupImg: string;
  popupContent: string;
  popupContentError: string
  closeModal: any

  PageName: any

  _isSaveDiagramLoader: boolean = false

  first: number = 0;

  ShowFaqListdata: any = window.localStorage.setItem("showClickableFlowlist", this._showAddEditClickableFlowScreen)

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;
  @ViewChild("modalDataDelete", { static: false }) public modalDataDelete: NgbModal;

  title = environment.pageTitle + ' - Clickable Flow';

  constructor(public clickableFlowService: ClickableFlowService, private modalService: NgbModal, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.getAllClickableFlowData()
    this.ClickableFlowListHeaders()
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  addNewRow(ButtonName: any) {
    this.PageName = ButtonName
    this._showAddEditClickableFlowScreen = true
    window.localStorage.setItem("showClickableFlowlist", this._showAddEditClickableFlowScreen)
  }

  ClickableFlowListHeaders() {
    this.clickableFlowHeadersArr = [
      { field: 'ClickableFlowsMasterID', header: 'Sr No.', width: "4%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'ContextName', header: 'Context', width: "26%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'BondModifiedOn', header: 'Mod. Dt.', width: "4%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'b' }
    ]
  }

  getAllClickableFlowData() {
    // debugger

    this.clickableFlowService.getAllClickableFlowData().subscribe((res: any) => {
      
      if (res.Status.toUpperCase() == "SUCCESS") {
// debugger
        this.ClickableFlowListArr = res.Data;
        this.isLoader = false;
        this.sortColumnName = '';
        this.sortorder = false;

        if (this.ClickableFlowListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        } else {
          this._ifNoRecords = false
        }

      } else if (res.Error) {

        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }

    })

  }

  sortcolumn(ColumnName: any) {

    let reverse = this.sortColumnName === ColumnName ? !this.descOrder : undefined;

    if (reverse === undefined)
      reverse = false;

    this.sortColumnName = ColumnName;
    this.descOrder = reverse;

    if (this.descOrder == true)
      this.sortorder = false;
    else
      this.sortorder = true;
  }

  _QuestionTodelete: any
  _deleteFlowData: any

  OpenDeletePopup(data: any): void {
    
    this._QuestionTodelete = data.FlowName
    this._deleteFlowData = data
    this.triggerModal(this.modalDataDelete);
  }

  showPopup: boolean = false

  deleteCFRecord(vDeleteFlowData: any) {

    

    this.clickableFlowService.DeleteClickableFlows(vDeleteFlowData).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        this.isLoader = true

        this.sortColumnName = '';
        this.sortorder = false;

        this.popupImg = "../../../assets/images/delete-popup.png";
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo);

        this.getAllClickableFlowData();

      }
      else if (res.Error) {
        this.isLoader = true

        this.showPopup = false
        this.popupImg = "../../../assets/images/Delete-popup-error.png";
        this.popupContent = "Oops!";

        this.popupContentError = res.Error;
      }
    })
  }

  OnAddEditBtnClick(vClickBtnName: string, vEditRowId: string) {
    
    this._isSaveDiagramLoader = true
    this.PageName = vClickBtnName
    var data = {
      ClickableFlowsMasterID: vEditRowId
    }
    this.clickableFlowService.GetClickableFlowsByID(data).subscribe((res: any) => {
      
      if (res.Status.toUpperCase() == "SUCCESS") {
        
        this._selectedClickableFlowById = res.Data
        this._isSaveDiagramLoader = false
        this._showAddEditClickableFlowScreen = true
        window.localStorage.setItem("showClickableFlowlist", this._showAddEditClickableFlowScreen)
      } else if (res.Error) {
      }
    })
  }

  AddEditPageResult(SuccVal: any) {
    this.getAllClickableFlowData()
    this._showAddEditClickableFlowScreen = false
  }
}
