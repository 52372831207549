import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';

import { HttpCommonReq } from '../../services/HttpCommonReq'

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {

      getFaqList: 'api/IntentMaint/GetAllRawIntents',
      SearchFaqList: 'api/IntentMaint/SearchFAQListData',
      getFaqRecord: 'api/IntentMaint/GetRawIntentsByID',
      updateFaqData: 'api/IntentMaint/UpdateIntentDetails',
      addFaqData: 'api/IntentMaint/InsertIntentDetails',
      deleteFaqData: 'api/IntentMaint/DeleteIntentDetails',
      createMainData: 'api/IntentMaint/GenerateMultipleUtterances',
      applylemmatization: 'api/IntentMaint/ApplyLemmatization',
      applyLookups: 'api/IntentMaint/ApplyLookupTableToNLU',
      removeLookups: 'api/IntentMaint/RemoveLookup',
      ExportFAQData: 'api/IntentMaint/ExportCSVFile',
      UpdateModelForSec: 'api/IntentMaint/UpdateModelForSections',
      DistinctSectionName: 'api/IntentMaint/GetDistinctSectionName',
      getAllBuildActivity: 'api/IntentMaint/GetAllBuildActivity',
      Check_InProgressActivity: 'api/IntentMaint/Check_InProgressActivity',
      GetBuildActivityForProgressBar: 'api/IntentMaint/GetBuildActivity',
      InsertBuildModel: 'api/IntentMaint/InsertBuildModelDetails',
      UpdateBuildModel: 'api/IntentMaint/UpdateBuildModelDetails',
      GetTestResultData: 'api/IntentMaint/GetTestResult',
      GetBuildTestingResult : 'api/IntentMaint/GetBuildTestingResult',
      getBuildModelStatus: 'api/IntentMaint/getBuildModelStatus',

      getDistinctFAQDatalst: 'api/IntentMaint/GetDistinctFAQData',
      UpdateSingleWords: 'api/IntentMaint/UpdateSingleWords',
      UpdateRelatedIntents: 'api/IntentMaint/UpdateRelatedIntents',
      addNewMultipleutterance: 'api/IntentMaint/InsertIntentMessagesDetails',
      ModifyMultipleutterance: 'api/IntentMaint/UpdateIntentMsg',
      DeleteMultipleutterance: 'api/IntentMaint/DeleteIntentMessageUtterance',
      getFAQStatusList: 'api/IntentMaint/GetFAQStatusList',
      checkModifyChangeORNOT: 'api/IntentMaint/checkModifyChangeORNOT',
      getTestResults: 'api/IntentMaint/GetTestResult',
      GetRawIntentByName: 'api/IntentMaint/GetRawIntentByName',

      // For default Intent
      GetAllIntents: 'api/IntentMaint/GetAllIntents',
      UpdateAppsettingValue: 'api/IntentMaint/UpdateAppsettingValue',
      UpdateIntentValue: 'api/IntentMaint/UpdateIntentValue',
      GetDefaultIntentCountValue: 'api/IntentMaint/GetDefaultIntentCountValue',// 
      GetIntentDetails: 'api/IntentMaint/GetIntentDetails',
      

      //for Intent List
      GetIntentByUsingSampleRes: 'api/Intent/GetIntentByUsingSampleRes',
      UpdateSampleResIntents: 'api/Intent/UpdateSampleResIntents',
      DeleteSampleResIntents: 'api/Intent/DeleteSampleResIntents'
    }
  }

  getTestResult(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.getTestResults, data)
  }

  getAllFaqRecords(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.getFaqList, data)
  }


  GetRawIntentByName(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.GetRawIntentByName, data)
  }

  fetchSingleFaqRecord(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.getFaqRecord, data)
  }

  updateSingleFaqRecord(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.updateFaqData, data)
  }

  addFaqRecord(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.addFaqData, data)
  }

  deleteFaqRecord(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.deleteFaqData, data)
  }

  searchFaqRecord(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.SearchFaqList, data)
  }

  createMainData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.createMainData, data)
  }

  applyLemmatization(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.applylemmatization, data)
  }

  applyLookups(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.applyLookups, data)
  }

  removeLookups(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.removeLookups, data)
  }

  ExportFAQData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.ExportFAQData, data)
  }

  UpdateModelForSections(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.UpdateModelForSec, data)
  }

  getDistinctSections() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.DistinctSectionName)
  }

  GetAllBuildActivity() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.getAllBuildActivity)
  }

  Check_InProgressActivity() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.Check_InProgressActivity)
  }

  GetBuildActivityForProgressBar() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.GetBuildActivityForProgressBar)
  }

  InsertBuildModelDetails(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.InsertBuildModel, data)
  }

  UpdateBuildActivityStatus(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.UpdateBuildModel, data)
  }

  GetTestResult(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.GetTestResultData, data)
  }

  GetBuildTestingResult(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.GetBuildTestingResult, data)
  }


  getBuildModelStatus() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.getBuildModelStatus)
  }

  getDistinctFAQData() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.getDistinctFAQDatalst)
  }

  UpdateSingleWord(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.UpdateSingleWords, data)
  }

  UpdateRelatedIntents(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.UpdateRelatedIntents, data)
  }

  deleteSingleMultipleUtterance(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.DeleteMultipleutterance, data)
  }

  GetFAQStatusList() {
    var data = {
    }
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.getFAQStatusList, data)
  }

  CheckModifyChangeORNOT(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.checkModifyChangeORNOT, data)
  }


  GetAllIntents() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.GetAllIntents)
  }

  UpdateAppsettingValue(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.UpdateAppsettingValue, data)
  }

  UpdateIntentValue(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.UpdateIntentValue, data)
  }


  GetDefaultIntentCountValue(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.GetDefaultIntentCountValue, data)
  }


  //Intent - list
  GetIntentByUsingSampleRes(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.GetIntentByUsingSampleRes, data)
  }

  UpdateSampleResIntents(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.UpdateSampleResIntents, data)
  }

  DeleteSampleResIntents(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.DeleteSampleResIntents, data)
  }
}
