import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AnnotationConstraints, ConnectorModel, ContextMenuSettingsModel, Diagram, DiagramBeforeMenuOpenEventArgs, DiagramComponent, DiagramConstraints, GridlinesModel, MarginModel, NodeConstraints, NodeModel, PaletteModel, ShapeAnnotationModel, SnapSettingsModel, SymbolInfo } from '@syncfusion/ej2-angular-diagrams';
import { ClickEventArgs, ExpandMode, MenuEventArgs } from '@syncfusion/ej2-angular-navigations';

import { Nodes, ContextFlowEntity, Slot, ReturnSlotBasedOnInput, Slotmessage } from '../context-flow/ContextFlowEntity'
import { FaqService } from '../../services/faq/faq.service';

import { ContextFlowService } from '../../services/ContextFlow/context-flow.service';
import { Router } from '@angular/router';
import { faqEntities } from '../fqaentities';

import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
// import { ConvFlowEntity, Slotmessage } from '../conv-design-flow/ConvFlowEntity';

@Component({
  selector: 'app-add-context-flow',
  templateUrl: './add-context-flow.component.html',
  styleUrls: ['./add-context-flow.component.css']
})
export class AddContextFlowComponent implements OnInit {

  @ViewChild('diagram')
  public diagram!: DiagramComponent;

  @ViewChild('viewdiagram')
  public viewdiagram!: DiagramComponent;

  public constraints: AnnotationConstraints;
  public nodeConstraints: NodeConstraints;
  public diagramConstraints: DiagramConstraints;

  public contextMenuSettings!: ContextMenuSettingsModel
  private interval: number[] = [1, 9, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25,
    9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75];
  private horizontalGridlines: GridlinesModel = { lineColor: '#e0e0e0', lineIntervals: this.interval };
  private verticalGridlines: GridlinesModel = { lineColor: '#e0e0e0', lineIntervals: this.interval };
  public snapSettings: SnapSettingsModel = { horizontalGridlines: this.horizontalGridlines, verticalGridlines: this.verticalGridlines };

  diagramArr: any = []
  diagramArrJson: any
  nodesArr: any
  connectorsArr: any
  public nodes: NodeModel[] = []
  public connectors: ConnectorModel[] = []
  selectedItem: NodeModel | ConnectorModel;

  isPropertyBlock: boolean = false
  isCreateFlowchart: boolean = true

  mainArr: any = []
  nodeObjArr: any = []

  _NodeObj = new Nodes();
  _ContextFlowEntityObj = new ContextFlowEntity()

  @Input() inputVariable: any;
  _inputData: any
  _selectedRowData: any;

  _isSaveDiagramLoader: boolean = false
  checkDiagram: any
  flowName: any
  parentNodeLabel: any

  popupImg: string;
  popupContent: string;
  popupContentError: string
  closeModal: any

  _isWhiteSpace: boolean = false
  _isSlotNameErrorFlag: boolean = false
  _isQuestionErrorFlag: boolean = false
  _isAnswerErrorFlag: boolean = false
  _isEmptyNodeName: boolean = false
  _isNodesNotconnected: boolean = false
  _isNodeConnectedToParentNode: boolean = false

  @Output() inputdata: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;

  public layout: Object = {

    //Set the layout type

    type: 'HierarchicalTree'

  };

  constructor(public faqconfigservice: FaqService, public contextFlowService: ContextFlowService, private router: Router, private modalService: NgbModal) { }

  ngOnInit(): void {
    //Enables the context menu
    this.contextMenuSettings = {
      //Enables the context menu
      show: true,
      items: [
        {
          text: 'Properties', id: 'Properties', target: '.e-diagramcontent'
        },
        {
          text: 'Delete Node', id: 'delete', target: '.e-diagramcontent'
        }
      ],
      // Hides the default context menu items
      showCustomMenuOnly: true,
    }



    this._inputData = this.inputVariable
    this._selectedRowData = this._inputData.selectedRowData
    this.parentNodeLabel = this._selectedRowData.Questions



    if (this._selectedRowData.FlowDiagramJSON) {
      this.fetchNodesAndConnectorsFromDB();
    }
  }
  public parentNode: NodeModel = {
    width: 140,
    height: 60,
    shape: {
      cornerRadius: 10
    }
  };

  public getSymbolInfo(symbol: NodeModel): SymbolInfo {
    return { fit: true };
  }

  public getSymbolDefaults(symbol: NodeModel): void {
    if (symbol.id === 'Terminator' || symbol.id === 'Process') {
      symbol.width = 80;
      symbol.height = 40;
    } else if (symbol.id === 'Decision' || symbol.id === 'Document' || symbol.id === 'PreDefinedProcess' ||
      symbol.id === 'PaperTap' || symbol.id === 'DirectData' || symbol.id === 'MultiDocument' || symbol.id === 'Data') {
      symbol.width = 50;
      symbol.height = 40;
    } else {
      symbol.width = 50;
      symbol.height = 50;
    }
    // symbol.style.strokeColor = '#757575';
    // symbol.style?.strokeColor= String
  }

  //SymbolPalette Properties
  public symbolMargin: MarginModel = { left: 15, right: 15, top: 15, bottom: 15 };
  public expandMode: ExpandMode = 'Multiple';
  public enableAnimation: any = true;
  //Initialize the flowshapes for the symbol palatte
  private flowshapes: NodeModel[] = [
    {
      id: 'process', shape: {
        type: 'Basic',
        shape: 'Rectangle',
        cornerRadius: 10
      }
    }
  ];

  private connectorSymbols: ConnectorModel[] = [
    {
      id: 'Link1', type: 'Orthogonal', sourcePoint: { x: 0, y: 0 }, targetPoint: { x: 40, y: 40 },
      targetDecorator: { shape: 'Arrow', style: { strokeColor: '#757575', fill: '#757575' } }, style: { strokeWidth: 2, strokeColor: '#757575' }
    }
  ];

  public palettes: PaletteModel[] = [
    { id: 'flow', expanded: true, symbols: this.flowshapes, iconCss: 'shapes', title: 'Flow Shapes' },
    { id: 'connectors', expanded: true, symbols: this.connectorSymbols, iconCss: 'shapes', title: 'Connectors' }
  ]

  public getConnectorDefaults(obj: ConnectorModel) {
    obj.style = {
      strokeColor: '#6BA5D7',
      fill: '#6BA5D7',
      strokeWidth: 2
    }
    obj.targetDecorator = {
      style: {
        fill: '#6BA5D7',
        strokeColor: '#6BA5D7'
      }
    }
  }

  public create(args: Object): void {
    this.diagram.fitToPage();
    // this.diagram.connectors[0].sourcePoint?.x = 150;
    // this.diagram.connectors[0].targetPoint.x = 150;
    // this.diagram.connectors[0].sourceID: 
    this.diagram.dataBind();
  }

  public created(args: Object): void {

    
    //Add Node
    this.diagram.add(this.parentNode);

    let annotation: ShapeAnnotationModel[] = [{
      id: 'label1',
      content: this.parentNodeLabel,
      constraints: AnnotationConstraints.ReadOnly
    }]

    this.diagram.addLabels(this.diagram.nodes[0], annotation);
    this.diagram.dataBind();


    var firstNodeID: any
    firstNodeID = this.diagram.nodes[0].id

    var nodeObj = new Nodes();
    nodeObj.NodeID = firstNodeID
    nodeObj.Question = this._selectedRowData.Questions
    nodeObj.Answer = this._selectedRowData.DisplayMessage
    nodeObj.SlotName = "Slot_FirstSlot"
    this.nodeObjArr.push(nodeObj)
  }

  //Default setting for nodes
  public nodeDefaults(obj: NodeModel): NodeModel {

    let node: NodeModel = {
      // Sets the annotation for the node
      annotations: [{
        //Sets the constraints as Read only
        constraints: AnnotationConstraints.ReadOnly
      }]

    };

    node.height = 60;

    node.width = 140;

    node.offsetX = 300;

    return node;

  }

  nextSlotName: any = []
  selectedNodeID: any
  //Context Menu
  public contextMenuOpen(args: DiagramBeforeMenuOpenEventArgs): void {


    this.isPropertyBlock = false

    if (this.isCreateFlowchart == false) {
      this.checkDiagram = this.viewdiagram
    } else {
      this.checkDiagram = this.diagram
    }

    let selectedNode: NodeModel = this.checkDiagram.selectedItems.nodes![0];

    //Check data exist
    if (selectedNode) {


      let connectorDataArr: any = []
      this.nextSlotName = []
      for (let i = 0; i < this.connectors.length; i++) {
        if (this.selectedNodeID === this.connectors[i].sourceID) {

          connectorDataArr.push(this.connectors[i])
        }
      }


      for (let j = 0; j < connectorDataArr.length; j++) {
        for (let k = 0; k < this.nodes.length; k++) {
          if (connectorDataArr[j].targetID === this.nodes[k].id) {
            this.nextSlotName.push(this.nodes[k].annotations![0].content)
          }
        }
      }




      if (this.nodeObjArr.length > 0) {
        if (selectedNode.annotations!.length > 0) {
          let selectedNodeName = this.checkDiagram.selectedItems.nodes![0].annotations![0].content
          // selectedNodeName.AnnotationConstraints.ReadOnly
          if (selectedNodeName !== "") {
            let fetchSignleRecord = this.nodeObjArr.find((x: { Question: string; }) => x.Question == selectedNodeName)

            if (fetchSignleRecord) {
              this._NodeObj.SlotName = fetchSignleRecord.SlotName
              this._NodeObj.Question = fetchSignleRecord.Question
              this._NodeObj.Answer = fetchSignleRecord.Answer
            }
          }
          else {
            this._NodeObj = new Nodes()
          }
        }
        else {
          this._NodeObj = new Nodes()
        }
      }
    }

    //Following code is to remove rightclick instead of node.
    //to get a connector
    let selectedConnector: ConnectorModel = this.checkDiagram.selectedItems.connectors![0];
    if (selectedNode && selectedNode.annotations && selectedNode.annotations.length > 0) {
      //get a node annotation object
      let label = selectedNode.annotations[0];
    }

    if (selectedConnector && selectedConnector.annotations && selectedConnector.annotations.length > 0) {
      //get a connector annotation object
      let label = selectedConnector.annotations[0];
    }
    if (!(selectedNode || selectedConnector) || this.checkDiagram.selectedItems.nodes![0].annotations![1]?.content) {
      //cancel a event if it is a diagram.
      args.cancel = true;
    }
  }

  public contextMenuClick(args: MenuEventArgs): void {
    if (args.item.id === "Properties") {
      this.isPropertyBlock = true
    } else if (args.item.id === 'delete') {


      let selectedNode: NodeModel = this.checkDiagram.selectedItems.nodes![0];
      if (selectedNode.annotations!.length > 0) {
        if (this.nodeObjArr.length > 0) {
          let selectedNodeName = this.checkDiagram.selectedItems.nodes![0].annotations![0].content

          if (selectedNodeName) {
            var checkSlotExists = this.nodeObjArr.find((x: { Question: string; }) => x.Question == selectedNodeName)

            if (checkSlotExists) {
              const index = this.nodeObjArr.indexOf(checkSlotExists);
              if (index !== -1) {
                this.nodeObjArr.splice(index, 1);
                this.checkDiagram.cut();
              }
            }
          }
          else {
            this.checkDiagram.cut();
          }
        }
      } else {
        this.checkDiagram.cut();
      }
    }
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  //Save the diagram.
  saveFlowchart() {

    // this._isSaveDiagramLoader = true
    if (this.diagram) {
      this.saveDiagram(this.diagram.saveDiagram());
    }
    else if (this.viewdiagram) {
      this.saveDiagram(this.viewdiagram.saveDiagram());
    }
    // this.saveToDB();
  }

  public saveDiagram(data: string): void {
    // let dataStr: string = 'data:text/json;charset=utf-8,' + encodeURIComponent(data);
    // let a: HTMLAnchorElement = document.createElement('a');
    // a.href = dataStr;
    // a.download = 'Diagram.json';
    // document.body.appendChild(a);
    // a.click();
    // a.remove();

    // this.diagramObj = JSON.stringify(data) // added to store diagram object into DB.
    this.diagramArrJson = data
    this.diagramArr = JSON.parse(data)

    this.nodesArr = this.diagramArr.nodes
    this.connectorsArr = this.diagramArr.connectors  //targetID


    this.nodes = this.diagramArr.nodes
    this.connectors = this.diagramArr.connectors

  }

  //Fetch nodes and connectors dynamically if already saved in DB
  fetchNodesAndConnectorsFromDB() {

    
    try {
      this.flowName = this._selectedRowData.FlowName
      if (this._selectedRowData.FlowDiagramJSON != "") {
        let selectedRowFlowDiagram = JSON.parse(this._selectedRowData.FlowDiagramJSON)

        this.diagramArr = JSON.parse(this._selectedRowData.FlowDiagramJSON)
        this.diagram = JSON.parse(this._selectedRowData.FlowDiagramJSON)

        this.nodes = selectedRowFlowDiagram.nodes
        this.connectors = selectedRowFlowDiagram.connectors

        this.isCreateFlowchart = false

        
        // this.parentNodeLabel = this._selectedRowData.Questions

        // this.nodes[0].annotations[1].content=this._selectedRowData.Questions
        this.nodes[0].annotations![1].content = this._selectedRowData.Questions

      }

      if (this._selectedRowData.ConversationalFlowJSON != "") {


        let selectedRowClickableFlowsJson = JSON.parse(this._selectedRowData.ConversationalFlowJSON)

        selectedRowClickableFlowsJson.Slots.forEach((values: any, key: any) => {

          var nodeObj = new Nodes();
          if (values.Slotmessage.length != 0) {
            nodeObj.Answer = values.Slotmessage[0].DisplayMessage;
          }
          else {
            nodeObj.Answer = "";
          }
          nodeObj.Question = values.Question;
          nodeObj.SlotName = values.SlotName;
          nodeObj.NodeID = values.NodeID;

          this.nodeObjArr.push(nodeObj)

        });



        this._ContextFlowEntityObj = selectedRowClickableFlowsJson
      }
    }
    catch (Exception) {
      this.popupImg = "../../../assets/images/CommonError.png"
      this.popupContent = "Oops!"
      this.popupContentError = "Some problem on our side.";
      this.triggerModal(this.modalWarningAndInfo)
    }
  }

  onKeypressEvent(event: any) {

    if (this._NodeObj.SlotName) {
      this._isSlotNameErrorFlag = false
    }
    if (this._NodeObj.Question) {
      this._isQuestionErrorFlag = false
    }
    if (this._NodeObj.Answer) {
      this._isAnswerErrorFlag = false
    }
  }


  checkWhiteSpace(inputVal: any) {
    var res = inputVal.trim()
    var reslength = res.length
    if (reslength === 0) {
      this._isWhiteSpace = true
      // this._isFlowName = true
    } else {
      this._isWhiteSpace = false
      // this._isFlowName = false
    }
  }

  setProperties() {

    if (!this._NodeObj.SlotName) {
      this._isSlotNameErrorFlag = true
    } else if (!this._NodeObj.Question) {
      this._isQuestionErrorFlag = true
    } else if (!this._NodeObj.Answer) {
      this._isAnswerErrorFlag = true
    } else if (!this._NodeObj.SlotName || !this._NodeObj.Question || !this._NodeObj.Answer) {
      this._isSlotNameErrorFlag = true
      this._isQuestionErrorFlag = true
      this._isAnswerErrorFlag = true
    } else {
      this.checkDiagram.selectedItems.nodes![0].annotations = []
      this.selectedItem = this.checkDiagram.selectedItems.nodes![0];
      // this.selectedItem.annotations?.push({ content: this._NodeObj.Question });
      let nodeID = this.checkDiagram.selectedItems.nodes![0].id
      // let nodeLabel = this.checkDiagram.selectedItems.nodes![0].annotations![0].content
      let nodeLabel = this._NodeObj.Question
      // selectedItem.style.fill = color;
      let annotation: ShapeAnnotationModel[] = [{
        id: nodeID,
        content: nodeLabel,
        constraints: AnnotationConstraints.ReadOnly
      }]
      // this.checkDiagram.removeLabels(this.selectedItem, annotation);
      this.checkDiagram.addLabels(this.selectedItem, annotation);
      this.saveFlowchart();

      // check node alredy exist if exist ..update it otherwise push it
      this._NodeObj.NodeID = nodeID

      var checkSlotExists = this.nodeObjArr.find((x: { NodeID: string; }) => x.NodeID == this._NodeObj.NodeID)

      if (checkSlotExists) {
        const index = this.nodeObjArr.indexOf(checkSlotExists);
        if (index !== -1) {
          this.nodeObjArr[index] = this._NodeObj;
        }
      }
      else {
        this.nodeObjArr.push(this._NodeObj)
      }

      this._NodeObj = new Nodes()

      this.isPropertyBlock = false
    }
  }

  saveMainDiagram() {

    
    this.saveFlowchart();
    for (let i of this.nodesArr) {
      if (i.annotations[0].content == "") {
        this._isEmptyNodeName = true
      } else {
        this._isEmptyNodeName = false
      }
    }
    if (this.connectorsArr.length > 0) {
      for (let i of this.connectorsArr) {
        if (i.sourceID == "" && i.targetID == "") {
          this._isNodesNotconnected = true
        } else {
          this._isNodesNotconnected = false
        }

        if (i.targetID == this.nodesArr[0].id) {
          this._isNodeConnectedToParentNode = true
        } else {
          this._isNodeConnectedToParentNode = false
        }
      }
    }
    if (this.nodeObjArr.length == 0) {
      this.popupImg = "../../../assets/images/update_error.png"
      this.popupContent = "Oops!"
      this.popupContentError = "Please create a flow diagram.";
      this.triggerModal(this.modalWarningAndInfo)
    } else if (this._isNodeConnectedToParentNode == true) {
      this.popupImg = "../../../assets/images/update_error.png"
      this.popupContent = "Oops!"
      this.popupContentError = "All the nodes should be placed below <b>" + this.parentNodeLabel + "</b> in the hierarchy";
      this.triggerModal(this.modalWarningAndInfo)
    } else if (this.connectorsArr.length == 0 || this._isNodesNotconnected == true) {
      this.popupImg = "../../../assets/images/update_error.png"
      this.popupContent = "Oops!"
      this.popupContentError = "You have to link the nodes by arrow to complete the diagram.";
      this.triggerModal(this.modalWarningAndInfo)
    } else if (this._isEmptyNodeName == true) {
      this.popupImg = "../../../assets/images/update_error.png"
      this.popupContent = "Oops!"
      this.popupContentError = "Please set intents for the nodes by right clicking on the node and selecting <b>Properties</b> option";
      this.triggerModal(this.modalWarningAndInfo)
    } else {

      //faqEntity - 

      if (this.nodeObjArr.length != 0) {

        this._selectedRowData.SlotName = this.nodeObjArr[0].SlotName
        this._selectedRowData.IsEndOfConversation = false
      }

      this._ConvFlowEntityObj = new ContextFlowEntity();
      this._ConvFlowEntityObj.IntentName = this._selectedRowData.Intent;
      this._ConvFlowEntityObj.PrimaryQuestion = this._selectedRowData.Questions;
      this._ConvFlowEntityObj.UseCaseName = this._selectedRowData.SectionName;

      var cnt: number = 1
      this.nodeObjArr.forEach((value: any, key: any) => {
        let connectorDataArr: any = []
        this.nextSlotName = []
        for (let i = 0; i < this.connectors.length; i++) {
          if (value.NodeID === this.connectors[i].sourceID) {

            connectorDataArr.push(this.connectors[i])
          }
        }
        for (let j = 0; j < connectorDataArr.length; j++) {
          for (let k = 0; k < this.nodes.length; k++) {
            if (connectorDataArr[j].targetID === this.nodes[k].id) {
              this.nextSlotName.push(this.nodes[k].annotations![0].content)
            }
          }
        }

        var SlotvaluesArr: any = []
        //for next slot
        if (this.nextSlotName.length != 0) {
          this.nextSlotName.forEach((NextSLotvalue: any, key: any) => {

            var nextSigleSlot = this.nodeObjArr.filter((x: { Question: string; }) => x.Question == NextSLotvalue)[0]
            if (nextSigleSlot) {
              SlotvaluesArr.push(nextSigleSlot.Question)
            }
          });
        }

        var slotObj = new Slot();

        slotObj.SlotName = value.SlotName;

        slotObj.SlotOrder = cnt
        slotObj.SlotValues = SlotvaluesArr
        slotObj.NodeID = value.NodeID
        slotObj.Question = value.Question
        slotObj.SlotDataType = "string"
        slotObj.DataValidation = "slotvalues",
          slotObj.DataFormat = "NA"
        slotObj.InputFormat = ""

        slotObj.NoOfAttemptsAllowed = 2
        slotObj.NoOfAttemptsOver = 0
        slotObj.AwaitMsg = ""
        slotObj.Returnslotbasedoninput = []

        slotObj.SavedUserInput = 2
        slotObj.SavedUserInput = 0
        slotObj.UserInputBox = false
        slotObj.Enablefield = ""

        var _ngModelReturnSlotBasedOnInputArr: Array<ReturnSlotBasedOnInput>
        _ngModelReturnSlotBasedOnInputArr = [];



        if (slotObj.SlotValues.length == 0) {

          var checkSlotExists = this._ConvFlowEntityObj.Slots.find((x: { SlotName: string; }) => x.SlotName == value.SlotName)

          if (checkSlotExists) {
            if (checkSlotExists.SlotValues.length != 0) {
              slotObj = checkSlotExists
            }
          }
          else {

            slotObj.SlotType = "Process";

            var SlotmessageObj = new Slotmessage();
            SlotmessageObj.AppType = "Web"
            SlotmessageObj.PreMsg = "";
            SlotmessageObj.PreMsgType = "";
            SlotmessageObj.MainMsg = value.Answer
            SlotmessageObj.DisplayMessage = value.Answer
            SlotmessageObj.ResponseType = "Text"
            SlotmessageObj.PostMsg = "";
            SlotmessageObj.PostMsgType = "";
            slotObj.Slotmessage.push(SlotmessageObj);

            var SlotmessageObj = new Slotmessage();
            SlotmessageObj.AppType = "App"
            SlotmessageObj.PreMsg = "";
            SlotmessageObj.PreMsgType = "";
            SlotmessageObj.MainMsg = value.Answer
            SlotmessageObj.DisplayMessage = value.Answer
            SlotmessageObj.ResponseType = "Text"
            SlotmessageObj.PostMsg = "";
            SlotmessageObj.PostMsgType = "";
            slotObj.Slotmessage.push(SlotmessageObj);


            //for next slot

            slotObj.EndOfConv = true
            var _ngModelReturnSlotBasedOnInput = new ReturnSlotBasedOnInput();
            _ngModelReturnSlotBasedOnInput.UserInput = "@All"
            _ngModelReturnSlotBasedOnInput.id = 1
            _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"

            _ngModelReturnSlotBasedOnInput.ReturnName = "none"

            var returnSlotmessage = new Slotmessage();
            returnSlotmessage.AppType = "Web"
            returnSlotmessage.PreMsg = "";
            returnSlotmessage.PreMsgType = "";
            returnSlotmessage.MainMsg = value.Answer
            returnSlotmessage.DisplayMessage = value.Answer
            returnSlotmessage.ResponseType = "Text"
            returnSlotmessage.PostMsg = "";
            returnSlotmessage.PostMsgType = "";
            _ngModelReturnSlotBasedOnInput.Slotmessage.push(returnSlotmessage);

            var returnSlotmessage = new Slotmessage();
            returnSlotmessage.AppType = "App"
            returnSlotmessage.PreMsg = "";
            returnSlotmessage.PreMsgType = "";
            returnSlotmessage.MainMsg = value.Answer
            returnSlotmessage.DisplayMessage = value.Answer
            returnSlotmessage.ResponseType = "Text"
            returnSlotmessage.PostMsg = "";
            returnSlotmessage.PostMsgType = "";
            _ngModelReturnSlotBasedOnInput.Slotmessage.push(returnSlotmessage);
            _ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);

            //for error slot
            var _ngModelReturnSlotBasedOnInput = new ReturnSlotBasedOnInput();
            _ngModelReturnSlotBasedOnInput.UserInput = "Error"
            _ngModelReturnSlotBasedOnInput.id = 2
            _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"
            _ngModelReturnSlotBasedOnInput.ReturnName = value.SlotName

            var returnSlotmessage = new Slotmessage();
            returnSlotmessage.AppType = "Web"
            returnSlotmessage.PreMsg = "";
            returnSlotmessage.PreMsgType = "";
            returnSlotmessage.MainMsg = value.Answer
            returnSlotmessage.DisplayMessage = value.Answer
            returnSlotmessage.ResponseType = "Text"
            returnSlotmessage.PostMsg = "";
            returnSlotmessage.PostMsgType = "";
            _ngModelReturnSlotBasedOnInput.Slotmessage.push(returnSlotmessage);

            var returnSlotmessage = new Slotmessage();
            returnSlotmessage.AppType = "App"
            returnSlotmessage.PreMsg = "";
            returnSlotmessage.PreMsgType = "";
            returnSlotmessage.MainMsg = value.Answer
            returnSlotmessage.DisplayMessage = value.Answer
            returnSlotmessage.ResponseType = "Text"
            returnSlotmessage.PostMsg = "";
            returnSlotmessage.PostMsgType = "";
            _ngModelReturnSlotBasedOnInput.Slotmessage.push(returnSlotmessage);

            _ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);

            //too may slot
            var _ngModelReturnSlotBasedOnInput = new ReturnSlotBasedOnInput();
            _ngModelReturnSlotBasedOnInput.UserInput = "tooManyAttempts"
            _ngModelReturnSlotBasedOnInput.id = 3
            _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"
            _ngModelReturnSlotBasedOnInput.ReturnName = "tooManyAttempts"

            var returnSlotmessage = new Slotmessage();
            returnSlotmessage.AppType = "Web"
            returnSlotmessage.PreMsg = "";
            returnSlotmessage.PreMsgType = "";
            returnSlotmessage.MainMsg = "Too many attempts, please try little letter."
            returnSlotmessage.DisplayMessage = "Too many attempts, please try little letter."
            returnSlotmessage.ResponseType = "Text"
            returnSlotmessage.PostMsg = "";
            returnSlotmessage.PostMsgType = "";
            _ngModelReturnSlotBasedOnInput.Slotmessage.push(returnSlotmessage);

            var returnSlotmessage = new Slotmessage();
            returnSlotmessage.AppType = "App"
            returnSlotmessage.PreMsg = "";
            returnSlotmessage.PreMsgType = "";
            returnSlotmessage.MainMsg = "Too many attempts, please try little letter."
            returnSlotmessage.DisplayMessage = "Too many attempts, please try little letter."
            returnSlotmessage.ResponseType = "Text"
            returnSlotmessage.PostMsg = "";
            returnSlotmessage.PostMsgType = "";

            _ngModelReturnSlotBasedOnInput.Slotmessage.push(returnSlotmessage);
            _ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);

            slotObj.Returnslotbasedoninput = _ngModelReturnSlotBasedOnInputArr;

          }

        }
        else {

          slotObj.SlotType = "Decision";

          var SlotmessageObj = new Slotmessage();
          SlotmessageObj.AppType = "Web"
          SlotmessageObj.PreMsg = "";
          SlotmessageObj.PreMsgType = "";
          SlotmessageObj.MainMsg = value.Answer
          SlotmessageObj.DisplayMessage = value.Answer
          SlotmessageObj.ResponseType = "List"
          SlotmessageObj.PostMsg = "";
          SlotmessageObj.PostMsgType = "";
          slotObj.Slotmessage.push(SlotmessageObj);

          var SlotmessageObj = new Slotmessage();
          SlotmessageObj.AppType = "App"
          SlotmessageObj.PreMsg = "";
          SlotmessageObj.PreMsgType = "";
          SlotmessageObj.MainMsg = value.Answer
          SlotmessageObj.DisplayMessage = value.Answer
          SlotmessageObj.ResponseType = "List"
          SlotmessageObj.PostMsg = "";
          SlotmessageObj.PostMsgType = "";
          slotObj.Slotmessage.push(SlotmessageObj);

          var Returncnt = 1

          slotObj.EndOfConv = false

          slotObj.SlotValues.forEach((Slotval: any, id: any) => {
            var _ngModelReturnSlotBasedOnInput = new ReturnSlotBasedOnInput();
            _ngModelReturnSlotBasedOnInput.UserInput = Slotval
            _ngModelReturnSlotBasedOnInput.id = Returncnt
            _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"
            var d = this.nodeObjArr.filter((x: { Question: string; }) => x.Question == Slotval)[0]
            if (d) {
              _ngModelReturnSlotBasedOnInput.ReturnName = d.SlotName
            }


            var returnSlotmessage = new Slotmessage();
            returnSlotmessage.AppType = "Web"
            returnSlotmessage.PreMsg = "";
            returnSlotmessage.PreMsgType = "";
            returnSlotmessage.MainMsg = d.Answer
            returnSlotmessage.DisplayMessage = d.Answer
            returnSlotmessage.ResponseType = "Text"
            returnSlotmessage.PostMsg = "";
            returnSlotmessage.PostMsgType = "";
            _ngModelReturnSlotBasedOnInput.Slotmessage.push(returnSlotmessage);

            var returnSlotmessage = new Slotmessage();
            returnSlotmessage.AppType = "App"
            returnSlotmessage.PreMsg = "";
            returnSlotmessage.PreMsgType = "";
            returnSlotmessage.MainMsg = d.Answer
            returnSlotmessage.DisplayMessage = d.Answer
            returnSlotmessage.ResponseType = "Text"
            returnSlotmessage.PostMsg = "";
            returnSlotmessage.PostMsgType = "";
            _ngModelReturnSlotBasedOnInput.Slotmessage.push(returnSlotmessage);

            _ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);


            Returncnt++
          })


          //for error slot
          var _ngModelReturnSlotBasedOnInput = new ReturnSlotBasedOnInput();
          _ngModelReturnSlotBasedOnInput.UserInput = "Error"
          _ngModelReturnSlotBasedOnInput.id = Returncnt;
          _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"
          _ngModelReturnSlotBasedOnInput.ReturnName = value.SlotName

          var returnSlotmessage = new Slotmessage();
          returnSlotmessage.AppType = "Web"
          returnSlotmessage.PreMsg = "";
          returnSlotmessage.PreMsgType = "";
          returnSlotmessage.MainMsg = value.Answer
          returnSlotmessage.DisplayMessage = value.Answer
          returnSlotmessage.ResponseType = "List"
          returnSlotmessage.PostMsg = "";
          returnSlotmessage.PostMsgType = "";
          _ngModelReturnSlotBasedOnInput.Slotmessage.push(returnSlotmessage);

          var returnSlotmessage = new Slotmessage();
          returnSlotmessage.AppType = "App"
          returnSlotmessage.PreMsg = "";
          returnSlotmessage.PreMsgType = "";
          returnSlotmessage.MainMsg = value.Answer
          returnSlotmessage.DisplayMessage = value.Answer
          returnSlotmessage.ResponseType = "List"
          returnSlotmessage.PostMsg = "";
          returnSlotmessage.PostMsgType = "";
          _ngModelReturnSlotBasedOnInput.Slotmessage.push(returnSlotmessage);


          _ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);

          //too may slot
          var _ngModelReturnSlotBasedOnInput = new ReturnSlotBasedOnInput();
          _ngModelReturnSlotBasedOnInput.UserInput = "tooManyAttempts"
          _ngModelReturnSlotBasedOnInput.id = Returncnt++
          _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"
          _ngModelReturnSlotBasedOnInput.ReturnName = "tooManyAttempts"

          var returnSlotmessage = new Slotmessage();
          returnSlotmessage.AppType = "Web"
          returnSlotmessage.PreMsg = "";
          returnSlotmessage.PreMsgType = "";
          returnSlotmessage.MainMsg = "Too many attempts, please try little letter."
          returnSlotmessage.DisplayMessage = "Too many attempts, please try little letter."
          returnSlotmessage.ResponseType = "Text"
          returnSlotmessage.PostMsg = "";
          returnSlotmessage.PostMsgType = "";
          _ngModelReturnSlotBasedOnInput.Slotmessage.push(returnSlotmessage);


          var returnSlotmessage = new Slotmessage();
          returnSlotmessage.AppType = "App"
          returnSlotmessage.PreMsg = "";
          returnSlotmessage.PreMsgType = "";
          returnSlotmessage.MainMsg = "Too many attempts, please try little letter."
          returnSlotmessage.DisplayMessage = "Too many attempts, please try little letter."
          returnSlotmessage.ResponseType = "Text"
          returnSlotmessage.PostMsg = "";
          returnSlotmessage.PostMsgType = "";

          _ngModelReturnSlotBasedOnInput.Slotmessage.push(returnSlotmessage);
          _ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);

          slotObj.Returnslotbasedoninput = _ngModelReturnSlotBasedOnInputArr;
        }

        var checkSlotExists = this._ConvFlowEntityObj.Slots.find((x: { SlotName: string; }) => x.SlotName == value.SlotName)

        if (checkSlotExists) {

          const index = this._ConvFlowEntityObj.Slots.indexOf(checkSlotExists);
          if (index !== -1) {
            this._ConvFlowEntityObj.Slots.splice(index, 1);
          }

        }

        this._ConvFlowEntityObj.Slots.push(slotObj);

        cnt++;
      });

      this.SaveToIntentTable(this._ConvFlowEntityObj);
      this.saveToDB(this._ConvFlowEntityObj);
    }
  }


  _ConvFlowEntityObj = new ContextFlowEntity();

  saveToDB(_ContextFlowEntityObj: ContextFlowEntity) {

    let flowdata = JSON.stringify(_ContextFlowEntityObj)
    let diagramData = JSON.stringify(this.diagramArr)

    const data = {
      SlotName: this.nodeObjArr[0].SlotName,
      IntentName: this._selectedRowData.Intent,
      ClickableFlowsJson: flowdata,
      FlowDiagramJSON: diagramData,
      Context: this._selectedRowData.Context
    }


    this.contextFlowService.saveContextFlowDiagram(data).subscribe((res: any) => {


      if (res.Status.toUpperCase() == "SUCCESS") {

        this.popupImg = "../../../assets/images/Update.png"
        this.popupContent = res.Message;
        this.popupContentError = "";
        this.triggerModal(this.modalWarningAndInfo)

        this._isSaveDiagramLoader = false
      } else if (res.Error) {
      }
    })
  }

  SaveToIntentTable(_ContextFlowEntityObj: ContextFlowEntity) {

    var resType = ""
    var sampleRes = ""
    var IsEndOfConv: boolean

    if (this.nodeObjArr.length > 1) {
      resType = "List"
      IsEndOfConv = false

      _ContextFlowEntityObj.Slots[0].SlotValues.forEach((value: any, key: any) => {
        if (sampleRes == "") { sampleRes = value }
        else { sampleRes = sampleRes + "," + value; }
      });
    }
    else {
      resType = "Text"; IsEndOfConv = true
    }

    const data = {
      SlotName: this.nodeObjArr[0].SlotName,
      Intent: this._selectedRowData.Intent,
      IsEndOfConversation: IsEndOfConv,
      SampleResponses: sampleRes,
      ResponseType: resType,
      Context: this._selectedRowData.Context
    }


    this.contextFlowService.UpdateContextRelatedInfo(data).subscribe((res: any) => {


      if (res.Status.toUpperCase() == "SUCCESS") {

        // this.popupImg = "../../../assets/images/Update.png"
        // this.popupContent = res.Message;
        // this.popupContentError = "";
        // this.triggerModal(this.modalWarningAndInfo)

        this._isSaveDiagramLoader = false
      } else if (res.Error) {
      }
    })
  }


  closePropertyBlock() {
    this.isPropertyBlock = false
    // this._isIntentNameErrorFlag = false
    // this._isSectionNameErrorFlag = false
  }

  cancelMainDiagram() {
    this.inputdata.emit("")
  }

  // fetchRIlist: any = []
  // AEparameters: any
  // fetchSingleRecordData(vIntentName: any) {

  //   this.AEparameters = new faqEntities();
  //   this.AEparameters.IntentMaintenance.IntentName = vIntentName

  //   this.faqconfigservice.GetRawIntentByName(this.AEparameters.IntentMaintenance).subscribe((res: any) => {

  //     if (res.Status.toUpperCase() == "SUCCESS") {


  //       if (res.Data.length != 0) {
  //         this.AEparameters = new faqEntities();
  //         this.AEparameters.IntentMaintenance = res.Data;

  //         this._NodeObj.RelatedIntent = this.AEparameters.IntentMaintenance.RelatedIntent;
  //         this._NodeObj.ResponceType = this.AEparameters.IntentMaintenance.ResponseType;
  //       }
  //     }
  //     else if (res.Error) {
  //       // this.popupImg = "../../../assets/images/CommonError.png";
  //       // this.popupContent = "Oops!";
  //       // this.popupContentError = res.Error;

  //       // this.triggerModal(this.modalWarningAndInfo)
  //     }
  //     // return strRelatedIntent;
  //   })
  // }


  // GenarteReturnSLotArrasync(vReturnslot?: any) {

  //   if (this._SlotObj.SlotValues.length == 0) {

  //     if (this.nextSlotName) {
  //       var _ngModelReturnSlotBasedOnInput = new ngModelReturnSlotBasedOnInput();
  //       _ngModelReturnSlotBasedOnInput.UserInput = "@All"
  //       _ngModelReturnSlotBasedOnInput.id = 1
  //       _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"

  //       var checkRetrunSlotExist = vReturnslot.find((x: { UserInput: string; }) => x.UserInput == "@All")
  //       if (!checkRetrunSlotExist) {

  //         this._ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);
  //       }
  //     }


  //     //for error
  //     var _ngModelReturnSlotBasedOnInput = new ngModelReturnSlotBasedOnInput();
  //     _ngModelReturnSlotBasedOnInput.UserInput = "Error"
  //     _ngModelReturnSlotBasedOnInput.id = 2
  //     _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"
  //     _ngModelReturnSlotBasedOnInput.ReturnName = this.selectedNodeName

  //     var checkRetrunSlotExist = vReturnslot.find((x: { UserInput: string; }) => x.UserInput == "Error")
  //     if (!checkRetrunSlotExist) {

  //       this._ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);
  //     }


  //     //for toomany
  //     if (this._SlotObj.NoOfAttemptsAllowed > 1) {

  //       var _ngModelReturnSlotBasedOnInput = new ngModelReturnSlotBasedOnInput();
  //       _ngModelReturnSlotBasedOnInput.UserInput = "tooManyAttempts"
  //       _ngModelReturnSlotBasedOnInput.id = 3
  //       _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"
  //       _ngModelReturnSlotBasedOnInput.ReturnName = "tooManyAttempts"

  //       var checkRetrunSlotExist = vReturnslot.find((x: { UserInput: string; }) => x.UserInput == "tooManyAttempts")
  //       if (!checkRetrunSlotExist) {

  //         this._ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);

  //       }
  //     }
  //     else {
  //       var checkRetrunSlotExist = vReturnslot.find((x: { UserInput: string; }) => x.UserInput == "tooManyAttempts")
  //       if (checkRetrunSlotExist) {

  //         const index = this._ngModelReturnSlotBasedOnInputArr.indexOf(checkRetrunSlotExist);
  //         if (index !== -1) {
  //           this._ngModelReturnSlotBasedOnInputArr.splice(index, 1);
  //         }
  //       }
  //     }


  //     this._ngModelReturnSlotBasedOnInputArr.forEach((vals: any, id: any) => {

  //       if (vals.UserInput.toUpperCase() == '@ALL' || vals.UserInput.toUpperCase() == 'ERROR' || vals.UserInput.toUpperCase() == 'TOOMANYATTEMPTS') {

  //       }
  //       else {
  //         this._ngModelReturnSlotBasedOnInputArr.splice(id, 1)
  //       }
  //     })



  //     this.SortRetrunSlotArray();
  //   }
  //   else {




  //     this._SlotObj.SlotValues.forEach((value: any, id: any) => {

  //       var cnt = 1
  //       var _ngModelReturnSlotBasedOnInput = new ngModelReturnSlotBasedOnInput();

  //       //add slots that define in slotValues
  //       _ngModelReturnSlotBasedOnInput.UserInput = value.trim()
  //       _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"
  //       _ngModelReturnSlotBasedOnInput.id = cnt

  //       var checkRetrunSlotExist = vReturnslot.find((x: { UserInput: string; }) => x.UserInput == value.trim())
  //       if (!checkRetrunSlotExist) {

  //         this._ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);
  //       }
  //       cnt++;
  //     })

  //     //delete All from ng array
  //     var checkRetrunSlotExistAll: any = this._ngModelReturnSlotBasedOnInputArr.find((x: { UserInput: string; }) => x.UserInput == "@All")
  //     if (checkRetrunSlotExistAll) {
  //       // const indexMainArray = this._ConvFlowEntityObj.Slots.indexOf(checkRetrunSlotExist);
  //       const index = this._ngModelReturnSlotBasedOnInputArr.indexOf(checkRetrunSlotExistAll);
  //       if (index !== -1) {
  //         this._ngModelReturnSlotBasedOnInputArr.splice(index, 1);
  //       }
  //     }

  //     //for error
  //     var _ngModelReturnSlotBasedOnInput = new ngModelReturnSlotBasedOnInput();
  //     _ngModelReturnSlotBasedOnInput.UserInput = "Error"
  //     _ngModelReturnSlotBasedOnInput.ReturnName = this.selectedNodeName
  //     _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"
  //     _ngModelReturnSlotBasedOnInput.id = 1

  //     var checkRetrunSlotExist = vReturnslot.find((x: { UserInput: string; }) => x.UserInput == "Error")
  //     if (!checkRetrunSlotExist) {

  //       this._ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);
  //     }


  //     //for toomany
  //     if (this._SlotObj.NoOfAttemptsAllowed > 1) {

  //       var _ngModelReturnSlotBasedOnInput = new ngModelReturnSlotBasedOnInput();
  //       _ngModelReturnSlotBasedOnInput.UserInput = "tooManyAttempts"
  //       _ngModelReturnSlotBasedOnInput.ReturnName = "tooManyAttempts"
  //       _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"
  //       _ngModelReturnSlotBasedOnInput.id = 2

  //       var checkRetrunSlotExist = vReturnslot.find((x: { UserInput: string; }) => x.UserInput == "tooManyAttempts")
  //       if (!checkRetrunSlotExist) {

  //         this._ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);
  //       }
  //     }


  //     this.SortRetrunSlotArray();
  //   }
  // }

  // SortRetrunSlotArray(): void {

  //   let stOrder: any = [];
  //   stOrder = this._ngModelReturnSlotBasedOnInputArr;
  //   this._ngModelReturnSlotBasedOnInputArr = [];
  //   var cnt = 1;

  //   // stOrder.forEach((value: any, key: any) => {
  //   this._SlotObj.SlotValues.forEach((value: any, id: any) => {


  //     var checkRetrunSlotExist = stOrder.find((x: { UserInput: string; }) => x.UserInput == value.trim());

  //     if (checkRetrunSlotExist) {
  //       checkRetrunSlotExist.id = cnt;
  //       this._ngModelReturnSlotBasedOnInputArr.push(checkRetrunSlotExist);
  //     }

  //     cnt++;
  //   });

  //   var checkRetrunSlotExist = stOrder.find((x: { UserInput: string; }) => x.UserInput == "@All");

  //   if (checkRetrunSlotExist) {
  //     checkRetrunSlotExist.id = cnt++;
  //     this._ngModelReturnSlotBasedOnInputArr.push(checkRetrunSlotExist);
  //   }

  //   var checkRetrunSlotExist = stOrder.find((x: { UserInput: string; }) => x.UserInput == "Error");

  //   if (checkRetrunSlotExist) {
  //     checkRetrunSlotExist.id = cnt++;
  //     this._ngModelReturnSlotBasedOnInputArr.push(checkRetrunSlotExist);
  //   }

  //   var checkRetrunSlotExist = stOrder.find((x: { UserInput: string; }) => x.UserInput == "tooManyAttempts");

  //   if (checkRetrunSlotExist) {
  //     checkRetrunSlotExist.id = cnt++;
  //     this._ngModelReturnSlotBasedOnInputArr.push(checkRetrunSlotExist);
  //   }

  // }

  _disableSaveBtn = true;
  CheckforWhiteSpace(vEvent: any) {

    if (vEvent.keyCode == 32) {
      // this._disableSaveBtn = true

      this.popupContentError = "";
      this.popupImg = "../../../assets/images/CommonError.png";
      this.popupContent = "Spaces are not allowed for Slot Name"
      this.triggerModal(this.modalWarningAndInfo)
    }
    else {
      // this._disableSaveBtn = false
    }
  }
}
