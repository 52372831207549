import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public jwtHelper: JwtHelperService) { }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('AccessToken');
    // Check whether the token is expired and return
    // true or false
    // return !this.jwtHelper.isTokenExpired(token);
    if (token != null) {
      return true;
    } else {
      return false;
    }
  }

  isAuthorized(allowedRoles: string[]): boolean {

    const userRole = localStorage.getItem('RoleName');
    ////////;
    // check if the list of allowed roles is empty, if empty, authorize the user to access the page
    if (allowedRoles == null || allowedRoles.length === 0) {
      return true;
    }

    // get token from local storage or state management
    const token = localStorage.getItem('AccessToken');

    // decode token to read the payload details
    // const decodeToken = this.jwtHelper.decodeToken(token !== null ? token: "");

    // check if it was decoded successfully, if not the token is not valid, deny access
    if (!token) {
      return false;
    }

    // check if the user roles is in the list of allowed roles, return true if allowed and false if not allowed
    return allowedRoles.includes(userRole !== null ? userRole : "");
  }

}
