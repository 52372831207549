<div id="chatbot" class="chatbot-window chatbot-div" *ngIf="ShowChat || _RoleName!='Marketing' || _RoleName!='Agent'"
    [ngClass]="{'p-chatbot-window': _pageName == 'Persona'}"
    [ngStyle]="{'background-image':'linear-gradient('+ _BotGradStartColor +', '+ _BotGradEndColor +')'}"
    (contextmenu)="onRightClick()" (keyup)="deleteonEsp($event)">
    <div class='chatbot-main' id='element' [ngClass]="{'p-chatbot-main': _pageName == 'Persona'}">

        <div class="loaderDivBlock" *ngIf="_showLoaderBlock">
            <div class="loaderBotIconBlock">
                <img *ngIf="_showLoaderImage" src='../../assets/BOND_Bot_Icon.png' id='bigBotIcon'
                    class='loaderBotIcon'>
            </div>
        </div>

        <div class='bbpanel-body bbbg-color' id='panel-body-color' *ngIf="_showPanelBody"
            [ngClass]="{'p-bbpanel-body': _pageName == 'Persona'}">
            <div class='row topbar remove-gradient-effect z-index-3' id='topbar-color'
                [ngClass]="{'d-none': _pageName == 'Persona'}">
                <div class="col-md-12">
                    <div id="topheader" class="chatBotCloseBanner topmostHeader"
                        [style]="'--topheader-bgColor:'+'linear-gradient('+ _BotGradStartColor + ' 40%' + ', '+ _BotGradEndColor + ' 644%' +')'">

                        <button *ngIf="_isOnline" class="no-border" (click)='RatingChatModels()'
                            style="outline:none!important; margin-right:2px; padding-right:1px!important;outline:none!important; padding:0px; background-color:transparent!important; border:2px!important; border-bottom-right-radius:25px !important;border-top-right-radius:25px !important;float: right;"
                            type="submit" [disabled]="_DisableTextMsg">
                            <img id='chatbotclose' class='chatbot-close' />
                        </button>

                        <button *ngIf="!_isOnline" class="no-border" (click)='closeChatBotIsOffline()'
                            style="outline:none!important; margin-right:2px; padding-right:1px!important;outline:none!important; padding:0px; background-color:transparent!important; border:2px!important; border-bottom-right-radius:25px !important;border-top-right-radius:25px !important;float: right;"
                            type="submit">
                            <img id='chatbotclose' class='chatbot-close' />
                        </button>
                    </div>
                </div>
            </div>

            <div [ngClass]="{'p-gradient-bg': _pageName == 'Persona'}"
                [style]="'--featured-bgColor:' + 'linear-gradient('+ _gradStartColor +', '+ _gradEndColor +')' ">
                <div #container class='bbchat-body panel-primary' id='ChatBody' (scroll)="onScroll($event)"
                    [ngClass]="{'p-bbchat-body': _pageName == 'Persona'}"
                    [style]="'--featured-bgColor:' + 'linear-gradient('+ _gradStartColor +', '+ _gradEndColor +')' ">

                    <dl *ngFor="let Chat of _Conversation; let i = index" id='conversations'>
                        <div id='conversations_{{Chat.Index}}'>
                            <div [style]="'--tranform:' + TransPropUserMsg ">
                                <dt *ngIf="Chat.MessageType=='UserMessages'" class='row' id='userMessages'>

                                    <div class="col-md-12">
                                        <div class='bbuser-msg bbcon-common'>
                                            <span id='userMessage'>{{Chat.Message}}</span>
                                        </div>
                                    </div>
                                </dt>
                            </div>
                            <div *ngIf="Chat.ResponseType=='GREET'">
                                <div class='bbgreet bbgreet-linear-bg' id=greet-banner
                                    [ngClass]="{'d-none': _pageName == 'Persona'}">
                                    <img src="../../assets/BOND_Bot_Icon.png"
                                        class='bbgreet-icon margin-left-33 xl-ml-0'>
                                    <dd *ngIf="Chat.MessageType=='Bot' && Chat.PreMsgType=='GREET'"
                                        class='bbgreeting greet-color margin-left-33 xl-ml-0 mb-0'>
                                        {{Chat.PreDispMessage}}
                                    </dd>
                                    <dd *ngIf="Chat.MessageType=='Bot' && Chat.ResponseType=='GREET'"
                                        class='bbgreet-message greet-color margin-left-33 xl-ml-0 mb-0'>
                                        {{Chat.Message}}
                                    </dd>
                                </div>
                            </div>
                            <div>
                                <div class='bbdefault-greet remove-gradient-effect' *ngIf="Chat.ResponseType=='GREET'">
                                    <div class="bbdefault-greet-img d-inline-block">
                                        <img src="../../assets/transparent_chatbot.png"
                                            [style]="'background-color:' + _gradStartColor "
                                            *ngIf="Chat.MessageType=='Bot' && Chat.PostMsgType=='GREET'"
                                            class='bbmessage-icon margin-bottom-m15' />
                                    </div>
                                    <div class="d-inline-block">
                                        <input type='submit' class='bbdefault-greet-msg bbcon-common' id='listbtn1'
                                            value='What can I help you with?' [disabled]="_DisableTextMsg" />
                                    </div>
                                </div>
                                <div class='bbchatIconMain disp-none' id='botMessage_{{Chat.Index}}'>

                                    <div class="remove-gradient-effect">

                                        <div class='bbchatIconChild'>
                                            <img *ngIf="Chat.BotMessageType=='Bot' && Chat.ResponseType!='GREET' "
                                                [style]="'background-color:' + _gradStartColor "
                                                src="../../assets/transparent_chatbot.png"
                                                class='float-left bbmessage-icon margin-left-10 xl-ml-0' />
                                            <dd *ngIf="Chat.BotMessageType=='Bot' &&Chat.PreMsgType=='Text'"
                                                class='bbbot-response bot-message margin-left-33'>
                                                <p [innerHtml]="Chat.PreDispMessage"></p>
                                            </dd>

                                            <dd *ngIf="Chat.PreMsgType=='List'" class='bbbot-response bot-message'>
                                                <div>{{ Chat.PreDispMessage }}</div>
                                            </dd>
                                            <dd *ngIf="Chat.PreMsgType=='IMG'" class='bbbot-response  bot-message'>
                                            </dd>
                                        </div>

                                        <dd *ngIf="Chat.BotMessageType=='Bot' && Chat.BotResponseType=='Text'"
                                            class='bbbot-response white-space-pre margin-left-33'>
                                            <!-- <p class='margin-0 bbcon-common' [innerHtml]="Chat.BotMessage"></p> -->
                                            <div>
                                                <span *ngIf='!_AOURls'>
                                                    <span *ngFor='let URLText of Chat.TEXTRespMsg; let i = index'>
                                                        <span *ngIf="URLText.containsURL===0"
                                                            [innerHtml]="URLText.key"></span>
                                                        <a target='_blank' *ngIf="URLText.containsURL===1"
                                                            [style]="'color:' + _gradEndColor " class='link-color'
                                                            href='{{URLText.value}}'>
                                                            <span [innerHtml]="URLText.key"></span>
                                                        </a>
                                                    </span>
                                                </span>

                                                <span *ngIf='_AOURls'>
                                                    {{Chat.BotMessage.split('~|~')[0].split('~')[0]}}

                                                    <a href='' class='AllLinkColor' *ngIf='_AL' data-toggle='modal'
                                                        data-target='#ConsentPopup'
                                                        (click)='OpenConsentDoc()'>{{Chat.BotMessage.split('~')[1]}}</a>

                                                    <a href='' class='AllLinkColor' *ngIf='!_AL' data-toggle='modal'
                                                        data-target='#OpenACCPopup1'
                                                        (click)='AccountLink()'>{{Chat.BotMessage.split('~')[1]}}</a>
                                                    {{Chat.BotMessage.split('~|~')[0].split('~')[2]}}
                                                </span>

                                                <a target='_blank' href='{{Chat.BotMessage.split(" ~|~")[2]}}'>
                                                    {{Chat.BotMessage.split('~|~')[0].split('~')[3]}}</a>
                                            </div>
                                            <span class='message-feedback margin-top-m36'
                                                *ngIf="IsthumbsON && Chat.ShowEmoji && Chat.BotMessageType=='Bot'&& Chat.PostMsgType!='GREET'"
                                                (blur)='onBlurCloseEmoji(i, Chat.UserReponse)' tabindex='-1'>
                                                <span class='thumbsup-down transform' id='transform_{{i}}'>
                                                    <img src="../../assets/LikeIcon.png"
                                                        class='like-dislike-icons default-like-margin'
                                                        (click)='ShowEmoticons(i,undefined,undefined,undefined)'
                                                        id='MessageRating_{{i}}' *ngIf="Chat.UserReponse==undefined" />
                                                    <img src="../../assets/LikeIcon.png"
                                                        class='like-dislike-icons default-like-margin'
                                                        (click)='ShowEmoticons(i,undefined,undefined,undefined)'
                                                        id='minuslike_{{i}}' *ngIf="Chat.UserReponse=='-U'" />
                                                    <img src="../../assets/LikeIcon.png"
                                                        class='like-dislike-icons default-like-margin'
                                                        (click)='ShowEmoticons(i,undefined,undefined,undefined)'
                                                        id='minusdislike_{{i}}' *ngIf="Chat.UserReponse=='-D'" />
                                                    <img src="../../assets/LikeIcon.png"
                                                        class='feedback-emoji like-dislike-icons like-margin hidden-icon'
                                                        title='Thumbs-up'
                                                        (click)='AddConversationMessageRating(i, Chat.BotMessage, 4,  Chat.Index)'
                                                        id='like_{{i}}' />
                                                    <img src="../../assets/ActiveLikeIcon_1.png"
                                                        [style]="'background-color:' + _gradEndColor "
                                                        class='like-dislike-icons like-icon-margin'
                                                        (click)='ShowEmoticons(i, Chat.BotMessage, 1, Chat.Index)'
                                                        id='activeLike_{{i}}' *ngIf="Chat.UserReponse=='U'" />
                                                    <img src="../../assets/DislikeIcon.png"
                                                        class='feedback-emoji like-dislike-icons dislike-margin hidden-icon'
                                                        title='Thumbs-down'
                                                        (click)='AddConversationMessageRating(i, Chat.BotMessage, 3, Chat.Index)'
                                                        id='dislike_{{i}}' />
                                                    <img src="../../assets/ActiveDislikeIcon_1.png"
                                                        [style]="'background-color:' + _gradEndColor "
                                                        class='like-dislike-icons dislike-icon-margin'
                                                        (click)='ShowEmoticons(i, Chat.BotMessage, 2, Chat.Index)'
                                                        id='activeDislike_{{i}}' *ngIf="Chat.UserReponse=='D'" />
                                                </span>
                                            </span>
                                        </dd>

                                        <dd *ngIf="Chat.BotResponseType=='List'"
                                            class='bbbot-response word-wrap-br margin-left-33 bbcon-common'>
                                            <!-- <div>
                                                {{ Chat.BotMessage }}

                                                <span *ngIf='!_AOURls'>
                                                    <span *ngFor='let URLText of _ListArray; let i = index'>
                                                        <span *ngIf="URLText.containsURL===0"
                                                            [innerHtml]="URLText.key"></span>
                                                        <a target='_blank' *ngIf="URLText.containsURL===1"
                                                            [style]="'color:' + _gradEndColor " class='link-color'
                                                            href='{{URLText.value}}'>
                                                            <span [innerHtml]="URLText.key"></span>
                                                        </a>
                                                    </span>
                                                </span>
                                            </div> -->
                                            <div>
                                                <span *ngIf='!_AOURls'>
                                                    <span *ngFor='let URLText of Chat.LISTRespMsg; let i = index'>
                                                        <span *ngIf="URLText.containsURL===0"
                                                            [innerHtml]="URLText.key"></span>
                                                        <a target='_blank' *ngIf="URLText.containsURL===1"
                                                            [style]="'color:' + _gradEndColor " class='link-color'
                                                            href='{{URLText.value}}'>
                                                            <span [innerHtml]="URLText.key"></span>
                                                        </a>
                                                    </span>
                                                </span>

                                                <span *ngIf='_AOURls'>
                                                    {{Chat.BotMessage.split('~|~')[0].split('~')[0]}}

                                                    <a href='' class='AllLinkColor' *ngIf='_AL' data-toggle='modal'
                                                        data-target='#ConsentPopup'
                                                        (click)='OpenConsentDoc()'>{{Chat.BotMessage.split('~')[1]}}</a>

                                                    <a href='' class='AllLinkColor' *ngIf='!_AL' data-toggle='modal'
                                                        data-target='#OpenACCPopup1'
                                                        (click)='AccountLink()'>{{Chat.BotMessage.split('~')[1]}}</a>
                                                    {{Chat.BotMessage.split('~|~')[0].split('~')[2]}}
                                                </span>

                                                <a target='_blank' href='{{Chat.BotMessage.split(" ~|~")[2]}}'>
                                                    {{Chat.BotMessage.split('~|~')[0].split('~')[3]}}</a>
                                            </div>
                                            <span class='message-feedback margin-top-m36'
                                                *ngIf="IsthumbsON && Chat.ShowEmoji && Chat.BotMessageType=='Bot'&& Chat.PostMsgType!='GREET'"
                                                (blur)='onBlurCloseEmoji(i, Chat.UserReponse)' tabindex='-1'>
                                                <span class='thumbsup-down transform' id='transform_{{i}}'>
                                                    <img src="../../assets/LikeIcon.png"
                                                        class='like-dislike-icons default-like-margin'
                                                        (click)='ShowEmoticons(i,undefined,undefined,undefined)'
                                                        id='MessageRating_{{i}}' *ngIf="Chat.UserReponse==undefined" />
                                                    <img src="../../assets/LikeIcon.png"
                                                        class='like-dislike-icons default-like-margin'
                                                        (click)='ShowEmoticons(i,undefined,undefined,undefined)'
                                                        id='minuslike_{{i}}' *ngIf="Chat.UserReponse=='-U'" />
                                                    <img src="../../assets/LikeIcon.png"
                                                        class='like-dislike-icons default-like-margin'
                                                        (click)='ShowEmoticons(i,undefined,undefined,undefined)'
                                                        id='minusdislike_{{i}}' *ngIf="Chat.UserReponse=='-D'" />
                                                    <img src="../../assets/LikeIcon.png"
                                                        class='feedback-emoji like-dislike-icons like-margin hidden-icon'
                                                        title='Thumbs-up'
                                                        (click)='AddConversationMessageRating(i, Chat.BotMessage, 4,  Chat.Index)'
                                                        id='like_{{i}}' />
                                                    <img src="../../assets/ActiveLikeIcon_1.png"
                                                        [style]="'background-color:' + _gradEndColor "
                                                        class='like-dislike-icons like-icon-margin'
                                                        (click)='ShowEmoticons(i, Chat.BotMessage, 1, Chat.Index)'
                                                        id='activeLike_{{i}}' *ngIf="Chat.UserReponse=='U'" />
                                                    <img src="../../assets/DislikeIcon.png"
                                                        class='feedback-emoji like-dislike-icons dislike-margin hidden-icon'
                                                        title='Thumbs-down'
                                                        (click)='AddConversationMessageRating(i, Chat.BotMessage, 3, Chat.Index)'
                                                        id='dislike_{{i}}' />
                                                    <img src="../../assets/ActiveDislikeIcon_1.png"
                                                        [style]="'background-color:' + _gradEndColor "
                                                        class='like-dislike-icons dislike-icon-margin '
                                                        (click)='ShowEmoticons(i, Chat.BotMessage, 2, Chat.Index)'
                                                        id='activeDislike_{{i}}' *ngIf="Chat.UserReponse=='D'" />
                                                </span>
                                            </span>
                                        </dd>

                                        <dd *ngIf="Chat.BotResponseType=='IMG'" class='bbbot-response bot-message'>
                                            <div>{{ Chat.Message.split('~')[0] }}</div>

                                        </dd>

                                        <dd *ngIf="Chat.BotResponseType=='URL'"
                                            class='bbbot-response word-wrap-br margin-left-33 bbcon-common'>
                                            <div>
                                                <span *ngIf='!_AOURls'>
                                                    <span *ngFor='let URLText of Chat.URLRespMsg; let i = index'>
                                                        <span *ngIf="URLText.containsURL===0"
                                                            [innerHtml]="URLText.key"></span>
                                                        <a target='_blank' *ngIf="URLText.containsURL===1"
                                                            [style]="'color:' + _gradEndColor " class='link-color'
                                                            href='{{URLText.value}}'>
                                                            <span [innerHtml]="URLText.key"></span>
                                                        </a>
                                                    </span>
                                                </span>

                                                <span *ngIf='_AOURls'>
                                                    {{Chat.BotMessage.split('~|~')[0].split('~')[0]}}

                                                    <a href='' class='AllLinkColor' *ngIf='_AL' data-toggle='modal'
                                                        data-target='#ConsentPopup'
                                                        (click)='OpenConsentDoc()'>{{Chat.BotMessage.split('~')[1]}}</a>

                                                    <a href='' class='AllLinkColor' *ngIf='!_AL' data-toggle='modal'
                                                        data-target='#OpenACCPopup1'
                                                        (click)='AccountLink()'>{{Chat.BotMessage.split('~')[1]}}</a>
                                                    {{Chat.BotMessage.split('~|~')[0].split('~')[2]}}
                                                </span>

                                                <a target='_blank' href='{{Chat.BotMessage.split(" ~|~")[2]}}'>
                                                    {{Chat.BotMessage.split('~|~')[0].split('~')[3]}}</a>
                                            </div>

                                            <span class='message-feedback margin-top-m36'
                                                *ngIf="IsthumbsON && Chat.ShowEmoji && Chat.BotMessageType=='Bot'&& Chat.PostMsgType!='GREET'"
                                                (blur)='onBlurCloseEmoji(i, Chat.UserReponse)' tabindex='-1'>
                                                <span class='thumbsup-down transform' id='transform_{{i}}'>
                                                    <img src="../../assets/LikeIcon.png"
                                                        class='like-dislike-icons default-like-margin'
                                                        (click)='ShowEmoticons(i,undefined,undefined,undefined)'
                                                        id='MessageRating_{{i}}' *ngIf="Chat.UserReponse==undefined" />
                                                    <img src="../../assets/LikeIcon.png"
                                                        class='like-dislike-icons default-like-margin'
                                                        (click)='ShowEmoticons(i,undefined,undefined,undefined)'
                                                        id='minuslike_{{i}}' *ngIf="Chat.UserReponse=='-U'" />
                                                    <img src="../../assets/LikeIcon.png"
                                                        class='like-dislike-icons default-like-margin'
                                                        (click)='ShowEmoticons(i,undefined,undefined,undefined)'
                                                        id='minusdislike_{{i}}' *ngIf="Chat.UserReponse=='-D'" />
                                                    <img src="../../assets/LikeIcon.png"
                                                        class='feedback-emoji like-dislike-icons like-margin hidden-icon '
                                                        title='Thumbs-up'
                                                        (click)='AddConversationMessageRating(i, Chat.BotMessage, 4,  Chat.Index)'
                                                        id='like_{{i}}' />
                                                    <img src="../../assets/ActiveLikeIcon_1.png"
                                                        [style]="'background-color:' + _gradEndColor "
                                                        class='like-dislike-icons like-icon-margin '
                                                        (click)='ShowEmoticons(i, Chat.BotMessage, 1, Chat.Index)'
                                                        id='activeLike_{{i}}' *ngIf="Chat.UserReponse=='U'" />
                                                    <img src="../../assets/DislikeIcon.png"
                                                        class='feedback-emoji like-dislike-icons dislike-margin hidden-icon '
                                                        title='Thumbs-down'
                                                        (click)='AddConversationMessageRating(i, Chat.BotMessage, 3, Chat.Index)'
                                                        id='dislike_{{i}}' />
                                                    <img src="../../assets/ActiveDislikeIcon_1.png"
                                                        [style]="'background-color:' + _gradEndColor "
                                                        class='like-dislike-icons dislike-icon-margin '
                                                        (click)='ShowEmoticons(i, Chat.BotMessage, 2, Chat.Index)'
                                                        id='activeDislike_{{i}}' *ngIf="Chat.UserReponse=='D'" />
                                                </span>
                                            </span>
                                        </dd>

                                        <div>
                                            <dd *ngIf="Chat.BotMessageType=='Bot' &&Chat.PostMsgType=='Text'"
                                                class='bbbot-response bot-message margin-left-33'>
                                                <p [innerHtml]="Chat.PostDispMessage"></p>
                                            </dd>
                                            <dd *ngIf="Chat.PostMsgType=='URL'"
                                                class='bbbot-response bot-message margin-left-33'
                                                style='word-wrap: break-word;'>
                                                <div class='bot-message'>
                                                    <span *ngFor='PostURL in Chat.PostURLRespMsg; let i = index'>
                                                    </span>
                                                </div>
                                            </dd>
                                            <dd *ngIf="Chat.PostMsgType=='List'" class='bbbot-response bot-message'
                                                style='margin-left:30px!important'>
                                                <div>{{ Chat.PostDispMessage }}</div>
                                            </dd>
                                            <dd *ngIf="Chat.PostMsgType=='IMG'" class='bbbot-response bot-message'
                                                style='margin-left:30px!important'>
                                                <div>{{ Chat.PostDispMessage.split('~')[0] }}</div>
                                                <!-- <a target='_blank' href={{ Chat.PostDispMessage.split('~')[1] }}></a> -->
                                            </dd>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dl>

                    <div class="col-md-12">
                        <div class='row'>
                            <div class='col-md-12 col-xs-9 col-sm-9 list-padding'>
                                <div *ngIf='!_LTI'>
                                    <div *ngFor='let item of _SampleTextData'>
                                        <input type='submit'
                                            class='btn btn-default bblstbtn list-options-float list-options-margin-4'
                                            value={{item}} (click)='SendMessage(item, "User", "")' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div #relatedIntent class='p_bbParentHelptext' *ngIf="_pageName == 'Persona'">
                        <dl *ngIf='!_DisableTextMsg'>
                            <div #bbbbRelatedIntent class='relatedIntentBlock margin-right-14 bbcon-common'
                                id='relatedIntent'>
                                <dt class='bbhelptext disp-none' id='relativeQue1' *ngIf="RelativeQue1 !=''"
                                    (click)='SendMessage(RelativeQue1, "User", "")'>{{ RelativeQue1 }} </dt>
                                <dt class='bbhelptext disp-none' id='relativeQue2' *ngIf="RelativeQue2 !=''"
                                    (click)='SendMessage(RelativeQue2, "User", "")'>{{ RelativeQue2 }} </dt>
                                <dt class='bbhelptext disp-none' id='relativeQue3' *ngIf="RelativeQue3 !=''"
                                    (click)='SendMessage(RelativeQue3, "User", "")'>{{ RelativeQue3 }} </dt>
                            </div>
                        </dl>
                    </div>

                    <div #relatedIntent class='bbParentHelptext' *ngIf="_pageName != 'Persona'"
                        [ngClass]="{'d-none':_isRelativeQueEmpty == true}">
                        <dl *ngIf='!_DisableTextMsg'>
                            <div #bbbbRelatedIntent class='relatedIntentBlock margin-right-14 bbcon-common'
                                id='relatedIntent'
                                [ngClass]="{'setdynamicRelatedIntentDivHeight':_dynamicRelatedIntentDivHeight == true}">
                                <dt class='bbhelptext disp-none' id='relativeQue1' *ngIf="RelativeQue1 !=''"
                                    (click)='SendMessage(RelativeQue1, "User", "")'>{{ RelativeQue1 }} </dt>
                                <dt class='bbhelptext disp-none' id='relativeQue2' *ngIf="RelativeQue2 !=''"
                                    (click)='SendMessage(RelativeQue2, "User", "")'>{{ RelativeQue2 }} </dt>
                                <dt class='bbhelptext disp-none' id='relativeQue3' *ngIf="RelativeQue3 !=''"
                                    (click)='SendMessage(RelativeQue3, "User", "")'>{{ RelativeQue3 }} </dt>
                            </div>
                        </dl>
                    </div>
                    <!-- *ngIf="loader"  -->

                    <div class="row loaderBlock">
                        <div class="col-md-12">
                            <span id="loadingSpan" class='spinner margin-left-33' *ngIf="loader"
                                [ngClass]="{'setLoaderPosition':_setloaderposition}">
                                <div class='bounce1'></div>
                                <div class='bounce2'></div>
                                <div class='bounce3'></div>
                            </span>
                        </div>
                    </div>

                </div>
            </div>

            <div class='bbpanel-footer bg-white' *ngIf="ShowChat" id='ChatbotFooter'
                [ngClass]="{'p-bbpanel-footer': _pageName == 'Persona'}">
                <div class='row'>
                    <div class="col-md-12">
                        <div *ngIf='!_TTS'>
                            <div class="input-group">
                                <div class="footer-input-field d-inline-block">
                                    <input #sendMsg type="text" class="form-control textb" maxlength="200"
                                        placeholder={{_txtplaceholder}} autofocus [(ngModel)]="UserMessage"
                                        (keyup.enter)="SendMessage(UserMessage,'User',0)" id="txtMessage"
                                        [disabled]="_DisableTextMsg" required>
                                </div>
                                <span class="input-group-btn d-inline-block">
                                    <button class="no-border" (click)="SendMessage(UserMessage, 'User',0)"
                                        style="outline:none!important; height:30px;width:30px; margin-right:2px; padding-right:1px!important;outline:none!important; padding:0px; background-color:white!important; border:2px!important; border-bottom-right-radius:25px !important;border-top-right-radius:25px !important;"
                                        type="submit" [disabled]="_DisableTextMsg">
                                        <img src="../../../assets/transparent_Send.png" class="sendb msg_send_img" />
                                    </button>
                                </span>
                            </div>
                        </div>

                        <div>
                            <div class='bbfile-attach BotFooterFN' *ngIf='_TTS'>
                                <div>
                                    <label class='bbcon-common margin-left-10'>File Name :
                                        {{_FileName}}</label>
                                </div>
                            </div>
                            <div class='bbfile-icon' *ngIf='_TTS && !_EnableWebCam'>
                                <div class='image-upload'>
                                    <label for='file-input'>
                                        <img src='../../../assets/images/chat_bot/UploadIcon.png' />
                                    </label>
                                    <input type='file' name='file' id='file-input' (change)='getTheFiles($event);' />
                                </div>
                            </div>
                            <div class='bbsubmit-btn' *ngIf='_TTS && !_EnableWebCam'>
                                <button class='no-border submitb bbbtn-submit' [disabled]='_IsDisableSendBtn'
                                    (click)='uploadFilesF()' type='submit'>
                                    <img src='../../../assets/transparent_Send.png' title='Upload picture'
                                        class="sendbfile msg_send_img" style='cursor:pointer!important;' />
                                </button>
                                <input type='button' class='btn btn primary' style='display:none;'
                                    (click)='uploadFiles()' value='Upload' />
                            </div>
                            <div class='bbcamera' *ngIf='_EnableWebCam'>
                                <div class='upload-btn-wrapper'>
                                    <span class='input-group-btn'>
                                        <button class='bbcamera-icon no-border'>
                                            <i class='fa fa-camera bbcamera-spec' data-toggle='modal'
                                                data-target='#webCamModal' (click)='startCamera()'></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <div class='bbsubmit-btn' *ngIf='_EnableWebCam'>
                                <button class='no-border submitb bbbtn-submit' [disabled]='_IsDisableSendBtn'
                                    (change)='uploadFiles()' type='submit'>
                                    <img src='../../../assets/transparent_Send.png' class='fontTextCommon sendbfile' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="ratingModalPopup">

            <div #ratingModal id="ratingModalPopup" class="modal hover_bkgr_fricc" tabindex="-1"
                [ngClass]="{'d-block': ShowRating == true}" role="dialog" aria-labelledby="dialog_confirm_mapLabel"
                aria-hidden="true" (click)="ClickedOut($event)">
                <div class="modal-dialog">

                    <div class="modal-content bbmodal-content">
                        <div class="modal-header border-bottom-0 pb-0">
                            <button type="button" class="close" data-dismiss="modal" (click)="CloseRatingModal()">
                                <img src="../../../assets/close_rating.png" />
                            </button>
                        </div>
                        <div class="modal-body bbmodal-body" *ngIf="_isratingpopup">
                            <h4 id="add-review-header"
                                class="rating-modal-title text-center text-theme-color bbcon-common">Your
                                feedback will help me do better</h4>

                            <p-rating [(ngModel)]="_ratingval" (onRate)="AddUserRating($event)" class="ratingStarsBlock"
                                [style]="'--featured-bgColor:' + _BotGradEndColor " [cancel]="false"></p-rating>
                        </div>
                        <div class="modal-body bbmodal-body" *ngIf="_isfeedbackPopup">
                            <h4 id="add-review-header"
                                class="rating-modal-title text-center text-theme-color bbcon-common feedbackText">
                                Thank you for your feedback !</h4>


                            <p-rating [(ngModel)]="_ratingval" class="ratingStarsBlock"
                                [style]="'--featured-bgColor:' + _BotGradEndColor " [readonly]="true"
                                [stars]="_numberOfStars" [cancel]="false"></p-rating>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

    <div class="modal" tabindex="-1" role="dialog" id='webCamModal' *ngIf='_webCamShow'>
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Modal title</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <webcam [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                        (initError)="handleInitError($event)"></webcam>

                    <div class="snapshot" *ngIf="_webcamImage" style='width:300px;height: 200px;'>
                        <h2>Nice one!</h2>
                        <img [src]="_webcamImage.imageAsDataUrl" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="triggerSnapshot();">Take A Snapshot</button>
                    <!-- <button type='button' class='btn btn-primary' ng-click='uploadFiles()' data-dismiss='modal'>Proceed</button>   -->
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>



    <div class="modal" tabindex="-1" role="dialog" id='ConsentPopup' *ngIf='_ConsentDoc'>
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Terms & Conditions</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden='true'> <i class='fa fa-times close' data-dismiss='modal'
                                (click)='CloseConsentPopup()' aria-label='Close'
                                style='font-size:20px; float:right'></i> </span>
                    </button>
                </div>

                <div class="modal-body">
                    <h4>Terms and Conditions for bank general services.</h4>
                    <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi mi sapien, sagittis eget
                        cursus ac, convallis id quam. Nunc ut porta augue. Vivamus ullamcorper semper elementum.
                        Mauris justo augue, pretium et eros ac, venenatis viverra mauris. Quisque fermentum
                        interdum nibh. Phasellus id augue eros. Phasellus at finibus est.<br><br> Quisque
                        pulvinar imperdiet mauris, eu ultricies enim cursus id. Phasellus ut dictum urna.
                        Integer condimentum ligula id mauris posuere, eu convallis dui lacinia. Aliquam
                        consectetur sapien ipsum, ac dapibus massa sollicitudin nec. Etiam sit amet mi turpis.
                        Nullam pulvinar tempus dui et vestibulum. Proin pulvinar scelerisque mattis. Curabitur
                        rutrum justo sit amet justo semper, in ornare lacus tempus. Phasellus tempus consequat
                        venenatis. Curabitur nec molestie sapien. Pellentesque aliquet purus sed ornare finibus.
                        Mauris dui augue, ornare ac egestas eu, condimentum elementum risus. Nulla facilisi. In
                        tempus volutpat ex at malesuada. Nullam in urna non leo vehicula auctor. Pellentesque
                        vel finibus tortor.<br><br> Nam et congue leo. Ut laoreet faucibus orci. Nam maximus
                        bibendum eros quis tempor. Praesent lacus nisl, varius id rutrum eu, consequat vitae
                        ipsum. Proin in risus ultricies orci mattis imperdiet sed nec nisi. Integer ac dui vitae
                        felis suscipit consectetur nec non diam. Suspendisse nec diam nunc. Quisque at metus
                        odio. Cras eu laoreet lorem.
                    </p>
                </div>

                <div class="modal-footer">
                    <p-checkbox [(ngModel)]='_IsAccept' (click)='ckChangeModify()' label="Accept" [binary]="true">
                    </p-checkbox>
                    <button type='button' id='hsEmbeddedCancel'
                        style='outline: none;float:right;text-align:left !important;padding:5px;'
                        class='btn btn btn-success' [disabled]='_DisableUpdate'
                        (click)='SubmitConsent()'>Submit</button>
                </div>
            </div>
        </div>
    </div>


    <div class='bbmodal fade' id='OpenACCPopup1' *ngIf='_OpenACCPopup' tabindex='-1' role='dialog'
        aria-labelledby='ConsentPopupLabel1' aria-hidden='true' style='padding-top: 135px;z-index:99999'>
        <div class='bbmodal-dialog' role='document' style='width:700px;'>
            <div class='spmodal-content'>
                <div class='spmodal-header' style='padding-right: 20px!important;height:25px!important;'>
                    <div class='col-md-10 col-sm-10'>
                        <span class='modal-title' id='ConsentPopupLabel'
                            style='font-size: 20px; font-weight: bold;'>Account Linking
                        </span>
                    </div>
                    <div> <span aria-hidden='true'>
                            <i class='fa fa-times close' data-dismiss='modal' (click)='CloseConsentPopup()'
                                aria-label='Close' style='font-size:20px; float:right'></i>
                        </span>
                    </div>
                </div>
                <div class='spmodal-body' style='padding:0px!important;'>
                    <div id='connect-widget'></div>
                </div>
                <div class='spmodal-footer'>
                    <div class='row'>
                        <div class='col-md-11 col-lg-11' style='padding-right:10px;'>
                            <button type='button' class='btn btn-secondary' data-dismiss='modal'
                                (click)='CloseConsentPopup()'>Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>