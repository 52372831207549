import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, ViewChild, ElementRef, HostListener, EventEmitter, Pipe } from '@angular/core';
import { UserDetailsService } from '../services/UserDetails/user-details.service';
import { Entities } from '../test-bot/Entities'
import { Router } from "@angular/router";

import { ChangeDetectorRef } from '@angular/core';

import { Table } from 'primeng/table';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DomSanitizer } from '@angular/platform-browser';

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css'],
  providers: [DatePipe]
})

export class UserDetailsComponent implements OnInit {
  @ViewChild('dt') dt: ElementRef
  @ViewChild("dt", { static: false }) public ptable: Table;


  userListArr: any = [];
  userHeadersArr: any = [];

  searchDataArr: any = [];

  singleDataArr: any = [];
  dataId: number;
  vStrMainData: string;

  generatedDataOfMU: string;

  searchUSER: string = "";
  DataForExport: any;

  selectedSearchOpt: any[];
  searchOptionArr: any = [];
  selectedOptArr: any = [];
  searchUSERArr: any = [];

  selectedSection: string = "";
  selectedFaq: string = "";
  selectedAns: string = "";
  selectedUt: string = "";
  IsSelectedCB: boolean = false;

  filterAllFields: any
  filterArr: any = [];
  isHide: boolean = true
  selectedFaqName: string = '';
  selectedResponceType: string = '';
  selectedUtterance: string = '';

  newFilterDataArr: any = [];
  selectedValues: any = [];
  toggle: boolean = false;
  chageFilterInput: boolean = false;
  totalSelectedValues: any;
  selectedFieldOutline: boolean = false;
  greyFieldOutline: boolean = false;

  hoverFilterArr: any = []
  selectedFilterOption: string

  isLoader: boolean = true;

  sortColumnName: string;
  sortorder: boolean;
  descOrder: Boolean;
  AfterUpdateColumnName: string;

  first: number = 0;
  rows = 10;
  islastPageFlag: boolean;

  parameters = new Entities()
  myDate: any

  totalRows: string;
  filteredEmailId: any = []; 
  selectedsectionname: string;
  newSrNo: string;
  newQuestion: string;
  newAnswer: string;
  newrow: boolean = false
  addRecord = new Entities()

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;

  showDeletePopup: boolean = false
  _DeleteReasonsLst: any = []
  _selectedDeleteReasons: any
  _isDisplayOtherSec: boolean = false

  dataTodelete: any
  closeModal: any
  _OtherData: any

  _RoleName: any
  _IsClient: any

  _languageArr: any = [];
  _filteredEmailIdArr: any = [];
  _buildModelArr: any = [];

  _ViewClientRoleAccess: boolean = false;

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;
  @ViewChild("modalDataDelete", { static: false }) public modalDataDelete: NgbModal;
  @ViewChild("ConfirmationModal", { static: false }) public confirmationModal: NgbModal;

  @Output() inputdata: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("comments") comment: ElementRef

  _isSelectedField: boolean = false

  _ifNoRecords: boolean = false

  title = environment.pageTitle + ' - User Details List';

  constructor(private modalService: NgbModal, private _eref: ElementRef, public userconfigservice: UserDetailsService,
    private _http: HttpClient, private cdref: ChangeDetectorRef, private datePipe: DatePipe, private router: Router, private sanitizer: DomSanitizer,
    private titleService: Title) {
    this.myDate = new Date();
    this.sortorder = false;
  }

  selectedCities: string[] = [];

  ngOnInit(): void {

    this.titleService.setTitle(this.title);


    this._RoleName = window.localStorage.getItem("RoleName");

    if (this._RoleName === 'ClientView' || this._RoleName === 'ContentView') {
      this._ViewClientRoleAccess = true;
    }

    this.fetchUserList();
    this.userListHeaders();
    this.filterDropdown();
    this.sortorder = false;

    if (this._RoleName.toUpperCase() == 'CLIENT') {
      this._IsClient = true
    }

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  filterDropdown() {
    this.filterArr = [
      {
        label: 'FAQ Status', value: 'faqstatus',
        items: [
          { name: 'Live', code: 'faqstatus', status: 'live', checked: false },
          { name: 'Live but content need updates', code: 'faqstatus', status: 'liveContentUpdate', checked: false },
          { name: 'New and content is approved', code: 'faqstatus', status: 'liveContentApproved', checked: false },
          { name: 'Not live', code: 'faqstatus', status: 'notLive', checked: false }
        ]
      },
      {
        label: 'Response Type', value: 'rt',
        items: [
          { name: 'Text', code: 'responseType', status: 'text', checked: false },
          { name: 'List', code: 'responseType', status: 'list', checked: false },
          { name: 'URL', code: 'responseType', status: 'url', checked: false },
        ]
      },
      {
        label: 'Utterance', value: 'us',
        items: [
          { name: 'Equal to 30', code: 'utterance', status: 'equalto30', checked: false },
          { name: 'Less than 30', code: 'utterance', status: 'lessthan30', checked: false },
          { name: 'More than 30', code: 'utterance', status: 'morethan30', checked: false },
        ]
      }
    ];

    this.hoverFilterArr = [
      { name: 'Live', code: 'NY', status: 'live' },
      { name: 'Live but content need updates', code: 'RM', status: 'liveContentUpdate' },
      { name: 'New and content is approved', code: 'LDN', status: 'liveContentApproved' },
      { name: 'Not live', code: 'IST', status: 'notLive' }
    ];

    this.searchOptionArr = [
      { name: 'Email ID', code: 'sectionName' },
      { name: 'FirstName', code: 'faq' },
      { name: 'RoleName', code: 'ans' }
    ]

  }

  selectOption(text: any) {
    this.isLoader = true;

    this.filterAllFields = text;
  }

  onClickedOutside(e: any) {

    this.toggle = false;
  }

  clearInputField() {

    this.selectedValues = []
    this.filterAllFields = []
    this.isHide = true

    this.filterDropdown();
    this.chageFilterInput = false

    const event = { "keyCode": 13 }

    this.searchData(event, "Search");
  }

  

  selectFilterOption(details: any) {
    this.isHide = false

    if (details.checked == true) {
      this.chageFilterInput = true

      var d = this.selectedValues.find((x: { code: string; }) => x.code == details.code)

      if (d) {
        this.dropSkill(d)
        this.selectedValues.push(details)
      }

      if (!d) {
        this.selectedValues.push(details)
      }
    }
    else {
      this.selectedValues.forEach((value: any, id: any) => {
        if (value.status == details.status)
          this.selectedValues.splice(id, 1)
        this.chageFilterInput = true
      });
    }

    this.totalSelectedValues = this.selectedValues.length
    if (this.totalSelectedValues === 0) {
      this.chageFilterInput = false
      this.isHide = true
    }

    this.filterAllFields = this.selectedValues;

    const event = { "keyCode": 13 }

    this.searchData(event, "Search");

  }


  dropSkill(index: any) {
    this.selectedValues.forEach((value: any, id: any) => {
      if (value.status == index.status) 
        this.selectedValues.splice(id, 1)
    });

    let indexMain = this.filterArr[0].items.indexOf(index);

    if (indexMain === -1) {
      indexMain = this.filterArr[1].items.indexOf(index);

      if (indexMain === -1) {
        indexMain = this.filterArr[2].items.indexOf(index);

        if (indexMain === -1) {
        }
        else {
          const dataMain = this.filterArr[2].items.filter(function (x: any) { return x.status === index.status })[0];

          dataMain.checked = false;

          this.filterArr[2].items[indexMain] = dataMain

        }
      }
      else {
        const dataMain = this.filterArr[1].items.filter(function (x: any) { return x.status === index.status })[0];

        dataMain.checked = false;

        this.filterArr[1].items[indexMain] = dataMain

      }
    }
    else {
      const dataMain = this.filterArr[0].items.filter(function (x: any) { return x.status === index.status })[0];

      dataMain.checked = false;

      this.filterArr[0].items[indexMain] = dataMain

    }

    this.filterAllFields = this.selectedValues;

    const event = { "keyCode": 13 }

  }

  clickEvent() {
    this.selectedFieldOutline = !this.selectedFieldOutline
    this.toggle = !this.toggle
  }

  changeStyle() {
    this.greyFieldOutline = !this.greyFieldOutline
  }

  handleLink: any
  _LinkArray: any = []
  _selectedRespType: any = [];
  selectedintentname: string

  fetchUserList() {
    this.isLoader = true;
    this.parameters = new Entities()

    this.userconfigservice.getUserDetailsData(this.parameters.UserDetails).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        this._hideEmptyRow = false;
        this.userListArr = []
        this.userListArr = res.Data;

        if (this.userListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        } else {
          this._ifNoRecords = false
        }

        this.isLoader = false;
        this.sortColumnName = '';
        this.first = 0;
        this.sortorder = false;

        this.ptable.reset();

      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  IntervalId: any

  closeInterval() {
    if (this.IntervalId) {
      clearInterval(this.IntervalId);
    }
  }

  userListHeaders() {
    this.userHeadersArr = [
      { field: 'DispID', header: 'Sr No.', width: "4%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'EmailId', header: 'Email ID', width: "7%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'Section Name' },
      { field: 'FirstName', header: 'FirstName', width: "14%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'FAQ' },
      { field: 'LastName', header: 'LastName', width: "14%", isEditable: "true", isSort: "true", textAlignment: "left", value: 'Answer' },
      { field: 'RoleName', header: 'Role', width: "10%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'b' },
    ]
  }


  popupContentError: string

  closePopup() {

    this.showPopup = false
    this.showDeletePopup = false
  }

  addFaqRecord(event: any) {
    this.isLoader = true;
    this.newrow = false;
    this.parameters.UserDetails.EmailId = event.EmailId

    if (event.DisplayMessage !== undefined) {
      var message = event.DisplayMessage.split('~|~')[0];
      var updMessage = message.replace(/~/g, '');
      event.UserMessage = updMessage.replace(/<br>/g, '');
    }

    this.parameters.UserDetails.Password = event.Password.trim()
    this.parameters.UserDetails.FirstName = event.UserMessage.trim()

    this.parameters.UserDetails.LastName =  event.LastName.trim()
    this.parameters.UserDetails.RoleName = event.RoleName.trim()
    this.parameters.UserDetails.Address = event.Address.name === undefined ? event.SectionName : event.SectionName.name;

    this.myDate = this.datePipe.transform(this.myDate, 'MMddyyyyHHmm');
    this.parameters.UserDetails.ContactNumber = event.ContactNumber.trim()

    this.userconfigservice.InsertUserDetailsData(this.parameters.UserDetails).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        this.isLoader = false;

        this.sortColumnName = '';
        this.first = 0;
        this.sortorder = false;

        this.showPopup = false;
        this.popupImg = "../../../assets/images/file-plus.png"
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)

        this.fetchUserList();

      } else if (res.Error) {
        this.isLoader = false;

        this.showPopup = false;
        this.popupImg = "../../../assets/images/file-plus-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;
      }
    })
  }

  filterEmailId(event: any) {
    let filtered: any[] = [];
    let result: any = [];
    let query = event.query;

    result = Array.from(this.userListArr.reduce((m: any, t: any) => m.set(t.SectionName, t), new Map()).values());

    for (let i = 0; i < result.length; i++) {
      if (result[i].SectionName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push({
          name: result[i].SectionName,
          value: result[i].SectionName
        });
      }
    }

    this.filteredEmailId = filtered
  }

  onRowEditInit(book: any) {
  }

  _hideEmptyRow: boolean = false

  addNewRow(vClickBtnName: string) {
    this.PageName = vClickBtnName
    this.userListArrData = this.userListArr

    this._showAddEditScreen = true
    window.localStorage.setItem("showFaq", this._showAddEditScreen) 
  }

  cancelNewRow() {
    this.newrow = false;

    this.addRecord.UserDetails.EmailId = "";
    this.addRecord.UserDetails.FirstName = "";
    this.addRecord.UserDetails.LastName = "";
  }

  OnSelectDeletedReason() {
    if (this._selectedDeleteReasons.name == 'Other') {
      this._isDisplayOtherSec = true
    }
    else
      this._isDisplayOtherSec = false
  }

  _QuestionTodelete: any

  OpenDeletePopup(data: any) {

    this._OtherData = "";
    this.dataTodelete = data

    this._QuestionTodelete = data.Questions

    this.triggerModal(this.modalDataDelete);
  }

  deleteFaqRecord(vCheckBoxSelectedList: any, vOtherData: any) {

    this.showDeletePopup = false
    this.isLoader = true

    if (this._selectedDeleteReasons == undefined) {
      this._selectedDeleteReasons = {
        code: "1",
        name: "Testing"
      }
    }

    this.parameters.UserDetails.BondUsersId = this.dataTodelete.BondUsersId
    this.parameters.UserDetails.EmailId = this.dataTodelete.EmailId

    this.userconfigservice.DeleteUserDetailsData(this.parameters.UserDetails).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        this.isLoader = true

        this.sortColumnName = '';
        this.sortorder = false;

        this.popupImg = "../../../assets/images/delete-popup.png";
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo);

        this.fetchUserList();

      }
      else if (res.Error) {
        this.isLoader = true

        this.showPopup = false
        this.popupImg = "../../../assets/images/Delete-popup-error.png";
        this.popupContent = "Oops!";

        this.popupContentError = res.Error;
      }
    })
  }

  selectSearchOption(text: any) {
    this.isLoader = true;
    this.selectedOptArr = text; 

    this.selectedSection = "";
    this.selectedFaq = "";
    this.selectedAns = "";
    this.selectedUt = "";

    var searchEntities = new Entities();

    if (this.selectedOptArr.length != 0) {

      for (let i = 0; i < this.selectedOptArr.length; i++) {
        if (this.selectedOptArr[i].code.toUpperCase() == "SECTIONNAME") {
          this.selectedSection = this.searchUSER
        } else if (this.selectedOptArr[i].code.toUpperCase() == "FAQ") {
          this.selectedFaq = this.searchUSER
        } else if (this.selectedOptArr[i].code.toUpperCase() == "ANS") {
          this.selectedAns = this.searchUSER
        } else if (this.selectedOptArr[i].code.toUpperCase() == "UTTERANCES") {
          this.selectedUt = this.searchUSER
        }
      }
      this.IsSelectedCB = true;
    }

    else {
      this.selectedSection = this.searchUSER;
      this.selectedFaq = this.searchUSER;
      this.selectedAns = this.searchUSER;
      this.selectedUt = this.searchUSER
      this.IsSelectedCB = false;
    }

    searchEntities.UserDetails.EmailId = this.selectedSection;
    searchEntities.UserDetails.FirstName = this.selectedFaq;
    searchEntities.UserDetails.RoleName = this.selectedAns;
    searchEntities.UserDetails.IsSelectedCB = this.IsSelectedCB;

    this.searchUSERRecord(searchEntities.UserDetails);

  }

  searchData(event: any, actionName: any) {
    this.selectedSection = "";
    this.selectedFaq = "";
    this.selectedAns = "";
    this.selectedUt = "";

    if (event.keyCode === 13) {
      var searchEntities = new Entities();

      if (this.selectedOptArr.length != 0) {

        for (let i = 0; i < this.selectedOptArr.length; i++) {
          if (this.selectedOptArr[i].code.toUpperCase() == "SECTIONNAME") {
            this.selectedSection = this.searchUSER
          } else if (this.selectedOptArr[i].code.toUpperCase() == "FAQ") {
            this.selectedFaq = this.searchUSER
          } else if (this.selectedOptArr[i].code.toUpperCase() == "ANS") {
            this.selectedAns = this.searchUSER
          } else if (this.selectedOptArr[i].code.toUpperCase() == "UTTERANCES") {
            this.selectedUt = this.searchUSER
          }
        }
        this.IsSelectedCB = true;
      }
      else {
        this.selectedSection = this.searchUSER;
        this.selectedFaq = this.searchUSER;
        this.selectedAns = this.searchUSER;
        this.selectedUt = this.searchUSER
        this.IsSelectedCB = false;
      }

      searchEntities.UserDetails.EmailId = this.selectedSection;
      searchEntities.UserDetails.FirstName = this.selectedFaq;
      searchEntities.UserDetails.RoleName = this.selectedAns;
      searchEntities.UserDetails.IsSelectedCB = this.IsSelectedCB;

      if (actionName.toUpperCase() === "SEARCH") {
        this.searchUSERRecord(searchEntities.UserDetails);
      }
      
    }
  }

  searchUSERRecord(searchObj: any) {
    this._ifNoRecords = false
    this.isLoader = true;

    this.DataForExport = this.parameters.UserDetails;

    this.userconfigservice.getUserDetailsData(searchObj).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        this.userListArr = res.Data;
        this.isLoader = false;
        this.toggle = false;

        this.sortColumnName = '';
        this.AfterUpdateColumnName = "";
        this.sortorder = false;

        this.ptable.reset();

        if (this.userListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        } else {
          this._ifNoRecords = false
        }

      } else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }


  sortcolumn(ColumnName: any) {

    let reverse = this.sortColumnName === ColumnName ? !this.descOrder : undefined;

    if (reverse === undefined)
      reverse = false;

    this.sortColumnName = ColumnName;
    this.descOrder = reverse;

    if (this.descOrder == true)
      this.sortorder = false;
    else
      this.sortorder = true;
  }

  GOToEnteredPageNo(pageNo: any): void {

    if (pageNo != "0" && pageNo != "") {

      var enteredPageNo: number = +pageNo;

      this.islastPageFlag = this.isLastPage(enteredPageNo);

      if (this.islastPageFlag === false) {
        this.first = enteredPageNo * 10 - 10;
      }
      else {
        const pageCount = Math.ceil(this.userListArr.length / this.rows);

        let page = pageCount - 1;
        page = page * this.rows

        this.first = page
      }
    }
    else {
      this.first = 0;
    }
  }

  isLastPage(pageNo: any): boolean {

    const pageCount = Math.ceil(this.userListArr.length / this.rows);

    const page = pageCount - 1;

    if (pageNo <= page) {
      return false;
    }
    else {
      return true;
    }
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onKeypressEvent(event: any) {
    var eventLength = event.target.value
    var textLength = eventLength.length;

    var htmlElement = this.comment.nativeElement as HTMLElement;

    if (textLength % 50 == 0) {
      var height = textLength / 50;

      if (height >= 2) {
        htmlElement.setAttribute("style", "height:93px !important")
      } else {
        htmlElement.setAttribute("style", "height:auto !important")
      }
    }

  }

  _newVersion: any
  _modelName1: any
  _Lang = "EN";

  _BuildmodelIP = {
    "StartTesting": false,
    "IntentTest": false
  };

  _showAddEditScreen:any = false

  ShowFaqList: any = window.localStorage.setItem("showFaq", this._showAddEditScreen)

  PageName: any
  EditRowID: any
  userListArrData: any

  OnAddEditBtnClick(vClickBtnName: string, vEditRowId: string) {

    this.PageName = vClickBtnName
    this.EditRowID = vEditRowId
    this.userListArrData = this.userListArr

    this._showAddEditScreen = true
    window.localStorage.setItem("showFaq", this._showAddEditScreen) 
  }

  cancelbuttonOnAddEdit() {
    this._showAddEditScreen = false

  }

  AddEditPageResult(SuccVal: any) {
    if (SuccVal.toUpperCase() == "SUCCESS") {
      this.fetchUserList()
      this._showAddEditScreen = false
    }
    else {
      this._showAddEditScreen = false
    }
  }

  CloseModel() {

  }

}
