<div class="container-fluid screen-container xl-bg-transperent ">

    <div class="mainContainerWrapper faqUsageWrapper">

        <div class="header row">
            <div class="col-12">
                <div class="float-left">
                    <span class="page-title">FAQs Usage</span>
                </div>

                <div class="float-right">
                    <span class="align-top">
                    </span>
                    <button [hidden]="_ViewClientRoleAccess" title="Export" type="button" class="btn btn-secondary"
                        (click)="AfterCLickExport()">
                        <img src="../../../assets/images/Export_icon.png" class="left-img export_icon">
                        <img src="../../../assets/images/Export-icon-selected.png" class="left-img seleted_export_icon">
                        <span class="align-middle">export</span>
                    </button>

                </div>
            </div>
        </div>

        <div class="row subHeader xl-pl-20px xl-pr-20px">
            <div class="col-md-12">
                <div class="float-left">
                    <div class="p-col-12">
                        <div class="p-field mb-2 position-relative customCalendar">

                            <div class="calendar-img">
                                <a (click)="clearInputField(rangeDates)">
                                    <img src="../../../assets/images/ClearInputIcon.png" *ngIf="_closeIcon">
                                </a>
                            </div>
                            <p-calendar [(ngModel)]="rangeDates" placeholder="Enter date range" selectionMode="range"
                                [readonlyInput]="true" inputId="range" (onSelect)="fetchDateRange(rangeDates)"
                                [showIcon]="true">
                            </p-calendar>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="row">

            <div class="tableContent" [hidden]="_ifNoRecords">
                <p-table #dt [value]="FAQUsageArr" styleClass="p-custom-datatable" [rows]="10" [paginator]="true"
                    reflow="true" paginatorTemplate="{RowsPerPageDropdown}" paginatorPosition="bottom"
                    paginatorPosition="bottom" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,40,50]"
                    currentPageReportTemplate="Displaying {last} of {totalRecords}" [rowHover]="true"
                    [scrollable]="true" scrollHeight="flex" [resizableColumns]="true" [(first)]="first" editMode="row"
                    [editable]="true">

                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col *ngFor="let col of faqsUsageHeadersArr" [style.width]='col.width'>
                        </colgroup>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn *ngFor="let col of faqsUsageHeadersArr" style="text-align: left;"
                                [ngClass]="col.field" data-toggle="tooltip" data-placement="top" title="{{col.header}}">
                                <div class="tableHeader" *ngIf="col.isSort === 'true'" (click)="sortcolumn(col.field)"
                                    [ngClass]="{'border-right-dadadd': col.field != 'Confidence'}">
                                    <div class="d-inline-block text-overflow-header"
                                        [ngClass]="{'pl-15':col.field === 'DispId', 'xl-pl-20px':col.field === 'DispId'}">
                                        {{col.header}}
                                    </div>

                                </div>
                                <div class="tableHeader" *ngIf="col.isSort === 'false'"
                                    [ngClass]="{'border-right-dadadd': col.field != 'BondModifiedOn', 'pl-25':col.field === 'Id', 'xl-pl-35px':col.field === 'Id', 'xl-pl-14px':col.field !== 'Id', 'pl-10':col.field !== 'Id'}">
                                    {{col.header}}
                                </div>
                            </th>

                        </tr>
                        <tr *ngIf="isLoader">
                            <td *ngFor="let col of faqsUsageHeadersArr">
                                <div class="item customSkeletonLoader">
                                    <ngx-skeleton-loader count="20" appearance=""></ngx-skeleton-loader>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-car let-ri="rowIndex">
                        <tr>
                            <td pEditableColumn *ngFor="let col of faqsUsageHeadersArr"
                                [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">
                                <div [ngClass]="{'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId'}">
                                    <div *ngIf="col.field != 'BondModifiedOn'"
                                        [ngClass]="{'pl-25':col.field === 'DispId', 'xl-pl-35px':col.field === 'DispId'}">
                                        {{car[col.field]}}
                                    </div>

                                    <div *ngIf="col.field === 'BondModifiedOn'">
                                        {{car[col.field] | date : 'dd/MM/yyyy HH:mm:ss'}}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                </p-table>
            </div>

            <div class="noRecordFound text-center border-top" *ngIf="_ifNoRecords">
                <img src="../../assets/images/no_records.png">
                <p class="title">No Result Found</p>
                <p class="mb-0 subtitle">Sorry, we couldn’t find any results for this search.</p>
            </div>

        </div>

    </div>
    <ng-template #modalWarningAndInfo let-modal="dismiss">
        <app-warning-and-info-modal
            [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
        </app-warning-and-info-modal>
    </ng-template>
</div>