import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    template: ''
})

export class Nodes {
    SectionName: any = "";
    IntentName: any = "";
    Question: any = "";
    Status: any = "";
    RelatedIntent = "";
    ResponceType = "";
    SampleResponses : ""
}

export class RelatedIntent {
    SectionName: any = "";
    IntentName: any = "";
    Question: any = "";
    RelatedIntent: any = [];
}