import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AnnotationConstraints, ConnectorModel, ContextMenuSettingsModel, DiagramBeforeMenuOpenEventArgs, DiagramComponent, DiagramConstraints, GridlinesModel, MarginModel, NodeConstraints, NodeModel, PaletteModel, ShapeAnnotationModel, SnapSettingsModel, SymbolInfo } from '@syncfusion/ej2-angular-diagrams';
import { ClickEventArgs, ExpandMode, MenuEventArgs } from '@syncfusion/ej2-angular-navigations';

import { Nodes, RelatedIntent } from '../clickable-flow/ClickableFlowEntity'
import { FaqService } from '../../services/faq/faq.service';
import { ClickableFlowService } from '../../services/ClickableFlow/clickable-flow.service'
import { Router } from '@angular/router';
import { faqEntities } from '../fqaentities';

import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-clickable-flow',
  templateUrl: './add-clickable-flow.component.html',
  styleUrls: ['./add-clickable-flow.component.css']
})
export class AddClickableFlowComponent implements OnInit {

  @ViewChild('diagram')
  public diagram!: DiagramComponent;

  @ViewChild('viewdiagram')
  public viewdiagram!: DiagramComponent;

  public constraints: AnnotationConstraints;
  public nodeConstraints: NodeConstraints;
  public diagramConstraints: DiagramConstraints;

  public contextMenuSettings!: ContextMenuSettingsModel
  private interval: number[] = [1, 9, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25,
    9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75];
  private horizontalGridlines: GridlinesModel = { lineColor: '#e0e0e0', lineIntervals: this.interval };
  private verticalGridlines: GridlinesModel = { lineColor: '#e0e0e0', lineIntervals: this.interval };
  public snapSettings: SnapSettingsModel = { horizontalGridlines: this.horizontalGridlines, verticalGridlines: this.verticalGridlines };

  diagramArr: any = []
  diagramArrJson: any
  nodesArr: any
  connectorsArr: any
  public nodes: NodeModel[] = []
  public connectors: ConnectorModel[] = []
  selectedItem: NodeModel | ConnectorModel;

  isPropertyBlock: boolean = false
  isCreateFlowchart: boolean = true

  FaqListArr: any = []
  TempForUSer: any = []
  _filteredSectionNameArr: any = [];
  selectedSectionName: any

  intentNameList: any = []
  parentIntentDataList: any = []
  DistinctParentIntentDataList: any = []
  _filteredIntentNameArr: any = []
  selectedIntentName: any

  mainArr: any = []
  nodeObjArr: any = []

  _NodeObj = new Nodes();
  _RelatedIntentObj = new RelatedIntent()

  @Input() inputVariable: any;
  _inputData: any
  _selectedRowData: any;

  _isSaveDiagramLoader: boolean = false
  checkDiagram: any
  flowName: any

  popupImg: string;
  popupContent: string;
  popupContentError: string
  closeModal: any

  _isSectionNameErrorFlag: boolean = false
  _isIntentNameErrorFlag: boolean = false
  _isFlowName: boolean = false
  sectionNameListArr: any = []
  intentNameListArr: any = []

  _isWhiteSpace: boolean = false

  @Output() inputdata: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;

  public layout: Object = {

    //Set the layout type

    type: 'HierarchicalTree'

  };

  constructor(public faqconfigservice: FaqService, public clickableFlowService: ClickableFlowService, private router: Router, private modalService: NgbModal) { }

  ngOnInit(): void {
    //Enables the context menu
    this.contextMenuSettings = {
      //Enables the context menu
      show: true,
      items: [
        {
          text: 'Set Intent', id: 'SetIntent', target: '.e-diagramcontent'
        },
        {
          text: 'Delete Node', id: 'delete', target: '.e-diagramcontent'
        }
      ],
      // Hides the default context menu items
      showCustomMenuOnly: true,
    }

    // this.constraints = AnnotationConstraints.ReadOnly

    this._inputData = this.inputVariable
    this._selectedRowData = this._inputData.selectedRowData[0]

    this.getAllClickableFlowParentIntentsData();
    this.fetchFaqList();

    if (this._inputData.PageName.toUpperCase() == "EDIT") {
      this.fetchNodesAndConnectorsFromDB();
    }

    // this.fetchIntentName();
  }

  // onClick(args: ClickEventArgs) {
  //   debugger
  //   // if (event.elementType === "node") {
  //   //   debugger
  //   //   // $("#basicDialog").ejDialog("open");
  //   //   // document.getElementById("text").innerHTML = event.element.name;
  //   // }
  // }

  // public click(args: ClickEventArgs): void {
  //   debugger
  //   args.elementType === "node"
  //   // var node = args.propName;
  //   let selectedNode: NodeModel = this.checkDiagram.selectedItems.nodes![0];
  // };

  public getSymbolInfo(symbol: NodeModel): SymbolInfo {
    return { fit: true };
  }

  public getSymbolDefaults(symbol: NodeModel): void {
    if (symbol.id === 'Terminator' || symbol.id === 'Process') {
      symbol.width = 80;
      symbol.height = 40;
    } else if (symbol.id === 'Decision' || symbol.id === 'Document' || symbol.id === 'PreDefinedProcess' ||
      symbol.id === 'PaperTap' || symbol.id === 'DirectData' || symbol.id === 'MultiDocument' || symbol.id === 'Data') {
      symbol.width = 50;
      symbol.height = 40;
    } else {
      symbol.width = 50;
      symbol.height = 50;
    }
    // symbol.style.strokeColor = '#757575';
    // symbol.style?.strokeColor= String
  }

  //SymbolPalette Properties
  public symbolMargin: MarginModel = { left: 15, right: 15, top: 15, bottom: 15 };
  public expandMode: ExpandMode = 'Multiple';
  public enableAnimation: any = true;
  //Initialize the flowshapes for the symbol palatte
  private flowshapes: NodeModel[] = [
    // { id: 'Process', shape: { type: 'Flow', shape: 'Process' } }
    {
      id: 'process', shape: {
        type: 'Basic',
        shape: 'Rectangle',
        cornerRadius: 10
      }
    }
  ];
  //Initializes connector symbols for the symbol palette

  private connectorSymbols: ConnectorModel[] = [
    {
      id: 'Link1', type: 'Orthogonal', sourcePoint: { x: 0, y: 0 }, targetPoint: { x: 40, y: 40 },
      targetDecorator: { shape: 'Arrow', style: { strokeColor: '#757575', fill: '#757575' } }, style: { strokeWidth: 2, strokeColor: '#757575' }
    },
    // {
    //   id: 'Link21', type: 'Straight', sourcePoint: { x: 0, y: 0 }, targetPoint: { x: 40, y: 40 },
    //   targetDecorator: { shape: 'Arrow', style: { strokeColor: '#757575', fill: '#757575' } }, style: { strokeWidth: 2, strokeColor: '#757575' }
    // }
  ];

  public palettes: PaletteModel[] = [
    { id: 'flow', expanded: true, symbols: this.flowshapes, iconCss: 'shapes', title: 'Flow Shapes' },
    { id: 'connectors', expanded: true, symbols: this.connectorSymbols, iconCss: 'shapes', title: 'Connectors' }
  ]

  public getConnectorDefaults(obj: ConnectorModel) {
    obj.style = {
      strokeColor: '#6BA5D7',
      fill: '#6BA5D7',
      strokeWidth: 2
    }
    obj.targetDecorator = {
      style: {
        fill: '#6BA5D7',
        strokeColor: '#6BA5D7'
      }
    }
  }

  public create(args: Object): void {
    this.diagram.fitToPage();
    // this.diagram.connectors[0].sourcePoint?.x = 150;
    // this.diagram.connectors[0].targetPoint.x = 150;
    // this.diagram.connectors[0].sourceID: 
    this.diagram.dataBind();
  }

  //Default setting for nodes
  public nodeDefaults(obj: NodeModel): NodeModel {

    let node: NodeModel = {
      // Sets the annotation for the node
      annotations: [{
        //Sets the constraints as Read only
        constraints: AnnotationConstraints.ReadOnly
      }]

    };

    node.height = 60;

    node.width = 140;

    node.offsetX = 300;

    return node;

  }

  //Context Menu
  public contextMenuOpen(args: DiagramBeforeMenuOpenEventArgs): void {

    this.isPropertyBlock = false

    if (this.isCreateFlowchart == false) {
      this.checkDiagram = this.viewdiagram
    } else {
      this.checkDiagram = this.diagram
    }

    let selectedNode: NodeModel = this.checkDiagram.selectedItems.nodes![0];

    //Check data exist
    if (selectedNode) {
      if (this.nodeObjArr.length > 0) {
        if (selectedNode.annotations!.length > 0) {
          let selectedNodeName = this.checkDiagram.selectedItems.nodes![0].annotations![0].content
          // selectedNodeName.AnnotationConstraints.ReadOnly
          if (selectedNodeName) {
            let fetchSignleRecord = this.nodeObjArr.find((x: { Question: string; }) => x.Question == selectedNodeName)


            if (fetchSignleRecord) {


              this._NodeObj.SectionName = {
                name: fetchSignleRecord.SectionName.name == undefined ? fetchSignleRecord.SectionName : fetchSignleRecord.SectionName.name,
                value: fetchSignleRecord.SectionName.value == undefined ? fetchSignleRecord.SectionName : fetchSignleRecord.SectionName.value
              }
              this._NodeObj.IntentName = {
                name: fetchSignleRecord.IntentName.name == undefined ? fetchSignleRecord.IntentName : fetchSignleRecord.IntentName.name,
                value: fetchSignleRecord.IntentName.value == undefined ? fetchSignleRecord.IntentName : fetchSignleRecord.IntentName.value
              }


              this.selectedSectionName = fetchSignleRecord.SectionName.name == undefined ? fetchSignleRecord.SectionName : fetchSignleRecord.SectionName.name,
                this.SperateOutIntents(fetchSignleRecord)

              this.fetchSingleRecordData(fetchSignleRecord.IntentName);

              this._NodeObj.Question = fetchSignleRecord.Question
              // this._NodeObj.Status = fetchSignleRecord.Status

              if (this._inputData.PageName.toUpperCase() == "EDIT") {
                let fetchSignleRecordIntentList = this.FaqListArr.filter((x: { SectionName: string; }) => x.SectionName == fetchSignleRecord.SectionName)

                let fetchSingleRecordStatus = fetchSignleRecordIntentList.filter((x: { IntentName: string; }) => x.IntentName == fetchSignleRecord.IntentName)


                if (fetchSingleRecordStatus.length == 0) {
                  fetchSingleRecordStatus = this.TempForUSer.filter((x: { IntentName: string; }) => x.IntentName == fetchSignleRecord.IntentName)
                }

                if (fetchSingleRecordStatus[0].IsLiveRawIntent == true) {
                  this._NodeObj.Status = 'Live'
                } else if (fetchSingleRecordStatus[0].IsModifiedRawIntent == true) {
                  this._NodeObj.Status = 'Live but content need updates'
                } else if (fetchSingleRecordStatus[0].IsContentApprovedRawIntent == true) {
                  this._NodeObj.Status = 'New and content is approved'
                } else if (fetchSingleRecordStatus[0].IsNewRawIntent == true) {
                  this._NodeObj.Status = 'Not live'
                }


                this._NodeObj.SampleResponses = fetchSingleRecordStatus[0].SampleResponses


              } else {
                this._NodeObj.Status = fetchSignleRecord.Status
              }
            }
          }
        } else {
          this._NodeObj = new Nodes()
        }
      }
    }

    //Following code is to remove rightclick instead of node.
    //to get a connector
    let selectedConnector: ConnectorModel = this.checkDiagram.selectedItems.connectors![0];
    if (selectedNode && selectedNode.annotations && selectedNode.annotations.length > 0) {
      //get a node annotation object
      let label = selectedNode.annotations[0];
    }

    if (selectedConnector && selectedConnector.annotations && selectedConnector.annotations.length > 0) {
      //get a connector annotation object
      let label = selectedConnector.annotations[0];
    }
    if (!(selectedNode || selectedConnector)) {
      //cancel a event if it is a diagram.
      args.cancel = true;
    }
  }

  public contextMenuClick(args: MenuEventArgs): void {
    if (args.item.id === "SetIntent") {
      this.isPropertyBlock = true
    } else if (args.item.id === 'delete') {

      let selectedNode: NodeModel = this.checkDiagram.selectedItems.nodes![0];
      if (selectedNode.annotations!.length > 0) {
        if (this.nodeObjArr.length > 0) {
          let selectedNodeName = this.checkDiagram.selectedItems.nodes![0].annotations![0].content

          var checkSlotExists = this.nodeObjArr.find((x: { Question: string; }) => x.Question == selectedNodeName)

          if (checkSlotExists) {
            const index = this.nodeObjArr.indexOf(checkSlotExists);
            if (index !== -1) {
              this.nodeObjArr.splice(index, 1);
              this.checkDiagram.cut();
            }
          }
        }
      } else {
        this.checkDiagram.cut();
      }
    }
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  //Save the diagram.
  saveFlowchart() {
    // debugger
    // this._isSaveDiagramLoader = true
    if (this.diagram) {
      this.saveDiagram(this.diagram.saveDiagram());
    }
    else if (this.viewdiagram) {
      this.saveDiagram(this.viewdiagram.saveDiagram());
    }
    // this.saveToDB();
  }

  public saveDiagram(data: string): void {
    // debugger
    // let dataStr: string = 'data:text/json;charset=utf-8,' + encodeURIComponent(data);
    // let a: HTMLAnchorElement = document.createElement('a');
    // a.href = dataStr;
    // a.download = 'Diagram.json';
    // document.body.appendChild(a);
    // a.click();
    // a.remove();

    // this.diagramObj = JSON.stringify(data) // added to store diagram object into DB.
    this.diagramArrJson = data
    this.diagramArr = JSON.parse(data)

    this.nodesArr = this.diagramArr.nodes
    this.connectorsArr = this.diagramArr.connectors
  }

  //Fetch nodes and connectors dynamically if already saved in DB
  fetchNodesAndConnectorsFromDB() {

    this.flowName = this._selectedRowData.FlowName
    if (this._selectedRowData.FlowDiagramJson != "") {
      let selectedRowFlowDiagram = JSON.parse(this._selectedRowData.FlowDiagramJson)

      this.diagramArr = JSON.parse(this._selectedRowData.FlowDiagramJson)


      this.nodes = selectedRowFlowDiagram.nodes
      this.connectors = selectedRowFlowDiagram.connectors

      this.isCreateFlowchart = false
    } if (this._selectedRowData.ClickableFlowsJson != "") {

      let selectedRowClickableFlowsJson = JSON.parse(this._selectedRowData.ClickableFlowsJson)
      this.nodeObjArr = selectedRowClickableFlowsJson
    }
  }

  //Fetch getCFlowsParentIntents
  getAllClickableFlowParentIntentsData() {


    this.clickableFlowService.getClickableFlowParentIntentsData().subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {


        this.parentIntentDataList = res.Data

        this.DistinctParentIntentDataList = Array.from(this.parentIntentDataList.reduce((m: any, t: any) => m.set(t.ParentIntent, t), new Map()).values());


      } else if (res.Error) {

      }

    })

  }

  //Fetch secion name 
  fetchFaqList() {
    this._isSaveDiagramLoader = true
    this.faqconfigservice.getAllFaqRecords(this._NodeObj).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {

        // this._hideEmptyRow = false;
        // this.faqListArr = []
        // this.faqListArr = res.Data;
        this._isSaveDiagramLoader = false
        this.FaqListArr = res.Data
        this.TempForUSer = res.Data
        // this.FaqListArr = this.FaqListArr.filter((x: { ResponseType: string; }) => x.ResponseType.toUpperCase() == 'LIST');


        for (let i of this.DistinctParentIntentDataList) {
          this.FaqListArr = this.FaqListArr.filter((x: { IntentName: string; }) => x.IntentName !== i.ParentIntent);
        }

        let filteredArr: any[] = [];
        let result: any = [];

        result = Array.from(this.FaqListArr.reduce((m: any, t: any) => m.set(t.SectionName, t), new Map()).values());

        for (let i = 0; i < result.length; i++) {
          // if (result[i].SectionName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filteredArr.push({
            name: result[i].SectionName,
            value: result[i].SectionName
          });
          // }
        }
        this.sectionNameListArr = filteredArr;
      }
    })
  }

  // filterSectionNameAE(event: any) {

  //   let filteredArr: any[] = [];
  //   let query = event.query;
  //   let result: any = [];

  //   result = Array.from(this.FaqListArr.reduce((m: any, t: any) => m.set(t.SectionName, t), new Map()).values());

  //   for (let i = 0; i < result.length; i++) {
  //     if (result[i].SectionName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
  //       filteredArr.push({
  //         name: result[i].SectionName,
  //         value: result[i].SectionName
  //       });
  //     }
  //   }
  //   this._filteredSectionNameArr = filteredArr;
  // }

  SelectSecionName(data: any) {

    this._isSectionNameErrorFlag = false

    this.selectedSectionName = data.value.name
    this._NodeObj.IntentName = ""
    this._NodeObj.Question = ""
    this._NodeObj.Status = ""
    this._NodeObj.RelatedIntent = ""
    this._NodeObj.ResponceType = ""


    this.SperateOutIntents(undefined);

  }


  SperateOutIntents(vFetchSignleRecordEdit: any) {

    let filtered: any[] = [];
    let result: any[] = [];
    this.intentNameList = this.FaqListArr.filter((x: { SectionName: string; }) => x.SectionName == this.selectedSectionName)
    result = Array.from(this.intentNameList.reduce((m: any, t: any) => m.set(t.IntentName, t), new Map()).values());

    for (let i = 0; i < result.length; i++) {
      filtered.push({
        name: result[i].IntentName,
        value: result[i].IntentName
      });
    }

    this.intentNameListArr = filtered;
    if (this.nodeObjArr.length > 0) {
      for (let i of this.intentNameList) {
        let fetchExstingIntentName = this.nodeObjArr.find((x: { Question: string; }) => x.Question == i.Questions)

        if (fetchExstingIntentName) {
          this.intentNameListArr = this.intentNameListArr.filter((x: { name: string; }) => x.name != fetchExstingIntentName.IntentName.name)
        }
      }

      if (vFetchSignleRecordEdit) {
        var isPresent = this.intentNameListArr.find((x: { name: string; }) => x.name == this._NodeObj.IntentName.name);

        if (!isPresent)
          this.intentNameListArr.push(this._NodeObj.IntentName);
      }
    }

    // if (vFetchSignleRecordEdit) {
    //   this.intentNameListArr.push(this._NodeObj.IntentName);
    // }
  }

  // onKeypressEvent(event: any) {
  //   
  //   let ff = event.target.value
  //   let dd = this._filteredSectionNameArr.find((x: { name: string; }) => x.name == ff)
  //   if (dd || ff == "") {
  //     this._isSectionNameSelected = false
  //     this.SelectSecionName(dd)
  //   } else {
  //     this._isSectionNameSelected = true
  //   }
  // }

  //Fetch intent name
  // fetchIntentName() {
  //   this.intentNameList = [
  //     { Name: 'CON120820211147', Status: true },
  //     { Name: 'FAQ121620211121', Status: true },
  //     { Name: 'APAddPayee', Status: false },
  //     { Name: 'FAQ113020211057', Status: true },
  //     { Name: 'FAQLSBALoans', Status: false },
  //     { Name: 'FAQMiscBye', Status: false },
  //     { Name: 'FAQGreet', Status: false },
  //     { Name: 'FAQSTLove', Status: false },
  //     { Name: 'FAQSTHowSmart', Status: false },
  //     { Name: 'FAQSTJoke', Status: false },
  //     { Name: 'FAQSTLookAround', Status: false },
  //   ]
  // }

  filterIntentNameAE(event: any) {

    let filtered: any[] = [];
    let query = event.query;
    let result: any = [];


    result = Array.from(this.intentNameList.reduce((m: any, t: any) => m.set(t.IntentName, t), new Map()).values());

    for (let i = 0; i < result.length; i++) {
      if (result[i].IntentName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push({
          name: result[i].IntentName,
          value: result[i].IntentName
        });
      }
    }
    this._filteredIntentNameArr = filtered;
  }

  SelectIntentName(data: any) {

    this._isIntentNameErrorFlag = false
    let intentNameRecord
    this.selectedIntentName = data.value.name
    intentNameRecord = this.intentNameList.filter((x: { IntentName: string; }) => x.IntentName == this.selectedIntentName)

    if (intentNameRecord.length != 0) {
      this._NodeObj.Question = intentNameRecord[0].Questions

      if (intentNameRecord[0].IsLiveRawIntent == true) {
        this._NodeObj.Status = 'Live'
      } else if (intentNameRecord[0].IsModifiedRawIntent == true) {
        this._NodeObj.Status = 'Live but content need updates'
      } else if (intentNameRecord[0].IsContentApprovedRawIntent == true) {
        this._NodeObj.Status = 'New and content is approved'
      } else if (intentNameRecord[0].IsNewRawIntent == true) {
        this._NodeObj.Status = 'Not live'
      }

      this._NodeObj.RelatedIntent = intentNameRecord[0].RelatedIntent
      this._NodeObj.ResponceType = intentNameRecord[0].ResponseType
      this._NodeObj.SampleResponses = intentNameRecord[0].SampleResponses

    }

  }

  checkWhiteSpace(inputVal: any) {
    var res = inputVal.trim()
    var reslength = res.length
    if (reslength === 0) {
      this._isWhiteSpace = true
      this._isFlowName = true
    } else {
      this._isWhiteSpace = false
      this._isFlowName = false
    }
  }

  setIntentName() {

    if (!this._NodeObj.SectionName) {
      this._isSectionNameErrorFlag = true
    } else if (!this._NodeObj.IntentName) {
      this._isIntentNameErrorFlag = true
    } else {

      this.checkDiagram.selectedItems.nodes![0].annotations = []
      this.selectedItem = this.checkDiagram.selectedItems.nodes![0];
      // this.selectedItem.annotations?.push({ content: this._NodeObj.Question });
      let nodeID = this.checkDiagram.selectedItems.nodes![0].id
      // let nodeLabel = this.checkDiagram.selectedItems.nodes![0].annotations![0].content
      let nodeLabel = this._NodeObj.Question
      // selectedItem.style.fill = color;
      let annotation: ShapeAnnotationModel[] = [{
        id: nodeID,
        content: nodeLabel,
        constraints: AnnotationConstraints.ReadOnly
      }]
      // this.checkDiagram.removeLabels(this.selectedItem, annotation);
      this.checkDiagram.addLabels(this.selectedItem, annotation);
      this.saveFlowchart();

      if (this.nodeObjArr.length > 0) {
        this.nodeObjArr = this.nodeObjArr.filter((x: { Question: string; }) => x.Question !== nodeLabel);
      }

      this.nodeObjArr.push(this._NodeObj)
      this._NodeObj = new Nodes()

      this.isPropertyBlock = false
    }
  }

  _isEmptyNodeName: boolean = false
  _isNodesNotconnected: boolean = false
  saveMainDiagram() {
    // debugger
    this.saveFlowchart();

    for (let i of this.nodesArr) {
      // let fetchSignleRecord = i.annotations[0].find((x: { content: string; }) => x.content == '')
      if (i.annotations[0].content == "") {
        this._isEmptyNodeName = true
      } else {
        this._isEmptyNodeName = false
      }
    }
    for (let i of this.connectorsArr) {
      if (i.sourceID == "" && i.targetID == "") {
        this._isNodesNotconnected = true
      } else {
        this._isNodesNotconnected = false
      }
    }

    if (!this.flowName || this._isWhiteSpace == true) {
      this._isFlowName = true
    } else if (this._isEmptyNodeName == true) {

      this.popupImg = "../../../assets/images/update_error.png"
      this.popupContent = "Oops!"
      this.popupContentError = "Please set intents for the nodes by right clicking on the node and selecting <b>Set Intent</b> option";
      this.triggerModal(this.modalWarningAndInfo)
    } else if (this.nodeObjArr.length == 0) {

      this.popupImg = "../../../assets/images/update_error.png"
      this.popupContent = "Oops!"
      this.popupContentError = "Nodes are not added to the flow yet. Cannot save the diagram.";

      this.triggerModal(this.modalWarningAndInfo)
    } else if (this.connectorsArr.length == 0 || this._isNodesNotconnected == true) {

      this.popupImg = "../../../assets/images/update_error.png"
      this.popupContent = "Oops!"
      this.popupContentError = "You have to link the nodes by arrow to complete the diagram.";

      this.triggerModal(this.modalWarningAndInfo)
    } else {

      // for (let i of this.nodesArr) {
      //   if (i.annotations.length == 0) {
      //     this.popupImg = "../../../assets/images/update_error.png"
      //     this.popupContent = "Oops!"
      //     this.popupContentError = "Nodes are not added to the flow yet. Cannot save the diagram.";

      //     this.triggerModal(this.modalWarningAndInfo)
      //   }
      // }
      this._isSaveDiagramLoader = true
      this.mainArr = []

      // this.saveFlowchart()

      let selectedConnector: any = []
      let nextNodeList: any = []

      for (let k = 0; k < this.nodesArr.length; k++) {
        this._RelatedIntentObj = new RelatedIntent()
        selectedConnector = []
        nextNodeList = []



        let fetchSignleRecord = this.nodeObjArr.find((x: { Question: string; }) => x.Question == this.nodesArr[k].annotations![0].content)

        this._RelatedIntentObj.SectionName = fetchSignleRecord.SectionName.name == undefined ? fetchSignleRecord.SectionName : fetchSignleRecord.SectionName.name
        this._RelatedIntentObj.IntentName = fetchSignleRecord.IntentName.name == undefined ? fetchSignleRecord.IntentName : fetchSignleRecord.IntentName.name
        this._RelatedIntentObj.Question = fetchSignleRecord.Question

        selectedConnector = this.connectorsArr.filter((x: { sourceID: string; }) => x.sourceID == this.nodesArr[k].id)
        if (selectedConnector.length > 0) {

          for (let i of selectedConnector) {
            nextNodeList.push(this.nodesArr.filter((x: { id: string; }) => x.id == i.targetID))
          }
          for (let i of nextNodeList) {
            let fetchIntentName = this.nodeObjArr.find((x: { Question: string; }) => x.Question == i[0].annotations![0].content)
            this._RelatedIntentObj.RelatedIntent.push(fetchIntentName.IntentName.name == undefined ? fetchIntentName.IntentName : fetchIntentName.IntentName.name)
          }
        }

        this.mainArr.push(this._RelatedIntentObj)

      }
      this.mainArr = JSON.stringify(this.mainArr)

      var data = {
        FlowName: this.flowName,
        FlowDiagramJson: this.diagramArrJson,
        ClickableFlowsJson: this.mainArr,
        Language: 'EN'
      }
      this.clickableFlowService.InsertClickableFlows(data).subscribe((res: any) => {

        if (res.Status.toUpperCase() == "SUCCESS") {
          this._isSaveDiagramLoader = false
          this.inputdata.emit("")
          this.popupImg = "../../../assets/images/Update.png"
          this.popupContent = res.Message;
          this.popupContentError = "";
          this.triggerModal(this.modalWarningAndInfo)
        }
        else if (res.Error) {
          this._isSaveDiagramLoader = false
          this.popupImg = "../../../assets/images/update_error.png"
          this.popupContent = "Oops!"
          this.popupContentError = res.Error;

          this.triggerModal(this.modalWarningAndInfo)
        }
      })
    }
  }

  closePropertyBlock() {
    this.isPropertyBlock = false
    this._isIntentNameErrorFlag = false
    this._isSectionNameErrorFlag = false
  }

  cancelMainDiagram() {
    this.inputdata.emit("")
  }

  fetchRIlist: any = []
  AEparameters: any
  fetchSingleRecordData(vIntentName: any) {

    this.AEparameters = new faqEntities();
    this.AEparameters.IntentMaintenance.IntentName = vIntentName

    this.faqconfigservice.GetRawIntentByName(this.AEparameters.IntentMaintenance).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {


        if (res.Data.length != 0) {
          this.AEparameters = new faqEntities();
          this.AEparameters.IntentMaintenance = res.Data;

          this._NodeObj.RelatedIntent = this.AEparameters.IntentMaintenance.RelatedIntent;
          this._NodeObj.ResponceType = this.AEparameters.IntentMaintenance.ResponseType;
        }
      }
      else if (res.Error) {
        // this.popupImg = "../../../assets/images/CommonError.png";
        // this.popupContent = "Oops!";
        // this.popupContentError = res.Error;

        // this.triggerModal(this.modalWarningAndInfo)
      }
      // return strRelatedIntent;
    })
  }
}
