import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { HttpCommonReq } from '../../services/HttpCommonReq'
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ConfusionMatrixService {

  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) { 

    this.configUrls = {
      GetConfusionMatrixData: 'api/ConfusionMatrix/GetConfusionMatrixData',
      SearchConfusionMatrixData: 'api/ConfusionMatrix/SearchConfusionMatrixData'
    }
  }

  GetConfusionMatrixData() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.GetConfusionMatrixData)
  }

  SearchConfusionMatrixData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.SearchConfusionMatrixData, data)
  }
}
