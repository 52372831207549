import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContextFlowService } from '../../services/ContextFlow/context-flow.service';
import { faqEntities } from '../../faq/fqaentities'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-context-flow',
  templateUrl: './context-flow.component.html',
  styleUrls: ['./context-flow.component.css'],
  providers: [DatePipe]
})
export class ContextFlowComponent implements OnInit {

  isLoader: boolean = false  //Need to change as true when data comes from DB
  isDefaultOptionBlockLoader: boolean = false  //Need to change as true when data comes from DB
  _hideEmptyRow: boolean = false;

  _ifNoRecords: boolean = false;
  _ifNoRecordsForDF: boolean = false;

  _hideContentDuringLoading: boolean = false;
  _hideOptionContentDuringLoading: boolean = false
  _IsAnythingChange = false;
  _disableSaveBtn = true;
  _isDefaultOptionBlockEmpty: boolean = true;
  newrow: boolean = false;
  editRow: boolean = false;
  _showFlowChartScreen: boolean = false

  defaultOptionListArr: any = []
  defaultOptionListHeadersArr: any = []
  contextListHeadersArr: any = []
  contextListArr: any = []
  singleDataArr: any = []

  popupImg: string;
  popupContent: string;
  popupContentError: string
  closeModal: any

  first: number = 0;

  selectedRowID: any;
  sendDataToDelete: any
  selectedContextName: any
  _selectedDefaultOptionToDelete: any
  selectedRow: any

  addRecord = new faqEntities() //For CLickable flow
  addIntentRecord = new faqEntities() //for iNtent
  updatedParameters = new faqEntities()

  _URL_REGEXP = /^(\s+\S+\s*)*(?!\s).*$/;

  _FromCLick: string

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;
  @ViewChild("modalDataDelete", { static: false }) public modalDataDelete: NgbModal;
  @ViewChild("addDefaultOption", { static: false }) public addDefaultOption: NgbModal;
  @ViewChild("modalDefaultOptionDataDelete", { static: false }) public modalDefaultOptionDataDelete: NgbModal;

  constructor(private modalService: NgbModal, private datePipe: DatePipe, public contextFlowService: ContextFlowService) { }

  ngOnInit(): void {
    this.defaultOptionListHeaders()

    this.contextListHeaders()
    this.fetchContextDetails()
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  defaultOptionListHeaders() {
    this.defaultOptionListHeadersArr = [
      { field: 'Questions', header: 'Default Option', width: "14%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'FAQ' },
      { field: 'DisplayMessage', header: 'Answer', width: "2%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'Answer' },
      { field: 'BondModifiedOn', header: '', width: "5%", isEditable: "false", isSort: "false", textAlignment: "center", value: 'b' }
    ]
  }

  fetchDefaultOptionDetails(vSelectedRow: any) {

    if (vSelectedRow.Context) {
      var FetchFLowDetaislBYID = new faqEntities();
      FetchFLowDetaislBYID.ClicakbleFlowMaster.ClickableFlowsMasterID = vSelectedRow.ClickableFlowsMasterID
      FetchFLowDetaislBYID.ClicakbleFlowMaster.Context = vSelectedRow.Context
      this.selectedContextName = vSelectedRow.Context
      this.isDefaultOptionBlockLoader = true;
      this.contextFlowService.GetContextOptions(FetchFLowDetaislBYID.ClicakbleFlowMaster).subscribe((res: any) => {

        if (res.Status.toUpperCase() == "SUCCESS") {
          this.defaultOptionListArr = res.Data

          this._hideEmptyRow = false;
          if (this.defaultOptionListArr.length == 0) {
            setTimeout(() => {
              this._ifNoRecordsForDF = true
            }, 500);
          } else {
            this._ifNoRecordsForDF = false
            this._isDefaultOptionBlockEmpty = false
          }

          this.isDefaultOptionBlockLoader = false;
        }
        else if (res.Error) {
          this.popupImg = "../../../assets/images/CommonError.png";
          this.popupContent = "Oops!";
          this.popupContentError = res.Error;

          this.triggerModal(this.modalWarningAndInfo)
        }
      })
    }
  }

  contextListHeaders() {
    this.contextListHeadersArr = [
      { field: 'Context', header: 'Context', width: "15%", isEditable: "false", isSort: "false", textAlignment: "left", value: 'FAQ' },
      { field: 'BondModifiedOn', header: '', width: "6%", isEditable: "false", isSort: "false", textAlignment: "center", value: 'b' }
    ]
  }

  fetchContextDetails() {

    this.isLoader = true;

    this.contextFlowService.getAllContextFlowRecords().subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {

        this.contextListArr = res.Data;
        this.contextListArr = this.contextListArr.filter((x: { Context: string; }) => x.Context !== "");

        this._hideEmptyRow = false;

        if (this.contextListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 500);
        } else {
          this._ifNoRecords = false
          this.fetchDefaultOptionDetails(this.contextListArr[0]);
          this._selectedContextObj = this.contextListArr[0]

        }

        this.isLoader = false;

      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  _selectedContextObj: any
  recordInfo(vSelectedRow: any) {

    this._isDefaultOptionBlockEmpty = false

    this.selectedContextName = vSelectedRow.Context

    this._selectedContextObj = vSelectedRow

    this.fetchDefaultOptionDetails(vSelectedRow);
  }

  addNewContextRow() {

    this.newrow = true;
    this._ifNoRecords = false;
    this.editRow = false

    var data = {
      Context: ""
    }

    if (this.contextListArr.length == 0) {
      this.contextListArr.push(data);
      this._hideEmptyRow = true;
    }

    this.addRecord = new faqEntities();
    this.first = 0;
  }

  addContextRecord(vDataForAdd: any) {

    this.isLoader = true
    this.newrow = false;
    this._IsAnythingChange = true

    this.contextFlowService.addContextRecord(vDataForAdd).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {

        this.isLoader = false;

        this.first = 0;

        this.popupImg = "../../../assets/images/file-plus.png"
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)

        this.fetchContextDetails();

      } else if (res.Error) {
        this.isLoader = false;
        this.popupImg = "../../../assets/images/file-plus-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;
      }
    })

    this.contextListArr = this.contextListArr.filter((x: { Context: string; }) => x.Context !== "");

    if (this.contextListArr.length == 0) {
      setTimeout(() => {
        this._hideEmptyRow = true
      }, 1000);
    } else {
      this._hideEmptyRow = false
    }
  }

  editContextRecord(vRecordID: any, data: any) {

    this.selectedRowID = vRecordID

    this.addRecord.ClicakbleFlowMaster = data;
    this.newrow = false
    this.editRow = true;
  }

  updateContextRecord(vDataForUpdate: any) {
    this.isLoader = true;
    this.editRow = false

    this.updatedParameters = new faqEntities();
    this.updatedParameters.ClicakbleFlowMaster.ClickableFlowsMasterID = vDataForUpdate.ClickableFlowsMasterID;
    this.updatedParameters.ClicakbleFlowMaster.Context = vDataForUpdate.Context;
    this.updatedParameters.ClicakbleFlowMaster.FlowDiagramJson = "";
    this.updatedParameters.ClicakbleFlowMaster.ClickableFlowsJson = "";

    this.contextFlowService.updateContextRecord(this.updatedParameters.ClicakbleFlowMaster).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {

        this.singleDataArr = res.Data
        this.isLoader = false;

        this.first = 0;

        this.popupImg = "../../../assets/images/Update.png"
        this.popupContent = res.Message;
        this.popupContentError = "";
        this.triggerModal(this.modalWarningAndInfo)

        this.fetchContextDetails();

      } else if (res.Error) {

        this.editRow = false;
        this.popupImg = "../../../assets/images/update_error.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  public boundDeleteRow = this.deletedCurrentRow.bind(this);

  /* popup for add default option */
  triggerAddDefaultListModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal', backdrop: 'static', keyboard: false }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReasonForIntentList(res)}`;
    });
  }

  private getDismissReasonForIntentList(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  DecideAddEditForDefultOptions(vSelectedTestFrom: string, vOptionDetails: any) {

    if (vSelectedTestFrom == "ADD") {
      this.addDefaultOptionRecord(vOptionDetails);
    }
    else {
      this.editDefaultOptionRecord();
    }
  }

  addDefaultOptionRecord(vOptionDetails: any) {

    this.isDefaultOptionBlockLoader = true;
    this._hideOptionContentDuringLoading = true

    if (this.selectedContextName) {
      vOptionDetails.sectionName = this.selectedContextName;
      vOptionDetails.IsEndOfConversation = true;
      vOptionDetails.IsCampaignFAQ = false;

      var myDate = new Date();
      var myDate1 = this.datePipe.transform(myDate, 'mmss');
      vOptionDetails.IntentName = "CNText" + this.selectedContextName + myDate1;
      vOptionDetails.ResponseType = "Text"
      vOptionDetails.Context = this.selectedContextName

      this.contextFlowService.addDefaultOptionRecord(vOptionDetails).subscribe((res: any) => {
        if (res.Status.toUpperCase() == "SUCCESS") {
          this.isDefaultOptionBlockLoader = false;
          this._hideOptionContentDuringLoading = false;

          this.first = 0;

          this.fetchDefaultOptionDetails(this._selectedContextObj);

          this.popupImg = "../../../assets/images/file-plus.png"
          this.popupContent = res.Message;
          this.popupContentError = "";

          this.triggerModal(this.modalWarningAndInfo)
        } else if (res.Error) {
          this.isLoader = false;
          this.popupImg = "../../../assets/images/file-plus-error.png";
          this.popupContent = "Oops!";
          this.popupContentError = res.Error;
        }
      })
    }
  }

  editDefaultOptionRecord() {

    this.isDefaultOptionBlockLoader = true;
    this.contextFlowService.updateDefaultOptionRecord(this.addIntentRecord.IntentMaintenance).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        this.singleDataArr = res.Data
        this.isLoader = false;
        this.editRow = false;
        this._hideContentDuringLoading = false

        this.first = 0;

        this.popupImg = "../../../assets/images/Update.png"
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)

        this.fetchDefaultOptionDetails(this._selectedContextObj);

      } else if (res.Error) {

        this.editRow = false;
        this.popupImg = "../../../assets/images/update_error.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  cancelNewRow() {

    this.newrow = false;
    this.editRow = false;

    this.addRecord.ClicakbleFlowMaster.Context = "";

    this.contextListArr = this.contextListArr.filter((x: { Context: string; }) => x.Context !== "");
    if (this.contextListArr.length == 0) {
      setTimeout(() => {
        this._ifNoRecords = true
      }, 1000);
    } else {
      this._ifNoRecords = false
    }
  }

  cancelEditedRow() {

    this.newrow = false;
    this.editRow = false;
  }

  addNewOption() {
    this._FromCLick = "ADD"

    this.addIntentRecord = new faqEntities();
    this.triggerAddDefaultListModal(this.addDefaultOption)
  }

  EditOption(_EditSelectedRow: any) {

    this._FromCLick = "EDIT"
    this.addIntentRecord = new faqEntities();
    this.addIntentRecord.IntentMaintenance.Questions = _EditSelectedRow.Questions
    this.addIntentRecord.IntentMaintenance.DisplayMessage = _EditSelectedRow.DisplayMessage
    this.addIntentRecord.IntentMaintenance.IntentId = _EditSelectedRow.IntentId
    this.addIntentRecord.IntentMaintenance.IntentName = _EditSelectedRow.Intent

    this.triggerAddDefaultListModal(this.addDefaultOption)
  }

  OpenDeletePopup(vEditRowData: any) {
    this.popupImg = "../../../assets/images/delete_popup_icon.png";
    this.popupContent = "Deleting context will also delete the default options and respective flows. Do you want to continue?";
    this.popupContentError = "";
    this.sendDataToDelete = vEditRowData
    this.triggerModal(this.modalDataDelete)
  }

  deletedCurrentRow() {

    this.isLoader = true
    this._hideContentDuringLoading = true

    this.contextFlowService.deleteContextRecord(this.sendDataToDelete).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {

        this.isLoader = false;
        this._hideContentDuringLoading = false

        this.fetchContextDetails();

        this.addRecord = new faqEntities()//NTD

        this.popupContentError = "";
        this.popupImg = "../../../assets/images/delete-popup.png";
        this.popupContent = "Context deleted successfully."
        this.triggerModal(this.modalWarningAndInfo)

      }
      else {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }


  cancelDefaultOptionPopup() {
    this.addIntentRecord.IntentMaintenance.Questions = ""
    this.addIntentRecord.IntentMaintenance.DisplayMessage = ""
  }

  OpenDefaultOptionDeletePopup(vRowData: any) {
    this.popupImg = "../../../assets/images/delete_popup_icon.png";
    this.popupContent = "Do you want to delete the option?";
    this.popupContentError = "";
    this._selectedDefaultOptionToDelete = vRowData
    this.triggerModal(this.modalDefaultOptionDataDelete)
  }

  deletedDefualtOptionCurrentRow() {

    this.isDefaultOptionBlockLoader = true
    this._hideOptionContentDuringLoading = true

    this.contextFlowService.deletedDefualtOption(this._selectedDefaultOptionToDelete).subscribe((res: any) => {


      if (res.Status.toUpperCase() == "SUCCESS") {

        this.isDefaultOptionBlockLoader = false;
        this._hideOptionContentDuringLoading = false

        this.addRecord = new faqEntities()//nTD

        this.popupContentError = "";
        this.popupImg = "../../../assets/images/delete-popup.png";
        this.popupContent = res.Message
        this.triggerModal(this.modalWarningAndInfo)

        this.fetchDefaultOptionDetails(this._selectedContextObj);//NTD

      }
      else {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  public boundDefaultOptionDeleteRow = this.deletedDefualtOptionCurrentRow.bind(this);

  OpenConversationDesignFlow(vIntentData: any) {
    //get data again here GetIntentByID
    this.GetIntentByID(vIntentData)

  }

  GetIntentByID(vIntentDataObj: any) {

    this.contextFlowService.GetIntentByID(vIntentDataObj).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {

        this.selectedRow = res.Data
        this._showFlowChartScreen = true
      }
      else {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  contextFlowListPage(SuccVal: any) {
    this._showFlowChartScreen = false
  }


  onchangeControlles(event: any, from: any, value: any) {

    if (from.toUpperCase() == "EDIT") {
      if (!this._URL_REGEXP.test(value)) {
        this._disableSaveBtn = true
      }
      else {
        if (value == "")
          this._disableSaveBtn = true
        else
          this._disableSaveBtn = false
      }
    }
    else {
      if (!this._URL_REGEXP.test(this.addRecord.ClicakbleFlowMaster.Context))
        this._disableSaveBtn = true
      else {

        if (this.addRecord.ClicakbleFlowMaster.Context == "")
          this._disableSaveBtn = true
        else
          this._disableSaveBtn = false
      }
    }
  }


  onchangeControllesDI(event: any, from: any, value: any) {

    if (!this._URL_REGEXP.test(this.addIntentRecord.IntentMaintenance.Questions)
      || !this._URL_REGEXP.test(this.addIntentRecord.IntentMaintenance.DisplayMessage)) {

      // if (event.keyCode == 13 || event.keyCode == 8) {
      //   if (this.addIntentRecord.IntentMaintenance.DisplayMessage.trim() == "" || this.addIntentRecord.IntentMaintenance.Questions.trim()=="") {
      //     this._disableSaveBtn = true
      //   }
      //   else {
      //     this._disableSaveBtn = false
      //   }
      // }
      // else
      this._disableSaveBtn = true
    }
    else {

      this._disableSaveBtn = false
    }
  }

  CheckforWhiteSpace(vEvent: any) {

    if (vEvent.keyCode == 32) {
      this._disableSaveBtn = true

      this.popupContentError = "";
      this.popupImg = "../../../assets/images/CommonError.png";
      this.popupContent = "Spaces are not allowed."
      this.triggerModal(this.modalWarningAndInfo)
    }
    else {
      this._disableSaveBtn = false
    }
  }
}
