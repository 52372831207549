import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';

import { HttpCommonReq } from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})
export class TestBotService {

  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {
      getPersona: 'api/Persona/GetPersona?vEmailId=',
      sendMessageToBond: 'api/BondChatBot/SendMessageToBondHub',
      addUserRating: "api/BondChatBot/InsertUserRating?vRating=",
      addConversationMsgRating: "api/BondChatBot/InsertConversationMessageRating",
      GetBotGradientColr: "api/BondChatBot/GetBotGradientColor",
      UpdateBotGradColr: "api/BondChatBot/UpdateBotGradientColor",
      GetCardDetail: "api/AccountDetail/GetCardDetail",
      UploadFile: "api/AccountOpen/UploadFile",
      UpdateAccountDetails: "api/BondChatBot/UpdateAccountDetails?vSessionId=",
      InsertAccountDetails: "api/AccountOpen/InsertAccLinkDetails",
      GetLinkToken: "api/AccountOpen/GetLinkToken?vPackageName"
    }
  }

  sendMessageToBondHub(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.sendMessageToBond, data)
  }

  userRating(data: any) {
    ;
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.addUserRating + data, "")
  }

  InsertConversationMessageRating(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.addConversationMsgRating, data)
  }

  GetBotGradientColor() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.GetBotGradientColr)
  }

  UpdateBotGradientColor(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.UpdateBotGradColr, data)
  }

  GetCardDetail() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.GetCardDetail)
  }

  UploadFile(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.postFile(this.configUrls.UploadFile, data)
  }

  UpdateAccountDetails(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.UpdateAccountDetails + data.sessionId, "")
  }

  InsertAccLinkDetails(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.InsertAccountDetails, data)
  }

  GetLinkToken(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.GetLinkToken + data.packageName, "")
  }
}
