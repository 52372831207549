import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http'
import { HttpCommonReq } from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})
export class TenantSelectionService {

  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {
      getTenants: 'api/Tenant/GetTenants',
      selectTenant: 'api/Tenant/SelectTenant',
      UpdateAccessToken: 'api/AuthorizeAPI/UpdateAccessToken'
    }
  }

  getTenants() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.getTenants)
  }

  UpdateSelectedTenant(data: any) {
    
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.selectTenant, data)
  }
}
