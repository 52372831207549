import { Component, Directive, EventEmitter, OnInit } from '@angular/core';
import { FaqService } from '../../services/faq/faq.service';
import { faqEntities } from '../../faq/fqaentities'
import { ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Input } from '@angular/core';
import { Output } from '@angular/core';

import { TestBotService } from '../../services/TestBot/test-bot.service'

import * as $ from 'jquery'
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'app-addfaq',
  templateUrl: './addfaq.component.html',
  styleUrls: ['./addfaq.component.css']
})

export class AddfaqComponent implements OnInit {

  constructor(public faqconfigservice: FaqService, private modalService: NgbModal, public testBotService: TestBotService) { }

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;
  @ViewChild("modalDataDelete", { static: false }) public modalDataDelete: NgbModal;
  @ViewChild("modalCreateData", { static: false }) public modalCreateData: NgbModal;
  @ViewChild("ConfirmationModal", { static: false }) public confirmationModal: NgbModal;
  @ViewChild("ConfirmationModalCampaign", { static: false }) public confirmationModalCampaign: NgbModal;
  @Output() inputdata: EventEmitter<any> = new EventEmitter<any>();

  _onPageLodeCnt: number = 0
  _RoleName = window.localStorage.getItem("RoleName");

  _isChecked: boolean = false
  _checkvalue: any

  _CampiagnFAQ: boolean = true;
  _IsCampiagnFAQ: boolean = false;

  _OtherData: any
  dataTodelete: any
  mainData: any

  selectedSectionName: any = [];
  filteredSectionName: any = [];
  sectionName: any = [];

  language: any = [];
  selectedLanguage: any = "English"

  hoverFilterArr: any = [];
  selectedFilterOption: any;

  responceType: any = [];
  selectedResponceType: any = [];

  text: string;

  isHide: boolean = true
  chageFilterInput: boolean = false;
  selectedFieldOutline: boolean = false;
  greyFieldOutline: boolean = false;
  filterArr: any = [];
  toggle: boolean = false;
  selectedValues: any = [];
  _singlewordError: string = "";
  _single: string = "";


  count: any = 0;

  parameters = new faqEntities();
  sendData = new faqEntities();
  addUtterance = new faqEntities();

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string
  closeModal: any

  generatedDataOfMU: string
  vMainData: any;
  vStrMainData: string;

  TempVarMulitpleUttr: string = ""

  _PageName: any
  _EditRowID: any
  singleDataArr: any = [];
  _createDataArr: any = [];

  _TotalSinglewords: number = 0
  _TotalmultipleUtternces: number = 0

  _multipleUtterenceArr: any = [];
  _multipleUtterenceHeadersArr: any = [];

  _mutteranceDisable: boolean = false

  isLoader: boolean = true;

  _createData: boolean = true;
  first: number = 0;
  rows = 10;
  islastPageFlag: boolean;

  newrow: boolean = false;
  editrow: boolean = false
  _lemmaName: string;
  fetchDispID: any;
  _IntentMessageID: any;

  _SingleWordFlag: boolean = true;

  @Input() inputVariable: any;
  _inputData: any
  _faqListArr: any = []

  _IsClient: boolean = false;
  _IsContent: any

  _ispreviewClicked: boolean = false

  _isMultipleUtterence: boolean = false

  _isBotResponceNull: boolean = false

  isBlockExpand: boolean = false;

  _CurrentRowData: any

  _ViewClientRoleAccess: boolean = false;


  _IsDisbale: boolean = false;

  _singleword: any = [];
  _singlewordlist: any = []

  createData: boolean = false;
  createDataArr: any = [];

  _usermessage: string;
  _displaymessage: string;
  _intentName: string;

  _isSWDuplicate: boolean = true;

  _filteredRelatedIntentAE: any = []
  _selectedRelatedIntentAE: any = []

  selectedCities: string[] = [];
  _isCheckedRI: boolean = false

  selectedSectionNameAE: any = [];
  filteredSectionNameAE: any = [];

  _selectedRespType: any = [];

  chageFilterInputRI: boolean = false;
  selectedFieldOutlineRI: boolean = false;
  greyFieldOutlineRI: boolean = false;
  filterArrRI: any = [];
  toggleRI: boolean = false;
  selectedValuesRI: any = [];

  AEparameters = new faqEntities();

  _LinkArray: any = []
  _ListArray: any = []
  _TextArray: any = []
  _LTI: boolean = true;
  _SampleTextData: any = []

  RelativeQue1: any = ""
  RelativeQue2: any = ""
  RelativeQue3: any = ""

  _provirewQuestion: any = ""
  _provirewAnswer: any = ""

  _gradStartColor: any;
  _gradEndColor: any;

  _Models: any = []
  _selectedModel: any

  formats: string[] = ['bold', 'italic', 'underline', 'link', 'list'];

  _keyUpMultipleUtterenceArr: any
  _TraningModelNameList: any

  ngOnInit(): void {

    this._RoleName = window.localStorage.getItem("RoleName");

    if (this._RoleName === 'ClientView' || this._RoleName === 'Client') {
      this._ViewClientRoleAccess = true;
    }

    this._inputData = this.inputVariable


    this.fetchLanguage();
    this.fetchResponceType();

    debugger
    this._PageName = this._inputData.PageName
    this._EditRowID = this._inputData.EditRowID
    this._faqListArr = this._inputData.faqListArrData
    this._CurrentRowData = this._inputData.currentRowData
    this._TraningModelNameList = this._inputData.TraningModelNameList

    this.fetchModels();

    if (this._RoleName?.toUpperCase() == 'CLIENT') {
      this._IsClient = true
    }
    if (this._RoleName?.toUpperCase() == 'CONTENT') {
      this._IsContent = true
    }

    this.AEparameters = new faqEntities();

    this.selectedSectionNameAE = ""
    this.selectedValuesRI = []
    this.RelativeQue1 = ""
    this.RelativeQue2 = ""
    this.RelativeQue3 = ""

    this._provirewAnswer = ""
    this._provirewQuestion = ""

    this.fetchMultipleUtterenceHeaders();

    if (this._PageName.toUpperCase() == "EDIT") {
      this.fetchSingleRecord(this._EditRowID)
    }
    else {
      this._selectedRespType.name = "Text"
      this._isMultipleUtterence = true
    }

    this.GetBotGradientColor();


  }

  content: any
  onNgModelChange(val: any) {

    this.content = val.target.innerHTML
    this.content = this.content.replace("<div>", "<br>");
    console.log(this.content)

    // for (let j = 0; j < this.content.length; j++) {
    //   var s = this.content[j];
    //   let start = s.indexOf("<div");
    //   var end = s.indexOf(">");
    //   var result = s.substring(start, end + 1);

    //   if (result != "") {
    //     var newstr = s.replace(result, '');
    //     this.content[j] = newstr
    //   } else {
    //     this.content[j] = s
    //   }
    // }

    // this.content = this.content.join("")
  }

  // ngAfterViewInit() {
  //   // this.test()
  // }

  // test() {
  //   document.getElementById("LemmaMUTextarea")!.innerHTML = "<textarea class='muTextarea p-20px form-control advanceBlockTextArea' [(ngModel)]=' AEparameters.IntentMaintenance.MultipleUtterance' (keyup)='showAllCounts( AEparameters.IntentMaintenance.SingleWords,  AEparameters.IntentMaintenance.MultipleUtterance, $event)' (keyup)='validateUtterCount()' (keyup)='onchangeControlles($event)' (change)='onchangeControlles($event)'></textarea>";
  // }

  RemoveCurretnIntentNameFromRIList(vRemoveItem: any) {

    if (this.filterArrRI.length != 0) {
      if (this.singleDataArr.IntentName != undefined) {
        var EditIntentName = this.singleDataArr.IntentName;
        var RIdistinctArr = this.filterArrRI;

        RIdistinctArr.forEach((value: any, id: any) => {
          if (value.name == EditIntentName) {
            this.filterArrRI.splice(id, 1)
          }
        });

        vRemoveItem.forEach((mainValue: any, mainid: any) => {

          RIdistinctArr.forEach((value: any, id: any) => {
            if (value.name == mainValue.trim()) {
              this.filterArrRI.splice(id, 1)
            }

          });
        });
      }
    }
  }

  GetBotGradientColor() {
    this.testBotService.GetBotGradientColor().subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        var urlSafe: any
        urlSafe = res.Data.BotBackgroundWebSite;

        this._gradStartColor = res.Data.BotGradStartColor;
        this._gradEndColor = res.Data.BotGradEndColor;

      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

      }
    })
  }

  getDistictFAQDataFromDB() {

    this.faqconfigservice.getDistinctFAQData().subscribe((res: any) => {

      this.sectionName = res.Data.SectionNameList;
      if (res.Status.toUpperCase() == "SUCCESS") {

        if (res.Data.length != 0) {
          for (let i = 0; i < res.Data.RelatedIntentList.length; i++) {

            this.filterArrRI.push({
              name: res.Data.RelatedIntentList[i].Name,
              checked: false,
              status: res.Data.RelatedIntentList[i].Status
            });
          }
        }

        if (this.singleDataArr.RelatedIntent) {
          this.selectedValuesRI = []

          this.fetchRIlist = this.singleDataArr.RelatedIntent.split(',')

          for (var i = 0; i < this.fetchRIlist.length; i++) {


            var strIntentName = this.fetchRIlist[i].trim();
            const dataMain = this.filterArrRI.filter(function (x: any) { return x.name.toUpperCase() === strIntentName.toUpperCase() })[0];


            if (dataMain) {

              var RIArray = {
                "value": true,
                "name": this.fetchRIlist[i].trim(),
                "status": dataMain.status
              }

              this.selectedValuesRI.push(RIArray)
            }
          }
        }

        this.RemoveCurretnIntentNameFromRIList(this.fetchRIlist)


        this.PrivewAnswer('');
      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })

  }

  GetFAQStatusList() {
    this.hoverFilterArr = [];
    this.faqconfigservice.GetFAQStatusList().subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        this.hoverFilterArr = res.Data
        if (this.selectedFilterOption.status == 'notLive' || this.selectedFilterOption.status == 'liveContentApproved') {
          this.hoverFilterArr.forEach((value: any, id: any) => {
            if (value.status == 'live')
              this.hoverFilterArr.splice(id, 1);

            this.hoverFilterArr.forEach((value: any, id: any) => {
              if (value.status == 'liveContentUpdate')
                this.hoverFilterArr.splice(id, 1);
            });

          });

          this.hoverFilterArr.forEach((value: any, id: any) => {
            if (value.status == 'liveContentUpdate')
              this.hoverFilterArr.splice(id, 1);
          });
        }
      }

      else if (res.Error) {
        this.popupImg = "../../../assets/images/update_error.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  fetchRIlist: any = []
  fetchSingleRecord(vEditRowID: any) {

    this.fetchDispID = vEditRowID

    this.AEparameters = new faqEntities();
    this.AEparameters.IntentMaintenance.IntentId = vEditRowID

    this.faqconfigservice.fetchSingleFaqRecord(this.AEparameters.IntentMaintenance).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {

        this.singleDataArr = res.Data

        if (this.singleDataArr.MultipleUtterance.length == 0) {
          this._isMultipleUtterence = true
        } else {
          this._isMultipleUtterence = false
          this._multipleUtterenceArr = this.singleDataArr.MultipleUtterance;

          if (this._isCheckedRI == true) {


            let modifiedResponceArr = this._multipleUtterenceArr
            this.replaceSquareBracketofMU(modifiedResponceArr);
          }
        }

        this.AEparameters.IntentMaintenance.IsCampaignFAQ = this.singleDataArr.IsCampaignFAQ
        this.selectedSectionNameAE = {
          name: this.singleDataArr.SectionName,
          value: this.singleDataArr.SectionName
        }
        this.selectedSectionNameAE.value = this.singleDataArr.SectionName;

        this._usermessage = this.singleDataArr.DisplayMessage
        this._displaymessage = this.singleDataArr.DisplayMessage
        this._intentName = this.singleDataArr.IntentName

        if (this.singleDataArr.Language.toUpperCase() == 'EN') {
          this.selectedLanguage = {
            name: 'English',
            code: 'en'
          }
        }
        else {
          this.selectedLanguage = {
            name: 'Spanish',
            code: 'sp'
          }
        }

        this.AEparameters.IntentMaintenance.Language = 'EN'


        this.AEparameters.IntentMaintenance.IntentName = this.singleDataArr.IntentName
        this.AEparameters.IntentMaintenance.IsEndOfConversation = !this.singleDataArr.IsEndOfConversation

        this.AEparameters.IntentMaintenance.SectionName = this.singleDataArr.SectionName

        this.AEparameters.IntentMaintenance.Questions = this.singleDataArr.Questions
        this.AEparameters.IntentMaintenance.DisplayMessage = this.singleDataArr.DisplayMessage
        this.AEparameters.IntentMaintenance.UserMessage = this.singleDataArr.DisplayMessage

        debugger
        var modelName = this.singleDataArr.ModelName
        const filteredModel = this._TraningModelNameList.filter(function (x: any) { return x.ModelName.toUpperCase() === modelName.toUpperCase() });

        if (filteredModel.length > 0) {
          this._selectedModel = { name: filteredModel[0].ModelName, code: filteredModel[0].DisplayModelName }
        }

        this._selectedRespType = {
          "code": this.singleDataArr.ResponseType.toLowerCase(),
          "name": this.singleDataArr.ResponseType
        }

        this.AEparameters.IntentMaintenance.MainData = this.singleDataArr.MainData == undefined ? "" : this.singleDataArr.MainData
        this.replaceSquareBracketofMU(this.singleDataArr.MultipleUtterance)
        this.CreateStringMU(this.singleDataArr.MultipleUtterance)

        this.AEparameters.IntentMaintenance.SingleWords = this.singleDataArr.SingleWords == undefined ? "" : this.singleDataArr.SingleWords
        this.AEparameters.IntentMaintenance.Miscellaneous = this.singleDataArr.Miscellaneous == undefined ? "" : this.singleDataArr.Miscellaneous

        this.AEparameters.IntentMaintenance.SampleResponse = this.singleDataArr.SampleResponses

        this.AEparameters.IntentMaintenance.IsApproved = this.singleDataArr.IsApproved
        this.AEparameters.IntentMaintenance.IsPublished = this.singleDataArr.IsPublished

        this._keyUpMultipleUtterenceArr = this.AEparameters.IntentMaintenance.MultipleUtterance

        this.findOutFAQStatus(this.singleDataArr.FaqStatus)

        this.GetFAQStatusList();

        this.getDistictFAQDataFromDB();

        this.fetchMultipleUtterenceList();

        this.showAllCounts(this.AEparameters.IntentMaintenance.SingleWords, this._keyUpMultipleUtterenceArr, '')
      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  findOutFAQStatus(vFaqStatus: any) {

    if (vFaqStatus == 'Live') {
      this.selectedFilterOption = {
        name: vFaqStatus,
        status: 'live',
        code: 'NY'
      }
    }
    else if (vFaqStatus == 'Live but content need updates') {
      this.selectedFilterOption = {
        name: vFaqStatus,
        status: 'liveContentUpdate',
        code: 'RM'
      }
    }
    else if (vFaqStatus == 'New and content is approved') {
      this._IsCampiagnFAQ = true;
      this.selectedFilterOption = {
        name: vFaqStatus,
        status: 'liveContentApproved',
        code: 'LDN'
      }
    }
    else if (vFaqStatus == 'Not live') {
      this._IsCampiagnFAQ = true;
      this.selectedFilterOption = {
        name: vFaqStatus,
        status: 'notLive',
        code: 'IST'
      }
    }
  }

  _disableMainUpdateButton: boolean = true

  URL_REGEXP = /^(\s+\S+\s*)*(?!\s).*$/;

  onchangeControlles(event: any) {

    if (this.AEparameters.IntentMaintenance.MainData == undefined || this.AEparameters.IntentMaintenance.MainData.length == 0) {
      this._createData = true;
    }
    else {
      this._createDataArr = ['*', '#', '~', "'", '%', '[', ']', '(', ')']

      for (var i = 0; i < this._createDataArr.length; i++) {
        this._createData = false;
        this._createData = this.AEparameters.IntentMaintenance.MainData.includes(this._createDataArr[i]);
      }
    }


    var SectionName = this.selectedSectionNameAE.name === undefined ? this.selectedSectionNameAE.trim() : this.selectedSectionNameAE.name;


    if (!SectionName.trim().length
      || !this.AEparameters.IntentMaintenance.IntentName.trim().length
      || !this.AEparameters.IntentMaintenance.Questions.trim().length
      || !this.AEparameters.IntentMaintenance.DisplayMessage.trim().length) {
      this._disableMainUpdateButton = true
    }
    else {
      var k = event.keyCode;

      if (this._disableMainUpdateButton == false) {

      }
      else {
        if (k >= 0 && k <= 31) {
          if (this._onPageLodeCnt == 0) {
            if (k == 8 || k == 46) {
              this._onPageLodeCnt++;
              this._disableMainUpdateButton = false
            }
            else {
              this._disableMainUpdateButton = true
            }
          }
          else {
            this._disableMainUpdateButton = true
          }
        } else {

          this._disableMainUpdateButton = false
        }
      }
    }
  }

  AddUpdateFAQData(vIntentMaintenanceObj: any) {


    this.sendData = new faqEntities();

    this.sendData.IntentMaintenance.IsCampaignFAQ = vIntentMaintenanceObj.IsCampaignFAQ

    this.sendData.IntentMaintenance.SectionName = this.selectedSectionNameAE.name === undefined ? this.selectedSectionNameAE.trim() : this.selectedSectionNameAE.name;

    this.sendData.IntentMaintenance.Language = 'EN'

    var strIntentStart = vIntentMaintenanceObj.IntentName.slice(0, 3);

    this.sendData.IntentMaintenance.IsEndOfConversation = vIntentMaintenanceObj.IsEndOfConversation;
    this.sendData.IntentMaintenance.IntentName = vIntentMaintenanceObj.IntentName.trim()

    debugger
    this.sendData.IntentMaintenance.ModelName = this._selectedModel.name.toUpperCase();

    // if (vIntentMaintenanceObj.IsEndOfConversation === true) {

    //   if (strIntentStart.toUpperCase() !== "FAQ") {

    //     this.sendData.IntentMaintenance.IsEndOfConversation = false;
    //     this.sendData.IntentMaintenance.IntentName = vIntentMaintenanceObj.IntentName.trim()

    //   }
    //   else {

    //     if (this._ViewClientRoleAccess != true) {
    //       this.showPopup = true

    //       this.popupImg = "../../../assets/images/CommonError.png";
    //       this.popupContent = "";
    //       this.popupContentError = "Intent Name should start from 'CON'";

    //       this.triggerModal(this.modalWarningAndInfo)
    //       return;
    //     }
    //   }
    // }
    // else {
    //   this.sendData.IntentMaintenance.IsEndOfConversation = true;
    //   this.sendData.IntentMaintenance.IntentName = vIntentMaintenanceObj.IntentName.trim()
    // }

    this.sendData.IntentMaintenance.Questions = vIntentMaintenanceObj.Questions.trim()

    if (vIntentMaintenanceObj.DisplayMessage !== undefined) {
      var message = vIntentMaintenanceObj.DisplayMessage.split('~|~')[0];
      var updMessage = message.replace(/~/g, '');
      vIntentMaintenanceObj.UserMessage = updMessage.replace(/<br>/g, '');
    }

    this.sendData.IntentMaintenance.DisplayMessage = vIntentMaintenanceObj.DisplayMessage.trim()
    this.sendData.IntentMaintenance.UserMessage = vIntentMaintenanceObj.UserMessage.trim()

    this.sendData.IntentMaintenance.ResponseType = this._selectedRespType.name === undefined ? this._selectedRespType : this._selectedRespType.name;

    this.sendData.IntentMaintenance.MainData = vIntentMaintenanceObj.MainData.trim()


    // this.sendData.IntentMaintenance.MultipleUtterance = this._multipleUtterenceArr
    this.sendData.IntentMaintenance.SingleWords = vIntentMaintenanceObj.SingleWords == undefined ? "" : vIntentMaintenanceObj.SingleWords.trim()

    this.sendData.IntentMaintenance.IntentId = vIntentMaintenanceObj.IntentId
    this.sendData.IntentMaintenance.IsApproved = vIntentMaintenanceObj.IsApproved
    this.sendData.IntentMaintenance.IsPublished = vIntentMaintenanceObj.IsPublished

    var strRI = ""
    for (var i = 0; i < this.selectedValuesRI.length; i++) {
      if (strRI == "") {
        strRI = this.selectedValuesRI[i].name
      }
      else {
        strRI = strRI + "," + this.selectedValuesRI[i].name
      }
    }

    this.sendData.IntentMaintenance.Miscellaneous = vIntentMaintenanceObj.Miscellaneous.trim();
    this.sendData.IntentMaintenance.RelatedIntent = strRI.trim()

    this.sendData.IntentMaintenance.FaqStatus = this.selectedFilterOption.name.trim()


    var modifiedSplitArr: any = []

    //var contentArr = this.content.split("~");
    // contentArr.forEach((val: any, key: any) => {
    //   modifiedSplitArr.push({ 'Utterance': val.trim() })
    // });


    if (this.content !== undefined) {
      let splitMUArr: any
      splitMUArr = this.content.split('~')

      modifiedSplitArr = this.removeInnerHtmlFromMU(splitMUArr)

      // if (this._isCheckedRI == false) {
      //   for (let i = 0; i < splitMUArr.length; i++) {
      //     splitMUArr[i] = splitMUArr[i].replace(/[\n,~]/g, '');
      //     splitMUArr[i] = splitMUArr[i].trim()
      //     modifiedSplitArr.push({ 'Utterance': splitMUArr[i] })
      //     console.log(splitMUArr[i])
      //   }

      // } else {
      //   let splitLine: any
      //   for (let i of splitMUArr) {
      //     i = i.replace(/[\n,~]/g, '');
      //     i = i.trim()
      //     splitLine = i.split('<')

      //     for (let j = 0; j < splitLine.length; j++) {
      //       var s = splitLine[j];
      //       let start = s.indexOf("<");
      //       var end = s.indexOf(">");
      //       var result = s.substring(start, end + 1);

      //       if (result != "") {
      //         var newstr = s.replace(result, '');
      //         splitLine[j] = newstr
      //       } else {
      //         splitLine[j] = s
      //       }
      //     }
      //     
      //     splitLine = splitLine.join("")
      //     modifiedSplitArr.push({ 'Utterance': splitLine })
      //   }

      // }

      // this._multipleUtterenceArr = modifiedSplitArr

      this.sendData.IntentMaintenance.MultipleUtterance = modifiedSplitArr
    } else {
      this.sendData.IntentMaintenance.MultipleUtterance = this.AEparameters.IntentMaintenance.MultipleUtterance
    }

    if (this._PageName.toUpperCase() == "ADD") {
      this.addFaqRecord(this.sendData.IntentMaintenance)
    }
    else {
      this.sendData.IntentMaintenance.IntentId = vIntentMaintenanceObj.IntentId
      this.updateFaqRecord(this.sendData.IntentMaintenance)
    }

  }

  addFaqRecord(vIntentMaintenanceObj: any) {

    this.faqconfigservice.addFaqRecord(vIntentMaintenanceObj).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {

        this.showPopup = true
        if (this._PageName != 'Edit') {
          this.popupImg = "../../../assets/images/file-plus.png"
        } else {
          this.popupImg = "../../../assets/images/Update.png"
        }
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)

        if (this.popupContent != "Intent name already exist.") {
          this.inputdata.emit(res.Status);

        }

      } else if (res.Error) {

        this.showPopup = true
        this.popupImg = "../../../assets/images/file-plus-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;
      }
    })
  }

  succUpdate: any
  updateFaqRecord(vIntentMaintenanceObj: any) {

    this.faqconfigservice.updateSingleFaqRecord(vIntentMaintenanceObj).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        this.popupImg = "../../../assets/images/Update.png"
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)

        if (this.popupContent != "Intent name already exist.") {
          this.inputdata.emit(res.Status);
        }
        else {
          this.AEparameters.IntentMaintenance.IntentName = this.singleDataArr.IntentName
        }
      }
      else if (res.Error) {

        this.popupImg = "../../../assets/images/update_error.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  UpdateSingleWord(vIntentMaintenanceObj: any) {

    var dataTosend = this.createDiffObject(vIntentMaintenanceObj)

    this._singleword = dataTosend.SingleWords.split(',');

    this._singleword.forEach((value: any, id: any) => {
      this._singleword[id] = value.trim();
    });

    var duplicates = this._singleword.reduce(function (acc: any, el: any, i: any, arr: any) {
      if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) {
        acc.push(el.trim());
      }
      return acc;
    }, []);

    if (duplicates.length != 0) {

      this.popupImg = "../../../assets/images/CommonError.png";
      this.popupContent = "Oops!";
      this.popupContentError = 'Duplicate Single word not allow !';

      this.triggerModal(this.modalWarningAndInfo)

    }
    else {

      this.faqconfigservice.UpdateSingleWord(dataTosend).subscribe((res: any) => {
        if (res.Status.toUpperCase() == "SUCCESS") {
          this.popupImg = "../../../assets/images/Update.png"
          this.popupContent = res.Message;
          this.popupContentError = "";
          this.AEparameters.IntentMaintenance.SingleWords = dataTosend.SingleWords;
          this.singleDataArr.SingleWords = dataTosend.SingleWords;

          this.triggerModal(this.modalWarningAndInfo)
        }
        else if (res.Error) {
          this.popupImg = "../../../assets/images/CommonError.png";
          this.popupContent = "Oops!";
          this.popupContentError = res.Error;

          this.triggerModal(this.modalWarningAndInfo)
        }
      })
    }
  }


  UpdateRelatedIntents(vIntentMaintenanceObj: any) {

    var dataTosend = this.createDiffObject(vIntentMaintenanceObj)

    var strRI = ""
    for (var i = 0; i < this.selectedValuesRI.length; i++) {
      if (strRI == "") {
        strRI = this.selectedValuesRI[i].name
      }
      else {
        strRI = strRI + "," + this.selectedValuesRI[i].name
      }
    }

    dataTosend.RelatedIntent = strRI

    this.faqconfigservice.UpdateRelatedIntents(dataTosend).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {

        this._DisabledUpdateRI = true

        this.popupImg = "../../../assets/images/Update.png"
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)
      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  createBtnforMainData(vStrMainData: any, vMainData: any) {

    this.isLoader = true
    this._DisabledUtterBts = true

    var dataTosend = this.createDiffObject(this.mainData)

    this.faqconfigservice.createMainData(dataTosend).subscribe((res: any) => {

      if (res.Status.toUpperCase() == 'SUCCESS') {

        this.TempVarMulitpleUttr = res.Data;

        this.singleDataArr = res.Data
        this.isLoader = false

        let modifiedResponceArr = this.TempVarMulitpleUttr
        this.replaceSquareBracketofMU(modifiedResponceArr);


        this.CreateStringMU(res.Data)

        this.popupImg = "../../../assets/images/Update.png"
        this.popupContent = "Multiple utterance created sucessfully";
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)

      }
      else if (res.Error) {
        this.isLoader = false
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  createDiffObject(vMainData: any) {

    var data = new faqEntities()
    data.IntentMaintenance.MultipleUtterance = this._multipleUtterenceArr;

    data.IntentMaintenance.IntentId = vMainData.IntentId
    data.IntentMaintenance.IntentName = vMainData.IntentName

    data.IntentMaintenance.SectionName = vMainData.SectionName
    data.IntentMaintenance.Questions = vMainData.Questions
    data.IntentMaintenance.UserMessage = vMainData.UserMessage

    data.IntentMaintenance.DisplayMessage = vMainData.DisplayMessage
    data.IntentMaintenance.Language = vMainData.Language
    data.IntentMaintenance.ResponseType = vMainData.ResponseType

    data.IntentMaintenance.RelatedIntent = vMainData.RelatedIntent
    data.IntentMaintenance.Miscellaneous = vMainData.Miscellaneous
    data.IntentMaintenance.SingleWords = vMainData.SingleWords

    data.IntentMaintenance.MainData = vMainData.MainData

    return data.IntentMaintenance;
  }

  LKPTextColor: boolean = false

  CreateStringMU(vMUArra: any) {

    var singleAppendUtter = ""

    vMUArra.forEach((value: any, id: any) => {
      this._single = value.Utterance;
      if (!this._single.includes("~ ")) {
        if (singleAppendUtter == '') {
          singleAppendUtter = "~ " + value.Utterance.trim()
        }
        else {
          singleAppendUtter = singleAppendUtter + "\n ~ " + value.Utterance.trim()
          singleAppendUtter = singleAppendUtter.replace(/\n/g, "<br>");
        }
      }
      else
        singleAppendUtter = value.Utterance.trim()
    });



    this.AEparameters.IntentMaintenance.MultipleUtterance = singleAppendUtter;
    this.content = singleAppendUtter;




    if (this.AEparameters.IntentMaintenance.MultipleUtterance.includes("Lkp_")) {
      this._isCheckedRI = true;
    }
    else if (this.AEparameters.IntentMaintenance.MultipleUtterance.includes("Lkp_")) {
      this._isCheckedRI = false;
    }




    this.showAllCounts(this.AEparameters.IntentMaintenance.SingleWords, this._keyUpMultipleUtterenceArr, "");



  }

  public boundedPrintValue = this.ReplaceMultipleUttrnece.bind(this);

  ReplaceMultipleUttrnece(MUttr: any) {
    this.AEparameters.IntentMaintenance.MultipleUtterance = MUttr;
  }

  MainMUOpretaeArr: any = []
  _disableCreateBtn: boolean = false

  ApplyLemmatization() {
    debugger
    if (this.content !== undefined) {
      this.AEparameters.IntentMaintenance.MultipleUtterance = this.content
    }

    if (this.AEparameters.IntentMaintenance.MultipleUtterance !== undefined) {
      let splitMUArr: any
      let modifiedSplitArr: any = []
      splitMUArr = this.AEparameters.IntentMaintenance.MultipleUtterance.split('~')

      modifiedSplitArr = this.removeInnerHtmlFromMU(splitMUArr);

      this._multipleUtterenceArr = modifiedSplitArr
    }

    this.isLoader = true

    this._disableMainUpdateButton = true
    this._disableCreateBtn = true

    if (this._TotalmultipleUtternces == 0) {
      this._DisabledUtterBts = true
    }

    this.faqconfigservice.applyLemmatization(this._multipleUtterenceArr).subscribe((res: any) => {
      debugger
      if (res.Status.toUpperCase() == 'SUCCESS') {

        this.CreateStringMU(res.Data)

        this._isCheckedRI = false
        this.isLoader = false
        this.showPopup = true

        this._disableMainUpdateButton = false
        this._disableCreateBtn = false

        this.popupImg = "../../../assets/images/Update.png";
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)
      }
      else if (res.Error) {

        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this._disableMainUpdateButton = false
        this._disableCreateBtn = false

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }


  ApplyLookups() {

    if (this.content !== undefined) {
      this.AEparameters.IntentMaintenance.MultipleUtterance = this.content
    }

    if (this.AEparameters.IntentMaintenance.MultipleUtterance !== undefined) {
      let splitMUArr: any
      let modifiedSplitArr: any = []
      splitMUArr = this.AEparameters.IntentMaintenance.MultipleUtterance.split('~')

      // if (this._isCheckedRI == false) {
      for (let i = 0; i < splitMUArr.length; i++) {
        var str1 = splitMUArr[i] = splitMUArr[i].replaceAll(/[\n,~]/g, '');
        var str2 = str1.replaceAll("</div>", '');
        var str3 = str2.replaceAll("<br>", '');
        var str4 = str3.replaceAll("&nbsp;", "");
        var str5 = str4.replaceAll(/(<([^>]+)>)/ig, '')
        // <[^>]+>|&nbsp;
        var str6 = str5.trim()

        if (str6)
          modifiedSplitArr.push({ 'Utterance': str6 })
      }

      // } else {
      //   let splitLine: any
      //   for (let i of splitMUArr) {
      //     var str1 = i.replaceAll(/[\n,~]/g, '');
      //     var str2 = str1.replaceAll("</div>", '');
      //     var str3 = str2.replaceAll("<br>", '');
      //     var str4 = str3.replaceAll("&nbsp;", "");
      //     var str5 = str4.replaceAll(/(<([^>]+)>)/ig, '')
      //     splitLine = str5.trim()

      //     if (splitLine)
      //       modifiedSplitArr.push({ 'Utterance': splitLine })

      //   }
      // }

      this._multipleUtterenceArr = modifiedSplitArr
    }

    this.isLoader = true

    this._disableMainUpdateButton = true
    this._disableCreateBtn = true

    this.faqconfigservice.applyLookups(this._multipleUtterenceArr).subscribe((res: any) => {
      if (res.Status.toUpperCase() == 'SUCCESS') {

        let modifiedResponceArr = res.Data

        this.replaceSquareBracketofMU(modifiedResponceArr);

        // this.CreateStringMU(res.Data)
        this.CreateStringMU(modifiedResponceArr)

        this._disableMainUpdateButton = false

        this.isLoader = false
        this.showPopup = true

        this._disableMainUpdateButton = false
        this._disableCreateBtn = false
        this.popupContentError = "";
        this.popupImg = "../../../assets/images/Update.png";
        this.popupContent = "Apply Lookups Successfully";
        this.triggerModal(this.modalWarningAndInfo)

      } else if (res.Error) {

        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;


        this._disableMainUpdateButton = false
        this._disableCreateBtn = false

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  _DisabledUtterBts: boolean = true

  CallLookupMethods() {
    this._DisabledUtterBts = true
    if (this._isCheckedRI == true) {
      this.ApplyLookups();
    }
    else {
      this.RemoveLookups();
    }
  }

  RemoveLookups() {

    if (this.content !== undefined) {
      this.AEparameters.IntentMaintenance.MultipleUtterance = this.content
    }

    if (this.AEparameters.IntentMaintenance.MultipleUtterance !== undefined) {
      let splitMUArr: any
      let modifiedSplitArr: any = []
      splitMUArr = this.AEparameters.IntentMaintenance.MultipleUtterance.split('~')


      let splitLine: any
      for (let i of splitMUArr) {
        var str5 = i.replace(/(<([^>]+)>)/ig, '')

        splitLine = str5.trim()

        if (splitLine)
          modifiedSplitArr.push({ 'Utterance': splitLine })
      }

      this._multipleUtterenceArr = modifiedSplitArr
    }

    this.isLoader = true

    this._disableMainUpdateButton = true
    this._disableCreateBtn = true

    this.faqconfigservice.removeLookups(this._multipleUtterenceArr).subscribe((res: any) => {
      if (res.Status.toUpperCase() == 'SUCCESS') {

        this.CreateStringMU(res.Data)

        this._disableMainUpdateButton = false

        this.isLoader = false
        this.showPopup = true

        this._disableMainUpdateButton = false
        this._disableCreateBtn = false

        this.popupContentError = "";
        this.popupImg = "../../../assets/images/delete-popup.png";
        this.popupContent = "Remove Lookups Successfully";
        this.triggerModal(this.modalWarningAndInfo)
      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;


        this._disableMainUpdateButton = false
        this._disableCreateBtn = false
        this.isLoader = false

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  _regExpForAlpha = /[a-zA-Z]/g;

  showAllCounts(vStringSingleWord: any, vStrMultipleUtternces: any, vEvent: any) {

    if (vStringSingleWord !== "") {
      var SingleWordlist: [] = vStringSingleWord.split(',');

      if (vEvent.keyCode === 13 || vEvent.keyCode === 8) {
      }
      else {
        SingleWordlist.forEach((value: any, id: any) => {
          if (value.length > 50) {
            this.popupImg = "../../../assets/images/CommonError.png";
            this.popupContent = "Single word length should be Less than 50";
            this.popupContentError = "";
            this._SingleWordFlag = false;
            this.triggerModal(this.modalWarningAndInfo)
          }
          else
            this._SingleWordFlag = true;
        });
      }

      var newArray = []
      for (var i = 0; i < SingleWordlist.length; i++) {

        if (SingleWordlist[i] && SingleWordlist[i] != " ") {
          newArray.push(SingleWordlist[i]);
        }
      }

      this._TotalSinglewords = newArray.length;
    }


    if (this.content) {
      this._keyUpMultipleUtterenceArr = this.content
    } else {
      this._keyUpMultipleUtterenceArr = vStrMultipleUtternces
    }

    if (this._keyUpMultipleUtterenceArr) {

      if (this._keyUpMultipleUtterenceArr != "~") {
        var MULengthArr = this._keyUpMultipleUtterenceArr.split("~")
        MULengthArr
        MULengthArr.forEach((element: any, index: any) => {
          MULengthArr[index] = element.trim();
          if (element.includes("\n~"))
            MULengthArr[index] = element.replace("\n~", "");
          if (element.includes("&nbsp;")) {
            MULengthArr[index] = element.replace("&nbsp;", "");
          }
        });

        if (MULengthArr) {

          MULengthArr.forEach((value: any, key: any) => {

            if (value.startsWith("<")) {
              MULengthArr.splice(key, 1);
            }

            if (value === "") {
              MULengthArr.splice(key, 1);
            }
          });



          var str = MULengthArr[0]

        }


        var MULenFinalArr = MULengthArr.filter((el: any) => { return el != null && el != "" });//&& this.URL_REGEXP.test(el)

        this._TotalmultipleUtternces = MULenFinalArr.length

        this._multipleUtterenceArr = []

        MULenFinalArr.forEach((value: any, id: any) => {
          var data = {
            Utterance: value.trim()
          }

          this._multipleUtterenceArr.push(data)
        });


        if (this._TotalmultipleUtternces == 0)
          this._DisabledUtterBts = true
        else {
          this._DisabledUtterBts = false
        }
      }
      else {
        this._TotalmultipleUtternces = 0
        this._multipleUtterenceArr = []

        this._DisabledUtterBts = true
      }
    }
  }

  validateUtterCount() {

    // if (this.content !== undefined) {
    //   this.AEparameters.IntentMaintenance.MultipleUtterance = this.content
    // }

    if (this.content) {
      this._keyUpMultipleUtterenceArr = this.content
    } else {
      this._keyUpMultipleUtterenceArr = this.AEparameters.IntentMaintenance.MultipleUtterance
    }

    if (this._keyUpMultipleUtterenceArr) {
      var MULengthArr = this._keyUpMultipleUtterenceArr.split("~ ")

      MULengthArr.forEach((element: any, index: any) => {
        MULengthArr[index] = element.trim();
        if (element.includes("\n~"))
          MULengthArr[index] = element.replace("\n~", "");
        MULengthArr[index] = element.replace("&nbsp;", "");

        if (element == "") {
          MULengthArr.splice(index, 1);
        }

        if (element == "<br>") {
          MULengthArr.splice(index, 1);
        }
      });

      var MULenFinalArr = MULengthArr.filter((el: any) => { return el != null && el != "" && this.URL_REGEXP.test(el) });

      if (MULenFinalArr.length > 30) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Only 30 Utterences are allow";
        this.popupContentError = "";
        this._IsDisbale = false;
        this.triggerModal(this.modalWarningAndInfo)

        return;
      }
      else {

        this._IsDisbale = true;
      }
    }
    else {
      this._TotalmultipleUtternces = 0
      this._multipleUtterenceArr = []

      this._DisabledUtterBts = true
    }
  }

  PrivewAnswer(vEvent: any) {

    this._SampleTextData = ""
    this.selectedResponceType = ""
    this._ispreviewClicked = true

    function Urlify(text: any) {
      try {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        if (urlRegex.test(text)) { return 1; }
        else { return 0; }
      }
      catch (e) {
      }
      throw new Error('Function not implemented.');
    }

    if (vEvent.keyCode === 13 || vEvent.keyCode === 8) {
    }
    else {
      if (this.AEparameters.IntentMaintenance.DisplayMessage.length > 2000) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Bot Answer should be Less than 2000";
        this.popupContentError = "";
        this._IsDisbale = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
      else
        this._IsDisbale = true;
    }

    if (this._selectedRespType.name.toUpperCase() === "LIST") {

      var UrlArray = this.AEparameters.IntentMaintenance.DisplayMessage.split('~|~');

      // this._MXAccLink = UrlArray[1].trim();

      this._ListArray = [];

      var linkText = UrlArray[0].split('~');

      var cnt = 1;

      for (var i = 0; i < linkText.length; i++) {

        if (i % 2 !== 0) {

          var key = linkText[i].trim();

          var value = UrlArray[cnt].trim();

          var containsURL = Urlify(value);

          this._ListArray.push({
            "key": key,
            "value": value,
            "containsURL": containsURL
          });

          cnt++;
        }
        else {
          containsURL = 0;

          key = linkText[i];

          value = key;

          this._ListArray.push({
            "key": key,
            "value": value,
            "containsURL": containsURL
          });
        }
      }

      if (this.AEparameters.IntentMaintenance.SampleResponse) {
        this._SampleTextData = this.AEparameters.IntentMaintenance.SampleResponse.split(",");
        if (this._SampleTextData[0] === "" || this._SampleTextData[0] === 'undefined')
          this._LTI = true;
        else
          this._LTI = false;
      }

      this._provirewQuestion = this.AEparameters.IntentMaintenance.Questions;
      this._provirewAnswer = this.AEparameters.IntentMaintenance.DisplayMessage;

      this.previewRelatedIntent();
    }

    if (this._selectedRespType.name.toUpperCase() === "URL") {

      this._provirewQuestion = this.AEparameters.IntentMaintenance.Questions;

      var UrlArray = this.AEparameters.IntentMaintenance.DisplayMessage.split('~|~');

      this._LinkArray = [];

      var linkText = UrlArray[0].split('~');

      var cnt = 1;

      for (var i = 0; i < linkText.length; i++) {

        if (i % 2 !== 0) {

          var key = linkText[i].trim();

          var value = UrlArray[cnt].trim();

          var containsURL = Urlify(value);

          this._LinkArray.push({
            "key": key,
            "value": value,
            "containsURL": containsURL
          });

          cnt++;
        }
        else {
          containsURL = 0;

          key = linkText[i];

          value = key;
          this._LinkArray.push({
            "key": key,
            "value": value,
            "containsURL": containsURL
          });
        }
      }

      this._SampleTextData = [];
      this.previewRelatedIntent()
    }

    if (this._selectedRespType.name.toUpperCase() === "TEXT") {

      var UrlArray = this.AEparameters.IntentMaintenance.DisplayMessage.split('~|~');

      // this._MXAccLink = UrlArray[1].trim();

      this._TextArray = [];

      var linkText = UrlArray[0].split('~');

      var cnt = 1;

      for (var i = 0; i < linkText.length; i++) {

        if (i % 2 !== 0) {

          var key = linkText[i].trim();

          var value = UrlArray[cnt].trim();

          var containsURL = Urlify(value);

          this._TextArray.push({
            "key": key,
            "value": value,
            "containsURL": containsURL
          });

          cnt++;
        }
        else {
          containsURL = 0;

          key = linkText[i];

          value = key;

          this._TextArray.push({
            "key": key,
            "value": value,
            "containsURL": containsURL
          });
        }
      }

      this._provirewQuestion = this.AEparameters.IntentMaintenance.Questions;
      this._provirewAnswer = this.AEparameters.IntentMaintenance.DisplayMessage;

      if (this._provirewAnswer == null) {
        this._isBotResponceNull = true
      } else {
        this._isBotResponceNull = false
      }

      this._SampleTextData = [];
      this.previewRelatedIntent()
    }
  }

  previewRelatedIntent() {

    var RIdataForPreview = []

    for (var i = 0; i < this.selectedValuesRI.length; i++) {

      var strIntentName = this.selectedValuesRI[i].name

      const dataMain = this._faqListArr.filter(function (x: any) { return x.IntentName.toUpperCase() === strIntentName.toUpperCase() })[0];

      var data: any
      if (dataMain != undefined) {
        data = {
          "query": dataMain.Questions
        }
      }


      RIdataForPreview.push(data);

    }

    this.DisplayRelatedIntent(RIdataForPreview)
  }

  DisplayRelatedIntent(relatedIntents: any) {
    try {

      this.RelativeQue1 = "";
      this.RelativeQue2 = ""
      this.RelativeQue3 = ""

      if (relatedIntents !== null) {
        for (var i = 0; i < relatedIntents.length; i++) {
          if (i === 0) {
            this.RelativeQue1 = relatedIntents[0].query;
          }
          else if (i === 1) {
            this.RelativeQue2 = relatedIntents[1].query;
          }
          else if (i === 2) {
            this.RelativeQue3 = relatedIntents[2].query;
          }
        }
      }
      else {
      }
    }
    catch (e) {

    }
  };

  filterSectionNameAE(event: any) {

    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.sectionName.length; i++) {
      if (this.sectionName[i].toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push({
          name: this.sectionName[i],
          value: this.sectionName[i]
        });
      }
    }
    this.filteredSectionNameAE = filtered;
  }

  fetchLanguage() {
    this.language = [
      { name: 'English' },
      { name: 'Spanish' }
    ]
  }


  fetchModels() {
    debugger
    this._TraningModelNameList.forEach((value: any, key: any) => {
      debugger
      this._Models.push({ name: value.ModelName, code: value.DisplayModelName })
    });


    // this.language = [
    //   { name: 'English' },
    //   { name: 'Spanish' }
    // ]
  }

  fetchResponceType() {
    this.responceType = [
      { name: 'Text', code: 'text' },
      { name: 'URL', code: 'url' },
      { name: 'List', code: 'list' }
    ]
  }

  handleChangeRI(e: any) {
    if (this.singleDataArr.FaqStatus === 'Live' || this.singleDataArr.FaqStatus === 'Live but content need updates') {
      if (this.AEparameters.IntentMaintenance.IsCampaignFAQ == true) {
        this.popupImg = "../../../assets/images/Update.png";
        this.popupContent = "Campaign FAQ will be updated when you update main div";
        this.popupContentError = "";
        this.triggerModal(this.confirmationModalCampaign)
        this._disableMainUpdateButton = false
      }
      else {
        this._disableMainUpdateButton = true
      }

      this.AEparameters.IntentMaintenance.IsCampaignFAQ = e.checked;

    }
  }

  public boundCancle = this.CancleCampaign.bind(this);


  public boundedMainYesValue = this.AddUpdateFAQData.bind(this);
  public boundedMainNoValue = this.onCancelPopup.bind(this);


  onCancelPopup() {

    this.inputdata.emit("");
  }

  CancleCampaign(e: any) {
    this.AEparameters.IntentMaintenance.IsCampaignFAQ = false;
    this._disableMainUpdateButton = true
  }

  onClickedOutsideRI(e: any) {
    this.toggleRI = false;
  }

  clickEventRI() {
    this.selectedFieldOutlineRI = !this.selectedFieldOutlineRI
    this.toggleRI = !this.toggleRI
  }

  changeStyleRI() {
    this.greyFieldOutlineRI = !this.greyFieldOutlineRI
  }

  _DisabledUpdateRI: any = true
  selectFilterOptionRI(details: any) {

    this.isHide = false
    this.toggleRI = false
    this._DisabledUpdateRI = false

    details.value = true

    if (details.value == true) {
      this.chageFilterInputRI = true


      var f = this.filterArrRI;
      if (this.selectedValuesRI.length < 3) {


        var strIntentName = details.name;
        const dataMain = this.filterArrRI.filter(function (x: any) { return x.name.toUpperCase() === strIntentName.toUpperCase() })[0];


        if (dataMain) {
          details.status = dataMain.status
          this.selectedValuesRI.push(details)

        }

        this.filterArrRI.forEach((value: any, id: any) => {
          if (value.name == details.name) {
            this.filterArrRI.splice(id, 1)
          }
        });
      }

      else {
        this.dropSkillRI(details)


        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = "Max three related intents allowed";

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }

      this._selectedRelatedIntentAE = ""
    }
    else {
      this.selectedValuesRI.forEach((value: any, id: any) => {
        if (value.name == details.name) {
          this.selectedValuesRI.splice(id, 1)
        }
        this.chageFilterInputRI = true
      });
    }

    this.PrivewAnswer('')

  }

  dropSkillRI(index: any) {

    this.chageFilterInputRI = true
    this._DisabledUpdateRI = false

    this.selectedValuesRI.forEach((value: any, id: any) => {
      if (value.name == index.name) {
        this.selectedValuesRI.splice(id, 1)
        index.value = false;
        this.filterArrRI.push(index)

      }
      this.chageFilterInputRI = true
    });

    this.PrivewAnswer('')
    this.onchangeControlles("")

  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  dismissFunction() {

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  cancelbuttonOnAddEdit() {
    if (this._disableMainUpdateButton == false && this._createData == true) {
      this.popupImg = "../../../assets/images/Update.png";
      this.popupContent = "Do want to save this changes?";
      this.popupContentError = "";
      this.triggerModal(this.confirmationModal)
    }
    else {
      this.inputdata.emit("");
    }
  }

  fetchMultipleUtterenceHeaders() {
    this._multipleUtterenceHeadersArr = [
      { field: 'DispID', header: 'Sr No.', width: "12%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'Utterance', header: 'Utterance', width: "80%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'Section Name' },
      { field: 'BondModifiedOn', header: '', width: "20%", isEditable: "true", isSort: "fal", textAlignment: "left", value: 'Answer' },
    ]
  }

  fetchMultipleUtterenceList() {
    this.isLoader = false
  }

  OpenDeletePopup(data: any, rowindex: any) {
    this._OtherData = "";
    this.dataTodelete = data
    this._IntentMessageID = data.IntentMessagesID;

    this.triggerModal(this.modalDataDelete);
  }

  sortcolumn(ColumnName: any) { }

  _hideNewAddedEmptyUtter: boolean = false
  addNewRow() {
    this.newrow = true;

    this._lemmaName = ""
    this.editrow = !this.newrow
  }

  cancelNewRow() {
    this.newrow = false;
    this.editrow = false;

    this._lemmaName = ""
  }

  fetchSingleUtterenceRecord(data: any, rowindex: any) {

    this.singleDataArr = data;
    this._IntentMessageID = data.IntentMessagesID;

    this.fetchDispID = rowindex
    this.editrow = true
    this.newrow = !this.editrow

    this._lemmaName = data.Utterance
  }

  deleteFaqRecord() {
    this.isLoader = true

    var DeteleReason = "";

    this.addUtterance = new faqEntities();
    this.addUtterance.IntentUtternace.IntentName = this.dataTodelete.IntentName
    this.addUtterance.IntentUtternace.Utterance = this.dataTodelete.Utterance
    this.addUtterance.IntentUtternace.IntentMessagesID = this._IntentMessageID

    this.faqconfigservice.deleteSingleMultipleUtterance(this.addUtterance.IntentUtternace).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        this.isLoader = false
        this.showPopup = true

        this.popupImg = "../../../assets/images/delete-popup.png";
        this.popupContent = res.Message;
        this.popupContentError = "";
        this._multipleUtterenceArr = res.Data;

        this.triggerModal(this.modalWarningAndInfo)

      }
      else if (res.Error) {
        this.isLoader = true

        this.showPopup = true
        this.popupImg = "../../../assets/images/Delete-popup-error.png";
        this.popupContent = "Oops!";

        this.popupContentError = res.Error;
      }
    })
  }

  OpenMuiltipleutteranncesPopup(data: any) {

    this._OtherData = "";
    this.mainData = data
    var _data = data.MainData.trim();

    this._createDataArr = ['*', '#', '~', "'", '%', '[', ']', '(', ')']

    for (var i = 0; i < this._createDataArr.length; i++) {
      this._createData = false;
      this._createData = _data.includes(this._createDataArr[i]);

      if (this._createData == false) {
        this.showPopup = true
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = 'Please enter the data in correct format';
        this.isLoader = false;
        this.popupContentError = "";
        this.newrow = false;
        this._isSWDuplicate = false;
        this._disableMainUpdateButton = false;
        this.AEparameters.IntentMaintenance.SingleWords = this.singleDataArr.SingleWords;
        this.showAllCounts(this.AEparameters.IntentMaintenance.SingleWords, this._keyUpMultipleUtterenceArr, '')
        this.triggerModal(this.modalWarningAndInfo)
        break;
      }
    }

    this._isMultipleUtterence = false

    if (this._createData == true) {
      this.triggerModal(this.modalCreateData);
    }
  }

  inputHoverEffect() {
    $(".addFaqWrapper .p-autocomplete-input").addClass("inputFieldHoverEffect");
    $(".addFaqWrapper .p-autocomplete-dropdown").addClass("inputFieldHoverEffect");
    $(".addFaqWrapper .p-autocomplete-input").removeClass("bg-white");
    $(".addFaqWrapper .p-autocomplete-dropdown").removeClass("bg-white");
  }

  removeInputHoverEffect() {
    $(".addFaqWrapper .p-autocomplete-input").removeClass("inputFieldHoverEffect");
    $(".addFaqWrapper .p-autocomplete-dropdown").removeClass("inputFieldHoverEffect");
  }

  effectOnClick() {
    $(".addFaqWrapper .p-autocomplete").addClass("selectedField");
    $(".addFaqWrapper .p-autocomplete-input").removeClass("bg-white");
    $(".addFaqWrapper .p-autocomplete-dropdown").removeClass("bg-white");
    $(".addFaqWrapper .p-autocomplete-input").removeClass("inputFieldHoverEffect");
    $(".addFaqWrapper .p-autocomplete-dropdown").removeClass("inputFieldHoverEffect");

  }

  removeDropdownEffect() {
    $(".addFaqWrapper .p-autocomplete").removeClass("selectedField");

  }

  effectOnSelectOption(option: any) {
    $(".addFaqWrapper .p-autocomplete-input").addClass("bg-white");
    $(".addFaqWrapper .p-autocomplete-dropdown").addClass("bg-white");
  }

  onAdvanceClick() {
    this.isBlockExpand = !this.isBlockExpand;
    $(".CalenderDropDown .p-dropdown .p-dropdown-panel").addClass("top-ne-210px");
  }

  AssingSelectedStatus(vSelectedStatus: any) {
    this.selectedFilterOption = vSelectedStatus.originalEvent.currentTarget.innerText
  }

  // CreateStringOFMU(vMUList: any) {


  //   var musingleString = ""

  //   for (var i = 0; i < vMUList.length; i++) {

  //     if (musingleString == "") {
  //       musingleString = "- " + vMUList[i].Utterance
  //     }
  //     else {
  //       musingleString = musingleString + "\n- " + vMUList[i].Utterance
  //     }
  //   }

  //   return musingleString;
  // }

  filterRelatedIntentAE(event: any) {

    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.filterArrRI.length; i++) {
      let item = this.filterArrRI[i];
      if (item.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push({
          name: this.filterArrRI[i].name,
          value: this.filterArrRI[i].checked
        });
      }
    }
    this._filteredRelatedIntentAE = filtered;
  }

  checkIntentName() {

    var strIntentStart = this.AEparameters.IntentMaintenance.IntentName.slice(0, 3);

    if (this.AEparameters.IntentMaintenance.IsEndOfConversation === true) {

      if (strIntentStart.toUpperCase() === "FAQ") {

        this.showPopup = true

        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "";
        this.popupContentError = "Intent Name should start from 'CON'";

        this.triggerModal(this.modalWarningAndInfo)
      }
    }
    else {

    }
  }

  //remove inner html from string
  removeInnerHtmlFromMU(splitMUArr: any) {

    let modifiedSplitArr: any = []

    // if (this._isCheckedRI == false) {
    for (let i = 0; i < splitMUArr.length; i++) {
      var str1 = splitMUArr[i].replaceAll(/[\n,~]/g, '');
      var str2 = str1.replaceAll('<br>', '')
      var str3 = str2.replaceAll('<div>', '')
      var str4 = str3.replaceAll('</div>', '')
      var str5 = str4.replaceAll('&nbsp;', '')

      var str6 = str5.replace(/(<([^>]+)>)/ig, '')

      var str7 = str6.trim()

      if (str7) {
        modifiedSplitArr.push({ 'Utterance': str7 })
      }
    }

    // }
    //  else {
    //   let splitLine: any
    //   for (let i of splitMUArr) {
    //     i = i.replace(/[\n,~]/g, '');

    //     var str1 = i.replaceAll(/[\n,~]/g, '');
    //     var str2 = str1.replaceAll('<br>', '')
    //     var str3 = str2.replaceAll('<div>', '')
    //     var str4 = str3.replaceAll('</div>', '')
    //     var str5 = str4.replaceAll('&nbsp;', '')

    //     var str6 = str5.replaceAll(/(<([^>]+)>)/ig, '')

    //     var str7 = str6.trim()

    //     if (str7) {
    //       modifiedSplitArr.push({ 'Utterance': str7 })
    //     }

    //     // i = i.trim()
    //     // splitLine = i.split('<')

    //     // for (let j = 0; j < splitLine.length; j++) {
    //     //   var s = splitLine[j];
    //     //   let start = s.indexOf("<");
    //     //   var end = s.indexOf(">");
    //     //   var result = s.substring(start, end + 1);

    //     //   if (result != "") {
    //     //     var newstr = s.replace(result, '');
    //     //     splitLine[j] = newstr
    //     //   } else {
    //     //     splitLine[j] = s
    //     //   }
    //     // }

    //     // splitLine = splitLine.join("")

    //     // var str5 = i.replace(/(<([^>]+)>)/ig, '')

    //     // splitLine = str5.trim()

    //     // if (splitLine) {
    //     //   modifiedSplitArr.push({ 'Utterance': splitLine })
    //     // }
    //   }
    // }
    return modifiedSplitArr;
  }

  //repace ( )  bracket or add inner html for lookup tables for multiple uttr string
  replaceSquareBracketofMU(vmodifiedResponceArr: any) {

    let splitMUArr: any
    for (let i of vmodifiedResponceArr) {
      splitMUArr = i.Utterance.split(' ')
      for (let j = 0; j < splitMUArr.length; j++) {
        var s = splitMUArr[j];
        let start = s.indexOf("(");
        var end = s.indexOf(")");
        var result = s.substring(start, end + 1);

        if (result != "") {
          var newstr = s.replace(result, '<span class="theme-text-color">' + result + '</span>');
          splitMUArr[j] = newstr
        } else {
          splitMUArr[j] = s
        }
      }

      splitMUArr = splitMUArr.join(" ")
      i.Utterance = splitMUArr
    }


    this._multipleUtterenceArr = vmodifiedResponceArr
  }
}