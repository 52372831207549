<div class="container-fluid screen-container xl-bg-transperent">
    <div class="row addFaqWrapper">
        <div class="col-md-12">
            <div class="mainContainerWrapper">
                <div class="header row">
                    <div class="col-md-12">
                        <div class="float-left">
                            <span *ngIf="_PageName != 'EditUser'" class="page-title">Add User</span>
                            <span *ngIf="_PageName == 'EditUser'" class="page-title">Modify User</span>
                        </div>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-md-12">
                        <div class="col-md-6 d-inline-block" *ngIf="_PageName != 'EditUser'">
                            <div class="blockLabel">First Name <span class="asterisk">*</span></div>

                            <div class="position-relative">
                                <div class="d-inline-block w-100">
                                    <input pInputText type="text" style="font-family: 'lato-regular';"
                                        class="intentInputField1 form-control"
                                        [(ngModel)]=" AEparameters.UserDetails.FirstName"
                                        (keyup)='onchangeControlles($event)' [disabled]="_IsClient" required>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 d-inline-block" *ngIf="_PageName == 'EditUser'">
                            <div class="blockLabel">First Name <span class="asterisk">*</span></div>

                            <div class="position-relative">
                                <div class="d-inline-block w-100">
                                    <input pInputText type="text" style="font-family: 'lato-regular';"
                                        class="intentInputField1 form-control"
                                        [(ngModel)]=" AEparameters.UserDetails.FirstName"
                                        (keyup)='onchangeControlles($event)' [disabled]="_IsClient" required>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 d-inline-block" *ngIf="_PageName == 'EditUser'">
                            <div class="blockLabel">Last Name <span class="asterisk">*</span></div>
                            <input pInputText type="text" style="font-family: 'lato-regular';"
                                class="intentInputField1 form-control" [(ngModel)]=" AEparameters.UserDetails.LastName"
                                (keyup)='onchangeControlles($event)' [disabled]="_IsClient" required>
                        </div>
                        <div class="col-md-6 d-inline-block" *ngIf="_PageName != 'EditUser'">
                            <div class="blockLabel">Last Name <span class="asterisk">*</span></div>
                            <input pInputText type="text" style="font-family: 'lato-regular';"
                                class="intentInputField1 form-control" [(ngModel)]=" AEparameters.UserDetails.LastName"
                                (keyup)='onchangeControlles($event)' [disabled]="_IsClient" required>
                        </div>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-md-12">

                        <div class="col-md-6 d-inline-block" *ngIf="_PageName != 'EditUser'">
                            <div class="blockLabel">Email Id <span class="asterisk">*</span></div>
                            <input pInputText type="text" style="font-family: 'lato-regular';"
                                class="intentInputField1 form-control" [(ngModel)]=" AEparameters.UserDetails.EmailId"
                                (keyup)='onchangeControlles($event)' [disabled]="_IsClient" required>

                        </div>
                        <div class="col-md-6 d-inline-block" *ngIf="_PageName == 'EditUser'">
                            <div class="blockLabel">Email Id </div>
                            <input pInputText type="text" style="font-family: 'lato-regular';"
                                class="intentInputField1 form-control" [(ngModel)]=" AEparameters.UserDetails.EmailId"
                                (keyup)='onchangeControlles($event)' [disabled]="_IsClient" disabled>
                        </div>


                        <div class="col-md-6 d-inline-block" *ngIf="_PageName != 'EditUser'">
                            <div class="col-md-6 d-inline-block">
                                <div class="blockLabel">Gender</div>
                                <p-dropdown [options]="gender" (keyup)='onchangeControlles($event)'
                                    class="CalenderDropDown" [(ngModel)]="AEparameters.UserDetails.Gender"
                                    optionLabel="name">
                                </p-dropdown>
                            </div>
                            <div class="col-md-6 d-inline-block">
                                <div class="blockLabel">Role <span class="asterisk">*</span></div>
                                <p-dropdown [options]="Role" (keyup)='onchangeControlles($event)'
                                    class="CalenderDropDown" [(ngModel)]="AEparameters.UserDetails.RoleName"
                                    optionLabel="name">
                                </p-dropdown>
                            </div>
                        </div>

                        <div class="col-md-6 d-inline-block" *ngIf="_PageName == 'EditUser'">
                            <div class="col-md-6 d-inline-block">
                                <div class="blockLabel">Gender</div>
                                <!-- <p-dropdown [options]="gender" (keyup)='onchangeControlles($event)'
                                    class="CalenderDropDown" [(ngModel)]="AEparameters.UserDetails.Gender"
                                    optionLabel="name" optionValue="code" *ngIf="!spinnerloader">
                                </p-dropdown> -->
                                <p-dropdown class="CalenderDropDown" [options]="gender" (keyup)='onchangeControlles($event)' [(ngModel)]="_selectedGender" optionLabel="name" *ngIf="!spinnerloader">
                                    <ng-template pTemplate="selectedItem">
                                        <div class="country-item country-item-value"  *ngIf="_selectedGender">
                                            <div>{{this._selectedGender.name}}</div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-GenderType pTemplate="item">
                                        <div class="country-item">
                                            <div>{{GenderType.name}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                                <div class="CalenderDropDown" *ngIf="spinnerloader">
                                    <div class="p-dropdown">
                                        <span><i class="fa fa-spinner fa-spin"></i></span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 d-inline-block">
                                <div class="blockLabel">Role <span class="asterisk">*</span></div>
                               <!--  <p-dropdown [options]="Role" (keyup)='onchangeControlles($event)'
                                    class="CalenderDropDown" [(ngModel)]="_selectedRole" optionValue="code" optionLabel="name"
                                    name="_selectedRole" *ngIf="!spinnerloader">
                                </p-dropdown> -->
                                <p-dropdown class="CalenderDropDown" [options]="Role" (keyup)='onchangeControlles($event)' [(ngModel)]="_selectedRole"  optionLabel="name" *ngIf="!spinnerloader">
                                    <ng-template pTemplate="selectedItem">
                                        <div class="country-item country-item-value"  *ngIf="_selectedRole">
                                            <div>{{this._selectedRole.name}}</div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-RoleType pTemplate="item">
                                        <div class="country-item">
                                            <div>{{RoleType.name}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                                <div class="CalenderDropDown" *ngIf="spinnerloader">
                                    <div class="p-dropdown">
                                        <span><i class="fa fa-spinner fa-spin"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-md-12">
                        <div class="col-md-6 d-inline-block" *ngIf="_PageName == 'EditUser'">
                            <div class="blockLabel">Address <span class="asterisk">*</span></div>
                            <textarea pInputText type="text" style="font-family: 'lato-regular';"
                                class="intentInputField1 form-control" [(ngModel)]=" AEparameters.UserDetails.Address"
                                (keyup)='onchangeControlles($event)' [disabled]="_IsClient" required></textarea>

                        </div>
                        <div class="col-md-6 d-inline-block" *ngIf="_PageName != 'EditUser'">
                            <div class="blockLabel">Address <span class="asterisk">*</span></div>
                            <textarea pInputText type="text" style="font-family: 'lato-regular';"
                                class="intentInputField1 form-control" [(ngModel)]=" AEparameters.UserDetails.Address"
                                (keyup)='onchangeControlles($event)' [disabled]="_IsClient" required></textarea>
                        </div>

                        <div class="col-md-6 d-inline-block" *ngIf="_PageName == 'EditUser'"
                            style="position: absolute;padding-right: 30px;">
                            <div class="blockLabel">Contact Number <span class="asterisk">*</span></div>
                            <input pInputText type="tel" autocomplete="off" style="font-family: 'lato-regular';"
                                class="intentInputField1 form-control"
                                [(ngModel)]=" AEparameters.UserDetails.ContactNumber"
                                (keyup)='onchangeControlles($event)' [disabled]="_IsClient" required>
                        </div>
                        <div class="col-md-6 d-inline-block" *ngIf="_PageName != 'EditUser'"
                            style="position: absolute;">
                            <div class="blockLabel">Contact Number <span class="asterisk">*</span></div>
                            <input pInputText type="tel" autocomplete="off" style="font-family: 'lato-regular';"
                                class="intentInputField1 form-control"
                                [(ngModel)]=" AEparameters.UserDetails.ContactNumber"
                                (keyup)='onchangeControlles($event)' [disabled]="_IsClient" required>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="col-md-6 d-inline-block" *ngIf="_PageName != 'EditUser'">
                            <div class="blockLabel">Password <span class="asterisk">*</span></div>

                            <div class="position-relative">
                                <div class="d-inline-block w-100">
                                    <input pInputText type="password" style="font-family: 'lato-regular';"
                                        autocomplete="off" class="intentInputField1 form-control"
                                        [(ngModel)]=" AEparameters.UserDetails.Password"
                                        (keyup)='onchangeControlles($event)' [disabled]="_IsClient" required>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 d-inline-block" *ngIf="_PageName != 'EditUser'">
                            <div class="blockLabel">Confirm Password <span class="asterisk">*</span></div>

                            <div class="position-relative">
                                <div class="d-inline-block w-100">
                                    <input pInputText type="password" autocomplete="off"
                                        style="font-family: 'lato-regular';" class="intentInputField1 form-control"
                                        [(ngModel)]=" AEparameters.UserDetails.Password"
                                        (keyup)='onchangeControlles($event)' [disabled]="_IsClient" required>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row hdBasicrow pl-15 pr-15">
                    <div class="col-md-12" *ngIf="_PageName == 'EditUser'">
                        <div class="addFaqFormFooter">

                            <div class="addFaqFormBtns d-inline-block float-left">
                                <button title="Update" type="button" class="btn btn-primary"
                                    (click)="AddUpdateUserData(AEparameters.UserDetails, false);">Update</button>
                                <button title="Cancel" type="button" class="btn btn-secondary"
                                    (click)='cancelbuttonOnAddEdit()'>Cancel</button>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-12" *ngIf="_PageName != 'EditUser'">
                        <div class="addFaqFormFooter">

                            <div class="addFaqFormBtns d-inline-block float-left">
                                <button title="Update" type="button" class="btn btn-primary"
                                    (click)="AddUpdateUserData(AEparameters.UserDetails, false);">Add</button>
                                <button title="Cancel" type="button" class="btn btn-secondary"
                                    (click)='cancelbuttonOnAddEdit()'>Cancel</button>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>