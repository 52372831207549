import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';

import { HttpCommonReq } from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})
export class PersonaService {

  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {
      getPersona: 'api/Persona/GetPersona?vEmailId=',
      sendMessageToBond: 'api/BondChatBot/SendMessageToBondHub',
      addUserRating: "api/BondChatBot/InsertUserRating?vRating=",
      addConversationMsgRating: "api/BondChatBot/InsertConversationMessageRating"
    }
  }

  getPersonaData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.getPersona + data)
  }

  sendMessageToBondHub(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.sendMessageToBond, data)
  }

  addUserRating(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.addUserRating + data, {})
  }

  addConversationMsgRating(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.addConversationMsgRating, data)
  }
}
