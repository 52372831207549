import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Router } from "@angular/router";

import {HttpCommonReq} from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})

export class ClusterPersonaService {

  configUrls:any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {
      getBDDataForBubblesURL: 'api/BankDashboard/GetBDConsumerData',
      getBankDashBordDataURL: 'api/BankDashboard/GetBankDashBordData',
      exportBankDashboardURL: 'api/BankDashboard/ExportCSVFile'
    }
  }

  getBDDataForBubbles(data: any){
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
    return httpClientCommonObj.get(this.configUrls.getBDDataForBubblesURL)
  }

  getBankDashBordData(data: any){
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
    const cluster = data.Cluster.replace("#", "")
    return httpClientCommonObj.get(this.configUrls.getBankDashBordDataURL+ "?vCumsomerid=1&vCluster=" + cluster + "&vCategory=" + data.category)
  }

  exportBDData(data: any){
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
    const cluster = data.Cluster.replace("#", "")
    return httpClientCommonObj.get(this.configUrls.exportBankDashboardURL+ "?vCumsomerid=1&vCluster=" + cluster + "&vCategory=" + data.category)
  }
}
