import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { LoginService } from '../services/Login/login.service'

@Component({
  selector: 'app-agent-login',
  templateUrl: './agent-login.component.html',
  styleUrls: ['./agent-login.component.css']
})

export class AgentLoginComponent implements OnInit {

  loginForm: FormGroup;
  invalidLogin: boolean = false;

  checktype: string = "password"
  eye: boolean = false
  eyeSlash: boolean = true;

  constructor(private formBuilder: FormBuilder, private router: Router, public loginService: LoginService) { }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required])],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
    const loginPayload = {
      EmailId: this.loginForm.controls.username.value,
      Password: this.loginForm.controls.password.value
    }

    this.loginService.AgentLogin(loginPayload).subscribe((res: any) => {

      
      if (res.Status == "Success") {
        window.localStorage.setItem("AccessToken", res.Data.AccessToken);
        window.localStorage.setItem("RoleName", res.Data.RoleName);
        window.localStorage.setItem("UserId", res.Data.UserId);
        window.localStorage.setItem("UserName", res.Data.UserName);
        window.localStorage.setItem("SessionId", res.Data.SessionId);
        window.localStorage.setItem("TenantId", res.Data.TenantId);

        sessionStorage.setItem("SessionId", res.Data.SessionId);
        sessionStorage.setItem("RoleName", res.Data.RoleName);
        sessionStorage.setItem("UserName", res.Data.UserName);
        sessionStorage.setItem("AccessToken", res.Data.AccessToken);
        sessionStorage.setItem("UserId", res.Data.UserId);
        sessionStorage.setItem("TenantId", res.Data.TenantId);

        this.router.navigateByUrl('portal/Personas/Individual');
      }
      else {
        this.invalidLogin = true;
      }
    })
  }

  showPassword() {
    this.checktype = "password"
    this.eye = false
    this.eyeSlash = true
  }

  hidePassword() {
    this.checktype = "text"
    this.eye = true
    this.eyeSlash = false
  }
}
