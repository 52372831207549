import { Injectable, ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http'

import { HttpCommonReq } from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})
export class EnterpriseUserAccountService {

  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {
      getNewAccountsList: 'api/AccountDetailForEnterprise/GetAllAccountDetail',
      SearchNewAccountList: 'api/AccountDetailForEnterprise/SearchNewAccountData',
      exportNewAccountsList: 'api/AccountDetailForEnterprise/ExportCSVFile',
      updateNewAccountsList: 'api/AccountDetailForEnterprise/UpdateAccountData'
    }
  }

  getNewAccountsData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.getNewAccountsList, data)
  }

  SearchNewAccountData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.SearchNewAccountList, data)
  }

  exportNewAccountsData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.exportNewAccountsList, data)
  }

  updateNewAccountsData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.updateNewAccountsList, data)
  }
}
