import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    template: ''
})
export class Entities implements OnInit {

    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }

    FailedFAQModel = {
        "MessageFrom":"",
        "FromDate":Date,
        "ToDate":Date,
        "UsersessionID":"",
        "ChatType":"",
        "IsThumbsDown":false,
        "IsFallback":"",
        "Language":""
    }

    ConversationHistoryModel = {
        "ConversationLogId":"",
        "MessageFrom":"",
        "MessageTo":"",
        "Message":"",
        "UserSessionId":"",
        "IntentName":"",
        "SenderType":"",
        "BondCreatedOn":Date,
        "RelatedIntents":""

    }
    NewAccountModel = {
        "DispID":"",
        "Id":"",
        "ReferanceNo":"",
        "FirstName":"",
        "EmailId":"",
        "MobileNo":"",
        "DLNo":"",
        "LinkAccStatus":"",
        "SigningStatus":"",
        "RvwStatus":"",
        "BondCreatedOn":""
    }

    TargetDataModel = {
        "BondTargetId":"",
        "TargetType":"",
        "TargetValue":"",
        "Duration":"",
        "Lang":""
    }

    SingleWordModel = {
        "SingleWordId":"",
        "Word":"",
        "IntentName":"",
        "SectionName":"",
        "DispID":""
    }

    Notifications = {
        "NotificationTo": "",
        "IsRead": false,
        "NotificationId": "",
        "NotificaitonFrom": "",
        "NotificationDesc": "",
        "NotificationOn": "",
        "ExpiryDate": "",
        "NotificationSubject": "",
        "Module": "",
        "BondCreatedBy":""
    };

    Lookups = {
        "LookupDataId":"",
        "LookupTableName":"",
        "LookupTableValue":"",
        "Language":"EN",
        "SorColumnName":"",
        "Reverseorder":"",
        "DispID":"",
        "IsSelectedCB" : false,
    }

    UserDetails = {
        "BondUsersId":0,
        "EmailId":"",
        "FirstName":"",
        "LastName":"",
        "RoleName":"",
        "Address":"",
        "ContactNumber":"",
        "Gender":"",
        "DOB":"",
        "Password":"",
        "Reverseorder":false,
        "SorColumnName":"",
        "DispID": "",
        "IsSelectedCB" : false
    }

    ProductLogModel = {
        "LogType":"",
        "FromDate":"",
        "ToDate":"",
        "SessionID":""
    }

    SendMessage  = {
        "UserName": "",
        "Messages":"",
        "LangCode": "",
        "SessionId": "",
        "MessageType": "",
        "AccessToken": "",
        "UserId": ""
    };

    ratingData = {
        "vAnswer": "",
        "vIsLiked": "",
        "vQuestion": ""
    };

    BotDashboardRequest = {
        "Duration":"",
        "FromDate":"",
        "ToDate":"",
        "Type":""
    }

    IntentMaintenance = {
        "IntentId":"",
        "IntentName":"",
        "SectionName":"",
        "Questions":"",
        "UserMessage":"",
        "DisplayMessage":"",
        "Language":"EN",
        "IsCampaignFAQ":"",
        "ResponseType":"",
        "IsApproved":"",
        "IsPublished":"",
        "FilePath":"",
        "MultipleUtterance":"",
        "RelatedIntent":"",
        "CurrentStatus":"",
        "IsLiveRawIntent":"",
        "IsModifiedRawIntent":"",
        "IsNewRawIntent":"",
        "IsContentApprovedRawIntent":"",
        "Miscellaneous":"",
        "SingleWords":"",
        "Id":"",
        "BambuId":"",
        "BondCreatedOn":"",
        "BondModifiedOn":"",
        "MainData":"",
        "Reverseorder":"",
        "SorColumnName":"",
        "DispId":"",
        "AfterUpdateColumnName":"",
        "TotalUtter":""
    }

    IntentTestingSummary = {
        "SectionName":"",
        "IntentName":"",
        "ExpectedResponse":"",
        "ActualResponse":"",
        "Status":"",
        "Utterance":"",
        "IsSelectedCB" : false,
        "DispID":""

    }
}