<form [formGroup]="passwordForm" (ngSubmit)="submitPassword()" class="password-Form w-100">
    <div class="form-group position-relative">
        <input autocomplete="new-password" type="{{oldPwdchecktype}}" class="form-control textBoxSizes"
            formControlName="oldpassword" id="oldpassword" placeholder="Old Password"
            [ngClass]="{ 'is-invalid': passwordSubmitted && p.oldpassword.errors }" [(ngModel)]='_oldpassword'>
        <div class="showPassword">
            <a (click)="showOldPassword()">
                <img src="../../../assets/images/showPwd.png" *ngIf="oldPeye" class="eyeIcon" alt="">
            </a>
            <a (click)="hideOldPassword()">
                <img src="../../../assets/images/hidePwd.png" *ngIf="oldPeyeSlash" class="eyeIcon" alt="">
            </a>
        </div>
        <div *ngIf="passwordSubmitted && p.oldpassword.errors" class="error invalid-feedback">
            <div *ngIf="p.oldpassword.errors.required" style="font-family: 'Lato-Regular';">Password is
                required</div>
            <div *ngIf="p.oldpassword.errors.minlength" style="font-family: 'Lato-Regular';">Password
                must
                be at least 6 characters</div>
        </div>
    </div>
    <div class="form-group position-relative">
        <input autocomplete="new-password" type="{{checktype}}" class="form-control textBoxSizes"
            formControlName="password" id="password" placeholder="New Password"
            [ngClass]="{ 'is-invalid': passwordSubmitted && p.password.errors }" [(ngModel)]='_newpassword'>
        <div class="showPassword">
            <a (click)="showPassword()">
                <img src="../../../assets/images/showPwd.png" *ngIf="eye" class="eyeIcon" alt="">
            </a>
            <a (click)="hidePassword()">
                <img src="../../../assets/images/hidePwd.png" *ngIf="eyeSlash" class="eyeIcon" alt="">
            </a>
        </div>
        <div *ngIf="passwordSubmitted && p.password.errors" class="error invalid-feedback">
            <div *ngIf="p.password.errors.required" style="font-family: 'Lato-Regular';">Password is
                required</div>
            <div *ngIf="p.password.errors.minlength" style="font-family: 'Lato-Regular';">Password must
                be at least 8 characters</div>
            <div *ngIf="p.password.errors.pattern" style="font-family: 'Lato-Regular';">Password must
                be contain at least one: Alphabet, Number, Special Character.</div>
        </div>
    </div>
    <div class="form-group position-relative mb-0">
        <input autocomplete="new-password" type="{{ConfirmPwdchecktype}}" class="form-control textBoxSizes"
            formControlName="confirmPassword" id="confirmPassword" placeholder="Confirm Password"
            [ngClass]="{ 'is-invalid': passwordSubmitted && p.confirmPassword.errors }" [(ngModel)]='_confirmPassword'>
        <div class="showPassword">
            <a (click)="showConfirmPassword()">
                <img src="../../../assets/images/showPwd.png" *ngIf="ConfirmPeye" class="eyeIcon" alt="">
            </a>
            <a (click)="hideConfirmPassword()">
                <img src="../../../assets/images/hidePwd.png" *ngIf="ConfirmPeyeSlash" class="eyeIcon" alt="">
            </a>
        </div>

        <div *ngIf="passwordSubmitted && p.confirmPassword.errors" class="error invalid-feedback">
            <div *ngIf="p.confirmPassword.errors.required" style="font-family: 'Lato-Regular';">Confirm
                Password is required</div>
            <div *ngIf="p.confirmPassword.errors.mustMatch" style="font-family: 'Lato-Regular';">
                Passwords must match</div>
        </div>
    </div>
    <div class="passwordPolicyBlock">
        <p class="policyTitle">Password policy</p>
        <ul>
            <li>The password should be between 8-12 characters and contain at least one: Alphabet,
                Number, Special Character. </li>
            <li>The password cannot be same as login id and it cannot match with last password.</li>
        </ul>
    </div>
    <div class="text-center">
        <button title="Login now" class="btn btn-success login_button">Change</button>
    </div>
</form>

<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>