import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MustMatch } from '../services/MustMatchValidator/must-match.service';

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  _isPasswordchange: boolean = false

  checktype: string = "password"
  eye: boolean = false
  eyeSlash: boolean = true;

  submitted = false;

  title = environment.pageTitle + ' - Notification';

  constructor(private formBuilder: FormBuilder, private router: Router, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
    },
      {
        validator: MustMatch('password', 'confirmPassword')
      });
  }

  get f() { return this.resetPasswordForm.controls; }

  onSubmit() {
    
    this.submitted = true;

    if (this.resetPasswordForm.invalid) {
      return;
    }

    if(this.resetPasswordForm.valid) {
      this._isPasswordchange = true
    }
  }

  showPassword() {
    this.checktype = "password"
    this.eye = false
    this.eyeSlash = true
  }

  hidePassword() {
    this.checktype = "text"
    this.eye = true
    this.eyeSlash = false
  }

}


