import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http'

import { HttpCommonReq } from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})

export class DashboardTargetService {

  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {
      getDashboardTargetList: 'api/TargetData/GetWeekData',
      UpdateDashboardTargetsData: 'api/TargetData/UpdateTargetValue'
    }
  }

  getDashboardTargetData() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.get(this.configUrls.getDashboardTargetList)
  }

  UpdateTargetValue(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.UpdateDashboardTargetsData, data)
  }
}
