<div class="container-fluid screen-container xl-bg-transperent">
    <div class="mainContainerWrapper failedFaqWrapper">
        <div class="header row">
            <div class="col-12">
                <div class="float-left">
                    <span class="page-title">Chat History</span>
                </div>
                <div class="float-right">
                    <button [disabled]='_ifNoRecords' [hidden]="_ViewClientRoleAccess" title="Export" type="button"
                        class="btn btn-secondary" (click)="AfterCLickExport()"><img
                            src="../../../assets/images/Export_icon.png" class="left-img"><span
                            class="align-middle">export</span></button>
                </div>
            </div>
        </div>

        <div class="row subHeader xl-pl-20px xl-pr-20px">
            <div class="col-md-12">
                <div class="float-left">

                    <div class="d-inline-block mb-2 position-relative mr-20px">
                        <div class="static-img"><img src="../../../assets/images/search.png"></div>
                        <input pInputText type="text" class="form-control userIdField" [(ngModel)]="searchUserId"
                            (keypress)="searchData($event, 'Search')" placeholder="Enter User ID">
                    </div>

                    <div class="d-inline-block position-relative mb-2 customCalendar">

                        <div class="calendar-img">
                            <a (click)="clearInputField(rangeDates)">
                                <img src="../../../assets/images/ClearInputIcon.png" *ngIf="_closeIcon">
                            </a>
                        </div>
                        <p-calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true"
                            inputId="range" (onSelect)="fetchDateRange(rangeDates)" placeholder="Enter date range"
                            [showIcon]="true">
                        </p-calendar>
                    </div>
                </div>

                <div class="float-right" (clickOutside)="onClickedOutside($event)">
                    <div class="dropdown-field">
                        <div class="dropdown-input-field" (click)="clickEvent()">
                            <div class="static-img" *ngIf="isHide"><img src="../../../assets/images/Filter.png"></div>
                            <div class="static-img" *ngIf="chageFilterInput == true"><img
                                    src="../../../assets/images/filter_icon.png"></div>
                            <input type="text" class="form-control custom-input-field"
                                [ngClass]="{'selectedfilterInput':chageFilterInput == true, 'selectedfilterInputOutline':selectedFieldOutline, 'greyInputOutline':greyFieldOutline == true}"
                                disabled>
                            <div class="filterChips">
                                <span *ngIf="chageFilterInput == true">{{ selectedAccountStatus }}</span>
                            </div>
                            <a class="dropdownArrow" (mouseover)="changeStyle()" (mouseout)="changeStyle()"
                                (click)="clickEvent()">
                                <img *ngIf="isHide" (click)="clickEvent()" src="../../../assets/images/arrow-down.png">
                                <img *ngIf="chageFilterInput == true" (click)="clickEvent()"
                                    src="../../../assets/images/Icon ionic-ios-arrow-down.png">
                            </a>
                        </div>
                    </div>
                    <div [ngClass]="{'showFilterContainer': toggle, 'hideFilterContainer':toggle == false}">
                        <div class="filterArrContainer">
                            <div class="filterHeaderBlock">
                                <span class="filterCotainerTitle">Filter types</span>
                            </div>
                            <div class="block w-100" *ngFor="let item of filterArr">
                                <div *ngFor="let data of item.items">
                                    <ul class="pl-0">
                                        <li>
                                            <label class="filterOptions mb-0"
                                                [ngClass]="{'selectedFilterOptions':data.checked == true}">
                                                <input type="checkbox" name="groupname" value="{{data.name}}"
                                                    [(ngModel)]="data.checked"
                                                    (ngModelChange)="selectFilterOption(data)" />
                                                <div class="filter-status-color" *ngIf="item.value == 'faqstatus'"
                                                    [ngClass]="{'status-bg-live': data.status === 'live', 'status-bg-live-update':data.status === 'liveContentUpdate',
                                            'status-bg-content-approved': data.status === 'liveContentApproved', 'status-bg-not-live': data.status === 'notLive'}">
                                                </div>
                                                {{data.name}}
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row faqListTable">

            <div class="tableContent" [hidden]="_ifNoRecords" [ngClass]="{'d-none':_isDateSelected}">
                <p-table #dt [value]="FailedFAQListArr" styleClass="p-custom-datatable" [rows]="10" [paginator]="true"
                    reflow="true" paginatorTemplate="{RowsPerPageDropdown}" paginatorPosition="bottom"
                    paginatorPosition="bottom" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,40,50]"
                    currentPageReportTemplate="Displaying {last} of {totalRecords}" [rowHover]="true"
                    [scrollable]="true" scrollHeight="flex" [resizableColumns]="true" [(first)]="first" editMode="row" [editable]="true">

                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col *ngFor="let col of failedHeadersArr" [style.width]='col.width'>
                        </colgroup>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn *ngFor="let col of failedHeadersArr" [ngClass]="col.field"
                                data-toggle="tooltip" data-placement="top" title="{{col.header}}">
                                <div class="tableHeader border-right-dadadd" *ngIf="col.isSort === 'true'">
                                    <div class="d-inline-block text-overflow-header"
                                        [ngClass]="{'pl-25':col.field === 'DispId', 'xl-pl-35px':col.field === 'DispId', 'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId'}">
                                        {{col.header}}
                                    </div>

                                </div>
                                <div class="tableHeader" *ngIf="col.isSort === 'false'"
                                    [ngClass]="{'border-right-dadadd': col.field != 'BondCreatedOn', 'pl-25':col.field === 'DispId', 'xl-pl-35px':col.field === 'DispId', 'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId'}">
                                    <div class="d-inline-block text-overflow-header">
                                        {{col.header}}
                                    </div>
                                </div>
                            </th>

                        </tr>
                        <tr *ngIf="isLoader">
                            <td *ngFor="let col of failedHeadersArr">
                                <div class="item customSkeletonLoader">
                                    <ngx-skeleton-loader count="20" appearance=""></ngx-skeleton-loader>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-car let-ri="rowIndex">
                        <tr [ngClass]="{'bg-e3fcff':_isRowSelected == true && ri == _selectedRowID - 1}">
                            <td pEditableColumn *ngFor="let col of failedHeadersArr"
                                [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">
                                <div [ngClass]="{'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId'}">
                                    <div *ngIf="col.field != 'BondCreatedOn' && col.field != 'UserSessionId'"
                                        [ngClass]="{'pl-25':col.field === 'DispId', 'xl-pl-35px':col.field === 'DispId'}">
                                        {{car[col.field]}}
                                    </div>

                                    <div style="color: blue; text-underline-position: below; pointer-events:fill !important"
                                        *ngIf="col.field == 'UserSessionId'"
                                        [ngClass]="{'pl-25':col.field === 'DispId', 'xl-pl-35px':col.field === 'DispId'}">

                                        <a (click)="getFilteredConversationHistData(car)">{{car[col.field]}}</a>
                                    </div>

                                    <div *ngIf="col.field === 'BondCreatedOn'">
                                        {{car[col.field] | date : 'MM/dd/yyyy HH:mm:ss'}}
                                    </div>

                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <div class="noRecordFound text-center border-top" *ngIf="_ifNoRecords">
                <img src="../../assets/images/no_records.png">
                <p class="title">No Result Found</p>
                <p class="mb-0 subtitle">Sorry, we couldn’t find any results for this search.</p>
            </div>

            <div class="noRecordFound text-center border-top" *ngIf="_isDateSelected">
                <img src="../../assets/images/no_records.png">
                <p class="mb-0 subtitle">Please select date to view the records</p>
            </div>

        </div>

    </div>
    <div #container class="chatHistoryBlock bg-white" id='ChatBody' *ngIf="_isChatHistoryBlock"
        style="overflow-y: scroll !important;">
        <div class="blockBanner">
            <div class="row">
                <div class="col-md-12">
                    <div class="float-left title">
                        Chat
                    </div>
                    <div class="float-right">
                        <a (click)="closeChat()">
                            <img src="../../../assets/images/cross.png" class="cross-img">
                        </a>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="subTitle">
                        User Session ID
                    </div>
                    <p class="sessionIdField">{{_fetchSessionID}}</p>
                </div>
            </div>
        </div>
        <div class="questionAnswerBlock"
            [style]="'--featured-bgColor:' + 'linear-gradient('+ _gradStartColor +', '+ _gradEndColor +')' ">
            <div *ngFor="let col of _Messages">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-12" *ngIf="col.SenderType == 'USER' && col.Message != 'Conversation Start'">
                            <div id="botMessage_{{col.ConversationLogId}}" class="bbuser-msg bbcon-common"
                                [ngClass]="{'border-red':this.selectedDataMsg === col.Message}">
                                <span id="userMessage">{{ col.Message }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" *ngIf="col.SenderType == 'BOND'">
                    <div class="bbchatIconMain disp-none" id="botMessage_1">
                        <div class="bbchatIconChild"><img src="../../assets/transparent_chatbot.png"
                                class="float-left bbmessage-icon margin-left-10">
                        </div>
                        <div class="remove-gradient-effect">
                            <dd class="bbbot-response white-space-pre margin-left-33">
                                <p class="mb-0 bbcon-common">{{col.Message}}
                                </p>
                                <span class='message-feedback margin-top-m36'>
                                    <span class='thumbsup-down transform' *ngIf="this._isthumbsDown == false">
                                        <img src="../../assets/ActiveDislikeIcon_1.png"
                                            [style]="'background-color:' + _gradEndColor " />
                                    </span>
                                    <span class='thumbsup-down transform' *ngIf="this._isthumbsDown == true">
                                        <img src="../../assets/ActiveLikeIcon_1.png"
                                            [style]="'background-color:' + _gradEndColor " />
                                    </span>
                                </span>
                            </dd>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" *ngFor="let Relatedquestion of col.RelatedIntents">
                    <div class="bbchatIconMain disp-none" id="botMessage_1">
                        <dd class="bbhelptext white-space-pre margin-left-33">
                            <p class="mb-0 bbcon-common">{{Relatedquestion}}
                            </p>
                        </dd>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>