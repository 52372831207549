<!-- <div class="container-fluid screen-container bg-white dashboard">

    <div class="row">
        <div class="col-md-9">
            <div class="row p-20px bg-f3f3f3">
                <div class="col-md-12 text-right">
                    <div class="dataBtn d-inline-block">
                        <button type="button" title={{_LstWeekTitle}} ng-class="{backgroundColorCommom : _SelectDuration === 'Last Week'}" class="btn btn-primary mr-2" (click)="LoadDashboardSummary('Last Week', undefined, undefined, this._activeType)" >W</button>
                        <button type="button" title={{_LstMonthTitle}} ng-class="{backgroundColorCommom : _SelectDuration === 'Last Month'}" class="btn btn-secondary mr-2" (click)="LoadDashboardSummary('Last Month', undefined, undefined, this._activeType)">M</button>
                        <button type="button" title={{_LstQuarterTitle}} ng-class="{backgroundColorCommom : _SelectDuration === 'Last Quarter'}" class="btn btn-success mr-2" (click)="LoadDashboardSummary('Last Quarter', undefined, undefined, this._activeType)">Q</button>
                        <button type="button" title={{_LstYearTitle}} ng-class="{backgroundColorCommom : _SelectDuration === 'Last Year'}" class="btn btn-danger mr-2" (click)="LoadDashboardSummary('Last Year', undefined, undefined, this._activeType)">Y</button>
                    </div>
                    <div class="calendarInput d-inline-block">
                        <p-calendar placeholder="Select date range" [(ngModel)]="rangeDates" [showButtonBar]="true"
                            selectionMode="range" [readonlyInput]="true" inputId="range"></p-calendar>
                    </div>
                </div>
            </div>
            <div class="row p-20px bg-f3f3f3">
                <div class="col-md-12">
                    <figure class="highcharts-figure">
                        <div id="highchartContainer"></div>
                    </figure>
                </div>
            </div>
            <hr /> -->

<!-- Total Users,  Total Sessions, Avg Duration -->
<!-- <div class="row count-btn p-20px">
                <div class="col-md-4 text-center">
                    <div class="spanClass cursor-pointer text-center"  (click)="getAxisValues(_SelectDuration, _FromDate, _ToDate, 'UserCount','')">
                        <span>
                            <span style="font-size:25px; padding-right:10px;">{{_BotInteraction.UserCount}}</span><br />
                            <span class="countSpans"> <b>Total Users </b></span>
                        </span>
                    </div>
                </div>
                <div class="col-md-4 text-center">
                    <div class="spanClass cursor-pointer text-center" (click)="getAxisValues(_SelectDuration, _FromDate, _ToDate, 'SessionCount','')">
                        <span>
                            <span style="font-size:25px; padding-right:10px;">{{_BotInteraction.SessionCount}}</span><br />
                            <span class="countSpans"> <b>Total Sessions </b></span>
                        </span>
                    </div>
                </div>
                <div class="col-md-4 text-center">
                    <div class="spanClass cursor-pointer text-center" (click)="getAxisValues(_SelectDuration, _FromDate, _ToDate, 'AvgDuration','')">
                        <span>
                            <span style="font-size:25px; padding-right:10px;">{{_BotInteraction.AvgDuration}}m</span><br />
                            <span class="countSpans"> <b>Avg Duration </b></span>
                        </span>
                    </div>
                </div>
            </div>
        </div> -->

<!-- right side bar -  Questions Answered -->
<!-- <div class="col-md-3 p-20px">
            <div class="media mb-4">
                <div class="media-body mr-3">
                    <label class="sidenavLables">Questions Answered</label>
                    <div>
                        <span class="sidenavCountsSpan">{{_BotInteraction.QuestionAnswered}}</span>
                        <p-progressBar class="{{QAcolor}}" [value]=_BotInteraction.QueAnsAchv>
                        </p-progressBar>
                        
                    </div>

                </div>
                <div class="align-self-center sidenavCountsPercentDiv {{QAcolor}}">
                    <span *ngIf="_BotInteraction.QueAnsPctIncDec =='NA'" class="sidenavCountsPercentSpan">{{ _BotInteraction.QueAnsPctIncDec}}</span>
                    <span *ngIf="_BotInteraction.QueAnsPctIncDec !='NA'" class="sidenavCountsPercentSpan">{{ _BotInteraction.QueAnsPctIncDec| number:'1.0-0'}}%</span>
                </div>
            </div> -->

<!-- Web Users -->
<!-- <div class="media mb-4">
                <div class="media-body mr-3">
                    <label class="sidenavLables">Web Users</label>
                    <div>
                        <span class="sidenavCountsSpan">{{_BotInteraction.WebUsers}}</span>
                        <p-progressBar class="{{WUcolor}}" [value]=_BotInteraction.WebUsrAchv></p-progressBar>
                    </div>

                </div>
                <div class="align-self-center sidenavCountsPercentDiv {{WUcolor}}" >
                    <span *ngIf="_BotInteraction.WebUsrPctIncDec =='NA'" class="sidenavCountsPercentSpan">{{ _BotInteraction.WebUsrPctIncDec}}</span>
                    <span *ngIf="_BotInteraction.WebUsrPctIncDec !='NA'" class="sidenavCountsPercentSpan">{{ _BotInteraction.WebUsrPctIncDec | number:'1.0-0'}}%</span>
                </div>
            </div> -->

<!-- Mobile Users -->
<!-- <div class="media mb-4">
                <div class="media-body mr-3">
                    <label class="sidenavLables">Mobile Users</label>
                    <div>
                        <span class="sidenavCountsSpan">{{_BotInteraction.MobileUsers}}</span>
                        <p-progressBar class="{{MUcolor}}" [value]=_BotInteraction.MobileUsrAchv></p-progressBar>
                    </div>

                </div>
                <div class="align-self-center sidenavCountsPercentDiv {{MUcolor}} ">
                    <span *ngIf="_BotInteraction.MobileUsrPctIncDec =='NA'" class="sidenavCountsPercentSpan">{{ _BotInteraction.MobileUsrPctIncDec}}</span>
                    <span *ngIf="_BotInteraction.MobileUsrPctIncDec !='NA'" class="sidenavCountsPercentSpan">{{ _BotInteraction.MobileUsrPctIncDec | number:'1.0-0'}}%</span>
                </div>
            </div> -->

<!-- Fallback Count -->
<!-- <div class="media mb-4">
                <div class="media-body mr-3">
                    <label class="sidenavLables">Fallback Count</label>
                    <div>
                        <span class="sidenavCountsSpan">{{_BotInteraction.FallbackCount}}</span>
                        <p-progressBar class="{{FCcolor}}" [value]=_BotInteraction.FallBackCountAchv></p-progressBar>
                    </div>
                </div>
                <div class="align-self-center sidenavCountsPercentDiv {{FCcolor}}">
                    <span *ngIf="_BotInteraction.FallbackCountPctIncDec =='NA'" class="sidenavCountsPercentSpan">{{ _BotInteraction.FallbackCountPctIncDec}}</span>
                    <span *ngIf="_BotInteraction.FallbackCountPctIncDec !='NA'" class="sidenavCountsPercentSpan">{{ _BotInteraction.FallbackCountPctIncDec | number:'1.0-0'}}%</span>
                </div>
            </div> -->

<!-- Avg NPS score -->
<!-- <div class="media mb-4">
                <div class="media-body mr-3">
                    <label class="sidenavLables">Avg NPS score</label>
                    <div>
                        <span class="sidenavCountsSpan">{{_BotInteraction.AvgNPSScore}}</span>
                        <p-progressBar class="{{ANScolor}}" [value]=_BotInteraction.AvgNPSScoreAchv></p-progressBar>
                    </div>
                </div>
                <div class="align-self-center sidenavCountsPercentDiv {{ANScolor}}">
                    <span *ngIf="_BotInteraction.AvgNPSScorePercIncDec =='NA'" class="sidenavCountsPercentSpan">{{ _BotInteraction.AvgNPSScorePercIncDec}}</span>
                    <span *ngIf="_BotInteraction.AvgNPSScorePercIncDec !='NA'" class="sidenavCountsPercentSpan">{{ _BotInteraction.AvgNPSScorePercIncDec | number:'1.0-0'}}%</span>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="HDScreenContainer">
    <div class="container-fluid screen-container bg-white dashboard" id="HomePageD">

        <div class="header row">
            <div class="col-md-12">
                <div class="float-left">
                    <span class="page-title">Enterprise Dashboard</span>
                </div>
                <div class="float-right">
                    <span class="align-top">
                        <p-dropdown [options]="calendar" class="CalenderDropDown" [(ngModel)]="selectedOption"
                            (onChange)="mySelectHandler(selectedOption)" optionLabel="name"></p-dropdown>
                    </span>
                    <button [hidden]="_ViewClientRoleAccess" title="Export" type="button" class="btn btn-secondary"
                        (click)='ExportDashboardData(selectedOption, null, null, _activeType)'>
                        <img src="../../../assets/images/Export_icon.png" class="left-img export_icon">
                        <img src="../../../assets/images/Export-icon-selected.png" class="left-img seleted_export_icon">
                        <span class="align-middle">export</span>
                    </button>
                    <button [hidden]="_ViewClientRoleAccess" title="Share" type="button" class="btn btn-primary" (click)="convetToPDF()">
                        <img src="../../../assets/images/share_icon.png" class="left-img"><span class="align-middle"
                            style="color:white !important;">Share</span></button>
                </div>
            </div>
        </div>

        <!-- <div class="header row">
        <div class="col-md-8">
            <span class="page-title">Usage Dashboard</span>
        </div>
        <div class="col-md-4">
            <span class="align-top">
                <p-dropdown [options]="calendar" class="CalenderDropDown" [(ngModel)]="selectedOption"
                    (onChange)="mySelectHandler(selectedOption)" optionLabel="name"></p-dropdown>
            </span>
            <button type="button" class="btn btn-secondary"><img src="../../../assets/images/Export_icon.png"
                    class="left-img"><span class="align-middle">export</span></button>
            <button type="button" class="btn btn-primary"><img src="../../../assets/images/share_icon.png"
                    class="left-img"><span class="align-middle">Share</span></button>
        </div>
    </div> -->

        <div class="row chartDetailsBlock">
            <div class="col-md-8">
                <figure class="highcharts-figure">
                    <div id="highchartContainer"></div>
                </figure>
            </div>

            <div class="col-md-4 rightSideMenu">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item" style="padding-top: 0;">
                        <span class="categoryName d-inline-block">BOND Score</span>
                        <span class="valueBlock d-inline-block">{{_BotInteraction.AccountsOpened}}</span>
                        <span class="valueBlock d-inline-block">{{_BotInteraction.AccountsOpenedPer| number:'1.0-0'}}%
                            <i *ngIf="_BotInteraction.AccountsOpenedIndicator =='Down'" class="fa fa-long-arrow-down"
                                aria-hidden="true"></i>
                            <i *ngIf="_BotInteraction.AccountsOpenedIndicator =='Up'" class="fa fa-long-arrow-up"
                                aria-hidden="true"></i>
                        </span>
                    </li>

                    <li class="list-group-item">
                        <span class="categoryName d-inline-block">Green Score</span>
                        <span class="valueBlock d-inline-block">{{_BotInteraction.QuestionAnswered}}</span>
                        <span class="valueBlock d-inline-block">{{_BotInteraction.QueAnsAchv| number:'1.0-0'}}%
                            <i *ngIf="_BotInteraction.QuesAnsIndicator =='Down'" class="fa fa-long-arrow-down"
                                aria-hidden="true"></i>
                            <i *ngIf="_BotInteraction.QuesAnsIndicator =='Up'" class="fa fa-long-arrow-up"
                                aria-hidden="true"></i>
                        </span>

                    </li>

                    <li class="list-group-item">
                        <span class="categoryName d-inline-block">ESG Index</span>
                        <span class="valueBlock d-inline-block">{{_BotInteraction.WebUsers}}</span>
                        <span class="valueBlock d-inline-block">{{_BotInteraction.WebUsrAchv| number:'1.0-0'}}%
                            <i *ngIf="_BotInteraction.WebUserIndicator =='Up'" class="fa fa-long-arrow-up"
                                aria-hidden="true"></i>
                            <i *ngIf="_BotInteraction.WebUserIndicator =='Down'" class="fa fa-long-arrow-down"
                                aria-hidden="true"></i>
                        </span>

                    </li>

                    <li class="list-group-item">
                        <span class="categoryName d-inline-block">Needs Addressed</span>
                        <span class="valueBlock d-inline-block">{{_BotInteraction.MobileUsers}}</span>
                        <span class="valueBlock d-inline-block">{{_BotInteraction.MobileUsrAchv| number:'1.0-0'}}%
                            <i *ngIf="_BotInteraction.MobUserIndicator =='Down'" class="fa fa-long-arrow-down"
                                aria-hidden="true"></i>
                            <i *ngIf="_BotInteraction.MobUserIndicator =='Up'" class="fa fa-long-arrow-up"
                                aria-hidden="true"></i>
                        </span>
                    </li>

                    <li class="list-group-item">
                        <span class="categoryName d-inline-block">Insights Provided</span>
                        <span class="valueBlock d-inline-block">{{_BotInteraction.FallbackCount}}</span>
                        <span class="valueBlock d-inline-block">{{_BotInteraction.FallBackCountAchv| number:'1.0-0'}}%
                            <i *ngIf="_BotInteraction.FallbackIndicator =='Up'" class="fa fa-long-arrow-up"
                                aria-hidden="true"></i>
                            <i *ngIf="_BotInteraction.FallbackIndicator =='Down'" class="fa fa-long-arrow-down"
                                aria-hidden="true"></i>
                        </span>
                    </li>

                    <li class="list-group-item">
                        <span class="categoryName d-inline-block">Engagement</span>
                        <span class="valueBlock d-inline-block">{{_BotInteraction.AvgNPSScore}}</span>
                        <span class="valueBlock d-inline-block">{{_BotInteraction.AvgNPSScoreAchv| number:'1.0-0'}}%
                            <i *ngIf="_BotInteraction.AvgNPSIndicator =='Up'" class="fa fa-long-arrow-up"
                                aria-hidden="true"></i>
                            <i *ngIf="_BotInteraction.AvgNPSIndicator =='Down'" class="fa fa-long-arrow-down"
                                aria-hidden="true"></i>
                        </span>
                    </li>

                    <li class="list-group-item">
                        <span class="categoryName d-inline-block">Carbon Credits</span>
                        <span class="valueBlock d-inline-block">{{_BotInteraction.InsightsProvided}}</span>
                        <span class="valueBlock d-inline-block">{{_BotInteraction.InsightsProvidedPer| number:'1.0-0'}}%
                            <i *ngIf="_BotInteraction.InsightsProvidedIndicator =='Up'" class="fa fa-long-arrow-up"
                                aria-hidden="true"></i>
                            <i *ngIf="_BotInteraction.InsightsProvidedIndicator =='Down'" class="fa fa-long-arrow-down"
                                aria-hidden="true"></i>
                        </span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row count-btn">
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-4">
                        <div class="spanClass cursor-pointer"
                            [ngClass]="{'selectedField':_isSelectedField == true && this._selectedtype === 'UserCount'} "
                            (click)="getAxisValues(_SelectDuration, _FromDate, _ToDate, 'UserCount','')">
                            <div class="countSpans">
                                <p class="countSpansTitle">Unique Users</p>
                                <p class="countSpansNumber">{{_BotInteraction.UserCount}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="spanClass cursor-pointer"
                            [ngClass]="{'selectedField':_isSelectedField && this._selectedtype === 'SessionCount'}"
                            (click)="getAxisValues(_SelectDuration, _FromDate, _ToDate, 'SessionCount','')">
                            <div class="countSpans">
                                <p class="countSpansTitle">Total Sessions</p>
                                <p class="countSpansNumber">{{_BotInteraction.SessionCount}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="spanClass cursor-pointer"
                            [ngClass]="{'selectedField':_isSelectedField && this._selectedtype === 'AvgDuration'}"
                            (click)="getAxisValues(_SelectDuration, _FromDate, _ToDate, 'AvgDuration','')">
                            <!-- <span>
                            <span class="countSpansNumber">{{_BotInteraction.AvgDuration}}m</span><br />
                            <span class="countSpans">Avg Duration</span>
                        </span> -->
                            <div class="countSpans">
                                <p class="countSpansTitle">Avg Duration</p>
                                <p class="countSpansNumber">{{_BotInteraction.AvgDuration | number:'1.1-1'}}m</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- commented for shell barger -->
            <div class="col-md-4">
                <div class="rightSideMenutotal">
                    <span class="title">Happiness Index</span>
                    <span class="score">{{_BotInteraction.CrossSellOpp}}</span>
                </div>
            </div>
            <!-- commented for shell barger -->
        </div>
    </div>
</div>

<ng-template #modalWarningAndInfo let-modal>
    <div class="customThemeModal">
        <div class="" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="closePopup">
                        <a (click)="modal.dismiss('Cross click')">
                            <img src="../../../assets/images/cross.png" class="cross-img">
                        </a>
                    </div>
                    <div class="popupImg">
                        <img src="{{popupImg}}" class="left-img">
                    </div>
                    <div class="popupTitle">{{popupContent}}</div>
                    <div class="popupContent">{{popupContentError}}</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>