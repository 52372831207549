<div class="container-fluid screen-container xl-bg-transperent " *ngIf="_showAddEditClickableFlowScreen==false">
    <div class="mainContainerWrapper">

        <div class="header row">
            <div class="col-md-12">
                <div class="float-left">
                    <span class="page-title">Clickable Flow</span>
                </div>

                <div class="float-right">
                    <button title="Add Clickable Flow" type="button" class="btn btn-primary"
                        (click)="addNewRow('Add')"><img src="../../../assets/images/plus_icon.png"
                            class="left-img"><span class="align-middle">add
                            Clickable Flow</span></button>

                </div>
            </div>
        </div>

        <div class="row">

            <div class="tableContent" [hidden]="_ifNoRecords">
                <p-table #dt [value]="ClickableFlowListArr" styleClass="p-custom-datatable" [rows]="10"
                    [paginator]="true" reflow="true" paginatorTemplate="{RowsPerPageDropdown}"
                    paginatorPosition="bottom" paginatorPosition="bottom" [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[10,20,30,40,50]"
                    currentPageReportTemplate="Displaying {last} of {totalRecords}" [rowHover]="true"
                    [scrollable]="true" scrollHeight="flex" [(first)]="first" editMode="row" [editable]="true"
                    [resizableColumns]="true">

                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col *ngFor="let col of clickableFlowHeadersArr" [style.width]='col.width'>
                        </colgroup>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn *ngFor="let col of clickableFlowHeadersArr"
                                [pSortableColumn]="col.field" [pSortableColumnDisabled]="col.field === 'BondModifiedOn'"
                                style="text-align: left;" [ngClass]="col.field" data-toggle="tooltip"
                                data-placement="top" title="{{col.header}}">
                                <div class="tableHeader" *ngIf="col.isSort === 'true'" (click)="sortcolumn(col.field)"
                                    [ngClass]="{'border-right-dadadd': col.field != 'BondModifiedOn' && col.field != 'ClickableFlowsJson'}">
                                    <div class="d-inline-block text-overflow-header"
                                        [ngClass]="{'pl-15':col.field === 'ClickableFlowsID', 'xl-pl-20px':col.field === 'ClickableFlowsID'}">
                                        {{col.header}}</div>
                                    <div class="tableSortIcon">
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </div>
                                </div>
                                <div *ngIf="col.isSort === 'false'"
                                    [ngClass]="{'pl-15':col.field === 'BondModifiedOn', 'xl-pl-20px':col.field === 'BondModifiedOn'}">
                                    {{col.header}}
                                </div>
                            </th>

                        </tr>
                        <tr *ngIf="isLoader">
                            <td *ngFor="let col of clickableFlowHeadersArr">
                                <div class="item customSkeletonLoader">
                                    <ngx-skeleton-loader count="20" appearance=""></ngx-skeleton-loader>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-car let-ri="rowIndex">
                        <tr class="position-relative">
                            <td pEditableColumn *ngFor="let col of clickableFlowHeadersArr"
                                [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">
                                <div
                                    [ngClass]="{'modDtBlock': col.field === 'BondModifiedOn','xl-pl-14px':col.field !== 'ClickableFlowsID', 'pl-10':col.field !== 'ClickableFlowsID'}">
                                    <div *ngIf="col.field != 'FlowName' && col.field != 'FlowDiagramJson' && col.field != 'ClickableFlowsJson' && col.field != 'BondModifiedOn'"
                                        [ngClass]="{'pl-25':col.field === 'ClickableFlowsID', 'xl-pl-35px':col.field === 'ClickableFlowsID'}">
                                        {{car[col.field]}}
                                    </div>

                                    <div *ngIf="col.field === 'BondModifiedOn'"
                                        [ngClass]="{'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId'}">
                                        {{car[col.field] | date : 'MM/dd/yyyy HH:mm:ss'}}
                                    </div>

                                    <!-- <div *ngIf="col.field === 'RvwStatus'" class="statusBlock"
                                        [ngClass]="{'completeStatus':car[col.field] === 'Completed', 'incompleteStatus':car[col.field] === 'Incomplete', 'pendingStatus':car[col.field] === 'Pending'}">
                                        <a (click)="statusLink(car)">{{car[col.field]}}</a>
                                    </div> -->
                                </div>

                                <div *ngIf="col.field === 'FlowName'"
                                    [ngClass]="{'xl-pl-14px':col.field !== 'ClickableFlowsID', 'pl-10':col.field !== 'ClickableFlowsID'}">
                                    {{car[col.field]}}
                                </div>

                                <!-- <div *ngIf="col.field === 'FlowDiagramJson'"
                                    [ngClass]="{'xl-pl-14px':col.field !== 'ClickableFlowsID', 'pl-10':col.field !== 'ClickableFlowsID', 'text-overflow-field': col.field === 'FlowDiagramJson'}"
                                    [innerHtml]="car[col.field]">
                                </div>

                                <div *ngIf="col.field === 'ClickableFlowsJson'"
                                    [ngClass]="{'xl-pl-14px':col.field !== 'ClickableFlowsID', 'pl-10':col.field !== 'ClickableFlowsID', 'text-overflow-field': col.field === 'ClickableFlowsJson'}"
                                    [innerHtml]="car[col.field]">
                                </div> -->

                                <div class="actions w-100"
                                    [ngClass]="{'showactionBlock': col.field === 'BondModifiedOn', 'xl-pl-14px':col.field !== 'ClickableFlowsID', 'pl-10':col.field !== 'ClickableFlowsID'}">
                                    <span>
                                        <a (click)="OnAddEditBtnClick('Edit',car.ClickableFlowsMasterID)"
                                            class="mr-2 cursor-pointer" class="faqModifyActionBtn" title="Modify">
                                            <img class="block_img" src="../../../assets/images/Modify.png">
                                            <img class="hover_modify_img"
                                                src="../../../assets/images/Modify_on_hover.png">
                                        </a>
                                    </span>
                                    <span class="float-right">
                                        <a class="faqDeleteActionBtn" title="Delete" (click)="OpenDeletePopup(car)">
                                            <img class="block_img" src="../../../assets/images/delete.png">
                                            <img class="hover_delete_img"
                                                src="../../../assets/images/delete_on_hover.png">
                                        </a>
                                    </span>
                                </div>

                                <!-- <div *ngIf="col.field === 'ImgPath'"
                                    [ngClass]="{'d-none': isStatusClick == true && car.DispID === eventDispId }">
                                    <a routerLink="/portal/personas/Individual">
                                        <img src="../../../assets/images/avataar/{{car[col.field]}}" class="avataarImg">
                                    </a>
                                </div>

                                <div *ngIf="col.field === 'download' " class="pendingStatusMsg"
                                    [ngClass]="{'d-block': isPendingStatus == true && car.DispID === eventDispId }">

                                    <p-radioButton name="groupname" value="true" label="Approve"
                                        (click)="checkPendingStatus('approve', car)" [(ngModel)]="PendingStatus">
                                    </p-radioButton>
                                </div>

                                <div *ngIf="col.field === 'ImgPath' " class="pendingStatusMsg"
                                    [ngClass]="{'d-block': isPendingStatus == true && car.DispID === eventDispId }">

                                    <p-radioButton name="groupname" value="disapprove" label="Disapprove"
                                        (click)="checkPendingStatus('disapprove', car)" [(ngModel)]="PendingStatus">
                                    </p-radioButton>
                                </div>

                                <div class="position-absolute incompleteStatusPosition"
                                    *ngIf="isIncompleteStatus == true && col.field === 'download'">
                                    <div [innerHtml]="userHtml" class="incompleteStatusMsg incomplete"
                                        [ngClass]="{'d-block': isIncompleteStatus == true && car.DispID === eventDispId }">
                                        {{car.Reason}}
                                    </div>
                                </div> -->

                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <div class="noRecordFound text-center border-top" *ngIf="_ifNoRecords">
                <img src="../../assets/images/no_records.png">
                <p class="title">No Result Found</p>
                <p class="mb-0 subtitle">Sorry, we couldn’t find any results for this search.</p>
            </div>
        </div>
    </div>
</div>

<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>


<ng-template #modalDataDelete let-modal>
    <div class="deletePopupModal">
        <div class="modal-content">
            <div class="modal-body">
                <div class="deleteClosePopup">
                    <a (click)="modal.dismiss('Cross click')">
                        <img src="../../../assets/images/cross.png" class="cross-img">
                    </a>
                </div>
                <div class="deletePopupTitle">This will delete the Flow</div>
                <div class="popupContent popupContentQue ">{{_QuestionTodelete}}
                </div>

                <div class="PoupContent deletebtnsdiv">
                    <button title="Delete" type="button" class="btn btn-primary mr-10px deletebtn"
                        (click)="deleteCFRecord(_deleteFlowData); modal.dismiss(); ">Delete</button>
                    <button title="Cancel" type="button" class="btn btn-secondary deletebtn"
                        (click)="modal.dismiss('Cross click')">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<app-add-clickable-flow *ngIf="_showAddEditClickableFlowScreen==true" (inputdata)="AddEditPageResult($event)"
    [inputVariable]="{PageName: PageName,selectedRowData:_selectedClickableFlowById}"></app-add-clickable-flow>

<div class="animatedLoader" *ngIf="_isSaveDiagramLoader" style="left: 0;">
    <img src="../../../assets/images/animation_loader.gif" class="loaderImg">

</div>