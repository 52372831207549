<div class="container-fluid screen-container xl-bg-transperent ">

    <div class="mainContainerWrapper">

        <div class="header row">
            <div class="col-12">
                <div class="float-left">
                    <span class="page-title">Deleted FAQ</span>
                </div>
            </div>
        </div>

        <div class="row subHeader xl-pl-20px xl-pr-20px">
            <div class="col-md-12">
                <div class="float-left">
                    <div class="search-field">

                        <div class="static-img"><img src="../../../assets/images/search.png"></div>
                        <input type="text" class="form-control custom-input-field" placeholder="Search"
                            [(ngModel)]="searchFAQ" (keypress)="searchData($event)">
                        <div class="multiselectSearch">
                            <p-multiSelect [options]="searchOptionArr" [(ngModel)]="selectedSearchOpt"
                            [disabled]='searchFAQ==""' (ngModelChange)="selectSearchOption($event)" optionLabel="name" display="chip"
                                [showHeader]="false" ></p-multiSelect>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="tableContent" [hidden]="_ifNoRecords">
                <p-table #dt [value]="deletedFAQArr" styleClass="p-custom-datatable" [rows]="10" [paginator]="true"
                    reflow="true" paginatorTemplate="{RowsPerPageDropdown}" paginatorPosition="bottom"
                    paginatorPosition="bottom" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30,40,50]"
                    currentPageReportTemplate="Displaying {last} of {totalRecords}" [rowHover]="true"
                    [scrollable]="true" scrollHeight="flex" [resizableColumns]="true" [(first)]="first" editMode="row" [editable]="true">

                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <col *ngFor="let col of deletedfaqHeadersArr" [style.width]='col.width'>
                        </colgroup>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th pResizableColumn *ngFor="let col of deletedfaqHeadersArr" [pSortableColumn]="col.field"
                                style="text-align: left;" [ngClass]="col.field" data-toggle="tooltip"
                                data-placement="top" title="{{col.header}}">
                                <div class="tableHeader" *ngIf="col.isSort === 'true'" (click)="sortcolumn(col.field)"
                                    [ngClass]="{'border-right-dadadd': col.field != 'BondCreatedOn'}">
                                    <div class="d-inline-block text-overflow-header"
                                        [ngClass]="{'pl-15':col.field === 'Id', 'xl-pl-20px':col.field === 'Id'}">
                                        {{col.header}}
                                    </div>
                                    <div class="tableSortIcon">
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </div>
                                </div>
                                <div class="tableHeader" *ngIf="col.isSort === 'false'"
                                    [ngClass]="{'border-right-dadadd': col.field != 'BondCreatedOn', 'pl-25':col.field === 'Id', 'xl-pl-35px':col.field === 'Id', 'xl-pl-14px':col.field !== 'Id', 'pl-10':col.field !== 'Id'}">
                                    {{col.header}}
                                </div>
                            </th>

                        </tr>
                        <tr *ngIf="isLoader">
                            <td *ngFor="let col of deletedfaqHeadersArr">
                                <div class="item customSkeletonLoader">
                                    <ngx-skeleton-loader count="20" appearance=""></ngx-skeleton-loader>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-car let-ri="rowIndex">
                        <tr style="word-wrap:break-word;">
                            <td pEditableColumn *ngFor="let col of deletedfaqHeadersArr"
                                [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">
                                <div [ngClass]="{'xl-pl-14px':col.field !== 'Id', 'pl-10':col.field !== 'Id'}">
                                    <div *ngIf="col.field != 'BondCreatedOn'"
                                        [ngClass]="{'pl-25':col.field === 'Id', 'xl-pl-35px':col.field === 'Id'}">
                                        {{car[col.field]}}
                                    </div>

                                    <div *ngIf="col.field === 'BondCreatedOn'">
                                        {{car[col.field] | date : 'MM/dd/yyyy HH:mm:ss'}}
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                </p-table>
            </div>

            <div class="noRecordFound text-center border-top" *ngIf="_ifNoRecords">
                <img src="../../assets/images/no_records.png">
                <p class="title">No Result Found</p>
                <p class="mb-0 subtitle">Sorry, we couldn’t find any results for this search.</p>
            </div>
        </div>

    </div>

</div>