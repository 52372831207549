<div class="customThemeModal">
    <div class="" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="closePopup">
                    <a (click)="inputVariable.dismissFunction()">
                        <img src="../../../assets/images/cross.png" class="cross-img">
                    </a>
                </div>
                <div class="popupImg">
                    <img src="{{inputVariable.image}}" class="left-img">
                </div>
                <div class="popupTitle">{{inputVariable.popupTitle}}</div>
                <div class="popupContent">{{inputVariable.popupContent}}</div>
                <div class="popupFooter">

                    <button type="button" class="btn btn-primary mr-10px" (click)="inputVariable.primaryBtnFunction()">{{inputVariable.primaryBtn}}</button>
                    <button title="Cancle" type="button" class="btn btn-secondary" (click)="inputVariable.dismissFunction()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>