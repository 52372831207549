import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Table } from 'primeng/table';
import { ConfusionMatrixService } from '../services/ConfusionMatrix/confusion-matrix.service';
import { ConfusionMatrixEntity } from './ConfusionMatrixEntity';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { FaqService } from '../services/faq/faq.service';

@Component({
  selector: 'app-confusion-matrix',
  templateUrl: './confusion-matrix.component.html',
  styleUrls: ['./confusion-matrix.component.css']
})
export class ConfusionMatrixComponent implements OnInit {

  ConfusionMatrixListArr: any = [];
  ConfusionMatrixHeadersArr: any = [];
  ExpectedIntentListArr: any = [];

  sortColumnName: string;
  sortorder: boolean;
  descOrder: Boolean;

  first: number = 0;

  _ifNoRecords: boolean = false

  searchOptionArr: any = [];
  selectedSearchOpt: any[];

  filteredExpectedIntent: any = []
  selectedExpectedIntent: any

  searchUSER: string = "";
  rangeValues: number[] = [0, 100];
  formsttedrangeValues: any

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string
  closeModal: any

  _isSearchDisable: boolean = false

  _ranngeValues = [0, 0]

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;
  @ViewChild("dt", { static: false }) public ptable: Table;

  title = environment.pageTitle + ' - Confusion Matrix';

  constructor(public confusionMatrixService: ConfusionMatrixService, private modalService: NgbModal, private titleService: Title
    , public faqconfigservice: FaqService) {
    this.getBuildModelStatus();
  }

  ngOnInit(): void {

    var event = {
      values: [0, 100]
    }
    this.handleChange(event)
    this.titleService.setTitle(this.title);
    this.searchData("", "", this._ranngeValues)
    this.getAllConfusionMatrixData();
    this.ConfusionMatrixListHeaders();
    this.filterDropdown();
  }

  filterDropdown() {
    this.searchOptionArr = [
      { name: 'Question', code: 'Question' },
      { name: 'Expected Intent', code: 'Expected Intent' },
      { name: 'Confidence', code: 'Confidence' }
    ]
  }

  isLoader: boolean = true;

  getAllConfusionMatrixData() {
    // this.isLoader = true
    this.confusionMatrixService.GetConfusionMatrixData().subscribe((res: any) => {
      if (res.Status.toUpperCase() == 'SUCCESS') {
        // this.ConfusionMatrixListArr = res.Data;
        this.ExpectedIntentListArr = res.Data

        // this.isLoader = false

        // if (this.ConfusionMatrixListArr.length == 0) {
        //   setTimeout(() => {
        //     this._ifNoRecords = true
        //   }, 1000);
        // } else {
        //   this._ifNoRecords = false
        // }

      } else if (res.Error) {

        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  searchData(SearchUser: any, expectedIntent: any, vRangeValues: any) {


    this._isSearchDisable = true
    var confusionMatrixEntityObj;

    confusionMatrixEntityObj = new ConfusionMatrixEntity();
    confusionMatrixEntityObj.Question = SearchUser.trim();

    if (expectedIntent) {
      confusionMatrixEntityObj.ExpectedIntent = expectedIntent.name == undefined ? expectedIntent : expectedIntent.name
      confusionMatrixEntityObj.ExpectedIntent = confusionMatrixEntityObj.ExpectedIntent.trim()
    }

    if (vRangeValues !== undefined && vRangeValues !== "") {

      confusionMatrixEntityObj.ConfidanceFrom = vRangeValues[0];
      confusionMatrixEntityObj.ConfidanceTo = vRangeValues[1];
    }
    else {
      confusionMatrixEntityObj.ConfidanceFrom = 0;
      confusionMatrixEntityObj.ConfidanceTo = 0;
    }
    debugger
    if (this._selectedModel)
      confusionMatrixEntityObj.ModelName = this._selectedModel.name;
    else 
      confusionMatrixEntityObj.ModelName = ""

    this.SearchConfusionMatrixData(confusionMatrixEntityObj);
  }


  SearchConfusionMatrixData(searchObj: any) {
    this._ifNoRecords = false
    this.isLoader = true;


    this.confusionMatrixService.SearchConfusionMatrixData(searchObj).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {

        this._isSearchDisable = false
        this.ConfusionMatrixListArr = res.Data;

        this.isLoader = false


        if (this.ConfusionMatrixListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        } else {
          this._ifNoRecords = false
        }

      } else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  ConfusionMatrixListHeaders() {
    this.ConfusionMatrixHeadersArr = [
      { field: 'ConfusionMatrixId', header: 'Sr No.', width: "4%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'Question', header: 'Question', width: "12%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'ExpectedIntent', header: 'Expected Intent', width: "12%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'Section Name' },
      { field: 'PredictedIntent', header: 'Predicted Intent', width: "12%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'FAQ' },
      { field: 'PredictedIntentConfidence', header: 'Predicted Intent Confidence', width: "12%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'b' }
    ]
  }

  sortcolumn(ColumnName: any) {

    let reverse = this.sortColumnName === ColumnName ? !this.descOrder : undefined;

    if (reverse === undefined)
      reverse = false;

    this.sortColumnName = ColumnName;
    this.descOrder = reverse;

    if (this.descOrder == true)
      this.sortorder = false;
    else
      this.sortorder = true;
  }

  selectSearchOption(text: any) { }

  filterExpectedIntent(event: any) {
    let filtered: any[] = [];
    let result: any = [];
    let query = event.query;

    result = Array.from(this.ExpectedIntentListArr.reduce((m: any, t: any) => m.set(t.ExpectedIntent, t), new Map()).values());

    for (let i = 0; i < result.length; i++) {
      if (result[i].ExpectedIntent.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push({
          name: result[i].ExpectedIntent,
          value: result[i].ExpectedIntent
        });
      }
    }

    this.filteredExpectedIntent = filtered
  }

  filterIntentSelection(intent: any) {

  }

  handleChange(rangeVal: any) {
    this.formsttedrangeValues = rangeVal.values[0] + ' - ' + rangeVal.values[1]
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  _selectedModel: any = null
  _TraningModelNameList: any
  _buildModelArr: any = []

  getBuildModelStatus() {
    debugger
    this.faqconfigservice.getBuildModelStatus().subscribe((res: any) => {
      debugger
      if (res.Status.toUpperCase() == 'SUCCESS') {


        this._TraningModelNameList = res.Data.TraningModelNames;

        if (this._TraningModelNameList.length > 0) {
          this._TraningModelNameList.forEach((value: any, key: any) => {
            this._buildModelArr.push({ name: value.ModelName, code: value.DisplayModelName })
          });
        }
        else {
          this._buildModelArr = [
            { name: 'MODEL1', code: 'Model 1' }
          ]
        }
      }
      else {
        this.popupImg = "../../../assets/images/CommonError.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }
}
