<div class="container-fluid ClickableFlowWrapper">
    <div class="mb-3 text-center" *ngIf="this._inputData.PageName == 'Edit'">
        <h4><b>{{flowName}}</b></h4>
    </div>
    <div class="mb-3" *ngIf="this._inputData.PageName == 'Add'">
        <input pInputText type="text" [(ngModel)]="flowName" maxlength="50" placeholder="Enter flow name...."
            class="intentInputField form-control w-50" (keyup)="checkWhiteSpace(flowName)">
        <label *ngIf="_isFlowName" class="errorFlag mb-0">Please enter flowName.</label>
    </div>

    <div class="row flowchartContainer" [ngClass]="{'ContainerHeightAfterErrorMsg': _isFlowName}"
        *ngIf="isCreateFlowchart">
        <div class="col-md-2">
            <div id="palette-space" class="sb-mobile-palette">
                <ejs-symbolpalette id="symbolpalette" [enableAnimation]='enableAnimation' [expandMode]='expandMode'
                    [palettes]='palettes' (created)='create($event)' width="100%" height="700px" [symbolHeight]=60
                    [symbolWidth]=60 [symbolMargin]='symbolMargin' [getSymbolInfo]='getSymbolInfo'>
                </ejs-symbolpalette>
            </div>
        </div>

        <div class="col-md-10">
            <ejs-diagram #diagram id="diagram" height="100%" [contextMenuSettings]="contextMenuSettings"
                (contextMenuClick)="contextMenuClick($event)" (contextMenuOpen)="contextMenuOpen($event)"
                [getNodeDefaults]="nodeDefaults">
            </ejs-diagram>
            <router-outlet></router-outlet>
        </div>
    </div>

    <div class="row flowchartContainer viewDiagramContainer" *ngIf="!isCreateFlowchart">
        <div class="col-md-2">
            <div id="palette-space" class="sb-mobile-palette">
                <ejs-symbolpalette id="symbolpalette" [enableAnimation]='enableAnimation' [expandMode]='expandMode'
                    [palettes]='palettes' (created)='create($event)' width="100%" height="700px" [symbolHeight]=60
                    [symbolWidth]=60 [symbolMargin]='symbolMargin' [getSymbolInfo]='getSymbolInfo'>
                </ejs-symbolpalette>
            </div>
        </div>

        <div class="col-md-10">
            <ejs-diagram #viewdiagram id="viewdiagram" height="100%" [nodes]="nodes" [connectors]="connectors"
                [layout]='layout' [contextMenuSettings]="contextMenuSettings"
                (contextMenuClick)="contextMenuClick($event)" (contextMenuOpen)="contextMenuOpen($event)"
                [getNodeDefaults]="nodeDefaults">
            </ejs-diagram>
            <router-outlet></router-outlet>
        </div>
    </div>

    <div class="row pageFooterBtn">
        <div class="col-md-12">
            <button type="button" title="Save" class="btn btn-primary mr-3" (click)="saveMainDiagram()">Save
                Diagram</button>

            <button type="button" title="Save" class="btn btn-primary mr-3"
                (click)="cancelMainDiagram()">Cancel</button>
        </div>
    </div>

    <div class="properties" *ngIf="isPropertyBlock">
        <div class="blockheader">
            <span class="title">Set Intent</span>
            <span class="float-right">
                <a (click)="closePropertyBlock()">
                    <img src="../../../assets/BotCloseBlack.png" class="crossImg" alt="crossImg">
                </a>
            </span>
        </div>
        <div class="blockContainer addFaqWrapper singleWordListWrapper">

            <div class="col-md-12">
                <div class="label">Section Name <span class="asterisk">*</span></div>
                <!-- <p-autoComplete class="customAutocomplete" [(ngModel)]="_NodeObj.SectionName" [minLength]="1"
                    [maxlength]="50" [suggestions]="_filteredSectionNameArr" (onSelect)="SelectSecionName($event)"
                    (completeMethod)="filterSectionNameAE($event)" field="name" [dropdown]="true" (onKeyUp)="onKeypressEvent($event)">
                </p-autoComplete>-->

                <p-dropdown [options]="sectionNameListArr" [(ngModel)]="_NodeObj.SectionName" optionLabel="name"
                    [filter]="true" filterBy="name" placeholder="Select a Section name"
                    (onChange)="SelectSecionName($event)" class="w-100">
                </p-dropdown>
                <label *ngIf="_isSectionNameErrorFlag" class="errorFlag">Please select section name from
                    dropdownlist.</label>
            </div>

            <div class="col-md-12">
                <div class="label">Intent Name <span class="asterisk">*</span></div>
                <!-- <p-autoComplete class="customAutocomplete" [(ngModel)]="_NodeObj.IntentName" [minLength]="1"
                    [maxlength]="50" [suggestions]="_filteredIntentNameArr" (onSelect)="SelectIntentName($event)"
                    (completeMethod)="filterIntentNameAE($event)" field="name" [dropdown]="true">
                </p-autoComplete> -->
                <p-dropdown [options]="intentNameListArr" [(ngModel)]="_NodeObj.IntentName" optionLabel="name"
                    [filter]="true" filterBy="name" placeholder="Select a Intent name"
                    (onChange)="SelectIntentName($event)">
                </p-dropdown>
                <label *ngIf="_isIntentNameErrorFlag" class="errorFlag">Please select intent name from
                    dropdownlist.</label>
            </div>

            <div class="col-md-12">
                <div class="label">Question <span class="asterisk">*</span></div>
                <textarea pInputText type="text" [(ngModel)]="_NodeObj.Question" maxlength="50"
                    class="intentInputField form-control" style="border-radius: 15px;height: auto;width: 100%;"
                    readonly></textarea>
            </div>

            <div class="col-md-12">
                <div class="label">Status <span class="asterisk">*</span></div>
                <div class="statusInputBox">
                    <input pInputText type="text" [(ngModel)]="_NodeObj.Status" maxlength="50"
                        class="intentInputField form-control w-100" readonly>
                </div>
                <div class="filter-status-color"
                    [ngClass]="{'status-bg-live': _NodeObj.Status === 'Live', 'status-bg-live-update':_NodeObj.Status === 'Live but content need updates', 
                'status-bg-content-approved': _NodeObj.Status === 'New and content is approved', 'status-bg-not-live': _NodeObj.Status === 'Not live'}">
                </div>
            </div>


            <div class="col-md-12">
                <div class="label">Related Intent </div>
                <input pInputText type="text" [(ngModel)]="_NodeObj.RelatedIntent" maxlength="50"
                    class="intentInputField form-control w-100" readonly>
            </div>

            <div class="col-md-12">
                <div class="label">Response Type </div>
                <input pInputText type="text" [(ngModel)]="_NodeObj.ResponceType" maxlength="50"
                    class="intentInputField form-control w-100" readonly>
            </div>

            <div class="col-md-12">
                <div class="label">List Options </div>
                <input pInputText type="text" [(ngModel)]="_NodeObj.SampleResponses" maxlength="50"
                    class="intentInputField form-control w-100" readonly>
            </div>

            <div class="row errorFlag" *ngIf="_NodeObj.SampleResponses">
                <div class="col-md-12">
                    <label>This FAQ already has list options. If this FAQ is added in clickable flow then list options
                        will not be displayed on chatbot. Instead children of the node in clickable flow will be
                        displayed as options</label>
                </div>
            </div>

            <div class="col-md-12 mt-3">
                <button class="btn btn-primary" (click)="setIntentName()">Set Intent</button>
            </div>
        </div>
    </div>
</div>

<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>

<div class="animatedLoader" *ngIf="_isSaveDiagramLoader" style="left: 0;">
    <img src="../../../assets/images/animation_loader.gif" class="loaderImg">
</div>