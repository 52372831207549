import { Component, Input, OnInit } from '@angular/core';
import { param } from 'jquery';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {

  @Input() confirmModalInputVariable: any;
  @Input() public callbackFunction: (vEntities: any) => void;

  @Input() public callbackCancelFunction: () => void;

  data: any
  constructor() {
  }

  ngOnInit(): void {
    this.data = this.confirmModalInputVariable
  }
  onEvent() {
    this.callbackFunction(param);
  }
}
