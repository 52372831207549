import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';

import {HttpCommonReq} from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})
export class AMLService {

  configUrls:any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {
      getAMLList: 'api/AMLCheckList/GetAllData',
      exportAMLList: 'api/AMLCheckList/ExportCSVFile',
      updteAMLStatus: 'api/AMLCheckList/UpdateAMLData'
    }
  }

  getAllAMLData(){
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
    return httpClientCommonObj.get(this.configUrls.getAMLList)
  }

  ExportAMLData(data: any){
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
    return httpClientCommonObj.post(this.configUrls.exportAMLList, data)
  }
  
  UpdateAMLData(data: any){
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
    return httpClientCommonObj.post(this.configUrls.updteAMLStatus, data)
  }
}
