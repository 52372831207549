import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    template: ''
})
export class UsageDashBoardEntities implements OnInit {

    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }

    BotDashboardRequest = {
        "Duration":"",
        "FromDate":"",
        "ToDate":"",
        "Type":""
    }

}