import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http'

import { HttpCommonReq } from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})

export class FailedFAQsService {

  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {

    this.configUrls = {
      getFailedFAQList: 'api/ConversationLog/GetConversationLogData',
      exportFailedFAQList: 'api/ConversationLog/ExportCSVFile',
      loadConversationHistory: 'api/ConversationHistory/LoadBotUsage',
      getFilteredConversationHistory: 'api/ConversationLog/GetFilteredConversations'
    }
  }

  getFailedFAQData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.getFailedFAQList, data)
  }

  exportFailedFAQData(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.exportFailedFAQList, data)
  }

  getConversationHistory() {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.loadConversationHistory, null)
  }

  getFilteredConversations(data: any) {
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);
    return httpClientCommonObj.post(this.configUrls.getFilteredConversationHistory, data)
  }
}
