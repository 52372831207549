
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TenantSelectionService } from '../../services/TenantSelection/tenant-selection.service'
import { TenantEntity } from './tenantentity';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-tenantselection',
  templateUrl: './tenantselection.component.html',
  styleUrls: ['./tenantselection.component.css']
})

export class TenantselectionComponent implements OnInit {
  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: Table;
  
  closeModal: any
  selectedTenant: any
  _tenantList: any = []

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string

  _isUdateTenant: boolean = false

  constructor(private modalService: NgbModal, private router: Router, public tenantSelectionService: TenantSelectionService) { }

  ngOnInit(): void {
    this.fetchTenantList();
  }

  fetchTenantList() {
    var tenantEntity = new TenantEntity();

    this.tenantSelectionService.getTenants().subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        this._tenantList = res.Data;
        if (res.Data.length > 0) {
          
          var tenantId = localStorage.getItem("TenantId");

          var d = this._tenantList.find((x: { TenantID: string; }) => x.TenantID == tenantId)
          this.selectedTenant = d
        }
      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  selectTenant() {
    
    var data = {
      TenantID: this.selectedTenant.TenantID
    }

    this.tenantSelectionService.UpdateSelectedTenant(data).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        
        window.localStorage.setItem("TenantId", this.selectedTenant.TenantID);
        sessionStorage.setItem("TenantId", this.selectedTenant.TenantID);

        window.localStorage.setItem("AccessToken", res.Data);
        sessionStorage.setItem("AccessToken", res.Data);
        
        this.popupImg = "../../../assets/images/Update.png"
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)

        setTimeout(() => {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl('portal/AccountAnalytics');
          });

          this._isUdateTenant = false
        }, 1000);

      } else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  Cancel() {
    this.router.navigateByUrl('portal/AccountAnalytics');
  }

  triggerModal(content: any) {
    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
