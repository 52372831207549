import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/internal/operators';
import { environment } from '../../environments/environment';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

export class HttpCommonReq {

  _configUrls: any = [];
  _baseURL = ""
  _Error: any;

  constructor(private httpClient: HttpClient, private router: Router) {
    this._baseURL = environment._baseURL

    const AccessToken = window.localStorage.getItem("AccessToken");

    this._configUrls = {
      Token: AccessToken
    }
  }

  postForLogin(url: any, data: any) {
    return this.httpClient.post(this._baseURL + url, data);
  }

  get(url: any) {
    const header = new HttpHeaders().set("Authorization", "bearer " + this._configUrls.Token);
    return this.httpClient.get(this._baseURL + url, { headers: header }).pipe(
      tap(), catchError(this.handleError()));
  }

  post(url: any, data: any) {
    const header = new HttpHeaders().set("Authorization", "bearer " + this._configUrls.Token).set("Content-Type", "application/json");
    return this.httpClient.post(this._baseURL + url, data, { headers: header }).pipe(
      tap(), catchError(this.handleError()));
  }

  postFile(url: any, data: any) {

    const header = new HttpHeaders().set("Authorization", "bearer " + this._configUrls.Token);
    return this.httpClient.post(this._baseURL + url, data, { headers: header }).pipe(
      tap(), catchError(this.handleError()));
  }

  private handleError<T>() {
    return (error: any): Observable<T> => {

      if (error.error instanceof ErrorEvent) {

        this._Error = {
          "Data": "",
          "Status": "Failed",
          "Error": "Failed",
          "Message": "Something bad happened at client side; please try again later."
        }
      }

      else if (error.status === 400) {

        this._Error = {
          "Data": "",
          "Status": "Failed",
          "Error": error.error.Error,
          "Message": error.error.Error
        }
      }

      else if (error.status === 401) {

        this.router.navigate(['/unauthorized']);

        this._Error = {
          "Data": "",
          "Status": "Failed",
          "Error": "",
          "Message": "Unauthorized access"
        }

        // Get a reference to the last interval + 1
        const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);

        // Clear any timeout/interval up to that id
        for (let i = 1; i < interval_id; i++) {
          window.clearInterval(i);
        }
       
      }

      else if (error.status === 403) {
        window.localStorage.clear();

        this.router.navigate(['/accessdenied']);

        this._Error = {
          "Data": "",
          "Status": "Failed",
          "Error": "",
          "Message": "Access denied"
        }
      }

      else {
        this._Error = {
          "Data": "",
          "Status": "Failed",
          "Error": "Something bad happened; please try again later.",
          "Message": "Something bad happened; please try again later."
        }
      }

      return of(this._Error);
    };
  }

 
}