import { Component, EventEmitter, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from "@angular/router";
import { Input } from '@angular/core';
import { Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { UserDetailsService } from '../../services/UserDetails/user-details.service';
import { Entities } from '../../test-bot/Entities'

@Component({
  selector: 'app-adduserdetails',
  templateUrl: './adduserdetails.component.html',
  styleUrls: ['./adduserdetails.component.css']
})

export class AdduserdetailsComponent implements OnInit {

  _RoleName = window.localStorage.getItem("RoleName");

  _isChecked: boolean = false
  _checkvalue: any

  _OtherData: any
  dataTodelete: any
  mainData: any

  selectedSectionName: any = [];
  filteredSectionName: any = [];
  sectionName: any = [];

  gender: any = [];
  Role: any = [];

  hoverFilterArr: any = [];
  selectedFilterOption: any;

  responceType: any = [];
  selectedResponceType: any = [];

  text: string;

  isHide: boolean = true
  chageFilterInput: boolean = false;
  selectedFieldOutline: boolean = false;
  greyFieldOutline: boolean = false;
  filterArr: any = [];
  toggle: boolean = false;
  selectedValues: any = [];

  count: any = 0;

  parameters = new Entities();
  sendData = new Entities();
  addUtterance = new Entities();

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string
  closeModal: any

  generatedDataOfMU: string
  vMainData: any;
  vStrMainData: string;

  TempVarMulitpleUttr: string = ""

  _PageName: any
  _EditRowID: any
  singleDataArr: any = [];
  _createDataArr: any = [];

  _multipleUtterenceArr: any = [];

  isLoader: boolean = true;

  _createData: boolean = true;
  first: number = 0;
  rows = 10;
  islastPageFlag: boolean;

  newrow: boolean = false;
  editrow: boolean = false
  _lemmaName: string;
  fetchDispID: any;
  _IntentMessageID: any;

  _SingleWordFlag: boolean = true;

  spinnerloader: boolean = true;

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;
  @ViewChild("modalDataDelete", { static: false }) public modalDataDelete: NgbModal;
  @ViewChild("modalCreateData", { static: false }) public modalCreateData: NgbModal;
  @ViewChild("ConfirmationModal", { static: false }) public confirmationModal: NgbModal;
  @ViewChild("ConfirmationModalCampaign", { static: false }) public confirmationModalCampaign: NgbModal;
  @Output() inputdata: EventEmitter<any> = new EventEmitter<any>();

  @Input() inputVariable: any;
  _inputData: any
  _userListArr: any = []

  _IsClient: any
  _IsContent: any

  _isMultipleUtterence: boolean = false

  _CurrentRowData: any

  _IsDisbale: boolean;

  _singleword: any = [];
  _singlewordlist: any = []

  createData: boolean = false;
  createDataArr: any = [];

  _usermessage: string;
  _displaymessage: string;
  _intentName: string;

  _isSWDuplicate: boolean = true;

  _filteredRelatedIntentAE: any = []
  _selectedRelatedIntentAE: any = []

  constructor(public userconfigservice: UserDetailsService, private modalService: NgbModal, private router: Router,
    private route: ActivatedRoute, private sanitized: DomSanitizer, private datePipe: DatePipe) { }

  selectedCities: string[] = [];

  ngOnInit(): void {

    this._RoleName = window.localStorage.getItem("RoleName");

    this._inputData = this.inputVariable

    this.fetchGender();
    this.fetchRole();

    this._PageName = this._inputData.PageName
    this._EditRowID = this._inputData.EditRowID
    this._userListArr = this._inputData.faqListArrData
    this._CurrentRowData = this._inputData.currentRowData

    if (this._PageName.toUpperCase() == "EDITUSER") {
      this.fetchSingleRecord(this._EditRowID)
    }

    if (this._RoleName?.toUpperCase() == 'CLIENT') {
      this._IsClient = true
    }
    if (this._RoleName?.toUpperCase() == 'CONTENT') {
      this._IsContent = true
    }

    this.selectedSectionNameAE = ""
    this.selectedValuesRI = []

    this._provirewAnswer = ""
    this._provirewQuestion = ""

  }

  _isCheckedRI: boolean = false

  selectedSectionNameAE: any = [];
  filteredSectionNameAE: any = [];

  _selectedRole: any = [];
  _selectedGender: any = [];

  chageFilterInputRI: boolean = false;
  selectedFieldOutlineRI: boolean = false;
  greyFieldOutlineRI: boolean = false;
  filterArrRI: any = [];
  toggleRI: boolean = false;
  selectedValuesRI: any = [];

  AEparameters = new Entities();

  _LinkArray: any = []
  _LTI: boolean = true;
  _SampleTextData: any = []

  _provirewQuestion: any = ""
  _provirewAnswer: any = ""

  formats: string[] = ['bold', 'italic', 'underline', 'link', 'list'];

  fetchSingleRecord(vEditRowID: any) {
    

    this.fetchDispID = vEditRowID

    this.AEparameters.UserDetails.EmailId = vEditRowID
    this.AEparameters.UserDetails.IsSelectedCB = true

    this.userconfigservice.getUserDetailsData(this.AEparameters.UserDetails).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
   

        this.spinnerloader = false

        this.singleDataArr = res.Data

        this.AEparameters = new Entities();

        this.AEparameters.UserDetails.EmailId = this.singleDataArr[0].EmailId
        this.AEparameters.UserDetails.FirstName = this.singleDataArr[0].FirstName

        this.AEparameters.UserDetails.LastName = this.singleDataArr[0].LastName
        this.AEparameters.UserDetails.Address = this.singleDataArr[0].Address
        this.AEparameters.UserDetails.ContactNumber = this.singleDataArr[0].ContactNumber

        this.AEparameters.UserDetails.RoleName = this.singleDataArr[0].RoleName

        if (this.singleDataArr[0].Gender.toUpperCase() == "F" || this.singleDataArr[0].Gender.toUpperCase() == "FEMALE") {
          this.AEparameters.UserDetails.Gender = "Female";

        }
        else if (this.singleDataArr[0].Gender.toUpperCase() == "M" || this.singleDataArr[0].Gender.toUpperCase() == "MALE") {
          this.AEparameters.UserDetails.Gender = "Male";
        }

        this._selectedRole = {
          "code": this.singleDataArr[0].RoleName.toLowerCase(),
          "name": this.singleDataArr[0].RoleName
        }

        this._selectedGender = {
          "code": this.singleDataArr[0].Gender.toLowerCase(),
          "name": this.singleDataArr[0].Gender
        }

      }
      else if (res.Error) {

        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  _disableMainUpdateButton: boolean = true
  URL_REGEXP = /^(\s+\S+\s*)*(?!\s).*$/;


  onchangeControlles(event: any) {

    var html = this.AEparameters.UserDetails.LastName
    var WOIHAnswer = html.replace(/<[^>]*>/g, "");

    if (!this.URL_REGEXP.test(this.selectedSectionNameAE)
      || !this.URL_REGEXP.test(this.AEparameters.UserDetails.EmailId)
      || !this.URL_REGEXP.test(this.AEparameters.UserDetails.FirstName)
      || (!this.URL_REGEXP.test(WOIHAnswer))) {
      this._disableMainUpdateButton = true
    }
    else {
      var k = event.keyCode;

      if (this._disableMainUpdateButton == false) {

      }
      else {
        if (k >= 0 && k <= 31) {
          this._disableMainUpdateButton = true

        } else {
          this._disableMainUpdateButton = false
        }
      }

    }
  }

  AddUpdateUserData(vUserDetailsObj: any, vIsCompare: any) {
    this.sendData = new Entities();

    this.sendData.UserDetails.BondUsersId = vUserDetailsObj.BondUsersId
    this.sendData.UserDetails.EmailId = vUserDetailsObj.EmailId
    this.sendData.UserDetails.FirstName = vUserDetailsObj.FirstName
    this.sendData.UserDetails.LastName = vUserDetailsObj.LastName

    if (vUserDetailsObj.Gender == "") {
      vUserDetailsObj.Gender = 'Male';
    }
    else {
      vUserDetailsObj.Gender = this._selectedGender.name
    }

    if (vUserDetailsObj.RoleName == "") {
      vUserDetailsObj.RoleName = 'Admin';
    }
    else {
      vUserDetailsObj.RoleName = this._selectedRole.name;
    }

    this.sendData.UserDetails.Address = vUserDetailsObj.Address
    this.sendData.UserDetails.DOB = vUserDetailsObj.DOB
    this.sendData.UserDetails.Gender = vUserDetailsObj.Gender


    this.sendData.UserDetails.ContactNumber = vUserDetailsObj.ContactNumber
    this.sendData.UserDetails.RoleName = vUserDetailsObj.RoleName

    if (this._PageName.toUpperCase() == "ADDUSER") {
      this.addUserRecord(this.sendData.UserDetails)
    }
    else {
      this.sendData.UserDetails.EmailId = vUserDetailsObj.EmailId
      this.updateUserRecord(this.sendData.UserDetails)
    }

  }

  addUserRecord(vUserDetailsObj: any) {
   
    this.userconfigservice.InsertUserDetailsData(vUserDetailsObj).subscribe((res: any) => {
    
      if (res.Status.toUpperCase() == "SUCCESS") {
        this.showPopup = true
        if (this._PageName != 'EditUser') {
          this.popupImg = "../../../assets/images/file-plus.png"
        } else {
          this.popupImg = "../../../assets/images/Update.png"
        }
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)

        this.inputdata.emit(res.Status);

      } else if (res.Error) {

        this.showPopup = true
        this.popupImg = "../../../assets/images/file-plus-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;
      }
    })
  }

  succUpdate: any
  updateUserRecord(vUserDetailsObj: any) {
    this.userconfigservice.UpdateUserDetailsData(vUserDetailsObj).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        this.popupImg = "../../../assets/images/Update.png"
        this.popupContent = "User Details updated Successfully";
        this.popupContentError = "";
        this.triggerModal(this.modalWarningAndInfo)

        this.inputdata.emit(res.Status);
      }
      else if (res.Error) {

        this.popupImg = "../../../assets/images/update_error.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  CreateStringMU(vMUArra: any) {
    var singleAppendUtter = ""

    vMUArra.forEach((value: any, id: any) => {


      if (singleAppendUtter == '') {
        singleAppendUtter = "- " + value.Utterance.trim()
      }
      else {
        singleAppendUtter = singleAppendUtter + "\n - " + value.Utterance.trim()
      }
    });


  }

  MainMUOpretaeArr: any = []
  _DisabledUtterBts: boolean = true

  fetchGender() {
    this.gender = [
      { name: 'Male', code: 'Male' },
      { name: 'Female', code: 'Female' }
    ]
  }

  fetchRole() {
    this.Role = [
      { name: 'Admin', code: 'Admin' },
      { name: 'Client', code: 'Client' },
      { name: 'Content', code: 'Content' }
    ]
  }

  triggerModal(content: any) {

    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  cancelbuttonOnAddEdit() {
    this.inputdata.emit("");
  }

  sortcolumn(ColumnName: any) { }

  _hideNewAddedEmptyUtter: boolean = false
  addNewRow() {
    this.newrow = true;

    this._lemmaName = ""
    this.editrow = !this.newrow

    var data = {
      'DispID': '',
      'Utterance': '',
      'BondModifiedOn': ''
    }

    if (this._multipleUtterenceArr.length == 0) {
      this._multipleUtterenceArr.push(data)
      this._hideNewAddedEmptyUtter = true
      this._isMultipleUtterence = false
    }
  }

}

