import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import * as signalR from '@microsoft/signalr';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { TestBotService } from '../services/TestBot/test-bot.service'

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Entities } from '../test-bot/Entities'
import { Url } from 'node:url';
import { Router } from '@angular/router';

import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-test-bot',
  templateUrl: './test-bot.component.html',
  styleUrls: ['./test-bot.component.css']
})
export class TestBotComponent implements OnInit {

  EnableUserRating: boolean = false;
  ShowRating: boolean = false;
  HideRating: boolean = false;
  ShowChat: boolean;

  _BotGradStartColor: any
  _BotGradEndColor: any
  _BotsiteUrl: any;
  _BotBackgroundWebSite: any

  _startColor: any;
  _endColor: any;

  _iconColor: any

  popupImg: string;
  popupContent: string;
  popupContentError: string;

  _isFlipIcon: boolean = true;

  _RoleName = window.localStorage.getItem("RoleName");
  _pageName = 'TestBot'

  _Pagename: any
  _currentURL: any

  _isthemeSettingBlock: boolean = false

  _isWhiteSpace: boolean = false
  _ClientTenantId = '7B599D13-A945-4F47-9964-5880C6E6AB87'

  _disableMainUpdateButton: boolean = true
  _onPageLodeCnt: number = 0

  @Output() fetchPageUrl: EventEmitter<any> = new EventEmitter<any>();
  @Output() recivechatValue: EventEmitter<any> = new EventEmitter<boolean>(true)

  title = environment.pageTitle + ' - Test Bot';

  constructor(public testBotService: TestBotService, public sanitizer: DomSanitizer, private router: Router, private titleService: Title) { }

  ngOnInit(): void {

    this.titleService.setTitle(this.title);

    this.GetBotGradientColor();
    this.fetchCurrentUrl()


  }

  fetchCurrentUrl() {

    this.fetchPageUrl.emit(this._pageName)

  }

  fetchShowChatValue(showChatVal: any) {
    this.ShowChat = showChatVal.defaultPrevented
  }

  ChatModels() {
    this.ShowChat = true;
    this._isthemeSettingBlock = false
  }

  eventHandler(event: any) {

  }

  GetBotGradientColor() {

    this.testBotService.GetBotGradientColor().subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {

        var urlSafe: any
        urlSafe = res.Data.BotBackgroundWebSite;
        this._BotBackgroundWebSite = this.sanitizer.bypassSecurityTrustResourceUrl(urlSafe);

        this._BotGradStartColor = res.Data.BotGradStartColor;
        this._BotGradEndColor = res.Data.BotGradEndColor;
        this._iconColor = res.Data.BotGradEndColor;
        this._BotsiteUrl = res.Data.BotBackgroundWebSite;
      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

      }
    })
  }

  UpdateBotGradientColor(vIsClick: any) {

    var data = {
      BotBackgroundWebSite: this._BotsiteUrl,
      BotGradStartColor: this._BotGradStartColor,
      BotGradEndColor: this._BotGradEndColor,
      IsClick: vIsClick
    }

    this.testBotService.UpdateBotGradientColor(data).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {

        window.location.reload();

      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;
      }
    })
  }

  checkWhiteSpace(inputVal: any) {
    var res = inputVal.trim()
    var reslength = res.length
    if (reslength === 0) {
      this._isWhiteSpace = true
    } else {
      this._isWhiteSpace = false
    }
  }

  openThemeSettingBlock() {
    this._isthemeSettingBlock = true
  }
  closeChat() {
    this._isthemeSettingBlock = false
  }

  ifFieldIsUpdated(event: any) {
    var k = event.keyCode;
    if (k >= 0 && k <= 31) {
      if (this._onPageLodeCnt == 0) {
        if (k == 8 || k == 46) {
          this._onPageLodeCnt++;
          this._disableMainUpdateButton = false
        }
        else {
          this._disableMainUpdateButton = true
        }
      }
      else {
        this._disableMainUpdateButton = true
      }
    } else {

      this._disableMainUpdateButton = false
    }
  }

}
