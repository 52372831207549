import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Output, ViewChild, ElementRef, HostListener, EventEmitter } from '@angular/core';
import { LKPListService } from '../services/LKPList/lkplist.service';


import { Entities } from '../test-bot/Entities'

import { ChangeDetectorRef } from '@angular/core';

import { Table } from 'primeng/table';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lkp-list',
  templateUrl: './lkp-list.component.html',
  styleUrls: ['./lkp-list.component.css'],
  providers: [DatePipe]
})

export class LKPListComponent implements OnInit {
  @ViewChild('dt') dt: ElementRef
  @ViewChild("dt", { static: false }) public ptable: Table;
  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: Table;
  @ViewChild("modalDataDelete", { static: false }) public modalDataDelete: Table;

  LkpListArr: any = [];
  lkpHeadersArr: any = [];

  _RoleName = window.localStorage.getItem("RoleName");


  singleDataArr: any = [];
  dataId: number;

  searchLKP: string = "";
  DataForExport: any;

  selectedOptArr: any = [];
  selectedSearchOpt: any[];

  IsSelectedCB: boolean = false;

  filterAllFields: any
  filterArr: any = [];
  isHide: boolean = true

  newFilterDataArr: any = [];
  selectedValues: any = [];
  searchOptionArr: any = [];
  toggle: boolean = false;
  chageFilterInput: boolean = false;
  totalSelectedValues: any;
  selectedFieldOutline: boolean = false;
  greyFieldOutline: boolean = false;

  hoverFilterArr: any = []
  selectedFilterOption: string

  fetchDispID: any
  editrow: boolean = false

  isLoader: boolean = true;

  sortColumnName: string;
  sortorder: boolean;
  descOrder: Boolean;
  AfterUpdateColumnName: string;

  selectedTable: string = "";
  selectedValue: string = "";
  selectedUt: string = "";
  searchFAQ: string = "";

  first: number = 0;
  rows = 10;
  islastPageFlag: boolean;

  parameters = new Entities()
  myDate: any

  totalRows: string;
  filteredSectionName: any = [];
  selectedsectionname: string;
  newSrNo: string;
  newrow: boolean = false

  addRecord = new Entities()
  updateRecord = new Entities()

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string

  showDeletePopup: boolean = false
  _DeleteReasonsLst: any = []
  _selectedDeleteReasons: any
  _isDisplayOtherSec: boolean = false

  closeModal: any
  _OtherData: any
  dataTodelete: any
  selectedCities: string[] = [];

  _ViewRoleAccess: boolean = false;
  _ViewClientRoleAccess: boolean = false;

  _ifNoRecords: boolean = false

  title = environment.pageTitle + ' - LKP List';

  constructor(private modalService: NgbModal, private _eref: ElementRef, public lKPListService: LKPListService, private _http: HttpClient, private cdref: ChangeDetectorRef, private datePipe: DatePipe, private titleService: Title, private router: Router) {
    this.myDate = new Date();
    this.sortorder = false;

  }

  ngOnInit(): void {
    ;

    this.titleService.setTitle(this.title);

    if (this._RoleName === 'ContentView') {
      this._ViewRoleAccess = true;
    }

    if (this._RoleName === 'ClientView' || this._RoleName === 'Client') {
      this._ViewClientRoleAccess = true;
    }

    this.fetLKPList();
    this.LookupListHeaders();
    this.filterDropdown();
    this.sortorder = false;

    this.fetchDeleteReasonListType()
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  filterDropdown() {
    this.filterArr = [
      {
        label: 'FAQ Status', value: 'faqstatus',
        items: [
          { name: 'Live', code: 'faqstatus', status: 'live', checked: false },
          { name: 'Live but content need updates', code: 'faqstatus', status: 'liveContentUpdate', checked: false },
          { name: 'New and content is approved', code: 'faqstatus', status: 'liveContentApproved', checked: false },
          { name: 'Not live', code: 'faqstatus', status: 'notLive', checked: false }
        ]
      }
    ];
  }

  fetchDeleteReasonListType() {
    this._DeleteReasonsLst = [
      { name: 'Testing', code: '1' },
      { name: 'Duplicate', code: '2' },
      { name: 'Merged with another current intent', code: '3' },
      { name: 'Removal requested by client', code: '4' },
      { name: 'Other', code: '5' }
    ]
  }

  OnSelectDeletedReason() {
    if (this._selectedDeleteReasons.name == 'Other') {
      this._isDisplayOtherSec = true
    }
    else
      this._isDisplayOtherSec = false
  }

  selectOption(text: any) {
    this.isLoader = true;

    this.filterAllFields = text;
  }

  onClickedOutside(e: any) {
    this.toggle = false;
  }

  clearInputField() {
    this.selectedValues = []
    this.filterAllFields = []
    this.isHide = true

    this.filterDropdown();
    this.chageFilterInput = false

    const event = { "keyCode": 13 }

    this.searchLKPRecord(event);
  }

  selectFilterOption(details: any) {
    this.isHide = false

    if (details.checked == true) {
      this.chageFilterInput = true
      this.selectedValues.push(details)
    }
    else {
      this.selectedValues.forEach((value: any, id: any) => {
        if (value.status == details.status)
          this.selectedValues.splice(id, 1)
        this.chageFilterInput = true
      });
    }

    this.totalSelectedValues = this.selectedValues.length
    if (this.totalSelectedValues === 0) {
      this.chageFilterInput = false
      this.isHide = true
    }

    this.filterAllFields = this.selectedValues;

    const event = { "keyCode": 13 }

    this.searchLKPRecord(event);

    var skinName = this.selectedValues.find((x: { name: string; }) => x.name == details);
  }

  dropSkill(index: any) {
    this.chageFilterInput = false
    this.selectedValues.forEach((value: any, id: any) => {
      if (value.status == index.status)
        this.selectedValues.splice(id, 1)
    });

    let indexMain = this.filterArr[0].items.indexOf(index);

    if (indexMain === -1) {
      indexMain = this.filterArr[1].items.indexOf(index);

      if (indexMain === -1) {
        indexMain = this.filterArr[2].items.indexOf(index);

        if (indexMain === -1) {
        }
        else {
          const dataMain = this.filterArr[2].items.filter(function (x: any) { return x.status === index.status })[0];

          dataMain.checked = false;

          this.filterArr[2].items[indexMain] = dataMain
        }
      }
      else {
        const dataMain = this.filterArr[1].items.filter(function (x: any) { return x.status === index.status })[0];

        dataMain.checked = false;

        this.filterArr[1].items[indexMain] = dataMain
      }
    }
    else {
      const dataMain = this.filterArr[0].items.filter(function (x: any) { return x.status === index.status })[0];

      dataMain.checked = false;

      this.filterArr[0].items[indexMain] = dataMain
    }

    this.filterAllFields = this.selectedValues;

    const event = { "keyCode": 13 }

    this.searchLKPRecord(event);
  }

  clickEvent() {
    this.selectedFieldOutline = !this.selectedFieldOutline
    this.toggle = !this.toggle
  }

  changeStyle() {
    this.greyFieldOutline = !this.greyFieldOutline
  }

  fetLKPList() {
    ;
    this.isLoader = true;
    var searchEntities = new Entities();
    this.lKPListService.getLookupData(searchEntities.Lookups).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {

        this._hideEmptyRow = false;

        this.LkpListArr = res.Data;
        this.isLoader = false;
        this.sortColumnName = '';
        this.first = 0;
        this.sortorder = false;

        this.ptable.reset();

      }
      else if (res.Error) {

        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  LookupListHeaders() {

    this.lkpHeadersArr = [
      { field: 'DispID', header: 'Sr No.', width: "6%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'LookupTableName', header: 'Lookup Table Name', width: "18%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'Section Name' },
      { field: 'LookupTableValue', header: 'Lookup Table Value', width: "18%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'FAQ' },
      { field: 'Language', header: 'Language', width: "8%", isEditable: "true", isSort: "true", textAlignment: "left", value: 'Answer' },
      { field: 'BondModifiedOn', header: 'Mod. Dt.', width: "10%", isEditable: "true", isSort: "true", textAlignment: "left", value: 'Answer' },
    ]

    this.searchOptionArr = [
      { name: 'Table Name', code: 'LookupTableName' },
      { name: 'Table Value', code: 'LookupTableValue' }
    ]
  }

  fetchSingleRecord(data: any, rowIndex: any) {
    this.fetchDispID = data.DispID
    this._disableSaveBtn = true

    this.fetchDispID = this.fetchDispID - this.fetchDispID + 1 * rowIndex
    this.editrow = true

    this.updateRecord.Lookups.LookupTableName = data.LookupTableName
    this.updateRecord.Lookups.LookupTableValue = data.LookupTableValue
    this.updateRecord.Lookups.Language = data.Language
    this.updateRecord.Lookups.DispID = data.DispID
    this.updateRecord.Lookups.LookupDataId = data.LookupDataId
  }

  getFilteredLKPData(searchEntities: any) {
    ;

    let EntitiesObj = new Entities();
    EntitiesObj.Lookups.Language = "EN";
    EntitiesObj.Lookups.LookupTableName = searchEntities.LookupTableName;
    EntitiesObj.Lookups.LookupTableValue = searchEntities.LookupTableValue;
    EntitiesObj.Lookups.IsSelectedCB = searchEntities.IsSelectedCB;

    this.isLoader = true;
    this.lKPListService.getLookupData(EntitiesObj.Lookups).subscribe((res: any) => {
      if (res.Status = 'SUCCESS') {
        ;
        this.LkpListArr = res.Data
        this.isLoader = false

        this.sortColumnName = '';
        this.sortorder = false;

        if (this.LkpListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        } else {
          this._ifNoRecords = false
        }

      }
      else if (res.Error) {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
      }
    })
  }

  updateLookupRecord() {
    this.isLoader = true;
    this.parameters = new Entities()
    this.searchLKP = "";

    this.parameters.Lookups.LookupDataId = this.updateRecord.Lookups.LookupDataId
    this.parameters.Lookups.LookupTableName = this.updateRecord.Lookups.LookupTableName
    this.parameters.Lookups.LookupTableValue = this.updateRecord.Lookups.LookupTableValue
    this.parameters.Lookups.Language = "EN"

    this.lKPListService.UpdateLookupData(this.parameters.Lookups).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        this.singleDataArr = res.Data
        this.isLoader = false;
        this.editrow = false;

        this.sortColumnName = '';
        this.first = 0;
        this.sortorder = false;

        this.popupImg = "../../../assets/images/Update.png"
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)

        this.fetLKPList();

      } else if (res.Error) {

        this.editrow = false;
        this.popupImg = "../../../assets/images/update_error.png"
        this.popupContent = "Oops!"
        this.popupContentError = res.Error;

        this.isLoader = false;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  _hideEmptyRow: boolean = false
  addNewRow() {
    this.newrow = true;

    this._ifNoRecords = false

    var data = {
      LookupTableName: "",
      LookupTableValue: "",
      Language: "EN"
    }

    if (this.LkpListArr.length == 0) {
      this.LkpListArr.push(data);
      this._hideEmptyRow = true;
    }

    this.addRecord.Lookups.LookupTableName = "";
    this.addRecord.Lookups.LookupTableValue = "";
    this.addRecord.Lookups.Language = "EN";
    this.first = 0;
  }

  ModifyRow() {
    this.newrow = true;

    this.addRecord.Lookups.LookupTableName = "";
    this.addRecord.Lookups.LookupTableValue = "";
    this.addRecord.Lookups.Language = "EN";

  }

  cancelNewRow() {
    ;
    this.newrow = false;
    this.editrow = false;

    this.addRecord.Lookups.LookupTableName = "";
    this.addRecord.Lookups.LookupTableValue = "";
    this.addRecord.Lookups.Language = "";

    if (this.LkpListArr.length == 1) {
      setTimeout(() => {
        this._ifNoRecords = true
      }, 1000);
    } else {
      this._ifNoRecords = false
    }
  }

  addLookupList(event: any) {
    this.isLoader = true;
    this.newrow = false;

    this.selectedValues = []
    this.filterAllFields = []
    this.isHide = true

    this.selectedOptArr = []
    this.selectedSearchOpt = []

    let EntitiesObj = new Entities();
    EntitiesObj.Lookups.Language = "EN";
    EntitiesObj.Lookups.LookupTableName = event.LookupTableName;
    EntitiesObj.Lookups.LookupTableValue = event.LookupTableValue;

    this.searchLKP = "";

    this.lKPListService.InsertLookupData(EntitiesObj.Lookups).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        this.isLoader = false;
        this.editrow = false;

        this.sortColumnName = '';
        this.first = 0;
        this.sortorder = false;
        this.editrow = false;

        this.showPopup = false;
        this.popupImg = "../../../assets/images/file-plus.png"
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)

        this.fetLKPList();

      }
      else if (res.Error) {
        this.isLoader = false;

        this.popupImg = "../../../assets/images/file-plus-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;
        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }


  deleteLKPRecord(vCheckBoxSelectedList: any, vOtherData: any) {
    this.showDeletePopup = false
    this.isLoader = true
    this.searchLKP = "" 

    this.lKPListService.DeleteLookupData(this.dataTodelete).subscribe((res: any) => {

      if (res.Status.toUpperCase() == "SUCCESS") {
        this.isLoader = true

        this.sortColumnName = '';
        this.sortorder = false;

        this.popupImg = "../../../assets/images/delete-popup.png";
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)

        this.fetLKPList();

      } else if (res.Error) {

        this.isLoader = true

        this.showPopup = true
        this.popupImg = "../../../assets/images/Delete-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  selectSearchOption(text: any) {
    ;

    this.selectedOptArr = text;

    if (this.searchLKP.length === 0)
      return;

    var searchEntities = this.setSearchEntities();
    this.getFilteredLKPData(searchEntities.Lookups);
  }

  AfterCLickExport() {
    var searchEntities = this.setSearchEntities();
    this.exportCSV(searchEntities.Lookups);
  }

  searchData(event: any) {
    if (event.keyCode === 13) {
      ;
      var searchEntities = this.setSearchEntities();
      this.searchLKPRecord(searchEntities.Lookups);
    }
  }

  setSearchEntities() {
    ;
    var searchEntities = new Entities();
    this.searchLKP = this.searchLKP.trim();
    if (this.selectedOptArr.length != 0) {
      for (let i = 0; i < this.selectedOptArr.length; i++) {
        if (this.selectedOptArr[i].code.toUpperCase() == "LOOKUPTABLENAME") {
          searchEntities.Lookups.LookupTableName = this.searchLKP
        } else if (this.selectedOptArr[i].code.toUpperCase() == "LOOKUPTABLEVALUE") {
          searchEntities.Lookups.LookupTableValue = this.searchLKP
        }
      }
      searchEntities.Lookups.IsSelectedCB = true;
    }
    else {
      searchEntities.Lookups.Language = "EN";
      searchEntities.Lookups.LookupTableName = this.searchLKP;
      searchEntities.Lookups.LookupTableValue = this.searchLKP;
      searchEntities.Lookups.IsSelectedCB = false;
    }

    return searchEntities;
  }

  searchLKPRecord(searchObj: any) {


    this.isLoader = true;

    this._hideEmptyRow = false;
    this.newrow = false;

    this.DataForExport = searchObj;

    this.lKPListService.getLookupData(searchObj).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {

        this.LkpListArr = res.Data;
        this.isLoader = false;

        this.sortColumnName = '';
        this.AfterUpdateColumnName = "";
        this.sortorder = false;

        if (this.LkpListArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        } else {
          this._ifNoRecords = false
        }

        this.ptable.reset();

      } else if (res.Error) {
        this.isLoader = false;

        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  exportCSV(searchObj: any) {
    if (this.DataForExport == undefined) {
      this.DataForExport = {
        SorColumnName: this.sortColumnName,
        Reverseorder: this.sortorder,
        AfterUpdateColumnName: this.AfterUpdateColumnName,
        Language: "EN"
      }
    }
    else {
      this.DataForExport.SorColumnName = this.sortColumnName
      this.DataForExport.Reverseorder = this.sortorder
      this.DataForExport.AfterUpdateColumnName = this.AfterUpdateColumnName
      this.DataForExport.Language = "EN";
    }

    if(!this.DataForExport.SorColumnName){
      this.DataForExport.SorColumnName = "BondModifiedOn"
      this.DataForExport.Reverseorder = false
    }


    this.lKPListService.ExportLookupData(this.DataForExport).subscribe((res: any) => {
      if (res.Status.toUpperCase() == "SUCCESS") {
        var status = res.status;
        const headers = res.headers;
        const data = res.Data;
        var contentType = 'application/CSV';
        var linkElement = document.createElement('a');
        var blob = new Blob([data], { type: contentType });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';

        this.myDate = new Date();

        var myDate1 = this.datePipe.transform(this.myDate, 'MMddyyyyHHmm');
        a.download = "LKPList_" + myDate1 + '.csv';

        document.body.appendChild(a);
        a.click();
        this.isLoader = false;

        this.popupImg = "../../../assets/images/download-popup.png";
        this.popupContent = res.Message;
        this.popupContentError = "";

        this.triggerModal(this.modalWarningAndInfo)

      } else if (res.Error) {
        this.popupImg = "../../../assets/images/download-popup-error.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;
        this.isLoader = false;

        this.triggerModal(this.modalWarningAndInfo)
      }
    })
  }

  sortcolumn(ColumnName: any) {
    let reverse = this.sortColumnName === ColumnName ? !this.descOrder : undefined;

    if (reverse === undefined)
      reverse = false;

    this.sortColumnName = ColumnName;
    this.descOrder = reverse;

    if (this.descOrder == true)
      this.sortorder = false;
    else
      this.sortorder = true;
  }

  GOToEnteredPageNo(pageNo: any): void {

    if (pageNo != "0" && pageNo != "") {

      var enteredPageNo: number = +pageNo;

      this.islastPageFlag = this.isLastPage(enteredPageNo);

      if (this.islastPageFlag === false) {
        this.first = enteredPageNo * 10 - 10;
      }
      else {
        const pageCount = Math.ceil(this.LkpListArr.length / this.rows);

        let page = pageCount - 1;
        page = page * this.rows

        this.first = page
      }
    }
    else {
      this.first = 0;
    }
  }

  isLastPage(pageNo: any): boolean {

    const pageCount = Math.ceil(this.LkpListArr.length / this.rows);

    const page = pageCount - 1;

    if (pageNo <= page) {
      return false;
    }
    else {
      return true;
    }
  }

  closePopup() {
    this.showPopup = false
    this.showDeletePopup = false
  }

  OpenDeletePopup(data: any) {
    this._OtherData = "";
    this.dataTodelete = data

    this.triggerModal(this.modalDataDelete)
  }

  triggerModal(content: any) {
    this.modalService.open(content, { centered: true, windowClass: 'customThemeModal' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onRowEditInit(book: any) {

  }

  _URL_REGEXP = /^(\s+\S+\s*)*(?!\s).*$/;

  _disableSaveBtn = true

  onchangeControlles(event: any) {

    if (!this._URL_REGEXP.test(this.updateRecord.Lookups.LookupTableValue) ||
      !this._URL_REGEXP.test(this.updateRecord.Lookups.LookupTableName) ||
      !this._URL_REGEXP.test(this.updateRecord.Lookups.Language) ||
      !this._URL_REGEXP.test(this.addRecord.Lookups.LookupTableName) ||
      !this._URL_REGEXP.test(this.addRecord.Lookups.LookupTableValue) ||
      !this._URL_REGEXP.test(this.addRecord.Lookups.Language)) {

      this._disableSaveBtn = true
    }
    else {
      this._disableSaveBtn = false
    }
  }
}
