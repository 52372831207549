import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { dateFormat } from 'highcharts';

@Component({
    template: ''
})

export class TenantEntity implements OnInit {

    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }

    Tenant = {
        "TenantId":"",
        "TenantName":""
    }
}