<div class="customThemeModal">
    <div class="" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="closePopup">
                    <a (click)="confirmModalInputVariable.dismissFunction()">
                        <img src="../../../assets/images/cross.png" class="cross-img">
                    </a>
                </div>
                <div class="popupImg" *ngIf="confirmModalInputVariable.image!=undefined">
                    <img src="{{confirmModalInputVariable.image}}" class="left-img">
                </div>
                <div class="popupTitle">{{confirmModalInputVariable.popupTitle}}</div>
                <div class="popupContent">{{confirmModalInputVariable.popupContent}}</div>

                <div class="popupFooter" *ngIf="confirmModalInputVariable.primaryBtn">
                    <button type="button" class="btn btn-primary mr-10px"
                        (click)="confirmModalInputVariable.primaryBtnFunction()">{{confirmModalInputVariable.primaryBtn}}</button>
                    <button title="Cancle" type="button" class="btn btn-secondary"
                        (click)="confirmModalInputVariable.dismissFunction();">Cancel</button>
                </div>

                <div class="popupFooter"
                    *ngIf="!confirmModalInputVariable.primaryBtn && !confirmModalInputVariable.IsCampaignFAQ && !confirmModalInputVariable.btnname">
                    <button title="Confirm" type="button" class="btn btn-primary mr-10px"
                        (click)="callbackFunction(confirmModalInputVariable.pendingstatus);confirmModalInputVariable.dismissFunction()">Confirm</button>
                    <button title="Cancle" type="button" class="btn btn-secondary"
                        (click)="confirmModalInputVariable.dismissFunction();">Cancel</button>
                </div>

                <div class="popupFooter" *ngIf="confirmModalInputVariable.IsCampaignFAQ">
                    <button title="Confirm" type="button" class="btn btn-primary mr-10px"
                        (click)="confirmModalInputVariable.dismissFunction()">Confirm</button>
                    <button title="Cancle" type="button" class="btn btn-secondary"
                        (click)="confirmModalInputVariable.dismissFunction(); callbackFunction(confirmModalInputVariable.boundCancle)">Cancel</button>
                </div>

                <div class="popupFooter" *ngIf="confirmModalInputVariable.iscancel">
                    <button title="Confirm" type="button" class="btn btn-primary mr-10px"
                        (click)="callbackFunction(confirmModalInputVariable.pendingstatus);confirmModalInputVariable.dismissFunction();">{{confirmModalInputVariable.btnname}}</button>
                    <button title="Cancle" type="button" class="btn btn-secondary"
                        (click)="confirmModalInputVariable.dismissFunction();callbackCancelFunction();">No</button>
                </div>

                <div class="popupFooter" *ngIf="confirmModalInputVariable.isDeleteDefaultIntent">
                    <button title="Confirm" type="button" class="btn btn-primary mr-10px"
                        (click)="callbackCancelFunction();confirmModalInputVariable.dismissFunction();">{{confirmModalInputVariable.btnname}}</button>
                    <button title="Cancle" type="button" class="btn btn-secondary"
                        (click)="confirmModalInputVariable.dismissFunction();">No</button>
                </div>
            </div>
        </div>
    </div>
</div>