<div class="container-fluid ClickableFlowWrapper">
    <div class="mb-3 text-center" *ngIf="this._inputData.PageName == 'Edit'">
        <h4><b>{{flowName}}</b></h4>
    </div>
    <div class="mb-3" *ngIf="this._inputData.PageName == 'Add'">
        <input pInputText type="text" [(ngModel)]="flowName" maxlength="50" placeholder="Enter flow name...."
            class="intentInputField form-control w-50" (keyup)="checkWhiteSpace(flowName)">
        <!-- <label *ngIf="_isFlowName" class="errorFlag mb-0">Please enter flowName.</label> -->
    </div>
    <!-- <div class="row flowchartContainer" [ngClass]="{'ContainerHeightAfterErrorMsg': _isFlowName}" -->
    <div class="row flowchartContainer" *ngIf="isCreateFlowchart">
        <div class="col-md-2">
            <div id="palette-space" class="sb-mobile-palette">
                <ejs-symbolpalette id="symbolpalette" [enableAnimation]='enableAnimation' [expandMode]='expandMode'
                    [palettes]='palettes' (created)='create($event)' width="100%" height="700px" [symbolHeight]=60
                    [symbolWidth]=60 [symbolMargin]='symbolMargin' [getSymbolInfo]='getSymbolInfo'>
                </ejs-symbolpalette>
            </div>
        </div>

        <div class="col-md-10">
            <ejs-diagram #diagram id="diagram" height="100%" [contextMenuSettings]="contextMenuSettings"
                (contextMenuClick)="contextMenuClick($event)" (contextMenuOpen)="contextMenuOpen($event)"
                [getNodeDefaults]="nodeDefaults" (created)='created($event)'>
            </ejs-diagram>
            <router-outlet></router-outlet>
        </div>
    </div>

    <div class="row flowchartContainer viewDiagramContainer" *ngIf="!isCreateFlowchart">
        <div class="col-md-2">
            <div id="palette-space" class="sb-mobile-palette">
                <ejs-symbolpalette id="symbolpalette" [enableAnimation]='enableAnimation' [expandMode]='expandMode'
                    [palettes]='palettes' (created)='create($event)' width="100%" height="700px" [symbolHeight]=60
                    [symbolWidth]=60 [symbolMargin]='symbolMargin' [getSymbolInfo]='getSymbolInfo'>
                </ejs-symbolpalette>
            </div>
        </div>

        <div class="col-md-10">
            <ejs-diagram #viewdiagram id="viewdiagram" height="100%" [nodes]="nodes" [connectors]="connectors"
                [layout]='layout' [contextMenuSettings]="contextMenuSettings"
                (contextMenuClick)="contextMenuClick($event)" (contextMenuOpen)="contextMenuOpen($event)"
                [getNodeDefaults]="nodeDefaults">
            </ejs-diagram>
            <router-outlet></router-outlet>
        </div>
    </div>

    <div class="row pageFooterBtn">
        <div class="col-md-12">
            <button type="button" title="Save" class="btn btn-primary mr-3" (click)="saveMainDiagram()">Save
                Diagram</button>

            <button type="button" title="Save" class="btn btn-primary mr-3" (click)="cancelMainDiagram()">Close</button>
        </div>
    </div>

    <div class="properties" *ngIf="isPropertyBlock">
        <div class="blockheader">
            <span class="title">Set Properties</span>
            <span class="float-right">
                <a (click)="closePropertyBlock()">
                    <img src="../../../assets/BotCloseBlack.png" class="crossImg" alt="crossImg">
                </a>
            </span>
        </div>
        <div class="blockContainer addFaqWrapper">

            <div class="col-md-12">
                <label class="formLabel">Slot Name <span class="asterisk">*</span></label>
                <input type="text" class="form-control textBoxSizes " id="SlotName" autocomplete="off"
                    [(ngModel)]="_NodeObj.SlotName" (keyup)="onKeypressEvent($event)"
                    (keypress)='CheckforWhiteSpace($event)'>
                <label *ngIf="_isSlotNameErrorFlag" class="errorFlag">Please enter slot name.</label>
            </div>

            <div class="col-md-12">
                <div class="label">Question <span class="asterisk">*</span></div>
                <textarea pInputText type="text" [(ngModel)]="_NodeObj.Question" maxlength="2000" class=" form-control"
                    style="height: auto;width: 100%;" (keyup)="onKeypressEvent($event)"></textarea>
                <label *ngIf="_isQuestionErrorFlag" class="errorFlag">Please enter question.</label>
            </div>

            <div class="col-md-12">
                <div class="label">Answer <span class="asterisk">*</span></div>
                <textarea pInputText type="text" [(ngModel)]="_NodeObj.Answer" maxlength="2000" class=" form-control"
                    rows="5" style="height: auto;width: 100%;" (keyup)="onKeypressEvent($event)"></textarea>
                <label *ngIf="_isAnswerErrorFlag" class="errorFlag">Please enter answer.</label>
            </div>

            <div class="col-md-12 mt-3">
                <button class="btn btn-primary" (click)="setProperties()">Save</button>
            </div>
        </div>
    </div>
</div>

<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>

<div class="animatedLoader" *ngIf="_isSaveDiagramLoader" style="left: 0;">
    <img src="../../../assets/images/animation_loader.gif" class="loaderImg">
</div>