<div class="container-fluid screen-container">
    <div class="row bg-white chartDetailsBlock">
        <div class="col-md-8 col-xl-9 pl-0 pr-0 pieChart">
            <div id="container" class="relative mainChart" (click)="DisplayPie()">
            </div>

            <div id="bubble2" class="chartbubbles bubbleTwo"></div>
            <div id="bubble1" class="chartbubbles bubbleOne"></div>
            <div id="bubble3" class="chartbubbles bubbleThree"></div>
        </div>

        <div class="col-md-4 col-xl-3 pl-0 pr-0">
            <div class="bankDashboardBlock">

                <h2 class="title">Persona Clusters</h2>
                <div class="subTitle">{{_bgColorName}} Zone </div>

                <div class="">
                    <span class="spanheadeclass">Behavior Index</span><br />
                    <span class="spanclass">{{_BehaviorIndex}}</span>
                </div>

                <div class="mt-15px xl-mt-45px">
                    <span class="spanheadeclass">Users</span><br />
                    <span class="spanclass">{{_Users}}</span>

                </div>

                <div class="mt-15px xl-mt-45px">
                    <span class="spanheadeclass">Conversion</span><br />
                    <span class="spanclass">{{_ConversionIndex}}%</span>
                </div>

                <div class="mt-15px xl-mt-45px">
                    <span class="spanheadeclass"> Selected</span><br />
                    <span class="spanclass">{{totalUser}}</span>
                </div>

                <div class="bankDashboardFilter" (clickOutside)="onClickedOutside($event)">

                    <div class="dropdown-field">
                        <div class="dropdown-input-field" (click)="clickEvent()">
                            <div class="static-img" *ngIf="isHide"><img src="../../../assets/images/Filter.png"></div>
                            <div class="static-img" *ngIf="chageFilterInput == true"><img
                                    src="../../../assets/images/filter_icon.png"></div>
                            <input type="text" class="form-control custom-input-field" style="width: 100% !important;"
                                [ngClass]="{'selectedfilterInput':chageFilterInput == true, 'selectedfilterInputOutline':selectedFieldOutline, 'greyInputOutline':greyFieldOutline == true}"
                                placeholder="Filter" disabled>
                            <div class="filterChips">
                                <span *ngIf="chageFilterInput == true">Filter/{{totalSelectedValues}}</span>
                            </div>
                            <a class="dropdownArrow" (mouseover)="changeStyle()" (mouseout)="changeStyle()"
                                (click)="clickEvent()">
                                <img *ngIf="isHide" (click)="clickEvent()" src="../../../assets/images/arrow-down.png">
                                <img *ngIf="chageFilterInput == true" (click)="clickEvent()"
                                    src="../../../assets/images/Icon ionic-ios-arrow-down.png">
                            </a>
                        </div>
                    </div>

                    <div [ngClass]="{'showFilterContainer': toggle, 'hideFilterContainer':toggle == false}">
                        <div class="filterArrContainer">
                            <div class="block w-100">
                                <div class="filterOptions" [ngClass]="{'selectedFilterOptions':data.checked == true}"
                                    *ngFor="let data of filterArr">
                                    <ul class="pl-0">
                                        <li>
                                            <label class="mb-0">
                                                <input type="checkbox" name="groupname" value="{{data.name}}"
                                                    [(ngModel)]="data.checked"
                                                    (ngModelChange)="selectFilterOption(data)" />
                                                {{data.name}}
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="customChipsContainer">
                    <div class="customChips" *ngFor="let item of selectedValues; let i = index">
                        <span class="chipsName">{{item.name}}</span>
                        <span><a class="crossbtn" (click)="dropSkill(item)"><img
                                    src="../../../assets/images/CrossBtn.png"></a></span>
                    </div>
                </div>

                <div class="row clusterExportBtn">
                    <div class="col-md-6 col-sm-12 campaignBtn">
                        <button type="button" class="btn btn-primary {{BgColor}}" title="Campaign">
                            <span>Campaign</span>
                        </button>
                    </div>

                    <div class="col-md-6 col-sm-12 ExportBtn">
                        <button [hidden]="_ViewClientRoleAccess" type="button" (click)="ExportBDData(cluster,category);"
                            class="btn btn-secondary exportBtn {{BgColorlable}}" title="Export">
                            <span>Export</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="exportBtnPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" title="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-center exportPopupBody">
                    <p>You have selected 12586
                        users from the Green Zone.</p>
                    <p>What do you want to next?</p>

                </div>
                <div style="text-align:center !important; padding-bottom:60px;">
                    <button title="Start CRM Campaign" class="exportPopupButton mr-2" data-dismiss="modal">Start CRM
                        Campaign</button>
                    <button title="Export As CSV" class="exportPopupButton" data-dismiss="modal">Export As CSV</button>
                </div>
            </div>
        </div>
    </div>

    <ng-template #modalWarningAndInfo let-modal>
        <div class="customThemeModal">
            <div class="" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="closePopup">
                            <a (click)="modal.dismiss('Cross click')">
                                <img src="../../../assets/images/cross.png" class="cross-img">
                            </a>
                        </div>
                        <div class="popupImg">
                            <img src="{{popupImg}}" class="left-img">
                        </div>
                        <div class="popupTitle">{{popupContent}}</div>
                        <div class="popupContent">{{popupContentError}}</div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>