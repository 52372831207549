<div class="intentListWrapper">
    <div class="modal-content">
        <div class="modal-body">
            <!-- <div class="closePopup">
                <a (click)="inputVariable.dismissFunction()">
                    <img src="../../../assets/images/cross.png" class="cross-img">
                </a>
            </div> -->

            <div class="container-fluid">

                <div class="header row mb-2">
                    <div class="col-md-12">
                        <div class="float-left">
                            <span class="page-title">FAQ List Options</span>
                        </div>

                        <div class="closePopup">
                            <a (click)="onCancelPopup()">
                                <img src="../../../assets/images/cross.png" class="cross-img">
                            </a>
                        </div>
                    </div>
                </div>

                <div class="header row">
                    <div class="col-md-12">
                        <div class="float-left intentListTitle">
                            <span class="page-subtitle">Parent Question: {{_selectedRowData.Questions}}</span>
                        </div>


                        <div class="float-right AddintentBtn">
                            <button title="Add list option" type="button" class="btn btn-primary"
                                (click)="addNewRow()"><img src="../../../assets/images/plus_icon.png"
                                    class="left-img"><span class="align-middle">Add list option</span></button>
                        </div>
                    </div>
                    <div class="col-md-12 float-left errorFlag" *ngIf="_selectedRowData.IsPresentInCliCkableFlow ==true ">
                        <label>* This FAQ is participating in clickable flow. Children of the node in clickable flow will
                            take priority over the list options.</label>
                    </div>

                    <div class="col-md-12 float-left errorFlag" *ngIf="ErrorMSG">
                        <label>* {{ErrorMSG}}</label>
                    </div>
                </div>

                <div class="row faqListTable">

                    <div class="tableContent" [hidden]="_ifNoRecords">

                        <p-table #dt [value]="intentListArr" styleClass="p-custom-datatable" [rows]="10" reflow="true"
                            [rowHover]="true" [scrollable]="true" scrollHeight="flex" [(first)]="first" editMode="row"
                            [editable]="true" [resizableColumns]="true">

                            <ng-template pTemplate="colgroup">
                                <colgroup>
                                    <col *ngFor="let col of intentListHeadersArr" [style.width]='col.width'>
                                </colgroup>
                            </ng-template>

                            <ng-template pTemplate="header">
                                <tr>
                                    <th pResizableColumn *ngFor="let col of intentListHeadersArr"
                                        [pSortableColumn]="col.field"
                                        [pSortableColumnDisabled]="col.field === 'BondModifiedOn'"
                                        style="text-align: left;" [ngClass]="col.field" data-toggle="tooltip"
                                        data-placement="top" title="{{col.header}}">
                                        <div class="tableHeader" *ngIf="col.isSort === 'true'"
                                            (click)="sortcolumn(col.field)"
                                            [ngClass]="{'border-right-dadadd': col.field != 'BondModifiedOn'}">
                                            <div class="d-inline-block"
                                                [ngClass]="{'text-overflow-header': col.width > '500%', 'pl-15':col.field === 'DispId', 'xl-pl-20px':col.field === 'DispId'}">
                                                {{col.header}}</div>
                                            <div class="tableSortIcon">
                                                <p-sortIcon [field]="col.field"></p-sortIcon>
                                            </div>
                                        </div>
                                        <div *ngIf="col.isSort === 'false'">
                                            {{col.header}}
                                        </div>
                                    </th>

                                </tr>
                                <tr *ngIf="isLoader">
                                    <td *ngFor="let col of intentListHeadersArr">
                                        <div class="item customSkeletonLoader">
                                            <ngx-skeleton-loader count="3" appearance=""></ngx-skeleton-loader>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-car let-ri="rowIndex">

                                <tr *ngIf="ri == 0 && newrow" class="newTableRow">
                                    <td *ngFor="let col of intentListHeadersArr" style="padding: 0!important">

                                        <div *ngIf="col.field == 'Questions'">
                                            <input pInputText type="text" class="newInputField form-control"
                                                [minLength]="1" maxlength="200" placeholder="Add your question here ..."
                                                (keyup)='onchangeControlles($event, "Add", this.addRecord.IntentMaintenance.Questions)'
                                                [(ngModel)]="this.addRecord.IntentMaintenance.Questions" required>
                                        </div>

                                        <div *ngIf="col.field == 'DisplayMessage'">
                                            <input pInputText type="text" class="newInputField form-control"
                                                [minLength]="1" maxlength="2000" placeholder="Add your answer here ..."
                                                (keyup)='onchangeControlles($event, "Add", this.addRecord.IntentMaintenance.DisplayMessage)'
                                                [(ngModel)]="this.addRecord.IntentMaintenance.DisplayMessage" required>
                                        </div>

                                        <div *ngIf="col.field === 'BondModifiedOn'">
                                            <button title="Add" type="button" class="btn btn-success"
                                                (click)="addIntentRecord(this.addRecord.IntentMaintenance)"
                                                [disabled]="!this.addRecord.IntentMaintenance.Questions || !this.addRecord.IntentMaintenance.DisplayMessage || _disableSaveBtn">Add</button>
                                            <button title="Cancel" class="cancelRow btn btn-secondary float-right"
                                                (click)="cancelNewRow()">Cancel</button>
                                        </div>
                                        <!-- <div *ngIf="col.field === 'status'">
                                            <button title="Cancel" class="cancelRow btn btn-secondary"
                                                (click)="cancelNewRow()">Cancel</button>
                                        </div> -->
                                    </td>
                                </tr>

                                <tr *ngIf="!_hideEmptyRow" [hidden]="_hideContentDuringLoading || car.IsDeleted == true">
                                    <td pEditableColumn *ngFor="let col of intentListHeadersArr"
                                        [ngStyle]="{'text-align': col.textAlignment}" [ngClass]="col.field">
                                        <div *ngIf="col.isEditable === 'true' && col.field == 'Questions'"
                                            [ngClass]="{'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId', 'text-overflow-field': col.field === 'Questions'}">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <textarea pInputText type="text" maxlength="200"
                                                        (keyup)='onchangeControlles($event , "Edit",car.Questions)'
                                                        [(ngModel)]="car.Questions"></textarea>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{car[col.field]}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </div>
                                        <div *ngIf="col.isEditable === 'true' && col.field == 'DisplayMessage'"
                                            [ngClass]="{'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId', 'text-overflow-field': col.field === 'DisplayMessage'}">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <textarea pInputText type="text" maxlength="2000"
                                                        (keyup)='onchangeControlles($event, "Edit",car.DisplayMessage)'
                                                        [(ngModel)]="car.DisplayMessage"></textarea>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{car[col.field]}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </div>
                                        <div *ngIf="col.field != 'BondModifiedOn' && col.field != 'Questions' && col.field != 'DisplayMessage'"
                                            [ngClass]="{'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId','pl-25':col.field === 'DispId', 'xl-pl-35px':col.field === 'DispId', 'text-overflow-field': col.field === 'DisplayMessage' || col.field === 'Questions'}">

                                            {{car[col.field]}}
                                        </div>

                                        <div class="actions w-100"
                                            [ngClass]="{'showactionBlock': col.field === 'BondModifiedOn', 'xl-pl-14px':col.field !== 'DispId', 'pl-10':col.field !== 'DispId'}">
                                            <span>

                                                <a class="mr-2" (click)="OpenDeletePopup(car)"
                                                    class="faqDeleteActionBtn" title="Delete">
                                                    <img class="block_img" src="../../../assets/images/delete.png">
                                                    <img class="hover_delete_img"
                                                        src="../../../assets/images/delete_on_hover.png">
                                                </a>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>

                    <div class="noRecordFound text-center border-top" *ngIf="_ifNoRecords">
                        <img src="../../assets/images/no_records.png">
                        <p class="title">No Records Found</p>
                    </div>

                </div>

                <div class="row text-right popupFooter mt-2" [hidden]="_ifNoRecords">
                    <div class="col-md-12">
                        <button class="btn btn-primary mr-3" [disabled]="_disableSaveBtn"
                            (click)="SaveIntent()">Save</button>
                        <button class="btn btn-secondary" (click)="onCancelPopup(); ">Cancel</button>
                    </div>
                </div>

            </div>
            <!-- <div class="PoupContent deletebtnsdiv">
                <button title="Delete" type="button" class="btn btn-primary mr-10px deletebtn"
                    (click)="deleteFaqRecord(selectedCities, _OtherData); modal.dismiss(); "
                    [disabled]='_disableDeleteBtn'>Delete</button>
                <button title="Cancel" type="button" class="btn btn-secondary deletebtn"
                    (click)="modal.dismiss('Cross click')">Cancel</button>
            </div> -->
        </div>
    </div>
</div>
<ng-template #modalWarningAndInfo let-modal="dismiss">
    <app-warning-and-info-modal
        [inputVariable]="{popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal}">
    </app-warning-and-info-modal>
</ng-template>
<ng-template #ConfirmationModal let-modal="dismiss">
    <app-confirmation-modal
        [confirmModalInputVariable]="{btnname: 'Yes' , popupTitle: popupContent, popupContent: popupContentError, image: popupImg, dismissFunction: modal, pendingstatus:  intentListArr, iscancel:true}"
        [callbackFunction]="boundedMainYesValue" [callbackCancelFunction]="boundedMainNoValue">
    </app-confirmation-modal>
</ng-template>