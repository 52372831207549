import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { dateFormat } from 'highcharts';

@Component({
    template: ''
})
export class faqEntities implements OnInit {

    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }

    IntentMaintenance = {
        "IntentId":"",
        "IntentName":"",
        "SectionName":"",
        "Questions":"",
        "UserMessage":"",
        "DisplayMessage":"",
        "Language":"EN",
        "IsCampaignFAQ":false,
        "ResponseType":"",
        "IsApproved": -1,
        "IsPublished":-1,
        "FilePath":"",
        "MultipleUtterance":"",
        "MultipleUtteranceU":"",
        "RelatedIntent":"",
        "CurrentStatus":"",
        "IsLiveRawIntent":false,
        "IsModifiedRawIntent":false,
        "IsNewRawIntent":false,
        "IsNewIntent":false,
        "IsContentApprovedRawIntent":false,
        "Miscellaneous":"",
        "SingleWords":"",
        "Id":"",
        "BambuId":"",
        "BondCreatedOn":"",
        "BondModifiedOn":"",
        "MainData":"",
        "Reverseorder": true,
        "SorColumnName":"",
        "DispId":"",
        "AfterUpdateColumnName":"",
        "TotalUtter":"",
        "StrTotalUtter" : "",
        "FaqStatus" : "", 
        "IsSelectedCB" : false,
        "DeleteReason": "",
        "ModelName": "",
        "FromDate":Date,
        "ToDate": Date,
        "SampleResponse": "",
        "DeleteReasonNo": 0,
        "IsEndOfConversation": false,
        
    }

    ClicakbleFlowMaster = {
        "ClickableFlowsMasterID":"",
        "Context":"",
        "FlowDiagramJson": "",
        "ClickableFlowsJson" : ""
    }

    IntentUtternace = {
        "IntentMessagesID":"",
        "IntentName":"",
        "CIntentName":"",
        "SectionName":"",
        "Utterance":"",
        "UserMessage":"",
        "DisplayMessage":"",
        "DeleteReason": "",
        "IsSelectedCB" : false,
        "Language":"EN"
    }
}