import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Connector, ConnectorModel, ContextMenuSettingsModel, DiagramBeforeMenuOpenEventArgs, DiagramComponent, GridlinesModel, MarginModel, NodeModel, PaletteModel, SnapSettingsModel, SymbolInfo } from '@syncfusion/ej2-angular-diagrams';
import { ClickEventArgs, ExpandMode, MenuEventArgs } from '@syncfusion/ej2-angular-navigations';

import { ConversationFlowService } from '../../services/ConversationFlow/conversation-flow.service'

import { TextBox } from '@syncfusion/ej2-inputs';
import { ConvFlowEntity, ngModelSlotmessage, Slot, Slotmessage, Returnparams, ReturnSlotBasedOnInput, ngModelReturnSlotBasedOnInput } from '../../faq/conv-design-flow/ConvFlowEntity'
// import { DialogComponent } from '@syncfusion/ej2-angular-popups';
// import { data } from 'jquery';

import { FaqService } from '../../services/faq/faq.service';

interface DataInfo {
  Description: string;
  Color: string;
  Id: number;
}
@Component({
  selector: 'app-conv-design-flow',
  templateUrl: './conv-design-flow.component.html',
  styleUrls: ['./conv-design-flow.component.css']
})
export class ConvDesignFlowComponent implements OnInit {

  @ViewChild('diagram')
  public diagram!: DiagramComponent;

  @ViewChild('viewdiagram')
  public viewdiagram!: DiagramComponent;

  @ViewChild('Description1')
  public description: TextBox;

  @Input() inputVariable: any;
  _inputData: any
  _selectedRowData: any = []
  _disablePublishBtn: boolean = true

  @Output() inputdata: EventEmitter<any> = new EventEmitter<any>();

  public contextMenuSettings!: ContextMenuSettingsModel

  private interval: number[] = [1, 9, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25,
    9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75];
  private horizontalGridlines: GridlinesModel = { lineColor: '#e0e0e0', lineIntervals: this.interval };
  private verticalGridlines: GridlinesModel = { lineColor: '#e0e0e0', lineIntervals: this.interval };
  public snapSettings: SnapSettingsModel = { horizontalGridlines: this.horizontalGridlines, verticalGridlines: this.verticalGridlines };

  //view Flowchart
  diagramArr: any = []
  nodesArr: any
  connectorsArr: any
  public nodes: NodeModel[] = []
  public connectors: ConnectorModel[] = []
  public visible: Boolean = false;

  public layout: Object = {

    //Set the layout type

    type: 'HierarchicalTree'

  };
  isCreateFlowchart: boolean = true

  selectedNodeID: any
  selectedNodeName: any
  delectedNodeNameInUpperCase: any
  Slots: any = {

    "UseCaseName": "",
    "IntentName": "",
    "PrimaryQuestion": "",

    "Slots": []
  }

  IsreturnSlot: boolean = false
  data = new ConvFlowEntity()

  validUserInputData = new ConvFlowEntity();

  _ConvFlowEntityObj = new ConvFlowEntity();
  _SlotObj = new Slot();
  _SlotmessageObj = new Slotmessage();
  _ReturnSlotBasedOnInputObj = new ReturnSlotBasedOnInput();
  _ngModelSlotmessageObj = new ngModelSlotmessage();
  // _ngModelReturnSlotBasedOnInputObj = new ngModelReturnSlotBasedOnInput();

  _ngModelReturnSlotBasedOnInputArr: Array<ngModelReturnSlotBasedOnInput>

  nextSlotName: any = []

  // generalForm!: FormGroup;
  // generalFormsubmitted = false;
  fetchRoleName: any

  SlotName: any;
  SlotOrder: any;
  SlotType: any
  WebDisplayMsg: any;
  appDisplayMsg: any;
  AwaitWebDisplayMsg: any;
  AwaitAppDisplayMsg: any;
  SlotValues: any = [];
  SlotInputValues: any
  NumberOfAttemptsAllowed: any = "1";
  errorUserInput = "Error";
  webErrorMsg: any;
  appErrorMsg: any;
  tooManyAttemptsUserInput = "tooManyAttempts";
  noOfAttemptsErrorMsgWeb: any;
  noOfAttemptsErrorMsgApp: any;

  //Additional fields for admin
  SlotDataType: any
  DataValidation: any
  Dataformat: any
  DataformatName: any
  Inputformat: any
  WebAppType = "Web"
  AppType = "App"

  //For slot message web
  WebPreMessage: any
  WebPreMessageType: any
  WebMainMessage: any
  WebResponseType: any
  WebPostMessage: any
  WebPoseMessageType: any

  //For slot message app
  appPreMessage: any
  appPreMessageType: any
  appMainMessage: any
  appResponseType: any
  appPostMessage: any
  appPoseMessageType: any

  //For await message web
  AwaitWebPreMessage: any
  AwaitWebPreMessageType: any
  AwaitWebMainMessage: any
  AwaitWebResponseType: any
  AwaitWebPostMessage: any
  AwaitWebPostMessageType: any

  //For await message app
  AwaitAppPreMessage: any
  AwaitAppPreMessageType: any
  AwaitAppMainMessage: any
  AwaitAppResponseType: any
  AwaitAppPostMessage: any
  AwaitAppPostMessageType: any

  //For user Input slot message web
  uiWebPreMessage: any
  uiWebPreMessageType: any
  uiWebMainMessage: any
  uiWebResponseType: any
  uiWebPostMessage: any
  uiWebPoseMessageType: any
  uiWebDisplayMsg: any

  //For user Input slot message app
  uiAppPreMessage: any
  uiAppPreMessageType: any
  uiAppMainMessage: any
  uiAppResponseType: any
  uiAppPostMessage: any
  uiAppPoseMessageType: any
  uiAppDisplayMsg: any

  //For user Input slot message app - Return to the same slot
  singleSlotValue: any
  selectedReturnSlot: any
  fetchReturnSlotRadioButton: any
  _isSlotValueEmpty: boolean = false

  //For error slot message web
  errorWebPreMessage: any
  errorWebPreMessageType: any
  errorWebMainMessage: any
  errorWebResponseType: any
  errorWebPostMessage: any
  errorWebPoseMessageType: any

  //For error slot message app
  errorAppPreMessage: any
  errorAppPreMessageType: any
  errorAppMainMessage: any
  errorAppResponseType: any
  errorAppPostMessage: any
  errorAppPoseMessageType: any

  //For error slot message web
  noOfAttemptsWebPreMessage: any
  noOfAttemptsWebPreMessageType: any
  noOfAttemptsWebMainMessage: any
  noOfAttemptsWebResponseType: any
  noOfAttemptsWebPostMessage: any
  noOfAttemptsWebPoseMessageType: any

  //For error slot message app
  noOfAttemptsAppPreMessage: any
  noOfAttemptsAppPreMessageType: any
  noOfAttemptsAppMainMessage: any
  noOfAttemptsAppResponseType: any
  noOfAttemptsAppPostMessage: any
  noOfAttemptsAppPoseMessageType: any

  validUserInputArr: any = []

  isPropertyBlock: boolean = false
  _isbuttonDisable: boolean = true

  _isPropertyLoader: boolean = false
  _isSaveDiagramLoader: boolean = false

  _isValidUserInputToSameSlot: boolean = false
  _isreturnSlotBasedOnInput: boolean = false

  _isReturnNameFieldDisabled: boolean = false
  _errorFlagIfReturnNameFieldDisabled: boolean = false
  tooltip: any

  dataTypes: any = []
  dataFormat: any = []
  dataValidation: any = []
  SlotTypeArr: any = []
  returnNameArr: any = []

  _isEndOfConv: boolean = false

  _selectedDataFormat: any;

  // public dlgButtons: Object[] = [{ click: this.dlgButtonClick.bind(this), buttonModel: { content: 'Update', isPrimary: true } }];

  constructor(private formBuilder: FormBuilder, public conversationFlowService: ConversationFlowService, public faqconfigservice: FaqService,) { }

  ngOnInit(): void {
    
    //Enables the context menu
    this.contextMenuSettings = {
      //Enables the context menu
      show: true,
      items: [
        {
          text: 'Properties', id: 'Properties', target: '.e-diagramcontent'
        },
        {
          text: 'Delete Node', id: 'delete', target: '.e-diagramcontent'
        }
      ],
      // Hides the default context menu items
      showCustomMenuOnly: true,
    }

    // this.MainGeneralForm();

    this.fetchRoleName = localStorage.getItem("RoleName")

    // this.SlotType = "Process"
    // this.SlotDataType = "String"
    // this.DataValidation = "NA"

    this._selectedDataFormat =
      { name: 'NA', code: 'NA', regex: 'NA' }

    this._inputData = this.inputVariable
    this._selectedRowData = this._inputData.selectedRowData
    this.Slots.UseCaseName = this._inputData.selectedRowData.SectionName
    this.Slots.IntentName = this._inputData.selectedRowData.IntentName
    this.Slots.PrimaryQuestion = this._inputData.selectedRowData.Questions


    //default values
    this._ngModelSlotmessageObj.appAppType = "App"
    this._ngModelSlotmessageObj.webAppType = "Web"
    this._SlotObj.SlotType = "Process"
    this._SlotObj.SlotDataType = "String"
    this._SlotObj.DataValidation = "NA"
    this._SlotObj.DataFormat = this._selectedDataFormat.regex


    if (this._selectedRowData.FlowDiagramJSON != "") {
      this.fetchNodesAndConnectorsFromDB();
    }
    this.fetchSlotData();
    this.fetchDataType();
    this.fetchDataFormat();
    this.fetchdataValidation();
    this.fetchSlotTypes();
    // this.fetchNodesArr();
  }

  public getSymbolInfo(symbol: NodeModel): SymbolInfo {
    return { fit: true };
  }

  public getSymbolDefaults(symbol: NodeModel): void {
    
    if (symbol.id === 'Terminator' || symbol.id === 'Process') {
      symbol.width = 80;
      symbol.height = 40;
    } else if (symbol.id === 'Decision' || symbol.id === 'Document' || symbol.id === 'PreDefinedProcess' ||
      symbol.id === 'PaperTap' || symbol.id === 'DirectData' || symbol.id === 'MultiDocument' || symbol.id === 'Data') {
      symbol.width = 50;
      symbol.height = 40;
    } else {
      symbol.width = 50;
      symbol.height = 50;
    }
    // symbol.style.strokeColor = '#757575';
    // symbol.style?.strokeColor= String
  }

  //SymbolPalette Properties
  public symbolMargin: MarginModel = { left: 15, right: 15, top: 15, bottom: 15 };
  public expandMode: ExpandMode = 'Multiple';
  public enableAnimation: any = true;
  //Initialize the flowshapes for the symbol palatte
  private flowshapes: NodeModel[] = [
    { id: 'Terminator', shape: { type: 'Flow', shape: 'Terminator' } },
    { id: 'Process', shape: { type: 'Flow', shape: 'Process' } }
  ];
  //Initializes connector symbols for the symbol palette
  private connectorSymbols: ConnectorModel[] = [
    {
      id: 'Link1', type: 'Orthogonal', sourcePoint: { x: 0, y: 0 }, targetPoint: { x: 40, y: 40 },
      targetDecorator: { shape: 'Arrow', style: { strokeColor: '#757575', fill: '#757575' } }, style: { strokeWidth: 2, strokeColor: '#757575' }
    },
    {
      id: 'Link21', type: 'Straight', sourcePoint: { x: 0, y: 0 }, targetPoint: { x: 40, y: 40 },
      targetDecorator: { shape: 'Arrow', style: { strokeColor: '#757575', fill: '#757575' } }, style: { strokeWidth: 2, strokeColor: '#757575' }
    }
  ];

  public palettes: PaletteModel[] = [
    { id: 'flow', expanded: true, symbols: this.flowshapes, iconCss: 'shapes', title: 'Shapes' },
    { id: 'connectors', expanded: true, symbols: this.connectorSymbols, iconCss: 'shapes', title: 'Connectors' }
  ]

  public create(args: Object): void {
    this.diagram.fitToPage();
    this.diagram.dataBind();
  }

  //Save the diagram.
  saveFlowchart() {
    
    this._isSaveDiagramLoader = true
    if (this.diagram) {
      this.saveDiagram(this.diagram.saveDiagram());
    } else if (this.viewdiagram) {
      this.saveDiagram(this.viewdiagram.saveDiagram());
    }
    this.saveToDB(this._ConvFlowEntityObj);
  }

  // //Save the diagram after click on save 
  // saveFlowChartOnpropertyBlock() {
  //   if (this.diagram) {
  //     this.saveDiagram(this.diagram.saveDiagram());
  //   } else if (this.viewdiagram) {
  //     this.saveDiagram(this.viewdiagram.saveDiagram());
  //   }
  //   this.saveToDB();
  // }

  // saveFlowchart1() {
  //   this.saveDiagram(this.viewdiagram.saveDiagram());
  // }

  diagramObj: any
  public saveDiagram(data: string): void {
    
    // let dataStr: string = 'data:text/json;charset=utf-8,' + encodeURIComponent(data);
    // let a: HTMLAnchorElement = document.createElement('a');
    // a.href = dataStr;
    // a.download = 'Diagram.json';
    // document.body.appendChild(a);
    // a.click();
    // a.remove();

    // this.diagramObj = JSON.stringify(data) // added to store diagram object into DB.
    this.diagramArr = JSON.parse(data)

    this.nodesArr = this.diagramArr.nodes
    this.connectorsArr = this.diagramArr.connectors

    //this.fetchNodesAndConnectors();
    this.isCreateFlowchart = false

  }

  // //fetch nodes and connectors dynamically after save the diagram.
  // fetchNodesAndConnectors() {
  //   
  //   this.nodes = this.nodesArr
  //   this.connectors = this.connectorsArr
  //   console.log("fetch nodes", this.nodes)
  //   console.log("fetch connectors", this.connectors)

  //   for (let i = 0; i < this.nodes.length; i++) {
  //     var fetchRecord = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.nodes[i].id)
  //     console.log(fetchRecord)
  //     if (!fetchRecord) {
  //       if (this.nodes[i].annotations![0].content?.toUpperCase() === 'END' || this.nodes[i].annotations![0].content?.toUpperCase() === 'ENDOFSLOT') {
  //         this.nodes[i].annotations![0].content = "EndOfSlots"
  //         this._isEndOfConv = true
  //       }
  //       const Object = {
  //         SlotID: this.nodes[i].id,
  //         SlotName: this.nodes[i].annotations![0].content,
  //         SlotType: this.SlotType,
  //         SlotDataType: this.SlotDataType,
  //         SlotOrder: "",
  //         SlotValues: [""],
  //         NoOfAttemptsAllowed: "1",
  //         NoOfAttemptsOver: 0,
  //         DataValidation: this.DataValidation,
  //         DataFormat: this._selectedDataFormat.regex,
  //         DataFormatName: this._selectedDataFormat.name,
  //         InputFormat: "",
  //         SavedUserInput: "",
  //         SaveIntoDB: "",
  //         UserInputBox: true,
  //         Enablefield: "",
  //         EndOfConv: this._isEndOfConv,
  //         SlotMessage: [
  //           {
  //             SlotID: this.nodes[i].id,
  //             AppType: "Web",
  //             PreMsg: "",
  //             PreMsgType: "",
  //             MainMsg: "",
  //             DisplayMessage: "",
  //             ResponseType: "",
  //             PostMsg: "",
  //             PostMsgType: ""
  //           },
  //           {
  //             SlotID: this.nodes[i].id,
  //             AppType: "App",
  //             PreMsg: "",
  //             PreMsgType: "",
  //             MainMsg: "",
  //             DisplayMessage: "",
  //             ResponseType: "",
  //             PostMsg: "",
  //             PostMsgType: ""
  //           }
  //         ],
  //         AwaitMsg: [
  //           {
  //             SlotID: this.nodes[i].id,
  //             AppType: "Web",
  //             PreMsg: "",
  //             PreMsgType: "",
  //             MainMsg: "",
  //             DisplayMessage: "",
  //             ResponseType: "",
  //             PostMsg: "",
  //             PostMsgType: ""
  //           },
  //           {
  //             SlotID: this.nodes[i].id,
  //             AppType: "App",
  //             PreMsg: "",
  //             PreMsgType: "",
  //             MainMsg: "",
  //             DisplayMessage: "",
  //             ResponseType: "",
  //             PostMsg: "",
  //             PostMsgType: ""
  //           }
  //         ],
  //         ReturnSlotBasedOnInput: [
  //           {
  //             SlotID: this.nodes[i].id,
  //             UserInput: this.errorUserInput,
  //             ReturnName: "",
  //             ReturnType: "Slot",
  //             ReturnParams: {},
  //             CustomCSMethod: "",
  //             APIEndPointDetails: [],
  //             Override: "",
  //             SlotMessage: [
  //               {
  //                 SlotID: this.nodes[i].id,
  //                 AppType: "Web",
  //                 PreMsg: "",
  //                 PreMsgType: "",
  //                 MainMsg: "",
  //                 DisplayMessage: "",
  //                 ResponseType: "",
  //                 PostMsg: "",
  //                 PostMsgType: ""
  //               },
  //               {
  //                 SlotID: this.nodes[i].id,
  //                 AppType: "App",
  //                 PreMsg: "",
  //                 PreMsgType: "",
  //                 MainMsg: "",
  //                 DisplayMessage: "",
  //                 ResponseType: "",
  //                 PostMsg: "",
  //                 PostMsgType: ""
  //               }
  //             ]
  //           },
  //           {
  //             SlotID: this.nodes[i].id,
  //             UserInput: this.tooManyAttemptsUserInput,
  //             ReturnName: this.tooManyAttemptsUserInput,
  //             ReturnType: "Slot",
  //             ReturnParams: {},
  //             CustomCSMethod: "",
  //             APIEndPointDetails: [],
  //             Override: "",
  //             SlotMessage: [
  //               {
  //                 SlotID: this.nodes[i].id,
  //                 AppType: "Web",
  //                 PreMsg: "",
  //                 PreMsgType: "",
  //                 MainMsg: "",
  //                 DisplayMessage: "",
  //                 ResponseType: "",
  //                 PostMsg: "",
  //                 PostMsgType: ""
  //               },
  //               {
  //                 SlotID: this.nodes[i].id,
  //                 AppType: "App",
  //                 PreMsg: "",
  //                 PreMsgType: "",
  //                 MainMsg: "",
  //                 DisplayMessage: "",
  //                 ResponseType: "",
  //                 PostMsg: "",
  //                 PostMsgType: ""
  //               }
  //             ]
  //           }
  //         ]
  //       }
  //       this.Slots.Slots.push(Object)
  //       this.Slots.Slots = this.Slots.Slots.filter((x: { SlotName: string; }) => x.SlotName.toUpperCase() !== 'START');

  //     }
  //   }
  //   console.log("slots aaray at first time......", this.Slots)
  // }

  textvalue = "FlowDiagramJSON";
  //Fetch nodes and connectors dynamically if already saved in DB
  fetchNodesAndConnectorsFromDB() {
    
    var txtVal = this.textvalue;
    if (!(txtVal in this._selectedRowData)) {
      this.isCreateFlowchart = true
    } else if (this._selectedRowData.FlowDiagramJSON != "") {
      let selectedRowFlowDiagram = JSON.parse(this._selectedRowData.FlowDiagramJSON)

      this.diagramArr = JSON.parse(this._selectedRowData.FlowDiagramJSON)

      this.nodes = selectedRowFlowDiagram.nodes
      this.connectors = selectedRowFlowDiagram.connectors

      this.isCreateFlowchart = false
    }
  }

  fetchSlotData() {
    
    var txtVal = this.textvalue;
    if (!(txtVal in this._selectedRowData)) {
      this.isCreateFlowchart = true
    } else {
      
      if (this._selectedRowData.ConversationalFlowJSON) {
        // this.Slots = JSON.parse(this._selectedRowData.ConversationalFlowJSON)
        this._ConvFlowEntityObj = JSON.parse(this._selectedRowData.ConversationalFlowJSON)

        if (this.Slots.Slots.length > 0) {
          this._disablePublishBtn = false
        }
      }

    }

  }

  public contextMenuClick(args: MenuEventArgs): void {
    
    let argsEvent: any
    let data
    argsEvent = args.event
    let diagramNodes: any = this.viewdiagram.selectedItems.nodes?.length
    let diagramConnector: any = this.viewdiagram.selectedItems.connectors?.length

    if (args.item.text === "Properties") {
      // data = "true"
      // this.abc = true
      this.isPropertyBlock = true
      this.FetchValueAndAddToNgModel(this.selectedNodeName);
      // this.SaveMainWebValues();
      // this.SaveMainAppValues();
      // this.SaveAwaitWebValues();
      // this.SaveAwaitAppValues();
      // this.SaveErrorSlotValues();
      // this.SaveTooManyAttemptsSlotValues();
      // this.SaveUserInputSlotValues();
      // this.SaveValidUserInputToSameSlot();
    } else if (args.item.id === 'delete') {
      
      if ((diagramNodes + diagramConnector) > 0) {
        this.viewdiagram.cut();


        var checkSlotExists = this._ConvFlowEntityObj.Slots.find((x: { SlotName: string; }) => x.SlotName == this.selectedNodeName)
        
        if (checkSlotExists) {
          const index = this._ConvFlowEntityObj.Slots.indexOf(checkSlotExists);
          if (index !== -1) {
            this._ConvFlowEntityObj.Slots.splice(index, 1);
          }
        }
      }
    }
  }


  FetchValueAndAddToNgModel(vSelectedNodeName: any) {

    

    var selectedNodedata = this._ConvFlowEntityObj.Slots.find((x: { SlotName: string; }) => x.SlotName == vSelectedNodeName)

    if (selectedNodedata) {
      this._SlotObj = new Slot();

      //general
      this._SlotObj = selectedNodedata;

      this._ngModelSlotmessageObj = new ngModelSlotmessage();

      //fetch slotvalues as a string
      this.SlotInputValues = this._SlotObj.SlotValues.toString()

      // slotmessages appp and web
      selectedNodedata.Slotmessage.forEach((value: any, id: any) => {
        if (value.AppType.toUpperCase() == 'WEB') {
          this._ngModelSlotmessageObj.webAppType = value.AppType == undefined ? "" : value.AppType;
          this._ngModelSlotmessageObj.webPreMsg = value.PreMsg == undefined ? "" : value.PreMsg;
          this._ngModelSlotmessageObj.webPreMsgType = value.PreMsgType == undefined ? "" : value.PreMsgType;
          this._ngModelSlotmessageObj.webMainMsg = value.MainMsg == undefined ? "" : value.MainMsg;
          this._ngModelSlotmessageObj.webDisplayMessage = value.DisplayMessage == undefined ? "" : value.DisplayMessage;
          this._ngModelSlotmessageObj.webResponseType = value.ResponseType == undefined ? "" : value.ResponseType;
          this._ngModelSlotmessageObj.webPostMsg = value.PostMsg == undefined ? "" : value.PostMsg;
          this._ngModelSlotmessageObj.webPostMsgType = value.PostMsgType == undefined ? "" : value.PostMsgType;
        }

        if (value.AppType.toUpperCase() == 'APP') {
          this._ngModelSlotmessageObj.appAppType = value.AppType == undefined ? "" : value.AppType;
          this._ngModelSlotmessageObj.appPreMsg = value.PreMsg == undefined ? "" : value.PreMsg;
          this._ngModelSlotmessageObj.appPreMsgType = value.PreMsgType == undefined ? "" : value.PreMsgType;
          this._ngModelSlotmessageObj.appMainMsg = value.MainMsg == undefined ? "" : value.MainMsg;
          this._ngModelSlotmessageObj.appDisplayMessage = value.DisplayMessage == undefined ? "" : value.DisplayMessage;
          this._ngModelSlotmessageObj.appResponseType = value.ResponseType == undefined ? "" : value.ResponseType;
          this._ngModelSlotmessageObj.appPostMsg = value.PostMsg == undefined ? "" : value.PostMsg;
          this._ngModelSlotmessageObj.appPostMsgType = value.PostMsgType == undefined ? "" : value.PostMsgType;
        }

      })

      
      this._ngModelReturnSlotBasedOnInputArr = [];

      //return slot
      selectedNodedata.Returnslotbasedoninput.forEach((value: any, id: any) => {
        var ngModelReturnSlotBasedOnInputObj = new ngModelReturnSlotBasedOnInput();

        ngModelReturnSlotBasedOnInputObj.id = id
        ngModelReturnSlotBasedOnInputObj.SlotID = value.SlotID;
        ngModelReturnSlotBasedOnInputObj.UserInput = value.UserInput;
        ngModelReturnSlotBasedOnInputObj.ReturnType = value.ReturnType == undefined ? "Slots" : value.ReturnType;
        ngModelReturnSlotBasedOnInputObj.ReturnName = value.ReturnName == undefined ? "" : value.ReturnName;
        ngModelReturnSlotBasedOnInputObj.Returnparams = new Returnparams();
        ngModelReturnSlotBasedOnInputObj.CustomCSMethod = value.CustomCSMethod;
        ngModelReturnSlotBasedOnInputObj.Override = value.Override == undefined ? "" : value.Override;
        ngModelReturnSlotBasedOnInputObj.APIEndPointDetails == [];

        value.Slotmessage.forEach((val: any, id: any) => {
          if (val.AppType.toUpperCase() == 'WEB') {
            ngModelReturnSlotBasedOnInputObj.Slotmessage.webAppType = val.AppType == undefined ? "" : val.AppType;
            ngModelReturnSlotBasedOnInputObj.Slotmessage.webPreMsg = val.PreMsg == undefined ? "" : val.PreMsg;
            ngModelReturnSlotBasedOnInputObj.Slotmessage.webPreMsgType = val.PreMsgType == undefined ? "" : val.PreMsgType;
            ngModelReturnSlotBasedOnInputObj.Slotmessage.webMainMsg = val.MainMsg == undefined ? "" : val.MainMsg;
            ngModelReturnSlotBasedOnInputObj.Slotmessage.webDisplayMessage = val.DisplayMessage == undefined ? "" : val.DisplayMessage;
            ngModelReturnSlotBasedOnInputObj.Slotmessage.webResponseType = val.ResponseType == undefined ? "" : val.ResponseType;
            ngModelReturnSlotBasedOnInputObj.Slotmessage.webPostMsg = val.PostMsg == undefined ? "" : val.PostMsg;
            ngModelReturnSlotBasedOnInputObj.Slotmessage.webPostMsgType = val.PostMsgType == undefined ? "" : val.PostMsgType;
          }

          if (val.AppType.toUpperCase() == 'APP') {
            ngModelReturnSlotBasedOnInputObj.Slotmessage.appAppType = val.AppType == undefined ? "" : val.AppType;
            ngModelReturnSlotBasedOnInputObj.Slotmessage.appPreMsg = val.PreMsg == undefined ? "" : val.PreMsg
            ngModelReturnSlotBasedOnInputObj.Slotmessage.appPreMsgType = val.PreMsgType == undefined ? "" : val.PreMsgType;
            ngModelReturnSlotBasedOnInputObj.Slotmessage.appMainMsg = val.MainMsg == undefined ? "" : val.MainMsg;
            ngModelReturnSlotBasedOnInputObj.Slotmessage.appDisplayMessage = val.DisplayMessage == undefined ? "" : val.DisplayMessage;
            ngModelReturnSlotBasedOnInputObj.Slotmessage.appResponseType = val.ResponseType == undefined ? "" : val.ResponseType;
            ngModelReturnSlotBasedOnInputObj.Slotmessage.appPostMsg = val.PostMsg == undefined ? "" : val.PostMsg;
            ngModelReturnSlotBasedOnInputObj.Slotmessage.appPostMsgType = val.PostMsgType == undefined ? "" : val.PostMsgType;
          }
        })

        this._ngModelReturnSlotBasedOnInputArr.push(ngModelReturnSlotBasedOnInputObj);
      })


    }
    else {
      this._SlotObj = new Slot();
      this._ngModelSlotmessageObj = new ngModelSlotmessage();
      this._ngModelReturnSlotBasedOnInputArr = [];

      //default values
      this._ngModelSlotmessageObj.appAppType = "App"
      this._ngModelSlotmessageObj.webAppType = "Web"
      this._SlotObj.SlotType = "Process"
      this._SlotObj.SlotDataType = "String"
      this._SlotObj.DataValidation = "NA"
      this._SlotObj.SlotName = this.selectedNodeName
      this._SlotObj.DataFormat = this._selectedDataFormat.regex
    }

    // 
    // var dq = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
    // console.log(dq)
    // if (dq) {
    //   //
    //   this.SlotName = dq.SlotName
    //   this.SlotOrder = dq.SlotOrder
    //   this.SlotInputValues = dq.SlotValues
    //   this.SlotType = dq.SlotType
    //   this.SlotDataType = dq.SlotDataType
    //   this.DataValidation = dq.DataValidation
    //   this._selectedDataFormat.name = dq.DataFormatName
    //   this._selectedDataFormat.regex = dq.DataFormat
    //   this._selectedDataFormat.code = dq.DataFormatName
    //   this.Inputformat = dq.InputFormat
    //   this.NumberOfAttemptsAllowed = dq.NoOfAttemptsAllowed

    //   if (dq.SlotValues.length > 0) {
    //     this._isreturnSlotBasedOnInput = true
    //   }

    //   // if (this.SlotValues.length > 0) {
    //   //   this._isSlotValueEmpty = true
    //   //   // this.addValidUserInputToSameSlot()
    //   // } else {
    //   //   this._isSlotValueEmpty = false
    //   // }
    // }
    // // if (this.selectedNodeID === dq) {
    // //   //
    // //   var d = this.slotDataFromJson.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
    // //   console.log(d)
    // //   this.generalForm.controls.SlotName.setValue(d.SlotName)
    // // }
  }


  UserInput: any
  ReturnName: any
  public contextMenuOpen(args: DiagramBeforeMenuOpenEventArgs): void {

    
    //to get a node
    this.isPropertyBlock = false

    let selectedNode: NodeModel = this.viewdiagram.selectedItems.nodes![0];
    if (selectedNode) {
      this.selectedNodeID = selectedNode.id
      this.selectedNodeName = selectedNode.annotations![0].content

      // this._SlotObj.SlotName = selectedNode.annotations![0].content //Newly added

      this.delectedNodeNameInUpperCase = this.selectedNodeName.toUpperCase()
      let connectorDataArr: any = []
      this.nextSlotName = []
      for (let i = 0; i < this.connectors.length; i++) {
        if (this.selectedNodeID === this.connectors[i].sourceID) {

          connectorDataArr.push(this.connectors[i])
        }
      }


      for (let j = 0; j < connectorDataArr.length; j++) {
        for (let k = 0; k < this.nodes.length; k++) {
          if (connectorDataArr[j].targetID === this.nodes[k].id) {
            this.nextSlotName.push(this.nodes[k].annotations![0].content)
          }
        }
      }
      
      // this.addValidUserInput()
      // this.GenarteReturnSLotArrasync();

      this.clearInputFields()

      this.fetchNodesArr();
    }
    //to get a connector
    let selectedConnector: ConnectorModel = this.viewdiagram.selectedItems.connectors![0];
    if (selectedNode && selectedNode.annotations && selectedNode.annotations.length > 0) {
      //get a node annotation object
      let label = selectedNode.annotations[0];
    }

    if (selectedConnector && selectedConnector.annotations && selectedConnector.annotations.length > 0) {
      //get a connector annotation object
      let label = selectedConnector.annotations[0];
    }
    if (!(selectedNode || selectedConnector)) {
      //cancel a event if it is a diagram.
      args.cancel = true;
    }
  }

  angForm = new FormGroup({
    names: new FormArray([
      new FormControl('', Validators.required),
      new FormControl('', Validators.required),
    ])
  });

  clearInputFields() {
    this.SlotOrder = ""
    this.SlotValues = ""
    this.SlotDataType = ""
    this.DataValidation = ""
    this.Dataformat = ""
    this.Inputformat = ""

    this.WebPreMessage = ""
    this.WebPreMessageType = ""
    this.WebMainMessage = ""
    this.WebDisplayMsg = ""
    this.WebResponseType = ""
    this.WebPostMessage = ""
    this.WebPoseMessageType = ""

    this.appPreMessage = ""
    this.appPreMessageType = ""
    this.appMainMessage = ""
    this.appDisplayMsg = ""
    this.appResponseType = ""
    this.appPostMessage = ""
    this.appPoseMessageType = ""

    this.AwaitWebPreMessage = ""
    this.AwaitWebPreMessageType = ""
    this.AwaitWebMainMessage = ""
    this.AwaitWebResponseType = ""
    this.AwaitWebPostMessage = ""
    this.AwaitWebPostMessageType = ""

    this.AwaitAppPreMessage = ""
    this.AwaitAppPreMessageType = ""
    this.AwaitAppMainMessage = ""
    this.AwaitAppResponseType = ""
    this.AwaitAppPostMessage = ""
    this.AwaitAppPostMessageType = ""

    this.errorWebPreMessage = ""
    this.errorWebPreMessageType = ""
    this.errorWebMainMessage = ""
    this.webErrorMsg = ""
    this.errorWebResponseType = ""
    this.errorWebPostMessage = ""
    this.errorWebPoseMessageType = ""

    this.errorAppPreMessage = ""
    this.errorAppPreMessageType = ""
    this.errorAppMainMessage = ""
    this.appErrorMsg = ""
    this.errorAppResponseType = ""
    this.errorAppPostMessage = ""
    this.errorAppPoseMessageType = ""

    this.noOfAttemptsWebPreMessage = ""
    this.noOfAttemptsWebPreMessageType = ""
    this.noOfAttemptsWebMainMessage = ""
    this.noOfAttemptsErrorMsgWeb = ""
    this.noOfAttemptsWebResponseType = ""
    this.noOfAttemptsWebPostMessage = ""
    this.noOfAttemptsWebPoseMessageType = ""

    this.noOfAttemptsAppPreMessage = ""
    this.noOfAttemptsAppPreMessageType = ""
    this.noOfAttemptsAppMainMessage = ""
    this.noOfAttemptsErrorMsgApp = ""
    this.noOfAttemptsAppResponseType = ""
    this.noOfAttemptsAppPostMessage = ""
    this.noOfAttemptsAppPoseMessageType = ""
  }

  // mainFormSubmit() {
  //   

  //   this._isPropertyLoader = true

  //   var fetchGeneralRecord = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   console.log(fetchGeneralRecord)

  //   var fetchSlotMessage = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID).SlotMessage
  //   console.log(fetchSlotMessage)

  //   var fetchWebRecord = fetchSlotMessage.find((x: { AppType: string; }) => x.AppType == "Web")
  //   console.log(fetchSlotMessage)

  //   var fetchAppRecord = fetchSlotMessage.find((x: { AppType: string; }) => x.AppType == "App")

  //   var fetchReturnSlot = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID).ReturnSlotBasedOnInput
  //   console.log(fetchReturnSlot)

  //   if (fetchGeneralRecord) {
  //     let indexMain = this.Slots.Slots.indexOf(fetchGeneralRecord);

  //     this.SlotValues.push(this.SlotInputValues)

  //     fetchGeneralRecord.SlotID = this.selectedNodeID
  //     fetchGeneralRecord.SlotName = this.SlotName
  //     fetchGeneralRecord.SlotOrder = this.SlotOrder
  //     fetchGeneralRecord.SlotValues = this.SlotValues
  //     fetchGeneralRecord.NoOfAttemptsAllowed = this.NumberOfAttemptsAllowed

  //     //Save web data
  //     if (fetchWebRecord) {
  //       let indexMain = fetchSlotMessage.indexOf(fetchWebRecord);
  //       fetchWebRecord.DisplayMsg = this.WebDisplayMsg,

  //         fetchSlotMessage[indexMain] = fetchWebRecord
  //     }

  //     //Save app data
  //     if (fetchAppRecord) {

  //       let indexMain = fetchSlotMessage.indexOf(fetchAppRecord);

  //       fetchAppRecord.DisplayMsg = this.appDisplayMsg

  //       fetchSlotMessage[indexMain] = fetchAppRecord
  //     }

  //     //Save data in 'Error' slot
  //     var fetchErrorSlotRecord = fetchReturnSlot.find((x: { UserInput: string; }) => x.UserInput == "Error")
  //     // console.log(d)

  //     if (fetchErrorSlotRecord) {
  //       var CheckWeb = fetchErrorSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "Web")
  //       if (CheckWeb) {
  //         let indexMain = fetchErrorSlotRecord.SlotMessage.indexOf(CheckWeb);

  //         CheckWeb.SlotID = this.selectedNodeID
  //         CheckWeb.DisplayMsg = this.webErrorMsg

  //         fetchErrorSlotRecord.SlotMessage[indexMain] = CheckWeb
  //       }

  //       var CheckApp = fetchErrorSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "App")
  //       if (CheckApp) {
  //         let indexMain = fetchErrorSlotRecord.SlotMessage.indexOf(CheckApp);

  //         CheckApp.SlotID = this.selectedNodeID
  //         CheckApp.DisplayMsg = this.appErrorMsg

  //         fetchErrorSlotRecord.SlotMessage[indexMain] = CheckApp
  //       }
  //     }

  //     //Save data in 'Too many attempts' slot
  //     var fetchTooManyAttemptSlotRecord = fetchReturnSlot.find((x: { UserInput: string; }) => x.UserInput == "tooManyAttempts")
  //     if (fetchTooManyAttemptSlotRecord) {

  //       var CheckWeb = fetchTooManyAttemptSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "Web")
  //       if (CheckWeb) {
  //         let indexMain = fetchTooManyAttemptSlotRecord.SlotMessage.indexOf(CheckWeb);

  //         CheckWeb.SlotID = this.selectedNodeID
  //         CheckWeb.DisplayMsg = this.noOfAttemptsErrorMsgWeb

  //         fetchTooManyAttemptSlotRecord.SlotMessage[indexMain] = CheckWeb
  //       }

  //       var CheckApp = fetchTooManyAttemptSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "App")
  //       if (CheckApp) {
  //         let indexMain = fetchTooManyAttemptSlotRecord.SlotMessage.indexOf(CheckApp);

  //         CheckApp.SlotID = this.selectedNodeID
  //         CheckApp.DisplayMsg = this.noOfAttemptsErrorMsgApp

  //         fetchTooManyAttemptSlotRecord.SlotMessage[indexMain] = CheckApp
  //       }
  //     }

  //     //Save data in Valid user input array
  //     for (let address of this.validUserInputArr) {
  //       var fetchReturnSlot = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID).ReturnSlotBasedOnInput
  //       console.log(fetchReturnSlot)
  //       var fetchReturnSlotRecord = fetchReturnSlot.find((x: { ReturnName: string; }) => x.ReturnName == address.ReturnName)

  //       var fetchSingleSlotRecord = fetchReturnSlot.find((x: { UserInput: string; }) => x.UserInput == "@All")
  //       console.log(fetchSingleSlotRecord)

  //       if (fetchReturnSlot) {
  //         if (fetchReturnSlotRecord) {
  //           // let indexMain = fetchReturnSlot.indexOf(fetchReturnSlotRecord);

  //           // fetchReturnSlotRecord.SlotID = this.selectedNodeID,
  //           //   fetchReturnSlotRecord.UserInput = this.userInputForm.controls.UserInput.value
  //           // fetchReturnSlotRecord.ReturnType = this.userInputForm.controls.ReturnType.value
  //           // fetchReturnSlotRecord.ReturnName = this.userInputForm.controls.ReturnName.value

  //           // fetchReturnSlot[indexMain] = fetchReturnSlotRecord

  //           var CheckWeb = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "Web")
  //           if (CheckWeb) {
  //             let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckWeb);

  //             CheckWeb.SlotID = this.selectedNodeID
  //             CheckWeb.WebDisplayMessage = address.SlotMessage[0].WebDisplayMessage

  //             fetchReturnSlotRecord.SlotMessage[indexMain] = CheckWeb
  //           }

  //           var CheckApp = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "App")
  //           if (CheckApp) {
  //             let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckApp);

  //             CheckApp.SlotID = this.selectedNodeID
  //             CheckApp.AppDisplayMessage = address.SlotMessage[1].AppDisplayMessage

  //             fetchReturnSlotRecord.SlotMessage[indexMain] = CheckApp
  //           }
  //         } else if (fetchSingleSlotRecord) {

  //           var CheckWeb = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "Web")
  //           if (CheckWeb) {
  //             let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckWeb);

  //             CheckWeb.SlotID = this.selectedNodeID
  //             CheckWeb.WebDisplayMessage = address.SlotMessage[0].WebDisplayMessage

  //             fetchReturnSlotRecord.SlotMessage[indexMain] = CheckWeb
  //           }

  //           var CheckApp = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "App")
  //           if (CheckApp) {
  //             let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckApp);

  //             CheckApp.SlotID = this.selectedNodeID
  //             CheckApp.AppDisplayMessage = address.SlotMessage[1].AppDisplayMessage

  //             fetchReturnSlotRecord.SlotMessage[indexMain] = CheckApp
  //           }
  //         } else {
  //           fetchGeneralRecord.ReturnSlotBasedOnInput = fetchGeneralRecord.ReturnSlotBasedOnInput.concat(this.validUserInputArr)
  //         }
  //       }
  //     }

  //     // fetchGeneralRecord.SlotMessage[0].DisplayMsg = this.WebDisplayMsg
  //     // fetchGeneralRecord.SlotMessage[1].DisplayMsg = this.appDisplayMsg
  //     // fetchGeneralRecord.ErrorMsg = this.webErrorMsg
  //     // fetchGeneralRecord.noOfAttemptsErrorMsg = this.noOfAttemptsErrorMsgWeb

  //     this.Slots.Slots[indexMain] == fetchGeneralRecord
  //   }

  //   // for (let address of this.validUserInputArr) {
  //   //   var fetchReturnSlot = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID).ReturnSlotBasedOnInput
  //   //   console.log(fetchReturnSlot)
  //   //   var fetchReturnSlotRecord = fetchReturnSlot.find((x: { ReturnName: string; }) => x.ReturnName == address.ReturnName)

  //   //   var fetchSingleSlotRecord = fetchReturnSlot.find((x: { UserInput: string; }) => x.UserInput == "@All")
  //   //   console.log(fetchSingleSlotRecord)

  //   //   if (fetchReturnSlot) {
  //   //     if (fetchReturnSlotRecord) {
  //   //       // let indexMain = fetchReturnSlot.indexOf(fetchReturnSlotRecord);

  //   //       // fetchReturnSlotRecord.SlotID = this.selectedNodeID,
  //   //       //   fetchReturnSlotRecord.UserInput = this.userInputForm.controls.UserInput.value
  //   //       // fetchReturnSlotRecord.ReturnType = this.userInputForm.controls.ReturnType.value
  //   //       // fetchReturnSlotRecord.ReturnName = this.userInputForm.controls.ReturnName.value

  //   //       // fetchReturnSlot[indexMain] = fetchReturnSlotRecord

  //   //       var CheckWeb = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "Web")
  //   //       if (CheckWeb) {
  //   //         let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckWeb);

  //   //         CheckWeb.SlotID = this.selectedNodeID
  //   //         // CheckWeb.MainMsg = this.userInputForm.controls.webDispMsg.value

  //   //         CheckWeb.PreMsg = address.SlotMessage[0].PreMsg
  //   //         CheckWeb.PreMsgType = address.SlotMessage[0].PreMsgType
  //   //         CheckWeb.MainMsg = address.SlotMessage[0].MainMsg
  //   //         CheckWeb.WebDisplayMessage = address.SlotMessage[0].WebDisplayMessage
  //   //         CheckWeb.ResponseType = address.SlotMessage[0].ResponseType
  //   //         CheckWeb.PostMsg = address.SlotMessage[0].PostMsg
  //   //         CheckWeb.PostMsgType = address.SlotMessage[0].PostMsgType

  //   //         fetchReturnSlotRecord.SlotMessage[indexMain] = CheckWeb
  //   //       }

  //   //       var CheckApp = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "App")
  //   //       if (CheckApp) {
  //   //         let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckApp);

  //   //         CheckApp.SlotID = this.selectedNodeID
  //   //         // CheckApp.MainMsg = this.userInputForm.controls.appDispMsg.value

  //   //         CheckApp.PreMsg = address.SlotMessage[1].PreMsg
  //   //         CheckApp.PreMsgType = address.SlotMessage[1].PreMsgType
  //   //         CheckApp.MainMsg = address.SlotMessage[1].MainMsg
  //   //         CheckApp.AppDisplayMessage = address.SlotMessage[1].AppDisplayMessage
  //   //         CheckApp.ResponseType = address.SlotMessage[1].ResponseType
  //   //         CheckApp.PostMsg = address.SlotMessage[1].PostMsg
  //   //         CheckApp.PostMsgType = address.SlotMessage[1].PostMsgType

  //   //         fetchReturnSlotRecord.SlotMessage[indexMain] = CheckApp
  //   //       }
  //   //     } else if (fetchSingleSlotRecord) {

  //   //       var CheckWeb = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "Web")
  //   //       if (CheckWeb) {
  //   //         let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckWeb);

  //   //         CheckWeb.SlotID = this.selectedNodeID
  //   //         // CheckWeb.MainMsg = this.userInputForm.controls.webDispMsg.value

  //   //         CheckWeb.PreMsg = address.SlotMessage[0].PreMsg
  //   //         CheckWeb.PreMsgType = address.SlotMessage[0].PreMsgType
  //   //         CheckWeb.MainMsg = address.SlotMessage[0].MainMsg
  //   //         CheckWeb.WebDisplayMessage = address.SlotMessage[0].WebDisplayMessage
  //   //         CheckWeb.ResponseType = address.SlotMessage[0].ResponseType
  //   //         CheckWeb.PostMsg = address.SlotMessage[0].PostMsg
  //   //         CheckWeb.PostMsgType = address.SlotMessage[0].PostMsgType

  //   //         fetchReturnSlotRecord.SlotMessage[indexMain] = CheckWeb
  //   //       }

  //   //       var CheckApp = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "App")
  //   //       if (CheckApp) {
  //   //         let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckApp);

  //   //         CheckApp.SlotID = this.selectedNodeID
  //   //         // CheckApp.MainMsg = this.userInputForm.controls.appDispMsg.value

  //   //         CheckApp.PreMsg = address.SlotMessage[1].PreMsg
  //   //         CheckApp.PreMsgType = address.SlotMessage[1].PreMsgType
  //   //         CheckApp.MainMsg = address.SlotMessage[1].MainMsg
  //   //         CheckApp.AppDisplayMessage = address.SlotMessage[1].AppDisplayMessage
  //   //         CheckApp.ResponseType = address.SlotMessage[1].ResponseType
  //   //         CheckApp.PostMsg = address.SlotMessage[1].PostMsg
  //   //         CheckApp.PostMsgType = address.SlotMessage[1].PostMsgType

  //   //         fetchReturnSlotRecord.SlotMessage[indexMain] = CheckApp
  //   //       }
  //   //     }
  //   //   }
  //   // }
  //   console.log("slot array in For NLP team.......", this.Slots)
  //   this.saveFlowChartOnpropertyBlock();
  //   // var fetchRecord = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   // console.log(fetchRecord)
  //   // if (fetchRecord) {
  //   //   fetchRecord.ReturnSlotBasedOnInput = fetchRecord.ReturnSlotBasedOnInput.concat(this.validUserInputArr)
  //   // }
  //   // console.log("slot array in general tab is", this.Slots)
  //   // this.isPropertyBlock = false
  //   // console.log("slot array in web tab is", this.Slots)
  //   // else {
  //   //   const mainGeneralSlotObject = {
  //   //     SlotID: this.selectedNodeID,
  //   //     SlotName: this.SlotName,
  //   //     SlotOrder: this.SlotOrder,
  //   //     SlotValues: this.SlotValues,
  //   //     NoOfAttemptsAllowed: this.NumberOfAttemptsAllowed,
  //   //     SlotMessage: [
  //   //       {
  //   //         SlotID: this.selectedNodeID,
  //   //         AppType: "Web",
  //   //         PreMsg: "",
  //   //         PreMsgType: "",
  //   //         mainmsg: this.WebDisplayMsg,
  //   //         displaymsg: this.WebDisplayMsg,
  //   //         ResponseType: "Text",

  //   //         PostMsg: "",
  //   //         PostMsgType: ""
  //   //       },
  //   //       {
  //   //         SlotID: this.selectedNodeID,
  //   //         AppType: "App",
  //   //         PreMsg: "",
  //   //         PreMsgType: "",
  //   //         mainmsg: this.appDisplayMsg,
  //   //         displaymsg: this.appDisplayMsg,
  //   //         ResponseType: "Text",

  //   //         PostMsg: "",
  //   //         PostMsgType: ""
  //   //       }
  //   //     ],
  //   //     ReturnSlotBasedOnInput: [
  //   //       {
  //   //         SlotID: this.selectedNodeID,
  //   //         UserInput: "Error",
  //   //         ReturnName: this.selectedNodeName,
  //   //         ReturnType: "Slot",
  //   //         ReturnParams: {},
  //   //         CustomCSMethod: "",
  //   //         APIEndPointDetails: [],
  //   //         SlotMessage: [
  //   //           {
  //   //             SlotID: this.selectedNodeID,
  //   //             AppType: "Web",
  //   //             PreMsg: "",
  //   //             PreMsgType: "",
  //   //             MainMsg: "Please select valid account type.",
  //   //             webDispMsg: this.webErrorMsg,
  //   //             ResponseType: "Text",
  //   //             PostMsg: "",
  //   //             PostMsgType: ""
  //   //           },
  //   //           {
  //   //             SlotID: this.selectedNodeID,
  //   //             AppType: "App",
  //   //             PreMsg: "",
  //   //             PreMsgType: "",
  //   //             MainMsg: "Please select valid account type.",
  //   //             appDispMsg: this.appErrorMsg,
  //   //             ResponseType: "Text",
  //   //             PostMsg: "",
  //   //             PostMsgType: ""
  //   //           }
  //   //         ]
  //   //       },
  //   //       {
  //   //         SlotID: this.selectedNodeID,
  //   //         UserInput: "tooManyAttempts",
  //   //         ReturnName: "tooManyAttempts",
  //   //         ReturnType: "Slot",
  //   //         ReturnParams: {},
  //   //         CustomCSMethod: "",
  //   //         APIEndPointDetails: [],
  //   //         SlotMessage: [
  //   //           {
  //   //             SlotID: this.selectedNodeID,
  //   //             AppType: "Web",
  //   //             PreMsg: "",
  //   //             PreMsgType: "",
  //   //             MainMsg: "You have exceeded maximum limit of entering an invalid email id. Please Start Over again.",
  //   //             webDispMsg: this.noOfAttemptsErrorMsgWeb,
  //   //             ResponseType: "Text",
  //   //             PostMsg: "",
  //   //             PostMsgType: ""
  //   //           },
  //   //           {
  //   //             SlotID: this.selectedNodeID,
  //   //             AppType: "App",
  //   //             PreMsg: "",
  //   //             PreMsgType: "",
  //   //             MainMsg: "You have exceeded maximum limit of entering an invalid email id. Please Start Over again.",
  //   //             appDispMsg: this.noOfAttemptsErrorMsgApp,
  //   //             ResponseType: "Text",
  //   //             PostMsg: "",
  //   //             PostMsgType: ""
  //   //           }
  //   //         ]
  //   //       }
  //   //     ],
  //   //     SlotDataType: "",
  //   //     DataValidation: "",
  //   //     DataFormat: "",
  //   //     InputFormat: "",
  //   //     NoOfAttemptsOver: "",
  //   //     // ReturnSlotBasedOnInput: {
  //   //     //   "SlotMessage": []
  //   //     // }
  //   //   }
  //   //   this.Slots.Slots.push(mainGeneralSlotObject)

  //   //   var fetchRecord = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   //   console.log(fetchRecord)
  //   //   if (fetchRecord) {
  //   //     fetchRecord.ReturnSlotBasedOnInput = fetchRecord.ReturnSlotBasedOnInput.concat(this.validUserInputArr)
  //   //   }
  //   //   console.log("slot array in general tab is", this.Slots)
  //   //   this.isPropertyBlock = false
  //   // }
  // }

  addValidUserInput() {
    
    this.validUserInputArr = []

    // if (this.nextSlotName) {
    //   if (this.nextSlotName.length == 0) {
    //     this.UserInput = "@All"
    //     this.ReturnName = ""
    //     const data = {
    //       id: this.validUserInputArr.length + 1,
    //       SlotID: this.selectedNodeID,
    //       UserInput: this.UserInput,
    //       ReturnName: this.ReturnName,
    //       ReturnType: "Slot",
    //       ReturnParams: {},
    //       CustomCSMethod: "",
    //       APIEndPointDetails: [],
    //       Override: "",
    //       SlotMessage: [
    //         {
    //           SlotID: this.selectedNodeID,
    //           AppType: "Web",
    //           PreMsg: "",
    //           PreMsgType: "",
    //           MainMsg: "",
    //           DisplayMessage: '',
    //           ResponseType: "",
    //           PostMsg: "",
    //           PostMsgType: ""
    //         },
    //         {
    //           SlotID: this.selectedNodeID,
    //           AppType: "App",
    //           PreMsg: "",
    //           PreMsgType: "",
    //           MainMsg: "",
    //           DisplayMessage: '',
    //           ResponseType: "",
    //           PostMsg: "",
    //           PostMsgType: ""
    //         }
    //       ]
    //     }
    //     this.validUserInputArr.push(data);
    //     // this.validUserInputArr.push(this.formBuilder.control(data));
    //     console.log(this.validUserInputArr)
    //   } else if (this.nextSlotName.length == 1) {
    //     this.UserInput = "@All"
    //     this.ReturnName = this.nextSlotName[0]
    //     const data = {
    //       id: this.validUserInputArr.length + 1,
    //       SlotID: this.selectedNodeID,
    //       UserInput: this.UserInput,
    //       ReturnName: this.ReturnName,
    //       ReturnType: "Slot",
    //       ReturnParams: {},
    //       CustomCSMethod: "",
    //       APIEndPointDetails: [],
    //       Override: "",
    //       SlotMessage: [
    //         {
    //           SlotID: this.selectedNodeID,
    //           AppType: "Web",
    //           PreMsg: "",
    //           PreMsgType: "",
    //           MainMsg: "",
    //           DisplayMessage: '',
    //           ResponseType: "",
    //           PostMsg: "",
    //           PostMsgType: ""
    //         },
    //         {
    //           SlotID: this.selectedNodeID,
    //           AppType: "App",
    //           PreMsg: "",
    //           PreMsgType: "",
    //           MainMsg: "",
    //           DisplayMessage: '',
    //           ResponseType: "",
    //           PostMsg: "",
    //           PostMsgType: ""
    //         }
    //       ]
    //     }
    //     this.validUserInputArr.push(data);
    //     // this.validUserInputArr.push(this.formBuilder.control(data));
    //     console.log(this.validUserInputArr)
    //   } else if (this.nextSlotName.length > 1) {
    //     for (let i = 0; i < this.nextSlotName.length; i++) {
    //       this.UserInput = this.selectedNodeName
    //       this.ReturnName = this.nextSlotName[i]
    //       const data = {
    //         id: this.validUserInputArr.length + 1,
    //         SlotID: this.selectedNodeID,
    //         UserInput: this.UserInput,
    //         ReturnName: this.ReturnName,
    //         ReturnType: "Slot",
    //         ReturnParams: {},
    //         CustomCSMethod: "",
    //         APIEndPointDetails: [],
    //         Override: "",
    //         SlotMessage: [
    //           {
    //             SlotID: this.selectedNodeID,
    //             AppType: "Web",
    //             PreMsg: "",
    //             PreMsgType: "",
    //             MainMsg: "",
    //             DisplayMessage: '',
    //             ResponseType: "",
    //             PostMsg: "",
    //             PostMsgType: ""
    //           },
    //           {
    //             SlotID: this.selectedNodeID,
    //             AppType: "App",
    //             PreMsg: "",
    //             PreMsgType: "",
    //             MainMsg: "",
    //             DisplayMessage: '',
    //             ResponseType: "",
    //             PostMsg: "",
    //             PostMsgType: ""
    //           }
    //         ]
    //       }
    //       this.validUserInputArr.push(data);
    //       // this.validUserInputArr.push(this.formBuilder.control(data));
    //       console.log(this.validUserInputArr)
    //     }
    //   }
    // }

    // if (this.SlotValues.length > 0) {
    //   for (let i: any = 0; i < this.SlotValues.length; i++) {
    //     this.validUserInputData = new conDesignEntities()

    //     this.validUserInputData.validUserInputEntity.UserInput = this.SlotValues[i]
    //     this.validUserInputData.validUserInputEntity.id = i + 1
    //     this.validUserInputData.validUserInputEntity.ReturnName = ""
    //     this.validUserInputData.validUserInputEntity.SlotID = this.selectedNodeID
    //     this.validUserInputData.validUserInputEntity.SlotMessage[0].SlotID = this.selectedNodeID
    //     this.validUserInputData.validUserInputEntity.SlotMessage[1].SlotID = this.selectedNodeID

    //     this.validUserInputArr.push(this.validUserInputData.validUserInputEntity);
    //   }
    //   // this.validUserInputArr.push(this.formBuilder.control(data));
    //   console.log(this.validUserInputArr)
    // }
  }
  ValidUserInputToSameSlot: any = []

  // addValidUserInputToSameSlot() {
  //   
  //   this.data = new conDesignEntities()
  //   // this.ValidUserInputToSameSlot = []
  //   var fetchExistingRecord = this.ValidUserInputToSameSlot.find((x: { ReturnName: string; }) => x.ReturnName == this.singleSlotValue)
  //   if (!fetchExistingRecord) {
  //     if (this.SlotValues.length > 0) {
  //       this.data.ReturnToSameSlotEntity.UserInput = "@All"
  //       this.data.ReturnToSameSlotEntity.id = this.ValidUserInputToSameSlot.length + 1
  //       this.data.ReturnToSameSlotEntity.ReturnName = this.singleSlotValue
  //       this.data.ReturnToSameSlotEntity.SlotID = this.selectedNodeID
  //       this.data.ReturnToSameSlotEntity.ReturnSlotType = this.fetchReturnSlotRadioButton
  //       this.data.ReturnToSameSlotEntity.SlotMessage[0].SlotID = this.selectedNodeID
  //       this.data.ReturnToSameSlotEntity.SlotMessage[1].SlotID = this.selectedNodeID
  //     }
  //   }
  //   this.ValidUserInputToSameSlot.push(this.data.ReturnToSameSlotEntity);
  // }

  checkMandatoryfields() {
    if (this.SlotName = "" || this.SlotName || this.SlotOrder || this.WebDisplayMsg || this.appDisplayMsg || this.SlotValues || this.NumberOfAttemptsAllowed
      || this.webErrorMsg || this.appErrorMsg || this.noOfAttemptsErrorMsgWeb || this.noOfAttemptsErrorMsgApp)
      this._isbuttonDisable = false
  }

  cancelBtn() {
    this.isPropertyBlock = false
  }
  closePropertyBlock() {
    this.isPropertyBlock = false
  }

  addDataAsSlotValueArr(event: any) {
    
    if (event !== "" || event !== "undefined") {
      this._SlotObj.SlotValues = event.split(',');
      // for (var key in this.SlotValues) {
      //   if (this.SlotValues[key] === "") {
      //     delete this.SlotValues[key]
      //   }
      // }
      this._SlotObj.SlotValues = this._SlotObj.SlotValues.filter((x: string) => x != "")
    }
  }

  GenarteReturnSLotArrasync(vReturnslot?: any) {
    
    if (this._SlotObj.SlotValues.length == 0) {

      if (this.nextSlotName) {
        var _ngModelReturnSlotBasedOnInput = new ngModelReturnSlotBasedOnInput();
        _ngModelReturnSlotBasedOnInput.UserInput = "@All"
        _ngModelReturnSlotBasedOnInput.id = 1
        _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"

        var checkRetrunSlotExist = vReturnslot.find((x: { UserInput: string; }) => x.UserInput == "@All")
        if (!checkRetrunSlotExist) {
          
          this._ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);
        }
      }


      //for error
      var _ngModelReturnSlotBasedOnInput = new ngModelReturnSlotBasedOnInput();
      _ngModelReturnSlotBasedOnInput.UserInput = "Error"
      _ngModelReturnSlotBasedOnInput.id = 2
      _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"
      _ngModelReturnSlotBasedOnInput.ReturnName = this.selectedNodeName

      var checkRetrunSlotExist = vReturnslot.find((x: { UserInput: string; }) => x.UserInput == "Error")
      if (!checkRetrunSlotExist) {
        
        this._ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);
      }


      //for toomany
      if (this._SlotObj.NoOfAttemptsAllowed > 1) {

        var _ngModelReturnSlotBasedOnInput = new ngModelReturnSlotBasedOnInput();
        _ngModelReturnSlotBasedOnInput.UserInput = "tooManyAttempts"
        _ngModelReturnSlotBasedOnInput.id = 3
        _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"
        _ngModelReturnSlotBasedOnInput.ReturnName = "tooManyAttempts"

        var checkRetrunSlotExist = vReturnslot.find((x: { UserInput: string; }) => x.UserInput == "tooManyAttempts")
        if (!checkRetrunSlotExist) {
          
          this._ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);

        }
      }
      else {
        var checkRetrunSlotExist = vReturnslot.find((x: { UserInput: string; }) => x.UserInput == "tooManyAttempts")
        if (checkRetrunSlotExist) {
          
          const index = this._ngModelReturnSlotBasedOnInputArr.indexOf(checkRetrunSlotExist);
          if (index !== -1) {
            this._ngModelReturnSlotBasedOnInputArr.splice(index, 1);
          }
        }
      }

      
      this._ngModelReturnSlotBasedOnInputArr.forEach((vals: any, id: any) => {
        
        if (vals.UserInput.toUpperCase() == '@ALL' || vals.UserInput.toUpperCase() == 'ERROR' || vals.UserInput.toUpperCase() == 'TOOMANYATTEMPTS') {

        }
        else {
          this._ngModelReturnSlotBasedOnInputArr.splice(id, 1)
        }
      })

      

      this.SortRetrunSlotArray();
    }
    else {

      


      this._SlotObj.SlotValues.forEach((value: any, id: any) => {
        
        var cnt = 1
        var _ngModelReturnSlotBasedOnInput = new ngModelReturnSlotBasedOnInput();

        //add slots that define in slotValues
        _ngModelReturnSlotBasedOnInput.UserInput = value.trim()
        _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"
        _ngModelReturnSlotBasedOnInput.id = cnt

        var checkRetrunSlotExist = vReturnslot.find((x: { UserInput: string; }) => x.UserInput == value.trim())
        if (!checkRetrunSlotExist) {
          
          this._ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);
        }
        cnt++;
      })

      //delete All from ng array
      var checkRetrunSlotExistAll: any = this._ngModelReturnSlotBasedOnInputArr.find((x: { UserInput: string; }) => x.UserInput == "@All")
      if (checkRetrunSlotExistAll) {
        // const indexMainArray = this._ConvFlowEntityObj.Slots.indexOf(checkRetrunSlotExist);
        const index = this._ngModelReturnSlotBasedOnInputArr.indexOf(checkRetrunSlotExistAll);
        if (index !== -1) {
          this._ngModelReturnSlotBasedOnInputArr.splice(index, 1);
        }
      }

      //for error
      var _ngModelReturnSlotBasedOnInput = new ngModelReturnSlotBasedOnInput();
      _ngModelReturnSlotBasedOnInput.UserInput = "Error"
      _ngModelReturnSlotBasedOnInput.ReturnName = this.selectedNodeName
      _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"
      _ngModelReturnSlotBasedOnInput.id = 1

      var checkRetrunSlotExist = vReturnslot.find((x: { UserInput: string; }) => x.UserInput == "Error")
      if (!checkRetrunSlotExist) {
        
        this._ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);
      }


      //for toomany
      if (this._SlotObj.NoOfAttemptsAllowed > 1) {

        var _ngModelReturnSlotBasedOnInput = new ngModelReturnSlotBasedOnInput();
        _ngModelReturnSlotBasedOnInput.UserInput = "tooManyAttempts"
        _ngModelReturnSlotBasedOnInput.ReturnName = "tooManyAttempts"
        _ngModelReturnSlotBasedOnInput.ReturnType = "Slot"
        _ngModelReturnSlotBasedOnInput.id = 2

        var checkRetrunSlotExist = vReturnslot.find((x: { UserInput: string; }) => x.UserInput == "tooManyAttempts")
        if (!checkRetrunSlotExist) {
          
          this._ngModelReturnSlotBasedOnInputArr.push(_ngModelReturnSlotBasedOnInput);
        }
      }
      

      this.SortRetrunSlotArray();
    }
  }

  SortRetrunSlotArray(): void {
    
    let stOrder: any = [];
    stOrder = this._ngModelReturnSlotBasedOnInputArr;
    this._ngModelReturnSlotBasedOnInputArr = [];
    var cnt = 1;

    // stOrder.forEach((value: any, key: any) => {
    this._SlotObj.SlotValues.forEach((value: any, id: any) => {

      
      var checkRetrunSlotExist = stOrder.find((x: { UserInput: string; }) => x.UserInput == value.trim());

      if (checkRetrunSlotExist) {
        checkRetrunSlotExist.id = cnt;
        this._ngModelReturnSlotBasedOnInputArr.push(checkRetrunSlotExist);
      }

      cnt++;
    });

    var checkRetrunSlotExist = stOrder.find((x: { UserInput: string; }) => x.UserInput == "@All");

    if (checkRetrunSlotExist) {
      checkRetrunSlotExist.id = cnt++;
      this._ngModelReturnSlotBasedOnInputArr.push(checkRetrunSlotExist);
    }

    var checkRetrunSlotExist = stOrder.find((x: { UserInput: string; }) => x.UserInput == "Error");

    if (checkRetrunSlotExist) {
      checkRetrunSlotExist.id = cnt++;
      this._ngModelReturnSlotBasedOnInputArr.push(checkRetrunSlotExist);
    }

    var checkRetrunSlotExist = stOrder.find((x: { UserInput: string; }) => x.UserInput == "tooManyAttempts");

    if (checkRetrunSlotExist) {
      checkRetrunSlotExist.id = cnt++;
      this._ngModelReturnSlotBasedOnInputArr.push(checkRetrunSlotExist);
    }

  }

  //Save the main form data when role is Admin
  mainFormSubmitforAdmin(data: any, vGeneralSlotObject: any, vSlotmsg: any, vReturnslot?: any) {
    
    this._isPropertyLoader = true

    this._ConvFlowEntityObj.IntentName = this._selectedRowData.IntentName;
    this._ConvFlowEntityObj.PrimaryQuestion = this._selectedRowData.Questions;
    this._ConvFlowEntityObj.UseCaseName = this._selectedRowData.SectionName;

    var slotObj = new Slot();

    var checkSlotExists = this._ConvFlowEntityObj.Slots.find((x: { SlotName: string; }) => x.SlotName == this.selectedNodeName)

    slotObj.SlotName = vGeneralSlotObject.SlotName;
    slotObj.SlotType = vGeneralSlotObject.SlotType;
    slotObj.SlotOrder = Number(vGeneralSlotObject.SlotOrder)

    var slotmessageWebObj = new Slotmessage();
    slotmessageWebObj.AppType = vSlotmsg.webAppType == undefined ? "" : vSlotmsg.webAppType;
    slotmessageWebObj.PreMsg = vSlotmsg.webPreMsg == undefined ? "" : vSlotmsg.webPreMsg;
    slotmessageWebObj.PreMsgType = vSlotmsg.webPreMsgType == undefined ? "" : vSlotmsg.webPreMsgType;
    slotmessageWebObj.MainMsg = vSlotmsg.webMainMsg == undefined ? "" : vSlotmsg.webMainMsg;
    slotmessageWebObj.DisplayMessage = vSlotmsg.webDisplayMessage == undefined ? "" : vSlotmsg.webDisplayMessage;
    slotmessageWebObj.ResponseType = vSlotmsg.webResponseType == undefined ? "" : vSlotmsg.webResponseType;
    slotmessageWebObj.PostMsg = vSlotmsg.webPostMsg == undefined ? "" : vSlotmsg.webPostMsg;
    slotmessageWebObj.PostMsgType = vSlotmsg.webPostMsgType == undefined ? "" : vSlotmsg.webPostMsgType;
    slotObj.Slotmessage.push(slotmessageWebObj);

    var slotmessageAppObj = new Slotmessage();
    slotmessageAppObj.AppType = vSlotmsg.appAppType == undefined ? "" : vSlotmsg.appAppType;
    slotmessageAppObj.PreMsg = vSlotmsg.appPreMsg == undefined ? "" : vSlotmsg.appPreMsg;
    slotmessageAppObj.PreMsgType = vSlotmsg.appPreMsgType == undefined ? "" : vSlotmsg.appPreMsgType;
    slotmessageAppObj.MainMsg = vSlotmsg.appMainMsg == undefined ? "" : vSlotmsg.appMainMsg;
    slotmessageAppObj.DisplayMessage = vSlotmsg.appDisplayMessage == undefined ? "" : vSlotmsg.appDisplayMessage;
    slotmessageAppObj.ResponseType = vSlotmsg.appResponseType == undefined ? "" : vSlotmsg.appResponseType;
    slotmessageAppObj.PostMsg = vSlotmsg.appPostMsg == undefined ? "" : vSlotmsg.appPostMsg;
    slotmessageAppObj.PostMsgType = vSlotmsg.appPostMsgType == undefined ? "" : vSlotmsg.appPostMsgType;
    slotObj.Slotmessage.push(slotmessageAppObj);

    slotObj.SlotValues = vGeneralSlotObject.SlotValues;
    slotObj.SlotDataType = vGeneralSlotObject.SlotDataType;
    slotObj.DataValidation = vGeneralSlotObject.DataValidation;

    slotObj.DataFormat = this._selectedDataFormat.regex;;
    slotObj.InputFormat = vGeneralSlotObject.InputFormat;
    slotObj.NoOfAttemptsAllowed = vGeneralSlotObject.NoOfAttemptsAllowed;
    slotObj.NoOfAttemptsOver = vGeneralSlotObject.NoOfAttemptsOver ? vGeneralSlotObject.NoOfAttemptsOver : 0;

    slotObj.AwaitMsg = vGeneralSlotObject.AwaitMsg;

    vReturnslot.forEach((value: any, id: any) => {
      
      var returnSlotBasedOnInputObj = new ReturnSlotBasedOnInput();

      returnSlotBasedOnInputObj.UserInput = value.UserInput;
      returnSlotBasedOnInputObj.ReturnType = 'Slots';
      returnSlotBasedOnInputObj.ReturnName = value.ReturnName;
      returnSlotBasedOnInputObj.Returnparams = new Returnparams();
      returnSlotBasedOnInputObj.CustomCSMethod = value.CustomCSMethod;
      returnSlotBasedOnInputObj.Override = value.Override == undefined ? "" : value.Override;
      returnSlotBasedOnInputObj.APIEndPointDetails = [];

      var slotmessageWebObj = new Slotmessage();
      slotmessageWebObj.AppType = value.Slotmessage.webAppType == undefined ? "" : value.Slotmessage.webAppType;
      slotmessageWebObj.PreMsg = value.Slotmessage.webPreMsg == undefined ? "" : value.Slotmessage.webPreMsg;
      slotmessageWebObj.PreMsgType = value.Slotmessage.webPreMsgType == undefined ? "" : value.Slotmessage.webPreMsgType;
      slotmessageWebObj.MainMsg = value.Slotmessage.webMainMsg == undefined ? "" : value.Slotmessage.webMainMsg;
      slotmessageWebObj.DisplayMessage = value.Slotmessage.webDisplayMessage == undefined ? "" : value.Slotmessage.webDisplayMessage;
      slotmessageWebObj.ResponseType = value.Slotmessage.webResponseType == undefined ? "" : value.Slotmessage.webResponseType;
      slotmessageWebObj.PostMsg = value.Slotmessage.webPostMsg == undefined ? "" : value.Slotmessage.webPostMsg;
      slotmessageWebObj.PostMsgType = value.Slotmessage.webPostMsgType == undefined ? "" : value.Slotmessage.webPostMsgType;
      returnSlotBasedOnInputObj.Slotmessage.push(slotmessageWebObj);

      var slotmessageAppObj = new Slotmessage();
      slotmessageAppObj.AppType = value.Slotmessage.appAppType == undefined ? "" : value.Slotmessage.appAppType;
      slotmessageAppObj.PreMsg = value.Slotmessage.appPreMsg == undefined ? "" : value.Slotmessage.appPreMsg;
      slotmessageAppObj.PreMsgType = value.Slotmessage.appPreMsgType == undefined ? "" : value.Slotmessage.appPreMsgType;
      slotmessageAppObj.MainMsg = value.Slotmessage.appMainMsg == undefined ? "" : value.Slotmessage.appMainMsg;
      slotmessageAppObj.DisplayMessage = value.Slotmessage.appDisplayMessage == undefined ? "" : value.Slotmessage.appDisplayMessage;
      slotmessageAppObj.ResponseType = value.Slotmessage.appResponseType == undefined ? "" : value.Slotmessage.appResponseType;
      slotmessageAppObj.PostMsg = value.Slotmessage.appPostMsg == undefined ? "" : value.Slotmessage.appPostMsg;
      slotmessageAppObj.PostMsgType = value.Slotmessage.appPostMsgType == undefined ? "" : value.Slotmessage.appPostMsgType;
      returnSlotBasedOnInputObj.Slotmessage.push(slotmessageAppObj);

      slotObj.Returnslotbasedoninput.push(returnSlotBasedOnInputObj);

    });

    slotObj.SavedUserInput = vGeneralSlotObject.SavedUserInput == undefined ? "" : vGeneralSlotObject.SavedUserInput;
    slotObj.SaveIntoDB = vGeneralSlotObject.SaveIntoDB == undefined ? "" : vGeneralSlotObject.SaveIntoDB;

    slotObj.UserInputBox = vGeneralSlotObject.UserInputBox;
    slotObj.Enablefield = vGeneralSlotObject.Enablefield == undefined ? "" : vGeneralSlotObject.Enablefield;

    
    if (this.selectedNodeName.toUpperCase() == 'END' || this.selectedNodeName.toUpperCase() == 'ENDOFSLOTS') {
      slotObj.EndOfConv = true;
    }
    else {
      slotObj.EndOfConv = false;
    }

    
    if (checkSlotExists) {
      const index = this._ConvFlowEntityObj.Slots.indexOf(checkSlotExists);
      if (index !== -1) {
        this._ConvFlowEntityObj.Slots.splice(index, 1);
      }
    }

    this._ConvFlowEntityObj.Slots.push(slotObj);

    
    this._ConvFlowEntityObj.Slots.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return a.SlotOrder - b.SlotOrder;
    });


    this.saveToDB(this._ConvFlowEntityObj);

  }


  saveToDB(_ConvFlowEntityObj: ConvFlowEntity) {
    
    let flowdata = JSON.stringify(_ConvFlowEntityObj)
    let diagramData = JSON.stringify(this.diagramArr)

    var slotname = this._ConvFlowEntityObj.Slots.find((x: { SlotOrder: number; }) => x.SlotOrder == 1)?.SlotName;

    const data = {
      SlotName: slotname,
      IntentName: this._selectedRowData.IntentName,
      ConversationalFlowJSON: flowdata,
      FlowDiagramJSON: diagramData
    }

    this.conversationFlowService.saveConversationFlowDiagram(data).subscribe((res: any) => {
      
      if (res.Status.toUpperCase() == "SUCCESS") {
        this._disablePublishBtn = false
        this._isPropertyLoader = false
        this._isSaveDiagramLoader = false
      } else if (res.Error) {
      }
    })
  }


  // filterValidUserInputArr: any = []
  // // Save the return slot data when role is Admin
  // returnSlotSubmit(data: any) {
  //   if (data == 'errorSlot') {
  //     
  //     this._isPropertyLoader = true

  //     var fetchReturnSlot = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID).ReturnSlotBasedOnInput
  //     console.log(fetchReturnSlot)

  //     var fetchReturnSlotRecord = fetchReturnSlot.find((x: { UserInput: string; }) => x.UserInput == "Error")
  //     // console.log(d)

  //     let sameID = fetchReturnSlot.find((x: { SlotID: string; }) => x.SlotID == fetchReturnSlotRecord.SlotID);   //Need to discuss the flow
  //     if (sameID) {
  //       fetchReturnSlot = fetchReturnSlot.filter((x: { SlotID: string; }) => x.SlotID != fetchReturnSlotRecord.SlotID)
  //     }

  //     if (fetchReturnSlotRecord) {
  //       let indexMain = fetchReturnSlot.indexOf(fetchReturnSlotRecord);

  //       fetchReturnSlotRecord.SlotID = this.selectedNodeID,
  //         fetchReturnSlotRecord.UserInput = this.errorUserInput
  //       // fetchReturnSlotRecord.ReturnType = this.errorForm.controls.ReturnType.value
  //       fetchReturnSlotRecord.ReturnName = this.selectedNodeName

  //       fetchReturnSlot[indexMain] = fetchReturnSlotRecord

  //       var CheckWeb = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "Web")
  //       if (CheckWeb) {
  //         let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckWeb);

  //         CheckWeb.SlotID = this.selectedNodeID
  //         CheckWeb.PreMsg = this.errorWebPreMessage
  //         CheckWeb.PreMsgType = this.errorWebPreMessageType
  //         CheckWeb.MainMsg = this.errorWebMainMessage
  //         CheckWeb.DisplayMessage = this.webErrorMsg
  //         CheckWeb.ResponseType = this.errorWebResponseType
  //         CheckWeb.PostMsg = this.errorWebPostMessage
  //         CheckWeb.PostMsgType = this.errorWebPoseMessageType

  //         fetchReturnSlotRecord.SlotMessage[indexMain] = CheckWeb
  //       }

  //       var CheckApp = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "App")
  //       if (CheckApp) {
  //         let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckApp);

  //         CheckApp.SlotID = this.selectedNodeID
  //         CheckApp.MainMsg = this.appErrorMsg
  //         CheckApp.PreMsg = this.errorAppPreMessage
  //         CheckApp.PreMsgType = this.errorAppPreMessageType
  //         CheckApp.MainMsg = this.errorAppMainMessage
  //         CheckApp.DisplayMessage = this.appErrorMsg
  //         CheckApp.ResponseType = this.errorAppResponseType
  //         CheckApp.PostMsg = this.errorAppPostMessage
  //         CheckApp.PostMsgType = this.errorAppPoseMessageType

  //         fetchReturnSlotRecord.SlotMessage[indexMain] = CheckApp
  //       }
  //     }
  //     // else {
  //     //   const mainErrorSlotObject = {
  //     //     SlotID: this.selectedNodeID,
  //     //     UserInput: this.errorUserInput,
  //     //     ReturnType: "",
  //     //     ReturnName: this.selectedNodeName,
  //     //     SlotMessage: [
  //     //       {
  //     //         SlotID: this.selectedNodeID,
  //     //         AppType: this.WebAppType,
  //     //         PreMsg: this.errorWebPreMessage,
  //     //         PreMsgType: this.errorWebPreMessageType,
  //     //         MainMsg: this.errorWebMainMessage,
  //     //         webDispMsg: this.webErrorMsg,
  //     //         ResponseType: this.errorWebResponseType,
  //     //         PostMsg: this.errorWebPostMessage,
  //     //         PostMsgType: this.errorWebPoseMessageType
  //     //       },
  //     //       {
  //     //         SlotID: this.selectedNodeID,
  //     //         AppType: this.AppType,
  //     //         PreMsg: this.errorAppPreMessage,
  //     //         PreMsgType: this.errorAppPreMessageType,
  //     //         MainMsg: this.errorAppMainMessage,
  //     //         appDispMsg: this.appErrorMsg,
  //     //         ResponseType: this.errorAppResponseType,
  //     //         PostMsg: this.errorAppPostMessage,
  //     //         PostMsgType: this.errorAppPoseMessageType
  //     //       }
  //     //     ]
  //     //     // webDispMsg: this.errorForm.controls.webDispMsg.value,
  //     //     // appDispMsg: this.errorForm.controls.appDispMsg.value
  //     //   }
  //     //   var fetchSlotMessage = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //     //   console.log(fetchSlotMessage)
  //     //   fetchSlotMessage.ReturnSlotBasedOnInput.push(mainErrorSlotObject)
  //     // }
  //     console.log("slot array in error slot is", this.Slots)
  //     this.saveFlowChartOnpropertyBlock();

  //   } else if (data == 'tooManyAttempts') {
  //     
  //     this._isPropertyLoader = true

  //     var fetchReturnSlot = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID).ReturnSlotBasedOnInput
  //     console.log(fetchReturnSlot)

  //     var fetchReturnSlotRecord = fetchReturnSlot.find((x: { UserInput: string; }) => x.UserInput == "tooManyAttempts")
  //     // console.log(d)

  //     let sameID = fetchReturnSlot.find((x: { SlotID: string; }) => x.SlotID == fetchReturnSlotRecord.SlotID);   //Need to discuss the flow
  //     if (sameID) {
  //       fetchReturnSlot = fetchReturnSlot.filter((x: { SlotID: string; }) => x.SlotID != fetchReturnSlotRecord.SlotID)
  //     }

  //     // if (fetchReturnSlot) {
  //     
  //     // d.ReturnSlotBasedOnInput.push(mainTooManyAttemptsSlotObject)

  //     if (fetchReturnSlotRecord) {
  //       let indexMain = fetchReturnSlot.indexOf(fetchReturnSlotRecord);

  //       fetchReturnSlotRecord.SlotID = this.selectedNodeID,
  //         fetchReturnSlotRecord.UserInput = this.tooManyAttemptsUserInput
  //       fetchReturnSlotRecord.ReturnType = ""
  //       fetchReturnSlotRecord.ReturnName = this.tooManyAttemptsUserInput

  //       fetchReturnSlot[indexMain] = fetchReturnSlotRecord

  //       var CheckWeb = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "Web")
  //       if (CheckWeb) {
  //         let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckWeb);

  //         CheckWeb.SlotID = this.selectedNodeID
  //         CheckWeb.AppType = this.WebAppType
  //         CheckWeb.PreMsg = this.noOfAttemptsWebPreMessage
  //         CheckWeb.PreMsgType = this.noOfAttemptsWebPreMessageType
  //         CheckWeb.MainMsg = this.noOfAttemptsWebMainMessage
  //         CheckWeb.DisplayMessage = this.noOfAttemptsErrorMsgWeb
  //         CheckWeb.ResponseType = this.noOfAttemptsWebResponseType
  //         CheckWeb.PostMsg = this.noOfAttemptsWebPostMessage
  //         CheckWeb.PostMsgType = this.noOfAttemptsWebPoseMessageType

  //         fetchReturnSlotRecord.SlotMessage[indexMain] = CheckWeb
  //       }

  //       var CheckApp = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "App")
  //       if (CheckApp) {
  //         let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckApp);

  //         CheckApp.SlotID = this.selectedNodeID
  //         CheckApp.AppType = this.AppType
  //         CheckApp.PreMsg = this.noOfAttemptsAppPreMessage
  //         CheckApp.PreMsgType = this.noOfAttemptsAppPreMessageType
  //         CheckApp.MainMsg = this.noOfAttemptsAppMainMessage
  //         CheckApp.DisplayMessage = this.noOfAttemptsErrorMsgApp
  //         CheckApp.ResponseType = this.noOfAttemptsAppResponseType
  //         CheckApp.PostMsg = this.noOfAttemptsAppPostMessage
  //         CheckApp.PostMsgType = this.noOfAttemptsAppPoseMessageType

  //         fetchReturnSlotRecord.SlotMessage[indexMain] = CheckApp
  //       }
  //     }
  //     // else {
  //     //   const mainTooManyAttemptsSlotObject = {
  //     //     SlotID: this.selectedNodeID,
  //     //     UserInput: this.tooManyAttemptsUserInput,
  //     //     ReturnType: "",
  //     //     ReturnName: this.tooManyAttemptsUserInput,
  //     //     SlotMessage: [
  //     //       {
  //     //         SlotID: this.selectedNodeID,
  //     //         AppType: this.WebAppType,
  //     //         PreMsg: this.noOfAttemptsWebPreMessage,
  //     //         PreMsgType: this.noOfAttemptsWebPreMessageType,
  //     //         MainMsg: this.noOfAttemptsWebMainMessage,
  //     //         DisplayMessage: this.noOfAttemptsErrorMsgWeb,
  //     //         ResponseType: this.noOfAttemptsWebResponseType,
  //     //         PostMsg: this.noOfAttemptsWebPostMessage,
  //     //         PostMsgType: this.noOfAttemptsWebPoseMessageType
  //     //       },
  //     //       {
  //     //         SlotID: this.selectedNodeID,
  //     //         AppType: this.AppType,
  //     //         PreMsg: this.noOfAttemptsAppPreMessage,
  //     //         PreMsgType: this.noOfAttemptsAppPreMessageType,
  //     //         MainMsg: this.noOfAttemptsAppMainMessage,
  //     //         DisplayMessage: this.noOfAttemptsErrorMsgApp,
  //     //         ResponseType: this.noOfAttemptsAppResponseType,
  //     //         PostMsg: this.noOfAttemptsAppPostMessage,
  //     //         PostMsgType: this.noOfAttemptsAppPoseMessageType
  //     //       }
  //     //     ]
  //     //     // webDispMsg: this.errorForm.controls.webDispMsg.value,
  //     //     // appDispMsg: this.errorForm.controls.appDispMsg.value
  //     //   }
  //     //   var fetchSlotMessage = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //     //   console.log(fetchSlotMessage)
  //     //   fetchSlotMessage.ReturnSlotBasedOnInput.push(mainTooManyAttemptsSlotObject)

  //     // }
  //     console.log("slot array in toomanyattempts slot is", this.Slots)
  //     this.saveFlowChartOnpropertyBlock();

  //   } else if (data == 'userInput') {
  //     

  //     console.log(this.validUserInputArr)
  //     var d = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //     console.log(d)

  //     if (d) {
  //       // if (this.validUserInputArr > 0) {
  //       // var deltedRecord = d.ReturnSlotBasedOnInput.find((x: { id: string; }) => x.id == i)
  //       // if(i > 0) {
  //       let fetchRecord = '';
  //       this.filterValidUserInputArr = []
  //       // this.validUserInputArr = []
  //       // fetchRecord = this.validUserInputArr.find((x: { id: string; }) => x.id == this.validUserInputArri + 1);
  //       // this.filterValidUserInputArr.push(fetchRecord)
  //       d.ReturnSlotBasedOnInput = d.ReturnSlotBasedOnInput.concat(this.validUserInputArr)
  //       // }else {
  //       //   d.ReturnSlotBasedOnInput = d.ReturnSlotBasedOnInput.concat(this.validUserInputArr)
  //       // }
  //       // this.validUserInputArr.delete(deltedRecord)

  //       // }
  //     }

  //     console.log("user input arr.......", this.Slots)
  //     // var fetchReturnSlot = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID).ReturnSlotBasedOnInput
  //     // console.log(fetchReturnSlot)

  //     // var fetchReturnSlotRecord = fetchReturnSlot.find((x: { UserInput: string; }) => x.UserInput == "@All")

  //     // // if (fetchReturnSlot) {
  //     //   //
  //     //   // d.ReturnSlotBasedOnInput.push(mainUserInputSlotObject)

  //     //   if (fetchReturnSlotRecord) {
  //     //     let indexMain = fetchReturnSlot.indexOf(fetchReturnSlotRecord);

  //     //     fetchReturnSlotRecord.SlotID = this.selectedNodeID,
  //     //       fetchReturnSlotRecord.UserInput = this.UserInput
  //     //     fetchReturnSlotRecord.ReturnType = ""
  //     //     fetchReturnSlotRecord.ReturnName = this.ReturnName

  //     //     fetchReturnSlot[indexMain] = fetchReturnSlotRecord

  //     //     var CheckWeb = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "Web")
  //     //     if (CheckWeb) {
  //     //       let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckWeb);

  //     //       CheckWeb.SlotID = this.selectedNodeID
  //     //       CheckWeb.MainMsg = this.uiWebDisplayMsg

  //     //       fetchReturnSlotRecord.SlotMessage[indexMain] = CheckWeb
  //     //     }

  //     //     var CheckApp = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "App")
  //     //     if (CheckApp) {
  //     //       let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckApp);

  //     //       CheckApp.SlotID = this.selectedNodeID
  //     //       CheckApp.MainMsg = this.uiAppDisplayMsg

  //     //       fetchReturnSlotRecord.SlotMessage[indexMain] = CheckApp
  //     //     }
  //     //   }
  //     //  else {
  //     //   const mainUserInputSlotObject = {
  //     //     SlotID: this.selectedNodeID,
  //     //     UserInput: this.UserInput,
  //     //     ReturnType: "",
  //     //     ReturnName: this.ReturnName,
  //     //     SlotMessage: [
  //     //       {
  //     //         SlotID: this.selectedNodeID,
  //     //         AppType: this.WebAppType,
  //     //         PreMsg: this.uiWebPreMessage,
  //     //         PreMsgType: this.uiWebPreMessageType,
  //     //         MainMsg: this.uiWebMainMessage,
  //     //         webDispMsg: this.uiWebDisplayMsg,
  //     //         ResponseType: this.uiWebResponseType,
  //     //         PostMsg: this.uiWebPostMessage,
  //     //         PostMsgType: this.uiWebPoseMessageType
  //     //       },
  //     //       {
  //     //         SlotID: this.selectedNodeID,
  //     //         AppType: this.AppType,
  //     //         PreMsg: this.uiAppPreMessage,
  //     //         PreMsgType: this.uiAppPreMessageType,
  //     //         MainMsg: this.uiAppMainMessage,
  //     //         appDispMsg: this.uiAppDisplayMsg,
  //     //         ResponseType: this.uiAppResponseType,
  //     //         PostMsg: this.uiAppPostMessage,
  //     //         PostMsgType: this.uiAppPoseMessageType
  //     //       }
  //     //     ]
  //     //     // webDispMsg: this.errorForm.controls.webDispMsg.value,
  //     //     // appDispMsg: this.errorForm.controls.appDispMsg.value
  //     //   }
  //     //   var fetchSlotMessage = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //     //   console.log(fetchSlotMessage)
  //     //   fetchSlotMessage.ReturnSlotBasedOnInput.push(mainUserInputSlotObject)
  //     // }
  //     // console.log("slot array in user input slot is", this.Slots)
  //   }
  // }

  // returnSlotSubmitUI(fetchValue: any, i: any) {
  //   
  //   this._isPropertyLoader = true

  //   if (fetchValue == 'returnSlotbasedOnInput') {
  //     console.log(this.validUserInputArr)
  //     var d = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //     console.log(d)

  //     if (d) {
  //       // if (this.validUserInputArr > 0) {
  //       // var deltedRecord = d.ReturnSlotBasedOnInput.find((x: { id: string; }) => x.id == i)
  //       // if(i > 0) {
  //       for (let address of this.validUserInputArr) {
  //         var fetchReturnSlot = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID).ReturnSlotBasedOnInput
  //         console.log(fetchReturnSlot)
  //         var fetchReturnSlotRecord = fetchReturnSlot.find((x: { ReturnName: string; }) => x.ReturnName == address.ReturnName)

  //         var fetchSingleSlotRecord = fetchReturnSlot.find((x: { UserInput: string; }) => x.UserInput == "@All")
  //         console.log(fetchSingleSlotRecord)

  //         if (fetchReturnSlot) {
  //           if (fetchReturnSlotRecord) {
  //             // let indexMain = fetchReturnSlot.indexOf(fetchReturnSlotRecord);

  //             // fetchReturnSlotRecord.SlotID = this.selectedNodeID,
  //             //   fetchReturnSlotRecord.UserInput = this.userInputForm.controls.UserInput.value
  //             // fetchReturnSlotRecord.ReturnType = this.userInputForm.controls.ReturnType.value
  //             // fetchReturnSlotRecord.ReturnName = this.userInputForm.controls.ReturnName.value

  //             // fetchReturnSlot[indexMain] = fetchReturnSlotRecord

  //             var CheckWeb = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "Web")
  //             if (CheckWeb) {
  //               let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckWeb);

  //               CheckWeb.SlotID = this.selectedNodeID
  //               // CheckWeb.MainMsg = this.userInputForm.controls.webDispMsg.value

  //               CheckWeb.PreMsg = address.SlotMessage[0].PreMsg
  //               CheckWeb.PreMsgType = address.SlotMessage[0].PreMsgType
  //               CheckWeb.MainMsg = address.SlotMessage[0].MainMsg
  //               CheckWeb.DisplayMessage = address.SlotMessage[0].WebDisplayMessage
  //               CheckWeb.ResponseType = address.SlotMessage[0].ResponseType
  //               CheckWeb.PostMsg = address.SlotMessage[0].PostMsg
  //               CheckWeb.PostMsgType = address.SlotMessage[0].PostMsgType

  //               fetchReturnSlotRecord.SlotMessage[indexMain] = CheckWeb
  //             }

  //             var CheckApp = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "App")
  //             if (CheckApp) {
  //               let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckApp);

  //               CheckApp.SlotID = this.selectedNodeID
  //               // CheckApp.MainMsg = this.userInputForm.controls.appDispMsg.value

  //               CheckApp.PreMsg = address.SlotMessage[1].PreMsg
  //               CheckApp.PreMsgType = address.SlotMessage[1].PreMsgType
  //               CheckApp.MainMsg = address.SlotMessage[1].MainMsg
  //               CheckApp.DisplayMessage = address.SlotMessage[1].AppDisplayMessage
  //               CheckApp.ResponseType = address.SlotMessage[1].ResponseType
  //               CheckApp.PostMsg = address.SlotMessage[1].PostMsg
  //               CheckApp.PostMsgType = address.SlotMessage[1].PostMsgType

  //               fetchReturnSlotRecord.SlotMessage[indexMain] = CheckApp
  //             }
  //           } else if (fetchSingleSlotRecord) {

  //             var CheckWeb = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "Web")
  //             if (CheckWeb) {
  //               let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckWeb);

  //               CheckWeb.SlotID = this.selectedNodeID
  //               // CheckWeb.MainMsg = this.userInputForm.controls.webDispMsg.value

  //               CheckWeb.PreMsg = address.SlotMessage[0].PreMsg
  //               CheckWeb.PreMsgType = address.SlotMessage[0].PreMsgType
  //               CheckWeb.MainMsg = address.SlotMessage[0].MainMsg
  //               CheckWeb.DisplayMessage = address.SlotMessage[0].WebDisplayMessage
  //               CheckWeb.ResponseType = address.SlotMessage[0].ResponseType
  //               CheckWeb.PostMsg = address.SlotMessage[0].PostMsg
  //               CheckWeb.PostMsgType = address.SlotMessage[0].PostMsgType

  //               fetchReturnSlotRecord.SlotMessage[indexMain] = CheckWeb
  //             }

  //             var CheckApp = fetchReturnSlotRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "App")
  //             if (CheckApp) {
  //               let indexMain = fetchReturnSlotRecord.SlotMessage.indexOf(CheckApp);

  //               CheckApp.SlotID = this.selectedNodeID
  //               // CheckApp.MainMsg = this.userInputForm.controls.appDispMsg.value

  //               CheckApp.PreMsg = address.SlotMessage[1].PreMsg
  //               CheckApp.PreMsgType = address.SlotMessage[1].PreMsgType
  //               CheckApp.MainMsg = address.SlotMessage[1].MainMsg
  //               CheckApp.DisplayMessage = address.SlotMessage[1].AppDisplayMessage
  //               CheckApp.ResponseType = address.SlotMessage[1].ResponseType
  //               CheckApp.PostMsg = address.SlotMessage[1].PostMsg
  //               CheckApp.PostMsgType = address.SlotMessage[1].PostMsgType

  //               fetchReturnSlotRecord.SlotMessage[indexMain] = CheckApp
  //             }
  //           }
  //         }
  //       }
  //       let fetchRecord: any = '';
  //       this.filterValidUserInputArr = []
  //       // this.validUserInputArr = []
  //       fetchRecord = this.validUserInputArr.find((x: { id: string; }) => x.id == i + 1);
  //       // this.filterValidUserInputArr.push(fetchRecord)
  //       let sameID = d.ReturnSlotBasedOnInput.find((x: { id: string; }) => x.id == fetchRecord.id);   //Need to discuss the flow
  //       if (sameID) {
  //         d.ReturnSlotBasedOnInput = d.ReturnSlotBasedOnInput.filter((x: { id: string; }) => x.id != fetchRecord.id)
  //       }
  //       this.filterValidUserInputArr.push(fetchRecord)
  //       d.ReturnSlotBasedOnInput = this.filterValidUserInputArr.concat(d.ReturnSlotBasedOnInput)
  //       // }else {
  //       //   d.ReturnSlotBasedOnInput = d.ReturnSlotBasedOnInput.concat(this.validUserInputArr)
  //       // }
  //       // this.validUserInputArr.delete(deltedRecord)

  //       // }
  //     }
  //   }
  //   // else if (fetchValue == 'returnToSameslot') {

  //   //   

  //   //   console.log(this.ValidUserInputToSameSlot)
  //   //   var d = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   //   console.log(d)

  //   //   if (d) {

  //   //     for (let address of this.ValidUserInputToSameSlot) {
  //   //       var fetchRecord = this.ValidUserInputToSameSlot.find((x: { ReturnName: string; }) => x.ReturnName == address.ReturnName)
  //   //       console.log(fetchRecord)
  //   //       if (fetchRecord) {
  //   //         var CheckWeb = fetchRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "Web")
  //   //         if (CheckWeb) {
  //   //           let indexMain = fetchRecord.SlotMessage.indexOf(CheckWeb);

  //   //           CheckWeb.SlotID = this.selectedNodeID
  //   //           // CheckWeb.MainMsg = this.userInputForm.controls.webDispMsg.value

  //   //           CheckWeb.PreMsg = address.SlotMessage[0].PreMsg
  //   //           CheckWeb.PreMsgType = address.SlotMessage[0].PreMsgType
  //   //           CheckWeb.MainMsg = address.SlotMessage[0].MainMsg
  //   //           CheckWeb.DisplayMessage = address.SlotMessage[0].DisplayMessage
  //   //           CheckWeb.ResponseType = address.SlotMessage[0].ResponseType
  //   //           CheckWeb.PostMsg = address.SlotMessage[0].PostMsg
  //   //           CheckWeb.PostMsgType = address.SlotMessage[0].PostMsgType

  //   //           fetchRecord.SlotMessage[indexMain] = CheckWeb
  //   //         }

  //   //         var CheckApp = fetchRecord.SlotMessage.find((x: { AppType: string; }) => x.AppType == "App")
  //   //         if (CheckApp) {
  //   //           let indexMain = fetchRecord.SlotMessage.indexOf(CheckApp);

  //   //           CheckApp.SlotID = this.selectedNodeID
  //   //           // CheckApp.MainMsg = this.userInputForm.controls.appDispMsg.value

  //   //           CheckApp.PreMsg = address.SlotMessage[1].PreMsg
  //   //           CheckApp.PreMsgType = address.SlotMessage[1].PreMsgType
  //   //           CheckApp.MainMsg = address.SlotMessage[1].MainMsg
  //   //           CheckApp.DisplayMessage = address.SlotMessage[1].DisplayMessage
  //   //           CheckApp.ResponseType = address.SlotMessage[1].ResponseType
  //   //           CheckApp.PostMsg = address.SlotMessage[1].PostMsg
  //   //           CheckApp.PostMsgType = address.SlotMessage[1].PostMsgType

  //   //           fetchRecord.SlotMessage[indexMain] = CheckApp
  //   //         }
  //   //       } else {
  //   //         console.log("new record.........")
  //   //         if (this.ValidUserInputToSameSlot.length == 0) {
  //   //           this.addValidUserInputToSameSlot()
  //   //         }
  //   //         console.log(this.ValidUserInputToSameSlot)
  //   //         // d.ReturnSlotBasedOnInput = this.ValidUserInputToSameSlot.concat(d.ReturnSlotBasedOnInput)
  //   //       }
  //   //       d.ReturnSlotBasedOnInput = d.ReturnSlotBasedOnInput.filter((x: { ReturnSlotType: string; }) => x.ReturnSlotType !== 'returnToSameSlot')

  //   //       d.ReturnSlotBasedOnInput = this.ValidUserInputToSameSlot.concat(d.ReturnSlotBasedOnInput)
  //   //     }
  //   //   }
  //   //   // var fetchReturnSlot = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID).ReturnSlotBasedOnInput
  //   //   // console.log(fetchReturnSlot)

  //   //   // var fetchSingleSlotRecord = fetchReturnSlot.find((x: { UserInput: string; }) => x.UserInput == "@All")
  //   //   // console.log(fetchSingleSlotRecord)

  //   //   // if (fetchRecord) {

  //   //   // }
  //   // }
  //   console.log("@All user input arr .......", this.Slots)
  //   this.saveFlowChartOnpropertyBlock();
  // }

  publish() {
    
    this._isSaveDiagramLoader = true
    const data = {
      IntentName: this._selectedRowData.IntentName
    }
    this.conversationFlowService.publishConversationFlowDiagram(data).subscribe((res: any) => {
      
      if (res.Status.toUpperCase() == "SUCCESS") {
        this._isSaveDiagramLoader = false
      } else if (res.Error) {
      }
    })
  }



  // SaveMainWebValues() {
  //   // 
  //   var dq = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   console.log(dq.SlotMessage)

  //   var dqa = dq.SlotMessage.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   console.log(dqa)
  //   if (dqa) {
  //     //
  //     this.WebPreMessage = dq.SlotMessage[0].PreMsg
  //     this.WebPreMessageType = dq.SlotMessage[0].PreMsgType
  //     this.WebMainMessage = dq.SlotMessage[0].MainMsg
  //     this.WebDisplayMsg = dq.SlotMessage[0].DisplayMessage
  //     this.WebResponseType = dq.SlotMessage[0].ResponseType
  //     this.WebPostMessage = dq.SlotMessage[0].PostMsg
  //     this.WebPoseMessageType = dq.SlotMessage[0].PostMsgType
  //   }
  //   // if (this.selectedNodeID === dq) {
  //   //   ////
  //   //   var d = this.slotDataFromJson.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   //   console.log(d)
  //   //   this.generalForm.controls.SlotName.setValue(d.SlotName)
  //   // }
  // }

  // SaveMainAppValues() {
  //   // 
  //   var dq = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   console.log(dq.SlotMessage)

  //   var dqa = dq.SlotMessage.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   console.log(dqa)
  //   if (dqa) {
  //     //
  //     this.appPreMessage = dq.SlotMessage[1].PreMsg
  //     this.appPreMessageType = dq.SlotMessage[1].PreMsgType
  //     this.appMainMessage = dq.SlotMessage[1].MainMsg
  //     this.appDisplayMsg = dq.SlotMessage[1].DisplayMessage
  //     this.appResponseType = dq.SlotMessage[1].ResponseType
  //     this.appPostMessage = dq.SlotMessage[1].PostMsg
  //     this.appPoseMessageType = dq.SlotMessage[1].PostMsgType
  //   }
  //   // if (this.selectedNodeID === dq) {
  //   //   //
  //   //   var d = this.slotDataFromJson.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   //   console.log(d)
  //   //   this.generalForm.controls.SlotName.setValue(d.SlotName)
  //   // }
  // }

  // SaveAwaitWebValues() {
  //   // 
  //   var dq = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   console.log(dq.AwaitMsg)

  //   var dqa = dq.AwaitMsg.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   console.log(dqa)
  //   if (dqa) {
  //     //
  //     this.AwaitWebPreMessage = dq.AwaitMsg[0].PreMsg
  //     this.AwaitWebPreMessageType = dq.AwaitMsg[0].PreMsgType
  //     this.AwaitWebMainMessage = dq.AwaitMsg[0].MainMsg
  //     this.AwaitWebDisplayMsg = dq.AwaitMsg[0].DisplayMessage
  //     this.AwaitWebResponseType = dq.AwaitMsg[0].ResponseType
  //     this.AwaitWebPostMessage = dq.AwaitMsg[0].PostMsg
  //     this.AwaitWebPostMessageType = dq.AwaitMsg[0].PostMsgType
  //   }
  //   // if (this.selectedNodeID === dq) {
  //   //   ////
  //   //   var d = this.slotDataFromJson.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   //   console.log(d)
  //   //   this.generalForm.controls.SlotName.setValue(d.SlotName)
  //   // }
  // }

  // SaveAwaitAppValues() {
  //   // 
  //   var dq = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   console.log(dq.AwaitMsg)

  //   var dqa = dq.AwaitMsg.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   console.log(dqa)
  //   if (dqa) {
  //     //
  //     this.AwaitAppPreMessage = dq.AwaitMsg[1].PreMsg
  //     this.AwaitAppPreMessageType = dq.AwaitMsg[1].PreMsgType
  //     this.AwaitAppMainMessage = dq.AwaitMsg[1].MainMsg
  //     this.AwaitAppDisplayMsg = dq.AwaitMsg[1].DisplayMessage
  //     this.AwaitAppResponseType = dq.AwaitMsg[1].ResponseType
  //     this.AwaitAppPostMessage = dq.AwaitMsg[1].PostMsg
  //     this.AwaitAppPostMessageType = dq.AwaitMsg[1].PostMsgType
  //   }
  //   // if (this.selectedNodeID === dq) {
  //   //   //
  //   //   var d = this.slotDataFromJson.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   //   console.log(d)
  //   //   this.generalForm.controls.SlotName.setValue(d.SlotName)
  //   // }
  // }

  // SaveErrorSlotValues() {
  //   
  //   var dq = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   console.log(dq.ReturnSlotBasedOnInput)

  //   var dqa = dq.ReturnSlotBasedOnInput.find((x: { UserInput: string; }) => x.UserInput == "Error")
  //   console.log(dqa)

  //   if (dqa) {
  //     //
  //     // this.errorForm.controls.UserInput.setValue(dqa.UserInput)
  //     // this.errorForm.controls.ReturnType.setValue(dqa.ReturnType)
  //     // this.errorForm.controls.ReturnName.setValue(dqa.ReturnName)
  //     // this.errorForm.controls.webDispMsg.setValue(dqa.SlotMessage[0].DisplayMessage)
  //     // this.errorForm.controls.appDispMsg.setValue(dqa.SlotMessage[1].DisplayMessage)

  //     this.errorWebPreMessage = dqa.SlotMessage[0].PreMsg
  //     this.errorWebPreMessageType = dqa.SlotMessage[0].PreMsgType
  //     this.errorWebMainMessage = dqa.SlotMessage[0].MainMsg
  //     this.webErrorMsg = dqa.SlotMessage[0].DisplayMessage
  //     this.errorWebResponseType = dqa.SlotMessage[0].ResponseType
  //     this.errorWebPostMessage = dqa.SlotMessage[0].PostMsg
  //     this.errorWebPoseMessageType = dqa.SlotMessage[0].PostMsgType

  //     this.errorAppPreMessage = dqa.SlotMessage[1].PreMsg
  //     this.errorAppPreMessageType = dqa.SlotMessage[1].PreMsgType
  //     this.errorAppMainMessage = dqa.SlotMessage[1].MainMsg
  //     this.appErrorMsg = dqa.SlotMessage[1].DisplayMessage
  //     this.errorAppResponseType = dqa.SlotMessage[1].ResponseType
  //     this.errorAppPostMessage = dqa.SlotMessage[1].PostMsg
  //     this.errorAppPoseMessageType = dqa.SlotMessage[1].PostMsgType
  //   }
  // }

  // SaveTooManyAttemptsSlotValues() {
  //   
  //   var dq = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   console.log(dq.ReturnSlotBasedOnInput)

  //   var dqa = dq.ReturnSlotBasedOnInput.find((x: { UserInput: string; }) => x.UserInput == "tooManyAttempts")
  //   console.log(dqa)

  //   if (dqa) {
  //     //
  //     this.noOfAttemptsWebPreMessage = dqa.SlotMessage[0].PreMsg
  //     this.noOfAttemptsWebPreMessageType = dqa.SlotMessage[0].PreMsgType
  //     this.noOfAttemptsWebMainMessage = dqa.SlotMessage[0].MainMsg
  //     this.noOfAttemptsErrorMsgWeb = dqa.SlotMessage[0].DisplayMessage
  //     this.noOfAttemptsWebResponseType = dqa.SlotMessage[0].ResponseType
  //     this.noOfAttemptsWebPostMessage = dqa.SlotMessage[0].PostMsg
  //     this.noOfAttemptsWebPoseMessageType = dqa.SlotMessage[0].PostMsgType

  //     this.noOfAttemptsAppPreMessage = dqa.SlotMessage[1].PreMsg
  //     this.noOfAttemptsAppPreMessageType = dqa.SlotMessage[1].PreMsgType
  //     this.noOfAttemptsAppMainMessage = dqa.SlotMessage[1].MainMsg
  //     this.noOfAttemptsErrorMsgApp = dqa.SlotMessage[1].DisplayMessage
  //     this.noOfAttemptsAppResponseType = dqa.SlotMessage[1].ResponseType
  //     this.noOfAttemptsAppPostMessage = dqa.SlotMessage[1].PostMsg
  //     this.noOfAttemptsAppPoseMessageType = dqa.SlotMessage[1].PostMsgType
  //   }
  // }

  // SaveUserInputSlotValues() {
  //   

  //   for (let address of this.validUserInputArr) {
  //     var dq = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //     console.log(dq.ReturnSlotBasedOnInput)

  //     var dqa = dq.ReturnSlotBasedOnInput.find((x: { ReturnName: string; }) => x.ReturnName == address.ReturnName)
  //     console.log(dqa)

  //     var fetchSingleSlotRecord = dq.ReturnSlotBasedOnInput.find((x: { UserInput: string; ReturnSlotType: string; }) => address.UserInput == "@All" && address.ReturnSlotType == "undefined")
  //     console.log(fetchSingleSlotRecord)

  //     if (dqa) {
  //       address.SlotMessage[0].PreMsg = dqa.SlotMessage[0].PreMsg
  //       address.SlotMessage[0].PreMsgType = dqa.SlotMessage[0].PreMsgType
  //       address.SlotMessage[0].MainMsg = dqa.SlotMessage[0].MainMsg
  //       address.SlotMessage[0].DisplayMessage = dqa.SlotMessage[0].DisplayMessage
  //       address.SlotMessage[0].ResponseType = dqa.SlotMessage[0].ResponseType
  //       address.SlotMessage[0].PostMsg = dqa.SlotMessage[0].PostMsg
  //       address.SlotMessage[0].PostMsgType = dqa.SlotMessage[0].PostMsgType

  //       address.SlotMessage[1].PreMsg = dqa.SlotMessage[1].PreMsg
  //       address.SlotMessage[1].PreMsgType = dqa.SlotMessage[1].PreMsgType
  //       address.SlotMessage[1].MainMsg = dqa.SlotMessage[1].MainMsg
  //       address.SlotMessage[1].DisplayMessage = dqa.SlotMessage[1].DisplayMessage
  //       address.SlotMessage[1].ResponseType = dqa.SlotMessage[1].ResponseType
  //       address.SlotMessage[1].PostMsg = dqa.SlotMessage[1].PostMsg
  //       address.SlotMessage[1].PostMsgType = dqa.SlotMessage[1].PostMsgType

  //     } else if (fetchSingleSlotRecord) {

  //       address.SlotMessage[0].PreMsg = dqa.SlotMessage[0].PreMsg
  //       address.SlotMessage[0].PreMsgType = dqa.SlotMessage[0].PreMsgType
  //       address.SlotMessage[0].MainMsg = dqa.SlotMessage[0].MainMsg
  //       address.SlotMessage[0].DisplayMessage = dqa.SlotMessage[0].DisplayMessage
  //       address.SlotMessage[0].ResponseType = dqa.SlotMessage[0].ResponseType
  //       address.SlotMessage[0].PostMsg = dqa.SlotMessage[0].PostMsg
  //       address.SlotMessage[0].PostMsgType = dqa.SlotMessage[0].PostMsgType

  //       address.SlotMessage[1].PreMsg = dqa.SlotMessage[1].PreMsg
  //       address.SlotMessage[1].PreMsgType = dqa.SlotMessage[1].PreMsgType
  //       address.SlotMessage[1].MainMsg = dqa.SlotMessage[1].MainMsg
  //       address.SlotMessage[1].DisplayMessage = dqa.SlotMessage[1].DisplayMessage
  //       address.SlotMessage[1].ResponseType = dqa.SlotMessage[1].ResponseType
  //       address.SlotMessage[1].PostMsg = dqa.SlotMessage[1].PostMsg
  //       address.SlotMessage[1].PostMsgType = dqa.SlotMessage[1].PostMsgType
  //     }
  //   }
  //   // var dq = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   // console.log(dq.ReturnSlotBasedOnInput)

  //   // var dqa = dq.ReturnSlotBasedOnInput.find((x: { UserInput: string; }) => x.UserInput == "@All")
  //   // console.log(dqa)

  //   // if (dqa) {
  //   //   //
  //   //   this.userInputForm.controls.UserInput.setValue(dqa.UserInput)
  //   //   this.userInputForm.controls.ReturnType.setValue(dqa.ReturnType)
  //   //   this.userInputForm.controls.ReturnName.setValue(dqa.ReturnName)
  //   //   this.userInputForm.controls.webDispMsg.setValue(dqa.SlotMessage[0].DisplayMessage)
  //   //   this.userInputForm.controls.appDispMsg.setValue(dqa.SlotMessage[1].DisplayMessage)
  //   // }
  // }

  // SaveValidUserInputToSameSlot() {
  //   
  //   // for (let address of this.ValidUserInputToSameSlot) {
  //   // if (this.ValidUserInputToSameSlot.length > 0) {
  //   // this._isValidUserInputToSameSlot = true
  //   // this.addValidUserInputToSameSlot()
  //   // }

  //   var dq = this.Slots.Slots.find((x: { SlotID: string; }) => x.SlotID == this.selectedNodeID)
  //   console.log(dq.ReturnSlotBasedOnInput)

  //   var dqa = dq.ReturnSlotBasedOnInput.find((x: { ReturnSlotType: string; }) => x.ReturnSlotType == 'returnToSameSlot')
  //   console.log(dqa)

  //   this.ValidUserInputToSameSlot

  //   if (dqa) {
  //     this._isValidUserInputToSameSlot = true
  //     this.ValidUserInputToSameSlot.push(dqa)
  //     for (let address of this.ValidUserInputToSameSlot) {
  //       this.singleSlotValue = dqa.ReturnName

  //       address.SlotMessage[0].PreMsg = dqa.SlotMessage[0].PreMsg
  //       address.SlotMessage[0].PreMsgType = dqa.SlotMessage[0].PreMsgType
  //       address.SlotMessage[0].MainMsg = dqa.SlotMessage[0].MainMsg
  //       address.SlotMessage[0].DisplayMessage = dqa.SlotMessage[0].DisplayMessage
  //       address.SlotMessage[0].ResponseType = dqa.SlotMessage[0].ResponseType
  //       address.SlotMessage[0].PostMsg = dqa.SlotMessage[0].PostMsg
  //       address.SlotMessage[0].PostMsgType = dqa.SlotMessage[0].PostMsgType

  //       address.SlotMessage[1].PreMsg = dqa.SlotMessage[1].PreMsg
  //       address.SlotMessage[1].PreMsgType = dqa.SlotMessage[1].PreMsgType
  //       address.SlotMessage[1].MainMsg = dqa.SlotMessage[1].MainMsg
  //       address.SlotMessage[1].DisplayMessage = dqa.SlotMessage[1].DisplayMessage
  //       address.SlotMessage[1].ResponseType = dqa.SlotMessage[1].ResponseType
  //       address.SlotMessage[1].PostMsg = dqa.SlotMessage[1].PostMsg
  //       address.SlotMessage[1].PostMsgType = dqa.SlotMessage[1].PostMsgType
  //     }
  //     this.ValidUserInputToSameSlot
  //   }

  //   // for (let address of this.ValidUserInputToSameSlot) {
  //   //   var dqa = dq.ReturnSlotBasedOnInput.find((x: { ReturnName: string; }) => x.ReturnName == address.ReturnName)
  //   //   console.log(dqa)

  //   //   var fetchSingleSlotRecord = dq.ReturnSlotBasedOnInput.find((x: { UserInput: string; }) => x.UserInput == "@All")
  //   //   console.log(fetchSingleSlotRecord)

  //   //   if (fetchSingleSlotRecord) {

  //   //     address.SlotMessage[0].PreMsg = dqa.SlotMessage[0].PreMsg
  //   //     address.SlotMessage[0].PreMsgType = dqa.SlotMessage[0].PreMsgType
  //   //     address.SlotMessage[0].MainMsg = dqa.SlotMessage[0].MainMsg
  //   //     address.SlotMessage[0].DisplayMessage = dqa.SlotMessage[0].DisplayMessage
  //   //     address.SlotMessage[0].ResponseType = dqa.SlotMessage[0].ResponseType
  //   //     address.SlotMessage[0].PostMsg = dqa.SlotMessage[0].PostMsg
  //   //     address.SlotMessage[0].PostMsgType = dqa.SlotMessage[0].PostMsgType

  //   //     address.SlotMessage[1].PreMsg = dqa.SlotMessage[1].PreMsg
  //   //     address.SlotMessage[1].PreMsgType = dqa.SlotMessage[1].PreMsgType
  //   //     address.SlotMessage[1].MainMsg = dqa.SlotMessage[1].MainMsg
  //   //     address.SlotMessage[1].DisplayMessage = dqa.SlotMessage[1].DisplayMessage
  //   //     address.SlotMessage[1].ResponseType = dqa.SlotMessage[1].ResponseType
  //   //     address.SlotMessage[1].PostMsg = dqa.SlotMessage[1].PostMsg
  //   //     address.SlotMessage[1].PostMsgType = dqa.SlotMessage[1].PostMsgType
  //   //   }
  //   // }
  // }

  DownloadFile() {
    // let dataStr: string = 'data:text/json;charset=utf-8,' + this.Slots;
    

    
    var d = this._ConvFlowEntityObj.Slots.sort(function (a, b) {
      
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return a.SlotOrder - b.SlotOrder;
    });



    if (this._ConvFlowEntityObj.Slots.length > 0) {
      let dataStr1 = JSON.stringify(this._ConvFlowEntityObj)
      let dataStr: string = 'data:text/json;charset=utf-8,' + encodeURIComponent(dataStr1);
      let a: HTMLAnchorElement = document.createElement('a');
      a.href = dataStr;
      a.download = 'CustomFlowChartData.json';
      document.body.appendChild(a);
      // let dataStr: string = 'data:text/json;charset=utf-8,' + encodeURIComponent(data);
      // let a: HTMLAnchorElement = document.createElement('a');
      // a.href = dataStr;
      // a.download = 'Diagram.json';
      // document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }

  fetchDataType() {
    this.dataTypes = [
      { name: 'String', code: 'String' },
      { name: 'Number', code: 'Number' }
    ]
  }

  onChangeDataType(event: any) {
    
    this.SlotDataType = event.value
  }

  fetchDataFormat() {
    this.dataFormat = [
      { name: 'NA', code: 'NA', regex: 'NA' },
      { name: 'Email', code: 'Email', regex: '/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/' },
      { name: 'Phone', code: 'Phone', regex: '/((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/' },
      { name: 'Only Characters', code: 'Only Characters', regex: '^[a-zA-Z_ ]*$' },
      { name: 'Only numbers', code: 'Only numbers', regex: '^[0-9]*$' },
      { name: 'AccountNumber', code: 'AccountNumber', regex: '^\\d{9,18}$' }
    ]
  }

  onChangeDataformat(event: any) {
    
    this._selectedDataFormat = event.value
    this.DataformatName = this._selectedDataFormat.name
  }

  fetchdataValidation() {
    this.dataValidation = [
      { name: 'NA', code: 'NA' },
      { name: 'regex', code: 'regex' },
      { name: 'slotvalues', code: 'slotvalues' }
    ]
  }

  onChangeDataValidation(event: any) {
    
    this.DataValidation = event.value
  }

  fetchSlotTypes() {
    this.SlotTypeArr = [
      { name: 'Process', code: 'Process' },
      { name: 'Decision', code: 'Decision' },
      { name: 'WebAPI ', code: 'WebAPI ' },
      { name: 'Processmultiop', code: 'Processmultiop' },
      { name: 'NA ', code: 'NA ' }
    ]
  }

  onChangeSlotType(event: any) {
    
    this.SlotType = event.value
  }

  fetchNodesArr() {
    
    this.returnNameArr = []
    for (let k = 0; k < this.nodes.length; k++) { //&& this.nodes[k].annotations![0].content !== this.selectedNodeName
      if (this.nodes[k].annotations![0].content?.toUpperCase() !== 'START') {
        this.returnNameArr.push({
          name: this.nodes[k].annotations![0].content,
          code: this.nodes[k].annotations![0].content
        })




      }
    }

    this.returnNameArr.push({
      name: "tooManyAttempts",
      code: "tooManyAttempts"
    })

    // for (let k = 0; k < this.nextSlotName.length; k++) {
    //   // if (this.nodes[k].annotations![0].content?.toUpperCase() !== 'START' && this.nodes[k].annotations![0].content !== this.selectedNodeName) {
    //   this.returnNameArr.push({
    //     name: this.nextSlotName[k],
    //     code: this.nextSlotName[k]
    //   })
    //   // }
    // }
    //this.singleSlotValue = this.returnNameArr[0].name
  }


  cancelbuttonOnConFlow() {
    this.inputdata.emit("");
  }



  ClearCompleteflow() {
    // this.Slots = undefined;
    // this.diagramArr = undefined;



    // this.saveToDB();

    this.inputdata.emit("");
  }

  onChangeNodesArr(event: any) {
    
    this.singleSlotValue = event.value
  }

  // fetchReturnSlotValue(event: any) {
  //   
  //   console.log("fetchReturnSlotValue", event)
  //   this.fetchReturnSlotRadioButton = event
  //   if (this.SlotValues.length > 0) {
  //     if (this.fetchReturnSlotRadioButton == 'returnToSameSlot') {
  //       this._isValidUserInputToSameSlot = true
  //       this._isreturnSlotBasedOnInput = false
  //       this.addValidUserInputToSameSlot()
  //     } else if (this.fetchReturnSlotRadioButton == 'returnSlotBasedOnInput') {
  //       this._isreturnSlotBasedOnInput = true
  //       this._isValidUserInputToSameSlot = false
  //     }
  //   }
  // }


  accordionStyle() {
    $(".collapse.show").each(function () {
      $(this).prev(".card-header").find(".fa").addClass("fa-angle-down").removeClass("fa-angle-up");
    });

    // Toggle right and down arrow icon on show hide of collapse element
    $(".collapse").on('show.bs.collapse', function () {
      $(this).prev(".card-header").find(".fa").removeClass("fa-angle-down").addClass("fa-angle-up");
    }).on('hide.bs.collapse', function () {
      $(this).prev(".card-header").find(".fa").removeClass("fa-angle-up").addClass("fa-angle-down");
    });
  }
}
