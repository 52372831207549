<div class="container-fluid screen-container bg-white">
    <div class="settingWrapper">
        <p class="wrapperTitle">Settings</p>
        <ul class="nav nav-tabs">
            <li><a data-toggle="tab" href="#accountInformation" [ngClass]="{'active':_addActiveShowClass}">Account
                    Information</a></li>
            <li><a data-toggle="tab" href="#password">Password</a></li>
            <li [hidden]="_hideOptionAsPerRole"><a data-toggle="tab" href="#notification">Notifications</a></li>
            <li [hidden]="_hideOptionAsPerRole"><a data-toggle="tab" href="#tenantselection"
                    [ngClass]="{'active':!_addActiveShowClass}">Tenant Selection</a>
            </li>
        </ul>

        <div class="tab-content">
            <div id="accountInformation" class="tab-pane fade" [ngClass]="{'active show':_addActiveShowClass}">
                <app-accountinformation></app-accountinformation>
            </div>

            <div id="password" class="tab-pane fade">
                <app-password></app-password>
            </div>

            <div id="notification" class="tab-pane fade">
                <div class="notificationWrapper">
                    <div class="media">
                        <div class="media-body">
                            <p class="mediaTitle">Desktop Notifications</p>
                            <p class="mediaContent">Receive notifications directly on my Windows</p>
                        </div>

                        <div class="align-self-center">
                            <p-inputSwitch (onChange)="handleDesktopNotification($event)" [(ngModel)]="_isDesktopNotify"
                                [readonly]="true">
                            </p-inputSwitch>
                        </div>
                    </div>

                    <div class="media">
                        <div class="media-body">
                            <p class="mediaTitle">Email Notifications</p>
                            <p class="mediaContent">Receive notifications directly on my Windows</p>
                        </div>

                        <div class="align-self-center">
                            <p-inputSwitch (onChange)="handleEmailNotification($event)" [(ngModel)]="_isEmailNotify"
                                [readonly]="true">
                            </p-inputSwitch>
                        </div>
                    </div>
                </div>
            </div>

            <div id="tenantselection" class="tab-pane fade" [ngClass]="{'active show':!_addActiveShowClass}">
                <app-tenantselection></app-tenantselection>
            </div>

        </div>
    </div>
</div>