import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';

import { DeletedFAQService } from '../../services/DeletedFAQ/deleted-faq.service'

import { Entities } from '../deletedfaqs/Entities'
import { faqEntities } from '../../faq/fqaentities'

import { Table } from 'primeng/table';

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-deletedfaqs',
  templateUrl: './deletedfaqs.component.html',
  styleUrls: ['./deletedfaqs.component.css']
})


export class DeletedfaqsComponent implements OnInit {
  @ViewChild('dt') dt: ElementRef
  @ViewChild("dt", { static: false }) public ptable: Table;

  deletedFAQArr: any = [];
  deletedfaqHeadersArr: any = [];
  searchOptionArr: any = [];
  selectedSearchOpt: any[];
  selectedOptArr: any = [];

  value: Date;

  first: number = 0;
  rows = 10;
  islastPageFlag: boolean;

  sortColumnName: string;
  sortorder: boolean;
  descOrder: Boolean;
  AfterUpdateColumnName: string;

  selectedSection: string = "";
  selectedFaq: string = "";
  selectedAns: string = "";
  IsSelectedCB: boolean = false;
  searchFAQ: string = "";

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string

  isLoader: boolean = true;

  parameters = new faqEntities()

  _searchData: string

  _ifNoRecords: boolean = false

  title = environment.pageTitle + ' - Deleted FAQ';

  constructor(public deletedFAQService: DeletedFAQService, private titleService: Title) {
  }

  ngOnInit(): void {

    this.titleService.setTitle(this.title);

    this.faqListHeaders();
    this.getAllDeletedFAQlist();
  }

  faqListHeaders() {
    this.deletedfaqHeadersArr = [
      { field: 'Id', header: 'Sr No.', width: "6%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'abc' },
      { field: 'SectionName', header: 'Section Name', width: "9%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'Section Name' },
      { field: 'Questions', header: 'FAQ', width: "15%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'FAQ' },
      { field: 'UserMessage', header: 'Answer', width: "18%", isEditable: "true", isSort: "true", textAlignment: "left", value: 'Answer' },
      { field: 'DeleteReason', header: 'Reason', width: "8%", isEditable: "true", isSort: "true", textAlignment: "left", value: 'Reason' },
      { field: 'BondModifiedBy', header: 'Del. By', width: "6%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'BondModified By' },
      { field: 'BondCreatedOn', header: 'Del. Date', width: "6%", isEditable: "false", isSort: "true", textAlignment: "left", value: 'BondCreated On' },
    ]

    this.searchOptionArr = [
      { name: 'Section Name', code: 'sectionName' },
      { name: 'FAQ', code: 'faq' },
      { name: 'Answer', code: 'ans' }
    ]
  }

  getAllDeletedFAQlist() {
    const params = {
    }
    this.getFilteredDeletedFAQlist(params);

  }

  selectSearchOption(text: any) {
    if (this.searchFAQ.length === 0)
      return;
    this.searchFAQ = this.searchFAQ.trim();
    var searchEntities = this.setSearchEntities();
    this.getFilteredDeletedFAQlist(searchEntities.IntentMaintenance);
  }

  searchData(event: any) {
    ;
    if (event.keyCode === 13) {
      var searchEntities = this.setSearchEntities();
      this.getFilteredDeletedFAQlist(searchEntities.IntentMaintenance);
    }
  }

  setSearchEntities() {
    
    var searchEntities = new faqEntities();
    this.searchFAQ = this.searchFAQ.trim();

    if (this.selectedSearchOpt !== undefined) {

      if (this.selectedSearchOpt.length != 0) {
        for (let i = 0; i < this.selectedSearchOpt.length; i++) {
          if (this.selectedSearchOpt[i].code.toUpperCase() == "SECTIONNAME") {
            searchEntities.IntentMaintenance.SectionName = this.searchFAQ.substring(0, 50)
          } else if (this.selectedSearchOpt[i].code.toUpperCase() == "FAQ") {
            searchEntities.IntentMaintenance.Questions = this.searchFAQ.substring(0, 200)
          } else if (this.selectedSearchOpt[i].code.toUpperCase() == "ANS") {
            searchEntities.IntentMaintenance.UserMessage = this.searchFAQ
          }
        }
        searchEntities.IntentMaintenance.IsSelectedCB = true;
      }

      else {
        searchEntities.IntentMaintenance.SectionName = this.searchFAQ.substring(0, 50);
        searchEntities.IntentMaintenance.Questions = this.searchFAQ.substring(0, 200);
        searchEntities.IntentMaintenance.UserMessage = this.searchFAQ;
        searchEntities.IntentMaintenance.IsSelectedCB = false;
      }
    }

    else {
      searchEntities.IntentMaintenance.SectionName = this.searchFAQ.substring(0, 50);
      searchEntities.IntentMaintenance.Questions = this.searchFAQ.substring(0, 200);
      searchEntities.IntentMaintenance.UserMessage = this.searchFAQ;
      searchEntities.IntentMaintenance.IsSelectedCB = false;
    }

    return searchEntities;
  }

  getFilteredDeletedFAQlist(searchEntities: any) {
    ;

    this.parameters.IntentMaintenance.SectionName = searchEntities.SectionName
    this.parameters.IntentMaintenance.IntentName = searchEntities.Questions
    this.parameters.IntentMaintenance.Questions = searchEntities.Questions
    this.parameters.IntentMaintenance.UserMessage = searchEntities.UserMessage
    this.parameters.IntentMaintenance.MultipleUtterance = searchEntities.MultipleUtterance
    this.parameters.IntentMaintenance.IsSelectedCB = searchEntities.IsSelectedCB

    this.isLoader = true;
    this.deletedFAQService.getAllDeletedFAQData(searchEntities).subscribe((res: any) => {
      if (res.Status = 'SUCCESS') {
        ;
        this.deletedFAQArr = res.Data
        this.isLoader = false
        this.sortColumnName = '';
        this.sortorder = false;

        if (this.deletedFAQArr.length == 0) {
          setTimeout(() => {
            this._ifNoRecords = true
          }, 1000);
        } else {
          this._ifNoRecords = false
        }

      }
      else if (res.Error) {
        ;
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.isLoader = false;
      }
    })
  }

  GOToEnteredPageNo(pageNo: any): void {

    if (pageNo != "0" && pageNo != "") {

      var enteredPageNo: number = +pageNo;

      this.islastPageFlag = this.isLastPage(enteredPageNo);

      if (this.islastPageFlag === false) {
        this.first = enteredPageNo * 10 - 10;
      }
      else {
        const pageCount = Math.ceil(this.deletedFAQArr.length / this.rows);

        let page = pageCount - 1;
        page = page * this.rows

        this.first = page
      }
    }
    else {
      this.first = 0;
    }
  }

  isLastPage(pageNo: any): boolean {
    const pageCount = Math.ceil(this.deletedFAQArr.length / this.rows);

    const page = pageCount - 1;

    if (pageNo <= page) {
      return false;
    }
    else {
      return true;
    }
  }

  sortcolumn(ColumnName: any) {
    let reverse = this.sortColumnName === ColumnName ? !this.descOrder : undefined;

    if (reverse === undefined)
      reverse = false;

    this.sortColumnName = ColumnName;
    this.descOrder = reverse;

    if (this.descOrder == true)
      this.sortorder = false;
    else
      this.sortorder = true;
  }
}