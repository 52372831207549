import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { PersonaService } from '../services/Persona/persona.service'
import { DatePipe } from '@angular/common';
import html2canvas from 'html2canvas';

import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-persona-cluster',
  templateUrl: './persona-cluster.component.html',
  styleUrls: ['./persona-cluster.component.css'],
  providers: [DatePipe]
})
export class PersonaClusterComponent implements OnInit {

  _isChecked: boolean = false
  _topHeaderImage: any

  _isLoader: boolean = false;
  _personImg: string

  _ViewClientRoleAccess: boolean = false;

  _RoleName = window.localStorage.getItem("RoleName");
  _pageName = 'Persona'

  PersonaCategoryList: any = [];
  monthList: any = [];
  personaDetails: any = [];
  BondScoreList: any = [];
  BondMonthArray: any = [];
  consumerFirstName: string;
  consumerLastName: string;
  BondScoreVal: any;
  BondStatus: any;

  BondScoreStatus: any;
  myDate: any
  _options: any

  _BondScoreArray: any
  _BondMonthArray: any;
  _maxBondScore: any;
  _minBondScore: any;
  _medianBondScore: any;

  _LTI: any
  _Conversation: any = [];
  _ManualQueAnsList: any = [];

  UserMessage: string

  showPopup: boolean = false
  popupImg: string;
  popupContent: string;
  popupContentError: string
  closeModal: any

  checked: boolean;
  scrolltop: number;
  @ViewChild('scrollMe') comment: ElementRef;

  valueBlockArr: any = [];
  valueBlockWidth: any

  @ViewChild("modalWarningAndInfo", { static: false }) public modalWarningAndInfo: NgbModal;

  title = environment.pageTitle + ' - Individual';

  constructor(public personaService: PersonaService, private datePipe: DatePipe, private modalService: NgbModal, private titleService: Title) {
  }

  ngOnInit(): void {

    this.titleService.setTitle(this.title);

    this._isLoader = true

    this.fetchPersonaCategoryList();
    this.fetchMonthList();

    this.getPersonaData();
    this.filterArr();
    this.InitialDataInBot();

    this.fetchDataByCalendar();

    this._RoleName = window.localStorage.getItem("RoleName");

    if (this._RoleName === 'ClientView' || this._RoleName === 'ContentView') {
      this._ViewClientRoleAccess = true;
    }
  }

  fetchPersonaCategoryList() {
    this.PersonaCategoryList = [
      { name: "Credit Card", value: "12%" },
      { name: "Auto Loan", value: "4.6%" },
      { name: "Emergency Fund", value: "14.5%" },
      { name: "Cancel Subscriptions", value: "Dup" },
      { name: "Medical Insurance", value: "NA" }
    ]
  }

  fetchMonthList() {
    this.monthList = [
      { name: "Oct" },
      { name: "May" },
      { name: "Jun" },
      { name: "Jul" },
      { name: "Aug" },
      { name: "Sep" },
      { name: "Oct" }
    ]
  }

  transform(value: string, args: any[]): string {
    if (!value) { return ''; }
    return value.split(' ')[0];
  }

  getPersonaData() {

    let vEmailId = "pooja@bond.ai"
    this.personaService.getPersonaData(vEmailId).subscribe((res: any) => {

      if (res.Status.toUpperCase() === 'SUCCESS') {
        
        this.personaDetails = res.Data

        this._isLoader = false;

        this.consumerFirstName = this.personaDetails.ConsumerName.split(' ')[0]
        this.consumerLastName = this.personaDetails.ConsumerName.split(' ')[1]

        this._personImg = "../../../assets/images/artwork.png"
        this._topHeaderImage = "topHeaderImage";

        this.BondScoreList = this.personaDetails.BondScoreList

        this._BondScoreArray = [];
        this._BondMonthArray = [];

        this.BondScoreList.forEach((value: any, key: any) => {

          this._BondScoreArray.push(value.BondScoreVal);

          const collectionDate = this.datePipe.transform(value.BondScoreOn, 'MMM-yy');

          this.BondMonthArray.push(collectionDate);
        });

        
        if (this.personaDetails.BondScoreList.length != 0) {
          this.BondScoreVal = this.personaDetails.BondScoreList[this.BondScoreList.length - 1].BondScoreVal;
        }

        this.checkBondStatus(this.BondScoreList, (this.BondScoreList.length));

        this._maxBondScore = Math.max.apply(Math, this._BondScoreArray);
        this._minBondScore = Math.min.apply(Math, this._BondScoreArray);
        this._medianBondScore = this.CalcMedian(this._BondScoreArray);

        this.HighChartFunction(this._BondScoreArray, this.BondMonthArray);

        this.checkValueBlockWidth();
      }
      else {
        this.popupImg = "../../../assets/images/CommonError.png";
        this.popupContent = "Oops!";
        this.popupContentError = res.Error;

        this.triggerModal(this.modalWarningAndInfo);
      }
    })
  }

  checkBondStatus(vBondScoreList: any, vId: any) {

    if (this.BondScoreVal != undefined)
      this.BondScoreStatus = (this.BondScoreVal - this.personaDetails.BondScoreList[vId - 2].BondScoreVal);

    if (Math.sign(this.BondScoreStatus) === -1)
      this.BondStatus = "NegativeVal";
    else
      this.BondStatus = "PositiveVal";
  }

  CalcMedian(numbers: any) {

    var median = 0, numsLen = numbers.length;
    numbers.sort();

    if (numsLen % 2 === 0) {
      median = (parseInt(numbers[numsLen / 2 - 1]) + parseInt(numbers[numsLen / 2])) / 2;
    }
    else { 
      median = numbers[(numsLen - 1) / 2];
    }

    return median;
  }

  HighChartFunction(vBondScoreArray: any, vBondMonthArray: any) {
    var bondscore = vBondScoreArray.map(function (v: any) {
      return parseInt(v, 10);
    });

    this._options = {
      title: {
        text: ''
      },

      subtitle: {
        text: ''
      },
      legend: {
        enabled: false
      },

      xAxis: {
        min: 0,
        gridLineWidth: 0,
        visible: false,
        labels: {
          enabled: false
        },
        categories: vBondMonthArray,

      },
      "credits": {
        enabled: false
      },
      yAxis: {
        min: 0,
        gridLineWidth: 0,
        title: {
          text: ''
        },
        labels: {
          enabled: false
        }
      },
      series: [{
        dataLabels: {
          enabled: true,

        },
        animation: {
          duration: 3000
        },
        marker: {
          radius: 2
        },

        type: 'line',
        color: '#03c4de',
        data: bondscore,
        lineWidth: 1,
        marginBottom: 10

      }],
      tooltip: {

      }
    }

    Highcharts.chart('cardChart', this._options);
  }

  filterArr() {
    this._ManualQueAnsList = [
      {
        Ques: "Test Que 1", ResponseType: "Text", MessageType: "BOT", Message: "Test Ans 1", SampleResponses: "", BotResponseType: "", PostDispMessage: "",
        PostMsgType: "", BotMessageType: "BOT", BotMessage: "", PreDispMessage: "", PreMsgType: "", AgentTransferMsg: "", IsDisabletxtbox: "", URLRespMsg: "", PreURLRespMsg: "", PostURLRespMsg: ""
      },

      {
        Ques: "Test Que 2", ResponseType: "Text", MessageType: "BOT", Message: "Test Ans 2", SampleResponses: "", BotResponseType: "", PostDispMessage: "",
        PostMsgType: "", BotMessageType: "BOT", BotMessage: "", PreDispMessage: "", PreMsgType: "", AgentTransferMsg: "", IsDisabletxtbox: "", URLRespMsg: "", PreURLRespMsg: "", PostURLRespMsg: ""
      },

      {
        Ques: "Test Que 3", ResponseType: "Text", MessageType: "BOT", Message: "Test Ans 3", SampleResponses: "", BotResponseType: "", PostDispMessage: "",
        PostMsgType: "", BotMessageType: "BOT", BotMessage: "", PreDispMessage: "", PreMsgType: "", AgentTransferMsg: "", IsDisabletxtbox: "", URLRespMsg: "", PreURLRespMsg: "", PostURLRespMsg: ""
      }
    ];
  }

  InitialDataInBot() {
    this._Conversation.push({ "ResponseType": "Text", "MessageType": "BOT", "Message": "What can I help you with?", "Index": 0, })
    this._Conversation.push({ "ResponseType": "Text", "MessageType": "UserMessages", "Message": "Account opening requirements", "Index": 0, })
    this._Conversation.push({ "ResponseType": "Text", "MessageType": "BOT", "Message": "You can access our list of requirements to open an account by clicking here.", "Index": 0, })
    this._Conversation.push({ "ResponseType": "Text", "MessageType": "UserMessages", "Message": "Can I apply for a new account online?", "Index": 0, })
    this._Conversation.push({ "ResponseType": "Text", "MessageType": "BOT", "Message": "With Online Banking, you have the option to apply for a deposit, investment, loan and credit card account.", "Index": 0, })
  }

  SendMessage(UserMessage: any, vMessageType: any, ReqFrom: any) {

    if (UserMessage === "" || UserMessage === null || UserMessage === undefined)
      return;
    else {

      this.AddUserMessage(UserMessage);

      let dataMain = this._ManualQueAnsList.filter(function (x: any) { return x.Ques.toUpperCase() === UserMessage.toUpperCase() })[0];

      this.UserMessage = "";
      if (dataMain != undefined) {
        (async () => {
          await this.delay(1000);

          this.AddMessage(dataMain);
        })();
      }
      else {

        dataMain = {
          Ques: "Test Que 1", ResponseType: "Text", MessageType: "BOT", Message: "Bot unable to give answer.", SampleResponses: "", BotResponseType: "", PostDispMessage: "",
          PostMsgType: "", BotMessageType: "BOT", BotMessage: "", PreDispMessage: "", PreMsgType: "", AgentTransferMsg: "", IsDisabletxtbox: "", URLRespMsg: "", PreURLRespMsg: "", PostURLRespMsg: ""
        },

          (async () => {
            await this.delay(1000);

            this.AddMessage(dataMain);
          })();
      }
    }
  };

  AddUserMessage(UserMessage: any) {

    var newMessage = {
      "ResponseType": "Text",
      "MessageType": "UserMessages",
      "Message": UserMessage,
      "Index": 0,
    };

    this._Conversation.push(newMessage);
  }

  AddMessage(ChatMessageObject: any) {
    var newMessage = {
      "ResponseType": ChatMessageObject.ResponseType,
      "MessageType": ChatMessageObject.MessageType,
      "Message": ChatMessageObject.Message,
      "Index": 0,
    };

    this._Conversation.push(newMessage)

    this.ScrollChatBody();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ScrollChatBody() {
    $("#ChatBody").animate({ scrollTop: $('#ChatBody').prop("scrollHeight") }, 200);
  };

  calendar: any = [];
  fetchDataByCalendar() {
    this.calendar = [
      { name: 'Last Week', code: 'week' },
      { name: 'Last Month', code: 'month' },
      { name: 'Last Quarter', code: 'quarter' },
      { name: 'Last Year', code: 'year' }
    ]
  }

  checkValueBlockWidth() {
    var maxNumber
    for (var val of this.personaDetails.PersonaCategoryList) {
      maxNumber = val.CategoryVal
      this.valueBlockArr.push(maxNumber)
    }
    var finalMaxValue = Math.max(...this.valueBlockArr)
    var numberOfDigits = finalMaxValue.toString().length
    if (numberOfDigits === 2) {
      this.valueBlockWidth = 'w-40px'
    } else if (numberOfDigits === 4) {
      this.valueBlockWidth = 'w-60px'
    } else if (numberOfDigits === 6) {
      this.valueBlockWidth = 'w-80px'
    } else if (numberOfDigits === 8) {
      this.valueBlockWidth = 'valueBlockWidth'
    }

  }

  triggerModal(content: any) {

    this.modalService.open(content).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  _checkvalue: any
  handleChange(e: any) {
    this._isChecked = e.checked;
    this._checkvalue = this._isChecked
  }
  ShowChat: boolean = true
  fetchShowChatValue(showChatVal: any) {
    this.ShowChat = showChatVal
  }

  convetToPDF() {
    const data = document.getElementById('HomePageD');
    if (data) {
      html2canvas(data).then(canvas => {
        var imgWidth = 208;
        var pageHeight = data.clientHeight;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL('image/png')
      });
    }
  }
}