import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http'

import { HttpCommonReq } from '../HttpCommonReq'

@Injectable({
  providedIn: 'root'
})

export class PasswordService {
  configUrls: any = [];

  constructor(private httpClient: HttpClient, private router: Router) {
    this.configUrls = {
      changeUserPassword: 'api/UserDetails/ChangePassword',
    }
  }

  ChangeUserPassword(data: any){
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
    return httpClientCommonObj.post(this.configUrls.changeUserPassword, data)
   }
 
   exportKYCData(data: any){
    let httpClientCommonObj = new HttpCommonReq(this.httpClient, this.router);   
    return httpClientCommonObj.post(this.configUrls.exportKYCList,data)
   }
}
